import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import GoogleCalendarIcon from "../../../assets/icons/google-cal-icon.png";
import { sendGetRequest } from '../../../actions/app-actions';
import { getAdminBusinessId } from '../../../utils/authHelper';
import { connect } from "react-redux";
import { notification } from '../../../utils/utility';

const CheckForConflictModal = (props) => {
    

    const { data, showConflictModal, hideConflictModal, sendGetRequest, setIsLoading } = props;
    const [calendars, setCalendars] = useState([]);
    const [selectedCalendars, setSelectedCalendars] = useState(JSON.parse(data?.selected_calendars) || []);

    useEffect(() => {
        setIsLoading(true);
        sendGetRequest({
            url: `/google-list-calendars/${data?.id}/${getAdminBusinessId()}`,
            onSuccess: function (response) {
              setCalendars(response.calendars);
              setIsLoading(false);
            },
            onError: function (error) {
                setIsLoading(false);
            },
        });    
    }, []);

    /**
     * on Calendar tick change handler
     * @param {*} browserEvent 
     * @param {*} cal 
     */
    const onCalendarTickChange = (browserEvent, cal) => {
        console.log('cal', cal, browserEvent.target.checked);
        const selectedCals = [...selectedCalendars];
        const found = selectedCals.some((calendar) => calendar?.id === cal?.id);
        if (browserEvent.target.checked) {
            if (!found) {
                selectedCals.push(cal);
                setSelectedCalendars(selectedCals);
            }
        } else {
            if (found) {
                setSelectedCalendars(selectedCals.filter((calendar) => calendar?.id !== cal.id));
            }
        }
    }

    /**
     * 
     * @returns 
     */
    const onUpdate = () => {
        if (selectedCalendars.length === 0) {
            return notification.error("Please select at least one calendar");
        }

        if (selectedCalendars.length > 4) {
            return notification.error("You cant select more than 4 calendars");
        }
        props.onUpdateCheckForConflictCals(selectedCalendars);
        //console.log('select', selectedCalendars);
    }

    return (
        <Modal className="cal_int_popup conflict_popup" isOpen={showConflictModal} toggle={hideConflictModal}>
        <ModalBody>
          <h2 className="title">How Should We Check For Conflicts?</h2>
          <FormGroup tag="fieldset" className="event-form-group wdout_field">
            <label>
              <img src={GoogleCalendarIcon} alt="Ms Office Icon" />
              {JSON.parse(data?.meta_data)?.email}
            </label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 px-0">
            <h4 className="mb-4">Check These Places For Conflicts</h4>
            {calendars.map((cal, index) => {
                return (
                    <div className="form-group event-form-group">
                        <div className="form-check-box">
                            <input 
                                id={`cal-${index}`} 
                                type="checkbox" 
                                value={cal?.id} 
                                onChange={(event) => onCalendarTickChange(event, cal)}
                                checked={selectedCalendars.some(
                                    (selectedCalendar) => selectedCalendar?.id === cal?.id
                                  )}
                            ></input>
                            <label 
                            htmlFor={`cal-${index}`}
                            >
                            <span></span>
                                {cal?.summary}
                            </label>
                        </div>
                    </div>
                );
            })}
          </div>
          <div className="col-md-6 ps-md-0">
            <button className="btn btn-outline" onClick={hideConflictModal}>Cancel</button>
          </div>
          <div className="col-md-6 pe-md-0 mt-4 mt-md-0">
            <button className="btn btn-app" onClick={onUpdate}>Update</button>
          </div>
        </ModalFooter>
      </Modal>
    );
}

export default connect(null, {
    sendGetRequest,
  })(CheckForConflictModal);
  