import React, { Component } from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import SidebarProgress from "./SidebarProgress";
import Loader from "../../../../components/Loader/Loader";
import { getCompanySettingServices } from "../../../../actions/companyActions";
import {
  saveStaff,
  isUsernameExist,
  isEmailExist,
} from "../../../../actions/staffActions";
import { connect } from "react-redux";
import {
  notification,
  IMAGE_BASE_URL,
  emailRegex,
} from "../../../../utils/utility";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getAdminBusinessId } from "../../../../utils/authHelper";
import FormField from "../../../../components/FormField/FormField";
import DatePickerField from "../../../../components/DatePickerField/DatePickerField";
import { withRouter } from "../../../../utils/utility";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const validation = Yup.object().shape({
  username: Yup.string().required("Username is required."),
  first_name: Yup.string().required("First name is required."),
  last_name: Yup.string().required("Last name is required."),
  timezone_id: Yup.string().required("Timezone is required."),
  email: Yup.string()
    .matches(emailRegex, "Please enter valid email")
    .required("Email is required"),
});

let initialValues = {
  username: "",
  first_name: "",
  last_name: "",
  status: "D",
  update_schedule_status: "D",
  email: "",
  timezone_id: "",
};

const informationValidation = Yup.object().shape({
  address_line_1: Yup.string().required("Address Line 1 is required."),
  //address_line_2: Yup.string().required("Address Line 2 is required."),
  contact_no: Yup.string().required("Contact Number is required."),
  city: Yup.string().required("City is required."),
  language_id: Yup.string().required("Language is required."),
  state: Yup.string().required("State is required."),
  gender: Yup.string().required("Gender is required."),
  country_id: Yup.string().required("Country is required."),
  date_of_birth: Yup.string().required("Date of birth is required."),
});

let informationInitialValues = {
  address_line_1: "",
  address_line_2: "",
  contact_no: "",
  website: "",
  city: "",
  language_id: "",
  state: "",
  gender: "",
  country_id: "",
  date_of_birth: "",
};

let socialInitialValues = {
  twitter_link: "",
  github_link: "",
  facebook_link: "",
  codepin_link: "",
  instagram_link: "",
  slack_link: "",
};

class Create extends Component {
  state = {
    step: 1,
    pageLoading: false,
    isLoading: false,
    languages: [],
    countries: [],
    timezones: [],
    date_of_birth: null,
    profileImageUrl: false,
    selectedFile: undefined,
    isImageChangedEventCalled: false,
    data: {
      username: "",
      first_name: "",
      last_name: "",
      status: "D",
      update_schedule_status: "D",
      email: "",
      timezone_id: "",
      profile_image: "",
      address_line_1: "",
      address_line_2: "",
      date_of_birth: "",
      phone: "",
      website: "",
      city: "",
      language_id: "",
      state: "",
      gender: "",
      country_id: "",
      communication_preferences: [],
      twitter_page_url: "",
      github_page_url: "",
      facebook_page_url: "",
      codepin_page_url: "",
      instagram_page_url: "",
      slack_page_url: "",
      business_id: getAdminBusinessId(),
    },
  };

  /**
   * executed when component is inserted in dom
   */
  componentDidMount() {
    const { getCompanySettingServices } = this.props;

    this.setState({
      pageLoading: true,
    });

    const _this = this;

    getCompanySettingServices({
      data: {},
      onSuccess: function (data) {
        _this.setState({
          pageLoading: false,
        });
        _this.setState({
          languages: data.languages,
          countries: data.countries,
          timezones: data.timezones,
        });
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  }

  onSelectFile = (e) => {
    this.setState({
      isImageChangedEventCalled: true,
    });

    if (!e.target.files || e.target.files.length === 0) {
      this.setState({
        selectedFile: undefined,
      });
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({
      selectedFile: e.target.files[0],
      profileImageUrl: objectUrl,
    });
  };

  /**
   * on cancel button handler
   */
  onCancel = () => {
    const { router } = this.props;
    const { navigate } = router || {};
    navigate("/admin/staff");
  };

  /**
   * Format date
   * @param {*} dated
   */
  formatDate = (dated) => {
    dated = new Date(dated);
    return (
      dated.getFullYear() + "/" + (dated.getMonth() + 1) + "/" + dated.getDate()
    );
  };

  render() {
    const {
      step,
      pageLoading,
      languages,
      countries,
      timezones,
      profileImageUrl,
      isImageChangedEventCalled,
      isLoading,
    } = this.state;
    const _this = this;

    return (
      <>
        <Loader isShowLoader={pageLoading} />
        <div className="create-event-wrapper">
          <div className="create-event-container">
            <Row className="flex-column-reverse flex-lg-row">
              <Col lg="9">
                <div className="event-card">
                  <div className="event-card-head">
                    <h3 className="event-title">Staff</h3>
                  </div>
                  {step === 1 ? (
                    <Formik
                      validationSchema={validation}
                      initialValues={initialValues}
                      enableReinitialize
                      onSubmit={async (data, formikHelpers) => {
                        const _this = this;

                        const { isUsernameExist, isEmailExist } = _this.props;

                        _this.setState({
                          pageLoading: true,
                          isLoading: true,
                        });

                        const response = await isUsernameExist({
                          data: {
                            username: data.username,
                            id: false,
                          },
                        });

                        if (response.data == "1") {
                          formikHelpers.setFieldError(
                            "username",
                            "Username already exist."
                          );
                          _this.setState({
                            pageLoading: false,
                            isLoading: false,
                          });

                          return false;
                        }

                        const resData = await isEmailExist({
                          data: {
                            email: data.email,
                            id: false,
                          },
                        });

                        if (resData.data == "1") {
                          formikHelpers.setFieldError(
                            "email",
                            "Email already exist."
                          );
                          _this.setState({
                            pageLoading: false,
                            isLoading: false,
                          });
                          return false;
                        }

                        _this.setState({
                          data: {
                            ..._this.state.data,
                            username: data.username,
                            first_name: data.first_name,
                            last_name: data.last_name,
                            status: data.status,
                            email: data.email,
                            timezone_id: data.timezone_id,
                            update_schedule_status: data.update_schedule_status,
                          },
                          step: 2,
                          pageLoading: false,
                          isLoading: false,
                        });
                      }}
                    >
                      {(formProps) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          setFieldValue,
                        } = formProps;

                        return (
                          <Form>
                            <div className="event-card-body">
                              <Row>
                                {/* Image Uploader */}
                                <Col sm="12">
                                  <div class="rd_profileimagepart">
                                    <div className="rd_imagethincontg">
                                      <p>Profile Image</p>
                                      <div className="rd_imagecontprofile">
                                        {profileImageUrl ? (
                                          <img
                                            src={
                                              !isImageChangedEventCalled
                                                ? IMAGE_BASE_URL +
                                                  profileImageUrl
                                                : profileImageUrl
                                            }
                                            alt=""
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="rd_imagethincontgbtnt">
                                      <p>
                                        Allowed JPG, GIF or PNG. Max size of
                                        800kB
                                      </p>
                                      <FormGroup className="has-icon-left position-relative">
                                        <label
                                          className="btn btn-outline mb-0"
                                          outline
                                        >
                                          Upload Photo
                                          <Input
                                            type="file"
                                            accept=".png,.jpg"
                                            onChange={this.onSelectFile}
                                            name="profile_image"
                                            id="uploadImg"
                                            hidden
                                          />
                                        </label>
                                      </FormGroup>
                                    </div>
                                  </div>
                                </Col>
                                {/* UserName Field */}
                                <Col md="6">
                                  <FormGroup>
                                    <Label for="username">
                                      Username
                                      <span className="RequiredMark">*</span>
                                    </Label>
                                    <FormField
                                      type="text"
                                      name="username"
                                      placeholder="Username"
                                      value={values.username}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: { name: "username", value },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                {/* First Name */}
                                <Col md="6">
                                  <FormGroup>
                                    <Label for="first_name">
                                      First Name
                                      <span className="RequiredMark">*</span>
                                    </Label>
                                    <FormField
                                      type="text"
                                      name="first_name"
                                      placeholder="First Name"
                                      value={values.first_name}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: { name: "first_name", value },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                {/* Last Name */}
                                <Col md="6">
                                  <FormGroup>
                                    <Label for="last_name">
                                      Last Name
                                      <span className="RequiredMark">*</span>
                                    </Label>
                                    <FormField
                                      type="text"
                                      name="last_name"
                                      placeholder="Last Name"
                                      value={values.last_name}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: { name: "last_name", value },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                {/* Email */}
                                <Col md="6">
                                  <FormGroup>
                                    <Label for="last_name">
                                      Email
                                      <span className="RequiredMark">*</span>
                                    </Label>
                                    <FormField
                                      type="text"
                                      name="email"
                                      placeholder="Email"
                                      value={values.email}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: { name: "email", value },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                {/* Timezone */}
                                <Col md="6">
                                  <FormGroup>
                                    <Label for="timezone">
                                      Timezone
                                      <span className="RequiredMark">*</span>
                                    </Label>
                                    <FormField
                                      placeholder="Select Timezone"
                                      showPlaceholder={true}
                                      type="select"
                                      name="timezone_id"
                                      errors={errors}
                                      touched={touched}
                                      value={values.timezone_id.toString()}
                                      options={timezones}
                                    />
                                  </FormGroup>
                                </Col>
                                {/* Checkboxes */}
                                <Col md="6" className="align-self-end">
                                  {/* Old One */}
                                  {/* <FormGroup>
                                    <Label for="update-schedule-status">
                                      Can Update Avaialbility
                                    </Label>

                                    <ToggleField
                                      labelText=""
                                      value={values.update_schedule_status}
                                      onChange={(value) => {
                                        handleChange({
                                          target: {
                                            name: "update_schedule_status",
                                            value,
                                          },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for="status">Status</Label>

                                    <ToggleField
                                      labelText=""
                                      value={values.status}
                                      onChange={(value) => {
                                        handleChange({
                                          target: {
                                            name: "status",
                                            value,
                                          },
                                        });
                                      }}
                                    />
                                  </FormGroup> */}
                                  {/* Can Update Availablity */}
                                  <div className="toggle_field">
                                    <div className="box_content p-0 w-100">
                                      <div className="form-check form-switch mw-100">
                                        <label
                                          className="form-check-label"
                                          htmlFor="update-schedule-status"
                                        >
                                          Can Update Avaialbility
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="update-schedule-status"
                                          name="update_schedule_status"
                                          checked={
                                            values.update_schedule_status ===
                                            "E"
                                          }
                                          onChange={(event) => {
                                            handleChange({
                                              target: {
                                                name: "update_schedule_status",
                                                value: event.target.checked
                                                  ? "E"
                                                  : "D",
                                              },
                                            });
                                          }}
                                          style={{ float: "right" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {/* Status */}
                                  <div className="toggle_field">
                                    <div className="box_content p-0 w-100">
                                      <div className="form-check form-switch mw-100">
                                        <label
                                          className="form-check-label"
                                          htmlFor="status"
                                        >
                                          Status
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="status"
                                          name="status"
                                          checked={values.status === "E"}
                                          onChange={(event) => {
                                            handleChange({
                                              target: {
                                                name: "status",
                                                value: event.target.checked
                                                  ? "E"
                                                  : "D",
                                              },
                                            });
                                          }}
                                          style={{ float: "right" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Col>

                                <Col md="6"></Col>
                              </Row>
                            </div>

                            <div className="event-card-footer">
                              <Row className="justify-content-between">
                                <Col md="4">
                                  <Button
                                    className="btn btn-danger"
                                    type="button"
                                    disabled={isLoading}
                                    onClick={this.onCancel}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                                <Col md="4" className="mt-4 mt-md-0">
                                  <Button
                                    type="submit"
                                    className="btn btn-app"
                                    disabled={isLoading}
                                  >
                                    Next Step
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  ) : null}

                  {step === 2 ? (
                    <Formik
                      validationSchema={informationValidation}
                      initialValues={informationInitialValues}
                      enableReinitialize
                      onSubmit={(data) => {
                        const _this = this;
                        _this.setState({
                          data: {
                            ..._this.state.data,
                            date_of_birth: data.date_of_birth
                              ? _this.formatDate(data.date_of_birth)
                              : null,
                            address_line_1: data.address_line_1,
                            address_line_2: data.address_line_2,
                            city: data.city,
                            contact_no: data.contact_no,
                            country_id: data.country_id,
                            gender: data.gender,
                            language_id: data.language_id,
                            state: data.state,
                            website: data.website,
                          },
                          step: 3,
                        });
                      }}
                    >
                      {(formProps) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          setFieldValue,
                          setFieldTouched,
                        } = formProps;

                        return (
                          <Form>
                            <div className="event-card-body">
                              <Row>
                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="address_line_1">
                                      Address Line 1
                                      <span className="RequiredMark">*</span>
                                    </Label>
                                    <FormField
                                      type="text"
                                      name="address_line_1"
                                      placeholder="Address Line 1"
                                      value={values.address_line_1}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: {
                                            name: "address_line_1",
                                            value,
                                          },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="address_line_2">
                                      Address Line 2
                                    </Label>
                                    <FormField
                                      type="text"
                                      name="address_line_2"
                                      placeholder="Address Line 2"
                                      value={values.address_line_2}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: {
                                            name: "address_line_2",
                                            value,
                                          },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="last_name">
                                      Date of Birth
                                      <span className="RequiredMark">*</span>
                                    </Label>
                                    <DatePickerField
                                      errors={errors}
                                      touched={touched}
                                      name="date_of_birth"
                                      value={values.date_of_birth}
                                      format="dd-MM-yyyy"
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="contact_no">
                                      Contact No
                                      <span className="RequiredMark">*</span>
                                    </Label>
                                    <PhoneInput
                                      containerClass="custom_phn_inp"
                                      country={"us"}
                                      value={values.contact_no}
                                      onBlur={() => {
                                        setFieldTouched(`contact_no`, true);
                                      }}
                                      onChange={(value) => {
                                        handleChange({
                                          target: { name: "contact_no", value },
                                        });
                                      }}
                                    />
                                    {errors[`contact_no`] &&
                                      touched[`contact_no`] && (
                                        <FormFeedback className="d-block">
                                          {errors[`contact_no`]}
                                        </FormFeedback>
                                      )}
                                    {/* <FormField
                                      type="text"
                                      name="contact_no"
                                      placeholder="Contact No"
                                      value={values.contact_no}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: { name: "contact_no", value },
                                        });
                                      }}
                                    /> */}
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="website">Website</Label>
                                    <FormField
                                      type="text"
                                      name="website"
                                      placeholder="Website"
                                      value={values.website}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: { name: "website", value },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="city">
                                      City
                                      <span className="RequiredMark">*</span>
                                    </Label>
                                    <FormField
                                      type="text"
                                      name="city"
                                      placeholder="City"
                                      value={values.city}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: { name: "city", value },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="city">
                                      Language
                                      <span className="RequiredMark">*</span>
                                    </Label>
                                    <FormField
                                      placeholder="Select language"
                                      showPlaceholder={true}
                                      type="select"
                                      name="language_id"
                                      errors={errors}
                                      touched={touched}
                                      value={values.language_id.toString()}
                                      options={languages}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="state">
                                      State
                                      <span className="RequiredMark">*</span>
                                    </Label>
                                    <FormField
                                      type="text"
                                      name="state"
                                      placeholder="State"
                                      value={values.state}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: { name: "state", value },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="city">
                                      Gender
                                      <span className="RequiredMark">*</span>
                                    </Label>
                                    <FormField
                                      placeholder="Select Gender"
                                      showPlaceholder={true}
                                      type="select"
                                      name="gender"
                                      errors={errors}
                                      touched={touched}
                                      value={values.gender.toString()}
                                      options={[
                                        {
                                          value: "M",
                                          label: "Male",
                                        },
                                        {
                                          value: "F",
                                          label: "Female",
                                        },
                                      ]}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="country_id">
                                      Country
                                      <span className="RequiredMark">*</span>
                                    </Label>
                                    <FormField
                                      placeholder="Select Country"
                                      showPlaceholder={true}
                                      type="select"
                                      name="country_id"
                                      errors={errors}
                                      touched={touched}
                                      value={values.country_id.toString()}
                                      options={countries}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="12" md="12" sm="12">
                                  <FormGroup>
                                    <Label
                                      className="d-block mb-4"
                                      for="communication"
                                    >
                                      Communication
                                    </Label>
                                    <div className="toggle_field flex-column flex-md-row">
                                      <div className="box_content p-0 col-md-2">
                                        <div className="form-check form-switch mw-100">
                                          <label
                                            className="form-check-label"
                                            htmlFor="update-schedule-status"
                                          >
                                            Email
                                          </label>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="update-schedule-status"
                                            name="email"
                                            checked={_this.state.data.communication_preferences.includes(
                                              "mail"
                                            )}
                                            onChange={(event) => {
                                              const comPrefName = "mail";
                                              const communicationPreferences = [
                                                ..._this.state.data
                                                  .communication_preferences,
                                              ];
                                              if (event.target.checked) {
                                                if (
                                                  !communicationPreferences.includes(
                                                    comPrefName
                                                  )
                                                ) {
                                                  communicationPreferences.push(
                                                    comPrefName
                                                  );
                                                }
                                              } else {
                                                if (
                                                  communicationPreferences.includes(
                                                    comPrefName
                                                  )
                                                ) {
                                                  let itemIndex =
                                                    communicationPreferences.indexOf(
                                                      comPrefName
                                                    );
                                                  if (itemIndex !== -1) {
                                                    communicationPreferences.splice(
                                                      itemIndex,
                                                      1
                                                    );
                                                  }
                                                }
                                              }
                                              _this.setState({
                                                data: {
                                                  ..._this.state.data,
                                                  communication_preferences:
                                                    communicationPreferences,
                                                },
                                              });
                                            }}
                                            style={{ float: "right" }}
                                          />
                                        </div>
                                      </div>
                                      <div className="box_content p-0 col-md-2 offset-md-2">
                                        <div className="form-check form-switch mw-100">
                                          <label
                                            className="form-check-label"
                                            htmlFor="sms"
                                          >
                                            SMS
                                          </label>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="sms"
                                            name="sms"
                                            checked={_this.state.data.communication_preferences.includes(
                                              "sms"
                                            )}
                                            onChange={(event) => {
                                              const comPrefName = "sms";
                                              const communicationPreferences = [
                                                ..._this.state.data
                                                  .communication_preferences,
                                              ];
                                              if (event.target.checked) {
                                                if (
                                                  !communicationPreferences.includes(
                                                    comPrefName
                                                  )
                                                ) {
                                                  communicationPreferences.push(
                                                    comPrefName
                                                  );
                                                }
                                              } else {
                                                if (
                                                  communicationPreferences.includes(
                                                    comPrefName
                                                  )
                                                ) {
                                                  let itemIndex =
                                                    communicationPreferences.indexOf(
                                                      comPrefName
                                                    );
                                                  if (itemIndex !== -1) {
                                                    communicationPreferences.splice(
                                                      itemIndex,
                                                      1
                                                    );
                                                  }
                                                }
                                              }
                                              _this.setState({
                                                data: {
                                                  ..._this.state.data,
                                                  communication_preferences:
                                                    communicationPreferences,
                                                },
                                              });
                                            }}
                                            style={{ float: "right" }}
                                          />
                                        </div>
                                      </div>
                                      <div className="box_content p-0 col-md-2 offset-md-2">
                                        <div className="form-check form-switch mw-100">
                                          <label
                                            className="form-check-label"
                                            htmlFor="phone"
                                          >
                                            Phone
                                          </label>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="phone"
                                            name="phone"
                                            checked={_this.state.data.communication_preferences.includes(
                                              "phone"
                                            )}
                                            onChange={(event) => {
                                              const comPrefName = "phone";
                                              const communicationPreferences = [
                                                ..._this.state.data
                                                  .communication_preferences,
                                              ];
                                              if (event.target.checked) {
                                                if (
                                                  !communicationPreferences.includes(
                                                    comPrefName
                                                  )
                                                ) {
                                                  communicationPreferences.push(
                                                    comPrefName
                                                  );
                                                }
                                              } else {
                                                if (
                                                  communicationPreferences.includes(
                                                    comPrefName
                                                  )
                                                ) {
                                                  let itemIndex =
                                                    communicationPreferences.indexOf(
                                                      comPrefName
                                                    );
                                                  if (itemIndex !== -1) {
                                                    communicationPreferences.splice(
                                                      itemIndex,
                                                      1
                                                    );
                                                  }
                                                }
                                              }
                                              _this.setState({
                                                data: {
                                                  ..._this.state.data,
                                                  communication_preferences:
                                                    communicationPreferences,
                                                },
                                              });
                                            }}
                                            style={{ float: "right" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                            <div className="event-card-footer">
                              <Row className="justify-content-between">
                                <Col md="4">
                                  <Button
                                    className="btn btn-danger"
                                    type="button"
                                    disabled={isLoading}
                                    onClick={this.onCancel}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                                <Col md="4" className="my-4 my-md-0">
                                  <Button
                                    className="btn btn-success"
                                    type="button"
                                    disabled={isLoading}
                                    onClick={() => {
                                      this.setState({ step: 1 });
                                    }}
                                  >
                                    Back
                                  </Button>
                                </Col>
                                <Col md="4">
                                  <Button
                                    type="submit"
                                    className="btn btn-app"
                                    disabled={isLoading}
                                  >
                                    Next Step
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  ) : null}

                  {step === 3 ? (
                    <Formik
                      initialValues={socialInitialValues}
                      enableReinitialize
                      onSubmit={(data) => {
                        const _this = this;
                        const { saveStaff } = _this.props;
                        _this.setState({
                          pageLoading: true,
                          isLoading: true,
                        });

                        saveStaff({
                          data: {
                            ..._this.state.data,
                            communication_preferences:
                              _this.state.data.communication_preferences.toString(),
                            profile_image: _this.state.selectedFile,
                            twitter_page_url: data.twitter_link,
                            github_page_url: data.github_link,
                            facebook_page_url: data.facebook_link,
                            codepin_page_url: data.codepin_link,
                            instagram_page_url: data.instagram_link,
                            slack_page_url: data.slack_link,
                          },
                          onSuccess: () => {
                            _this.setState({
                              isLoading: false,
                              pageLoading: false,
                            });
                            notification.success("Staff created successfully");
                            const { router } = this.props;
                            const { navigate } = router || {};
                            navigate("/admin/staff");
                          },
                          onError: (error) => {
                            _this.setState({
                              isLoading: false,
                              pageLoading: false,
                              errorMessage: error,
                            });
                          },
                        });
                      }}
                    >
                      {(formProps) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          setFieldValue,
                        } = formProps;

                        return (
                          <Form>
                            <div className="event-card-body">
                              <Row>
                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="twitter">Twitter</Label>
                                    <FormField
                                      type="text"
                                      name="twitter_link"
                                      placeholder="Twitter"
                                      value={values.twitter_link}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: {
                                            name: "twitter_link",
                                            value,
                                          },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="github">Github</Label>
                                    <FormField
                                      type="text"
                                      name="github_link"
                                      placeholder="Github"
                                      value={values.github_link}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: {
                                            name: "github_link",
                                            value,
                                          },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="facebook">Facebook</Label>
                                    <FormField
                                      type="text"
                                      name="facebook_link"
                                      placeholder="Facebook"
                                      value={values.facebook_link}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: {
                                            name: "facebook_link",
                                            value,
                                          },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="codepin">Codepin</Label>
                                    <FormField
                                      type="text"
                                      name="codepin_link"
                                      placeholder="Codepin"
                                      value={values.codepin_link}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: {
                                            name: "codepin_link",
                                            value,
                                          },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="instagram">Instagram</Label>
                                    <FormField
                                      type="text"
                                      name="instagram_link"
                                      placeholder="Instagram"
                                      value={values.instagram_link}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: {
                                            name: "instagram_link",
                                            value,
                                          },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <FormGroup>
                                    <Label for="slack">Slack</Label>
                                    <FormField
                                      type="text"
                                      name="slack_link"
                                      placeholder="Slack"
                                      value={values.slack_link}
                                      errors={errors}
                                      touched={touched}
                                      onChange={({ target }) => {
                                        const { value } = target;
                                        handleChange({
                                          target: { name: "slack_link", value },
                                        });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>

                            <div className="event-card-footer">
                              <Row className="justify-content-between">
                                <Col md="4">
                                  <Button
                                    className="btn btn-danger"
                                    type="button"
                                    disabled={isLoading}
                                    onClick={this.onCancel}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                                <Col md="4" className="my-4 my-md-0">
                                  <Button
                                    className="btn btn-success"
                                    type="button"
                                    disabled={isLoading}
                                    onClick={() => {
                                      this.setState({ step: 2 });
                                    }}
                                  >
                                    Back
                                  </Button>
                                </Col>
                                <Col md="4">
                                  <Button
                                    type="submit"
                                    className="btn btn-app"
                                    disabled={isLoading}
                                  >
                                    Finish
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  ) : null}
                </div>
              </Col>
              <Col lg="3">
                <SidebarProgress step={step} />
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, {
  getCompanySettingServices,
  saveStaff,
  isUsernameExist,
  isEmailExist,
})(withRouter(Create));
