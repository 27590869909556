import React, { useState, useEffect } from "react";
import GoogleCalendarSVG from "../../../assets/calendar-integration/images/google_calendar.svg";
import DisconnectSVG from "../../../assets/calendar-integration/images/disconnected-chains.svg";
import { connect } from "react-redux";
import { disconnect } from "../../../actions/admin/calendar-integration-actions";
import Loader from "../../../components/Loader/Loader";
import DisconnectModal from "./disconnect-modal";
import CheckForConflictModal from "./check-for-conflict-modal";
import AddToCalendarModal from "./add-to-calendar-modal";
import OutlookCheckForConflictModal from "./outlook/check-for-conflict-modal";
import OutlookIcon from "../../../assets/icons/outlook-icon.png";
import EditIcon from "../../../assets/icons/edit_icon.svg";
import { GOOGLE_CALENDAR_ID, OUTLOOK_CALENDAR_ID } from "../../../data/integration-app";
import { sendPostRequest } from "../../../actions/app-actions";
import { notification } from "../../../utils/utility";
import isEmpty from "../../../validation/is-empty";

const ConnectedCalendars = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSet, setDataSet] = useState([]);
  const [calendarData, setCalendarData] = useState(null);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showConflictModal, setShowConflictModal] = useState(false);
  const [showOutlookConflictModal, setShowOutlookConflictModal] = useState(false);
  const [showAddToCalendarModal, setShowAddToCalendarModal] = useState(false);
  const [integrationId, setIntegrationId] = useState("");
  
  
  useEffect(() => {
    setDataSet(props.data);
  }, []);


  /**
   * on add to calendar handler
   * @param {*} data 
   */
  const onAddToCalendarHandler = (data) => {
    setCalendarData(data);
    setIntegrationId(data?.id);
    setShowAddToCalendarModal(true);
  }

  

  /**
   * hide Add to Calendar modal
   */
  const hideAddToCalendarModal = () => {
    setIntegrationId("");
    setShowAddToCalendarModal(false);
  }

  /**
   * on check for conflict handler
   * @param {*} data 
   */
  const onCheckForConflictHandler = (data) => {
    setCalendarData(data);
    setIntegrationId(data?.id);
    if (parseInt(data.app_id) === GOOGLE_CALENDAR_ID) {
      setShowConflictModal(true);
    } else if (parseInt(data.app_id)  === OUTLOOK_CALENDAR_ID) {
      setShowOutlookConflictModal(true);  
    }
  }


  /**
   * on update add to calendar
   * @param {*} data 
   */
  const onUpdateAddToCalendar = (data) => {
    const { sendPostRequest } = props;
    setIsLoading(true);
    sendPostRequest({
      url: `/add-to-calendar`,
      data: data,
      onSuccess: function (response) {
        const dataSetList = [...dataSet];
        const found = dataSetList.some((listItem) => listItem.id === data.app_integration_id);
        if (found) {
          const dataSetIndex = dataSetList.findIndex((el) => el.id === data.app_integration_id);
          
          // make previous config record to null, so we dont show on ui
          const preConnRecordIndex = dataSetList.findIndex((el) => el.id === integrationId);
          if (preConnRecordIndex !== -1) {
            dataSetList[preConnRecordIndex].config = null;
          }
          
          
          dataSetList[dataSetIndex].config = {
            ...dataSetList[dataSetIndex].config,
            add_new_event_status: data.add_new_event_status,
            selected_calendar_id: data.selected_calendar_id,
            delete_event_status: data.delete_event_status
          }

          setDataSet(dataSetList);
          setShowAddToCalendarModal(false);
        }
        setIsLoading(false);
        notification.success("Record successfully updated.");
      },
      onError: function (error) {
        setIsLoading(false);
        console.log("error", error);
      },
    });
  }

  /**
   * on update conflict calendars
   * @param {*} selectedCals 
   */
  const onUpdateCheckForConflictCals = (selectedCals) => {
    const { sendPostRequest } = props;
    setIsLoading(true);
    sendPostRequest({
      url: `/admin-selected-calendars`,
      data: {
        integrationId,
        selectedCals,
      },
      onSuccess: function (response) {
        const dataSetList = [...dataSet];
        const found = dataSetList.some((listItem) => listItem.id === integrationId);
        if (found) {
          const dataSetIndex = dataSetList.findIndex((el) => el.id === integrationId);
          dataSetList[dataSetIndex].selected_calendars = JSON.stringify(selectedCals);
          
          if (dataSetList[dataSetIndex].app_id === GOOGLE_CALENDAR_ID) {
            setShowConflictModal(false);
          } else if (dataSetList[dataSetIndex].app_id === OUTLOOK_CALENDAR_ID) {
            setShowOutlookConflictModal(false);
          }
          setDataSet(dataSetList);
        }
        setIsLoading(false);
        
        notification.success("Google Calendar List updated successfully.");
      },
      onError: function (error) {
        setIsLoading(false);
        console.log("error", error);
      },
    });

  }


  /**
   * hide check for conflict modal
   */
  const hideOutlookCheckForConflictModal = () => {
    setIntegrationId("");
    setShowOutlookConflictModal(false);
  }

  /**
   * hide check for conflict modal
   */
  const hideCheckForConflictModal = () => {
    setIntegrationId("");
    setShowConflictModal(false);
  }

  /**
   * disconnect
   * @param {*} id
   */
  const disconnect = (id) => {
    setIntegrationId(id);
    setShowDisconnectModal(true);
  };

  /**
   * hide disconnect modal
   */
  const hideDisconnectModal = () => {
    setIntegrationId("");
    setShowDisconnectModal(false);
  };

  /**
   * Get Integration connection icon
   */
  const getConnectionIcon = (appId) => {
    switch (appId) {
      case GOOGLE_CALENDAR_ID:
          return GoogleCalendarSVG;
      default:
          return OutlookIcon;
    }
  } 

  /**
   * Get Integration connection name
   * @param {*} type
   * @returns
   */
  const getConnectionName = (appId) => {
    switch (appId) {
      case GOOGLE_CALENDAR_ID:
        return "Google Calendar";
      case OUTLOOK_CALENDAR_ID:
        return "Outlook Calendar";
      default:
        return "Google Calendar";
    }
  };

  /**
   * disconnect from calendar
   * @param int id
   */
  const disconnectFromCalendar = (id) => {
    const { disconnect, setIntegrationDataSet, setCalendarIntegrationView } = props;
    setIsLoading(true);
    disconnect({
      data: {
        id,
      },
      onSuccess: function (message, success) {
        const processedDataSet = dataSet.filter(function (obj) {
          return obj.id !== id;
        });
        if (processedDataSet.length === 0) {
          setIntegrationDataSet(processedDataSet);
          setDataSet(processedDataSet);
          setCalendarIntegrationView("2");
        } else {
          setIntegrationDataSet(processedDataSet);
          setDataSet(processedDataSet);
        }
        setIsLoading(false);
      },
      onError: function (error) {
        console.log("error", error);
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Loader isShowLoader={isLoading} />
      {showDisconnectModal && (
        <DisconnectModal
          recordId={integrationId}
          showDisconnectModal={showDisconnectModal}
          hideDisconnectModal={hideDisconnectModal}
          disconnect={disconnectFromCalendar}
        />
      )}
      {showConflictModal && (
        <CheckForConflictModal
          data={calendarData} 
          showConflictModal={showConflictModal}
          hideConflictModal={hideCheckForConflictModal}
          setIsLoading={setIsLoading}
          onUpdateCheckForConflictCals={onUpdateCheckForConflictCals}
        />
      )}
      {showOutlookConflictModal && (
        <OutlookCheckForConflictModal 
          data={calendarData}
          showConflictModal={showOutlookConflictModal}
          hideConflictModal={hideOutlookCheckForConflictModal}
          setIsLoading={setIsLoading}
          onUpdateCheckForConflictCals={onUpdateCheckForConflictCals}
        />
      )}
      {showAddToCalendarModal && (
        <AddToCalendarModal
          showAddToCalendarModal={showAddToCalendarModal}
          hideAddToCalendarModal={hideAddToCalendarModal}
          setIsLoading={setIsLoading}
          data={calendarData}
          onUpdateAddToCalendar={onUpdateAddToCalendar}
          dataSet={dataSet}
          getConnectionIcon={getConnectionIcon}
        />
      )}
      <section className="integreted-calenders calend-integ w-100">
        <div className="bg-div">
          <div className="section-title d-flex justify-content-between align-items-center">
            <h2>
              My Calendar Account
              <span>
                {dataSet.length}
                <span>/</span>3
              </span>
            </h2>
            <a
              href="#"
              className="bootstrap-default-btn btn-calender-account"
              onClick={() => {
                props.setCalendarIntegrationView("2");
              }}
            >
              Add Calendar Account
            </a>
          </div>
          <div className="temp-row">
            <div className="active-calenders">
              <div className="container-fluid">
                <div class="row">
                  {dataSet.map((data, index) => {
                    return (<>
                      <div className="col-xl-6 ps-xl-0">
                        <div className="calender-party-col flex-column flex-md-row">
                          <div className="calender-party-details">
                            <img
                              src={getConnectionIcon(data.app_id)}
                              alt="Google Calender"
                            />
                            <div className="calender-name">
                              <p>{getConnectionName(data.app_id)}</p>
                              <p className="status">
                                <span className="ml-0">{JSON.parse(data?.meta_data)?.email}</span>
                              </p>
                            </div>
                          </div>
                          <button
                            class="bootstrap-default-btn btn-diconnect"
                            onClick={() => disconnect(data.id)}
                          >
                            <span>Disconnect</span>
                            <img src={DisconnectSVG} alt="" />
                          </button>
                        </div>
                      </div>
                    </>);
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        
          <div className="bg-div mt-4">
            <div className="cal_integ_other">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-xl-6">
                    <div className="txt">
                      <h3>Check for conflicts</h3>
                      <p>Set the calendar(s) to check for conflicts to prevent double bookings</p>
                    </div>
                  </div>
                  <div className="col-xl-6 mt-4 mt-xl-0">
                    {/* For multiple connected connection needs to duplicate calendar-party-col container */}

                    {dataSet.map((data, index) => {
                      return (
                        <div className="calender-party-col">
                          <div className="calender-party-details">
                            <img
                              src={getConnectionIcon(data.app_id)}
                              alt="Google Calender"
                            />
                            <div className="calender-name">
                              <p>Check {JSON.parse(data?.meta_data)?.email}</p>
                              <p className="status">
                                <span className="ml-0">
                                  <ul>
                                    {(JSON.parse(data?.selected_calendars) || []).map((selectedCal) => {
                                      if (data.app_id === GOOGLE_CALENDAR_ID && data.calendars) {
                                        const found = data.calendars.find((calendar) => calendar?.id === selectedCal?.id);
                                        if (found) {
                                          return (<li>{found?.summary}</li>);
                                        }
                                      } else {
                                        return (<li>{selectedCal?.summary}</li>);
                                      }
                                    })}
                                  </ul>
                                </span>
                              </p>
                            </div>
                          </div>
                          <button
                            class="bootstrap-default-btn"
                            onClick={() => onCheckForConflictHandler(data)}
                          >
                            <img src={EditIcon} alt="edit icon" style={{ maxWidth: 17 }} />
                          </button>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            {/* Add to Calendar Part */}
            <div className="cal_integ_other mt-4">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-xl-6">
                    <div className="txt">
                      <h3>Add to calendar</h3>
                      <p>Set the calendar you would like to add new events to as they're scheduled.</p>
                    </div>
                  </div>
                  <div className="col-xl-6 mt-4 mt-xl-0">
                  {dataSet.filter((connection, index) => !isEmpty(connection.config)).map((data, index) => {

                      let selectedCalendar = null;
                      if ((data.app_id === GOOGLE_CALENDAR_ID || data.app_id === OUTLOOK_CALENDAR_ID) && data.config) {
                        const found = data.calendars.find((calendar) => calendar?.id === data?.config?.selected_calendar_id);
                        if (found) {
                          selectedCalendar = found?.summary;
                        }
                      }

                      return (
                        <div className="calender-party-col">
                          <div className="calender-party-details">
                            <img
                              src={getConnectionIcon(data.app_id)}
                              alt="Google Calender"
                            />
                            <div className="calender-name">
                              <p>Add to {JSON.parse(data?.meta_data)?.email}</p>
                              <p className="status">
                                <span className="ml-0">
                                  {selectedCalendar}
                                </span>
                              </p>
                            </div>
                          </div>
                          <button
                            class="bootstrap-default-btn"
                            onClick={() => onAddToCalendarHandler(data)}
                          >
                            <img src={EditIcon} alt="edit icon" style={{ maxWidth: 17 }} />
                          </button>
                        </div>  
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
    </>
  );
};

export default connect(null, {
  disconnect,
  sendPostRequest
})(ConnectedCalendars);
