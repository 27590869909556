import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const validation = Yup.object().shape({
  last_name: Yup.string().required("Last Name is required."),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      "Please enter valid email"
    )
    .required("Email is required"),
});

let initialValues = {
  reason: [],
  nature_of_issue: "",
  preferred_contact: [],
};

class CancelPlanModal extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    isOpen: false,
  };

  /**
   * on toggle modal
   */
  toggle = () => {
    const { isOpen } = this.state;
    console.log("is open", isOpen);
    this.setState({
      isOpen: !isOpen,
    });
  };

  render() {
    const { isOpen } = this.state;
    const { cancelPlan } = this.props;
    return (
      <>
        <button
          className="btn btn-danger ml-auto btn-14"
          style={{ width: "auto" }}
          onClick={(event) => {
            event.preventDefault();
            this.toggle();
          }}
        >
          Cancel Subscription
        </button>
        <Modal isOpen={isOpen} toggle={this.toggle}>
          <ModalBody>
            <div className="">
              <div className="eventdetailsaddbox rd_modalvieew">
                <div className="box_content rd_modalboxcontent">
                  <div className="rd_inputselectheadermodalsetting">
                    <button
                      className="rd_colsebtn"
                      onClick={this.toggle}
                    ></button>
                  </div>
                  <h5 className="rd_modalheadertext rd_modalheadertextnm">
                    We hate to see you leave
                  </h5>

                  <p className="rd_modalheadertextparath">
                    We really would like to know the reason behind your
                    cancelation
                  </p>

                  <hr />

                  <Formik
                    //validationSchema={validation}
                    initialValues={initialValues}
                    onSubmit={(data) => {
                      let natureOfIssue = "";
                      const found = data.reason.some(
                        (item) => item === "other"
                      );
                      if (found) {
                        natureOfIssue = data.nature_of_issue;
                      }

                      cancelPlan(
                        data.reason.join(),
                        natureOfIssue,
                        data.preferred_contact.join()
                      );

                      this.toggle();

                      console.log("reason", data.reason.join());
                      console.log("issue nature", natureOfIssue);
                      console.log(
                        "preferred_contact",
                        data.preferred_contact.join()
                      );
                    }}
                  >
                    {(formProps) => {
                      const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        setFieldValue,
                      } = formProps;

                      return (
                        <Form>
                          <div className="rd_checkmodalsetting checkleft">
                            <div className="form-check">
                              <Field
                                type="checkbox"
                                name="reason"
                                id="the_price_is_out_of_my_budget"
                                value="the_price_is_out_of_my_budget"
                                className="form-check-input"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="the_price_is_out_of_my_budget"
                              >
                                The price is out of my budget
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                type="checkbox"
                                name="reason"
                                id="lack_of_features_in_the_app"
                                value="lack_of_features_in_the_app"
                                className="form-check-input"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="lack_of_features_in_the_app"
                              >
                                Lack of features in the app
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                type="checkbox"
                                name="reason"
                                id="found_an_alternative"
                                value="found_an_alternative"
                                className="form-check-input"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="found_an_alternative"
                              >
                                Found an alternative
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                type="checkbox"
                                name="reason"
                                id="user_guide_issues"
                                value="user_guide_issues"
                                className="form-check-input"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="user_guide_issues"
                              >
                                User guide issues
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                type="checkbox"
                                name="reason"
                                id="dont_want_to_renew_just_yet"
                                value="dont_want_to_renew_just_yet"
                                className="form-check-input"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="dont_want_to_renew_just_yet"
                              >
                                Don't want to renew just yet!
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                type="checkbox"
                                name="reason"
                                id="other"
                                value="other"
                                className="form-check-input"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="other"
                              >
                                other
                              </label>
                            </div>
                            <div className="rd_ifothchsh">
                              <div className="rd_whyleavcontm">
                                <p>What's the nature of the issue?</p>
                                <textarea
                                  name="nature_of_issue"
                                  id=""
                                  cols="30"
                                  rows="10"
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    handleChange({
                                      target: {
                                        name: "nature_of_issue",
                                        value,
                                      },
                                    });
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <p>Preferred contact method</p>
                            <div className="form-check">
                              <Field
                                type="checkbox"
                                name="preferred_contact"
                                id="phone_number"
                                value="phone_number"
                                className="form-check-input"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="phone_number"
                              >
                                Phone number
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                type="checkbox"
                                name="preferred_contact"
                                id="email"
                                value="email"
                                className="form-check-input"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="email"
                              >
                                Email
                              </label>
                            </div>
                          </div>

                          <div className="rd_cancelappbtnmodal">
                            <button
                              className="rd_cancel"
                              type="button"
                              onClick={this.toggle}
                            >
                              Cancel
                            </button>
                            <button type="submit" className="btn btn-app">
                              Save
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default CancelPlanModal;
