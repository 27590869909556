import React, { useState } from "react";
import UpcomingEvents from "./upcoming-events";
import PendingEvents from "./pending-events";
import Past from "./past";
import DateRange from "./data-range";

const Dashboard = () => {
  const [tab, setTab] = useState(1);

  /**
   * Toggle tab
   * @param int tab
   */
  const toggleTab = (tab) => {
    setTab(tab);
  };

  return (
    <section>
      <div>
        <div className="eventdetailsaddbox rd_noshadow">
          <div className="boxheader rd_floatingheaderthig">
            <div className="rd_inputselectheader">
              <div className="rd_selectheaderrdt2">
                <button
                  onClick={() => toggleTab(1)}
                  className={`${tab === 1 ? "active" : ""}`}
                >
                  Upcoming
                </button>
                <button
                  onClick={() => toggleTab(2)}
                  className={`${tab === 2 ? "active" : ""}`}
                >
                  Pending
                </button>
                <button
                  onClick={() => toggleTab(3)}
                  className={`${tab === 3 ? "active" : ""}`}
                >
                  Past
                </button>
                <button
                  onClick={() => toggleTab(4)}
                  className={`${tab === 4 ? "active" : ""}`}
                >
                  Date Range
                </button>
              </div>
              <div className="rd_linkfilexp">
                <button className="rd_filterboxth d-none">Filter</button>
                <button className="rd_exporboxth d-none">Export</button>
              </div>
            </div>
          </div>
          {tab === 1 ? <UpcomingEvents /> : null}
          {tab === 2 ? <PendingEvents /> : null}
          {tab === 3 ? <Past /> : null}
          {tab === 4 ? <DateRange /> : null}
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
