import React from 'react';
import ZoomIcon from "../../../assets/icons/zoom.svg";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";

const Zoom = () => {
    return (
        <Col md="6" xxl="3" xl="4">
            <Link to="/integrations/zoom" className="int-col">
            <div className="img">
                <img src={ZoomIcon} alt="Google Calendar" />
            </div>
            <div className="text">
                <h3>Zoom</h3>
                <p>
                Lorem Ipsum is simply dummy text of the
                printing an typesetting industry.
                </p>
            </div>
            </Link>
        </Col>
    );
}

export default Zoom;