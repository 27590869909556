import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'antd';
import editIcon from "../../assets/icons/edit_icon.svg";
import personsIcon from "../../assets/icons/persons_group.svg";
import meetIcon from "../../assets/icons/google-meet.svg";
import linkIcon from "../../assets/icons/link_icon.svg";
import eventImg from "../../assets/fe-images/event_img.svg";
import checkMark from "../../assets/fe-images/checkMark.svg";
import clockIcon from "../../assets/icons/Clock.svg";
import calenderIcon from "../../assets/icons/Planner.svg";
import LocationIcon from "../../assets/icons/location_icon.svg";
import TickIcon from "../../assets/icons/check_mark_green.svg";
const { Panel } = Collapse;

const EventSummary = () => {

    let header1 = <h2>Event Type <Link to='/event-summary'><img src={editIcon} alt="Edit Icon" /></Link></h2>;
    let header2 = <h2>Event Details <Link to='/event-summary'><img src={editIcon} alt="Edit Icon" /></Link></h2>;
    let header3 = <h2>Availability <Link to='/event-summary'><img src={editIcon} alt="Edit Icon" /></Link></h2>;
    let header4 = <h2>Advanced <Link to='/event-summary'><img src={editIcon} alt="Edit Icon" /></Link></h2>;
    let StaffHeader1 = <p className='prof_img d-flex align-items-center'><span><img src={eventImg} alt="Profile Img" /></span> Staff Name</p>;
    const [advanceTab, setAdvanceTab] = useState(false);
    const [freeEvent, setFreeEvent] = useState(false);
    const [showAllAssignments, setShowAllAssignments] = useState(false);
    const showStaffAssignment = () => {
        setShowAllAssignments(true);
    }
    return (<>
        <div className='event_summarry'>
            <h2 className='mb-3'>Decibel Event Management {freeEvent ? <span className='eve_status free'>Free</span> : <span className='eve_status paid'>Paid</span>}</h2>
            <Collapse accordion defaultActiveKey={['1']}>
                {/* Event Type */}
                <Panel header={header1} key="1">
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            <h3>Event Name:</h3>
                            <p><span className='custom_circle'></span> Decibel Event Management</p>
                        </div>
                        <div className="col-auto">
                            <h3>Event Format:</h3>
                            <p><span><img src={personsIcon} alt="Group Humans" /></span> Group <span>[Attendees: 25]</span></p>
                        </div>
                        <div className="col-auto">
                            <h3>Event URL:</h3>
                            <p><Link to='/event-summary'><span><img src={linkIcon} alt="" /></span> ThriveBooking.com/company_name</Link></p>
                        </div>
                        <div className="col-auto">
                            <h3>Venue:</h3>
                            <p><span><img src={meetIcon} alt="" /></span> Google Meet</p>
                        </div>
                    </div>
                    <div className="row justify-content-between" style={{ borderTop: "1px solid rgba(112,112,112,0.1)", paddingTop: 20, marginTop: 20 }}>
                        <div className="col-auto">
                            <h3>location:</h3>
                            <p><span><img src={LocationIcon} alt="Location Icon" /></span> 2972 Westheimer Rd. Santa Ana, Illinois 85486</p>
                        </div>
                        <div className="col-auto">
                            <h3 className="op-0">Hidden</h3>
                            <p><span><img src={TickIcon} alt="Check mark" /></span> Display Location while booking.</p>
                        </div>
                        <div className="col-auto">
                            <h3>Venue note:</h3>
                            <p>Lorem Ipsum is that it has a more-or-less normal</p>
                        </div>
                    </div>
                </Panel>
                {/* Event Details */}
                <Panel header={header2} key="2">
                    <div className="event_detail_tab">
                        {/* first row */}
                        <div className="main_row row justify-content-between">
                            <div className="col-lg-2 col-6 event_img">
                                <h3>Image:</h3>
                                <img src={eventImg} alt="Event Img" />
                            </div>
                            <div className="col-lg-2 col-6 event_clr">
                                <h3>Event Color:</h3>
                                <span className='d-block' style={{ background: '#1FBAE7' }}></span>
                            </div>
                            <div className="col-md-8">
                                <h3>Description:</h3>
                                <p className='event_des'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                            </div>
                        </div>
                        <div className="main_row row justify-content-between">
                            <div className="col-12">
                                <h3>Allow</h3>
                                <div className="allow_row row">
                                    {freeEvent ? <div className="col-auto d-flex">
                                        <h3>Bookings Number:</h3>
                                        <p className="ml-2">85</p>
                                    </div> : null}
                                    <div className="col-auto">
                                        <p>Allow Recurring Booking? <span><img src={checkMark} alt="Check Mark" /></span></p>
                                    </div>
                                    <div className="col-auto">
                                        <p>Allow Attendee Cancellation? <span><img src={checkMark} alt="Check Mark" /></span></p>
                                    </div>
                                    <div className="col-auto">
                                        <p>Allow Rescheduling? <span><img src={checkMark} alt="Check Mark" /></span></p>
                                    </div>
                                    {freeEvent ? null :
                                        <div className="col-auto">
                                            <p>Paypal guest payment: <span style={{ fontSize: 18 }}>5</span></p>
                                        </div>
                                    }
                                </div>
                                {freeEvent ? null : <>
                                    <div className="row mt-4">
                                        <div className="col-auto d-flex">
                                            <h3>Bookings Number:</h3>
                                            <p className="ml-2">85</p>
                                        </div>
                                        <div className="col-auto d-flex">
                                            <h3>Do You Require A Prepayment/Deposit:</h3>
                                            <p className="mx-2">Yes</p>
                                            <h3>Full Payment [Deposit %45]</h3>
                                        </div>
                                    </div>
                                    <div className="allow_row row">
                                        <div className="col-auto">
                                            <p>Coupon Codes status <span><img src={checkMark} alt="Check Mark" /></span></p>
                                        </div>
                                        <div className="col-auto">
                                            <p>Enable Recurrent Booking Discounts <span><img src={checkMark} alt="Check Mark" /></span></p>
                                        </div>
                                        <div className="col-auto">
                                            <p>Staff Commission Status: <span><img src={checkMark} alt="Check Mark" /></span></p>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>
                        <div className="main_row price_row row justify-content-between">
                            <div className="col-md-6">
                                <h3>Pricing</h3>
                                <div className="row justify-content-between" style={{paddingBottom: 5, marginBottom: 7, borderBottom: "1px solid rgba(224,224,224,0.6)"}}>
                                    <div className="col-auto">
                                        <p>Item name: Fahran Naime</p>
                                    </div>
                                    <div className="col-auto">
                                        <p>Item price: $588</p>
                                    </div>
                                    <div className="col-auto">
                                        <p>Quantity: 8</p>
                                    </div>
                                </div>
                                <div className="row justify-content-between" style={{paddingBottom: 5, marginBottom: 7, borderBottom: "1px solid rgba(224,224,224,0.6)"}}>
                                    <div className="col-auto">
                                        <p>Item name: Fahran Naime</p>
                                    </div>
                                    <div className="col-auto">
                                        <p>Item price: $588</p>
                                    </div>
                                    <div className="col-auto">
                                        <p>Quantity: 8</p>
                                    </div>
                                </div>
                                <div class="text-center"><button class="btn more_btn">+2 more</button></div>
                            </div>
                            <div className="col-md-6">
                                <h3>Add on</h3>
                                <div className="row justify-content-between" style={{paddingBottom: 5, marginBottom: 7, borderBottom: "1px solid rgba(224,224,224,0.6)"}}>
                                    <div className="col-auto">
                                        <p>Item name: Fahran Naime</p>
                                    </div>
                                    <div className="col-auto">
                                        <p>Item price: $588</p>
                                    </div>
                                    <div className="col-auto">
                                        <p>Quantity: 8</p>
                                    </div>
                                </div>
                                <div className="row justify-content-between" style={{paddingBottom: 5, marginBottom: 7, borderBottom: "1px solid rgba(224,224,224,0.6)"}}>
                                    <div className="col-auto">
                                        <p>Item name: Fahran Naime</p>
                                    </div>
                                    <div className="col-auto">
                                        <p>Item price: $588</p>
                                    </div>
                                    <div className="col-auto">
                                        <p>Quantity: 8</p>
                                    </div>
                                </div>
                                <div class="text-center"><button class="btn more_btn">+2 more</button></div>
                            </div>
                        </div>
                        <div className="main_row row justify-content-between">
                            <div className="col-12">
                                <h3>Cancellation Policy:</h3>
                                <p className='event_des'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now.</p>
                                <div className="allow_row row">
                                    <div className="col-auto">
                                        <p>Booking Page <span><img src={checkMark} alt="Check Mark" /></span></p>
                                    </div>
                                    <div className="col-auto">
                                        <p>Confirmation Page <span><img src={checkMark} alt="Check Mark" /></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main_row row justify-content-between">
                            <div className="col-12">
                                <h3>Instructions to Attendee:</h3>
                                <p className='event_des'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now.</p>
                                <div className="allow_row row">
                                    <div className="col-auto">
                                        <p>Reminder Email <span><img src={checkMark} alt="Check Mark" /></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel header={header3} key="3">
                    <div className="availability_tab">
                        <nav className='custom_tabs d-flex'>
                            <button className={`btn ${advanceTab ? null : 'active'}`} onClick={() => { setAdvanceTab(false) }}>Event Duration</button>
                            <button className={`btn ${advanceTab ? 'active' : null}`} onClick={() => { setAdvanceTab(true) }}>Advanced</button>
                        </nav>
                        {advanceTab ?
                            <div className="advance_tab main_row row">
                                <div className="col-lg-3 col-md-4">
                                    <p>Minimum Scheduling Notice status: <span>2</span></p>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <p>Maximum Scheduling Notice status: <span>2</span></p>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <p>Maximum Event Per Day: <span>8</span></p>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <p>Maximum Event Per Week: <span>9</span></p>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <p>Buffer Before The Event status: <span>5</span></p>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <p>Buffer After The Event status: <span>6</span></p>
                                </div>
                            </div> : null}
                        <div className="main_row row justify-content-between">
                            <div className="col-auto">
                                <h3>Event Duration:</h3>
                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 60 minutes</p>
                            </div>
                            <div className="col-auto">
                                <h3>Event Validity:</h3>
                                <p><span><img src={calenderIcon} alt="Clock Icon" /></span> 08/23/2022 - 09/06/2022</p>
                            </div>
                            <div className="col-lg-7">
                                <h3>Event Internal Notes:</h3>
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that.</p>
                            </div>
                        </div>
                        <div className="main_row row justify-content-between">
                            <div className="col-12 mb-3">
                                <h3>Event availability:</h3>
                            </div>
                            <div className="col-auto week_days">
                                <p className='week_title'>Monday</p>
                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                            </div>
                            <div className="col-auto week_days">
                                <p className='week_title'>Tuesday</p>
                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                            </div>
                            <div className="col-auto week_days">
                                <p className='week_title'>Wednesday</p>
                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                            </div>
                            <div className="col-auto week_days">
                                <p className='week_title'>Thursday</p>
                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                            </div>
                            <div className="col-auto week_days">
                                <p className='week_title'>Friday</p>
                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                            </div>
                            <div className="col-auto week_days">
                                <p className='week_title'>Saturday</p>
                                <p>-</p>
                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                            </div>
                            <div className="col-auto week_days">
                                <p className='week_title'>Sunday</p>
                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                <p>-</p>
                            </div>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-12">
                                <Collapse accordion defaultActiveKey={['1']}>
                                    <div className="mb-3">
                                        <h3>Staff Assignment:</h3>
                                    </div>
                                    <Panel header={StaffHeader1} key="1">
                                        <div className="main_row row justify-content-between">
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Monday</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                            </div>
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Tuesday</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                            </div>
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Wednesday</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                            </div>
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Thursday</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                            </div>
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Friday</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                            </div>
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Saturday</p>
                                                <p>-</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                            </div>
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Sunday</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                <p>-</p>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <Collapse accordion defaultActiveKey={['1']}>
                                    <Panel header={StaffHeader1} key="1">
                                        <div className="main_row row justify-content-between">
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Monday</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                            </div>
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Tuesday</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                            </div>
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Wednesday</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                            </div>
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Thursday</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                            </div>
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Friday</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                            </div>
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Saturday</p>
                                                <p>-</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                            </div>
                                            <div className="col-auto week_days">
                                                <p className='week_title'>Sunday</p>
                                                <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                <p>-</p>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                                {showAllAssignments ? <>
                                    <Collapse accordion defaultActiveKey={['1']}>
                                        <Panel header={StaffHeader1} key="1">
                                            <div className="main_row row justify-content-between">
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Monday</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                </div>
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Tuesday</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                </div>
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Wednesday</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                </div>
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Thursday</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                </div>
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Friday</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                </div>
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Saturday</p>
                                                    <p>-</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                </div>
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Sunday</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                    <p>-</p>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Collapse accordion defaultActiveKey={['1']}>
                                        <Panel header={StaffHeader1} key="1">
                                            <div className="main_row row justify-content-between">
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Monday</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                </div>
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Tuesday</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                </div>
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Wednesday</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                </div>
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Thursday</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                </div>
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Friday</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                </div>
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Saturday</p>
                                                    <p>-</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                </div>
                                                <div className="col-auto week_days">
                                                    <p className='week_title'>Sunday</p>
                                                    <p><span><img src={clockIcon} alt="Clock Icon" /></span> 2:25pm to 3:25</p>
                                                    <p>-</p>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </> : null}
                                {showAllAssignments ? null : <div className="text-center"><button className='btn more_btn' onClick={showStaffAssignment}>+2 more</button></div>}
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel header={header4} key="4">
                    <div className="advanced_tab">
                        <div className="main_row row">
                            <div className="col-6 col-md-3">
                                <h3>First Name:</h3>
                                <p>Adam</p>
                            </div>
                            <div className="col-6 col-md-3">
                                <h3>Last Name:</h3>
                                <p>Staff</p>
                            </div>
                            <div className="col-12 col-md-3 mt-4 mt-md-0">
                                <h3>Email Address:</h3>
                                <p>adamstaff2514@gmail.com</p>
                            </div>
                        </div>
                        <div className="main_row row justify-content-between">
                            <div className="col-12">
                                <h3>Ask Attendee Questions:</h3>
                            </div>
                            <div className="col-auto">
                                <p>Lorem Ipsum is that it has a more-or-less normal ?</p>
                            </div>
                            <div className="col-auto">
                                <p>Lorem Ipsum is that it has a more-or-less normal ?</p>
                            </div>
                            <div className="col-auto">
                                <p>Lorem Ipsum is that it has a more-or-less normal ?</p>
                            </div>
                        </div>
                        <div className="main_row row">
                            <div className="col-12">
                                <h3>Invitee Setting</h3>
                            </div>
                            <div className="col-auto">
                                <p>Allow Attendee to add additional guest <span className="ml-2 mr-0"><img src={checkMark} alt="Check Mark" /></span></p>
                            </div>
                        </div>
                        <div className="main_row row justify-content-between">
                            <div className="d-flex d-md-block col-lg-auto col-md-4">
                                <h3>Make Event Public:</h3>
                                <p>Yes</p>
                            </div>
                            <div className="d-flex d-md-block col-lg-auto col-md-4 mt-3 mt-md-0">
                                <h3>Collect Customer Feedback:</h3>
                                <p>Yes</p>
                            </div>
                            <div className="col-lg-auto col-md-4 mt-3 mt-md-0">
                                <h3>Reminders:</h3>
                                <p>Email Reminders <span className="ml-2 mr-0"><img src={checkMark} alt="Check Mark" /></span></p>
                                <p className='mt-2'>SMS Reminders <span className="ml-2 mr-0"><img src={checkMark} alt="Check Mark" /></span></p>
                            </div>
                            <div className="col-lg-auto col-md-4 mt-3 mt-md-0">
                                <h3>Confirmation Page:</h3>
                                <p>Default</p>
                            </div>
                        </div>
                    </div>
                </Panel>
            </Collapse>
        </div>
    </>);
}

export default EventSummary;