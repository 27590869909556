import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { getUserAndPlanDetails } from "../../../actions/subscriptionActions";
import {
  getStaffs,
  inviteStaff,
  softDeleteStaff,
} from "../../../actions/staffActions";
import Loader from "../../../components/Loader/Loader";
import {
  SET_SUBSCRIPTION_STAFF,
  SET_SUBSCRIPTION_USER_PAGINATION,
  SET_IS_LOADING,
} from "../../../actions/types";
import SubscriptionPlans from "./subscription-plans";
import ManageInviteUser from "./manage-invite-user";
import { getAdminBusinessId } from "../../../utils/authHelper";
import { notification } from "../../../utils/utility";
import BillingPage from "./billing-page";
import StaffHasBookingModal from "../../admin/settings/Staff/StaffHasBookingModal";
import Tooltip from "../../../components/Tooltip";

const Subscription = (props) => {
  const [showSubsPage, setShowSubsPage] = useState(true);
  const [showStaffHasBookings, setShowStaffHasBookings] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });
    loadStaffs({
      ...props.subscription.pagination,
      sortField: "id",
      sortOrder: "desc",
    });
    getUserAndPlans();
  }, []);

  /**
   * Get user and Plan
   */
  const getUserAndPlans = () => {
    const { getUserAndPlanDetails } = props;
    getUserAndPlanDetails({
      onSuccess: () => {
        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });
      },
      onError: (error) => {
        console.log("error", error);
        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });
      },
    });
  };

  /**
   * on invitee staff
   * @param {*} data
   */
  const onInviteStaff = (data) => {
    const { inviteStaff } = props;

    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    inviteStaff({
      data: {
        ...data,
        business_id: getAdminBusinessId(),
      },
      onSuccess: function (details) {
        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });
        loadStaffs({
          ...props.subscription.pagination,
          pageNumber: 1,
          sortField: "id",
          sortOrder: "desc",
        });
        notification.success("Staff Invited successfully");
        // _this.reloadPage();
      },
      onError: function (error) {
        notification.error(
          error ? error : "An error occured during invitation, please try later"
        );
        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });
      },
    });
  };

  /**
   * delete staff
   * @param {*} staffId
   */
  const onDeleteStaff = (staffId) => {
    const { softDeleteStaff } = props;
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });
    softDeleteStaff({
      data: {
        id: staffId,
      },
      onSuccess: function () {
        dispatch({
          type: SET_SUBSCRIPTION_USER_PAGINATION,
          payload: {
            pageNumber: 1,
            pageSize: props.subscription.pagination.pageSize,
          },
        });
        loadStaffs({
          ...props.subscription.pagination,
          pageNumber: 1,
          sortField: "id",
          sortOrder: "desc",
        });
        notification.success(`Staff successfully deleted`);
      },
      onError: function (error) {
        const { data } = error.response;
        if (parseInt(data.code) === 255) {
          setShowStaffHasBookings(true);
        } else {
          notification.error(
            "An Error occurred during delete, please try later"
          );
        }

        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });
      },
    });
  };

  /**
   * Load staffs
   * @param {*} data
   */
  const loadStaffs = (data) => {
    const { getStaffs } = props;
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });
    getStaffs({
      data,
      onSuccess: function (details) {
        dispatch({
          type: SET_SUBSCRIPTION_STAFF,
          payload: {
            staffs: details.entity,
            totalStaffs: details.totalCount,
          },
        });
        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });
      },
      onError: function (error) {
        console.log(error);
        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });
      },
    });
  };

  /**
   * on page change
   * @param {*} pageNumber
   * @param {*} pageSize
   */
  const onPageChange = (pageNumber, pageSize) => {
    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });
    dispatch({
      type: SET_SUBSCRIPTION_USER_PAGINATION,
      payload: {
        pageNumber,
        pageSize,
      },
    });
    loadStaffs({
      pageSize,
      pageNumber,
      sortField: "id",
      sortOrder: "desc",
    });
  };

  return (
    <section>
      <Loader isShowLoader={props.layout.isLoading} />
      <div className="">
        <div className="eventdetailsaddbox rd_noshadow">
          <h3 className="section_title">Manage Subscriptions</h3>
          {/* Tabbing */}
          <div className="filter_bar dark" style={{ marginTop: 30 }}>
            <ul className="cus_tab">
              <li>
                <button
                  className={`btn ${showSubsPage ? "active" : null}`}
                  onClick={() => setShowSubsPage(true)}
                >
                  Subscription Plans <Tooltip code="P14F1" />
                </button>
              </li>
              <li>
                <button
                  className={`btn ${!showSubsPage ? "active" : null}`}
                  onClick={() => setShowSubsPage(false)}
                >
                  Billing Info <Tooltip code="P14F2" />
                </button>
              </li>
            </ul>
          </div>
          {showSubsPage && (
            <>
              <SubscriptionPlans getUserAndPlans={getUserAndPlans} />
              <ManageInviteUser
                onPageChange={onPageChange}
                onInviteStaff={onInviteStaff}
                onDeleteStaff={onDeleteStaff}
              />
            </>
          )}
          {!showSubsPage && (
            <>
              <BillingPage />
            </>
          )}
        </div>
      </div>
      {showStaffHasBookings && (
        <StaffHasBookingModal
          showStaffHasBookings={showStaffHasBookings}
          toggle={() => setShowStaffHasBookings(false)}
        />
      )}
    </section>
  );
};

const mapStateToProps = (state) => ({
  subscription: state.subscription,
  layout: state.layout,
});

export default connect(mapStateToProps, {
  getUserAndPlanDetails,
  getStaffs,
  inviteStaff,
  softDeleteStaff,
})(Subscription);
