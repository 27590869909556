import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";

import "./assets/custom-scss/_bootstrap-for-v2.scss";
//import "bootstrap/dist/css/bootstrap.css";// for new theme comments out
import "./assets/custom-scss/_front-end-v2.scss";
import "./assets/custom-scss/index.scss"; // for new theme comments out
//import "./assets/custom-scss/_welcome-to-meetocto.scss";
//import './assets/fonts/sofia-pro/index.css';

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import "./i18n";
//import * as serviceWorker from "./serviceWorker";
import Spinner from "./components/Spinner";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense fallback={<Spinner />}>
      <App />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
