import React from 'react';
import { Row, Col } from 'reactstrap';
import filterIcon from "../../assets/icons/filter_icon.svg";
import { connect } from "react-redux";
import { toggleFilter, onClearFilters } from '../../actions/filterActions';
import Tooltip from '../Tooltip';

const Filter = (props) => {

    const { filterShow } = props.filter;

    return (
        <Row>
            <Col sm="12">
                <div className={`filter_bar d-flex justify-content-between align-items-center ${props.className !== undefined ? props.className : ""}`}>
                    <h3>
                        {props.name}
                        {props.tooltipCode && <Tooltip code={props.tooltipCode} />}
                    </h3>
                    <div className='d-flex align-items-center w-auto'>
                        {props.showButtons ? props.buttons : null}
                        <div className="filter_sec">
                            <button
                                className="btn p-0"
                                onClick={() => props.toggleFilter(true)}
                                style={{ width: "auto" }}
                            >
                                <img src={filterIcon} alt="" width={27} height={27} />
                            </button>
                            <div
                                className={`filter_backdrop ${filterShow ? "active" : ""
                                    }`}
                            ></div>
                            <div
                                className={`filter_content ${filterShow ? "active" : ""
                                    }`}
                            >
                                <div className="position-relative">
                                    <div className="top_bar">
                                        <h3>Filters</h3>
                                        <button
                                            className="btn p-0"
                                            onClick={() => {
                                                props.toggleFilter(false);
                                                props.onClearFilters();
                                                props.onClearFilterHandler();
                                            }}>
                                            Clear Filters
                                        </button>
                                    </div>
                                    <div className="filter_items_container">
                                        {props.children}
                                    </div>
                                    <div className="bottom_bar">
                                        <button
                                            className="btn btn-outline"
                                            onClick={() => props.toggleFilter(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn btn-app"
                                            onClick={props.onFilterRecords}
                                        >
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

const mapStateToProps = (state) => ({
    filter: state.filter
});

export default connect(mapStateToProps, {
    toggleFilter,
    onClearFilters
})(Filter);
