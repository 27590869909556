import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "../../../components/Loader/Loader";
import currentStep from "../../../assets/images/current-step.png";
import unSelectedRadio from "../../../assets/images/unselected-radio.png";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import FormField from "../../../components/FormField/FormField";
import axiosInstance from "../../../axios-instance";
import { connect } from "react-redux";
import {
  getStepOneData,
  saveStepOneData,
} from "../../../actions/admin/quick-setup-actions";
import isEmpty from "../../../validation/is-empty";
import { notification, IMAGE_BASE_URL } from "../../../utils/utility";
import { getAdminBusinessId } from "../../../utils/authHelper";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const validation = Yup.object().shape({
  company_name: Yup.string().required(
    "Organization (Company or Personal name) is required."
  ),
  phone: Yup.string().required("Phone is required."),
  company_street: Yup.string().required("Street is required."),
  company_state: Yup.string().required("State is required."),
  company_zip_code: Yup.string().required("Zip Code is required."),
  country_id: Yup.string().required("Country is required."),
});

let initialValues = {
  company_name: "",
  phone: "",
  company_street: "",
  company_state: "",
  company_zip_code: "",
  country_id: "",
};

const QuickSetupStepOne = (props) => {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [id, setId] = useState(null);

  const [profileImageUrl, setProfileImageUrl] = useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [isImageChangedEventCalled, setIsImageChangedEventCalled] =
    useState(false);

  /**
   * On Go back event
   */
  const onGoBack = () => {
    const { route } = props;
    const { history } = route || {};
    history.goBack();
  };

  useEffect(() => {
    setPageLoading(true);

    axiosInstance
      .get("/countries")
      .then((response) => {
        setCountries(response.data.data.countries);
      })
      .catch((error) => {
        console.error(error);
      });

    const { getStepOneData } = props;

    getStepOneData({
      onSuccess: function (response) {
        const { data } = response;
        if (!isEmpty(data)) {
          setId(data.id);
          setFormValues({
            company_name: data.company_name,
            phone: data.phone,
            company_street: data.company_street,
            company_state: data.company_state,
            company_zip_code: data.company_zip_code,
            country_id: data.country_id,
          });
          setProfileImageUrl(data.company_logo);
        }
        setPageLoading(false);
      },
      onError: function (error) {
        setPageLoading(false);
      },
    });
  }, []);

  const onSelectFile = (e) => {
    setIsImageChangedEventCalled(true);

    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setProfileImageUrl(objectUrl);
  };

  return (
    <section>
      <Loader isShowLoader={pageLoading} />

      <div className="profile-step w-100 mt-0 mb-0 pt-3">
        <div className="step-1 w-100">
          <div
            className="by-step pt-1"
            style={{ backgroundPositionY: "16px", backgroundSize: "95%" }}
          >
            <div className="step">
              <img src={currentStep} alt="" />
              <p className="current-step">Step 1</p>
            </div>
            <div className="step">
              <img src={unSelectedRadio} alt="" />
              <p>Step 2</p>
            </div>
            <div className="step">
              <img src={unSelectedRadio} alt="" />
              <p>Step 3</p>
            </div>
          </div>
        </div>

        <Formik
          validationSchema={validation}
          initialValues={formValues || initialValues}
          enableReinitialize
          onSubmit={(data) => {
            const { saveStepOneData } = props;
            setPageLoading(true);

            let formData = {
              ...data,
              business_id: getAdminBusinessId(),
            };

            if (selectedFile !== undefined) {
              formData.company_logo = selectedFile;
            }

            if (id) {
              formData.id = id;
            }

            saveStepOneData({
              data: formData,
              onSuccess: (response) => {
                if (response.success) {
                  notification.success("Record saved successfully");
                }
                setPageLoading(false);

                navigate("/admin/quick-setup-step-two");
              },
              onError: (error) => {
                notification.error(
                  "An error occured during save, Please try later"
                );
                setPageLoading(false);
                console.log("company error ", error);
              },
            });
          }}
        >
          {(formProps) => {
            const {
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              setFieldTouched,
            } = formProps;

            return (
              <>
                <Form>
                  <Row className="p-4">
                    <Col md={12}>
                      <div className="rd_profileimagepart pl-0">
                        <div className="rd_imagethincontg">
                          <span>Company Logo/ Profile Picture</span>
                          <div className="rd_imagecontprofile">
                            {profileImageUrl ? (
                              <img
                                src={
                                  !isImageChangedEventCalled
                                    ? IMAGE_BASE_URL + profileImageUrl
                                    : profileImageUrl
                                }
                                alt=""
                              />
                            ) : null}
                          </div>{" "}
                        </div>
                        <div className="rd_imagethincontgbtnt">
                          <p>Allowed JPG, GIF or PNG. Max size of 800kB</p>
                          <FormGroup className="has-icon-left position-relative">
                            <label
                              className="CustomButton text-white text-center"
                              outline
                            >
                              Upload Photo
                              <Input
                                type="file"
                                accept=".png,.jpg"
                                onChange={onSelectFile}
                                name="profile_image"
                                id="uploadImg"
                                hidden
                              />
                            </label>
                          </FormGroup>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Label htmlFor="company-name">
                          Organization (Company or Personal name) *
                        </Label>
                        <FormField
                          id="company-name"
                          type="text"
                          name="company_name"
                          placeholder="Company or Personal name"
                          value={values.company_name}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "company_name", value },
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Label htmlFor="phone">Phone *</Label>
                        <PhoneInput
                          containerClass="custom_phn_inp"
                          country={"us"}
                          value={values.phone}
                          onBlur={() => {
                            setFieldTouched(`phone`, true);
                          }}
                          onChange={(value) => {
                            handleChange({
                              target: { name: "phone", value },
                            });
                          }}
                        />
                        {errors[`phone`] && touched[`phone`] && (
                          <FormFeedback className="d-block">
                            {errors[`phone`]}
                          </FormFeedback>
                        )}
                        {/* <FormField
                          id="phone"
                          type="text"
                          name="phone"
                          placeholder="Phone"
                          value={values.phone}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "phone", value },
                            });
                          }}
                        /> */}
                      </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Label htmlFor="addLine1">Address Line 1 *</Label>
                        <FormField
                          id="addLine1"
                          type="text"
                          name="Address Line 1"
                          placeholder="Street"
                          // value={values.company_street}
                          errors={errors}
                          touched={touched}
                          // onChange={({ target }) => {
                          //   const { value } = target;
                          //   handleChange({
                          //     target: { name: "company_street", value },
                          //   });
                          // }}
                        />
                        <p className="info_txt">
                          Street address, P.O. box, Company name C/o
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Label htmlFor="addLine2">Address Line 2 *</Label>
                        <FormField
                          id="addLine2"
                          type="text"
                          name="Address Line 2"
                          placeholder="Street"
                          // value={values.company_street}
                          errors={errors}
                          touched={touched}
                          // onChange={({ target }) => {
                          //   const { value } = target;
                          //   handleChange({
                          //     target: { name: "company_street", value },
                          //   });
                          // }}
                        />
                        <p className="info_txt">
                          Apartment, suite, unit, building, floor, etc
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Label htmlFor="company-street">City *</Label>
                        <FormField
                          id="company-street"
                          type="text"
                          name="company_street"
                          placeholder="Street"
                          // value={values.company_street}
                          errors={errors}
                          touched={touched}
                          // onChange={({ target }) => {
                          //   const { value } = target;
                          //   handleChange({
                          //     target: { name: "company_street", value },
                          //   });
                          // }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Label htmlFor="company-state">
                          State/Province/Region *
                        </Label>
                        <FormField
                          id="company-state"
                          type="text"
                          name="company_state"
                          placeholder="State"
                          value={values.company_state}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "company_state", value },
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Label htmlFor="company-zip-code">
                          Zip/Postal code *
                        </Label>
                        <FormField
                          id="company-zip-code"
                          type="text"
                          name="company_zip_code"
                          placeholder="State"
                          value={values.company_zip_code}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "company_zip_code", value },
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Label htmlFor="country-id">Country *</Label>
                        <select
                          name="country_id"
                          id="country-id"
                          value={values.country_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Select Country</option>
                          {countries.map((item) => (
                            <option value={item.value}>{item.label}</option>
                          ))}
                        </select>
                        <ErrorMessage
                          name="country_id"
                          render={(error) => (
                            <span
                              className="error"
                              style={{ color: "#dc3545", fontSize: "80%" }}
                            >
                              {error}
                            </span>
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mb-4 p-4">
                    <Col md={6} lg={6}>
                      &nbsp;
                    </Col>

                    <Col md={3} lg={3}>
                      <Button
                        className="btn btn-outline"
                        type="button"
                        disabled={pageLoading}
                        onClick={onGoBack}
                      >
                        Cancel
                      </Button>
                    </Col>

                    <Col md="3" lg="3" className="mt-4 mt-md-0">
                      <Button
                        type="submit"
                        className="btn btn-app"
                        disabled={pageLoading}
                      >
                        Next Step
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

export default connect(null, {
  getStepOneData,
  saveStepOneData,
})(QuickSetupStepOne);
