import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  SET_PLAN_PERIOD_TYPE,
  SET_SUBSCRIPTION_PLANS,
  SET_USER_AND_PLAN_DETAILS,
  SET_IS_LOADING,
} from "../../../actions/types";
import {
  onCancelPlan,
  upgradePlan,
} from "../../../actions/subscriptionActions";
import IdeaIcon from "../../../assets/icons/idea_icon.svg";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ToolTip from "../../events/Components/Common/ToolTip";
import ConfirmPaymentModal from "./confirm-payment-modal";
import CancelPlanModal from "./cancel-plan-modal";
import PlanDetailModal from "./plan-detail-modal";
import isEmpty from "../../../validation/is-empty";
import CardInfoModal from "./card-info-modal";
import { notification } from "../../../utils/utility";

const SubscriptionPlans = (props) => {
  const dispatch = useDispatch();

  const [showCardInfoModal, setShowCardInfoModal] = useState(false);
  const { plans, planPeriodType, userPlan } = props.subscription;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  /**
   * swap elements of array
   * @param array arr
   * @param int index1
   * @param int index2
   */
  const swapElements = (arr, index1, index2) => {
    let temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;
  };

  /**
   * toggle checkbox status
   * @param mixed event
   * @param int planId
   */
  const toggleIndividualOrTeamStatus = (event, planId) => {
    const appPlans = [...props.subscription.plans];
    planId = parseInt(planId);
    if (planId === 1 || planId === 4) {
      swapElements(appPlans, 0, 3);
    } else if (planId === 2 || planId === 5) {
      swapElements(appPlans, 1, 4);
    } else {
      swapElements(appPlans, 2, 5);
    }
    dispatch({
      type: SET_SUBSCRIPTION_PLANS,
      payload: appPlans,
    });
  };

  /**
   * on cancel plan
   * @param {*} reason
   * @param {*} natureOfIssue
   * @param {*} preferredContact
   */
  const onCancelSubscription = (reason, natureOfIssue, preferredContact) => {
    const { onCancelPlan, getUserAndPlans } = props;

    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    onCancelPlan({
      data: {
        reason,
        nature_of_issue: natureOfIssue,
        preferred_contact: preferredContact,
      },
      onSuccess: function (response) {
        getUserAndPlans();
        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });
        notification.success("Plan cancelled successfully");
        getUserAndPlans();
      },
      onError: function (error) {
        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });
      },
    });
  };

  /**
   * upgrade plan handler
   * @param {*} data
   */
  const upgradePlanHandler = (data) => {
    const { upgradePlan, getUserAndPlans } = props;
    const { selectedPlan, planPeriodType } = props.subscription;

    data.plan_id = selectedPlan.id;
    data.plan_type = planPeriodType;

    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    upgradePlan({
      data: data,
      onSuccess: function (message) {
        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });
        if (message) {
          notification.success(message);
          getUserAndPlans();
        }
      },
      onError: function (error) {
        console.log("upgrade plan error", error);
        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });
      },
    });
  };

  /**
   * on upgrade plan, in case you are moving from free plan to paid
   * @param {*} plan
   */
  const onUpgradePlan = (plan) => {
    setShowCardInfoModal(true);
    dispatch({
      type: SET_USER_AND_PLAN_DETAILS,
      payload: {
        selectedPlan: plan,
      },
    });
    console.log("plan", plan);
  };

  return (
    <>
      {/* Monthly Anullay Switch */}
      <div className="mb-4 d-flex justify-content-center align-items-center">
        <label className="form-check-label mb-0">Monthly</label>
        <div className="toggle_field mx-2">
          <div className="box_content bg-transparent p-0">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={props.subscription.planPeriodType === "annual"}
                onChange={(event) => {
                  dispatch({
                    type: SET_PLAN_PERIOD_TYPE,
                    payload: event.target.checked ? "annual" : "monthly",
                  });
                }}
              />
            </div>
          </div>
        </div>
        <label className="form-check-label mb-0">Annually</label>
        {userPlan.is_paid_subscription === "E" && (
          <CancelPlanModal cancelPlan={onCancelSubscription} />
        )}
      </div>
      {/* Annual Subscription Benefit */}
      <div className="cmplt_prof_msg subscription_msg">
        <img src={IdeaIcon} alt="Alert Icon" />{" "}
        <p>
          You Can Save <span>$50</span> On Annually Subscription
        </p>
      </div>
      <Row className="pt-2">
        {showCardInfoModal && (
          <CardInfoModal
            setShowCardInfoModal={setShowCardInfoModal}
            upgradePlan={upgradePlanHandler}
          />
        )}
        {plans
          .filter((plan, planIndex) => {
            return planIndex < 3;
          })
          .map((plan, index) => {
            return (
              <Col lg="4" md="6" key={index}>
                <div className="subscription_card">
                  <div className="cd_head">
                    <h3>
                      {plan.plan_name}{" "}
                      <label>
                        {planPeriodType === "monthly" ? (
                          <>(Monthly)</>
                        ) : (
                          <>(Annually)</>
                        )}
                      </label>
                      <ToolTip />
                    </h3>
                    <div className="w-auto d-flex justify-content-center align-items-center">
                      <label className="form-check-label mb-0">
                        Individual
                      </label>
                      <div className="toggle_field mx-2">
                        <div className="box_content bg-transparent p-0">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={plan.plan_type === "team"}
                              onChange={(event) =>
                                toggleIndividualOrTeamStatus(event, plan.id)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <label className="form-check-label mb-0">Team</label>
                    </div>
                  </div>
                  <div className="cd_body">
                    <p>
                      Start automating basic scheduling features
                      <span>
                        $
                        {planPeriodType === "monthly"
                          ? plan.monthly_price
                          : plan.annual_price}
                        .00
                      </span>
                    </p>

                    {/* Plan Price */}
                    <h2>
                      <span>
                        $
                        {planPeriodType === "monthly"
                          ? plan.monthly_price
                          : plan.annual_price}
                        .00
                      </span>
                    </h2>

                    <div className="d-flex flex-wrap justify-content-between">
                      <PlanDetailModal />
                      {/* <button className="btn btn-outline" onClick={toggle}>
                        View Details
                      </button> */}
                      {plan.id === userPlan.plan_id ? (
                        <button className="btn btn_success">Active</button>
                      ) : (
                        <>
                          {/* Already paid for subscription */}
                          {userPlan.is_paid_subscription === "E" ? (
                            <>
                              <ConfirmPaymentModal
                                price={
                                  planPeriodType === "monthly"
                                    ? plan.monthly_price
                                    : plan.annual_price
                                }
                                plan_id={plan.id}
                                plan={plan}
                                userCurrentPlan={userPlan}
                                upgradePlan={upgradePlanHandler}
                              />
                            </>
                          ) : (
                            <>
                              {/* Go to Payment Page */}
                              {plan.monthly_price > userPlan.monthly_price ||
                              isEmpty(userPlan.plan_id) ? (
                                <button
                                  className="btn btn-outline brand"
                                  onClick={() => onUpgradePlan(plan)}
                                >
                                  Upgrade Plan
                                </button>
                              ) : (
                                <button className="btn btn-outline brand">
                                  Downgrade Plan
                                </button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <Modal isOpen={modal} toggle={toggle} centered>
                      <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                      <ModalBody>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </ModalBody>
                      <ModalFooter>
                        <button className="btn btn-outline" onClick={toggle}>
                          Cancel
                        </button>
                      </ModalFooter>
                    </Modal>
                  </div>
                </div>
              </Col>
            );
          })}
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  subscription: state.subscription,
});

export default connect(mapStateToProps, {
  upgradePlan,
  onCancelPlan,
})(SubscriptionPlans);
