import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getBookingDetails } from "../../../actions/admin/scheduled-event-actions";
import { Modal, ModalBody, Row, Col, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { sendPostRequest } from "../../../actions/app-actions";
import { notification } from "../../../utils/utility";

const PendingViewDetailsModal = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState(null);

  useEffect(() => {
    const { getBookingDetails, bookingId } = props;
    setIsLoading(true);
    getBookingDetails({
      data: {
        id: bookingId,
      },
      onSuccess: function (response) {
        console.log(response);
        setData(response);
        setIsLoading(false);
      },
      onError: function (error) {
        console.error(error);
        setIsLoading(false);
      },
    });
  }, []);

  /**
   * confirm booking
   * @param {*} bookingId
   */
  const onConfirmBooking = (bookingId) => {
    const { sendPostRequest } = props;
    setIsLoading(true);
    props.handleClose();
    sendPostRequest({
      url: `/update-booking-status`,
      data: {
        id: bookingId,
        status: "A",
      },
      onSuccess: function (response) {
        setIsLoading(false);
        notification.success("Booking confirmed successfully!");
        props.loadPendingEvents();
      },
      onError: function (error) {
        setIsLoading(false);
        notification.error(
          "An Error Occured during confirmation, Please try later"
        );
        console.log("error", error);
      },
    });
  };

  return (
    <>
      <Loader isShowLoader={isLoading} />
      <Modal
        returnFocusAfterClose={true}
        isOpen={props.showViewDetailModal && !isLoading}
        toggle={props.handleClose}
        className="event-modal modal-lg modal-dialog"
      >
        <ModalBody>
          <div className="">
            <div className="box_content py-0">
              <div className="rd_inputselectheadermodalsetting">
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="rd_modalheadertext rd_modalheadertextnm my-0 text-capitalize">
                    {data ? data.event_name : null}
                  </h2>
                  <button
                    className="rd_colsebtn"
                    onClick={props.handleClose}
                  ></button>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <h3 className="rd_modalheadertext rd_modalheadertextnm my-0">
                  Booking Details
                </h3>
                <p>
                  <span className="rd_timeiconthin"></span>
                  <span className="vd-timing">
                    {data ? `${data.start_time} - ${data.end_time}` : null}
                  </span>
                </p>
              </div>
              <Row className="mt-3">
                <Col lg="12">
                  <Row>
                    <Col lg="12">
                      <div className="vd-title">Event Title :</div>
                      <div className="vd-description pt-1 pb-3">
                        {data ? data.event_name : null}
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="vd-title">Host :</div>
                      <div className="vd-description pt-1 pb-3">
                        {data ? data.host : null}
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="vd-title">Invitee :</div>
                      <div className="vd-description pt-1 pb-3">
                        {data ? data.invitee : null}
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="vd-title">When :</div>
                      <div className="vd-description pt-1 pb-3">
                        {data
                          ? `${data.when}   ${data.start_time}-${data.end_time}`
                          : null}{" "}
                        ({data ? `${data.host_timezone}` : null})
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="vd-title">Event Duration :</div>
                      <div className="vd-description pt-1 pb-3">
                        {data ? data.event_duration : null}
                      </div>
                    </Col>

                    <Col lg="12">
                      <div className="vd-title">Invitee Time Zone :</div>
                      <div className="vd-description pt-1 pb-3">
                        {data ? data.invitee_timezone : null}
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="vd-title mb-2">Questions</div>
                    </Col>
                    <Col lg="12">
                      <div className="vd-title fw-normal">How old are you?</div>
                      <div className="vd-description pt-1 pb-3">24</div>
                    </Col>
                    <Col lg="12">
                      <div className="vd-title fw-normal">
                        Where do you live?
                      </div>
                      <div className="vd-description pt-1 pb-3">Pakistan</div>
                    </Col>

                    <Col lg="12">
                      <div className="vd-title">Venue :</div>
                      <div className="vd-description pt-1 pb-3">
                        {data ? data.venue : null}
                      </div>
                    </Col>

                    {data && data.venue_details !== "" ? (
                      <Col lg="12">
                        <div className="vd-title">Venue Details :</div>
                        <div className="vd-description pt-1 pb-3">
                          {data ? data.venue_details : null}
                        </div>
                      </Col>
                    ) : null}

                    {data && data.customer_instruction !== null ? (
                      <Col lg="12">
                        <div className="vd-title">Invitee instruction :</div>
                        <div className="vd-description pt-1 pb-3">
                          {data ? data.customer_instruction : null}
                        </div>
                      </Col>
                    ) : null}

                    {data &&
                    (data.message_from_customer !== null ||
                      data.message_from_customer !== "") ? (
                      <Col lg="12">
                        <div className="vd-title">Invitee Feedback :</div>
                        <div className="vd-description pt-1 pb-3">
                          {data ? data.message_from_customer : null}
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
                <Col lg="12" className="mb-3">
                  <div className="upcoming_details_bottom row justify-content-between">
                    {data &&
                    props.staffCanConfirm === "E" &&
                    data.is_admin_invitee === "D" ? (
                      <Button
                        className="btn btn-app mb-4"
                        type="button"
                        onClick={() => {
                          onConfirmBooking(data.id);
                        }}
                      >
                        Confirm
                      </Button>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, {
  getBookingDetails,
  sendPostRequest,
})(PendingViewDetailsModal);
