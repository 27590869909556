import React from "react";
import { Page, Text, Document } from "@react-pdf/renderer";

const BookingDetailPdf = ({ data }) => {
  return (
    <Document>
      <Page>
        {data && (
          <>
            <Text>Event Name: {data.event_name}</Text>
            <Text>Event end date: {data.end_date}</Text>
            <Text>
              Event type: {data.is_event_paid === "E" ? "Paid" : "Free"}
            </Text>
            <Text>Event venue: {data.venue}</Text>
            <Text>Event duration: {data.event_duration}</Text>
            <Text>Event date: {data.when}</Text>
            <Text>Invitee: {data.invitee_name}</Text>
            <Text>Timezone: {data.invitee_timezone}</Text>
            <Text>Host: {data.host}</Text>
          </>
        )}
      </Page>
    </Document>
  );
};

export default BookingDetailPdf;
