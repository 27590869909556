import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Loader/Loader';
import Filter from '../Filter/Filter';
import TextEditor from '../../views/events/Components/Common/TextEditorField';
import { Table, Dropdown, Menu } from 'antd';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import DemoImg from "../../assets/fe-images/event_img.svg";
import Elipsis from "../../assets/icons/elipsisV.svg";
import EditIcon from "../../assets/icons/edit_icon.svg";
import ViewIcon from "../../assets/icons/eye_icon.svg";
import TrashIcon from "../../assets/icons/trash_icon.svg";
import UploaderIcon from "../../assets/icons/uploader_icon.svg";

// const menu = [
//     {
//         key: '1',
//         label: (
//             <Link to={"/#"}>View tooltip</Link>
//         ),
//         //   icon: <img src={ViewIcon} alt="" className="elipsis" />
//     },
//     {
//         key: '2',
//         label: (
//             <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
//                 2nd menu item (disabled)
//             </a>
//         ),
//         //   icon: <SmileOutlined />,
//         disabled: true,
//     },
//     {
//         key: '3',
//         label: (
//             <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
//                 3rd menu item (disabled)
//             </a>
//         ),
//         disabled: true,
//     },
//     {
//         key: '4',
//         danger: true,
//         label: 'a danger item',
//     },
// ];
const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location'
    },
    {
        title: 'Text',
        dataIndex: 'text',
        key: 'text'
    },
    {
        title: 'Image',
        dataIndex: 'image',
        key: 'image'
    },
    {
        title: 'Article',
        dataIndex: 'article',
        key: 'article'
    },
    {
        title: 'Video',
        dataIndex: 'video',
        key: 'video'
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (_, state) => {
            const menu = (
                <Menu
                    items={[
                        {
                            label: (
                                <Link
                                    to={`/admin/staff/edit/${data.id}`}
                                    className="text-dark"
                                >
                                    Edit
                                </Link>
                            ),
                            key: "0",
                        },
                        {
                            type: "divider",
                        },
                        {
                            label: (
                                <>
                                    Delete
                                </>
                            ),
                            key: "1",
                        },
                    ]}
                />
            );
            return (<>
                <div className="d-flex">
                    <div className="toggle_field mr-4">
                        <div className="box_content w-100 p-0">
                            <div className="form-check form-switch mw-100">
                                <input className="form-check-input" type="checkbox" id="maxNoOfBookings" name="max_no_booking_status"
                                />
                            </div>
                        </div>
                    </div>
                    <Dropdown menu={{ menu }}>
                        <a onClick={(e) => e.preventDefault()}>
                            <img src={Elipsis} alt="" className="elipsis" style={{ maxWidth: 4 }} />
                        </a>
                    </Dropdown>
                </div>
            </>)
        },
    },
];
const data = [
    {
        key: '1',
        id: '12541',
        location: 'Availability Area',
        text: <><p>Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</p></>,
        image: <><img src={DemoImg} alt="Tooltip Img" /></>,
        article: <><Link to="/#">Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</Link></>,
        video: <><Link to="/#">Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</Link></>,
    },
    {
        key: '2',
        id: '12541',
        location: 'Availability Area',
        text: <><p>Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</p></>,
        image: <><img src={DemoImg} alt="Tooltip Img" /></>,
        article: <><Link to="/#">Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</Link></>,
        video: <><Link to="/#">Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</Link></>,
    },
    {
        key: '3',
        id: '12541',
        location: 'Availability Area',
        text: <><p>Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</p></>,
        image: <><img src={DemoImg} alt="Tooltip Img" /></>,
        article: <><Link to="/#">Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</Link></>,
        video: <><Link to="/#">Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</Link></>,
    },
    {
        key: '4',
        id: '12541',
        location: 'Availability Area',
        text: <><p>Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</p></>,
        image: <><img src={DemoImg} alt="Tooltip Img" /></>,
        article: <><Link to="/#">Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</Link></>,
        video: <><Link to="/#">Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</Link></>,
    },
    {
        key: '5',
        id: '12541',
        location: 'Availability Area',
        text: <><p>Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</p></>,
        image: <><img src={DemoImg} alt="Tooltip Img" /></>,
        article: <><Link to="/#">Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</Link></>,
        video: <><Link to="/#">Lorem ipsum dolor sit amet, conscecteture lorem ipsum dolor sit</Link></>,
    },
];
const TooltipManagment = () => {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [state, setstate] = useState();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    useEffect(() => {
        setIsPageLoading(false);
    }, []);
    return (<>
        <Row className="app-user-list">
            <Loader isShowLoader={isPageLoading} />
            <Col sm="12">
                <Filter
                    name="Tooltip Management"
                    showButtons={true}
                    buttons={
                        <button
                            className="btn btn-app btn-add mr-4"
                            type="button"
                            style={{ width: "126px" }}
                            onClick={toggle}>
                            Add New
                        </button>
                    }
                />
                <Modal className="tooltip_modal" isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Add New Tooltip</ModalHeader>
                    <ModalBody>
                        <form>
                            <div className="form-group">
                                <label htmlFor="location">Add location</label>
                                <input type="text" name="location" id="location" placeholder='Add location' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="tooltipText">Add text</label>
                                <input type="text" name="tooltipText" id="tooltipText" placeholder='Add text' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="articleLink">Add Article Link</label>
                                <TextEditor
                                    name="articleLink"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="videoLink">Add Video Link</label>
                                <TextEditor
                                    name="videoLink"
                                />
                            </div>
                            <div className="img_upload">
                                <label htmlFor="tooltipImg">
                                    <input type="file" name="tooltipImg" id="tooltipImg" />
                                    <img src={UploaderIcon} alt="Upload icon" />
                                    <p>Drop your image here, or <span>browse</span> here</p>
                                </label>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="col-md-6 px-0" onClick={toggle}>
                            <button className='btn btn-outline'>Cancel</button>
                        </div>
                        <div className="col-md-6 px-0">
                            <button className='btn btn-app'>Add tooltip</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </Col>
            <Col sm="12">
                <Table
                    columns={columns}
                    dataSource={data}
                    className="tooltip_table"
                />
            </Col>
        </Row>
    </>);
}

export default TooltipManagment;