import React, { Component } from "react";
import One from "../../assets/bookingeventassets/assets/1.svg";

import P from "../../assets/bookingeventassets/assets/p.svg";
import C from "../../assets/bookingeventassets/assets/c.svg";
import Four from "../../assets/bookingeventassets/assets/4.svg";
import "react-calendar/dist/Calendar.css";

import "../../assets/bookingeventassets/paidcss/freecss.css";

import Calendar from "react-calendar";
import {
  getAvailableTimeSlots,
  getAvailableDates,
} from "../../actions/bookingAction";
import { connect } from "react-redux";
import {
  notification,
  stripHTMLTags,
  getUserBrowserTimezone,
} from "../../utils/utility";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ZoomIcon from "../../assets/icons/zoom.svg";
import MeetIcon from "../../assets/icons/google-meet.svg";
import TeamsIcon from "../../assets/icons/Microsoft_Teams.svg";
import GoToIcon from "../../assets/icons/goto_icon.svg";
import PhoneCall from "../../assets/icons/phone_call.svg";
import InPersonMeet from "../../assets/icons/in_person_meet.svg";
import OtherMeet from "../../assets/icons/other_meet_icon.svg";
import CloseIcon from "../../assets/icons/close_blue.svg";
import SearchIocn from "../../assets/icons/search_icon.svg";
import GlobeIocn from "../../assets/icons/Globe.svg";
import { getTimezones } from "../../actions/companyActions";
import isEmpty from "../../validation/is-empty";
import moment from "moment";
import Loader from "../../components/Loader/Loader";
import { getAdminBusinessId } from "../../utils/authHelper";
import * as Icon from "react-feather";
import { Collapse } from "antd";
const { Panel } = Collapse; 
class BookingStep1 extends Component {
  state = {
    isLoading: false,
    isDebuggingEnabled: false,
    calendarDate: new Date(),
    selectedStaffId: null,
    slots: [],
    selectedSlot: null,
    timezones: [],
    timezoneId: "",
    isDateSelected: false,
    availableDates: [],
  };

  /**
   *
   */
  componentDidMount() {
    const _this = this;
    const { getTimezones, onSelectTimzone } = _this.props;

    _this.getMonthAvailableDates(moment().month() + 1, moment().year());

    getTimezones({
      data: {},
      onSuccess: function (data) {
        _this.setState({
          timezones: data.timezones,
        });
        const userBrowserTimezone = getUserBrowserTimezone();
        data.timezones.forEach((timezone, index) => {
          if (userBrowserTimezone === timezone.timezone_value) {
            _this.setState({ timezoneId: timezone.value });
            onSelectTimzone(timezone.value, timezone.timezone_value);
          }
        });
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  }


  /**
   * toggle debugging option
   */
  toggleDebugging = () => {
    this.setState((prevState, props) => ({
      isDebuggingEnabled: !prevState.isDebuggingEnabled
    })); 

  }

  /**
   * Get dates of month
   * @param int month
   * @param int year
   * @returns array
   */
  getMonthAvailableDates = (month, year, staffId = null) => {
    const _this = this;
    const { getAvailableDates, event } = _this.props;
    
    if (event.staffs && event.staffs.length > 0 && event.allow_attendee_select_staff == 'E' && isEmpty(staffId)) {
      return;
    }

    _this.setState({ isLoading: true });

    //console.log("month", month, "year", year);
    let daysInMonth = moment(year + "-" + month, "YYYY-M").daysInMonth();
    //console.log("days in month, ", daysInMonth);
    let monthDates = [];
    
    const monthStartDate = moment(year + "-" + month + "-" + 1, "YYYY-M-D").format(
      "YYYY-M-D"
    ); 
    const monthEndDate = moment(year + "-" + month + "-" + daysInMonth, "YYYY-M-D").format(
      "YYYY-M-D"
    );

    while (daysInMonth) {
      monthDates.push(
        moment(year + "-" + month + "-" + daysInMonth, "YYYY-M-D").format(
          "YYYY-M-D"
        )
      );
      daysInMonth--;
    }

    

    getAvailableDates({
      data: {
        dates: monthDates.reverse(),
        event_id: _this.props.event.id,
        timezone: getUserBrowserTimezone(),
        business_id: getAdminBusinessId(),
        staff_id : staffId,
        month_start_date: monthStartDate, 
        month_end_date: monthEndDate
      },
      onSuccess: function (result) {
        const { data } = result.data;
        _this.setState({
          availableDates: data,
          isLoading: false,
        });
      },
      onError: function (error) {
        _this.setState({
          isLoading: false,
        });
      },
    });
  };

  /**
   * on select time slot
   * @param {*} timeslot
   */
  onSelectTimeSlot = (timeslot) => {
    const { onSelectSlot } = this.props;
    onSelectSlot(timeslot);
    this.setState({
      selectedSlot: timeslot,
    });
  };

  /**
   * on confirm
   */
  onConfirm = () => {
    const { onUpdateStep } = this.props;

    /**
     * if isRescheduleEvent exist then reschedule event
     */
    console.log(
      "testing",
      this.props.isRescheduleEvent && this.props.reschedule
    );
    if (this.props.isRescheduleEvent && this.props.reschedule) {
      this.props.reschedule();
    } else {
      onUpdateStep(2);
    }
  };

  /**
   * on go to previous month button handler
   * @param {*} event
   */
  onGotoPrevMonth = (event) => {
    const _this = this;
    event.preventDefault();
    const { calendarDate, selectedStaffId } = this.state;
    const dated = moment(calendarDate).subtract(1, "M");
    //console.log("onGotoPrevMonth dated", dated.toDate());
    _this.setState({
      calendarDate: dated.toDate(),
    });
    this.getMonthAvailableDates(dated.format("M"), dated.format("YYYY"));
  };

  /**
   * on go to next month button handler
   */
  onGotoNextMonth = (event) => {
    const _this = this;
    event.preventDefault();
    const { calendarDate, selectedStaffId } = this.state;
    //console.log("calendar Date", calendarDate);
    const dated = moment(calendarDate).add(1, "M");
    //console.log("onGotoNextMonth dated", dated.toDate());
    _this.setState({
      calendarDate: dated.toDate(),
    });
    this.getMonthAvailableDates(dated.format("M"), dated.format("YYYY"));
  };

  /**
   *
   * @param {*} date
   */
  onCalendarDateChange = (date) => {
    const _this = this;
    const { getAvailableTimeSlots, onSelectDate, event } = this.props;
    const { timezoneId, timezones, selectedStaffId, isDateSelected } =
      _this.state;

    if (!isDateSelected) {
      _this.setState({ isDateSelected: true });
    }

    if (event.staffs && event.staffs.length > 0 && event.allow_attendee_select_staff == 'E' && isEmpty(selectedStaffId)) {
      return notification.warning("Please first select staff");
    }

    let timezone = "";

    if (isEmpty(timezoneId)) {
      return notification.warning("Please select timezone");
    } else {
      timezone = timezones.find((tz) => {
        return tz.value == timezoneId;
      });
    }

    if (isEmpty(timezone)) {
      return notification.warning("Please select timezone");
    }

    console.log("onCalendarDateChange", date);

    _this.setState({
      calendarDate: date,
      isLoading: true
    });

    onSelectDate(date);

    let data = {
      selected_date:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
      timezone: timezone.timezone_value,
      business_id: _this.props.event.business_id,
      event_id: _this.props.event.id,
    };

    if (selectedStaffId) {
      data["staff_id"] = selectedStaffId;
    }

    getAvailableTimeSlots({
      data,
      onSuccess: function (response) {
        console.log("response", response);
        _this.setState({
          slots: response,
          isLoading: false
        });
      },
      onError: (error) => {
        notification.error(error.response.data.message);
        _this.setState({
          isLoading: false
        });
      },
    });
  };

  render() {
    const _this = this;
    const { event, onSelectStaff, onSelectTimzone, specialRibbon } = this.props;
    const {
      calendarDate,
      slots,
      selectedSlot,
      timezones,
      timezoneId,
      isDateSelected,
      isLoading,
      availableDates,
      isDebuggingEnabled,
      selectedStaffId
    } = this.state;

    return (
      <>
        <Loader isShowLoader={isLoading} />
        <div className="row mb-2 pb-1">
          {!isEmpty(specialRibbon) && (
            <div className="col-12">
              <nav className="navbar navbar-expand-sm main_noti shadow">
                <ul className="navbar-nav">
                  <li className="nav-item noti_side">
                    <img src={One} />
                  </li>

                  <li className="nav-item left_noti ml-3 ml-md-0">
                    <span>{specialRibbon}</span>
                  </li>
                </ul>
              </nav>
            </div>
          )}

          {event.staffs && event.staffs.length > 0 && event.allow_attendee_select_staff == 'E' && (
            <div className="col-12">
              <div className="booking_arrow row align-items-center mx-0">
                <div className="col-12">
                  <h1> {event.event_name} </h1>
                </div>
                <div className="col-12 bookig_staff_sec">
                  <div className="staff_slect">
                    <form>
                      <div className="head">
                        <h3>Choose a Staff</h3>
                        <div className="search_inp invisible">
                          <input
                            type="text"
                            name="search"
                            placeholder="Search"
                          />
                          <img src={SearchIocn} alt="search icon" />
                        </div>
                      </div>
                      <div className="staff_select_main">
                        {(event.staffs || []).map((staff, index) => {
                          return (
                            <div
                              className="slide_inner position-relative"
                              key={`staff-select-${index}`}
                            >
                              <input
                                type="radio"
                                name="event-staff"
                                value={staff.id}
                                id={`staff-${staff.id}`}
                                onChange={(e) => {
                                  onSelectStaff(e.target.value);
                                  _this.setState({
                                    selectedStaffId: e.target.value,
                                  });
                                  _this.getMonthAvailableDates(moment(calendarDate).format("M"), moment(calendarDate).format("YYYY"), e.target.value);
                                }}
                              />
                              <label htmlFor={`staff-${staff.id}`}>
                                <img
                                  src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg"
                                  alt=""
                                  className="staff_img"
                                />
                                <p>
                                  {" "}
                                  {`${staff.first_name} ${staff.last_name}`}
                                </p>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-xl-6 mb-4 mb-xl-0">
            <div className="action_title">
              <h3>Select Date</h3>
            </div>
            <form className="row">
              <div className="col-md-12">
                <div className="cale_container position-relative">
                  {availableDates.length === 0 && (
                    <div className="nodate_avail">
                      {(event.staffs && event.staffs.length > 0 && isEmpty(selectedStaffId)) ? (
                      <div className="txt">
                        <p>Please select Staff</p>
                      </div>
                      ) : (
                        <>
                          <button
                            className="btn p-0 arrow prev"
                            onClick={this.onGotoPrevMonth}
                          >
                          <Icon.ChevronLeft size={20} />
                          </button>
                          <div className="txt">
                            <p>No Dates Available</p>
                            <button
                              className="btn p-0"
                              onClick={this.onGotoNextMonth}
                            >
                              Go to next Month
                            </button>
                          </div>
                          <button
                            className="btn p-0 arrow next"
                            onClick={this.onGotoNextMonth}
                          >
                            <Icon.ChevronRight size={20} />
                          </button>
                        </>
                      )}
                      

                    </div>
                  )}

                  <Calendar
                    tileClassName={({ date, view }) => {
                      const calDated =
                        date.getFullYear() +
                        "-" +
                        (date.getMonth() + 1) +
                        "-" +
                        date.getDate();
                      
                      if (view === "month") {
                        if (!isDebuggingEnabled) {
                          if (
                            availableDates.find(
                              (availableDate) => availableDate === calDated
                            ) &&
                            moment(calDated, "YYYY-M-D").diff(
                              new Date(),
                              "days"
                            ) >= 0
                          ) {
                            return "highlight";
                          } else {
                            return "rc-disabled-day";
                          }
                        } else {
                          if (
                            availableDates.find(
                              (availableDate) => availableDate === calDated
                            ) &&
                            moment(calDated, "YYYY-M-D").diff(
                              new Date(),
                              "days"
                            ) >= 0
                          ) {
                            return "highlight";
                          } else {
                            console.log('calDated', calDated, moment(calDated).isSame(new Date(), 'month'));
                            if (!moment(calDated).isSame(calendarDate, 'month')) {
                              return "rc-disabled-day";
                            }
                          }

                        }
                      }
                      return "";
                      
                    }}
                    minDate={!isDebuggingEnabled ? new Date() : moment().startOf('month').toDate()}
                    onChange={this.onCalendarDateChange}
                    activeStartDate={calendarDate}
                    className="cus_style"
                    locale="en-GB"
                    onClickMonth={(value) => {
                      const _this = this;
                      const dated = moment(value);
                      const month = dated.format("M");
                      const year = dated.format("YYYY");
                      _this.setState({
                        calendarDate: dated.toDate(),
                      });
                      _this.getMonthAvailableDates(month, year, selectedStaffId);
                    }}
                    onActiveStartDateChange={({ action }) => {
                      const _this = this;
                      const { calendarDate } = _this.state;
                      let dated = null;
                      /***
                       * action Params details
                       * next = Next month
                       * next2 = Next year
                       * prev = Previous month
                       * prev2 = Previous year
                       */
                      if (action === "next" || action === "prev") {
                        // next month or previous month
                        dated =
                          action === "next"
                            ? moment(calendarDate).add(1, "M")
                            : moment(calendarDate).subtract(1, "M");
                      } else if (action === "next2" || action === "prev2") {
                        // next year or previou year
                        dated =
                          action === "next"
                            ? moment(calendarDate).add(1, "y")
                            : moment(calendarDate).subtract(1, "y");
                      }

                      //console.log("action", action);

                      if (["next", "prev", "next2", "prev2"].includes(action)) {
                        _this.setState({
                          calendarDate: dated.toDate(),
                        });
                        _this.getMonthAvailableDates(
                          dated.format("M"),
                          dated.format("YYYY"),
                          selectedStaffId
                        );
                      }
                    }}
                  />
                </div>
                  {_this.props.auth.isAuthenticated && (
                    <>
                      <div className="debug-btn">
                        <span className="tooltip-wrapper">
                          <button className="btn btn-app w-auto" type="button" onClick={this.toggleDebugging}>
                            <span className="mr-2">
                                  <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" role="img"><path d="M2.475 9.166a1.16 1.16 0 0 1-1.641-.013h0a1.16 1.16 0 0 1 .013-1.641l3.392-3.42A2.7 2.7 0 0 1 6.949.505a.391.391 0 0 1 .257.667l-.671.66a1.161 1.161 0 0 0 1.629 1.655l.67-.66a.391.391 0 0 1 .663.265 2.7 2.7 0 0 1-3.626 2.652Z" fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round"></path></svg>
                            </span> 
                            Debug
                          </button>
                          <div className="tooltip-section" >
                              <div className="cust_tooltip">
                                <div className="body">
                                  <h3>Debugging your availability</h3>
                                  <p>
                                    ''learn why particular times are not accessible on your calendar''
                                    </p>
                                    <p>
                                      "Your invitees will not have visibility of this information."
                                    </p>
                                    {/* <p>
                                      <span className="me-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                                      </span>
                                    Don't worry – your invitees will never see this information.
                                  </p> */}
                              </div> 
                            </div>
                          </div>
                          </span>
                      </div>
                    </>
                  )}
                  

              </div>
            </form>
          </div>
          {(slots || []).length > 0 ? (
            <>
              <div className="col-xl-6">
                <div className="action_title d-flex justify-content-between align-items-center">
                  <h3>Select Time</h3>
                  <div className="cus_timezone">
                    <img src={GlobeIocn} alt="Globe Icon" />
                    <select
                      value={timezoneId}
                      onChange={(event) => {
                        _this.setState({
                          timezoneId: event.target.value,
                        });
                        onSelectTimzone(
                          event.target.value,
                          event.target.selectedOptions[0].text
                        );
                      }}
                    >
                      <option value="">Select Timezone</option>
                      {(timezones || []).map((timezone, index) => {
                        return (
                          <option key={index} value={timezone.value}>
                            {timezone.timezone_value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-12 px-0">
                  <div className="row main_time_div time_slots_wrapper">
                  {_this.props.auth.isAuthenticated && isDebuggingEnabled && (
                    <Collapse>
                      {(slots || []).map((item, index) => {
                      if (item.status === 'NA') {
                        return (
                          <Panel header={<div className="custom-panel-header" key={`slot-${index}`}>
                            <div className="booked-time">
                              <span>{item.slot}</span>
                              <a href="#" className="details-link">
                                  <div className="cust-icon">
                                      <span role="img" aria-label="right" className="anticon anticon-right ant-collapse-arrow">
                                        <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                          <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                                          </svg>
                                      </span>
                                  </div>
                                details
                              </a>
                            </div>
                            <div className="custom-status">UNAVAILABLE</div>
                              <div className="button-container">
                              {(item.data || []).map((data, innerIndex) => {
                                return <button>{data.title}</button>
                              })}
                              </div>
                            </div>} key={index}>
                            <div className="custom-panel-content">
                              {(item.data || []).map((data, innerIndex) => {
                                return <div className="info-text">{data.title} - <span>{data.message}</span></div>
                              })}
                            </div>
                          </Panel>
                        );
                      } else {
                        return (
                          <div className="cust-check" onClick={() => this.onSelectTimeSlot(item.slot)}>
                              <div className="check-btn cust-check time-slots">
                                <input type="radio" name="whenStart" id="time-slot-1"></input>
                                <label htmlFor="time-slot-1">
                                    <span>{item.slot}</span>
                                </label>
                              </div>
                          </div>
                        );
                      } 
                      
                    })} 
                    </Collapse>
                  )}

                  {!isDebuggingEnabled && (
                    <>
                      {(slots || []).map((item, index) => {
                        if (item.status === 'A') {
                          return (
                            <div className="cust-check" onClick={() => this.onSelectTimeSlot(item.slot)}>
                                <div className="check-btn cust-check time-slots">
                                  <input type="radio" name="whenStart" id="time-slot-1"></input>
                                  <label htmlFor="time-slot-1">
                                      <span>{item.slot}</span>
                                  </label>
                                </div>
                            </div>
                          ); 
                        }
                      })} 
                    </>
                  )}

                  {!isDebuggingEnabled && (slots || []).filter(item => item.status === 'A').length === 0 && (
                    <p className="text-center">No Slot Available</p>
                  )}

                  {/* {(slots || []).map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`col-5 main_time ${
                            selectedSlot == item.slot ? "time_active" : null
                          }`}
                          onClick={() => this.onSelectTimeSlot(item.slot)}
                        >
                          <span>{item.slot}</span>
                        </div>
                      );
                    })} */}
                  </div>
                </div>

                <div className="col-12 confirm_button">
                  {selectedSlot ? (
                    <button
                      type="button"
                      className="btn btn-app"
                      id="cnfrm_btn"
                      onClick={this.onConfirm}
                    >
                      {_this.props.isRescheduleEvent
                        ? "Reschedule"
                        : `Confirm ${selectedSlot}`}
                    </button>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="col-xl-6">
              <div className="action_title d-flex justify-content-between align-items-center">
                <h3>Select Time</h3>
                <div className="cus_timezone">
                  <img src={GlobeIocn} alt="Globe Icon" />
                  <select
                    value={timezoneId}
                    onChange={(event) => {
                      _this.setState({ timezoneId: event.target.value });
                      onSelectTimzone(
                        event.target.value,
                        event.target.selectedOptions[0].text
                      );
                    }}
                  >
                    <option value="">Select Timezone</option>
                    {(timezones || []).map((timezone, index) => {
                      return (
                        <option key={index} value={timezone.value}>
                          {timezone.timezone_value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-12 px-0">
                <div className="row main_time_div cus_style booking-time-style answer_list">
                  {!isDateSelected ?
                      <p className="text-center">Select date to see available slots.</p>
                      :
                      <p className="text-center">No Slot Available</p>
                    }
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="main_add pb-2 meeting-data">
              <div className="row justify-content-between align-items-center m-0 pe-xl-4">
                <div className="col-md-auto mb-3 mb-lg-0">
                  <div className="row justify-content-start align-items-center">
                    <div className="sechulde_s">
                      <span className="event_label">Venue:</span>
                      {event.venue === "Microsoft Teams" ? (
                        <img src={TeamsIcon} alt="microsoft teams icon" />
                      ) : event.venue === "Zoom" ? (
                        <img src={ZoomIcon} alt="Zoom icon" />
                      ) : event.venue === "Google Meet" ? (
                        <img src={MeetIcon} alt="Google Meet icon" />
                      ) : event.venue === "Phone Call" ? (
                        <img src={PhoneCall} alt="Phone Call icon" />
                      ) : event.venue === "In-Person Meeting" ? (
                        <img
                          src={InPersonMeet}
                          alt="In Person Meet icon"
                          style={{ maxWidth: 13 }}
                        />
                      ) : event.venue === "Others" ? (
                        <img src={OtherMeet} alt="Other icon" />
                      ) : event.venue === "Go to Meeting" ? (
                        <img
                          src={GoToIcon}
                          alt="Go To icon"
                          style={{ maxWidth: 30 }}
                        />
                      ) : (
                        <img src={P} alt="event_img" />
                      )}
                    </div>
                    <div className="sechulde_d">{event.venue}</div>
                  </div>
                </div>
                <div className="col-md-auto mb-3 mb-lg-0">
                  <div className="row justify-content-start align-items-center">
                    <div className="sechulde_s">
                      <span className="event_label">Event duration:</span>
                      <img src={C} />
                    </div>
                    <div className="sechulde_d">
                      {event.timeslot !== "Customized"
                        ? event.timeslot + " minutes"
                        : event.timeslot}{" "}
                    </div>
                  </div>
                </div>
                <div className="col-md-auto mb-3 mb-md-0">
                  <div className="row justify-content-start align-items-center">
                    <div className="sechulde_s">
                      <span className="event_label">Event format:</span>
                    </div>
                    <div className="sechulde_d">
                      {event.event_format_id === 1 ? "One On One" : "Group"}
                    </div>
                  </div>
                </div>
                <div className="col-md-auto">
                  <div className="row justify-content-start align-items-center">
                    <div className="sechulde_s">
                      <span className="event_label">Event Type:</span>
                    </div>
                    <div className="sechulde_d">
                      {event.is_event_paid === "E" ? (
                        <>
                          Paid <span>$ {event.event_fee}</span>
                        </>
                      ) : (
                        <>Free</>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event_desc">
          <div className="row">
            <div className="col-12">
              <div className="head">
                <h3>Description</h3>
              </div>
            </div>
            <div className="col-12">
              <div className="desc p-3">
                <p>{stripHTMLTags(event.event_description)}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAvailableTimeSlots,
  getTimezones,
  getAvailableDates,
})(BookingStep1);
