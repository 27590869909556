import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ErrorPage from "../errors/ErrorPage";
import AdhocEventErrorPage from "../errors/AdhocEventErrorPage";
import Loader from "../../components/Loader/Loader";
import logo from "../../assets/bookingeventassets/assets/logo.svg";
import isEmpty from "../../validation/is-empty";
import SideLogo from "../../assets/bookingeventassets/assets/sidelogo.svg";
import SideBarFootLogo from "../../assets/bookingeventassets/assets/side_bar_foot_logo.svg";
import Three from "../../assets/bookingeventassets/assets/3.jpg";
import { useParams } from "react-router-dom";
import {
  notification,
  getUserBrowserTimezone,
  getDBFormatedDate,
  gotProperty,
} from "../../utils/utility";
import { getEvent } from "../../actions/bookingAction";
import { getTimezones } from "../../actions/companyActions";
import { Input, FormFeedback } from "reactstrap";
import FormField from "../../components/FormField/FormField";
import One from "../../assets/bookingeventassets/assets/1.svg";
import * as Icon from "react-feather";
import moment from "moment";
import GlobeIocn from "../../assets/icons/Globe.svg";
import Calendar from "react-calendar";
import { sendPostRequest } from "../../actions/app-actions";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";

let initialValues = {
  name: "",
  email: "",
  message_from_customer: "",
  reminder_phone_no: "",
  invitee_guests: [],
};

const validation = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  reminder_phone_no: Yup.string().required("Phone number is required."),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      "Please enter valid email"
    )
    .required("Email is required"),
});

const AdhocBooking = (props) => {
  const { slug, dated, slot } = useParams();

  const [error, setError] = useState(null);
  const [adhocError, setAdhocError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState({});
  const [company, setCompany] = useState({});
  const [specialRibbon, setSpecialRibbon] = useState(null);
  const [timezones, setTimezones] = useState([]);
  const [timezoneId, setTimezoneId] = useState("");
  const [timezoneValue, setTimezoneValue] = useState("");
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [availableDates, setAvailableDates] = useState([]);
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [step, setStep] = useState(isEmpty(dated) ? 1 : 2);
  const [guestEmails, setGuestEmails] = useState([]);
  const [endSlotTime, setEndSlotTime] = useState(null);
  const [bookingDate, setBookingDate] = useState(null);

  const [name, setName] = useState(null);

  useEffect(() => {
    const { getEvent, getTimezones } = props;
    setIsLoading(true);
    getEvent({
      data: {
        slug,
      },
      onSuccess: function (response) {
        if (gotProperty(response, "is_booked")) {
          setAdhocError(true);
          setName(response.user);
          return;
        }

        if (isEmpty(response.company)) {
          setIsLoading(false);
          setError("Company information is missing!");
          return;
        }
        setIsLoading(false);
        setEvent(response.event);
        getMonthAvailableDates(
          moment().month() + 1,
          moment().year(),
          response.event.id
        );
        setCompany(response.company);
        setSpecialRibbon(
          !isEmpty(response.special_ribbon)
            ? response.special_ribbon.special_announcement
            : null
        );

        if (!isEmpty(dated) && !isEmpty(slot)) {
          setSelectedSlot(slot);
          setBookingDate(dated);
          setStep(2);
        }
      },
      onError: (error) => {
        setIsLoading(false);
        setError("This event is invalid or no longer available");
        notification.error(error.response.data.message);
      },
    });

    getTimezones({
      data: {},
      onSuccess: function (data) {
        setTimezones(data.timezones);
        const userBrowserTimezone = getUserBrowserTimezone();
        data.timezones.forEach((timezone, index) => {
          if (userBrowserTimezone === timezone.timezone_value) {
            setTimezoneId(timezone.value);
            setTimezoneValue(timezone.timezone_value);
            //onSelectTimzone(timezone.value, timezone.timezone_value);
          }
        });
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  }, []);

  /**
   * on select time slot
   * @param {*} timeslot
   */
  const onSelectTimeSlot = (timeslot) => {
    setSelectedSlot(timeslot);
  };

  /**
   *
   * @param {*} date
   */
  const onCalendarDateChange = (date) => {
    const { sendPostRequest } = props;

    let timezone = "";
    if (isEmpty(timezoneId)) {
      return notification.warning("Please select timezone");
    } else {
      timezone = timezones.find((tz) => {
        return tz.value == timezoneId;
      });
    }

    if (!isDateSelected) {
      setIsDateSelected(true);
    }
    setCalendarDate(date);
    setBookingDate(getDBFormatedDate(new Date(date)));

    sendPostRequest({
      url: `/admin/adhoc-available-slots`,
      data: {
        timezone: timezone.timezone_value,
        selected_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        event_id: event.id,
      },
      onSuccess: function (response) {
        const { data } = response;
        console.log("data", data);
        setSlots(data.data);
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  };

  /**
   * Get dates of month
   * @param int month
   * @param int year
   * @returns array
   */
  const getMonthAvailableDates = (month, year, eventId = "") => {
    console.log("month", month, "year", year);
    const { sendPostRequest } = props;

    sendPostRequest({
      url: `/admin/adhoc-available-dates`,
      data: {
        month,
        year,
        event_id: isEmpty(eventId) ? event.id : eventId,
      },
      onSuccess: function (response) {
        const { data } = response;
        console.log("data", data);
        setAvailableDates(data.data);
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  };

  /**
   * on go to previous month button handler
   * @param {*} event
   */
  const onGotoPrevMonth = (event) => {
    event.preventDefault();
    const dated = moment(calendarDate).subtract(1, "M");
    setCalendarDate(dated.toDate());
    getMonthAvailableDates(dated.format("M"), dated.format("YYYY"));
  };

  /**
   * on go to next month button handler
   */
  const onGotoNextMonth = (event) => {
    event.preventDefault();
    const dated = moment(calendarDate).add(1, "M");
    setCalendarDate(dated.toDate());
    getMonthAvailableDates(dated.format("M"), dated.format("YYYY"));
  };

  /**
   * on confirm
   */
  const onConfirm = () => {
    setStep(2);
  };

  return (
    <>
      {adhocError ? (
        <AdhocEventErrorPage name={name} />
      ) : (
        <>
          {error ? (
            <ErrorPage message={error} />
          ) : (
            <>
              <Loader isShowLoader={isLoading} />
              <div className="container-fluid">
                <nav className="navbar navbar-expand-sm justify-content-center top_nav shadow">
                  <a className="navbar-brand" href="#">
                    <img src={logo} alt="Logo" style={{ width: "100%" }} />
                  </a>
                </nav>
              </div>
              <div className="main_div booking_main_row mt-4">
                <div className="left_div">
                  <nav className="navbar side_navbar">
                    <ul className="navbar-nav side_bar_ul">
                      <li className="nav-item logo">
                        <img src={SideLogo} alt="logo" />
                      </li>
                      <li className="nav-item featured">
                        <img className="w-100" src={Three} alt="feature_img" />
                      </li>
                      <li className="nav-item">
                        <h2>{company.company_name}</h2>
                        <p>{company.company_description}</p>
                      </li>
                      <li className="nav-item">
                        <h2>Contact Details</h2>
                        <p className="sub_head">
                          <span>Email:</span> <br />
                          {company.company_email}
                        </p>
                        <p className="sub_head">
                          <span>Phone No:</span> <br />
                          {company.company_phone_no}
                        </p>
                        <p className="sub_head">
                          <span>Address</span> <br />
                          {company.company_address}
                        </p>

                        <div className="row side_bar_foot_img_div">
                          <div className="col-12 side_bar_foot_logo_img">
                            <img src={SideBarFootLogo} />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="right_div">
                  <div className="row mb-2 pb-1">
                    {!isEmpty(specialRibbon) && (
                      <div className="col-12">
                        <nav className="navbar navbar-expand-sm main_noti shadow">
                          <ul className="navbar-nav">
                            <li className="nav-item noti_side">
                              <img src={One} />
                            </li>
                            <li className="nav-item left_noti ml-3 ml-md-0">
                              <span>{specialRibbon}</span>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    )}
                  </div>
                  {step === 1 && (
                    <div className="row">
                      <div className="col-xl-6 mb-4 mb-xl-0">
                        <div className="action_title">
                          <h3>Select Date</h3>
                        </div>
                        <form className="row">
                          <div className="col-md-12">
                            <div className="cale_container position-relative">
                              {availableDates.length === 0 && (
                                <div className="nodate_avail">
                                  <button
                                    className="btn p-0 arrow prev"
                                    onClick={onGotoPrevMonth}
                                  >
                                    <Icon.ChevronLeft size={20} />
                                  </button>
                                  <div className="txt">
                                    <p>No Dates Available</p>
                                    <button
                                      className="btn p-0"
                                      onClick={onGotoNextMonth}
                                    >
                                      Go to next Month
                                    </button>
                                  </div>
                                  <button
                                    className="btn p-0 arrow next"
                                    onClick={onGotoNextMonth}
                                  >
                                    <Icon.ChevronRight size={20} />
                                  </button>
                                </div>
                              )}

                              <Calendar
                                tileClassName={({ date, view }) => {
                                  const calDated =
                                    date.getFullYear() +
                                    "-" +
                                    (date.getMonth() + 1) +
                                    "-" +
                                    date.getDate();

                                  if (view === "month") {
                                    if (
                                      availableDates.find(
                                        (availableDate) =>
                                          availableDate === calDated
                                      ) &&
                                      moment(calDated, "YYYY-M-D").diff(
                                        new Date(),
                                        "days"
                                      ) >= 0
                                    ) {
                                      return "highlight";
                                    } else {
                                      return "rc-disabled-day";
                                    }
                                  }
                                  return "";
                                }}
                                minDate={new Date()}
                                onChange={onCalendarDateChange}
                                activeStartDate={calendarDate}
                                className="cus_style"
                                locale="en-GB"
                                onClickMonth={(value) => {
                                  const dated = moment(value);
                                  const month = dated.format("M");
                                  const year = dated.format("YYYY");
                                  setCalendarDate(dated.toDate());
                                  getMonthAvailableDates(month, year);
                                }}
                                onActiveStartDateChange={({ action }) => {
                                  let dated = null;
                                  /***
                                   * action Params details
                                   * next = Next month
                                   * next2 = Next year
                                   * prev = Previous month
                                   * prev2 = Previous year
                                   */
                                  if (action === "next" || action === "prev") {
                                    // next month or previous month
                                    dated =
                                      action === "next"
                                        ? moment(calendarDate).add(1, "M")
                                        : moment(calendarDate).subtract(1, "M");
                                  } else if (
                                    action === "next2" ||
                                    action === "prev2"
                                  ) {
                                    // next year or previou year
                                    dated =
                                      action === "next"
                                        ? moment(calendarDate).add(1, "y")
                                        : moment(calendarDate).subtract(1, "y");
                                  }

                                  //console.log("action", action);

                                  if (
                                    ["next", "prev", "next2", "prev2"].includes(
                                      action
                                    )
                                  ) {
                                    setCalendarDate(dated.toDate());
                                    getMonthAvailableDates(
                                      dated.format("M"),
                                      dated.format("YYYY")
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      {(slots || []).length > 0 ? (
                        <>
                          <div className="col-xl-6">
                            <div className="action_title d-flex justify-content-between align-items-center">
                              <h3>Select Time</h3>
                              <div className="cus_timezone">
                                <img src={GlobeIocn} alt="Globe Icon" />
                                <select
                                  value={timezoneId}
                                  onChange={(event) => {
                                    setTimezoneId(event.target.value);
                                    /*
                                onSelectTimzone(
                                  event.target.value,
                                  event.target.selectedOptions[0].text
                                );
                                */
                                  }}
                                >
                                  <option value="">Select Timezone</option>
                                  {(timezones || []).map((timezone, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={timezone.value}
                                      >
                                        {timezone.timezone_value}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-12 px-0">
                              <div className="row main_time_div time_slots_wrapper">
                                {(slots || []).map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={`col-5 main_time ${
                                        selectedSlot == item
                                          ? "time_active"
                                          : null
                                      }`}
                                      onClick={() => onSelectTimeSlot(item)}
                                    >
                                      <span>{item}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="col-12 confirm_button">
                              {selectedSlot ? (
                                <button
                                  type="button"
                                  className="btn btn-app"
                                  id="cnfrm_btn"
                                  onClick={onConfirm}
                                >
                                  {`Confirm ${selectedSlot}`}
                                </button>
                              ) : (
                                <> </>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col-xl-6">
                          <div className="action_title d-flex justify-content-between align-items-center">
                            <h3>Select Time</h3>
                            <div className="cus_timezone">
                              <img src={GlobeIocn} alt="Globe Icon" />
                              <select
                                value={timezoneId}
                                onChange={(event) => {
                                  setTimezoneId(event.target.value);
                                  /*
                              onSelectTimzone(
                                event.target.value,
                                event.target.selectedOptions[0].text
                              );
                              */
                                }}
                              >
                                <option value="">Select Timezone</option>
                                {(timezones || []).map((timezone, index) => {
                                  return (
                                    <option key={index} value={timezone.value}>
                                      {timezone.timezone_value}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-12 px-0">
                            <div className="row main_time_div cus_style">
                              <p className="text-center">
                                {!isDateSelected ? (
                                  <>Select date to see available slots.</>
                                ) : (
                                  <>No Slot Available.</>
                                )}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {step === 2 && (
                    <div className="row sub_div">
                      <div
                        className="col-12 main_add schedule-event-form"
                        style={{ padding: 30 }}
                      >
                        <Formik
                          validationSchema={validation}
                          initialValues={initialValues}
                          enableReinitialize
                          onSubmit={(data) => {
                            const { sendPostRequest } = props;

                            console.log("data", data);

                            const bookingDateTime =
                              bookingDate + " " + selectedSlot;
                            let endDate = new Date(bookingDateTime);
                            if (!isNaN(event.timeslot)) {
                              endDate.setTime(
                                endDate.getTime() + event.timeslot * 60 * 1000
                              );
                            }

                            const endSlot =
                              ("0" + endDate.getHours()).slice(-2) +
                              ":" +
                              ("0" + endDate.getMinutes()).slice(-2);

                            setEndSlotTime(endSlot);

                            setIsLoading(true);

                            const bookingDetails = {
                              event_id: event.id,
                              booking_date_time: bookingDateTime,
                              start_time: selectedSlot + ":00",
                              end_time: endSlot + ":00",
                              message_from_customer: data.message_from_customer,
                              reminder_phone_no: data.reminder_phone_no,
                              business_id: company.business_id,
                              timezone: timezoneValue,
                              email: data.email,
                              first_name: data.name,
                              last_name: data.name,
                            };

                            sendPostRequest({
                              url: `/adhoc-booking/save`,
                              data: bookingDetails,
                              onSuccess: function (response) {
                                setIsLoading(false);
                                const { data } = response;
                                notification.success(
                                  "Event is booked successfully"
                                );
                              },
                              onError: function (error) {
                                setIsLoading(false);
                                console.log("error", error);
                              },
                            });
                          }}
                        >
                          {(formProps) => {
                            const {
                              values,
                              errors,
                              touched,
                              handleChange,
                              setFieldValue,
                              setFieldTouched,
                            } = formProps;

                            return (
                              <Form>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <label
                                        className="form-group-label"
                                        htmlFor="exampleInputEmail1"
                                      >
                                        Name *
                                      </label>

                                      <FormField
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        errors={errors}
                                        touched={touched}
                                        value={values.name}
                                        onChange={({ target }) => {
                                          const { value } = target;
                                          handleChange({
                                            target: { name: "name", value },
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <label
                                        className="form-group-label"
                                        htmlFor="exampleInputEmail1"
                                      >
                                        Email *
                                      </label>
                                      <FormField
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                        autoComplete="new-off"
                                        errors={errors}
                                        touched={touched}
                                        value={values.email}
                                        onChange={({ target }) => {
                                          const { value } = target;
                                          handleChange({
                                            target: { name: "email", value },
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3">
                                    <label class="form-group-label">
                                      Guest Email(s)
                                    </label>
                                    <ReactMultiEmail
                                      placeholder="Please enter guests Email Address"
                                      emails={guestEmails}
                                      onChange={(_emails) => {
                                        setGuestEmails(_emails);
                                      }}
                                      getLabel={(email, index, removeEmail) => {
                                        return (
                                          <div data-tag key={index}>
                                            {email}
                                            <span
                                              data-tag-handle
                                              onClick={() => removeEmail(index)}
                                            >
                                              ×
                                            </span>
                                          </div>
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label
                                        className="form-group-label"
                                        htmlFor="exampleFormControlTextarea1"
                                      >
                                        Please share anything that will help
                                        prepare for our Meeting.
                                      </label>
                                      <Input
                                        type="textarea"
                                        className="form-control"
                                        name="message_from_customer"
                                        value={values.message_from_customer}
                                        onChange={({ target }) => {
                                          const { name, value } = target || {};
                                          handleChange({
                                            target: {
                                              name: "message_from_customer",
                                              value,
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      (event.venue_id === 1 ||
                                        event.venue_id === "1") &&
                                      event.venue_location_status ===
                                        "invitee_add_location"
                                        ? "col-md-6"
                                        : "col-md-12"
                                    }
                                  >
                                    <div className="form-group">
                                      <label
                                        className="form-group-label"
                                        htmlFor=""
                                      >
                                        Send text reminder to *
                                      </label>
                                      <PhoneInput
                                        containerClass="custom_phn_inp"
                                        country={"us"}
                                        value={values.reminder_phone_no}
                                        onBlur={() => {
                                          setFieldTouched(
                                            `reminder_phone_no`,
                                            true
                                          );
                                        }}
                                        onChange={(value) => {
                                          handleChange({
                                            target: {
                                              name: "reminder_phone_no",
                                              value,
                                            },
                                          });
                                        }}
                                      />
                                      {errors[`reminder_phone_no`] &&
                                        touched[`reminder_phone_no`] && (
                                          <FormFeedback className="d-block">
                                            {errors[`reminder_phone_no`]}
                                          </FormFeedback>
                                        )}
                                    </div>
                                  </div>
                                  {(event.venue_id === 1 ||
                                    event.venue_id === "1") &&
                                    event.venue_location_status ===
                                      "invitee_add_location" && (
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label
                                            className="form-group-label"
                                            htmlFor=""
                                          >
                                            Add Venue Location
                                          </label>
                                          <FormField
                                            type="text"
                                            name="add_venue_location"
                                            placeholder="2972 Westheimer Rd. Santa Ana, Illinois 85486"
                                            errors={errors}
                                            touched={touched}
                                            // value={values.reminder_phone_no}
                                            // onChange={({ target }) => {
                                            //   const { value } = target;
                                            //   handleChange({
                                            //     target: { name: "reminder_phone_no", value },
                                            //   });
                                            // }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  <div className="col-12">
                                    <hr
                                      style={{
                                        width: "100%",
                                        margin: "30px 0",
                                        borderColor: "#E0E0E0",
                                      }}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <div className="instructions">
                                      {(event.venue_id === 1 ||
                                        event.venue_id === "1") &&
                                        event.venue_location_status ===
                                          "display_location_booking" && (
                                          <div className="inst_main">
                                            <h4>Venue Location</h4>
                                            <p>{event.venue_location}</p>
                                          </div>
                                        )}
                                      <div className="inst_main">
                                        {!isEmpty(
                                          event.instruction_to_attendee
                                        ) && (
                                          <>
                                            <h4>Instructions to Attendee</h4>
                                            <p>
                                              {event.instruction_to_attendee}
                                            </p>
                                          </>
                                        )}
                                      </div>
                                      <div className="inst_main">
                                        {!isEmpty(
                                          event.cancellation_policy
                                        ) && (
                                          <>
                                            <h4>Cancellation Policy</h4>
                                            <p>{event.cancellation_policy}</p>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row justify-content-between mt-4">
                                  <div
                                    className="col-md-6 copy_link"
                                    id="copy_link"
                                  ></div>
                                  <div className="col-md-6" id="s_e_btn">
                                    <button
                                      type="submit"
                                      className="btn btn-app"
                                    >
                                      Schedule Event
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default connect(null, {
  getEvent,
  getTimezones,
  sendPostRequest,
})(AdhocBooking);
