import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { SET_USER_AND_PLAN_DETAILS } from "../../../actions/types";
import { useDispatch } from "react-redux";

const ConfirmPaymentModal = (props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  /**
   * on toggle modal
   */
  const toggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const { plan, userCurrentPlan } = props;
  return (
    <>
      <button
        button
        className="btn btn-outline brand"
        onClick={(event) => {
          event.preventDefault();
          console.log(plan);
          dispatch({
            type: SET_USER_AND_PLAN_DETAILS,
            payload: {
              selectedPlan: plan,
            },
          });
          toggle();
        }}
      >
        {userCurrentPlan.monthly_price > plan.monthly_price
          ? "Downgrade Plan"
          : "Upgrade Plan"}
      </button>

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalBody>
          <div className="">
            <div className="eventdetailsaddbox rd_modalvieew">
              <div className="box_content rd_modalboxcontent">
                <div className="rd_inputselectheadermodalsetting">
                  <button className="rd_colsebtn" onClick={toggle}></button>
                </div>

                <p className="rd_modalheadertextparath mt-5 mb-5">
                  Are you sure, you want to{" "}
                  {userCurrentPlan.monthly_price > plan.monthly_price
                    ? "downgrade"
                    : "upgrade"}{" "}
                  your subscription plan ?
                </p>

                <div className="rd_cancelappbtnmodal">
                  <button className="btn btn-danger" onClick={toggle}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-app"
                    onClick={() => {
                      props.upgradePlan({});
                      toggle();
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ConfirmPaymentModal;
