import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import { Formik, Form } from "formik";
import { Edit } from "react-feather";
import * as Yup from "yup";
import FormField from "../../../components/FormField/FormField";
import DatePickerField from "../../../components/DatePickerField/DatePickerField";
import { getDBFormatedDate } from "../../../utils/utility";

const validation = Yup.object().shape({
  coupon_code: Yup.string().required("Coupon Code is required"),
  coupon_payout_type: Yup.string().required("Coupon type is required"),
  coupon_value: Yup.string().required("Coupon Value is required"),
  coupon_limit: Yup.string().required("Coupon Limit is required"),
  coupon_expiry: Yup.string().required("Coupon Expiry is required"),
});

let initialValues = {
  coupon_code: "",
  coupon_payout_type: "",
  coupon_value: "",
  coupon_limit: "",
  coupon_expiry: "",
};

const EditPromoModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    let couponExpiryDate = new Date();

    if (props.data.coupon_expiry) {
      let expiryDated = props.data.coupon_expiry.split("-");
      if (expiryDated.length === 3) {
        couponExpiryDate = new Date(
          expiryDated[1] + "-" + expiryDated[0] + "-" + expiryDated[2]
        );
      }
    }

    setFormValues({
      ...props.data,
      coupon_expiry: couponExpiryDate,
    });
  }, []);

  /**
   * on toggle modal
   */
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Edit
        className="mr-50"
        size={15}
        onClick={(event) => {
          event.preventDefault();
          toggle();
        }}
      />
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="event-modal modal-lg modal-dialog"
      >
        <ModalBody>
          <div className="">
            <div className="box_content">
              <div className="rd_inputselectheadermodalsetting">
                <button className="rd_colsebtn" onClick={toggle}></button>
              </div>

              <h5 className="rd_modalheadertext rd_modalheadertextnm">
                Edit Coupon
              </h5>

              <Formik
                validationSchema={validation}
                initialValues={formValues || initialValues}
                enableReinitialize
                onSubmit={(values) => {
                  const { onUpdateRecord } = props;
                  let formData = {
                    ...values,
                    coupon_expiry: getDBFormatedDate(values.coupon_expiry),
                  };
                  toggle();
                  onUpdateRecord(formData);
                }}
              >
                {(formProps) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                  } = formProps;
                  return (
                    <Form>
                      <Row>
                        <Col lg="6" md="12">
                          <label className="same-label" htmlFor="coupon_code">
                            Coupon Code *
                          </label>
                          <FormField
                            type="text"
                            name="coupon_code"
                            placeholder="Coupon Code"
                            value={values.coupon_code}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "coupon_code", value },
                              });
                            }}
                          />
                        </Col>

                        <Col lg="6" md="12">
                          <label className="same-label" htmlFor="coupon_type">
                            Coupon Type *
                          </label>
                          <FormField
                            placeholder="Coupon Type"
                            showPlaceholder={true}
                            type="select"
                            name="coupon_payout_type"
                            errors={errors}
                            touched={touched}
                            value={values.coupon_payout_type.toString()}
                            options={[
                              { value: "P", label: "Percentage" },
                              { value: "F", label: "Flat" },
                            ]}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6" md="12">
                          <label className="same-label" htmlFor="coupon_type">
                            Coupon Value *
                          </label>
                          <FormField
                            type="text"
                            name="coupon_value"
                            placeholder="Coupon Value"
                            value={values.coupon_value}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "coupon_value", value },
                              });
                            }}
                          />
                        </Col>

                        <Col lg="6" md="12">
                          <label className="same-label" htmlFor="coupon_type">
                            Coupon Limit *
                          </label>
                          <FormField
                            type="text"
                            name="coupon_limit"
                            placeholder="Coupon Limit"
                            value={values.coupon_limit}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "coupon_limit", value },
                              });
                            }}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-4 mb-5">
                        <Col lg="12">
                          <label htmlFor="coupon_expiry">Coupon Expiry *</label>
                          <DatePickerField
                            errors={errors}
                            touched={touched}
                            name="coupon_expiry"
                            format="dd-MM-yyyy"
                            value={values.coupon_expiry}
                          />
                        </Col>
                      </Row>

                      <div className="form-groups row">
                        <div className="form-group col-6 sm-12"></div>
                      </div>

                      <div className="rd_cancelappbtnmodal">
                        <button
                          className="btn btn-outline"
                          type="button"
                          onClick={toggle}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-app">
                          Save
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditPromoModal;
