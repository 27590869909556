import React, { useState } from "react";
import classnames from "classnames";
import { TabContent, TabPane } from "reactstrap";
import ClientPayments from "./client";
import StaffPayments from "./staff";

const Payment = () => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <ClientPayments />
    </>
  );
};

export default Payment;
