import { TOGGLE_MOB_LEFT_MENU } from "../actions/types";

export const toogleMenu = (userData) => (dispatch) => {
  if (userData)
    dispatch({
      type: "ToogleSideBar",
      sectionClass: "contentboxcont rd_largcontentboxcont",
    });
  else dispatch({ type: "ToogleSideBar", sectionClass: "contentboxcont" });
};

/**
 * Toggle mobile left menu
 * @param {*} status
 * @returns
 */
export const toggleMobileLeftMenu = (status) => async (dispatch) => {
  dispatch({ type: TOGGLE_MOB_LEFT_MENU, payload: status });
};
