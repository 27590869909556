import React from "react";
import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import { logoutUser, setCurrentUser } from "./actions/authActions";
import Routes from "./routes/Routes";
import "./assets/scss/global.scss";
import axios from "./axios-instance";
//import { tz } from "moment";
import publicRoutes from "./routes/types/public";
import { matchPath } from "react-router-dom";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import {
  PUSHER_APP_KEY,
  PUSHER_APP_CLUSTER,
  LARAVEL_ECHO_WS_HOST,
  LARAVEL_ECHO_WS_PORT,
  LARAVEL_ECHO_WSS_PORT,
} from "./utils/prefixes";

const publicRouteList = [];
publicRoutes.forEach((route, index) => {
  publicRouteList.push(route.path);
});

let isPublicRoute = false;

publicRouteList.forEach((path, index) => {
  const matches = matchPath(path, window.location.pathname);
  if (matches) {
    isPublicRoute = true;
    return;
  }
});

// Check for token

if (!isPublicRoute || matchPath("/booking/:slug", window.location.pathname)) {
  if (localStorage.jwtToken) {
    // Set auth token header auth
    setAuthToken(localStorage.jwtToken);
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded.user));
    // Check for expired token
    const currentTime = Date.now() / 1000;
    //tz.setDefault(localStorage.userTimezone);
    if (decoded.exp < currentTime && !matchPath("/booking/:slug", window.location.pathname)) {
      // Logout user
      store.dispatch(logoutUser());
      // Clear current Profile
      // Redirect to login
      window.location.href = "/admin/login";
    }
  }
}








// Setup Laravel echo and pusher
if (!window.Echo) {
  window.Pusher = Pusher;
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: PUSHER_APP_KEY,
    cluster: PUSHER_APP_CLUSTER,
    wsHost: LARAVEL_ECHO_WS_HOST,
    wsPort: LARAVEL_ECHO_WS_PORT,
    wssPort: LARAVEL_ECHO_WSS_PORT,
    encrypted: false, // laravel websocket we need to make it false
    disableStats: true,
    enabledTransports: ["ws", "wss"],
    forceTLS: true,
  });
}

const App = () => {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};

export default App;
