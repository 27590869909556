import React, { Component, Fragment } from "react";
import moment from "moment";
import { Card, Button } from "reactstrap";
import { Calendar, Repeat } from "react-feather";
import DayScheduleSelectorModal from "../../Modals/DayScheduleSelectorModal";
import WeekdayScheduleModal from "../../Modals/WeekDayScheduleModal";

class ScheduleDayDropdown extends Component {
  state = {
    showDayModal: false,
    showWeekdayModal: false,
    showDayDropdown: true,
  };

  /*
  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false);
  }
  */

  showCalendar = () => {
    //const { setShowInlineModal } = this.props || {};
    this.setState({
      showDayModal: true,
      //showDayDropdown: false,
    });
    //setShowInlineModal(false);
  };

  closeInlineModal = () => {
    const { setShowInlineModal } = this.props || {};
    setShowInlineModal(false);
  };

  handleToggle = () => {
    const { showDayModal } = this.state || {};
    const { setShowInlineModal } = this.props || {};
    this.setState({
      showDayModal: !showDayModal,
    });
    setShowInlineModal(true);
  };

  handleWeekToggle = () => {
    const { showWeekdayModal } = this.state || {};
    const { setShowInlineModal } = this.props || {};
    this.setState({
      showWeekdayModal: !showWeekdayModal,
    });
    setShowInlineModal(true);
  };

  
  /*
  handleOutsideClick = (e) => {
    const { setShowInlineModal } = this.props || {};
    if (!this.node.contains(e.target)) {
      setShowInlineModal(false);
    }
  };
  */
    


  weekDay = () => {
    //const { setShowInlineModal } = this.props || {};
    //setShowInlineModal(false);
    this.setState({
      showWeekdayModal: true,
      //showDayDropdown: false,
    });
  };

  render() {
    const { day, staff, businessId, showInlineModal, browserEvent } = this.props || {};
    const { showDayModal, showDayDropdown, showWeekdayModal } =
      this.state || {};

    const { clientX, clientY } = browserEvent || {};
    const xPoint = parseInt(clientX) - 260;
    const yPoint = parseInt(clientY) + browserEvent.target.offsetTop - browserEvent.target.clientHeight - 100;


    return (
      <Fragment>
        {showDayModal && (
          <DayScheduleSelectorModal
            businessId={businessId}
            timezoneId={this.props.timezoneId}
            onToggle={this.handleToggle}
            isOpen={showDayModal}
            selectedDate={day}
            weekDayId={moment(day).format("d")}
            eventSchedules={this.props.evntSchedules}
            setEventSchedules={this.props.setEvntSchedules}
          />
        )}

        {showWeekdayModal && (
          <WeekdayScheduleModal
            weekDayId={moment(day).format("d")}
            businessId={businessId}
            timezoneId={this.props.timezoneId}
            day={moment(day).format("dddd")}
            onToggle={this.handleWeekToggle}
            isOpen={showWeekdayModal}
            eventSchedules={this.props.evntSchedules}
            setEventSchedules={this.props.setEvntSchedules}
          />
        )}
        {showInlineModal && (
          <div
            ref={(node) => {
              this.node = node;
            }}
            className="event-day-dropdown"
            style={{
              position: "absolute",
              background: "#ffffff",
              border: "1px solid #fff",
              borderRadius: "5px",
              top: `${yPoint}px`,
              left: `${xPoint}px`,
              zIndex: 1000,
            }}
          >
            <div className="event-day">
              <Card>
                <ul>
                  <li onClick={this.showCalendar}>
                    <Calendar size="14" />
                    Edit All Dates
                  </li>
                  <li onClick={this.weekDay}>
                    <Repeat size="14" />
                    Edit All {moment(day).format("dddd")}s
                  </li>
                  <li>
                    <Button
                      className="btn btn-danger"
                      onClick={this.closeInlineModal}
                      style={{ height: "auto" }}
                    >
                      Close
                    </Button>
                  </li>
                </ul>
              </Card>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default ScheduleDayDropdown;