import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "../../../../components/FormField/FormField";
import DatePickerField from "../../../../components/DatePickerField/DatePickerField";
import { getDBFormatedDate, notification } from "../../../../utils/utility";
import Tooltip from "../../../../components/Tooltip";

const validation = Yup.object().shape({
  vacation_name: Yup.string().required("Vacation name is required"),
  start_date: Yup.string().required("Start date is required"),
  end_date: Yup.string().required("End date is required"),
});

let initialValues = {
  vacation_name: "",
  start_date: "",
  end_date: "",
};

const EditModal = (props) => {
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    let vacationStartDate = new Date();

    if (props.data.start_date) {
      let startDate = props.data.start_date.split("-");
      if (startDate.length === 3) {
        vacationStartDate = new Date(
          startDate[1] + "-" + startDate[0] + "-" + startDate[2]
        );
      }
    }

    let vacationEndDate = new Date();
    if (props.data.end_date) {
      let endDate = props.data.end_date.split("-");
      if (endDate.length === 3) {
        vacationEndDate = new Date(
          endDate[1] + "-" + endDate[0] + "-" + endDate[2]
        );
      }
    }

    setFormValues({
      ...props.data,
      start_date: vacationStartDate,
      end_date: vacationEndDate,
    });
  }, []);

  /**
   * Get tomorrow date
   */
  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  };

  return (
    <>
      <Modal
        isOpen={props.showEditModal}
        toggle={props.handleClose}
        className="modal-dialog"
      >
        <ModalBody>
          <div className="">
            <div className="box_content">
              <div className="rd_inputselectheadermodalsetting">
                <button
                  className="rd_colsebtn"
                  onClick={props.handleClose}
                ></button>
              </div>

              <h5 className="rd_modalheadertext rd_modalheadertextnm">
                Edit Vacation
              </h5>

              <Formik
                validationSchema={validation}
                initialValues={formValues || initialValues}
                enableReinitialize
                onSubmit={(values) => {
                  const { onUpdateRecord } = props;
                  let formData = {
                    ...values,
                    start_date: getDBFormatedDate(values.start_date),
                    end_date: getDBFormatedDate(values.end_date),
                  };

                  if (values.start_date.getTime() > values.end_date.getTime()) {
                    return notification.error(
                      "End date cant be earlier than the start date"
                    );
                  }
                  props.handleClose();
                  onUpdateRecord(formData);
                }}
              >
                {(formProps) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                  } = formProps;
                  return (
                    <Form>
                      <Row>
                        <Col lg="12" md="12">
                          <label className="same-label" htmlFor="coupon_code">
                            Vacation Name *
                          </label>
                          <FormField
                            type="text"
                            name="vacation_name"
                            placeholder="Vacation Name"
                            value={values.vacation_name}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "vacation_name", value },
                              });
                            }}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-4 mb-5">
                        <Col lg="12">
                          <label htmlFor="coupon_expiry">Start Date * <Tooltip code="P7F2" /></label>
                          <DatePickerField
                            minDate={getTomorrowDate()}
                            errors={errors}
                            touched={touched}
                            name="start_date"
                            format="dd-MM-yyyy"
                            value={values.start_date}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-4 mb-5">
                        <Col lg="12">
                          <label htmlFor="coupon_expiry">End Date * <Tooltip code="P7F3" /> </label>
                          <DatePickerField
                            minDate={getTomorrowDate()}
                            errors={errors}
                            touched={touched}
                            name="end_date"
                            format="dd-MM-yyyy"
                            value={values.end_date}
                          />
                        </Col>
                      </Row>

                      <div className="form-groups row">
                        <div className="form-group col-6 sm-12"></div>
                      </div>

                      <div className="rd_cancelappbtnmodal">
                        <button
                          type="button"
                          className="rd_cancel"
                          onClick={props.handleClose}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-app">
                          Save
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditModal;
