import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import TimeChooserField from "../Common/TimeChooserField";
import { DayPicker, isMatch } from "react-day-picker";
import "react-day-picker/dist/style.css";

import { X, Plus } from "react-feather";
import moment from "moment";
import { getDayId, getFormatedDate } from "../../../../utils/utility";
import TrashIcon from "../../../../assets/icons/trash_icon.svg";
import PlusIcon from "../../../../assets/icons/plus_icon.svg";

class DayScheduleSelectorModal extends Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDays: [],
      selectedTime: [
        {
          startTime: "09:00am",
          endTime: "05:00pm",
        },
      ],
    };
  }

  componentDidMount() {
    const { weekDayId, selectedDate, eventSchedules } = this.props || {};
    const dated = moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY');

    let evntSchedules = [];

    (eventSchedules || []).map((schedule, index) => {
      if (schedule.dated === dated) {
        (schedule.schedules || []).map((daySchedule, dayScheduleIndex) => {
          evntSchedules.push({
            startTime: daySchedule.start_time,
            endTime: daySchedule.end_time,
          });
        });
      }
    });

    if (evntSchedules.length === 0) {
      const dayId = getDayId(weekDayId);
      (eventSchedules || []).map((schedule, index) => {
        if (schedule.dayId === dayId) {
          (schedule.schedules || []).map((daySchedule, dayScheduleIndex) => {
            evntSchedules.push({
              startTime: daySchedule.start_time,
              endTime: daySchedule.end_time,
            });
          });
        }
      });
    }

    if (evntSchedules.length > 0) {
      this.setState({
        selectedTime: [...evntSchedules],
      });
    }
  }

  toggle = () => {
    const { onToggle } = this.props || {};
    onToggle();
  };

  /*
  handleDayClick(day, { selected }) {
    const selectedDays = this.state.selectedDays.concat();
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        isMatch(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    this.setState({ selectedDays });
  }
  */

  handleDayClick = (selectedDays) => {
    this.setState({ selectedDays: selectedDays });
  }

  addOption = () => {
    const { selectedTime } = this.state || {};
    const defaultOption = {
      startTime: "09:00am",
      endTime: "05:00pm",
    };

    const newOptions = [...selectedTime, defaultOption];
    this.setState({
      selectedTime: newOptions,
    });
  };

  onUpdate = (fieldName, fieldValue, index) => {
    const { selectedTime } = this.state || {};
    const newOptions = (selectedTime || []).map((time, key) => {
      const { value } = fieldValue || {};
      if (index === key) {
        return {
          ...time,
          [fieldName]: value,
        };
      }

      return time;
    });

    this.setState({
      selectedTime: newOptions,
    });
  };

  removeOption = (index) => {
    const { selectedTime } = this.state || {};

    if ((selectedTime || []).length > 1) {
      const newOptions = (selectedTime || []).filter(
        (time, currentItem) => parseInt(index) !== parseInt(currentItem)
      );

      this.setState({
        selectedTime: newOptions,
      });
    }
  };

  /**
   * save schedule only in event schedule state
   */
  saveSchedule = () => {
    const { onToggle, eventSchedules } = this.props || {};
    const { selectedDays, selectedTime } = this.state;

    const selectEvents = (selectedTime || []).map((time, index) => {
      const { startTime, endTime } = time || {};
      return {
        start_time: startTime,
        end_time: endTime,
      };
    });

    const schedules = [...eventSchedules];

    (selectedDays || []).forEach((dated) => {
      const formatedDate = getFormatedDate(dated);
      const found = schedules.some((el) => el.dated === formatedDate);

      if (found) {
        const dayIndex = schedules.findIndex((el) => el.dated === formatedDate);
        schedules[dayIndex].schedules = [...selectEvents];
      } else {
        schedules.push({
          dayId: null,
          dated: formatedDate,
          schedules: [...selectEvents],
        });
      }
    });
    console.log("updated schedules", schedules);
    this.props.setEventSchedules(schedules, this.props.timezoneId);
    onToggle();
  };

  render() {
    const { isOpen } = this.props || {};
    const { selectedDays, selectedTime } = this.state || {};
    
    console.log('seleted days', selectedDays);

    return (
      <div className="day-schedule-wrapper">
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          size="sm"
          className="event-modal modal-lg modal-dialog cal_day_popup"
        >
          <div className="cal_day_popup_wrapper">
            <ModalHeader toggle={this.toggle}>
              Select the date(s) you want to assign specific hours
            </ModalHeader>
            <ModalBody>
              <div className="calendar-wrapper">
                <div className="select_date">Select Date</div>
                <DayPicker
                  mode="multiple"
                  // todayButton={true}
                  selected={selectedDays}
                  // selectedDays={selectedDays}
                  onSelect={this.handleDayClick}
                />
              </div>
              <div className="time-interval-wrapper">
                <Label>What hours are you available?</Label>
                {(selectedTime || []).map((time, index) => {
                  const { startTime, endTime } = time || {};
                  return (
                    <Row key={index}>
                      <Col md="5" lg="5" sm="5">
                        <FormGroup>
                          <TimeChooserField
                            name="startTime"
                            index={index}
                            onUpdate={this.onUpdate}
                            interval={startTime}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="5" lg="5" sm="5">
                        <FormGroup>
                          <TimeChooserField
                            name="endTime"
                            index={index}
                            onUpdate={this.onUpdate}
                            interval={endTime}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2" lg="2" sm="2">
                        <div className="icons">
                          <span onClick={() => this.removeOption(index)}>
                            <img src={TrashIcon} alt="Trash Icon" />
                          </span>
                          {index === 0 && (
                          <span onClick={this.addOption}>
                            <img src={PlusIcon} alt="Plus Icon" />
                          </span>
                          )}
                          
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </ModalBody>

            <ModalFooter>
              <div className="action-items">
                <Button className="btn-outline" onClick={this.toggle}>
                  Cancel
                </Button>
                <Button className="btn btn-primary" onClick={this.saveSchedule}>
                  Save
                </Button>
              </div>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DayScheduleSelectorModal;