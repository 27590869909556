import React from "react";
import { useTranslation } from "react-i18next";
import * as Icon from "react-feather";
import classnames from "classnames";
import { Col, Row } from "reactstrap";
import "../../../../assets/scss/components/customizer.scss";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Link } from "react-router-dom";

const SettingsMenu = (props) => {
  const { t } = useTranslation();

  const configItems = [
    {
      title: t("Your Organization"),
      icon: (
        <Icon.Home
          size={20}
          className="mr-2 fonticon-wrap cust_svg"
          color="#0C4767"
          strokeWidth="1.4"
        />
      ),
      to: "/admin/settings/company",
    },

    /*
    {
      title: t("SETTINGS.APPEARANCE"),
      icon: "desktop",
      to: "/admin/settings/appearance",
    },
    */
    {
      title: t("SETTINGS.PAYMENT"),
      icon: (
        <Icon.CreditCard
          size={20}
          className="mr-2 fonticon-wrap cust_svg rect"
          color="#0C4767"
          strokeWidth="1.4"
        />
      ),
      to: "/admin/settings/payment",
    },
    // {
    //   title: t("SETTINGS.EMAIL_TEMPLATE"),
    //   icon: <Icon.Mail size={20} className="mr-2 fonticon-wrap" />,
    //   to: "/admin/settings/email-template",
    // },
    // {
    //   title: t("SETTINGS.SMS_TEMPLATE"),
    //   icon: <Icon.MessageSquare size={20} className="mr-2 fonticon-wrap" />,
    //   to: "/admin/settings/sms-template",
    // },
    {
      title: t("Promos"),
      icon: (
        <Icon.Gift
          size={20}
          className="mr-2 fonticon-wrap cust_svg rect"
          color="#0C4767"
          strokeWidth="1.4"
        />
      ),
      to: "/admin/settings/discounts",
    },
    {
      title: t("SETTINGS.MARKETING_TOOLS"),
      icon: (
        <Icon.BarChart
          size={20}
          className="mr-2 fonticon-wrap cust_svg"
          color="#0C4767"
          strokeWidth="1.6"
        />
      ),
      to: "/admin/settings/seo",
    },
    {
      title: t("Integrations"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 21.8 21.8"
          className="mr-2 fonticon-wrap cust_svg rect line"
        >
          <g transform="translate(-220.1 -32.1)">
            <g transform="translate(218 31)">
              <rect
                width="20"
                height="18"
                rx="2"
                transform="translate(3 4)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.4"
              />
              <line
                y2="3"
                transform="translate(18 2)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.4"
              />
              <line
                y2="3"
                transform="translate(8 2)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.4"
              />
              <line
                x2="20"
                transform="translate(3 9)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.4"
              />
            </g>
            <line
              x2="5"
              transform="translate(224 43.5)"
              fill="none"
              stroke="#0C4767"
              strokeLinecap="round"
              strokeWidth="1.4"
            />
            <line
              x2="8.5"
              transform="translate(224 47.5)"
              fill="none"
              stroke="#0C4767"
              strokeLinecap="round"
              strokeWidth="1.4"
            />
          </g>
        </svg>
      ),
      to: "/admin/integrations",
    },
    {
      title: t("Events Settings"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="19"
          viewBox="0 0 19.8 21.8"
          className="mr-2 fonticon-wrap cust_svg rect"
        >
          <g
            id="Group_9382"
            data-name="Group 9382"
            transform="translate(-197.1 -63.1)"
          >
            <g
              id="Group_9369"
              data-name="Group 9369"
              transform="translate(195 62)"
            >
              <rect
                id="Rectangle_3113"
                data-name="Rectangle 3113"
                width="18"
                height="18"
                rx="2"
                transform="translate(3 4)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.4"
              />
              <line
                id="Line_2"
                data-name="Line 2"
                y2="4"
                transform="translate(16 2)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.4"
              />
              <line
                id="Line_3"
                data-name="Line 3"
                y2="4"
                transform="translate(8 2)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.4"
              />
              <line
                id="Line_4"
                data-name="Line 4"
                x2="18"
                transform="translate(3 10)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.4"
              />
            </g>
            <g
              id="Group_9381"
              data-name="Group 9381"
              transform="translate(-108.638 -115.209)"
            >
              <path
                id="Path_24122"
                data-name="Path 24122"
                d="M315.156,196.766a.566.566,0,0,1-.549-.451l-.094-.445a3.039,3.039,0,0,1-.488-.2l-.381.247a.566.566,0,0,1-.709-.074l-.371-.371a.567.567,0,0,1-.074-.71l.247-.381a2.988,2.988,0,0,1-.2-.488l-.445-.094a.566.566,0,0,1-.452-.549v-.521a.567.567,0,0,1,.451-.549l.445-.094a3,3,0,0,1,.2-.487l-.247-.381a.566.566,0,0,1,.074-.71l.371-.371a.566.566,0,0,1,.709-.074l.381.247a3.008,3.008,0,0,1,.484-.2l.1-.444a.566.566,0,0,1,.549-.45h.521a.567.567,0,0,1,.554.45l.094.444a3.006,3.006,0,0,1,.487.2l.381-.247a.566.566,0,0,1,.71.074l.371.371a.568.568,0,0,1,.074.71l-.247.381a3.006,3.006,0,0,1,.2.487l.444.094a.567.567,0,0,1,.45.554v.521a.566.566,0,0,1-.45.554l-.444.094a3.024,3.024,0,0,1-.2.489l.247.381a.568.568,0,0,1-.074.71l-.371.371a.566.566,0,0,1-.71.074l-.381-.247a3,3,0,0,1-.487.2l-.094.445a.567.567,0,0,1-.554.451Z"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeWidth="1"
              />
              <path
                id="Path_24123"
                data-name="Path 24123"
                d="M317.105,195.81a1.133,1.133,0,1,0-1.133-1.133,1.133,1.133,0,0,0,1.133,1.133Z"
                transform="translate(-1.688 -1.688)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeWidth="1"
              />
            </g>
          </g>
        </svg>
      ),
      to: "/admin/settings/events",
    },
    {
      title: t("Email Settings"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 23.437 22.092"
          className="mr-2 fonticon-wrap cust_svg"
        >
          <g
            id="Group_9386"
            data-name="Group 9386"
            transform="translate(-243.623 -64.973)"
          >
            <g
              id="Group_9384"
              data-name="Group 9384"
              transform="translate(5.842 -283.404)"
            >
              <path
                id="Path_24126"
                data-name="Path 24126"
                d="M239.128,351.34l8.782,5.539a2.2,2.2,0,0,0,2.348,0L259,351.34"
                transform="translate(0.073 0.338)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.4"
              />
              <line
                id="Line_17"
                data-name="Line 17"
                y1="6.794"
                x2="7.92"
                transform="translate(240.123 357.344)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                stroke-miterlimit="10"
                strokeWidth="1.4"
              />
              <line
                id="Line_18"
                data-name="Line 18"
                x2="3.613"
                y2="3.325"
                transform="translate(250.907 357.276)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                stroke-miterlimit="10"
                strokeWidth="1.4"
              />
              <path
                id="Path_24127"
                data-name="Path 24127"
                d="M250.754,365.276h-8.606a3.466,3.466,0,0,1-3.467-3.468v-9.065a3.466,3.466,0,0,1,3.467-3.467h14.02a3.466,3.466,0,0,1,3.467,3.467v7.629"
                transform="translate(0 0)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.4"
              />
            </g>
            <g
              id="Group_9385"
              data-name="Group 9385"
              transform="translate(-52.638 -110.209)"
            >
              <path
                id="Path_24122"
                data-name="Path 24122"
                d="M315.156,196.766a.566.566,0,0,1-.549-.451l-.094-.445a3.039,3.039,0,0,1-.488-.2l-.381.247a.566.566,0,0,1-.709-.074l-.371-.371a.567.567,0,0,1-.074-.71l.247-.381a2.988,2.988,0,0,1-.2-.488l-.445-.094a.566.566,0,0,1-.452-.549v-.521a.567.567,0,0,1,.451-.549l.445-.094a3,3,0,0,1,.2-.487l-.247-.381a.566.566,0,0,1,.074-.71l.371-.371a.566.566,0,0,1,.709-.074l.381.247a3.008,3.008,0,0,1,.484-.2l.1-.444a.566.566,0,0,1,.549-.45h.521a.567.567,0,0,1,.554.45l.094.444a3.006,3.006,0,0,1,.487.2l.381-.247a.566.566,0,0,1,.71.074l.371.371a.568.568,0,0,1,.074.71l-.247.381a3.006,3.006,0,0,1,.2.487l.444.094a.567.567,0,0,1,.45.554v.521a.566.566,0,0,1-.45.554l-.444.094a3.024,3.024,0,0,1-.2.489l.247.381a.568.568,0,0,1-.074.71l-.371.371a.566.566,0,0,1-.71.074l-.381-.247a3,3,0,0,1-.487.2l-.094.445a.567.567,0,0,1-.554.451Z"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeWidth="1"
              />
              <path
                id="Path_24123"
                data-name="Path 24123"
                d="M317.105,195.81a1.133,1.133,0,1,0-1.133-1.133,1.133,1.133,0,0,0,1.133,1.133Z"
                transform="translate(-1.688 -1.688)"
                fill="none"
                stroke="#0C4767"
                strokeLinecap="round"
                strokeWidth="1"
              />
            </g>
          </g>
        </svg>
      ),
      to: "/admin/settings/email-settings",
    },
    {
      title: t("My Octo Link"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 22.22 22.22"
          className="mr-2 fonticon-wrap cust_svg rect"
        >
          <g
            id="Group_9383"
            data-name="Group 9383"
            transform="translate(-380.818 -5.654) rotate(-45)"
          >
            <rect
              id="Rectangle_3117"
              data-name="Rectangle 3117"
              width="7.945"
              height="2.27"
              rx="1.1"
              transform="translate(261.309 287.855)"
              fill="none"
              stroke="#0C4767"
              strokeWidth="1.4"
            />
            <path
              id="Path_24124"
              data-name="Path 24124"
              d="M262.8,290.747a1.309,1.309,0,0,1-1.187,1.321h-5.976a1.187,1.187,0,0,1-1.187-1.187V287.1a1.187,1.187,0,0,1,1.187-1.187h5.976a1.293,1.293,0,0,1,1.187,1.3"
              fill="none"
              stroke="#0C4767"
              strokeWidth="1.4"
            />
            <path
              id="Path_24125"
              data-name="Path 24125"
              d="M273.22,287.227a1.3,1.3,0,0,1,1.187-1.316h5.976a1.187,1.187,0,0,1,1.187,1.187v3.784a1.187,1.187,0,0,1-1.187,1.187h-5.976a1.22,1.22,0,0,1-1.077-1.319"
              transform="translate(-5.457)"
              fill="none"
              stroke="#0C4767"
              strokeWidth="1.4"
            />
          </g>
        </svg>
      ),
      to: "/admin/settings/my-octo-link",
    },
  ];

  return (
    <div
      className={classnames("customizer d-none d-lg-block", {
        open: props.settingState === true,
      })}
    >
      <div
        className="customizer-toggle"
        onClick={() => props.toggleSettings(!props.settingState)}
      >
        <Icon.Settings className="open-icon" size={22} />
      </div>
      <div className="outer">
        <div className="header d-flex justify-content-between px-2 pt-2">
          <div className="title">
            <h4 className="text-uppercase mb-0">
              <Icon.Settings className="open-icon mr-2 d-lg-none" size={22} />{" "}
              Setting
            </h4>
          </div>

          <div
            className="d-lg-none cursor-pointer"
            onClick={() => props.toggleSettings(false)}
          >
            <Icon.X size={20} color="#0C4767" />
          </div>
        </div>
        <PerfectScrollbar
          options={{
            wheelPropagation: false,
          }}
          className="customizer-content p-2"
        >
          <Row>
            {configItems.map((item, index) => (
              <Col sm="12" md="12" className="fonticon-container" key={index}>
                <div className="fonticon-wrap">{item.icon}</div>
                <label className="fonticon-classname">
                  <Link
                    to={item.to}
                    onClick={() => {
                      props.toggleSettings(false);
                    }}
                  >
                    {item.title}
                  </Link>
                </label>
              </Col>
            ))}
          </Row>
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default SettingsMenu;
