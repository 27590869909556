import React from "react";
import Logo from "../../assets/images/logo-new.svg";

const AdhocEventErrorPage = (props) => {
  return (
    <div className="rd_vacationfilterpart justify-content-center cancel_eve pt-3 pb-5">
      <div className="noavail_wrapper">
        <div className="txt text-center">
          <img src={Logo} className="logo" alt="Brand Logo" />
          <h4>{props.name}</h4>
          <h2>This invitation is longer valid</h2>
          <div className="mt-5">
            <p className="mb-4 text-dark">
              You are logged in as mateenribbashdev@gmail.com This message is
              only visible to you
            </p>
            <p className="text-dark">
              <strong>
                Someone has already scheduled a time with you via this
                invitation.{" "}
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdhocEventErrorPage;
