import axios from "../../axios-instance";
import { fallBackErrorMessage } from "../../constants/event";

/**
 * add new promo
 * @param {*} options
 */
export const addPromo = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("super-admin/promo/save", data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * get promos
 * @param {*} options
 * @returns
 */
export const getPromos = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post("/super-admin/promos", data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * delete promo
 * @param {*} options
 * @returns
 */
export const deletePromo = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post("/super-admin/promo/delete", data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * update promo
 * @param {*} options
 */
export const updatePromo = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("super-admin/promo/save", data)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};
