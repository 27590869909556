import React, { Component } from "react";
import { Modal, ModalBody, Row, Col, Button } from "reactstrap";
import { notification } from "../../../utils/utility";
import { connect } from "react-redux";
import { requestFeature } from "../../../actions/companyActions";
import Loader from "../../../components/Loader/Loader";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import FormField from "../../../components/FormField/FormField";

const validation = Yup.object().shape({
  title: Yup.string()
    .max(200, "Cant exceed more than 200 Characters.")
    .required("Title is required"),
  description: Yup.string()
    .max(1000, "Cant exceed more than 1000 Characters.")
    .required("Description is required"),
});

let initialValues = {
  title: "",
  description: "",
};

class FeatureRequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      pageLoading: false,
    };
  }

  /**
   * toggle modal
   */
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
    this.props.hideFeatureRequestModal();
  };

  render() {
    const { isOpen, pageLoading } = this.state;

    return (
      <>
        <Loader isShowLoader={pageLoading} />
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className="event-modal modal-lg modal-dialog"
        >
          <ModalBody>
            <div className="">
              <div className="box_content">
                <div className="rd_inputselectheadermodalsetting">
                  <button
                    className="rd_colsebtn"
                    onClick={this.toggle}
                  ></button>
                </div>

                <h5 className="rd_modalheadertext rd_modalheadertextnm">
                  Feature Request
                </h5>

                <Formik
                  validationSchema={validation}
                  initialValues={initialValues}
                  enableReinitialize
                  onSubmit={(values) => {
                    const _this = this;
                    const { requestFeature } = this.props;

                    _this.setState({
                      pageLoading: true,
                    });

                    requestFeature({
                      data: {
                        ...values,
                      },
                      onSuccess: (response) => {
                        _this.setState({
                          pageLoading: false,
                        });

                        notification.success(
                          "Your requests and feedback make MeetOcto better! Thank you!"
                        );

                        _this.toggle();
                        console.log("response", response);
                      },
                      onError: (error) => {
                        notification.error(
                          "An error occured during save, Please try later"
                        );
                        _this.setState({
                          pageLoading: false,
                        });
                        console.log("company error ", error);
                      },
                    });
                  }}
                >
                  {(formProps) => {
                    const {
                      values,
                      errors,
                      touched,
                      handleChange,
                      setFieldValue,
                    } = formProps;
                    return (
                      <Form>
                        <Row>
                          <Col lg="12" md="12">
                            <label className="same-label" htmlFor="coupon_code">
                              Title *
                            </label>
                            <FormField
                              type="text"
                              name="title"
                              placeholder="Feature request title"
                              value={values.title}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: { name: "title", value },
                                });
                              }}
                            />
                          </Col>

                          <Col lg="12" md="12">
                            <label className="same-label" htmlFor="coupon_code">
                              Description *
                            </label>
                            <FormField
                              component="textarea"
                              name="description"
                              placeholder="Enter feature Description"
                              elementclasses="rd_areaprofilepagethi"
                              value={values.description}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: {
                                    name: "description",
                                    value,
                                  },
                                });
                              }}
                            />
                          </Col>

                          <Col lg="12" md="12" className="mt-4 mb-2">
                            <p>Please include</p>
                            <span>
                              (Use case, what you're trying to achieve and why
                              its important for you ?).
                            </span>
                          </Col>
                        </Row>

                        <div className="form-groups row">
                          <div className="form-group col-6 sm-12"></div>
                        </div>

                        <div className="rd_cancelappbtnmodal">
                          <button
                            type="button"
                            className="rd_cancel"
                            onClick={this.toggle}
                            disabled={pageLoading}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-app"
                            disabled={pageLoading}
                          >
                            {pageLoading ? "Submitting..." : "Submit"}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default connect(null, {
  requestFeature,
})(FeatureRequestModal);
