import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { notification, IMAGE_BASE_URL } from "../../../utils/utility";
import { connect } from "react-redux";
import { getCompanyData } from "../../../actions/companyActions";
import Loader from "../../../components/Loader/Loader";
import ClrPicker from "../../../views/events/Components/Fields/ClrPicker";
import axiosInstance from "../../../axios-instance";
import { getLoggedInUserId } from "../../../utils/authHelper";
import Tooltip from "../../../components/Tooltip";

class EmbedCodeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      companySlug: "",
      pageLoading: false,
      backgroundColor: "default",
      textColor: "default",
      buttonColor: "default",
    };
  }

  /**
   * executed when component is inserted in dom
   */
  componentDidMount() {
    const { getCompanyData } = this.props;

    this.setState({
      pageLoading: true,
    });

    const _this = this;

    getCompanyData({
      data: {},
      onSuccess: function (details) {
        _this.setState({
          pageLoading: false,
          companySlug: details.url_slug,
        });
      },
      onError: function (error) {
        _this.setState({
          pageLoading: false,
        });
      },
    });
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
    this.props.hideEmbedCodeModal();
  };

  /**
   * on copy code
   */
  onCopyCode = () => {
    var copyText = document.getElementById("share-company-element");
    copyText.select();
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    notification.success(`Code copied successfully`);
  };

  /**
   *
   */
  onPreview = () => {
    const embedCodeElement = document.getElementById("share-company-element");
    axiosInstance
      .post(`admin/generate-code-preview`, {
        data: embedCodeElement.value,
        type: "company",
        userId: getLoggedInUserId(),
      })
      .then((res) => {
        window.open(
          `${IMAGE_BASE_URL}uploads/preview/company-code-preview-${getLoggedInUserId()}.html`,
          "_blank"
        );
      })
      .catch((err) => {});
  };

  render() {
    const { isOpen, pageLoading } = this.state;
    const _this = this;

    const { companySlug, backgroundColor, textColor, buttonColor } = this.state;
    let content = "";

    const dataConfig = {
      "company-url": companySlug,
      "background-color": backgroundColor,
      "text-color": textColor,
      "button-color": buttonColor,
      type: "company",
    };
    content =
      "<div id='meetocto-booking-widget' data-config='" +
      JSON.stringify(dataConfig) +
      "'></div>";
    content +=
      '<link rel="stylesheet" href="https://www.staging.thrive-booking.com/embeddable-widget/widget.css" />';
    content +=
      '<script type="text/javascript" src="https://www.staging.thrive-booking.com/embeddable-widget/widget.js"></script>';
    return (
      <>
        <Loader isShowLoader={pageLoading} />
        {!pageLoading && (
          <Modal
            isOpen={isOpen}
            toggle={this.toggle}
            className="event-modal embed_code_modal modal-lg modal-dialog"
          >
            <ModalHeader>
              Share Company Page
              <Tooltip code="P17F1" />
            </ModalHeader>
            <ModalBody>
              <div className="">
                <div className="row mx-0 flex-column-reverse flex-md-row">
                  <div className="col-md-5 clr_settings">
                    <div className="inner">
                      <h4>Page Settings</h4>
                      <p>
                        Customize the look of your booking page to fit
                        seamlessly into your website.
                      </p>
                      <div className="clr_field_width d-flex align-items-center justify-content-between">
                        <label className="form-label">Background color</label>
                        <ClrPicker
                          onColorPickerChange={(color) => {
                            _this.setState({ backgroundColor: color });
                          }}
                          value={_this.state.backgroundColor}
                        />
                      </div>
                      <div className="clr_field_width d-flex align-items-center justify-content-between">
                        <label className="form-label">Text color</label>
                        <ClrPicker
                          onColorPickerChange={(color) => {
                            _this.setState({ textColor: color });
                          }}
                          value={_this.state.textColor}
                        />
                      </div>
                      <div className="clr_field_width d-flex align-items-center justify-content-between">
                        <label className="form-label">
                          Button and link color
                        </label>
                        <ClrPicker
                          onColorPickerChange={(color) => {
                            _this.setState({ buttonColor: color });
                          }}
                          value={_this.state.buttonColor}
                        />
                      </div>

                      <div className="clr_picker d-flex align-items-center mt-5">
                        <button
                          className="btn btn-grey mr-2"
                          onClick={_this.onPreview}
                        >
                          Preview
                        </button>
                        <button
                          className="btn btn-grey ml-2"
                          onClick={(event) => {
                            event.preventDefault();
                            _this.setState({
                              backgroundColor: "default",
                              textColor: "default",
                              buttonColor: "default",
                            });
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 code">
                    <div className="eventdetailsaddbox rd_modalvieewdashboard">
                      <div className="box_content rd_modalboxcontent">
                        <div className="rd_emebedparinmodl">
                          <h4>Embed Code</h4>
                          <p>
                            Place this code in your page's HTML where you
                            <br />
                            want your company page to appear.
                          </p>

                          <textarea
                            name=""
                            cols="30"
                            rows="10"
                            id="share-company-element"
                            readOnly={true}
                            value={content}
                          ></textarea>

                          <p style={{ display: "none" }}>
                            See how to embed your Calendly link across the web,
                            including sites like
                            <a href="">Wix</a>
                            <a href=""> Squarespace</a>
                            and <a href="">WordPress</a>. Or explore
                            <a href="">advanced embed features .</a>
                          </p>
                          <div className="rd_dashboardmodalfooterpart">
                            <div></div>
                            <div className="rd_dashboardmodalfooterpartb">
                              <button
                                onClick={this.toggle}
                                className="btn btn-outline"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={this.onCopyCode}
                                className="btn btn-app"
                              >
                                Copy Code
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }
}

export default connect(null, {
  getCompanyData,
})(EmbedCodeModal);
