import React, { useState, useEffect, useMemo } from "react";

const TimeField = (props) => {
  const [value, setValue] = useState(null);
  const [showPickerDrop, setShowPickerDrop] = useState(false);

  const [view, setView] = useState(["day", "hour", "minutes"]);
  const [colWidth, setColWidth] = useState("33.3%");

  const [minDay, setMinDay] = useState(0);
  const [maxDay, setMaxDay] = useState(30);

  const [minHour, setMinHour] = useState(0);
  const [maxHour, setMaxHour] = useState(23);

  const [minMinutes, setMinMinutes] = useState(0);
  const [maxMinutes, setMaxMinutes] = useState(59);

  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const widths = ["100%", "50%", "33.3%"];

  const timeFieldLabelValue = useMemo(() => {
    let response = "";

    const timeFieldView = props.view ? props.view : ["day", "hour", "minutes"];

    if (view.includes("day") && timeFieldView.includes("day")) {
      response += day > 1 ? `${day} Days ` : `${day} Day `;
    }

    if (view.includes("hour") && timeFieldView.includes("hour")) {
      response += hour > 1 ? `${hour} hrs ` : `${hour} hr `;
    }

    if (view.includes("minutes") && timeFieldView.includes("minutes")) {
      response += minutes > 1 ? `${minutes} min` : `${minutes} mins`;
    }
    const valueInMinutes = day * 1440 + hour * 60 + minutes;
    props.onChange(valueInMinutes);
    setValue(valueInMinutes);

    return response;
  }, [day, hour, minutes]);

  /**
   * setting default value
   */
  useEffect(() => {
    if (props.defaultValue) {
      const d = Math.floor(props.defaultValue / 1440);
      setDay(d);
      const h = Math.floor((props.defaultValue - d * 1440) / 60);
      setHour(h);
      const m = props.defaultValue - d * 1440 - h * 60;
      setMinutes(m);
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (props.view) {
      setView(props.view);
      setColWidth(widths[props.view.length - 1]);
    }

    // set min day
    if (props.minDay) {
      setMinDay(props.minDay);
    }

    // set max day
    if (props.maxDay) {
      setMaxDay(props.maxDay);
    }

    // set min hour
    if (props.minHour) {
      setMinHour(props.minHour);
    }

    // set max hour
    if (props.maxHour) {
      setMaxHour(props.maxHour);
    }

    // set min hour
    if (props.minMinutes) {
      setMinMinutes(props.minMinutes);
    }

    // set max hour
    if (props.maxMinutes) {
      setMaxMinutes(props.maxMinutes);
    }
  }, []);

  return (
    <>
      <div className="cus_num_field d-flex">
        <div className="holder">
          <p>Time</p>
        </div>
        <div className="inp">
          <input
            type="text"
            placeholder="Time"
            onFocus={() => setShowPickerDrop(true)}
            value={timeFieldLabelValue}
          />
          {showPickerDrop ? (
            <div className="picker_drop">
              <div className="header d-flex">
                {view.includes("day") && (
                  <div className="cols" style={{ width: colWidth }}>
                    days
                  </div>
                )}
                {view.includes("hour") && (
                  <div className="cols" style={{ width: colWidth }}>
                    hours
                  </div>
                )}
                {view.includes("minutes") && (
                  <div className="cols" style={{ width: colWidth }}>
                    mins
                  </div>
                )}
              </div>
              <div className="body d-flex">
                {view.includes("day") && (
                  <div className="cols" style={{ width: colWidth }}>
                    {Array.apply(null, { length: maxDay + 1 - minDay }).map(
                      (_, idx) => (
                        <span
                          key={`day-${idx}`}
                          onClick={() => setDay(idx + minDay)}
                          className={` ${day == idx + minDay ? "active" : ""}`}
                        >
                          {idx + minDay}
                        </span>
                      )
                    )}
                  </div>
                )}

                {view.includes("hour") && (
                  <div className="cols" style={{ width: colWidth }}>
                    {Array.apply(null, { length: maxHour + 1 - minHour }).map(
                      (_, idx) => (
                        <span
                          key={`hour-${idx}`}
                          onClick={() => setHour(idx + minHour)}
                          className={` ${
                            hour == idx + minHour ? "active" : ""
                          }`}
                        >
                          {idx + minHour}
                        </span>
                      )
                    )}
                  </div>
                )}

                {view.includes("minutes") && (
                  <div className="cols" style={{ width: colWidth }}>
                    {Array.apply(null, {
                      length: maxMinutes + 1 - minMinutes,
                    }).map((_, idx) => (
                      <span
                        key={`minutes-${idx}`}
                        onClick={() => setMinutes(idx + minMinutes)}
                        className={` ${
                          minutes == idx + minMinutes ? "active" : ""
                        }`}
                      >
                        {idx + minMinutes}
                      </span>
                    ))}
                  </div>
                )}
              </div>

              <div className="foot d-flex align-items-center justify-content-end">
                <button
                  className="btn rd_cancel"
                  onClick={() => setShowPickerDrop(false)}
                >
                  Ok
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default TimeField;
