import React, { Component } from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "../../../components/FormField/FormField";
import {
  validateCardNumber,
  validateCardExpiry,
} from "../../../validation/index";

const validation = Yup.object().shape({
  card_number: Yup.string()
    .required("Card Number cannot be empty")
    .test("valid-card", "Invalid credit card number", (value) => {
      return validateCardNumber(value);
    }),
  first_name: Yup.string().required("Card Holder cannot be empty"),
  last_name: Yup.string().required("Card Holder cannot be empty"),
  exp_date: Yup.string()
    .required("Exp Date cannot be empty")
    .test("valid-expiry", "Invalid expiry date", (value) => {
      return validateCardExpiry(value);
    }),
  cvc: Yup.string()
    .matches(/^[0-9]{3,4}$/, "Invalid CVC number")
    .required("CVC cannot be empty"),
});

let initialValues = {
  first_name: "",
  last_name: "",
  card_number: "",
  exp_date: "",
  cvc: "",
};

class CardInfoModal extends Component {
  constructor(props) {
    super(props);
  }

  /**
   * on toggle modal
   */
  toggle = () => {
    this.props.setShowCardInfoModal(false);
  };

  render() {
    const { upgradePlan } = this.props;

    return (
      <>
        <Modal className="billing_modal" isOpen={true} toggle={this.toggle}>
          <ModalBody className="p-0">
            <div className="rd_inputselectheadermodalsetting d-flex justify-content-between align-items-center">
              <h3></h3>
              <button className="rd_colsebtn" onClick={this.toggle}></button>
            </div>
            <div className="box_content">
              <h3>Payment Details</h3>
              <Formik
                validationSchema={validation}
                initialValues={initialValues}
                enableReinitialize
                onSubmit={(data) => {
                  console.log("data", data);
                  const [expMonth, expYear] = data.exp_date.split("/");
                  data.exp_month = expMonth;
                  data.exp_year = expYear;
                  upgradePlan(data);
                  this.toggle();
                }}
              >
                {(formProps) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                  } = formProps;

                  return (
                    <Form>
                      <Row className="rd_addnewuserfieth">
                        <Col md="6">
                          <label htmlFor="">First Name *</label>
                          <div className="input-group">
                            <FormField
                              type="text"
                              name="first_name"
                              placeholder="Enter First Name"
                              value={values.first_name}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: { name: "first_name", value },
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <label htmlFor="">Last Name *</label>
                          <div className="input-group">
                            <FormField
                              type="text"
                              name="last_name"
                              placeholder="Enter Last Name"
                              value={values.last_name}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: { name: "last_name", value },
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <label htmlFor="">Card Number *</label>
                          <div className="input-group">
                            <FormField
                              type="text"
                              name="card_number"
                              placeholder="Enter Card Number"
                              value={values.card_number}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: { name: "card_number", value },
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <label htmlFor="">CVC *</label>
                          <div className="input-group">
                            <FormField
                              type="text"
                              name="cvc"
                              placeholder="Enter Card CVC"
                              value={values.cvc}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: { name: "cvc", value },
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <label htmlFor="">Exp. Date *</label>
                          <div className="input-group">
                            <FormField
                              type="text"
                              name="exp_date"
                              placeholder="Enter Card Expiry e-g 03/25"
                              value={values.exp_date}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: { name: "exp_date", value },
                                });
                              }}
                            />
                          </div>
                        </Col>

                        <Col md="12" className="d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-app"
                            style={{ maxWidth: 320 }}
                          >
                            Pay
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default CardInfoModal;
