import React, { useState } from "react";
import { Modal, ModalBody, Row, Col, Button } from "reactstrap";
import { Formik, Form } from "formik";

import * as Yup from "yup";
import FormField from "../../../../components/FormField/FormField";
import DatePickerField from "../../../../components/DatePickerField/DatePickerField";
import { getDBFormatedDate, notification } from "../../../../utils/utility";

const validation = Yup.object().shape({
  coupon_code: Yup.string().required("Coupon Code is required"),
  coupon_payout_type: Yup.string().required("Coupon type is required"),
  coupon_value: Yup.string().required("Coupon Value is required"),
  coupon_limit: Yup.string().required("Coupon Limit is required"),
  coupon_expiry: Yup.string().required("Coupon Expiry is required"),
});

let initialValues = {
  coupon_code: "",
  coupon_payout_type: "",
  coupon_value: "",
  coupon_limit: "",
  coupon_expiry: "",
};

const CreateModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);

  /**
   * on toggle modal
   */
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  /**
   * Get tomorrow date
   */
  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  };

  return (
    <>
      <Button
        className="btn btn-outline btn-add mr-4"
        type="button"
        style={{ width: "126px" }}
        onClick={(event) => {
          event.preventDefault();
          toggle();
        }}
      >
        Add New
      </Button>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="event-modal modal-lg modal-dialog"
      >
        <ModalBody>
          <div className="">
            <div className="box_content pt-0 pb-2">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="rd_modalheadertext rd_modalheadertextnm my-0">
                  Add Promo
                </h2>
                <div className="rd_inputselectheadermodalsetting">
                  <button className="rd_colsebtn" onClick={toggle}></button>
                </div>
              </div>

              <Formik
                validationSchema={validation}
                initialValues={formValues || initialValues}
                enableReinitialize
                onSubmit={(data) => {
                  const { onAddRecord } = props;
                  onAddRecord({
                    ...data,
                    coupon_expiry: getDBFormatedDate(data.coupon_expiry),
                  });
                  toggle();
                }}
              >
                {(formProps) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                  } = formProps;
                  return (
                    <Form>
                      <Row>
                        <Col lg="6" md="6">
                          <label className="same-label" htmlFor="coupon_code">
                            Coupon Code *
                          </label>
                          <FormField
                            type="text"
                            name="coupon_code"
                            placeholder="Coupon Code"
                            value={values.coupon_code}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "coupon_code", value },
                              });
                            }}
                          />
                        </Col>

                        <Col lg="6" md="6">
                          <label className="same-label" htmlFor="coupon_expiry">
                            Coupon Type *
                          </label>
                          <FormField
                            placeholder="Coupon Type"
                            showPlaceholder={true}
                            type="select"
                            name="coupon_payout_type"
                            errors={errors}
                            touched={touched}
                            value={values.coupon_payout_type.toString()}
                            options={[
                              { value: "P", label: "Percentage" },
                              { value: "F", label: "Flat" },
                            ]}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6" md="6">
                          <label className="same-label" htmlFor="coupon_expiry">
                            Coupon Value *
                          </label>
                          <FormField
                            type="text"
                            name="coupon_value"
                            placeholder="Coupon Value"
                            value={values.coupon_value}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "coupon_value", value },
                              });
                            }}
                          />
                        </Col>
                        <Col lg="6" md="6">
                          <label className="same-label" htmlFor="coupon_expiry">
                            Coupon Limit *
                          </label>
                          <FormField
                            type="text"
                            name="coupon_limit"
                            placeholder="Coupon Limit"
                            value={values.coupon_limit}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "coupon_limit", value },
                              });
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg="6" md="6">
                          <label className="same-label" htmlFor="coupon_expiry">
                            Coupon Expiry *
                          </label>
                          <DatePickerField
                            errors={errors}
                            touched={touched}
                            name="coupon_expiry"
                          />
                        </Col>
                      </Row>

                      <div className="form-groups row">
                        <div className="form-group col-6 sm-12"></div>
                      </div>

                      <div className="rd_cancelappbtnmodal">
                        <button
                          type="button"
                          className="btn btn-outline"
                          onClick={toggle}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-app">
                          Save
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateModal;
