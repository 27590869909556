import React from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { setCalendarView } from "../../actions/calendarActions";
import {
  NEXT_CALENDAR_MONTH,
  PREV_CALENDAR_MONTH,
  TODAY_CALENDAR_MONTH,
} from "../../actions/types";
import * as Icon from "react-feather";

const Toolbar = (props) => {
  moment.locale("en", {
    week: {
      dow: 7,
    },
  });
  moment.locale("en");

  const { selectedView: calendarSelectedView } = props.calendar;

  const dispatch = useDispatch();

  /**
   * Get title for calendar date
   */
  const getCalendarDateTitle = () => {
    const { selectedView } = props.calendar;
    const { selectedDate } = props;
    switch (selectedView) {
      case "month":
        return moment(selectedDate).format("MMMM, yyyy");
      case "week":
        const dated = moment(selectedDate);
        return `${dated.startOf("week").format("MMM DD")} - ${dated
          .endOf("week")
          .format("MMM DD")}, ${dated.format("yyyy")}`;
      case "day":
        console.log("selected date", selectedDate);
        return moment(selectedDate).format("dddd DD, MMM yyyy");
    }
  };

  /**
   *
   * @returns
   */
  const onBackHandler = () => {
    const { selectedView, onNavigate } = props.calendar;
    const { selectedDate, setSelectedDate } = props;
    switch (selectedView) {
      case "month":
        const dated = moment(selectedDate).subtract(1, "month");
        onNavigate(dated, "PREV");
        setSelectedDate(dated);
        //dispatch({ type: PREV_CALENDAR_MONTH, payload: dated });
        break;
      case "week":
        const weekDated = moment(selectedDate).subtract(1, "week");
        onNavigate(weekDated, "PREV");
        setSelectedDate(weekDated);
        //dispatch({ type: PREV_CALENDAR_MONTH, payload: weekDated });
        break;
      case "day":
        const dayDated = moment(selectedDate).subtract(1, "day");
        onNavigate(dayDated, "PREV");
        setSelectedDate(dayDated);
        //dispatch({ type: PREV_CALENDAR_MONTH, payload: dayDated });
        break;
    }
  };

  /**
   *
   * @returns
   */
  const onNextHandler = () => {
    const { selectedView, onNavigate } = props.calendar;
    const { selectedDate, setSelectedDate } = props;
    switch (selectedView) {
      case "month":
        const dated = moment(selectedDate).add(1, "month");
        onNavigate(dated, "NEXT");
        //dispatch({ type: NEXT_CALENDAR_MONTH, payload: dated });
        setSelectedDate(dated);
        break;
      case "week":
        const weekDated = moment(selectedDate).add(1, "week");
        onNavigate(weekDated, "NEXT");
        //dispatch({ type: NEXT_CALENDAR_MONTH, payload: weekDated });
        setSelectedDate(weekDated);
        break;
      case "day":
        const dayDated = moment(selectedDate).add(1, "day");
        onNavigate(dayDated, "NEXT");
        setSelectedDate(dayDated);
        //dispatch({ type: NEXT_CALENDAR_MONTH, payload: dayDated });
        break;
    }
  };

  /**
   * on today handler
   */
  const onTodayHandler = () => {
    const { onNavigate } = props.calendar;
    const { currentDate, setSelectedDate } = props;
    onNavigate(currentDate, "TODAY");
    setSelectedDate(currentDate);
    //dispatch({ type: TODAY_CALENDAR_MONTH, payload: currentDate });
  };

  return (
    <div className="toolbar">
      <div className="row justify-content-between align-items-center">
        <div className="col-auto calendar_view">
          <div className="d-flex">
            <button className="btn" onClick={onTodayHandler} type="button">
              Today
            </button>
            <button className="btn" onClick={onBackHandler} type="button">
              Back
            </button>
            <button className="btn" onClick={onNextHandler} type="button">
              Next
            </button>
          </div>
        </div>
        <div className="col-auto calnder_month">
          <div className="row align-items-center">
            <div className="col-auto">
              <button
                className="btn btn_month"
                onClick={onBackHandler}
                type="button"
              >
                {/* <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 48 48">
                  <path d="M28.05 36 16 23.95 28.05 11.9l2.15 2.15-9.9 9.9 9.9 9.9Z" />
                </svg> */}
                <Icon.ChevronLeft size="40" color="#0C4767" />
              </button>
            </div>
            <div className="col-auto">
              <h3>{getCalendarDateTitle()}</h3>
            </div>
            <div className="col-auto">
              <button
                className="btn btn_month"
                onClick={onNextHandler}
                type="button"
              >
                {/* <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                  <path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z" />
                </svg> */}
                <Icon.ChevronRight size="40" color="#0C4767" />
              </button>
            </div>
          </div>
        </div>
        <div className="col-auto calendar_view">
          <div className="d-flex">
            {props.calendar.views.includes("month") && (
              <button
                className={`btn ${
                  calendarSelectedView === "month" ? "active" : null
                }`}
                onClick={() => {
                  props.setCalendarView("month");
                }}
              >
                Month
              </button>
            )}

            {props.calendar.views.includes("week") && (
              <button
                className={`btn ${
                  calendarSelectedView === "week" ? "active" : null
                }`}
                onClick={() => {
                  props.setCalendarView("week");
                }}
              >
                Week
              </button>
            )}

            {props.calendar.views.includes("day") && (
              <button
                className={`btn ${
                  calendarSelectedView === "day" ? "active" : null
                }`}
                onClick={() => {
                  props.setCalendarView("day");
                }}
              >
                Day
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  calendar: state.calendar,
});

export default connect(mapStateToProps, {
  setCalendarView,
})(Toolbar);
