import React, { useState, useEffect } from "react";
import { Col } from "reactstrap";
//import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { withRouter } from "../../utils/utility";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";

import { logoutUser } from "../../actions/authActions";
import {
  getUserDashboardLink,
  getUserRoles,
  getUserBusinesses,
  encryptUserRole,
} from "../../utils/authHelper";
import isEmpty from "../../validation/is-empty";
import { notification } from "../../utils/utility";

const UserType = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [userType, setUserType] = useState(null);
  const [userBusiness, setUserBusiness] = useState(null);

  const decoded = jwt_decode(localStorage.jwtToken);
  const config = JSON.parse(localStorage.getItem('config'));
  const user = config.user;

  useEffect(() => {
    if (props.auth.isAuthenticated && localStorage.userType) {
      navigate(getUserDashboardLink(localStorage.userType));
    }

    if (!props.auth.isAuthenticated) {
      navigate("/admin/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.isAuthenticated]);

  /**
   * Get User Role Icon
   * @param String role
   * @returns String
   */
  const getRoleIcon = (role) => {
    switch (role) {
      case "customer":
        return <a href="#" className="rd_splashcard rd_splashcardadmin"></a>;
      case "admin":
        return <a href="#" className="rd_splashcard rd_splashcardadmin"></a>;
      case "staff":
        return (
          <a href="#" className="rd_splashcard rd_splashcardstaff">
            {user.is_staff_acc_deleted === "E" || user.status === "D" ? (
              <span class="danger">Inactive</span>
            ) : null}
          </a>
        );
      default:
        return (
          <a href="#" className="rd_splashcard rd_splashcardsuperadmin"></a>
        );
    }
  };

  /**
   * on close button handler
   */
  const onCloseHandler = () => {
    props.logoutUser();
    navigate("/admin/login");
  };

  /**
   * on continue button handler
   */
  const onContinueHandler = () => {
    if (!userType) {
      return notification.error("Please select user type");
    }

    if (userType === "admin" && !userBusiness) {
      return notification.error("Please select type of business");
    }

    if (userType !== "admin") {
      localStorage.setItem("userType", encryptUserRole(userType));
      // if user type is staff then we need to save staff_business_id as businessId in localStorage
      if (userType === "staff" && localStorage.config) {
        console.log('local', localStorage.config);
        //const decoded = jwt_decode(localStorage.jwtToken);
        const config = JSON.parse(localStorage.config);
        console.log('local parsed', config);
        localStorage.setItem("businessId", config.staff_business_id);
      }
      navigate(getUserDashboardLink(localStorage.userType));
    } else {
      if (userBusiness !== null) {
        localStorage.setItem("userType", encryptUserRole(userType));
        localStorage.setItem("businessId", userBusiness);
        navigate(getUserDashboardLink(localStorage.userType));
      }
    }
  };

  const roles = getUserRoles();
  let businesses = getUserBusinesses();

  let userRoles = null;
  if (!isEmpty(roles) && roles.length > 1) {
    userRoles = roles.map((role, index) => {
      return (
        <div
          className={`rd_carditem user_card ${
            role == userType ? "rd_splashitemselected" : ""
          }`}
          key={index}
          onClick={() => {
            if (
              (user.is_staff_acc_deleted === "E" || user.status === "D") &&
              role === "staff"
            ) {
              notification.warning(
                "Your staff account is deactivated by Admin."
              );
            } else {
              setUserType(role);
            }
          }}
        >
          {getRoleIcon(role)}
        </div>
      );
    });
  }

  let userBusinesses = null;
  if (!isEmpty(businesses) && Object.keys(businesses).length > 1) {
    businesses = Object.keys(businesses).map((key) => [key, businesses[key]]);
    userBusinesses = businesses.map((business, index) => {
      const [businessName, businessId] = business;
      return (
        <div
          className={`rd_carditem rd_carditemsub ${
            businessId == userBusiness ? "rd_splashitemselected" : ""
          }`}
          key={businessId}
        >
          <a
            href="#"
            onClick={() => setUserBusiness(businessId)}
            className={
              businessId == 1
                ? "rd_splashcard rd_splashcardhairsalon"
                : "rd_splashcard rd_splashcardacademy"
            }
          ></a>
        </div>
      );
    });
  } else if (
    !isEmpty(businesses) &&
    Object.keys(businesses).length === 1 &&
    userBusiness === null
  ) {
    setUserBusiness(businesses[Object.keys(businesses)[0]]);
  }

  return (
    <div className="container">
      <div className="eventdetailsaddbox rd_noshadow">
        <div className="rd_pasevencontth rd_pasevencontth2">
          <h4>Select User Type</h4>
          {userRoles && (
            <div className="rd_eventcardscontainer justify-content-center">
              {userRoles}
            </div>
          )}

          {userBusinesses && userType === "admin" && (
            <>
              <h4>Select Business Type</h4>
              <div className="rd_eventcardscontainer justify-content-center">
                {userBusinesses}
              </div>
            </>
          )}

          <Col md="12" className="text-right mt-2 mb-2">
            <button
              className="rd_mainbuttonstylelight"
              type="button"
              onClick={onCloseHandler}
            >
              Cancel
            </button>
            <button
              className="rd_mainbuttonstyleorange"
              type="button"
              onClick={onContinueHandler}
            >
              Continue
            </button>
          </Col>
        </div>
      </div>
    </div>
  );
};

/*
UserType.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
*/

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(UserType));
