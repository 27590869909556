import React, { Component } from "react";
import { gotProperty } from "../../../../utils/utility";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { onUpdateFilters } from "../../../../actions/filterActions";

class CustomerFilters extends Component {
  render() {
    const { searchFilters } = this.props.filter;

    return (
      <>
        <div className="form-group">
          <label htmlFor="staff-name">Name</label>
          <input
            type="text"
            id="staff-name"
            placeholder="Customer ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "name") ? searchFilters["name"] : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("name", e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="status">Status</label>
          <Input
            type="select"
            name="status"
            id="status"
            value={
              gotProperty(searchFilters, "status")
                ? searchFilters["status"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("status", e.target.value);
            }}
          >
            <option value="">Select Status</option>
            <option value="E">Active</option>
            <option value="D">Deactive</option>
          </Input>
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            placeholder="Email ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "email") ? searchFilters["email"] : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("email", e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input
            type="text"
            id="phone"
            placeholder="Phone ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "phone") ? searchFilters["phone"] : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("phone", e.target.value);
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  onUpdateFilters,
})(CustomerFilters);
