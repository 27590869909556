import React, { useState } from "react";
import { Fragment } from "react";
import * as Icon from "react-feather";
import SidebarProgressPoint from "./SidebarProgressPoint";

const progressPoints = [
  {
    stepTitle: "Account",
    stepKey: "step-1",
  },
  {
    stepTitle: "Information",
    stepKey: "step-2",
  },
  {
    stepTitle: "Social",
    stepKey: "step-3",
  },
];

const SidebarProgress = (props) => {
  const { props: pageProps } = props;
  const { route } = pageProps || {};
  const { location, match, history } = route || {};

  const [isToggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!isToggle);
  };

  return (
    <div className="event-card">
      <div className="event-card-head">
        <h3 className="event-title">Staff Profile Steps</h3>
        <span onClick={handleToggle}>
          {isToggle ? (
            <Icon.ChevronUp size="16" />
          ) : (
            <Icon.ChevronDown size="16" />
          )}
        </span>
      </div>
      {!isToggle && (
        <Fragment>
          <div className="event-card-body">
            <ul className="progress-points">
              {(progressPoints || []).map((e, i) => {
                return (
                  <SidebarProgressPoint
                    point={e}
                    index={i + 1}
                    key={i}
                    step={props.step}
                  />
                );
              })}
            </ul>
          </div>
          <div className="event-card-footer d-none d-lg-block"></div>
        </Fragment>
      )}
    </div>
  );
};

export default SidebarProgress;
