import React from "react";
import { connect } from "react-redux";
import { Alert, FormFeedback, Input, Col, Button } from "reactstrap";
import { Formik, Form } from "formik";
import Loader from "../../../components/Loader/Loader";
import * as Yup from "yup";
import { Component } from "react";
import {
  getCompanyData,
  getCompanySettingServices,
  saveCompanyDetails,
} from "../../../actions/companyActions";
import {
  notification,
  IMAGE_BASE_URL,
  COMPANY_BASE_URL,
  gotProperty,
  urlSlugRegex,
  emailRegex,
} from "../../../utils/utility";
import { getAdminBusinessId } from "../../../utils/authHelper";
import FormField from "../../../components/FormField/FormField";
import CopyIcon from "../../../assets/icons/copy_icon.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const validation = Yup.object().shape({
  company_name: Yup.string().required("Company name is required."),
  language_id: Yup.string().required("Language is required."),
  //timezone_id: Yup.string().required("Timezone is required."),
  url_slug: Yup.string()
    //.matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, "Please enter valid url")
    .matches(urlSlugRegex, "Invalid URL slug")
    .required("URL slug is required"),
  company_email: Yup.string()
    .matches(emailRegex, "Please enter valid email")
    .required("Email is required"),
  phone_no: Yup.string().required("Phone no is required."),
  company_address: Yup.string().required("Address is required."),
});

let initialValues = {
  company_name: "",
  language_id: "",
  //timezone_id: "",
  url_slug: "",
  company_email: "",
  phone_no: "",
  company_address: "",
  company_city: "",
  company_state: "",
  company_zip_code: "",
  country_id: "",
};

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errorMessage: "",
      isOpen: false,
      languages: [],
      countries: [],
      timezones: [],
      pageLoading: false,
      companyImageUrl: "",
      isImageChangedEventCalled: false,
      companyLogo: "",
    };
  }

  /**
   * Copy company event
   * @param string companyURLSlug
   */
  onCopyCompanyLink = (companyURLSlug) => {
    let textArea = document.createElement("textarea");
    textArea.value = COMPANY_BASE_URL + companyURLSlug;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const status = document.execCommand("copy");

      if (status) {
        notification.success(
          `Company URL Copied ${COMPANY_BASE_URL}${companyURLSlug}`
        );
      } else {
        notification.error("Copy command is not supported in your browser.");
      }
    } catch (err) {
      notification.error("Copy command is not supported in your browser.");
    }

    /*
    document.body.removeChild(textArea);

    var copyText = document.getElementById(copyFieldElementId);
    copyText.disabled = false;
    copyText.select();
    document.execCommand("copy");
    copyText.disabled = true;
    window.getSelection().removeAllRanges();
    */
    //toast.success(`Event URL Copied ${FRONTEND_BASE_URL}booking/${eventURL}`);
  };

  /**
   * executed when component is inserted in dom
   */
  componentDidMount() {
    const { getCompanyData, getCompanySettingServices } = this.props;

    this.setState({
      pageLoading: true,
    });

    const _this = this;

    getCompanyData({
      data: {},
      onSuccess: function (details) {
        console.log("details", details);
        initialValues = {
          ...initialValues,
          id: details.id,
          company_name: details.company_name,
          language_id: gotProperty(details, "language")
            ? details.language.value
            : "",
          /*
            timezone_id: gotProperty(details, "timezone")
            ? details.timezone.value
            : "",
          */
          phone_no: details.phone_no,
          url_slug: details.url_slug,
          company_email: details.company_email,
          company_address: details.company_address,
          company_city: details.company_city,
          company_state: details.company_state,
          company_zip_code: details.company_zip_code,
          country_id: gotProperty(details, "country")
            ? details.country.value
            : "",
        };

        _this.setState({
          pageLoading: false,
          companyImageUrl: details.company_logo,
        });
      },
      onError: function (error) {
        _this.setState({
          pageLoading: false,
        });
      },
    });

    getCompanySettingServices({
      data: {},
      onSuccess: function (data) {
        _this.setState({
          languages: data.languages,
          countries: data.countries,
          timezones: data.timezones,
        });
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  }

  /**
   * On select file handler
   * @param {*} e
   * @returns
   */
  onSelectFile = (e) => {
    this.setState({ isImageChangedEventCalled: true });
    if (!e.target.files || e.target.files.length === 0) {
      this.setState({ companyLogo: undefined });
      return;
    }
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({
      companyImageUrl: objectUrl,
      companyLogo: e.target.files[0],
    });
  };

  render() {
    const {
      errorMessage,
      isLoading,
      pageLoading,
      languages,
      timezones,
      countries,
      companyLogo,
    } = this.state;

    return (
      <div className="eventdetailsaddbox rd_noshadow">
        <Loader isShowLoader={pageLoading} />
        {/* <div className="rd_supertoptwocom">
          <h3 className="rd_mbdispno">Company</h3>
          <div className="boxheader rd_floatingheaderthig">
            <div className="rd_inputselectheader">
              <div className="rd_selectheaderrdt2 rd_selectheaderrdt2profile">
                <h3>
                  <span className="dashboardmenuicon"></span>
                  <span className="rd_nextarrows"></span>
                  &nbsp; Settings &nbsp;
                  <span className="rd_nextarrows"></span> &nbsp;
                  <a href="#">Company</a>
                </h3>
              </div>
            </div>
          </div>
        </div> */}
        <div className="rd_vacationfilterpart rd_vacationfilterpart3">
          <Formik
            validationSchema={validation}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(data) => {
              const { saveCompanyDetails } = this.props;

              const _this = this;

              _this.setState({
                pageLoading: true,
              });

              saveCompanyDetails({
                data: {
                  ...data,
                  business_id: getAdminBusinessId(),
                  company_logo: companyLogo,
                },
                onSuccess: (response) => {
                  if (response.success) {
                    notification.success(response.message);
                  }

                  _this.setState({
                    pageLoading: false,
                  });

                  console.log("response", response);
                },
                onError: (error) => {
                  notification.error(
                    "An error occured during save, Please try later"
                  );

                  _this.setState({
                    pageLoading: false,
                  });
                  console.log("company error ", error);
                },
              });
            }}
          >
            {(formProps) => {
              const { values, errors, touched, handleChange, setFieldTouched } =
                formProps;

              return (
                <Form>
                  <div className="rd_profileimagepart pt-3">
                    <div className="rd_imagethincontg settings_uploader">
                      <p>Company Logo</p>
                      <div className="col-md-12 px-0">
                        <img
                          src={
                            !this.state.isImageChangedEventCalled
                              ? IMAGE_BASE_URL + this.state.companyImageUrl
                              : this.state.companyImageUrl
                          }
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="rd_imagethincontgbtnt">
                      <p>Allowed JPG, GIF or PNG. Max size of 800kB</p>
                      <label className="btn btn-outline mx-1">
                        Upload Photo
                        <input
                          type="file"
                          value={""}
                          onChange={this.onSelectFile}
                          name="company_logo"
                          id="uploadImg"
                          hidden
                        />
                      </label>
                    </div>
                  </div>
                  <div className="rd_profilerd_erpart">
                    <div className="rd_vacationflex2">
                      <p>Organization (Company or Personal name) * </p>
                      <div className="rd_profilethingco">
                        <FormField
                          type="text"
                          name="company_name"
                          placeholder="Company Name"
                          value={values.company_name}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "company_name", value },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="rd_vacationflex3 slug_inp">
                      <p>Slug Url *</p>
                      <div className="input-group">
                        <div className="input-group-prepend rd_dropdownbtn">
                          <div className="input-group-text rd_domainunofi">
                            {COMPANY_BASE_URL}
                          </div>
                        </div>

                        <Input
                          className={`form-control noshadfoc ${
                            errors["url_slug"] && "is-invalid"
                          }`}
                          placeholder="URL Slug"
                          name="url_slug"
                          value={values.url_slug}
                          onChange={({ target }) => {
                            const { value, name } = target || {};
                            handleChange({
                              target: { name, value },
                            });
                          }}
                        />

                        <div className="input-group-prepend rd_sahredownbtn">
                          <button
                            type="button"
                            className="input-group-text"
                            onClick={() =>
                              this.onCopyCompanyLink(values.url_slug)
                            }
                          >
                            <span className="d-none d-lg-block">Copy link</span>
                            <img
                              src={CopyIcon}
                              alt="Copy Icon"
                              className="d-lg-none"
                              style={{ maxWidth: 20 }}
                            />
                          </button>
                        </div>

                        {errors["url_slug"] && (
                          <FormFeedback className="d-block">
                            {errors["url_slug"]}
                          </FormFeedback>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="rd_profilerd_erpart">
                    <div className="rd_vacationflex2">
                      <p>Email *</p>
                      <div className="rd_profilethingco">
                        <FormField
                          type="text"
                          name="company_email"
                          placeholder="Email"
                          value={values.company_email}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "company_email", value },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="rd_vacationflex2">
                      <p>Phone *</p>
                      <div className="rd_profilethingco">
                        <PhoneInput
                          containerClass="custom_phn_inp"
                          country={"us"}
                          value={values.phone_no}
                          onBlur={() => {
                            setFieldTouched(`phone_no`, true);
                          }}
                          onChange={(value) => {
                            handleChange({
                              target: { name: "phone_no", value },
                            });
                          }}
                        />
                        {errors[`phone_no`] && touched[`phone_no`] && (
                          <FormFeedback className="d-block">
                            {errors[`phone_no`]}
                          </FormFeedback>
                        )}
                        {/* <FormField
                          type="text"
                          name="phone_no"
                          placeholder="(012) 345 6789"
                          value={values.phone_no}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "phone_no", value },
                            });
                          }}
                        /> */}
                      </div>
                    </div>
                    <div className="rd_vacationflex2">
                      <p>Language *</p>
                      <div className="rd_profilethingco">
                        <FormField
                          placeholder="Select language"
                          showPlaceholder={true}
                          type="select"
                          name="language_id"
                          errors={errors}
                          touched={touched}
                          value={values.language_id.toString()}
                          options={languages}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="rd_profilerd_erpart">
                    <div className="rd_vacationflex2">
                      <p>Address Line 1 *</p>
                      <div className="rd_profilethingco">
                        <FormField
                          type="text"
                          name="company_address"
                          placeholder="Address"
                          value={values.company_address}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "company_address", value },
                            });
                          }}
                        />
                        <p className="info_txt">
                          Street address, P.O. box, Company name C/o
                        </p>
                      </div>
                    </div>
                    <div className="rd_vacationflex2">
                      <p>Address Line 2 *</p>
                      <div className="rd_profilethingco">
                        <FormField
                          type="text"
                          name="company_address"
                          placeholder="Address"
                          value={values.company_address}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "company_address", value },
                            });
                          }}
                        />
                        <p className="info_txt">
                          Apartment, suite, unit, building, floor, etc
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="rd_profilerd_erpart rd_profilerd_erpart2">
                    <div className="rd_vacationflex2">
                      <p>City</p>
                      <div className="rd_adddayofinput">
                        <FormField
                          type="text"
                          name="company_city"
                          placeholder="City"
                          value={values.company_city}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "company_city", value },
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="rd_vacationflex2">
                      <p>State/Province/Region</p>
                      <div className="rd_profilethingco">
                        <FormField
                          type="text"
                          name="company_state"
                          placeholder="State"
                          value={values.company_state}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "company_state", value },
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="rd_vacationflex2">
                      <p>Zip/Postal code</p>
                      <div className="rd_profilethingco">
                        <FormField
                          type="text"
                          name="company_zip_code"
                          placeholder="Zip Number"
                          value={values.company_zip_code}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "company_zip_code", value },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="rd_profilerd_erpart">
                    <div className="rd_vacationflex2">
                      <p>Country</p>
                      <div className="">
                        <FormField
                          placeholder="Country"
                          showPlaceholder={true}
                          type="select"
                          name="country_id"
                          errors={errors}
                          touched={touched}
                          value={values.country_id.toString()}
                          options={countries}
                        />
                      </div>
                    </div>
                    <div className="rd_vacationflex2">
                      {/* <p>Timezone *</p>
                      <div className="rd_profilethingco">
                        <div className="">
                          <FormField
                            placeholder="Select Timezone"
                            showPlaceholder={true}
                            type="select"
                            name="timezone_id"
                            errors={errors}
                            touched={touched}
                            value={values.timezone_id.toString()}
                            options={timezones}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <Col sm="12" className="submit_btn">
                    <Button
                      type="submit"
                      className="btn btn-app col-md-2 col-lg-2 col-sm-12 mb-5"
                    >
                      Save
                    </Button>
                  </Col>

                  <div className="event-card-footer">
                    {errorMessage !== "" && (
                      <Alert color="danger" className="mt-4 text-center p-10">
                        {errorMessage}
                      </Alert>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  getCompanyData,
  getCompanySettingServices,
  saveCompanyDetails,
})(Company);
