import React, { useState, useEffect, Fragment } from "react";
import { getAdminBusinessId } from "../../../utils/authHelper";
import { sendPostRequest } from "../../../actions/app-actions";
import { UPDATE_NOTIFICATIONS } from "../../../actions/types";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/Loader/Loader";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { gotProperty } from "../../../utils/utility";
import isEmpty from "../../../validation/is-empty";
import NotiBell from "../../../assets/icons/notification_bell.svg";

const Notifications = (props) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 7,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [dataSet, setDataSet] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchNotifications(pagination.pageSize, pagination.current);
  }, []);

  const loadMoreNotifications = () => {
    fetchNotifications(pagination.pageSize, pagination.current + 1);
  };

  /**
   * fetch notifications
   * @param {*} pageSize
   * @param {*} pageNumber
   */
  const fetchNotifications = (pageSize, pageNumber) => {
    const { sendPostRequest } = props;
    setIsLoading(true);
    setPagination({
      current: pageNumber,
      pageSize,
    });
    sendPostRequest({
      url: `/admin/notifications/${getAdminBusinessId()}`,
      data: {
        pageSize,
        pageNumber,
        notification_page: "E",
      },
      onSuccess: function (response) {
        const { data } = response;
        const totalUnread = data.totalUnreadNotifications;
        if (totalCount === 0) {
          setTotalCount(data.totalCount);
        }
        console.log("my resonse", data.data);

        if (isEmpty(data.data)) {
          setHasMore(false);
        }

        if (!isEmpty(dataSet)) {
          const processedData = { ...dataSet };
          Object.keys(data.data).map((key, index) => {
            (data.data[`${key}`] || []).map((notification, innerIndex) => {
              if (gotProperty(processedData, key)) {
                processedData[`${key}`].push(notification);
              } else {
                processedData[`${key}`] = [];
                processedData[`${key}`].push(notification);
              }
            });
          });
          console.log("process", processedData);
          setDataSet(processedData);
        } else {
          setDataSet(data.data);
        }
        setIsLoading(false);
      },
      onError: function (error) {
        console.log("error", error);
        setIsLoading(false);
      },
    });
  };

  return (
    <section className="all_noti" id="scrollableDiv">
      {/* <InfiniteScroll
        dataLength={14}
        next={loadMoreNotifications}
        hasMore={true}
        height={600}
        scrollableTarget="scrollableDiv"
        loader={<Loader isShowLoader={true} />}
      > */}
      {Object.keys(dataSet).map((key, index) => {
        return (
          <Fragment key={`notification-dated-${index}`}>
            <span class="day">
              {moment(key, "DD-MM-YYYY").format("dddd, MMMM, DD YYYY")}
            </span>
            {(dataSet[`${key}`] || []).map((notification, innerIndex) => {
              return (
                <Fragment key={`notification-${innerIndex}`}>
                  <div className="notification-item">
                    <div class="row no-gutters">
                      <div class="col-auto noti_img pr-2">
                        <div class="user_img">
                          <img
                            src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg"
                            alt="User Profile Pic"
                          />
                        </div>
                        <div class="noti_icon">
                          <img
                            src="/static/media/new_schedule.0a235fb35c7fa75cb5edd56dc78d3c88.svg"
                            alt="Notificion Icon"
                          />
                        </div>
                      </div>
                      <div class="col-auto noti_txt pl-2">
                        <h4>{notification.title}</h4>
                        <p>{notification.description}</p>
                        <p class="time">
                          {moment.utc(notification.dated).local().fromNow()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
      <Loader isShowLoader={isLoading} />
      {!hasMore && (
        <div className="no_more_container">
          <div className="img">
            <img src={NotiBell} alt="Notification Bell" />
          </div>
          <p>There is no more notification yet.</p>
        </div>
      )}
      {hasMore && (
        <div className="more_container text-center">
          <button className="btn" onClick={() => loadMoreNotifications()}>
            See More
          </button>
        </div>
      )}

      {/* </InfiniteScroll> */}
    </section>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  notification: state.notification,
});

export default connect(mapStateToProps, { sendPostRequest })(Notifications);
