import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Button } from "reactstrap";
import { Formik, Form } from "formik";
import Loader from "../../../components/Loader/Loader";
import * as Yup from "yup";
import FormField from "../../../components/FormField/FormField";
import ColorPickerField from "../../../components/ColorPickerField/ColorPickerField";
import Tooltip from "../../../components/Tooltip";

import { getCompanyData } from "../../../actions/companyActions";
import { saveMyOctoLink } from "../../../actions/myThriveLinkAction";
import { notification } from "../../../utils/utility";
import AfterHeaderBar from "../../../components/AfterHeaderBar/AfterHeaderBar";
import ClrPicker from "../../events/Components/Fields/ClrPicker";

const validation = Yup.object().shape({
  url_slug: Yup.string().required("URL slug is required"),
});

let initialValues = {
  url_slug: "",
  thank_u_page_url: "",
  company_description: "",
  privacy_policy: "",
  cancellation_policy: "",
  terms_and_conditions: "",
  appointment_auto_confirm_status: "D",
  default_color_status: "D",
  event_primary_color: {
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  },
  event_secondary_color: {
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  },
  event_background_color: {
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  },
  text_color: {
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  },
};

const MyOctoLink = (props) => {
  const [formValues, setFormValues] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);

  /**
   *
   * @returns object
   */
  const getDefaultColor = () => {
    return {
      r: "241",
      g: "112",
      b: "19",
      a: "1",
    };
  };

  /**
   * processed color value
   * @param {*} color
   */
  const getColorValue = (color) => {
    if (color) {
      let colorValues = JSON.parse(color);
      if (
        colorValues.hasOwnProperty("r") &&
        colorValues.hasOwnProperty("g") &&
        colorValues.hasOwnProperty("b")
      ) {
        return {
          r: colorValues.r.toString(),
          g: colorValues.g.toString(),
          b: colorValues.b.toString(),
          a: colorValues.a.toString(),
        };
      } else {
        return getDefaultColor();
      }
    } else {
      return getDefaultColor();
    }
  };

  useEffect(() => {
    const { getCompanyData } = props;

    setPageLoading(true);

    getCompanyData({
      data: {},
      onSuccess: function (details) {
        setFormValues({
          id: details.id,
          url_slug: details.url_slug,
          thank_u_page_url: details.thank_u_page_url,
          company_description: details.company_description,
          privacy_policy: details.privacy_policy,
          cancellation_policy: details.cancellation_policy,
          terms_and_conditions: details.terms_and_conditions,

          appointment_auto_confirm_status:
            details.appointment_auto_confirm_status
              ? details.appointment_auto_confirm_status
              : "D",
          default_color_status: details.default_color_status
            ? details.default_color_status
            : "D",
          event_primary_color: getColorValue(details.event_primary_color),
          event_secondary_color: getColorValue(details.event_secondary_color),
          event_background_color: getColorValue(details.event_background_color),
          text_color: getColorValue(details.text_color),
        });
        setPageLoading(false);
      },
      onError: function (error) {
        setPageLoading(false);
      },
    });
  }, []);

  return (<>
    <AfterHeaderBar title="My Octo Link" />
    <section>
      <Loader isShowLoader={pageLoading} />
      <div>
        <div className="eventdetailsaddbox rd_noshadow">
          <div className="rd_vacationfilterpart rd_specialcenterthingim rd_specialcenterthingimte">
            <Formik
              validationSchema={validation}
              initialValues={formValues || initialValues}
              enableReinitialize
              onSubmit={(data) => {
                const { saveMyOctoLink } = props;
                setPageLoading(true);
                let processedData = {
                  ...data,
                  event_primary_color: JSON.stringify(data.event_primary_color),
                  event_secondary_color: JSON.stringify(
                    data.event_secondary_color
                  ),
                  event_background_color: JSON.stringify(
                    data.event_background_color
                  ),
                  text_color: JSON.stringify(data.text_color),
                };
                console.log("data", processedData);

                saveMyOctoLink({
                  data: {
                    ...processedData,
                  },
                  onSuccess: (response) => {
                    if (response.success) {
                      notification.success(response.message);
                    }
                    setPageLoading(false);
                    console.log("response", response);
                  },
                  onError: (error) => {
                    notification.error(
                      "An error occured during save, Please try later"
                    );
                    setPageLoading(false);
                    console.log("company error ", error);
                  },
                });
              }}
            >
              {(formProps) => {
                const { values, errors, touched, handleChange, setFieldValue } =
                  formProps;

                console.log("new values", values);

                return (
                  <>
                    <Form style={{ width: "100%", padding: 20 }} className="octo_link_sec">
                      <div className="row justify-content-between">
                        {/* Thrive Link */}
                        <div className="col-lg-8 col-md-6 cus_field">
                          <label>Octo Link * <Tooltip code="P24F1" /> </label>
                          <div className="input-group">
                            <div className="input-group-prepend rd_dropdownbtn">
                              <div className="input-group-text rd_domainunofi">
                                https://meetocto.com
                              </div>
                            </div>
                            <FormField
                              type="text"
                              name="url_slug"
                              placeholder="URL Slug"
                              elementclasses="noshadfoc"
                              value={values.url_slug}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: { name: "url_slug", value },
                                });
                              }}
                            />
                          </div>
                        </div>
                        {/* Thanks Page url */}
                        <div className="col-lg-4 col-md-6 cus_field">
                          <label>Thank You Page URL <Tooltip code="P24F2" /> </label>
                          <div className="input-group">
                            <FormField
                              type="text"
                              name="thank_u_page_url"
                              placeholder="Thank You page url"
                              elementclasses="p-2 noshadfoc"
                              value={values.thank_u_page_url}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: {
                                    name: "thank_u_page_url",
                                    value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                        {/* Company Descripton */}
                        <div className="col-md-6 cus_field">
                          <label>Company Description <Tooltip code="P24F3" /></label>
                          <FormField
                            component="textarea"
                            name="company_description"
                            placeholder="Company Description"
                            elementclasses="rd_areaprofilepagethi"
                            value={values.company_description}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: {
                                  name: "company_description",
                                  value,
                                },
                              });
                            }}
                          />
                        </div>
                        {/* Privacy Policy */}
                        <div className="col-md-6 cus_field">
                          <label>Privacy Policy <Tooltip code="P24F4" /></label>
                          <FormField
                            component="textarea"
                            name="privacy_policy"
                            placeholder="Privacy Policy"
                            elementclasses="rd_areaprofilepagethi"
                            value={values.privacy_policy}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: {
                                  name: "privacy_policy",
                                  value,
                                },
                              });
                            }}
                          />
                        </div>
                        {/* Cancelation POlicy */}
                        <div className="col-md-6 cus_field">
                          <label>Cancellation Policy <Tooltip code="P24F5" /> </label>
                          <FormField
                            component="textarea"
                            name="cancellation_policy"
                            placeholder="Cancellation Policy"
                            elementclasses="rd_areaprofilepagethi"
                            value={values.cancellation_policy}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: {
                                  name: "cancellation_policy",
                                  value,
                                },
                              });
                            }}
                          />
                        </div>
                        {/* Terms & Conditions */}
                        <div className="col-md-6 cus_field">
                          <label>Terms and Coditions <Tooltip code="P24F6" /></label>
                          <FormField
                            component="textarea"
                            name="terms_and_conditions"
                            placeholder="Terms and Conditions"
                            elementclasses="rd_areaprofilepagethi"
                            value={values.terms_and_conditions}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: {
                                  name: "terms_and_conditions",
                                  value,
                                },
                              });
                            }}
                          />
                        </div>
                        {/* Checkbox */}
                        <div className="col-12 col-lg-auto cus_field">
                          <div className="row">
                            <div className="col-md-6 col-lg-auto toggle_field">
                              <div className="box_content p-0">
                                <div className="form-check form-switch">
                                  <label
                                    className="form-check-label"
                                    htmlFor="appointment_auto_confirm_status"
                                    style={{marginRight: 50}}
                                  >
                                    Appointment Auto Confirm <Tooltip code="P24F7" />
                                  </label>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="appointment_auto_confirm_status"
                                    name="appointment_auto_confirm_status"
                                    checked={
                                      values.appointment_auto_confirm_status === "E"
                                        ? true
                                        : false
                                    }
                                    onChange={(event) => {
                                      handleChange({
                                        target: {
                                          name: "appointment_auto_confirm_status",
                                          value: event.target.checked ? "E" : "D",
                                        },
                                      });
                                    }}
                                    style={{ float: "right" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-auto toggle_field">
                              <div className="box_content p-0">
                                <div className="form-check form-switch">
                                  <label
                                    className="form-check-label"
                                    htmlFor="default_color_status"
                                    style={{marginRight: 50}}
                                  >
                                    Default Color Scheme <Tooltip code="P24F8" />
                                  </label>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="default_color_status"
                                    name="default_color_status"
                                    checked={
                                      values.default_color_status === "E"
                                        ? true
                                        : false
                                    }
                                    onChange={(event) => {
                                      handleChange({
                                        target: {
                                          name: "default_color_status",
                                          value: event.target.checked ? "E" : "D",
                                        },
                                      });
                                    }}
                                    style={{ float: "right" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Title */}
                        <div className="col-12">
                          <h3 className="custom_title">Customize Theme</h3>
                        </div>
                        {/* Primary Color */}
                        <div className="col-12 col-md-auto octo_clr mb-4 mb-lg-0">
                          <div className="box_content p-0">
                            <div className="d-flex align-items-center">
                              <label
                                className="form-check-label"
                                htmlFor=""
                              >
                                Primary Color <Tooltip code="P24F9" />
                              </label>
                              <ClrPicker />
                            </div>
                          </div>
                        </div>
                        {/* Secondary Color */}
                        <div className="col-12 col-md-auto octo_clr mb-4 mb-lg-0">
                          <div className="box_content p-0">
                            <div className="d-flex align-items-center">
                              <label
                                className="form-check-label"
                                htmlFor=""
                              >
                                Secondary Color <Tooltip code="P24F10" />
                              </label>

                              <ClrPicker />
                            </div>
                          </div>
                        </div>
                        {/* Background Font */}
                        <div className="col-12 col-md-auto octo_clr mb-4 mb-lg-0">
                          <div className="box_content p-0">
                            <div className="d-flex align-items-center">
                              <label
                                className="form-check-label"
                                htmlFor=""
                              >
                                Background Font <Tooltip code="P24F11" />
                              </label>
                              <ClrPicker />
                            </div>
                          </div>
                        </div>
                        {/* Text COlor */}
                        <div className="col-12 col-md-auto octo_clr">
                          <div className="box_content p-0">
                            <div className="d-flex align-items-center">
                              <label
                                className="form-check-label"
                                htmlFor=""
                              >
                                Text Color <Tooltip code="P24F12" />
                              </label>
                              <ClrPicker />
                              {/* <ColorPickerField
                                value={values.text_color}
                                onChange={(value) => {
                                  handleChange({
                                    target: {
                                      name: "text_color",
                                      value,
                                    },
                                  });
                                }}
                              /> */}
                            </div>
                          </div>
                        </div>
                        {/* Save Btn */}
                        <Col lg="12" md="12" sm="12" className="mt-5">
                        <Button
                          style={{ marginBottom: "10px" }}
                          type="submit"
                          className="btn btn-app col-md-2 col-lg-2 col-sm-12 float-right"
                        >
                          Save
                        </Button>
                      </Col>
                      </div>
                      {/* Old Src */}
                      {/* <div className="rd_seotoppart1contflitem13 d-inline-block">

                      </div>

                      <div className="rd_seotoppart1contflitem13 d-inline-block">

                      </div>

                      <div className="rd_seotoppart1contflitem13 d-inline-block">


                      </div>

                      <div className="rd_seotoppart1contflitem13 d-inline-block">


                      </div>

                      <div className="rd_seotoppart1contflitem13 d-inline-block">

                      </div>

                      <div className="rd_seotoppart1contflitem13 d-inline-block">

                      </div>

                      <div className="rd_seotoppart1contflitemfull">
                        <h5>
                          <strong>Customize Theme</strong>
                        </h5>
                        <div className="rd_bottomthriveliflex">
                          <div className="rd_seotoppart1contflitem13">
                            <div className="rd_borderboxthgn">
                              <div className="box_content">
                                <div className="form-check form-switch">
                                  <label
                                    className="form-check-label"
                                    htmlFor=""
                                  >
                                    Primary Color
                                  </label>
                                  <ColorPickerField
                                    value={values.event_primary_color}
                                    onChange={(value) => {
                                      handleChange({
                                        target: {
                                          name: "event_primary_color",
                                          value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="rd_seotoppart1contflitem13">
                            <div className="rd_borderboxthgn">
                              <div className="box_content">
                                <div className="form-check form-switch">
                                  <label
                                    className="form-check-label"
                                    htmlFor=""
                                  >
                                    Secondary Color
                                  </label>

                                  <ColorPickerField
                                    value={values.event_secondary_color}
                                    onChange={(value) => {
                                      handleChange({
                                        target: {
                                          name: "event_secondary_color",
                                          value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="rd_seotoppart1contflitem13">
                            <div className="rd_borderboxthgn">
                              <div className="box_content">
                                <div className="form-check form-switch">
                                  <label
                                    className="form-check-label"
                                    htmlFor=""
                                  >
                                    Background Font
                                  </label>
                                  <ColorPickerField
                                    value={values.event_background_color}
                                    onChange={(value) => {
                                      handleChange({
                                        target: {
                                          name: "event_background_color",
                                          value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="rd_bottomthriveliflex">
                          <div className="rd_seotoppart1contflitem13">
                            <div className="rd_borderboxthgn">
                              <div className="box_content">
                                <div className="form-check form-switch">
                                  <label
                                    className="form-check-label"
                                    htmlFor=""
                                  >
                                    Text Color
                                  </label>

                                  <ColorPickerField
                                    value={values.text_color}
                                    onChange={(value) => {
                                      handleChange({
                                        target: {
                                          name: "text_color",
                                          value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  </>);
};

export default connect(null, {
  getCompanyData,
  saveMyOctoLink,
})(MyOctoLink);