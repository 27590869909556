import React from "react";
import { Col, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import AddPromo from "./Promo/create";
import PromoListing from "./Promo/list";
import SpecialOffer from "./Promo/SpecialOffer";
import RecurrentDiscount from "./Promo/recurrent-discount";

class Discount extends React.Component {
  constructor(props) {
    super(props);
    this.setState({ promoCode: {} });

    this.editClickPromoCode = this.editClickPromoCode.bind(this);
  }
  state = {
    activeTab: "1",
  };

  editClick = (value) => {
    console.log(value);
  };

  ChangeTab = () => {
    this.setState({
      activeTab: "2", // open add new form when click on edit
    });
  };

  editClickPromoCode(param) {
    this.setState({
      activeTab: "3", // open add new form when click on edit
    });
    this.setState({ promoCode: param });
    console.log("do something: ", param);
  }

  toggle = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    return (
      <div class="eventdetailsaddbox rd_noshadow promo_screen">
        <div class="boxheader rd_floatingheaderthig">
          <div class="rd_inputselectheader">
            <div class="rd_selectheaderrdt2 rd_selectheaderrdt2profile">
              <button
                className={classnames({
                  active: this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Recurrent Discounts
              </button>
              <button
                className={classnames({
                  active: this.state.activeTab === "2",
                })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                Promocodes
              </button>
              {/* <button
                className={classnames({
                  active: this.state.activeTab === "3",
                })}
                onClick={() => {
                  this.toggle("3");
                  this.setState({ promoCode: {} });
                }}
              >
                Add New
              </button> */}

              <button
                className={classnames({
                  active: this.state.activeTab === "4",
                })}
                onClick={() => {
                  this.toggle("4");
                }}
              >
                Special Ribbon
              </button>
            </div>
          </div>
        </div>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <RecurrentDiscount />
          </TabPane>
          <TabPane tabId="2">
            <PromoListing onToggleTab={this.toggle}></PromoListing>
          </TabPane>
          <TabPane tabId="3">
            <Col md="12">
              <AddPromo />
            </Col>
          </TabPane>
          <TabPane tabId="4">
            <Col md="12">
              <SpecialOffer />
            </Col>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
export default Discount;
