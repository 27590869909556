import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { sendGetRequest } from "../../../../actions/app-actions";
import Loader from "../../../../components/Loader/Loader";
import { Modal, ModalBody, Row, Col, Button } from "reactstrap";
import moment from "moment-timezone";
import { getLoggedInUserTimezone, toUserTime } from "../../../../utils/utility";

const PendingViewDetailsModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const { eventId } = props;

    const { sendGetRequest } = props;

    setIsLoading(true);

    sendGetRequest({
      url: `admin/adhoc/${eventId}`,
      onSuccess: function (response) {
        console.log("data", response);
        setData(response.event);
        setIsLoading(false);
      },
      onError: function (error) {
        setIsLoading(false);
      },
    });
  }, []);

  return (
    <>
      <Loader isShowLoader={isLoading} />
      <Modal
        returnFocusAfterClose={true}
        isOpen={props.showViewDetailModal && !isLoading}
        toggle={props.handleClose}
        className="event-modal modal-lg modal-dialog"
      >
        <ModalBody>
          <div className="">
            <div className="box_content py-0">
              <div className="rd_inputselectheadermodalsetting">
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="rd_modalheadertext rd_modalheadertextnm my-0 text-capitalize">
                    {data ? data.event_name : null}
                  </h2>
                  <button
                    className="rd_colsebtn"
                    onClick={props.handleClose}
                  ></button>
                </div>
              </div>
              <Row className="mt-3">
                <Col lg="12">
                  <Row>
                    <Col lg="6">
                      <div className="vd-title">Event Title :</div>
                      <div className="vd-description pt-1 pb-3">
                        {data ? data.event_name : null}
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="vd-title">Venue :</div>
                      <div className="vd-description pt-1 pb-3">
                        {data ? data?.venue?.venue : null}
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="vd-title">Event Duration :</div>
                      <div className="vd-description pt-1 pb-3">
                        {data
                          ? `${data?.event_duration_in_minutes} Minutes`
                          : null}
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="vd-title">Expire On :</div>
                      <div className="vd-description pt-1 pb-3">
                        {data
                          ? moment(data?.created_at, "YYYY-MM-DD")
                              .add(data?.link_expiration_period, "days")
                              .format("DD-MM-YYYY")
                          : null}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {data ? (
                <>
                  <div
                    className="rd_emebedparinmodl"
                    style={{ background: "none" }}
                  >
                    <h5>
                      <strong>Selected Slots</strong>
                    </h5>
                    {data?.adhoc_dates.map((adhocDate, index) => {
                      return (
                        <Fragment key={index}>
                          <h4 className="mt-3 mb-1">
                            <span className="rd_archiveiconth"></span>
                            {moment(adhocDate.dated, "YYYY-MM-DD").format(
                              "dddd, MMMM, DD YYYY"
                            )}
                          </h4>
                          <div className="row add-time-slot-container custom_slot">
                            <Row>
                              {(adhocDate.availabilities || []).map(
                                (availability, innerIndex) => {
                                  return (
                                    <Col md="2" lg="2">
                                      <div
                                        key={innerIndex}
                                        className="slot time_active"
                                      >
                                        {toUserTime(
                                          availability.start_time,
                                          getLoggedInUserTimezone(),
                                          "HH:mm:ss",
                                          "HH:mm"
                                        )}
                                      </div>
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, {
  sendGetRequest,
})(PendingViewDetailsModal);
