import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";

class PaymentConfigMissingModal extends Component {
  render() {
    const { handleClose, showPaymentConfigMissingModal } = this.props;

    return (
      <>
        <Modal isOpen={showPaymentConfigMissingModal} toggle={handleClose}>
          <ModalBody>
            <div className="">
              <div className="rd_modalvieew">
                <div className="box_content rd_modalboxcontent">
                  <div className="rd_inputselectheadermodalsetting">
                    <button
                      className="rd_colsebtn"
                      onClick={handleClose}
                    ></button>
                  </div>

                  <h5 className="rd_modalheadertext rd_modalheadertextnm">
                    Complete Payment Settings
                  </h5>

                  <p className="rd_modalheadertextparath mt-5 mb-5">
                    In order to proceed with this step you will need to complete
                    payment settings please click on this{" "}
                    <Link to={`/admin/settings/payment`}>Link</Link>.
                  </p>

                  <div className="rd_cancelappbtnmodal">
                    <button className="rd_cancel" onClick={handleClose}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default PaymentConfigMissingModal;
