import axios from "../axios-instance";

/**
 * get event advanced page data
 * @param {*} options
 * @returns
 */
export const getAdminReferrals = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post(`/admin/referrals`, data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};
