import React, { Fragment, useState, useEffect } from "react";
import Joyride, { EVENTS, ACTIONS } from "react-joyride";
import { connect, useDispatch } from "react-redux";
import { TOGGLE_ADMIN_PROFILE_DROPDOWN } from "../../../actions/types";
import OctoIcon from "../../../assets/icons/meetocto_icon.svg";

const Footer = (props) => {
  const [steps, setSteps] = useState([]);
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    setSteps([
      {
        content: (
          <div className="tour_guide d-flex align-items-start">
            <div className="img">
              <div className="steps">1/4</div>
              <img src={OctoIcon} alt="" />
            </div>
            <p>
              Welcome to our guided tour, your first step is the quick set up to
              ensure your account will work as designed.
            </p>
          </div>
        ),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        title: "Quick Setup",
        target: ".react-tour-step-one",
      },
      {
        content: (
          <div className="tour_guide d-flex align-items-start">
            <div className="img">
              <div className="steps">2/4</div>
              <img src={OctoIcon} alt="" />
            </div>
            <p>
              Visit your profile settings and double check all info is accurate.
            </p>
          </div>
        ),
        disableBeacon: true,
        placement: "left",
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        title: "Quick Setup",
        target: ".react-tour-step-two",
      },
      {
        content: (
          <div className="tour_guide d-flex align-items-start">
            <div className="img">
              <div className="steps">3/4</div>
              <img src={OctoIcon} alt="" />
            </div>
            <p>You are ready to create your first event!</p>
          </div>
        ),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        title: "Quick Setup",
        target: ".react-tour-step-three",
      },
      {
        content: (
          <div className="tour_guide d-flex align-items-start">
            <div className="img">
              <div className="steps">4/4</div>
              <img src={OctoIcon} alt="" />
            </div>
            <p>
              Share your link so invitees can start scheduling meetings with
              you.
            </p>
          </div>
        ),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        title: "Quick Setup",
        target: ".react-tour-step-fourth",
      },
    ]);
    setRunTour(true);
  }, []);

  const handleCallback = (data) => {
    const { action, index, lifecycle, type } = data;

    const { layout } = props;

    /*
    console.log(
      "action",
      action,
      "index",
      index,
      "lifecycle",
      lifecycle,
      "type",
      type,
      "Events",
      EVENTS
    );
    */

    if (action === "close") {
      setRunTour(false);
      return;
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (
        (!layout.profileDropdownStatus &&
          index === 0 &&
          action === ACTIONS.NEXT) ||
        (index === 2 && action === ACTIONS.PREV)
      ) {
        dispatch({
          type: TOGGLE_ADMIN_PROFILE_DROPDOWN,
          payload: !layout.profileDropdownStatus,
        });
        setRunTour(true);
        setStepIndex(nextStepIndex);
      } else if (
        index === 1 ||
        index === 2 ||
        (index === 3 && action === ACTIONS.PREV)
      ) {
        setRunTour(true);
        setStepIndex(nextStepIndex);
      } else {
        setRunTour(false);
      }
    }
  };

  return (
    <Fragment>
      {/* <Joyride
        steps={steps}
        run={runTour}
        callback={handleCallback}
        stepIndex={stepIndex}
        continuous
      /> */}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps, null)(Footer);
