import React, { Component } from "react";
import { Row, Col, Button, FormGroup } from "reactstrap";
import FormField from "./Components/Common/FormField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ToggleField from "./Components/Common/ToggleField";
import SidebarProgress from "./Components/Sidebar/sidebar-progress";
import InviteModal from "./Components/Modals/InviteModal";
import SectionQuestions from "./Components/Sections/section-questions";
import { Fragment } from "react";
import {
  saveEventAdvancedPage,
  getEventAdvancedPage,
} from "../../actions/eventActions";
import { connect } from "react-redux";
import { withRouter } from "../../utils/utility";
// import AddQuestionModal from './Components/Modals/AddQuestionModal';

const validation = Yup.object().shape({
  //name: Yup.string().required("Name is required"),
});

let initialValues = {
  event_public_visiblity_status: "D",
  confirmation_page: "default",
  confirmation_page_url: "",
  customer_feedback_status: "D",
  email_reminder_status: "D",
  sms_reminder_status: "D",
  email_followup_status: "D",
};

class AddNewEventAdvanced extends Component {
  formRef = React.createRef();



  state = {
    questions: [
      {
        question:
          "Please share anything that will help prepare for our meeting.",
        question_type: "multiline",
        show_status: "E",
        mandatory_status: "D",
        details: [],
      },
    ],
    invitee: {
      defaultView: "name",
      allowGuest: false,
      autofill: false,
    },
    isLoading: false,
  };

  componentDidMount() {
    const _this = this;

    const { getEventAdvancedPage } = this.props || {};
    const id = this.getId();

    getEventAdvancedPage({
      data: {
        id,
      },
      onSuccess: function (response) {
        initialValues = {
          event_public_visiblity_status: response.event_public_visiblity_status,
          confirmation_page: response.confirmation_page,
          confirmation_page_url: response.confirmation_page_url,
          customer_feedback_status: response.customer_feedback_status,
          email_reminder_status: response.email_reminder_status,
          sms_reminder_status: response.sms_reminder_status,
          email_followup_status: response.email_followup_status,
        };

        const newQuestions = (response.questions || []).map(
          (question, questionIndex) => {
            question.details = question.question_details;
            return question;
          }
        );

        _this.setState({
          questions: newQuestions,
        });

        // invitee settings
        const inviteeSettings = {
          defaultView:
            response.invitee_settings_type == "1" ? "name" : "first-name",
          allowGuest: response.invitee_guests_status == "E",
          autofill: response.invitee_autofill_status == "E",
        };
        _this.setState({
          invitee: inviteeSettings,
        });
      },
    });
  }

  /**
   * Get id
   */
  getId = () => {
    const { router } = this.props;
    const { params } = router || {};
    const { id } = params || {};
    return id;
  }

  handleInviteeChange = (inviteVlaue) => {
    const { invitee } = this.state;

    this.setState({
      invitee: {
        ...invitee,
        ...inviteVlaue,
      },
    });
  };

  addQuestion = (question) => {
    const { questions } = this.state || {};
    this.setState({
      questions: [...questions, question],
    });
  };

  onChange = (fieldName, fieldValue, index) => {
    const { questions } = this.state || {};
    const allowedTypes = ["select", "checkbox", "radio"];

    const newQuestions = (questions || []).map((question, questionIndex) => {
      let options = {
        ...question,
        [fieldName]: fieldValue,
      };
      if (
        fieldName === "question_type" &&
        (allowedTypes || []).indexOf(fieldValue) === -1
      ) {
        options = {
          ...question,
          [fieldName]: fieldValue,
          details: [],
        };
      }
      if (questionIndex === index) {
        return options;
      }

      return question;
    });

    console.log({
      newQuestions,
    });

    this.setState({
      questions: newQuestions,
    });
  };

  addOption = (index) => {
    const { questions } = this.state || {};
    const newQuestions = (questions || []).map((question, questionIndex) => {
      if (questionIndex === index) {
        const { details } = question || {};
        const defaultOption = {
          field_value: "",
          field_label: "",
        };
        return {
          ...question,
          details: [...details, defaultOption],
        };
      }

      return question;
    });

    this.setState({
      questions: newQuestions,
    });
  };

  onRemoveQuestion = (questionIndex) => {
    const { questions } = this.state || {};
    const newQuestions = (questions || []).filter(
      (option, optIndex) => optIndex !== questionIndex
    );
    this.setState({
      questions: newQuestions,
    });
  };

  removeOption = (questionIndex, optionIndex) => {
    const { questions } = this.state || {};
    const newQuestions = (questions || []).map((question, index) => {
      const { details: options } = question || {};
      if (index === questionIndex) {
        return {
          ...question,
          details: (options || []).filter(
            (option, optIndex) => optIndex !== optionIndex
          ),
        };
      }

      return question;
    });

    this.setState({
      questions: newQuestions,
    });
  };

  formatLabel = (optionText) => {
    const newOption = optionText.replace(/ /g, "-");
    return newOption.toLowerCase();
  };

  changeOption = (questionIndex, optionIndex, fieldValue) => {
    const { questions } = this.state || {};

    const newQuestions = (questions || []).map((question, index) => {
      const { details: options } = question || {};
      if (index === questionIndex) {
        const newOptions = (options || []).map((option, optIndex) => {
          if (optIndex === optionIndex) {
            return {
              field_label: fieldValue,
              fieldValue: this.formatLabel(fieldValue),
            };
          }

          return option;
        });
        return {
          ...question,
          details: newOptions,
        };
      }

      return question;
    });

    this.setState({
      questions: newQuestions,
    });
  };

  /**
   * on cancel button handler
   */
  onCancel = () => {
    const { router } = this.props;
    const { navigate } = router || {};
    navigate("/admin/events/list");
  };

  /**
   * save event as draft
   */
  onGoBack = () => {
    const { router } = this.props;
    const { navigate } = router || {};
    navigate(-1);
  };

  /**
   * save event as draft
   */
  onSaveAsDraft = () => {
    const data = this.formRef.current.values;
    const { invitee, questions } = this.state;


    const id = this.getId();

    this.setState({
      isLoading: true,
    });

    let newData = {
      ...data,
      invitee_settings_type: invitee.defaultView == "name" ? 1 : 2,
      invitee_guests_status: invitee.allowGuest ? "E" : "D",
      invitee_autofill_status: invitee.autofill ? "E" : "D",
      event_questions: questions,
      id: parseInt(id),
    };

    saveEventAdvancedPage({
      data: newData,
      onSuccess: (eventId) => {
        const { router } = this.props;
        const { navigate } = router || {};
        navigate(`/admin/events/list`);
      },
      onError: (error) => {
        this.setState({
          isLoading: false,
          errorMessage: error,
        });
      },
    });
  };

  render() {
    const { questions, invitee, isLoading } = this.state || {};
    const { defaultView, allowGuest } = invitee || {};
    const id = this.getId();
    return (
      <div className="create-event-wrapper">
        <div className="create-event-container">
          <Row className="flex-column-reverse flex-md-row">
            <Col md="9" lg="9">
              <div className="event-card">
                <div className="event-card-head">
                  <h3 className="event-title">Advanced</h3>
                </div>
                <Formik
                  innerRef={this.formRef}
                  validationSchema={validation}
                  initialValues={initialValues}
                  enableReinitialize
                  onSubmit={(data) => {
                    const { invitee, questions } = this.state;

                    this.setState({
                      isLoading: true,
                    });

                    let newData = {
                      ...data,
                      invitee_settings_type:
                        invitee.defaultView == "name" ? 1 : 2,
                      invitee_guests_status: invitee.allowGuest ? "E" : "D",
                      invitee_autofill_status: invitee.autofill ? "E" : "D",
                      event_questions: questions,
                      id: parseInt(id),
                    };

                    saveEventAdvancedPage({
                      data: newData,
                      onSuccess: (eventId) => {
                        const { router } = this.props;
                        const { navigate } = router || {};
                        navigate(`/admin/events/list`);
                      },
                      onError: (error) => {
                        this.setState({
                          isLoading: false,
                          errorMessage: error,
                        });
                      },
                    });
                  }}
                >
                  {(formProps) => {
                    const {
                      values,
                      errors,
                      touched,
                      handleChange,
                      setFieldValue,
                    } = formProps;
                    return (
                      <Form>
                        <div className="event-card-body">
                          <div className="event-field-group padding-zero">
                            {defaultView === "first-name" ? (
                              <Fragment>
                                <Row>
                                  <Col md="6" lg="6">
                                    <FormField
                                      showLabel
                                      placeholder="First Name"
                                      type="text"
                                      name="type"
                                      label="First Name"
                                      errors={errors}
                                      touched={touched}
                                      disabled={true}
                                      value=""
                                    />
                                  </Col>
                                  <Col md="6" lg="6">
                                    <FormField
                                      showLabel
                                      placeholder="Last Name"
                                      type="text"
                                      name="type"
                                      label="Last Name"
                                      errors={errors}
                                      touched={touched}
                                      disabled={true}
                                      value=""
                                    />
                                  </Col>
                                </Row>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <Row>
                                  <Col md="12" lg="12">
                                    <FormField
                                      showLabel
                                      placeholder="Name"
                                      type="text"
                                      name="type"
                                      label="Name"
                                      errors={errors}
                                      touched={touched}
                                      disabled={true}
                                      value=""
                                    />
                                  </Col>
                                </Row>
                              </Fragment>
                            )}
                            <Row className="mt-2">
                              <Col md="12" lg="12">
                                <FormGroup className="event-form-group">
                                  <label>Email Address</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    value=""
                                    onChange={this.handleChange}
                                    name="email"
                                    placeholder="Email Address"
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            {/* {allowGuest === true && (
                              <Row className="mt-2">
                                <Col md="2" lg="2">
                                  <Button className="btn btn-default btn-outline">
                                    Add Guest
                                  </Button>
                                </Col>
                              </Row>
                            )} */}
                            <Row>
                              <Col md="3" lg="3">
                                <div className="form-group">
                                  <InviteModal
                                    invitee={invitee}
                                    onChange={this.handleInviteeChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="event-field-group">
                            <div className="form-group event-group">
                              <label>Ask Attendee Questions</label>
                              <SectionQuestions
                                onRemoveQuestion={this.onRemoveQuestion}
                                questions={questions}
                                onChange={this.onChange}
                                onAddQuestion={this.addQuestion}
                                onAddOption={this.addOption}
                                onRemoveOption={this.removeOption}
                                onChangeOption={this.changeOption}
                              />
                            </div>
                          </div>
                          <div className="event-field-group">
                            <Row>
                              <Col md="6" lg="6">
                                <FormGroup
                                  tag="fieldset"
                                  className="event-form-group "
                                >
                                  <label>Make Event Public</label>
                                  <Row>
                                    <Col md="6" lg="6">
                                      <div className="event-form-check">
                                        <input
                                          type="radio"
                                          id="public-visiblity-status-yes"
                                          name="event_public_visiblity_status"
                                          checked={
                                            values.event_public_visiblity_status ==
                                            "E"
                                          }
                                          value="E"
                                          onChange={({ target }) => {
                                            const { name, value } =
                                              target || {};
                                            handleChange({
                                              target: {
                                                name,
                                                value,
                                              },
                                            });
                                          }}
                                        ></input>
                                        <label htmlFor="public-visiblity-status-yes">
                                          <span></span>
                                          Yes
                                        </label>
                                      </div>
                                    </Col>
                                    <Col md="6" lg="6">
                                      <div className="event-form-check">
                                        <input
                                          type="radio"
                                          id="public-visiblity-status-no"
                                          name="event_public_visiblity_status"
                                          value="D"
                                          checked={
                                            values.event_public_visiblity_status ==
                                            "D"
                                          }
                                          onChange={({ target }) => {
                                            const { name, value } =
                                              target || {};
                                            handleChange({
                                              target: {
                                                name,
                                                value,
                                              },
                                            });
                                          }}
                                        ></input>
                                        <label htmlFor="public-visiblity-status-no">
                                          <span></span>
                                          No
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup
                                  tag="fieldset"
                                  className="event-form-group "
                                >
                                  <label>Confirmation Page</label>
                                  <Row>
                                    <Col md="12" lg="12">
                                      <div className="event-form-check">
                                        <input
                                          type="radio"
                                          id="confirmation-page-yes"
                                          name="confirmation_page"
                                          value="default"
                                          checked={
                                            values.confirmation_page ==
                                            "default"
                                          }
                                          onChange={({ target }) => {
                                            const { name, value } =
                                              target || {};
                                            handleChange({
                                              target: {
                                                name,
                                                value,
                                              },
                                            });
                                          }}
                                        ></input>
                                        <label htmlFor="confirmation-page-yes">
                                          <span></span>
                                          Default
                                        </label>
                                      </div>
                                    </Col>
                                    <Col md="12" lg="12">
                                      <div className="event-form-check">
                                        <input
                                          type="radio"
                                          id="confirmation-page-no"
                                          name="confirmation_page"
                                          value="external"
                                          checked={
                                            values.confirmation_page ==
                                            "external"
                                          }
                                          onChange={({ target }) => {
                                            const { name, value } =
                                              target || {};
                                            handleChange({
                                              target: {
                                                name,
                                                value,
                                              },
                                            });
                                          }}
                                        ></input>
                                        <label htmlFor="confirmation-page-no">
                                          <span></span>
                                          External (Coming Soon)
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>

                                  {values.confirmation_page === "external" && (
                                    <div className="form-group">
                                      <Row>
                                        <Col md="6">
                                          <FormField
                                            showLabel
                                            placeholder="Confirmation Page URL"
                                            type="text"
                                            name="type"
                                            label="Confirmation Page URL"
                                            value={values.confirmation_page_url}
                                            errors={errors}
                                            touched={touched}
                                            onChange={({ target }) => {
                                              const { value } = target;
                                              handleChange({
                                                target: {
                                                  name: "confirmation_page_url",
                                                  value,
                                                },
                                              });
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md="6" lg="6">
                                <FormGroup
                                  tag="fieldset"
                                  className="event-form-group "
                                >
                                  <label>Reminders</label>
                                  {/* <div className="form-group event-form-group d-flex justify-content-between">
                                    <label>Email Reminders</label>
                                    <ToggleField
                                      classes={"text-right"}
                                      labelText=""
                                      value={values.email_reminder_status}
                                      onChange={(value) => {
                                        handleChange({
                                          target: {
                                            name: "email_reminder_status",
                                            value,
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="form-group event-form-group d-flex justify-content-between">
                                    <label>SMS Reminders</label>
                                    <ToggleField
                                      classes={"text-right"}
                                      labelText=""
                                      value={values.sms_reminder_status}
                                      onChange={(value) => {
                                        handleChange({
                                          target: {
                                            name: "sms_reminder_status",
                                            value,
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="form-group event-form-group d-flex justify-content-between">
                                    <label>Email Follow-up</label>
                                    <ToggleField
                                      classes={"text-right"}
                                      labelText=""
                                      value={values.email_followup_status}
                                      onChange={(value) => {
                                        handleChange({
                                          target: {
                                            name: "email_followup_status",
                                            value,
                                          },
                                        });
                                      }}
                                    />
                                  </div> */}
                                  <div className="toggle_field big">
                                    <div className="box_content w-100 p-0">
                                      <div className="form-check form-switch mw-100">
                                        <input className="form-check-input" type="checkbox" id="emailReminders" name="email_reminder_status"
                                          value={values.email_reminder_status}
                                          checked={
                                            values.email_reminder_status ===
                                            "E"
                                          }
                                          onChange={(event) => {
                                            handleChange({
                                              target: {
                                                name: "email_reminder_status",
                                                value: event.target.checked
                                                  ? "E"
                                                  : "D",
                                              },
                                            });
                                          }}
                                        />
                                        <label className="form-check-label cus_label" htmlFor="emailReminders">
                                          Email Reminders
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="toggle_field big">
                                    <div className="box_content w-100 p-0">
                                      <div className="form-check form-switch mw-100">
                                        <input className="form-check-input" type="checkbox" id="smsReminders" name="sms_reminder_status"
                                          value={values.sms_reminder_status}
                                          checked={
                                            values.sms_reminder_status ===
                                            "E"
                                          }
                                          onChange={(event) => {
                                            handleChange({
                                              target: {
                                                name: "sms_reminder_status",
                                                value: event.target.checked
                                                  ? "E"
                                                  : "D",
                                              },
                                            });
                                          }}
                                        />
                                        <label className="form-check-label cus_label" htmlFor="smsReminders">
                                          SMS Reminders
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="toggle_field big">
                                    <div className="box_content w-100 p-0">
                                      <div className="form-check form-switch mw-100">
                                        <input className="form-check-input" type="checkbox" id="emailFollowUp" name="email_followup_status"
                                          value={values.email_followup_status}
                                          checked={
                                            values.email_followup_status ===
                                            "E"
                                          }
                                          onChange={(event) => {
                                            handleChange({
                                              target: {
                                                name: "email_followup_status",
                                                value: event.target.checked
                                                  ? "E"
                                                  : "D",
                                              },
                                            });
                                          }}
                                        />
                                        <label className="form-check-label cus_label" htmlFor="emailFollowUp">
                                          Email Follow-up
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6" lg="6">
                                <FormGroup
                                  tag="fieldset"
                                  className="event-form-group "
                                >
                                  <label>Collect Customer Feedback</label>
                                  <Row>
                                    <Col md="6" lg="6">
                                      <div className="event-form-check">
                                        <input
                                          type="radio"
                                          id="custom-feedback-yes"
                                          name="customer_feedback_status"
                                          checked={
                                            values.customer_feedback_status ==
                                            "E"
                                          }
                                          value="E"
                                          onChange={({ target }) => {
                                            const { name, value } =
                                              target || {};
                                            handleChange({
                                              target: {
                                                name,
                                                value,
                                              },
                                            });
                                          }}
                                        ></input>
                                        <label htmlFor="custom-feedback-yes">
                                          <span></span>
                                          Yes
                                        </label>
                                      </div>
                                    </Col>
                                    <Col md="6" lg="6">
                                      <div className="event-form-check">
                                        <input
                                          type="radio"
                                          id="custom-feedback-no"
                                          name="customer_feedback_status"
                                          checked={
                                            values.customer_feedback_status ==
                                            "D"
                                          }
                                          value="D"
                                          onChange={({ target }) => {
                                            const { name, value } =
                                              target || {};
                                            handleChange({
                                              target: {
                                                name,
                                                value,
                                              },
                                            });
                                          }}
                                        ></input>
                                        <label htmlFor="custom-feedback-no">
                                          <span></span>
                                          No
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="event-card-footer">
                          <Row>
                            <Col md="3" lg="3">
                              <Button
                                className="btn btn-danger"
                                type="button"
                                disabled={isLoading}
                                onClick={this.onCancel}
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col md="3" lg="3" className="mt-3 mt-md-0">
                              <Button
                                className="btn btn-outline"
                                type="button"
                                disabled={isLoading}
                                onClick={this.onSaveAsDraft}
                              >
                                Save as Draft
                              </Button>
                            </Col>

                            <Col md="3" lg="3" className="mt-3 mt-md-0">
                              <Button
                                className="btn btn-success"
                                type="button"
                                disabled={isLoading}
                                onClick={this.onGoBack}
                              >
                                Back
                              </Button>
                            </Col>

                            <Col md="3" lg="3" className="mt-3 mt-md-0">
                              <Button
                                type="submit"
                                className="btn btn-app"
                                disabled={isLoading}
                              >
                                Finish
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Col>
            <Col md="3" lg="3">
              <SidebarProgress props={this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  saveEventAdvancedPage,
  getEventAdvancedPage,
})(withRouter(AddNewEventAdvanced));
