import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";

class DisconnectModal extends Component {
  render() {
    const { recordId, showDisconnectModal, hideDisconnectModal, disconnect } =
      this.props;
    return (
      <>
        <Modal isOpen={showDisconnectModal} toggle={hideDisconnectModal}>
          <ModalBody>
            <div className="">
              <div className="rd_modalvieew">
                <div className="box_content rd_modalboxcontent">
                  <div className="rd_inputselectheadermodalsetting">
                    <button
                      className="rd_colsebtn"
                      onClick={hideDisconnectModal}
                    ></button>
                  </div>

                  <h5 className="rd_modalheadertext rd_modalheadertextnm">
                    Confirm
                  </h5>

                  <p className="rd_modalheadertextparath mt-5 mb-5">
                    Are you sure, you want to disconnect ?
                  </p>

                  <div className="rd_cancelappbtnmodal">
                    <button className="rd_cancel" onClick={hideDisconnectModal}>
                      Cancel
                    </button>
                    <button
                      className="btn btn-app"
                      onClick={() => {
                        hideDisconnectModal();
                        disconnect(recordId);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default DisconnectModal;
