import {
  SET_USER_AND_PLAN_DETAILS,
  SET_SUBSCRIPTION_STAFF,
  SET_PLAN_PERIOD_TYPE,
  SET_SUBSCRIPTION_PLANS,
  SET_SUBSCRIPTION_USER_PAGINATION,
} from "../actions/types";
const initialState = {
  plans: [],
  userPlan: {
    plan_id: "",
    plan_period_type: "",
    plan_type: "",
    is_paid_subscription: "D",
    monthly_price: 0,
  },
  admin: {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    phone: "",
  },
  pagination: {
    pageNumber: 1,
    pageSize: 12,
  },
  staffs: [],
  totalStaffs: 0,
  planType: "individual",
  planPeriodType: "monthly",
  selectedPlan: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_AND_PLAN_DETAILS:
    case SET_SUBSCRIPTION_STAFF:
      return {
        ...state,
        ...action.payload,
      };

    case SET_PLAN_PERIOD_TYPE:
      return {
        ...state,
        planPeriodType: action.payload,
      };

    case SET_SUBSCRIPTION_PLANS:
      return {
        ...state,
        plans: action.payload,
      };

    case SET_SUBSCRIPTION_USER_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };

    default:
      return state;
  }
};
