import React, { Component } from "react";
import { connect } from "react-redux";
import { getCompanyEvents } from "../../actions/bookingAction";
import { notification, IMAGE_BASE_URL } from "../../utils/utility";
import "../../assets/bookingeventassets/paidcss/logosidebar.css";
import logo from "../../assets/bookingeventassets/assets/logo.svg";
import SideLogo from "../../assets/bookingeventassets/assets/sidelogo.svg";
import side_bar_foot_logo from "../../assets/bookingeventassets/assets/side_bar_foot_logo.svg";

import Share from "../../assets/icons/share.svg";
import One from "../../assets/bookingeventassets/assets/1.svg";
import Two from "../../assets/bookingeventassets/assets/2.svg";
import P from "../../assets/bookingeventassets/assets/p.svg";
import C from "../../assets/bookingeventassets/assets/c.svg";
import { withRouter } from "../../utils/utility";
import ZoomIcon from "../../assets/icons/zoom.svg";
import MeetIcon from "../../assets/icons/google-meet.svg";
import TeamsIcon from "../../assets/icons/Microsoft_Teams.svg";
import GoToIcon from "../../assets/icons/goto_icon.svg";
import PhoneCall from "../../assets/icons/phone_call.svg";
import InPersonMeet from "../../assets/icons/in_person_meet.svg";
import OtherMeet from "../../assets/icons/other_meet_icon.svg";
import isEmpty from "../../validation/is-empty";
import CompanyEventMeetoctoImage from "./company-event-meetocto-image";

import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom";

class CompanyPage extends Component {
  state = {
    company: {},
    events: [],
    specialRibbon: null,
    isLoading: false,
  };
  /**
   * execute when component inserted in dom
   */
  componentDidMount() {
    const _this = this;
    const { router, getCompanyEvents } = this.props || {};
    const { params } = router || {};
    const { slug } = params || {};

    getCompanyEvents({
      data: {
        slug,
      },
      onSuccess: function (response) {
        console.log("response", response);
        _this.setState({
          company: response.company,
          events: response.events,
          specialRibbon: !isEmpty(response.special_ribbon)
            ? response.special_ribbon.special_announcement
            : null,
        });
      },
      onError: (error) => {
        notification.error(error.response.data.message);
      },
    });
  }

  /**
   * on booknow button handler
   * @param string eventURL
   */
  onBookNow = (eventURL) => {
    const { router } = this.props;
    const { navigate } = router || {};
    navigate(`/booking/${eventURL}`);
  };
  render() {
    const { company, events, specialRibbon } = this.state;
    return (
      <>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-sm justify-content-center top_nav shadow">
            <Link className="navbar-brand" to="/admin/dashboard">
              <img src={logo} alt="Logo" style={{ width: "100%" }} />
            </Link>
          </nav>
        </div>
        <div className="main_div booking_main_row">
          <div className="left_div">
            <nav className="navbar side_navbar">
              <ul className="navbar-nav side_bar_ul">
                <li className="nav-item comp_logo">
                  <img src={SideLogo} alt="company logo" />
                </li>
                <li className="nav-item">
                  <h2>{company.company_name}</h2>
                  <p>{company.company_description}</p>
                </li>

                <li className="nav-item">
                  <h2>Contact Details</h2>
                  <p className="sub_head">
                    <span>Email:</span> <br />
                    {company.company_email}
                  </p>
                  <p className="sub_head">
                    <span>Phone No:</span> <br />
                    {company.company_phone_no}
                  </p>
                  <p className="sub_head">
                    <span>Address</span> <br />
                    {company.company_address}
                  </p>

                  <div className="row side_bar_foot_img_div">
                    <div className="col-12 side_bar_foot_logo_img">
                      <img src={side_bar_foot_logo} />
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>

          <div className="right_div">
            <div className="row">
              {!isEmpty(specialRibbon) && (
                <div className="col-12">
                  <nav className="navbar navbar-expand-sm main_noti shadow">
                    <ul className="navbar-nav">
                      <li className="nav-item noti_side">
                        <img src={One} />
                      </li>

                      <li className="nav-item left_noti ml-3 ml-md-0">
                        <span>{specialRibbon}</span>
                      </li>
                    </ul>
                  </nav>
                </div>
              )}
              {/* <div className="col-12">
                <nav className="navbar navbar-expand-sm main_noti shadow">
                  <ul className="navbar-nav">
                    <li className="nav-item noti_side">
                      <img src={One} alt="info icon" />
                    </li>

                    <li className="nav-item left_noti">
                      <span>
                        This is a warning alert — check it out! Lorem Ipsum has
                        been the industry’s standard dummy text ever since the
                        1500s
                      </span>
                    </li>
                  </ul>
                </nav>
              </div> */}
              <div className="col-12">
                <h1>All Events</h1>
              </div>
            </div>

            <div className="row sub_div">
              {events.length > 0
                ? events.map((item, index) => (
                    <div
                      className="col-lg-6 col-xl-4 main_add eve_card"
                      key={item.id}
                    >
                      <div className="img">
                        {item.event_image ? (
                          <>
                            <img
                              src={
                                item.event_image
                                  ? IMAGE_BASE_URL + item.event_image
                                  : Two
                              }
                              alt="event img"
                            />
                            <div className="eve_name">{item.event_title}</div>{" "}
                          </>
                        ) : (
                          <>
                            <CompanyEventMeetoctoImage
                              imageId={Math.floor(
                                Math.random() * (3 - 1 + 1) + 1
                              )}
                              eventTitle={item.event_title}
                            />
                          </>
                        )}
                      </div>
                      <div className="body">
                        <div className="eve_title">
                          <h3>{item.event_title}</h3>
                          {item.is_event_paid === "E" ? (
                            <div className="eve_status">
                              {"$" + item.event_fee}
                            </div>
                          ) : (
                            <div className="eve_status">Free</div>
                          )}
                        </div>
                        <div className="eve_desc">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry Lorem Ipsum is simply dummy
                            text of the printing and typesetting industry.
                          </p>
                        </div>
                        <div className="eve_info row justify-content-between">
                          <div className="col-12 format">
                            <div className="d-flex align-items-center">
                              <div className="sechulde_s">Event format:</div>
                              <div className="sechulde_d">
                                {item.event_format}
                              </div>
                            </div>
                          </div>
                          <div className="col-auto d-flex">
                            <div className="sechulde_s">
                              {item.venue === "Microsoft Teams" ? (
                                <img
                                  src={TeamsIcon}
                                  alt="microsoft teams icon"
                                />
                              ) : item.venue === "Zoom" ? (
                                <img src={ZoomIcon} alt="Zoom icon" />
                              ) : item.venue === "Google Meet" ? (
                                <img src={MeetIcon} alt="Google Meet icon" />
                              ) : item.venue === "Phone Call" ? (
                                <img src={PhoneCall} alt="Phone Call icon" />
                              ) : item.venue === "In-Person Meeting" ? (
                                <img
                                  src={InPersonMeet}
                                  alt="In Person Meet icon"
                                  style={{ maxWidth: 13 }}
                                />
                              ) : item.venue === "Others" ? (
                                <img
                                  src={OtherMeet}
                                  alt="microsoft teams icon"
                                  style={{ maxWidth: 35 }}
                                />
                              ) : item.venue === "Go to Meeting" ? (
                                <img
                                  src={GoToIcon}
                                  alt="Go To icon"
                                  style={{ maxWidth: 28 }}
                                />
                              ) : (
                                <img src={P} alt="event_img" />
                              )}
                            </div>
                            <div className="sechulde_d">{item.venue}</div>
                          </div>
                          <div className="col-auto d-flex">
                            <div className="sechulde_s">
                              <img src={C} />
                            </div>
                            <div className="sechulde_d">{item.timeslot}</div>
                          </div>
                        </div>
                        <div className="col-12 act_btns">
                          <div className="row justify-content-between">
                            <div className="col-7 p-0">
                              <button
                                type="button"
                                className="btn btn-app"
                                onClick={() => this.onBookNow(item.event_url)}
                              >
                                Book Now
                              </button>
                            </div>
                            <div className="col-5 p-0">
                              <button type="button" className="btn btn-grey">
                                <img src={Share} alt="share icon" />
                                <span>Share</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : []}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, {
  getCompanyEvents,
})(withRouter(CompanyPage));
