import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
import Loader from "../../../components/Loader/Loader";
import currentStep from "../../../assets/images/current-step.png";
import unSelectedRadio from "../../../assets/images/unselected-radio.png";
import fillStep from "../../../assets/images/fill.png";
import clockIcon from "../../../assets/icons/clcok-icon.svg";
import { Row, Col, Label, Button } from "reactstrap";
import clsx from "clsx";
import axiosInstance from "../../../axios-instance";
import { useNavigate } from "react-router-dom";
import {
  getStepTwoData,
  saveStepTwoData,
} from "../../../actions/admin/quick-setup-actions";
import { connect } from "react-redux";
import isEmpty from "../../../validation/is-empty";
import { notification, COMPANY_BASE_URL } from "../../../utils/utility";
import {
  getAdminBusinessId,
  getLoggedInUserId,
} from "../../../utils/authHelper";

const validation = Yup.object().shape({
  url_slug: Yup.string().required("URL is required"),
  timezone_id: Yup.string().required("Timezone Cannot be empty"),
});

let initialValues = {
  url_slug: "",
  timezone_id: "",
  timeFrom: "",
  timeTo: "",
  time_format: "24",
  calendar_first_day: 7,
  start_time: "",
  end_time: "",
  week_days: [],
};

const QuickSetupStepTwo = (props) => {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [isScheduleExist, setIsScheduleExist] = useState(false);

  const onChangeTimeFormat = (format, setFieldValue) => {
    setFieldValue("time_format", format);
  };

  const onChangeFirstCalenderDay = (day, setFieldValue) => {
    setFieldValue("calendar_first_day", day);
  };

  const onChangeWeekDays = (value, values, setFieldValue) => {
    const newValues = [...values];
    if (newValues.includes(value)) {
      newValues.splice(newValues.indexOf(value), 1);
    } else {
      newValues.push(value);
    }

    setFieldValue("week_days", newValues);
  };

  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    setPageLoading(true);

    axiosInstance
      .get("/timezones")
      .then((response) => {
        setTimezones(response.data.data.timezones);
      })
      .catch((error) => {
        console.error(error);
      });

    const { getStepTwoData } = props;

    getStepTwoData({
      onSuccess: function (response) {
        const { data } = response;
        if (!isEmpty(data)) {
          setFormValues({
            ...initialValues,
            url_slug: data.url_slug,
            timezone_id: data.timezone_id,
            time_format: data.time_format,
            calendar_first_day: parseInt(data.calendar_first_day),
          });
          setIsScheduleExist(data.is_schedule_exist === "E");
        }

        setPageLoading(false);
      },
      onError: function (error) {
        setPageLoading(false);
      },
    });
  }, []);

  /**
   * On Go back event
   */
  const onGoBack = () => {
    navigate(-1);
  };

  return (
    <section>
      <Loader isShowLoader={pageLoading} />

      <div className="profile-step w-100 mt-0 mb-0 pt-3 profile-step-2">
        <div className="step-1 w-100">
          <div
            className="by-step pt-1"
            style={{ backgroundPositionY: "16px", backgroundSize: "95%" }}
          >
            <div className="step">
              <img src={fillStep} alt="" />
              <p>Step 1</p>
            </div>
            <div className="step">
              <img src={currentStep} alt="" />
              <p className="current-step">Step 2</p>
            </div>
            <div className="step">
              <img src={unSelectedRadio} alt="" />
              <p>Step 3</p>
            </div>
          </div>
        </div>
        {/* </div>
      <div className="rd_vacationfilterpart rd_vacationfilterpart3"> */}
        <Formik
          validationSchema={validation}
          initialValues={formValues || initialValues}
          enableReinitialize
          onSubmit={(data) => {
            setPageLoading(true);

            let formData = {
              ...data,
              business_id: getAdminBusinessId(),
              provider_id: getLoggedInUserId(),
            };

            if (isScheduleExist) {
              formData.is_schedule_exist = true;
            } else {
              formData.schedule_details = [];
              data.week_days.forEach((day) => {
                formData.schedule_details.push({
                  weekday_id: day,
                  type: "weekly",
                  on_status: "E",
                  schedule_availability: [
                    {
                      start_time: data.start_time,
                      end_time: data.end_time,
                    },
                  ],
                });
              });
            }

            const { saveStepTwoData } = props;

            saveStepTwoData({
              data: formData,
              onSuccess: (response) => {
                if (response.success) {
                  notification.success("Record saved successfully");
                }
                setPageLoading(false);
                navigate("/admin/quick-setup-step-three");
              },
              onError: (error) => {
                notification.error(
                  "An error occured during save, Please try later"
                );
                setPageLoading(false);
                console.log("company error ", error);
              },
            });
          }}
        >
          {(formProps) => {
            const {
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
            } = formProps;

            return (
              <>
                <Form>
                  <Row className="p-4">
                    <Col md={12} lg={12}>
                      <div className="meetocto-link">
                        <div className="form-group">
                          <Label className="same-label" htmlFor="url_slug">
                            MeetOcto Link *
                          </Label>
                          <div className="meetocto-link-input">
                            <button className="btn">{COMPANY_BASE_URL}</button>
                            <input
                              type="text"
                              className="form-control same-input"
                              name="url_slug"
                              id="url_slug"
                              value={values.url_slug}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          <ErrorMessage
                            name="url_slug"
                            render={(error) => (
                              <span className="error">{error}</span>
                            )}
                          />
                        </div>
                      </div>
                    </Col>

                    <div className="form-group col-md-6">
                      <label className="same-label" htmlFor="timezone_id">
                        Timezone *
                      </label>
                      <select
                        name="timezone_id"
                        id="timezone_id"
                        className="form-select timezone"
                        value={values.timezone_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Timezone</option>
                        {timezones.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        name="timezone_id"
                        render={(error) => (
                          <span className="error">{error}</span>
                        )}
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label className="same-label">Time Format</label>
                      <div className="time-format-btns">
                        <button
                          type="button"
                          className={clsx("btn p-0", {
                            activeBtn: values.time_format === "24",
                          })}
                          onClick={() =>
                            onChangeTimeFormat("24", setFieldValue)
                          }
                        >
                          24 Hours
                        </button>
                        <button
                          type="button"
                          className={clsx("btn p-0", {
                            activeBtn: values.time_format === "12",
                          })}
                          onClick={() =>
                            onChangeTimeFormat("12", setFieldValue)
                          }
                        >
                          12 Hours
                        </button>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="same-label">Week Start From</label>
                      <div className="week-btns">
                        <button
                          type="button"
                          className={clsx("btn p-0", {
                            activeBtn: values.calendar_first_day === 7,
                          })}
                          onClick={() =>
                            onChangeFirstCalenderDay(7, setFieldValue)
                          }
                        >
                          S
                        </button>
                        <button
                          type="button"
                          className={clsx("btn p-0", {
                            activeBtn: values.calendar_first_day === 1,
                          })}
                          onClick={() =>
                            onChangeFirstCalenderDay(1, setFieldValue)
                          }
                        >
                          M
                        </button>
                        <button
                          type="button"
                          className={clsx("btn p-0", {
                            activeBtn: values.calendar_first_day === 2,
                          })}
                          onClick={() =>
                            onChangeFirstCalenderDay(2, setFieldValue)
                          }
                        >
                          T
                        </button>
                        <button
                          type="button"
                          className={clsx("btn p-0", {
                            activeBtn: values.calendar_first_day === 3,
                          })}
                          onClick={() =>
                            onChangeFirstCalenderDay(3, setFieldValue)
                          }
                        >
                          W
                        </button>
                        <button
                          type="button"
                          className={clsx("btn p-0", {
                            activeBtn: values.calendar_first_day === 4,
                          })}
                          onClick={() =>
                            onChangeFirstCalenderDay(4, setFieldValue)
                          }
                        >
                          T
                        </button>
                        <button
                          type="button"
                          className={clsx("btn p-0", {
                            activeBtn: values.calendar_first_day === 5,
                          })}
                          onClick={() =>
                            onChangeFirstCalenderDay(5, setFieldValue)
                          }
                        >
                          F
                        </button>
                        <button
                          type="button"
                          className={clsx("btn p-0", {
                            activeBtn: values.calendar_first_day === 6,
                          })}
                          onClick={() =>
                            onChangeFirstCalenderDay(6, setFieldValue)
                          }
                        >
                          S
                        </button>
                      </div>
                    </div>

                    {!isScheduleExist && (
                      <>
                        <div className="form-groups row">
                          <div className="form-group col-md-6">
                            <label className="same-label" htmlFor="timeFrom">
                              Business Hours
                            </label>
                            <div className="business-hours-input">
                              <input
                                type="time"
                                className="form-control same-input"
                                name="start_time"
                                id="timeFrom"
                                value={getIn(values, "start_time")}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <button type="button" className="">
                                <img src={clockIcon} alt="" />
                              </button>
                            </div>
                            <ErrorMessage
                              name="start_time"
                              render={(error) => (
                                <span className="error">{error}</span>
                              )}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label className="same-label" htmlFor="timeTo">
                              To
                            </label>
                            <div className="business-hours-input">
                              <input
                                type="time"
                                className="form-control same-input"
                                name="end_time"
                                id="timeTo"
                                value={getIn(values, "end_time")}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <button type="button" className="">
                                <img src={clockIcon} alt="" />
                              </button>
                            </div>
                            <ErrorMessage
                              name="end_time"
                              render={(error) => (
                                <span className="error">{error}</span>
                              )}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="same-label">Availability</label>
                          <div className="availability-btns">
                            <button
                              type="button"
                              className={clsx("btn p-0", {
                                activeBtn: values.week_days.includes(7),
                              })}
                              onClick={() =>
                                onChangeWeekDays(
                                  7,
                                  values.week_days,
                                  setFieldValue
                                )
                              }
                            >
                              S
                            </button>
                            <button
                              type="button"
                              className={clsx("btn p-0", {
                                activeBtn: values.week_days.includes(1),
                              })}
                              onClick={() =>
                                onChangeWeekDays(
                                  1,
                                  values.week_days,
                                  setFieldValue
                                )
                              }
                            >
                              M
                            </button>
                            <button
                              type="button"
                              className={clsx("btn p-0", {
                                activeBtn: values.week_days.includes(2),
                              })}
                              onClick={() =>
                                onChangeWeekDays(
                                  2,
                                  values.week_days,
                                  setFieldValue
                                )
                              }
                            >
                              T
                            </button>
                            <button
                              type="button"
                              className={clsx("btn p-0", {
                                activeBtn: values.week_days.includes(3),
                              })}
                              onClick={() =>
                                onChangeWeekDays(
                                  3,
                                  values.week_days,
                                  setFieldValue
                                )
                              }
                            >
                              W
                            </button>
                            <button
                              type="button"
                              className={clsx("btn p-0", {
                                activeBtn: values.week_days.includes(4),
                              })}
                              onClick={() =>
                                onChangeWeekDays(
                                  4,
                                  values.week_days,
                                  setFieldValue
                                )
                              }
                            >
                              T
                            </button>
                            <button
                              type="button"
                              className={clsx("btn p-0", {
                                activeBtn: values.week_days.includes(5),
                              })}
                              onClick={() =>
                                onChangeWeekDays(
                                  5,
                                  values.week_days,
                                  setFieldValue
                                )
                              }
                            >
                              F
                            </button>
                            <button
                              type="button"
                              className={clsx("btn p-0", {
                                activeBtn: values.week_days.includes(6),
                              })}
                              onClick={() =>
                                onChangeWeekDays(
                                  6,
                                  values.week_days,
                                  setFieldValue
                                )
                              }
                            >
                              S
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </Row>

                  <Row className="p-4">
                    <Col md={6} lg={6}>
                      &nbsp;
                    </Col>

                    <Col md="3" lg="3">
                      <Button
                        className="btn btn-outline"
                        type="button"
                        disabled={pageLoading}
                        onClick={onGoBack}
                      >
                        Back
                      </Button>
                    </Col>

                    <Col md="3" lg="3" className="mt-4 mt-md-0">
                      <Button
                        type="submit"
                        className="btn btn-app"
                        disabled={pageLoading}
                      >
                        Next Step
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

export default connect(null, {
  getStepTwoData,
  saveStepTwoData,
})(QuickSetupStepTwo);
