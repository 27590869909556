import React, { Component } from "react";
import { TabContent, TabPane, Row, Col } from "reactstrap";
import ListView from "./ListView";
import CalendarView from "./CalendarView";
import { getTimezones } from "../../../../../actions/companyActions";
import { connect } from "react-redux";
import FormField from "../../../../../components/FormField/FormField";
import AvailabilityTabs from "./AvailabilityTabs";

class Availability extends Component {
  state = {
    events: [],
    activeTab: "1",
    showModal: false,
    timezones: [],
    timezoneId: "",
  };

  componentDidMount() {
    const _this = this;
    const { getTimezones } = _this.props;
    getTimezones({
      data: {},
      onSuccess: function (data) {
        _this.setState({
          timezones: data.timezones,
        });
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  }

  /**
   * toggle tab
   * @param {*} tab
   */
  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      this.props.onToggleActiveTab(tab);
    }
  };

  render() {
    const { activeTab, timezones } = this.state;
    const { schedules, setSchedules, errors, touched } = this.props || {};

    return (
      <>
        <div className="tabs-wrapper event-availbility">
          <div className="tabs-container">
            <Row>
              <Col md="4" lg="4">
                <div className="form-group event-form-group">
                  <FormField
                    showLabel
                    placeholder="Select Timezone"
                    showPlaceholder={true}
                    type="select"
                    name="timezone_id"
                    label="Select Timezone"
                    errors={[]}
                    touched={[]}
                    options={timezones}
                    value={this.props.timezoneId}
                    onChange={({ target }) => {
                      const { value } = target;
                      setSchedules(schedules, value);
                      this.setState({
                        timezoneId: value,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col md="8" lg="8">
                <AvailabilityTabs activeTab={activeTab} toggle={this.toggle} />
                {/* <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <span className="icon">
                        <Icon.List size="20" />
                      </span>
                      List View
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      <span className="icon">
                        <Icon.Calendar size="20" />
                      </span>
                      Calendar View
                    </NavLink>
                  </NavItem>
                </Nav> */}
              </Col>
            </Row>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <ListView
                  eventSchedules={schedules}
                  timezoneId={this.state.timezoneId}
                  setEventSchedules={setSchedules}
                  errors={[]}
                  touched={[]}
                />
              </TabPane>
              <TabPane tabId="2">
                <CalendarView
                  eventSchedules={schedules}
                  timezoneId={this.state.timezoneId}
                  setEventSchedules={setSchedules}
                  errors={[]}
                  touched={[]}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, {
  getTimezones,
})(Availability);
