import React, { useEffect, useState, useRef } from "react";
import Loader from "../../../components/Loader/Loader";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "../../../components/FormField/FormField";
import { FormGroup, Input, Col, Button, Row, FormFeedback } from "reactstrap";
import { getCompanySettingServices } from "../../../actions/companyActions";
import { connect } from "react-redux";
import { Select } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ImageCropper from "../../../components/ImageCropper/index";

import {
  getUserProfileData,
  updateUserProfileData,
} from "../../../actions/myProfileActions";
import {
  notification,
  emailRegex,
  IMAGE_BASE_URL,
} from "../../../utils/utility";

const { Option } = Select;

const validation = Yup.object().shape({
  first_name: Yup.string().required("First name is required."),
  last_name: Yup.string().required("Last name is required."),
  phone: Yup.string().required("Phone is required."),
  email: Yup.string()
    .matches(emailRegex, "Please enter valid email")
    .required("Email is required"),
  timezone_id: Yup.string().required("Timezone is required."),
});

let initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  address_line_1: "",
  city: "",
  state: "",
  zip: "",
  country_id: "",
  timezone_id: "",
  image_cropped_x: "",
  image_cropped_y: "",
  image_cropped_width: "",
  image_cropped_height: "",
  image_cropped_unit: "",
};

const ProfileInfo = (props) => {
  const [formValues, setFormValues] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [timezones, setTimezones] = useState([]);

  const [profileImageUrl, setProfileImageUrl] = useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [isImageChangedEventCalled, setIsImageChangedEventCalled] =
    useState(false);

  const croppedImage = useRef(null);

  useEffect(() => {
    const { getCompanySettingServices, getUserProfileData } = props;

    setPageLoading(true);

    getUserProfileData({
      data: {},
      onSuccess: function (resData) {
        setFormValues({
          id: resData.id,
          first_name: resData.first_name,
          last_name: resData.last_name,
          email: resData.email,
          phone: resData.phone ? resData.phone : "",
          address_line_1: resData.address_line_1,
          city: resData.city,
          state: resData.state,
          zip: resData.zip,
          country_id: resData.country
            ? resData.country.value
              ? resData.country.value
              : ""
            : "",
          timezone_id: resData.timezone_id ? resData.timezone_id : "",
          image_cropped_x: resData.image_cropped_x,
          image_cropped_y: resData.image_cropped_y,
          image_cropped_width: resData.image_cropped_width,
          image_cropped_height: resData.image_cropped_height,
          image_cropped_unit: resData.image_cropped_unit,
        });

        setProfileImageUrl(resData.profile_image);
        setPageLoading(false);
      },
      onError: function (error) {
        setPageLoading(false);
      },
    });

    getCompanySettingServices({
      data: {},
      onSuccess: function (data) {
        setPageLoading(false);
        setCountries(data.countries);
        setTimezones(data.timezones);
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectFile = (e) => {
    setIsImageChangedEventCalled(true);

    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    console.log(
      "aaa",
      e.target.files[0],
      URL.createObjectURL(e.target.files[0])
    );

    // I've kept this example simple by using the first image instead of multiple
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setProfileImageUrl(objectUrl);
  };

  return (
    <section>
      <Loader isShowLoader={pageLoading} />
      <div>
        <div className="rd_vacationfilterpart rd_vacationfilterpart3">
          <Formik
            validationSchema={validation}
            initialValues={formValues || initialValues}
            enableReinitialize
            onSubmit={(data) => {
              const { updateUserProfileData } = props;

              setPageLoading(true);

              updateUserProfileData({
                data: {
                  ...data,
                  profile_image: selectedFile,
                  cropped_image: croppedImage.current,
                },
                onSuccess: (response) => {
                  if (response.success) {
                    notification.success(response.message);
                  }

                  // update timezone in localstorage
                  const timezoneId = localStorage.getItem("userTimezoneId");
                  // console.log(
                  //   "storage timezone id",
                  //   timezoneId,
                  //   "form tz id",
                  //   data.timezone_id
                  // );
                  if (timezoneId != data.timezone_id) {
                    const timezone = timezones.find(
                      (tz) => parseInt(tz.value) === parseInt(data.timezone_id)
                    );
                    // console.log("timzone", timezone);
                    if (timezone) {
                      localStorage.setItem(
                        "userTimezone",
                        timezone.timezone_value
                      );
                      localStorage.setItem("userTimezoneId", timezone.value);
                    }
                  }

                  setPageLoading(false);
                },
                onError: (error) => {
                  notification.error(
                    "An error occured during save, Please try later"
                  );
                  setPageLoading(false);
                  console.log("company error ", error);
                },
              });
              console.log("data", {
                ...data,
                profile_image: selectedFile,
              });
              console.log(selectedFile);
            }}
          >
            {(formProps) => {
              const {
                values,
                errors,
                touched,
                handleChange,
                setFieldTouched,
                setFieldValue,
              } = formProps;

              return (
                <>
                  <Form>
                    <div className="rd_profileimagepart pt-3">
                      <div className="rd_imagethincontg">
                        <p>Profile Image</p>
                        <div className="rd_imagecontprofile">
                          {/* {profileImageUrl ? (
                            <ImageCropper
                              imgSrc={
                                !isImageChangedEventCalled
                                  ? IMAGE_BASE_URL + profileImageUrl
                                  : profileImageUrl
                              }
                            />
                          ) : null} */}
                          {/* {profileImageUrl ? (
                            <img
                              src={
                                !isImageChangedEventCalled
                                  ? IMAGE_BASE_URL + profileImageUrl
                                  : profileImageUrl
                              }
                              alt=""
                            />
                          ) : null} */}
                        </div>
                      </div>
                      <div className="rd_imagethincontgbtnt">
                        <p>Allowed JPG, GIF or PNG. Max size of 800kB</p>
                        <FormGroup className="has-icon-left position-relative">
                          <label className="btn btn-outline" outline>
                            Upload Photo
                            <Input
                              type="file"
                              accept=".png,.jpg"
                              onChange={onSelectFile}
                              name="profile_image"
                              id="uploadImg"
                              hidden
                            />
                          </label>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="rd_croperthing">
                      <p>Preview Shows Here</p>
                      {profileImageUrl ? (
                        <ImageCropper
                          imgSrc={
                            !isImageChangedEventCalled
                              ? IMAGE_BASE_URL + profileImageUrl
                              : profileImageUrl
                          }
                          data={{
                            x: values.image_cropped_x,
                            y: values.image_cropped_y,
                            width: values.image_cropped_width,
                            height: values.image_cropped_height,
                            unit: values.image_cropped_unit,
                          }}
                          onChange={(crop, cImage) => {
                            console.log("cIMage", cImage);
                            croppedImage.current = cImage;
                            setFieldValue("image_cropped_x", parseInt(crop.x));
                            setFieldValue("image_cropped_y", parseInt(crop.y));
                            setFieldValue(
                              "image_cropped_width",
                              parseInt(crop.width)
                            );
                            setFieldValue(
                              "image_cropped_height",
                              parseInt(crop.height)
                            );
                            setFieldValue("image_cropped_unit", crop.unit);
                            //console.log("crop", crop);
                          }}
                        />
                      ) : null}
                    </div>
                    <div className="rd_profilerd_erpart">
                      <div className="rd_vacationflex2">
                        <p>First Name *</p>
                        <div className="rd_profilethingco">
                          <FormField
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            value={values.first_name}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "first_name", value },
                              });
                            }}
                          />
                        </div>

                        <p>Email *</p>
                        <div className="rd_profilethingco">
                          <FormField
                            type="text"
                            name="email"
                            placeholder="Email"
                            value={values.email}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "email", value },
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="rd_vacationflex2">
                        <p>Last Name *</p>
                        <div className="rd_profilethingco">
                          <FormField
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            value={values.last_name}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "last_name", value },
                              });
                            }}
                          />
                        </div>

                        <p>Phone Number *</p>
                        <div className="rd_profilethingco">
                          <PhoneInput
                            containerClass="custom_phn_inp"
                            country={"us"}
                            value={values.phone}
                            onBlur={() => {
                              setFieldTouched(`phone`, true);
                            }}
                            onChange={(value) => {
                              handleChange({
                                target: { name: "phone", value },
                              });
                            }}
                          />
                          {errors[`phone`] && touched[`phone`] && (
                            <FormFeedback className="d-block">
                              {errors[`phone`]}
                            </FormFeedback>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="rd_profilerd_erpart rd_profilerd_erpart2">
                      <div className="rd_vacationflex2">
                        <p>Address Line 1</p>

                        <div className="rd_profilethingco">
                          <Input
                            type="textarea"
                            rows="6"
                            name="address_line_1"
                            placeholder="Address"
                            value={values.address_line_1}
                            onChange={({ target }) => {
                              const { value } = target || {};
                              handleChange({
                                target: { name: "address_line_1", value },
                              });
                            }}
                          />
                          <p className="info_txt">
                            Street address, P.O. box, Company name C/o
                          </p>
                        </div>
                      </div>
                      <div className="rd_vacationflex2">
                        <p>Address Line 2</p>

                        <div className="rd_profilethingco">
                          <Input
                            type="textarea"
                            rows="6"
                            name="address_line_1"
                            placeholder="Address"
                            value={values.address_line_1}
                            // onChange={({ target }) => {
                            //   const { value } = target || {};
                            //   handleChange({
                            //     target: { name: "address_line_1", value },
                            //   });
                            // }}
                          />
                          <p className="info_txt">
                            Apartment, suite, unit, building, floor, etc
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="rd_profilerd_erpart rd_profilerd_erpart2">
                      <div className="rd_vacationflex2">
                        <p>City</p>
                        <div className="rd_profilethingco">
                          <FormField
                            type="text"
                            name="city"
                            placeholder="City"
                            value={values.city}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "city", value },
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="rd_vacationflex2">
                        <p>State/Province/Region</p>
                        <div className="rd_profilethingco">
                          <FormField
                            type="text"
                            name="state"
                            placeholder="State"
                            value={values.state}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "state", value },
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="rd_vacationflex2">
                        <p>Zip/Postal code</p>
                        <div className="rd_profilethingco">
                          <FormField
                            type="text"
                            name="zip"
                            placeholder="Zip Code"
                            value={values.zip}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "zip", value },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="rd_profilerd_erpart rd_profilerd_erpart2">
                      <div className="rd_vacationflex2">
                        <p>Country</p>
                        <div className="rd_profilethingco">
                          <Select
                            showSearch
                            size="large"
                            allowClear={true}
                            className="w-100"
                            placeholder="Please select Country"
                            value={values.country_id}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                              handleChange({
                                target: { name: "country_id", value },
                              });
                            }}
                          >
                            {(countries || []).map((country, index) => {
                              return (
                                <Option
                                  key={country.value}
                                  value={country.value}
                                >
                                  {country.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>

                      <div className="rd_vacationflex2">
                        <p>Timezone *</p>
                        <div className="rd_profilethingco">
                          <div className="">
                            <FormField
                              placeholder="Select Timezone"
                              showPlaceholder={true}
                              type="select"
                              name="timezone_id"
                              errors={errors}
                              touched={touched}
                              value={values.timezone_id.toString()}
                              options={timezones}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <Col lg="12" md="12" sm="12" className="submit_btn">
                      <Button
                        type="submit"
                        className="btn btn-app col-md-2 col-lg-2 col-sm-12 mb-5"
                      >
                        Save
                      </Button>
                    </Col>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default connect(null, {
  getCompanySettingServices,
  getUserProfileData,
  updateUserProfileData,
})(ProfileInfo);
