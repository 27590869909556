import axios from "../axios-instance";
// import {
//   Company_START,
//   Company_ERROR,
//   Company_SUCCESS,
//   IsDataSubmitedSuccessfully,
// } from "../constants/company";
import { getAdminBusinessId } from "../utils/authHelper";
import { objectToFormData } from "../utils/utility";

/**
 * get event advanced page data
 * @param {*} options
 * @returns
 */
export const getCompanyData = (options) => async (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get(`/admin/settings/company/${getAdminBusinessId()}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * Get company setting page services
 * @param {*} options
 * @returns
 */
export const getCompanySettingServices = (options) => async (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get(`/company-settings-services`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * Get company setting page services
 * @param {*} options
 * @returns
 */
export const getTimezones = (options) => async (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get(`/timezones`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * save company details
 * @param {*} options
 */
export const saveCompanyDetails = (options) => {
  const { onSuccess, onError, data } = options || {};
  //data.id = getAdminBusinessId();
  const formData = objectToFormData(data);
  axios
    .post("admin/settings/company/save", formData)
    .then((res) => {
      const { data: resData } = res || {};
      if (onSuccess) {
        onSuccess(resData);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message = errorData.message || error.message;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * request feature
 * @param {*} options
 */
export const requestFeature = (options) => {
  const { onSuccess, onError, data } = options || {};
  data.business_id = getAdminBusinessId();
  axios
    .post("admin/request-feature", data)
    .then((res) => {
      const { data: resData } = res || {};
      if (onSuccess) {
        onSuccess(resData);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message = errorData.message || error.message;
      if (onError) {
        onError(message);
      }
    });
};

/*
export const updateCompanyData = (thriveData) => (dispatch) => {
  dispatch({
    type: Company_START,
  });
  axios
    .post("admin/settings/company/save", thriveData)
    .then((res) => {
      dispatch({
        type: IsDataSubmitedSuccessfully,
        IsDataSubmitedSuccessfully: true,
      });
      //  getCompanyData(1)(dispatch);
    })
    .catch((err) =>
      dispatch({
        type: Company_ERROR,
        payload: err.response.data.data,
        //  IsDataSubmitedSuccessfully:false
      })
    );
};
*/
