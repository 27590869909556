import axios from "../axios-instance";
import {
  Event_START,
  Event_ERROR,
  Event_SUCCESS,
  Event_FAIL,
  IsDataSubmitedSuccessfully,
} from "../constants/eventSettings";
import { getAdminBusinessId } from "../utils/authHelper";

/**
 * get event settings data
 * @param {*} options
 * @returns
 */
export const getEventConfigData = (options) => async (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get(`/admin/settings/event/${getAdminBusinessId()}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * save event settings data
 * @param {*} options
 */
export const saveEventConfigData = (options) => {
  const { onSuccess, onError, data } = options || {};
  data.business_id = getAdminBusinessId();
  axios
    .post("admin/settings/event/save", data)
    .then((res) => {
      const { data: resData } = res || {};
      if (onSuccess) {
        onSuccess(resData);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message = errorData.message || error.message;
      if (onError) {
        onError(message);
      }
    });
};

/*
export const getEventData = (id) => (dispatch) => {
  dispatch({
    type: Event_START,
  });
  axios
    .get("/admin/settings/event/" + getAdminBusinessId())
    .then((res) => {
      dispatch({
        type: Event_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: Event_FAIL,
        payload: err.response.data.data,
      })
    );
};
*/

export const updateEventData = (thriveData) => (dispatch) => {
  dispatch({
    type: Event_START,
  });
  axios
    .post("admin/settings/event/save", thriveData)
    .then((res) => {
      dispatch({
        type: IsDataSubmitedSuccessfully,
        IsDataSubmitedSuccessfully: true,
      });
      // getEventData(1)(dispatch);
    })
    .catch((err) =>
      dispatch({
        type: Event_ERROR,
        payload: err.response.data.data,
      })
    );
};
