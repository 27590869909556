import React, { useState } from "react";
import { Modal, ModalBody, Row, Col, Button } from "reactstrap";
import { Formik, Form } from "formik";

import * as Yup from "yup";
import FormField from "../../../../components/FormField/FormField";
import DatePickerField from "../../../../components/DatePickerField/DatePickerField";
import { getDBFormatedDate, notification } from "../../../../utils/utility";
import Tooltip from "../../../../components/Tooltip";

const validation = Yup.object().shape({
  vacation_name: Yup.string().required("Vacation name is required"),
  start_date: Yup.string().required("Start date is required"),
  end_date: Yup.string().required("End date is required"),
});

let initialValues = {
  vacation_name: "",
  start_date: "",
  end_date: "",
};

const CreateModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);

  /**
   * on toggle modal
   */
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  /**
   * Get tomorrow date
   */
  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  };

  return (
    <>
      <Button
        className="btn btn-outline btn-add mr-4"
        type="button"
        style={{ width: "126px" }}
        onClick={(event) => {
          event.preventDefault();
          toggle();
        }}
      >
        Add New
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog">
        <ModalBody>
          <div className="">
            <div className="box_content pt-0 pb-2">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="rd_modalheadertext rd_modalheadertextnm my-0">
                  Add Vacation
                </h2>
                <div className="rd_inputselectheadermodalsetting">
                  <button className="rd_colsebtn" onClick={toggle}></button>
                </div>
              </div>

              <Formik
                validationSchema={validation}
                initialValues={formValues || initialValues}
                enableReinitialize
                onSubmit={(values) => {
                  const { onAddRecord } = props;
                  let formData = {
                    ...values,
                    start_date: getDBFormatedDate(values.start_date),
                    end_date: getDBFormatedDate(values.end_date),
                  };

                  if (values.start_date.getTime() > values.end_date.getTime()) {
                    return notification.error(
                      "End date cant be earlier than the start date"
                    );
                  }
                  toggle();
                  onAddRecord(formData);
                }}
              >
                {(formProps) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                  } = formProps;
                  return (
                    <Form>
                      <Row>
                        <Col lg="12" md="12">
                          <label className="same-label" htmlFor="coupon_code">
                            Vacation Name *
                          </label>
                          <FormField
                            type="text"
                            name="vacation_name"
                            placeholder="Vacation Name"
                            value={values.vacation_name}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "vacation_name", value },
                              });
                            }}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-4 mb-4">
                        <Col lg="12">
                          <label htmlFor="coupon_expiry">Start Date * <Tooltip code="P7F2" /></label>
                          <DatePickerField
                            minDate={getTomorrowDate()}
                            errors={errors}
                            touched={touched}
                            name="start_date"
                            format="dd-MM-yyyy"
                            value={values.start_date}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-4 mb-4">
                        <Col lg="12">
                          <label htmlFor="coupon_expiry">End Date * <Tooltip code="P7F3" /></label>
                          <DatePickerField
                            minDate={getTomorrowDate()}
                            errors={errors}
                            touched={touched}
                            name="end_date"
                            format="dd-MM-yyyy"
                            value={values.end_date}
                          />
                        </Col>
                      </Row>

                      <div className="form-groups row">
                        <div className="form-group col-6 sm-12"></div>
                      </div>

                      <div className="rd_cancelappbtnmodal">
                        <button
                          type="button"
                          className="btn btn-outline"
                          onClick={toggle}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-app">
                          Save
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateModal;
