import React, { useState, useEffect } from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "../../../components/FormField/FormField";
import { connect } from "react-redux";
import { getCompanySettingServices } from "../../../actions/companyActions";

const validation = Yup.object().shape({
  first_name: Yup.string().required("First Name is required."),
  last_name: Yup.string().required("Last Name is required."),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      "Please enter valid email"
    )
    .required("Email is required"),
});

let initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  country_id: "",
};

const BillingInfoModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [countries, setCountries] = useState([]);

  /**
   * on toggle modal
   */
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const { getCompanySettingServices } = props;
    getCompanySettingServices({
      data: {},
      onSuccess: function (data) {
        setCountries(data.countries);
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  }, []);

  return (
    <>
      <button
        className="btn btn-outline"
        style={{ maxWidth: 100 }}
        onClick={toggle}
      >
        Edit
      </button>
      <Modal className="billing_modal" isOpen={isOpen} toggle={toggle}>
        <ModalBody className="p-0">
          <div className="rd_inputselectheadermodalsetting d-flex justify-content-between align-items-center">
            <h3>Update Payment info</h3>
            <button className="rd_colsebtn" onClick={toggle}></button>
          </div>
          <div className="box_content">
            <h3>Payment Details</h3>

            <Formik
              validationSchema={validation}
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(data) => {
                console.log(data);
                toggle();
                //onInviteStaff(data);
              }}
            >
              {(formProps) => {
                const { values, errors, touched, handleChange, setFieldValue } =
                  formProps;

                return (
                  <Form>
                    <Row className="rd_addnewuserfieth">
                      <Col md="6">
                        <label htmlFor="">First Name</label>
                        <div className="input-group">
                          <FormField
                            type="text"
                            name="first_name"
                            placeholder="Enter First Name"
                            value={values.first_name}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "first_name", value },
                              });
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <label htmlFor="">Last Name</label>
                        <div className="input-group">
                          <FormField
                            type="text"
                            name="last_name"
                            placeholder="Enter Last Name"
                            value={values.last_name}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "last_name", value },
                              });
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <label htmlFor="">Card Number</label>
                        <div className="input-group">
                          <FormField
                            type="text"
                            name="card_number"
                            placeholder="Enter Card Number"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <label htmlFor="">CVV</label>
                        <div className="input-group">
                          <FormField
                            type="text"
                            name="card_cvv"
                            placeholder="Enter Card Cvv"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <label htmlFor="">Exp. Date</label>
                        <div className="input-group">
                          <FormField
                            type="text"
                            name="card_expdate"
                            placeholder="Enter Card Expiry"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </Col>
                      <Col md="12" className="next_portion">
                        <h3>Billing Details</h3>
                      </Col>
                      <Col md="12">
                        <label htmlFor="">Address line 1</label>
                        <div className="input-group flex-column">
                          <FormField
                            type="text"
                            name="address_line_1"
                            placeholder="123 curious road san mateo, CA, 1234"
                            errors={errors}
                            touched={touched}
                          />
                          <p class="info_txt">
                            Street address, P.O. box, Company name C/o
                          </p>
                        </div>
                      </Col>
                      <Col md="12">
                        <label htmlFor="">Address line 2</label>
                        <div className="input-group flex-column">
                          <FormField
                            type="text"
                            name="address_line_2"
                            placeholder="123 curious road san mateo, CA, 1234"
                            errors={errors}
                            touched={touched}
                          />
                          <p class="info_txt">
                            Apartment, suite, unit, building, floor, etc
                          </p>
                        </div>
                      </Col>
                      <Col md="6">
                        <label htmlFor="">City *</label>
                        <div className="input-group">
                          <FormField
                            type="text"
                            name="city"
                            placeholder="Lahore"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <label htmlFor="">State/Province/Region</label>
                        <div className="input-group">
                          <FormField
                            type="text"
                            name="state"
                            placeholder="Punjab"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <label htmlFor="">Zip/Postal code</label>
                        <div className="input-group">
                          <FormField
                            type="text"
                            name="zip_code"
                            placeholder="54000"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <label htmlFor="">Country</label>
                        <div className="input-group">
                          <FormField
                            placeholder="Country"
                            showPlaceholder={true}
                            type="select"
                            name="country_id"
                            errors={errors}
                            touched={touched}
                            options={countries}
                            value={values.country_id.toString()}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <label htmlFor="">Phone Number*</label>
                        <div className="input-group">
                          <FormField
                            type="text"
                            name="phn_Num"
                            placeholder="+125-54125-222"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </Col>
                      <Col md="12" className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-app"
                          style={{ maxWidth: 320 }}
                        >
                          Save card Info
                        </button>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, {
  getCompanySettingServices,
})(BillingInfoModal);
