import React, { useState, useEffect } from "react";
import logoLg from "../../assets/images/lg-logo.png";
import crossBtn from "../../assets/images/clear.png";
import { Link } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import visibility from "../../assets/icons/visibility.svg";
import visibilityOff from "../../assets/icons/visibility_off.svg";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { withRouter, gotProperty, notification } from "../../utils/utility";
import PropTypes from "prop-types";
import isEmpty from "../../validation/is-empty";
import { loginUser } from "../../actions/authActions";
import Loader from "../../components/Loader/Loader";
import {
  getUserDashboardLink,
  getUserRoles,
  getUserBusinesses,
} from "../../utils/authHelper";
import CryptoJS from "crypto-js";
import { USER_TYPE_PREFIX } from "../../utils/prefixes";
import { USER_TYPES } from "../../routes/types/private";
import { GET_ERRORS } from "../../actions/types";

const Login = (props) => {
  const dispatch = useDispatch();

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const Schema = Yup.object().shape({
    email: Yup.string().required("Email cannot be empty"),
    password: Yup.string().required("Password cannot be empty"),
  });

  useEffect(() => {
    console.log("props", props.errors);
    if (!isEmpty(props.errors)) {
      setIsLoading(false);
      notification.error(props.errors);
      dispatch({
        type: GET_ERRORS,
        payload: null,
      });
    }
  }, [props.errors]);

  useEffect(() => {
    if (props.auth.isAuthenticated) {
      setIsLoading(false);
      // based on usertype redirect to user specific dashboard
      if (localStorage.userType) {
        navigate(getUserDashboardLink(localStorage.getItem("userType")));
      } else {
        const userRoles = getUserRoles();
        const userBusinesses = getUserBusinesses();

        // Getting business ids
        let businessIds = [];
        if (!isEmpty(userBusinesses)) {
          for (let userBusiness in userBusinesses) {
            businessIds.push(userBusinesses[userBusiness]);
          }
        }

        if (
          userRoles.length === 1 &&
          (businessIds.length === 0 || businessIds.length === 1)
        ) {
          localStorage.setItem(
            "userType",
            CryptoJS.SHA256(USER_TYPE_PREFIX + userRoles.pop())
          );
          // for admin user save business id in local storage
          if (
            localStorage.userType === USER_TYPES.admin &&
            businessIds.length === 1
          ) {
            localStorage.setItem("businessId", businessIds.pop());
          }
          navigate(getUserDashboardLink(localStorage.getItem("userType")));
        } else {
          navigate("/admin/user-type");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.isAuthenticated]);

  const formikProps = {
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      const data = {
        email: values.email,
        password: values.password,
      };
      setIsLoading(true);
      props.loginUser(data);
    },
  };

  const changePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  return (
    <>
      <Loader isShowLoader={isLoading} />
      <Formik {...formikProps}>
        {(formik) => {
          const { values, handleChange, handleSubmit, handleBlur } = formik;
          return (
            <div className="signup-process">
              <div className="logo-side">
                <img src={logoLg} alt="" />
              </div>
              <div className="signup">
                <div className="cross-btn">
                  <Link to="/landingPage">
                    <img width="33" src={crossBtn} alt="" />
                  </Link>
                </div>
                <div className="signup-form">
                  <form onSubmit={handleSubmit}>
                    <h1>Welcome Back!</h1>
                    <p className="r-p">
                      You Are One Password Away From Creating Something Amazing
                    </p>
                    <div className="form-group mb-0">
                      <label className="same-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control same-input"
                        name="email"
                        id="email"
                        placeholder="Enter Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      render={(error) => <span className="error">{error}</span>}
                    />
                    <div className="form-group mb-0 mt-2">
                      <label className="same-label" htmlFor="password">
                        Password
                      </label>
                      <div className="password-group">
                        <input
                          type={passwordVisibility ? "text" : "password"}
                          className="form-control same-input"
                          name="password"
                          id="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {!passwordVisibility && (
                          <img
                            src={visibilityOff}
                            onClick={changePasswordVisibility}
                            alt=""
                          />
                        )}
                        {passwordVisibility && (
                          <img
                            src={visibility}
                            onClick={changePasswordVisibility}
                            alt=""
                          />
                        )}
                      </div>
                      <ErrorMessage
                        name="password"
                        render={(error) => (
                          <span className="error">{error}</span>
                        )}
                      />
                    </div>
                    <div className="more-opt">
                      <div className="form-group d-flex align-items-center w-auto m-0 p-0">
                        <input type="checkbox" name="remember" id="remember" />
                        <label className="same-label" htmlFor="remember">
                          Remember Me
                        </label>
                      </div>
                      <Link to="#" className="mt-2">
                        Forgot Password?
                      </Link>
                    </div>
                    <button type="submit" className="btn btn-app">
                      Login
                    </button>
                    <p className="login-here">
                      Don't have a account? <Link to="/signup">SignUp Now</Link>
                    </p>
                  </form>
                </div>
                <div className="signup-footer">
                  <ul className="languages d-none">
                    <li>English (UK)</li>
                    <li>हिन्दी</li>
                    <li>ਪੰਜਾਬੀ</li>
                    <li>বাংলা</li>
                    <li>Deutsch</li>
                    <li>ગુજરાતી</li>
                    <li>Español</li>
                  </ul>
                  <p>Copyright © 2021 MeetOcto.</p>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(withRouter(Login));
