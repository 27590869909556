import axios from "../../axios-instance";
import { objectToFormData } from "../../utils/utility";

/**
 * get tooltips
 * @param {*} options
 * @returns
 */
export const getTooltips = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post("/super-admin/tooltips", data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * Get Promo by code
 * @param {*} options
 * @returns
 */
export const getPages = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .get(`/super-admin/pages`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * Get Promo by code
 * @param {*} options
 * @returns
 */
export const getPageFields = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { pageId } = data || {};
  axios
    .get(`/super-admin/page-fields/${pageId}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * create tooltip
 * @param {*} options
 */
export const createTooltip = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  const formData = objectToFormData(data);
  axios
    .post("super-admin/tooltip/create", formData)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
};

/**
 * update tooltip
 * @param {*} options
 */
export const updateTooltip = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  const formData = objectToFormData(data);
  axios
    .post("super-admin/tooltip/update", formData)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
};

/**
 * delete tooltip
 * @param {*} options
 */
export const deleteTooltip = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("super-admin/tooltip/delete", data)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
};
