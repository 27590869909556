import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { staffPendingEvents } from "../../../actions/admin/scheduled-event-actions";
import isEmpty from "../../../validation/is-empty";
import Loader from "../../../components/Loader/Loader";
import { Pagination } from "antd";
import { onModalOpen, onModalClose, gotProperty } from "../../../utils/utility";
import filterIcon from "../../../assets/icons/filter_icon.svg";
import { Row, Col, Input } from "reactstrap";
import moment from "moment";
import PendingEventDetails from "./pending-event-details";
import { getLoggedInUserId } from "../../../utils/authHelper";

class PendingEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      totalEvents: 0,
      bookingId: null,
      showViewDetailModal: false,
      isLoading: false,
      pageLoading: false,
      pagination: {
        current: 1,
        pageSize: 30,
      },
      isEventFilterVisible: true,
      filterStatus: "",
      filterCollapse: false,
    };
  }

  /**
   * get remaining time
   * @param {*} bookingDateTime
   */
  getRemainingTime = (bookingDateTime) => {
    bookingDateTime = moment(bookingDateTime);
    const currentDate = moment(new Date());
    const diff = bookingDateTime.diff(currentDate);
    const diffDuration = moment.duration(diff);

    if (diffDuration.days() > 0) {
      return `Starts in ${diffDuration.days()} ${
        diffDuration.days() > 1 ? "Days" : "Day"
      }, ${diffDuration.hours()} ${
        diffDuration.hours() > 1 ? "Hours" : "Hour"
      } and ${diffDuration.minutes()} ${
        diffDuration.minutes() > 1 ? "Minutes" : "Minute"
      }`;
    } else {
      return `Starts in ${diffDuration.hours()} ${
        diffDuration.hours() > 1 ? "Hours" : "Hour"
      } and ${diffDuration.minutes()} ${
        diffDuration.minutes() > 1 ? "Minutes" : "Minute"
      }`;
    }
  };

  /**
   * hide modal
   */
  hideViewDetailModal = () => {
    this.setState({
      showViewDetailModal: false,
    });
    onModalClose();
  };

  /**
   * on page change
   * @param {*} page
   * @param {*} pageSize
   */
  onPageChange = (page, pageSize) => {
    const _this = this;
    _this.setState({
      isLoading: true,
    });
    _this.fetchListEvents({
      pagination: {
        current: page,
        pageSize: pageSize,
      },
    });
  };

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        staff_id: getLoggedInUserId(),
        ...params.filters,
      },
    };
  };

  /**
   * fetch list events
   */
  fetchListEvents = (params = {}) => {
    const _this = this;
    const { staffPendingEvents } = _this.props || {};
    staffPendingEvents({
      data: _this.prepareDataParam(params),
      onSuccess: function (response) {
        const { data, total, event_settings: eventSettings } = response;

        _this.setState({
          events: data,
          totalEvents: total,
          isLoading: false,
          pageLoading: false,
          pagination: { ...params.pagination },
        });
      },
      onError: function (error) {
        console.error(error);
      },
    });
  };

  /**
   * load upcoming events
   */
  loadPendingEvents = () => {
    const _this = this;
    const { pagination } = _this.state;
    _this.setState({
      isLoading: true,
    });
    _this.fetchListEvents({ pagination });
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchListEvents({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  /**
   * updating filter
   * @param {*} name
   * @param {*} value
   */
  onUpdateFilters = (name, value) => {
    const searchFilters = { ...this.state.filter };
    if (isEmpty(value)) {
      delete searchFilters[name];
    } else {
      searchFilters[name] = value;
    }
    this.setState({
      filter: searchFilters,
      //isLoading: true,
    });
  };

  /**
   * on filter data table
   */
  onFilterDataTable = (name, value) => {
    const searchFilters = { ...this.state.filter };
    if (isEmpty(value)) {
      delete searchFilters[name];
    } else {
      searchFilters[name] = value;
    }
    this.setState({
      filter: searchFilters,
      isLoading: true,
    });

    this.handleTableChange(
      {
        ...this.state.pagination,
      },
      {
        ...searchFilters,
      },
      {}
    );
  };

  /**
   * filter records
   */
  filterRecords = () => {
    const _this = this;
    _this.setState({
      isLoading: true,
      filterShow: false,
    });
    _this.handleTableChange(
      {
        ..._this.state.pagination,
      },
      {
        ..._this.state.filter,
      },
      {}
    );
  };

  /**
   * on clear filters
   */
  onClearFilters = () => {
    const _this = this;
    this.setState({
      filter: {},
      isLoading: true,
      filterShow: false,
    });
    _this.handleTableChange(
      {
        ..._this.state.pagination,
      },
      {},
      {}
    );
  };

  componentDidMount() {
    const _this = this;
    const { pagination } = _this.state;
    _this.setState({
      isLoading: true,
    });
    _this.fetchListEvents({ pagination });
  }

  /**
   * on view detail click
   * @param {*} bookingData
   */
  onViewDetail = (bookingData) => {
    this.setState({
      bookingId: bookingData.id,
      showViewDetailModal: true,
    });
  };

  render() {
    const {
      events,
      isLoading,
      pagination,
      showViewDetailModal,
      bookingId,
      filter,
    } = this.state || {};
    const _this = this;
    return (
      <div className="rd_pasevencontth">
        <Row>
          <Col sm="12">
            <div className="filter_bar d-flex justify-content-between align-items-center">
              <h3>Event List</h3>
              <div className="filter_sec">
                <button
                  className="btn p-0"
                  onClick={() =>
                    this.setState({ ...this.state, filterShow: true })
                  }
                  style={{ width: "auto" }}
                >
                  <img src={filterIcon} alt="" width={27} height={27} />
                </button>
                <div
                  className={`filter_backdrop ${
                    this.state.filterShow ? "active" : ""
                  }`}
                ></div>
                <div
                  className={`filter_content ${
                    this.state.filterShow ? "active" : ""
                  }`}
                >
                  <div className="position-relative">
                    <div className="top_bar">
                      <h3>Filters</h3>
                      <button className="btn p-0" onClick={this.onClearFilters}>
                        Clear Filters
                      </button>
                    </div>
                    <div className="filter_items_container">
                      <div class="form-group">
                        <label htmlFor="event-name">Event Name</label>
                        <input
                          type="text"
                          id="event-name"
                          placeholder="Event Name ..."
                          class="form-control"
                          value={
                            gotProperty(filter, "event_name")
                              ? filter["event_name"]
                              : ""
                          }
                          onChange={(e) => {
                            this.onUpdateFilters("event_name", e.target.value);
                          }}
                        />
                      </div>

                      <div class="form-group">
                        <label htmlFor="event-format">Event Format</label>
                        <Input
                          type="select"
                          name="event_format"
                          id="event-format"
                          value={
                            gotProperty(filter, "event_format_id")
                              ? filter["event_format_id"]
                              : ""
                          }
                          onChange={(e) => {
                            this.onUpdateFilters(
                              "event_format_id",
                              e.target.value
                            );
                          }}
                        >
                          <option value="">Select Event Format</option>
                          <option value="1">One to One</option>
                          <option value="2">Group</option>
                        </Input>
                      </div>
                    </div>
                    <div className="bottom_bar">
                      <button
                        className="btn btn-outline"
                        onClick={() =>
                          this.setState({ ...this.state, filterShow: false })
                        }
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-app"
                        onClick={this.filterRecords}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Loader isShowLoader={isLoading} />
        {Object.keys(events).length > 0 ? (
          <Fragment>
            {Object.keys(events).map((key, index) => {
              return (
                <Fragment key={index}>
                  <h4>
                    <span className="rd_archiveiconth"></span>
                    {moment(key, "DD-MM-YYYY").format("dddd, MMMM, DD YYYY")}
                  </h4>
                  <div className="rd_eventcardscontainer">
                    {(events[`${key}`] || []).map((event, innerIndex) => {
                      if (event.type === "regular") {
                        return (
                          <div className="rd_carditem" key={innerIndex}>
                            <div className="rd_cardheaderevenpasc">
                              <h5
                                className={
                                  event.status === "CC"
                                    ? "text-line-through"
                                    : ""
                                }
                              >
                                {event.event_name}
                              </h5>
                              {event.event_format_id === "1" ||
                              event.event_format_id === 1 ? (
                                <p
                                  className={
                                    event.status === "CC"
                                      ? "text-line-through"
                                      : ""
                                  }
                                >
                                  One on One
                                </p>
                              ) : (
                                <p
                                  className={
                                    event.status === "CC"
                                      ? "text-line-through"
                                      : ""
                                  }
                                >{`1 to ${event.no_of_attendies} invitees`}</p>
                              )}
                            </div>
                            <div className="rd_cardbdyevenpasc">
                              {event.status !== "CC" ? (
                                <p
                                  className={
                                    event.status === "CC"
                                      ? "text-line-through"
                                      : ""
                                  }
                                >
                                  <span className="rd_timeiconthin"></span>
                                  {_this.getRemainingTime(
                                    event.booking_date_time
                                  )}
                                </p>
                              ) : null}

                              <p
                                className={
                                  event.status === "CC"
                                    ? "text-line-through"
                                    : ""
                                }
                              >
                                <span className="rd_timelefticon"></span>
                                {`${event.start_time} - ${event.end_time}`}
                              </p>
                              <p
                                className={
                                  event.status === "CC"
                                    ? "text-line-through"
                                    : ""
                                }
                              >
                                <span
                                  className="rd_archiveiconth"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "10px",
                                  }}
                                ></span>
                                {event.venue}
                              </p>

                              {event.status === "CC" ? (
                                <p>
                                  <span className="text-danger">
                                    {" "}
                                    Cancelled by {event.cancellation_by}{" "}
                                  </span>{" "}
                                  {event.cancellation_remarks}
                                </p>
                              ) : null}
                            </div>
                            <button
                              className="view-detail-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                this.onViewDetail(event);
                                onModalOpen();
                              }}
                            >
                              View Detail
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="rd_carditem"
                            key={innerIndex}
                            style={{ border: "1px solid #ccc" }}
                          >
                            <div className="rd_cardheaderevenpasc">
                              <h5
                                className={
                                  event.status === "CC"
                                    ? "text-line-through"
                                    : ""
                                }
                              >
                                {event.event_name}
                              </h5>
                            </div>
                            <div className="rd_cardbdyevenpasc">
                              {event.status !== "CC" ? (
                                <p
                                  className={
                                    event.status === "CC"
                                      ? "text-line-through"
                                      : ""
                                  }
                                >
                                  <span className="rd_timelefticon"></span>
                                  {_this.getRemainingTime(
                                    event.booking_date_time
                                  )}
                                </p>
                              ) : null}

                              <p
                                className={
                                  event.status === "CC"
                                    ? "text-line-through"
                                    : ""
                                }
                              >
                                <span className="rd_timeiconthin"></span>
                                {`${event.start_time} - ${event.end_time}`}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        ) : (
          <div className="box_content rd_boxcontentinf"></div>
        )}
        {showViewDetailModal && (
          <PendingEventDetails
            bookingId={bookingId}
            showViewDetailModal={showViewDetailModal}
            handleClose={this.hideViewDetailModal}
            loadPendingEvents={this.loadPendingEvents}
            staffCanConfirm={
              this.props?.auth?.config?.staff_can_confirm_booking
            }
          />
        )}
        {Object.keys(events).length > 0 ? (
          <Row className="pb-4 pr-3">
            <Col className="text-center" lg="12" md="12">
              <Pagination
                className="list-view-pagination"
                defaultCurrent={pagination.current}
                current={pagination.current}
                total={this.state.totalEvents}
                pageSize={pagination.pageSize}
                onChange={this.onPageChange}
              />
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  staffPendingEvents,
})(PendingEvents);
