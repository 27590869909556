import axios from "../axios-instance";
import { getAdminBusinessId } from "../utils/authHelper";
import { fallBackErrorMessage } from "../constants/event";

/**
 * Get company setting page services
 * @param {*} options
 * @returns
 */
export const getEvents = (options) => async (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get(`/events/${getAdminBusinessId()}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/*
export const getDashboardFirst = (filter) => (dispatch) => {
  dispatch({
    type: AdminDashboard_START,
  });
  axios
    .get("admin/crm-get-total-booking-status/" + getAdminBusinessId(), filter)
    .then((res) => {
      dispatch({
        type: AdminDashboardFirst_SUCCESS,
        payload: res.data,
        AdminDashboardFirst: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: AdminDashboard_ERROR,
        payload: err.response.data.data,
      })
    );
};

export const getDashboardSecond = (filter) => (dispatch) => {
  dispatch({
    type: AdminDashboard_START,
  });
  axios
    .get("admin/crm-booked-events/" + getAdminBusinessId(), filter)
    .then((res) => {
      dispatch({
        type: AdminDashboardSecond_SUCCESS,
        payload: res.data,
        AdminDashboardSecond: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: AdminDashboard_ERROR,
        payload: err.response.data.data,
      })
    );
};
*/

/**
 * Get company events
 * @param {*} options
 * @returns
 */
export const getDashboardUpcomingEvents = (options) => async (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get(`admin/crm-upcoming-events/${getAdminBusinessId()}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData.data || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/*
export const getDashboardThird = (filter) => (dispatch) => {
  dispatch({
    type: AdminDashboard_START,
  });
  axios
    .get("admin/crm-upcoming-events/" + getAdminBusinessId(), filter)
    .then((res) => {
      dispatch({
        type: AdminDashboardThird_SUCCESS,
        payload: res.data,
        AdminDashboardThird: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: AdminDashboard_ERROR,
        payload: err.response.data.data,
      })
    );
};
*/

/*
export const getDashboardForth = (filter) => (dispatch) => {
  dispatch({
    type: AdminDashboard_START,
  });
  axios
    .get("admin/crm-booking-sales/" + getAdminBusinessId(), filter)
    .then((res) => {
      dispatch({
        type: AdminDashboardForthPortion_SUCCESS,
        payload: res.data,
        AdminDashboardForth: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: AdminDashboard_ERROR,
        payload: err.response.data.data,
      })
    );
};

export const getDashboardFifth = (filter) => (dispatch) => {
  dispatch({
    type: AdminDashboard_START,
  });
  axios
    .get("admin/crm-sales-per-booking/" + getAdminBusinessId(), filter)
    .then((res) => {
      dispatch({
        type: AdminDashboardFifthPortion_SUCCESS,
        payload: res.data,
        AdminDashboardFifth: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: AdminDashboard_ERROR,
        payload: err.response.data.data,
      })
    );
};
*/

/**
 * toggle Event secret status
 * @param {*} options
 */
export const setDefaultLandingPage = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/set-default-landing-page", data)
    .then((res) => {
      const { data: resData } = res || {};
      onSuccess(resData);
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};
