import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  initCalendar,
  setCalendarView,
  setCalendarEvents,
} from "../../actions/calendarActions";
import moment from "moment";
import Toolbar from "./Toolbar";
import MonthView from "./MonthView";
import WeekView from "./WeekView";
import DayView from "./DayView";
moment.locale("en", {
  week: {
    dow: 7,
  },
});
moment.locale("en");

const Calendar = (props) => {
  /**
   * props destruction
   */
  const {
    views = ["month", "week", "day"], // Views to be show on calendar
    selectedView = "month", // Selected view of Calendar
    events = [], // Events
    shortEventSlot = false, // If we want just only short slot then we will make is true, otherwise leave it as default
    showMoreInPopup = false, // if set to true, then show more will show in popup, other wise in day view
    onSelectEvent = (event) => {}, // trigger when user click on slot
    onNavigate = (date, action) => {},
    onSelectSlot = (date, browserEvent) => {}, // trigger when user click on day in month view
  } = props;

  const [calendarEvents, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date()); // Current date
  const [selectedDate, setSelectedDate] = useState(new Date()); // Selected date, in which the calendar will be shown

  useEffect(() => {
    //const { setCalendarEvents } = props;
    //setCalendarEvents(events);
    setEvents(events);
  }, [events]);

  useEffect(() => {
    const { initCalendar } = props;
    initCalendar({
      views,
      selectedView,
      shortEventSlot,
      showMoreInPopup,
      onSelectEvent,
      onNavigate,
      onSelectSlot,
    });
  }, []);

  return (
    <div className="rd_vacationfilterpart rd_calendartabthing2 mx-0">
      <div className="box_content">
        <div className="custom_calendar">
          <Toolbar
            currentDate={currentDate}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          {props.calendar.selectedView === "month" && (
            <MonthView
              events={calendarEvents}
              currentDate={currentDate}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          )}
          {props.calendar.selectedView === "week" && (
            <WeekView
              events={calendarEvents}
              currentDate={currentDate}
              selectedDate={selectedDate}
            />
          )}
          {props.calendar.selectedView === "day" && (
            <DayView
              events={calendarEvents}
              currentDate={currentDate}
              selectedDate={selectedDate}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  calendar: state.calendar,
});

export default connect(mapStateToProps, {
  initCalendar,
  setCalendarView,
  setCalendarEvents,
})(Calendar);
