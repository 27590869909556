import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
//import EventSchedulingModal from "../Modals/EventSchedulingModal";
//import { Calendar, momentLocalizer } from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ScheduleDayDropdown from "./schdules/schedule-day-dropdown";
import Calendar from "../../../../components/Calendar/Calendar";

//const localizer = momentLocalizer(moment);

const ScheduleCalendarView = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  
  const [calendarDate, setCalendarDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [browserEvent, setBrowserEvent] = useState(null);

  useEffect(() => {
    processWeeklyScheduleForCalendar(moment().month() + 1, moment().year());
  }, [props.eventSchedules]);

  /**
   * Get day schedule
   * @param int dayId
   */
  const getDaySchedule = (dayId) => {
    const { eventSchedules } = props || {};
    const found = eventSchedules.some((el) => el.dayId === dayId);
    if (found) {
      return (eventSchedules || []).filter(
        (schedule) => schedule.dayId === dayId
      )[0].schedules;
    } else {
      return null;
    }
  };

  /**
   * process event schedule to show in calendar
   * @param {*} month
   * @param {*} year
   */
  const processWeeklyScheduleForCalendar = (month, year) => {
    const weeklySchedules = [
      getDaySchedule(7),
      getDaySchedule(1),
      getDaySchedule(2),
      getDaySchedule(3),
      getDaySchedule(4),
      getDaySchedule(5),
      getDaySchedule(6),
    ];

    const daysInMonth = moment(year + "-" + month, "YYYY-MM").daysInMonth();
    //console.log(moment("2021-09-26").day());
    let events = [];
    for (let dayOfMonth = 1; dayOfMonth <= daysInMonth; dayOfMonth++) {
      dayOfMonth = dayOfMonth < 10 ? "0" + dayOfMonth : dayOfMonth;

      // first check for custom date availability
      const selectedMonth = ("0" + month).slice(-2);
      const formatedDate = dayOfMonth + "-" + selectedMonth + "-" + year;
      const { eventSchedules } = props || {};

      //console.log('abc event Schedules', eventSchedules, formatedDate);

      const found = eventSchedules.some((el) => el.dated === formatedDate);
      if (found) {
        const dayIndex = eventSchedules.findIndex(
          (el) => el.dated === formatedDate
        );
        const schedules = eventSchedules[dayIndex].schedules;

        (schedules || []).map((schedule, index) => {
          const startTimeParts = schedule.start_time.split(":");
          const endTimeParts = schedule.end_time.split(":");
          events.push({
            title: schedule.start_time + " - " + schedule.end_time,
            start: new Date(
              year,
              month - 1,
              dayOfMonth,
              startTimeParts[0],
              startTimeParts[1],
              0
            ),
            end: new Date(
              year,
              month - 1,
              dayOfMonth,
              endTimeParts[0],
              endTimeParts[1],
              0
            ),
          });
        });

        continue;
      }

      //console.log('xxxxxx', weeklySchedules, moment(year + "-" + month + "-" + dayOfMonth).day())

      if (
        weeklySchedules[moment(year + "-" + month + "-" + dayOfMonth).day()] !==
        null
      ) {
        const daySchedules =
          weeklySchedules[moment(year + "-" + month + "-" + dayOfMonth).day()];
        (daySchedules || []).map((schedule, index) => {
          const startTimeParts = schedule.start_time.split(":");
          const endTimeParts = schedule.end_time.split(":");
          events.push({
            title: schedule.start_time + " - " + schedule.end_time,
            start: new Date(
              year,
              month - 1,
              dayOfMonth,
              startTimeParts[0],
              startTimeParts[1],
              0
            ),
            end: new Date(
              year,
              month - 1,
              dayOfMonth,
              endTimeParts[0],
              endTimeParts[1],
              0
            ),
          });
        });
      }
    }
    setEvents(events);
    //console.log("events", events);
  };

  const handleSelect = (event) => {
    setShowModal(true);
    setSelectedEvent(event);
  };

  return (
    <Fragment>
      {/* <div className="availbility-container-wrapper"> */}
      <div>
        {/* <Calendar
          showMultiDayTimes
          step={60}
          localizer={localizer}
          events={events}
          onSelectEvent={(event) => alert(event.title)}
          onSelectSlot={handleSelect}
          selectable
          views={["month"]}
          onNavigate={(date, view, action) => {
            processWeeklyScheduleForCalendar(
              date.getMonth() + 1,
              date.getFullYear()
            );
          }}
          popup
        /> */}
        <Calendar
          views={["month"]}
          date={calendarDate}
          events={events}
          onNavigate={(date, action) => {
            const dated = moment(date);
            const month = dated.format("M");
            const year = dated.format("YYYY");
            processWeeklyScheduleForCalendar(month, year);
          }}
          shortEventSlot={true}
          onSelectSlot={(date, browserEvent) => {
            setSelectedDate(moment(date, "DD-MM-YYYY"));
            setBrowserEvent(browserEvent);
            setShowModal(true);
          }}
        />
        {showModal && (
          <ScheduleDayDropdown
            showInlineModal={showModal}
            setShowInlineModal={setShowModal}
            businessId={props.businessId}
            timezoneId={props.timezoneId}
            day={selectedDate}
            browserEvent={browserEvent}
            evntSchedules={props.eventSchedules}
            setEvntSchedules={props.setEventSchedules}
          />
        )}
      </div>
    </Fragment>
  );
};

export default ScheduleCalendarView;
