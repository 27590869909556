import MsOfficeIcon from "../../../assets/icons/ms-office-360.svg";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";

const Office365Calendar = () => {
    return (
        <Col md="6" xxl="3" xl="4">
            <Link to="all-integrations" className="int-col">
            <div className="img">
                <img src={MsOfficeIcon} alt="Google Calendar" />
            </div>
            <div className="text">
                <h3>Office 365 Calendar</h3>
                <p>
                Lorem Ipsum is simply dummy text of the
                printing an typesetting industry.
                </p>
            </div>
            </Link>
        </Col>
    );
}

export default Office365Calendar;