import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-date-picker";
import { FormFeedback } from "reactstrap";

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const { touched, errors, name } = props;

  return (
    <>
      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(name, val);
        }}
      />
      {errors[name] && touched[name] && (
        <FormFeedback className="d-block">{errors[name]}</FormFeedback>
      )}
    </>
  );
};

export default DatePickerField;
