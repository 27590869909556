import React, { useState, useEffect } from "react";
import Loader from "../../../../components/Loader/Loader";
import PendingFilters from "./pending-filters";
import Filter from "../../../../components/Filter/Filter";
import { connect } from "react-redux";
import { pendingEvents } from "../../../../actions/admin/scheduled-event-actions";
import isEmpty from "../../../../validation/is-empty";
import { toggleFilter } from "../../../../actions/filterActions";
import { FRONTEND_BASE_URL } from "../../../../utils/utility";
import CopyIcon from "../../../../assets/icons/copy_icon.svg";
import "antd/dist/antd.css";
import { Pagination } from "antd";
import { Row, Col } from "reactstrap";
import DeleteEventModal from "../../../events/Components/Modals/DeleteEventModal";
import { sendPostRequest } from "../../../../actions/app-actions";
import { notification } from "../../../../utils/utility";
import PendingViewDetailsModal from "./pending-view-details-modal";

const Pending = (props) => {
  const [events, setEvents] = useState([]);
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [totalEvents, setTotalEvents] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showViewDetailModal, setShowViewDetailModal] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  });

  useEffect(() => {
    fetchListEvents({ pagination });
  }, []);

  /**
   * Copy event url
   * @param string event_url
   */
  const onCopyEventLink = (eventURL, copyFieldElementId) => {
    var copyText = document.getElementById(copyFieldElementId);
    copyText.disabled = false;
    copyText.select();
    document.execCommand("copy");
    copyText.disabled = true;
    window.getSelection().removeAllRanges();
    notification.success(
      `Event URL Copied ${FRONTEND_BASE_URL}adhoc-booking/${eventURL}`
    );
  };

  /**
   * on delete adhoc event
   */
  const onDelete = (eventId) => {
    const { sendPostRequest } = props;
    setIsLoading(true);
    sendPostRequest({
      url: `/admin/adhoc-delete`,
      data: {
        id: eventId,
      },
      onSuccess: function (response) {
        setIsLoading(false);
        setShowDeleteEventModal(false);
        fetchListEvents({ pagination });
        notification.success(`Event successfully deleted`);
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  };

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  const prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * fetch list events
   */
  const fetchListEvents = (params = {}) => {
    setIsLoading(true);
    const { pendingEvents } = props;
    pendingEvents({
      data: prepareDataParam(params),
      onSuccess: function (response) {
        const { data, total } = response;
        setEvents(data);
        setTotalEvents(total);
        setPagination({ ...params.pagination });
        setIsLoading(false);
      },
      onError: function (error) {
        console.error(error);
      },
    });
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  const handleTableChange = (pagination, filters, sorter) => {
    fetchListEvents({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  /**
   * on clear filter handler
   */
  const clearFilterHandler = () => {
    setIsLoading(true);
    handleTableChange(
      {
        ...pagination,
      },
      {},
      {}
    );
  };

  /**
   * on page change
   * @param {*} page
   * @param {*} pageSize
   */
  const onPageChange = (page, pageSize) => {
    setIsLoading(true);
    fetchListEvents({
      pagination: {
        current: page,
        pageSize: pageSize,
      },
    });
  };

  /**
   * filter records
   */
  const filterRecords = () => {
    const { toggleFilter, filter } = props;
    toggleFilter(false);
    setIsLoading(true);
    handleTableChange(
      {
        ...pagination,
      },
      {
        ...filter.searchFilters,
      },
      {}
    );
  };

  return (
    <section>
      <Loader isShowLoader={isLoading} />
      <div className="">
        <div className="eventdetailsaddbox rd_noshadow">
          <div className="rd_pasevencontth pr-md-0">
            <Filter
              name="Events"
              onFilterRecords={filterRecords}
              onClearFilterHandler={clearFilterHandler}
            >
              <PendingFilters />
            </Filter>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <div className="rd_eventcardscontainer">
                {(events || []).map((event, index) => {
                  const { venue, event_name, event_url, id } = event || {};
                  return (
                    <div className="rd_carditem rd_carditemnoback" key={index}>
                      <div className="dashboarditemthing dashboarditemthing2">
                        <div className="rd_cardheaderevenpasc mt-2 mb-2">
                          <h3 className="w-100">{event_name}</h3>
                        </div>
                        <p>
                          <span>Event Venue: </span>
                          {venue}
                        </p>

                        <div className="rd_eventlibuttanlikco">
                          <div className="rd_eventlibuttanlikcolinkco">
                            <div className="bottom_btns">
                              <button
                                className="view-detail-btn w-100"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setEventId(id);
                                  setShowViewDetailModal(true);
                                }}
                              >
                                View Detail
                              </button>
                            </div>
                            <div>
                              <button
                                className="rd_deletebuttontheven"
                                onClick={() => {
                                  setEventId(id);
                                  setShowDeleteEventModal(true);
                                }}
                              ></button>
                            </div>
                          </div>

                          <div>
                            <div className="rd_profilethingco">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control noshadfoc pl-2"
                                  id={"copy-field-element-" + index}
                                  placeholder="https://referral_link.com/yourlink"
                                  value={
                                    FRONTEND_BASE_URL +
                                    "adhoc-booking/" +
                                    event_url
                                  }
                                  disabled
                                />
                                <div className="input-group-prepend rd_sahredownbtn">
                                  <button
                                    className="input-group-text"
                                    onClick={() =>
                                      onCopyEventLink(
                                        event_url,
                                        "copy-field-element-" + index
                                      )
                                    }
                                  >
                                    <span className="d-none d-lg-block">
                                      Copy link
                                    </span>
                                    <img
                                      src={CopyIcon}
                                      alt="copy icon"
                                      className="d-lg-none"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {showDeleteEventModal && (
          <DeleteEventModal
            eventId={eventId}
            showDeleteEventModal={showDeleteEventModal}
            handleClose={() => {
              setShowDeleteEventModal(false);
            }}
            onDelete={onDelete}
          />
        )}

        {showViewDetailModal && (
          <PendingViewDetailsModal
            eventId={eventId}
            showViewDetailModal={showViewDetailModal}
            handleClose={() => {
              setShowViewDetailModal(false);
            }}
          />
        )}

        {!isLoading ? (
          <Row className="pb-4 pr-3">
            <Col className="text-center" lg="12" md="12">
              <Pagination
                className="list-view-pagination"
                defaultCurrent={pagination.current}
                current={pagination.current}
                total={totalEvents}
                pageSize={pagination.pageSize}
                onChange={onPageChange}
                showSizeChanger={false}
              />
            </Col>
          </Row>
        ) : null}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  pendingEvents,
  toggleFilter,
  sendPostRequest,
})(Pending);
