import React, { Component } from 'react';
import { Link } from "react-router-dom";
import freeEvent from '../../assets/images/events/free-event.svg';
import paidEvent from '../../assets/images/events/paid-event.svg';
import adHocEvent from '../../assets/images/events/ad-hoc-event.svg';


class EventTypeList extends Component {
    render() {
        return (
            <div className="event-wrapper">
                <div className="event-container">
                    <h2 className="event-title">Create New Events</h2>
                    <div className="event-type-wrapper">
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-xl-3">
                                <Link to="/admin/events/create?type=free-event">
                                    <div className="card">
                                        <div className="image-wrapper">
                                            <img src={freeEvent} alt="Free Event" />
                                        </div>
                                        <p>
                                            I want to create a free event
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-xl-3">
                                <Link to="/admin/events/create?type=paid-event">
                                    <div className="card">
                                        <div className="image-wrapper">
                                            <img src={paidEvent} alt="Paid Event" />
                                        </div>
                                        <p>
                                            I want to charge for the event
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 col-xl-3">
                                <Link to="/admin/events/create/ad-hoc">
                                    <div className="card">
                                        <div className="image-wrapper">
                                            <img src={adHocEvent} alt="Ad Hoc Event" />
                                        </div>
                                        <p>
                                            I want to start a quick event
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default EventTypeList;