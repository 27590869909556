import React, { Component, Fragment } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { getTimezones } from "../../../../actions/companyActions";
import FormField from "../../../../components/FormField/FormField";
import EventSummaryCalendar from "./event-summary-calendar";

class EventSummaryAvailabilityComponent extends Component {
  
  timezoneValue = "";

  state = {
    timezones: [],
    timezoneId: "",
  };

  /**
   * Execute when component inserted in dom
   */
  componentDidMount() {
    const _this = this;
    const { getTimezones } = _this.props;
    getTimezones({
      data: {},
      onSuccess: function (data) {
        _this.setState({
          timezones: data.timezones,
        });
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  }

   
  /**
   * 
   * @returns 
   */
  getFilteredSchedules = () => {
    const _this = this;
    const {
      schedules,
      type, 
      staffId
    } = _this.props;

    if (type === "event_staff") {
      const staffSchedules = (schedules || []).filter((schedule, index) => {
        return schedule.staffId == staffId;
      });
      if (staffSchedules.length > 0) {
        this.timezoneValue = staffSchedules[0].timezoneId;
        return staffSchedules[0].schedules;
      } else {
        return []; 
      }
      
    } else if (type === "event") {
      const eventSchedules = (schedules || []).filter((schedule, index) => {
        return schedule.type == "event";
      });
      if (eventSchedules.length > 0) {
        this.timezoneValue = eventSchedules[0].timezoneId;
        return eventSchedules[0].schedules;
      } else {
        return [];
      }
    } else {
      return [];
    }

  }

  render() {
    const {
      errors,
      touched,
      businessId,
    } = this.props || {};
    const { 
      timezones, 
      timezoneId,
    } = this.state;

    const filteredSchedules = this.getFilteredSchedules();

    return (
      <Fragment>
        <div className="tabs-wrapper event-availbility">
          <div className="tabs-container">
            <Row className="pt-4">
              <Col lg="4">
              <div className="form-group event-form-group">
                  <FormField
                    showLabel
                    placeholder="Select Timezone"
                    showPlaceholder={true}
                    type="select"
                    name="event_format_id"
                    label="Select Timezone"
                    errors={[]}
                    touched={[]}
                    value={timezoneId || this.timezoneValue}
                    options={timezones}
                    disabled={true}
                  />
                </div>
              </Col>
            </Row>
              <EventSummaryCalendar
                  businessId={businessId}
                  timezoneId={this.state.timezoneId}
                  errors={errors}
                  touched={touched}
                  eventSchedules={filteredSchedules}
                />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  getTimezones
})(EventSummaryAvailabilityComponent);
