import React from "react";
import { Modal, ModalBody } from "reactstrap";

const DeleteScheduleModal = (props) => {
  const { showDeleteScheduleModal, hideDeleteScheduleModal } = props;

  return (
    <>
      <Modal isOpen={showDeleteScheduleModal} toggle={hideDeleteScheduleModal}>
        <ModalBody>
          <div className="">
            <div className="rd_modalvieew">
              <div className="box_content rd_modalboxcontent">
                <div className="rd_inputselectheadermodalsetting">
                  <button
                    className="rd_colsebtn"
                    onClick={hideDeleteScheduleModal}
                  ></button>
                </div>

                <h5 className="rd_modalheadertext rd_modalheadertextnm">
                  Confirm
                </h5>

                <p className="rd_modalheadertextparath mt-5 mb-5">
                  Are you sure, you want to delete schedule ?
                </p>

                <div className="rd_cancelappbtnmodal">
                  <button
                    className="rd_cancel"
                    onClick={hideDeleteScheduleModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-app"
                    type="submit"
                    onClick={() => {
                      hideDeleteScheduleModal();
                      props.deleteScheduleTemplate(props.scheduleId);
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DeleteScheduleModal;
