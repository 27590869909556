import axios from "../axios-instance";
import {
  MyProfile_START,
  MyProfile_ERROR,
  MyProfile_SUCCESS,
  IsDataSubmitedSuccessfully,
} from "../constants/myProfile";
import { getLoggedInUserId } from "../utils/authHelper";
import { objectToFormData, gotProperty } from "../utils/utility";
import { SET_CURRENT_USER } from "./types";
import jwt_decode from "jwt-decode";
import setAuthToken from "../utils/setAuthToken";

/**
 * Get User details
 * @param {*} options
 * @returns
 */
export const getUserProfileData = (options) => async (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get("user-details/" + getLoggedInUserId())
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * Get User details
 * @param {*} options
 * @returns
 */
export const updateUserProfileData = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};

  let croppedImage = null;
  if (gotProperty(data, "cropped_image")) {
    croppedImage = data.cropped_image;
  }

  let formData = objectToFormData(data);

  /*
  if (croppedImage) {
    //console.log("ccc", croppedImage);
    formData.append("cropped_image", croppedImage);
  }
  */

  axios
    .post("user/edit", formData)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};

        const { data: userData } = data.data;

        //const decoded = jwt_decode(userData.user);

        localStorage.setItem("config", JSON.stringify(userData.user));

        localStorage.setItem("jwtToken", userData.user);
        // Set token to Auth header
        //setAuthToken(userData.user);

        dispatch({
          type: SET_CURRENT_USER,
          payload: userData?.user?.user,
        });

        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

export const getMyProfileData = (id) => (dispatch) => {
  dispatch({
    type: MyProfile_START,
  });
  axios
    .get("user-details/" + id)
    .then((res) => {
      axios
        .get("company-settings-services")
        .then((countryRes) => {
          dispatch({
            type: MyProfile_SUCCESS,
            payload: res.data,
            countryDD: countryRes.data.data.countries,
          });
        })
        .catch((err) => {
          dispatch({
            type: MyProfile_ERROR,
            payload: err.response.data.data,
          });
        });
    })
    .catch((err) =>
      dispatch({
        type: MyProfile_ERROR,
        payload: err.response.data.data,
      })
    );
};

export const updateMyProfileData = (profileData) => (dispatch) => {
  dispatch({
    type: MyProfile_START,
  });
  axios
    .post("user/edit", profileData)
    .then((res) => {
      dispatch({
        type: IsDataSubmitedSuccessfully,
        IsDataSubmitedSuccessfully: true,
      });
      //  getMyProfileData(1)(dispatch);
    })
    .catch((err) =>
      dispatch({
        type: MyProfile_ERROR,
        payload: err.response.data.data,
      })
    );
};
