import React, { useState, useEffect, useRef } from "react";
import Calendar from "../../../components/Calendar/Calendar";
import Filter from "../../../components/Filter/Filter";
import { connect } from "react-redux";
import { getCalendarOrListBookings } from "../../../actions/admin/my-calendar-actions";
import { notification } from "../../../utils/utility";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import ViewDetailModal from "./view-details-modal";
import { onModalClose } from "../../../utils/utility";
import { getAdminBusinessId } from "../../../utils/authHelper";
import MyCalendarFilters from "./my-calendar-filters";
import { toggleFilter } from "../../../actions/filterActions";
import useMediaQuery from "../../../hooks/useMediaQuery";

const MyCalendar = (props) => {
  const [calendarDate, setCalendarDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pageLoading, setPageLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [showViewDetailModal, setShowViewDetailModal] = useState(false);
  const [bookingId, setBookingId] = useState(null);

  const selectedMonth = useRef(null);
  const selectedYear = useRef(null);

  const isTablet = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    getBookingsForCalendar();
  }, []);

  /**
   * hide modal
   */
  const hideViewDetailModal = () => {
    setShowViewDetailModal(false);
    onModalClose();
  };

  /**
   * filter records
   */
  const filterRecords = () => {
    const { toggleFilter } = props;
    toggleFilter(false);
    getBookingsForCalendar(selectedMonth.current, selectedYear.current);
  };

  /**
   * on clear filter handler
   */
  const clearFilterHandler = () => {
    const { getCalendarOrListBookings } = props;
    setPageLoading(true);

    let filtersData = {};

    if (selectedMonth.current && selectedYear.current) {
      filtersData.selected_month = selectedMonth;
      filtersData.selected_year = selectedYear;
    }

    getCalendarOrListBookings({
      data: {
        is_calendar_view: "E",
        business_id: getAdminBusinessId(),
        filter: {
          ...filtersData,
        },
      },
      onSuccess: (response) => {
        setPageLoading(false);
        let bookings = [];
        response.forEach((resData) => {
          const startTimeParts = resData.booking_start_time.split(":");
          const endTimeParts = resData.booking_end_time.split(":");

          let bookingDate = new Date();

          if (resData.booking_date) {
            let bd = resData.booking_date.split("-");

            if (bd.length === 3) {
              bookingDate = new Date(bd[1] + "-" + bd[0] + "-" + bd[2]);
            }
          }
          bookings.push({
            id: resData.id,
            title: resData.event_name,
            start: new Date(
              bookingDate.getFullYear(),
              bookingDate.getMonth(),
              bookingDate.getDate(),
              startTimeParts[0],
              startTimeParts[1],
              0
            ),
            end: new Date(
              bookingDate.getFullYear(),
              bookingDate.getMonth(),
              bookingDate.getDate(),
              endTimeParts[0],
              endTimeParts[1],
              0
            ),
            type: resData.type,
          });
        });
        setEvents(bookings);
      },
      onError: (error) => {
        notification.error("An error occured during save, Please try later");
        setPageLoading(false);
        console.log("Error ", error);
      },
    });
  };

  /**
   * Get booking for calendar
   * @param {*} selectedMonth
   * @param {*} selectedYear
   */
  const getBookingsForCalendar = (
    selectedMonth = null,
    selectedYear = null
  ) => {
    const { getCalendarOrListBookings, filter } = props;
    setPageLoading(true);

    let filtersData = {};

    if (selectedMonth && selectedYear) {
      filtersData.selected_month = selectedMonth;
      filtersData.selected_year = selectedYear;
    }

    getCalendarOrListBookings({
      data: {
        is_calendar_view: "E",
        business_id: getAdminBusinessId(),
        filter: {
          ...filtersData,
          ...filter.searchFilters,
        },
      },
      onSuccess: (response) => {
        setPageLoading(false);
        let bookings = [];
        response.forEach((resData) => {
          const startTimeParts = resData.booking_start_time.split(":");
          const endTimeParts = resData.booking_end_time.split(":");

          let bookingDate = new Date();

          if (resData.booking_date) {
            let bd = resData.booking_date.split("-");

            if (bd.length === 3) {
              bookingDate = new Date(bd[1] + "-" + bd[0] + "-" + bd[2]);
            }
          }
          bookings.push({
            id: resData.id,
            title: resData.event_name,
            start: new Date(
              bookingDate.getFullYear(),
              bookingDate.getMonth(),
              bookingDate.getDate(),
              startTimeParts[0],
              startTimeParts[1],
              0
            ),
            end: new Date(
              bookingDate.getFullYear(),
              bookingDate.getMonth(),
              bookingDate.getDate(),
              endTimeParts[0],
              endTimeParts[1],
              0
            ),
            type: resData.type,
          });
        });
        setEvents(bookings);
      },
      onError: (error) => {
        notification.error("An error occured during save, Please try later");
        setPageLoading(false);
        console.log("Error ", error);
      },
    });
  };

  return (
    <div className="eventdetailsaddbox rd_noshadow">
      {showViewDetailModal && (
        <ViewDetailModal
          bookingId={bookingId}
          showViewDetailModal={showViewDetailModal}
          handleClose={hideViewDetailModal}
          loadBookings={getBookingsForCalendar}
        />
      )}
      <Loader isShowLoader={pageLoading} />
      <Filter
        name="My Calendar"
        onFilterRecords={filterRecords}
        onClearFilterHandler={clearFilterHandler}
      >
        <MyCalendarFilters />
      </Filter>
      <Calendar
        views={["month", "week", "day"]}
        date={calendarDate}
        events={events}
        onSelectEvent={(event) => {
          console.log("event clicked", event);
          setBookingId(event.id);
          if (event.type !== "adhoc") {
            setShowViewDetailModal(true);
          }
        }}
        onNavigate={(date, action) => {
          const dated = moment(date);
          const month = dated.format("MM");
          //const day = dated.format("DD");
          const year = dated.format("YYYY");
          selectedMonth.current = month;
          selectedYear.current = year;
          getBookingsForCalendar(month, year);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  getCalendarOrListBookings,
  toggleFilter,
})(MyCalendar);
