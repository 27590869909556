


export const PlanManager_START = 'PlanManager_START';
export const PlanManager_END = 'PlanManager_END';
export const PlanManager_ERROR = 'PlanManager_ERROR';
export const PlanManager_SUCCESS = 'PlanManager_SUCCESS';
export const PlanManager_FAIL = 'PlanManager_FAIL';
export const IsDataSubmitedSuccessfully='IsDataSubmitedSuccessfully';

export const PlanManager_DD_SUCCESS = 'PlanManager_DD_SUCCESS';