import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getCalendarOrListBookings } from "../../../actions/admin/my-calendar-actions";
import { getLoggedInUserId } from "../../../utils/authHelper";
import { notification } from "../../../utils/utility";
import Loader from "../../../components/Loader/Loader";
import Filter from "../../../components/Filter/Filter";
import Calendar from "../../../components/Calendar/Calendar";
import ViewDetailModal from "./view-details-modal";

const StaffCalendar = (props) => {
  const [pageLoading, setPageLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [calendarDate, setCalendarDate] = useState(null);
  const [showViewDetailModal, setShowViewDetailModal] = useState(false);
  const [bookingId, setBookingId] = useState(null);

  /**
   * hide modal
   */
  const hideViewDetailModal = () => {
    setShowViewDetailModal(false);
  };

  useEffect(() => {
    getBookingsForCalendar();
  }, []);

  /**
   * Get booking for calendar
   * @param {*} selectedMonth
   * @param {*} selectedYear
   */
  const getBookingsForCalendar = (
    selectedMonth = null,
    selectedYear = null
  ) => {
    const { getCalendarOrListBookings } = props;
    setPageLoading(true);

    let filtersData = {};

    if (selectedMonth && selectedYear) {
      filtersData.selected_month = selectedMonth;
      filtersData.selected_year = selectedYear;
    }

    filtersData.staff = [{ title: "", value: getLoggedInUserId() }];

    getCalendarOrListBookings({
      data: {
        is_calendar_view: "E",
        filter: {
          ...filtersData,
        },
      },
      onSuccess: (response) => {
        setPageLoading(false);
        let bookings = [];
        response.forEach((resData) => {
          const startTimeParts = resData.booking_start_time.split(":");
          const endTimeParts = resData.booking_end_time.split(":");

          let bookingDate = new Date();

          if (resData.booking_date) {
            let bd = resData.booking_date.split("-");

            if (bd.length === 3) {
              bookingDate = new Date(bd[1] + "-" + bd[0] + "-" + bd[2]);
            }
          }
          bookings.push({
            id: resData.id,
            title: resData.event_name,
            start: new Date(
              bookingDate.getFullYear(),
              bookingDate.getMonth(),
              bookingDate.getDate(),
              startTimeParts[0],
              startTimeParts[1],
              0
            ),
            end: new Date(
              bookingDate.getFullYear(),
              bookingDate.getMonth(),
              bookingDate.getDate(),
              endTimeParts[0],
              endTimeParts[1],
              0
            ),
            type: resData.type,
          });
        });
        setEvents(bookings);
      },
      onError: (error) => {
        notification.error("An error occured during save, Please try later");
        setPageLoading(false);
        console.log("Error ", error);
      },
    });
  };

  return (
    <>
      <Loader isShowLoader={pageLoading} />
      <Filter name="My Calendar" />

      {showViewDetailModal && (
        <ViewDetailModal
          bookingId={bookingId}
          showViewDetailModal={showViewDetailModal}
          handleClose={hideViewDetailModal}
          loadBookings={getBookingsForCalendar}
        />
      )}

      <Calendar
        views={["month", "week", "day"]}
        date={calendarDate}
        events={events}
        onSelectEvent={(event) => {
          console.log("event", event);
          setBookingId(event.id);
          if (event.type !== "adhoc") {
            setShowViewDetailModal(true);
          }
        }}
        // onSelectEvent={(event) => {
        //   console.log("event clicked", event);
        //   setBookingId(event.id);
        //   if (event.type !== "adhoc") {
        //     setShowViewDetailModal(true);
        //   }
        // }}
        // onNavigate={(date, action) => {
        //   const dated = moment(date);
        //   const month = dated.format("MM");
        //   const day = dated.format("DD");
        //   const year = dated.format("YYYY");
        //   getBookingsForCalendar("", month, year);
        // }}
      />
    </>
  );
};

export default connect(null, {
  getCalendarOrListBookings,
})(StaffCalendar);
