import ExchangeCalendarIcon from "../../../assets/icons/exchange-calendar.svg";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";

const ExchangeCalendar = () => {
    return (
        <Col md="6" xxl="3" xl="4">
            <Link to="all-integrations" className="int-col">
            <div className="img">
                <img
                src={ExchangeCalendarIcon}
                alt="Google Calendar"
                />
            </div>
            <div className="text">
                <h3>Exchange Calendar</h3>
                <p>
                Lorem Ipsum is simply dummy text of the
                printing an typesetting industry.
                </p>
            </div>
            </Link>
        </Col>
    );
}

export default ExchangeCalendar;