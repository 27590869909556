import React from "react";

import { encryptUserRole } from "../../utils/authHelper";
import CryptoJS from "crypto-js";
// Admin
import UserType from "../../views/auth/UserType";
import Dashboard from "../../views/admin/dashboard/Dashboard";
import AdminProfile from "../../views/admin/profile/index";
import AdminCompany from "../../views/admin/settings/Company";
import AdminGeneral from "../../views/admin/settings/General";
import AdminAppearance from "../../views/admin/settings/Appearance";
import AdminPayment from "../../views/admin/settings/Payment";
import AdminEmailNotification from "../../views/admin/settings/EmailNotification";
import AdminEmailTemplate from "../../views/admin/settings/EmailTemplate";
import AdminSMSNotification from "../../views/admin/settings/SMSNotification";
import AdminSMSTemplate from "../../views/admin/settings/SMSTemplate";
import AdminFrequentlyDiscount from "../../views/admin/settings/FrequentlyDiscount";
import Discount from "../../views/admin/settings/Discount";
import AdminFrontTooltips from "../../views/admin/settings/FrontTooltips";
import AdminFormFields from "../../views/admin/settings/FormFields";
import AdminSEO from "../../views/admin/settings/SEO";
import AdminGoogleCalendar from "../../views/admin/settings/GoogleCalendar";

//import Events from "../views/admin/my-booking/Events";
import EventSettngs from "../../views/admin/settings/EventSettings";
import EmailSettngs from "../../views/admin/settings/EmailSettings";
import MyOctoLink from "../../views/admin/settings/MyOctoLink";
import Schedule from "../../views/admin/settings/Schedule/index";
import Vacation from "../../views/admin/settings/Vacation/index";
import AdminManageSubscription from "../../views/admin/subscription/index";
import CalendarIntegration from "../../views/admin/calendar-integrations";

import AdminScheduledEvents from "../../views/admin/schedule/scheduled-events";
import AdminMyCalendar from "../../views/admin/my-calendar/index";

import BookingHistory from "../../views/admin/settings/Staff/BookingHistory";
import CustomerAppointment from "../../views/admin/settings/Staff/CustomerAppointment";
//import StaffAppointment from "../views/admin/settings/Staff/StaffAppointment";

import StaffListing from "../../views/admin/settings/Staff/index";
import StaffAdd from "../../views/admin/settings/Staff/Create";
import StaffEdit from "../../views/admin/settings/Staff/Edit";
//import CrmMain from "../views/admin/settings/CRM/CrmMain";

import CrmPayments from "../../views/admin/crm/payments/index";
import AdminCustomerListing from "../../views/admin/crm/customer/index";
//import AdminStaffSchedule from "../views/admin/settings/Staff/Schedule/index";
//import MainPayment from "../views/admin/settings/CRM/Payments/MainPayment";

//import StaffPayment from "../views/admin/settings/CRM/Payments/Staff/StaffPayment";

import QuickSetupStepOne from "../../views/admin/quick-setup/step-one";
import QuickSetupStepTwo from "../../views/admin/quick-setup/step-two";
import QuickSetupStepThree from "../../views/admin/quick-setup/step-three";

//import ScheduleBackup from "../views/admin/settings/Schedule-backup/index";

// Staff
import StaffDashboard from "../../views/staff/dashboard/Dashboard";
import Calendar from "../../views/staff/Calendar";
import StaffSchedule from "../../views/staff/Schedule/index";
import StaffBookingHistory from "../../views/staff/BookingHistory/index";

// Super Admin
import SuperAdminDashboard from "../../views/super-admin/dashboard/Dashboard";
import SubcriberDetailsMain from "../../views/super-admin/SubscriberDetails/SubcriberDetailsMain";
import PlanManager from "../../views/super-admin/PlanManager/PlanManager";
import ReferalProgram from "../../views/super-admin/ReferalProgram/ReferalProgram";
import SuperAdminPromo from "../../views/super-admin/Promo/index";
import Tooltip from "../../views/super-admin/tooltip/index";

// Customer
import CustomerDashboard from "../../views/customer/dashboard/Dashboard";

// Events

import EventTypeList from "../../views/events/list-events-types";
import AddNewEvent from "../../views/events/add-new-event";
import AddNewEventDetails from "../../views/events/add-new-event-details";
import AddNewEventAvailability from "../../views/events/add-new-event-availbility";
import AddNewEventAdvanced from "../../views/events/add-new-event-advanced";
import AddAdHocComponent from "../../views/events/add-ad-hoc-event";
import EventSuccessPage from "../../views/events/event-success-page";
import ListEventsView from "../../views/events/list-view-events";
//import MyCalendar from "../views/admin/settings/MyCalender/MyCalendar";

import AdminRefferalList from "../../views/admin/settings/AdminRefferal/AdminRefferalList";
import NotificationSettingsTemplate from "../../components/Notifications/NotificationSettings";
import AllNotifications from "../../components/Notifications/AllNotifications";
import Notifications from "../../views/admin/notifications";
import NotificationSettings from "../../views/admin/notifications/settings";
import EventSummary from "../../components/EventSummary/EventSummary";

import AdminEventSummary from "../../views/events/event-summary";

//import CustomCalendar from "../views/admin/my-calendar-backup/calendar-view";
import TooltipManagment from "../../components/TooltipManage/TooltipManagment";
import CancelEvent from "../../views/events/CancelEvent";
import EventNotavail from "../../views/events/EventNotavail";
import AllIntegrations from "../../views/admin/all-integrations/index";
import NewIntegrations from "../../views/admin/new-integration/index";



export const USER_TYPES = {
  admin: encryptUserRole("admin").toString(CryptoJS.enc.Hex),
  customer: encryptUserRole("customer").toString(CryptoJS.enc.Hex),
  superAdmin: encryptUserRole("super-admin").toString(CryptoJS.enc.Hex),
  staff: encryptUserRole("staff").toString(CryptoJS.enc.Hex),
};

const routes = [
  {
    component: UserType,
    exact: true,
    path: "/user-type",
    userType: USER_TYPES.admin,
  },
  // admin routes
  {
    component: Dashboard,
    exact: true,
    path: "/admin/dashboard",
    userType: USER_TYPES.admin,
  },
  // admin routes
  {
    component: AdminEventSummary,
    exact: true,
    path: "/admin/event-summary/:id",
    userType: USER_TYPES.admin,
  },
  {
    component: EventSummary,
    exact: true,
    path: "/admin/event-summary",
    userType: USER_TYPES.admin,
  },
  {
    component: TooltipManagment,
    exact: true,
    path: "/admin/tooltip-managment",
    userType: USER_TYPES.admin,
  },
  {
    component: Tooltip,
    exact: true,
    path: "/admin/tooltip",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() => import("../views/admin/profile/Profile")),
    component: AdminProfile,
    exact: true,
    path: "/admin/profile",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() => import("../views/admin/settings/Company")),
    component: AdminCompany,
    exact: true,
    path: "/admin/settings/company",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() => import("../views/admin/settings/General")),
    component: AdminGeneral,
    exact: true,
    path: "/admin/settings/general",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() => import("../views/admin/settings/Appearance")),
    component: AdminAppearance,
    exact: true,
    path: "/admin/settings/appearance",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() => import("../views/admin/settings/Payment")),
    component: AdminPayment,
    exact: true,
    path: "/admin/settings/payment",
    userType: USER_TYPES.admin,
  },
  {
    component: AdminEmailNotification,
    //component: React.lazy(() =>
    //  import("../views/admin/settings/EmailNotification")
    //),
    exact: true,
    path: "/admin/settings/email-notifications",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() =>
    //  import("../views/admin/settings/EmailTemplate")
    //),
    component: AdminEmailTemplate,
    exact: true,
    path: "/admin/settings/email-template",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() =>
    //  import("../views/admin/settings/SMSNotification")
    //),
    component: AdminSMSNotification,
    exact: true,
    path: "/admin/settings/sms-notifications",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() => import("../views/admin/settings/SMSTemplate")),
    component: AdminSMSTemplate,
    exact: true,
    path: "/admin/settings/sms-template",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() =>
    //  import("../views/admin/settings/FrequentlyDiscount")
    // ),
    component: AdminFrequentlyDiscount,
    exact: true,
    path: "/admin/settings/frequently-discount",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() => import("../views/admin/settings/Promocode")),
    component: Discount,
    exact: true,
    path: "/admin/settings/discounts",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() => import("../views/admin/settings/Promocode")),
    component: CalendarIntegration,
    exact: true,
    path: "/admin/calendar-integration",
    userType: USER_TYPES.admin,
  },

  {
    //component: React.lazy(() =>
    //  import("../views/admin/settings/FrontTooltips")
    // ),
    component: AdminFrontTooltips,
    exact: true,
    path: "/admin/settings/front-tooltips",
    userType: USER_TYPES.admin,
  },
  {
    component: React.lazy(() => import("../../views/admin/settings/FormFields")),
    exact: true,
    path: "/admin/settings/form-fields",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() =>
    //  import("../views/admin/settings/RecurrenceBooking")
    //),
    component: AdminFormFields,
    exact: true,
    path: "/admin/settings/recurrence-booking",
    userType: USER_TYPES.admin,
  },

  {
    component: AdminManageSubscription,
    exact: true,
    path: "/admin/manage-subscription",
    userType: USER_TYPES.admin,
  },

  {
    component: QuickSetupStepOne,
    exact: true,
    path: "/admin/quick-setup-step-one",
    userType: USER_TYPES.admin,
  },

  {
    component: QuickSetupStepTwo,
    exact: true,
    path: "/admin/quick-setup-step-two",
    userType: USER_TYPES.admin,
  },

  {
    component: QuickSetupStepThree,
    exact: true,
    path: "/admin/quick-setup-step-three",
    userType: USER_TYPES.admin,
  },

  {
    component: AdminScheduledEvents,
    exact: true,
    path: "/admin/scheduled-events",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() => import("../views/admin/settings/SEO")),
    component: AdminSEO,
    exact: true,
    path: "/admin/settings/seo",
    userType: USER_TYPES.admin,
  },
  /*
  {
    component: Integrations,
    exact: true,
    path: "/admin/integrations",
    userType: USER_TYPES.admin,
  },
  */
  /*
          {
            component: Events,
            exact: true,
            path: "/admin/my-bookings",
            userType: USER_TYPES.admin,
          },
          */

  // staff routes
  {
    component: StaffDashboard,
    exact: true,
    path: "/staff/dashboard",
    userType: USER_TYPES.staff,
  },

  // super admin routes
  {
    component: SuperAdminDashboard,
    exact: true,
    path: "/super-admin/dashboard",
    userType: USER_TYPES.superAdmin,
  },

  {
    component: SubcriberDetailsMain,
    exact: true,
    path: "/super-admin/subscriber",
    userType: USER_TYPES.superAdmin,
  },

  {
    component: PlanManager,
    exact: true,
    path: "/super-admin/plan-manager",
    userType: USER_TYPES.superAdmin,
  },

  {
    component: ReferalProgram,
    exact: true,
    path: "/super-admin/referral-program",
    userType: USER_TYPES.superAdmin,
  },

  {
    component: SuperAdminPromo,
    exact: true,
    path: "/super-admin/promos",
    userType: USER_TYPES.superAdmin,
  },

  // customer routes
  {
    component: CustomerDashboard,
    exact: true,
    path: "/customer/dashboard",
    userType: USER_TYPES.customer,
  },
  {
    component: EventSettngs,
    exact: true,
    path: "/admin/settings/events",
    userType: USER_TYPES.admin,
  },
  {
    component: EmailSettngs,
    exact: true,
    path: "/admin/settings/email-settings",
    userType: USER_TYPES.admin,
  },
  {
    component: MyOctoLink,
    exact: true,
    path: "/admin/settings/my-octo-link",
    userType: USER_TYPES.admin,
  },
  {
    component: AdminMyCalendar,
    exact: true,
    path: "/admin/my-calendar",
    userType: USER_TYPES.admin,
  },
  {
    component: Schedule,
    //component: ScheduleBackup,
    exact: true,
    path: "/admin/settings/schedule",
    userType: USER_TYPES.admin,
  },
  {
    component: Vacation,
    exact: true,
    path: "/admin/vacation",
    userType: USER_TYPES.admin,
  },
  {
    component: BookingHistory,
    exact: true,
    path: "/admin/staff/booking-history",
    userType: USER_TYPES.admin,
  },
  /*
        {
          component: CustomCalendar,
          exact: true,
          path: "/admin/custom-calendar",
          userType: USER_TYPES.admin,
        },
        */
  /*
        {
          component: AdminStaffSchedule,
          exact: true,
          path: "/admin/staff/schedule",
          userType: USER_TYPES.admin,
        },
        */

  {
    component: StaffListing,
    exact: true,
    path: "/admin/staff",
    userType: USER_TYPES.admin,
  },
  {
    component: StaffAdd,
    exact: true,
    path: "/admin/staff/create",
    userType: USER_TYPES.admin,
  },
  {
    component: StaffEdit,
    exact: true,
    path: "/admin/staff/edit/:id",
    userType: USER_TYPES.admin,
  },

  /*
          {
            component: DirectoryInformationView,
            exact: true,
            path: "/admin/settings/staff/directory-information/view/:id",
            userType: USER_TYPES.admin,
          },
          {
            component: DirectoryInformationEdit,
            exact: true,
            path: "/admin/settings/staff/directory-information/edit/:id",
            userType: USER_TYPES.admin,
          },
          */
  /*
          {
            component: CrmMain,
            exact: true,
            path: "/admin/settings/crm",
            userType: USER_TYPES.admin,
          },
          */

  {
    component: AdminCustomerListing,
    exact: true,
    path: "/admin/customer",
    userType: USER_TYPES.admin,
  },
  {
    component: CrmPayments,
    exact: true,
    path: "/admin/booking-history",
    userType: USER_TYPES.admin,
  },

  {
    //component: React.lazy(() => import("../views/admin/profile/Profile")),
    component: AdminProfile,
    exact: true,
    path: "/customer/profile",
    userType: USER_TYPES.customer,
  },
  {
    //component: React.lazy(() => import("../views/admin/profile/Profile")),
    component: CustomerAppointment,
    exact: true,
    path: "/customer/booking",
    userType: USER_TYPES.customer,
  },

  {
    //component: React.lazy(() => import("../views/admin/profile/Profile")),
    component: AdminProfile,
    exact: true,
    path: "/staff/profile",
    userType: USER_TYPES.staff,
  },

  {
    component: StaffSchedule,
    exact: true,
    path: "/staff/schedule",
    userType: USER_TYPES.staff,
  },
  {
    //component: React.lazy(() => import("../views/admin/profile/Profile")),
    component: StaffBookingHistory,
    exact: true,
    path: "/staff/booking-history",
    userType: USER_TYPES.staff,
  },

  /*
        {
          //component: React.lazy(() => import("../views/admin/profile/Profile")),
          component: StaffAppointment,
          exact: true,
          path: "/staff/booking",
          userType: USER_TYPES.staff,
        },
        */
  /*
        {
          //component: React.lazy(() => import("../views/admin/profile/Profile")),
          component: StaffPayment,
          exact: true,
          path: "/staff/payment",
          userType: USER_TYPES.staff,
        },
        */
  {
    //component: React.lazy(() => import("../views/admin/profile/Profile")),
    component: Calendar,
    exact: true,
    path: "/staff/calendar",
    userType: USER_TYPES.staff,
  },
  {
    component: EventTypeList,
    exact: true,
    path: "/admin/events/create/list",
    userType: USER_TYPES.admin,
  },

  {
    component: AddNewEvent,
    exact: true,
    path: "/admin/events/create",
    userType: USER_TYPES.admin,
  },
  {
    component: AddNewEvent,
    exact: true,
    path: "/admin/events/:id/create",
    userType: USER_TYPES.admin,
  },
  {
    component: AddNewEventDetails,
    exact: true,
    path: "/admin/events/create/:id/step-2",
    userType: USER_TYPES.admin,
  },
  {
    component: AddNewEventAvailability,
    exact: true,
    path: "/admin/events/create/:id/step-3",
    userType: USER_TYPES.admin,
  },
  {
    component: AddNewEventAdvanced,
    exact: true,
    path: "/admin/events/create/:id/step-4",
    userType: USER_TYPES.admin,
  },

  {
    component: AddAdHocComponent,
    exact: true,
    path: "/admin/events/create/ad-hoc",
    userType: USER_TYPES.admin,
  },

  {
    component: AddAdHocComponent,
    exact: true,
    path: "/admin/events/create/:id/ad-hoc",
    userType: USER_TYPES.admin,
  },

  {
    component: EventSuccessPage,
    exact: true,
    path: "/admin/events/success",
    userType: USER_TYPES.admin,
  },
  {
    component: ListEventsView,
    exact: true,
    path: "/admin/events/list",
    userType: USER_TYPES.admin,
  },

  {
    //component: React.lazy(() =>
    //  import("../views/admin/settings/SMSNotification")
    //),
    component: AdminRefferalList,
    exact: true,
    path: "/admin/settings/admin-refferal",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() => import("../views/admin/profile/Profile")),
    component: NotificationSettings,
    exact: true,
    path: "/admin/notification-settings",
    userType: USER_TYPES.admin,
  },
  // {
  //   component: NotificationSettingsTemplate,
  //   exact: true,
  //   path: "/admin/notification-settings-template",
  //   userType: USER_TYPES.admin,
  // },
  {
    component: Notifications,
    exact: true,
    path: "/admin/notifications",
    userType: USER_TYPES.admin,
  },
  // {
  //   component: AllNotifications,
  //   exact: true,
  //   path: "/admin/all-notifications",
  //   userType: USER_TYPES.admin,
  // },
  {
    //component: React.lazy(() => import("../views/admin/profile/Profile")),
    component: AdminProfile,
    exact: true,
    path: "/super-admin/profile",
    userType: USER_TYPES.superAdmin,
  },
  {
    //component: React.lazy(() => import("../views/admin/profile/Profile")),
    component: CancelEvent,
    exact: true,
    path: "/admin/cancel-event",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() => import("../views/admin/profile/Profile")),
    component: AllIntegrations,
    exact: true,
    path: "/admin/all-integrations",
    userType: USER_TYPES.admin,
  },
  {
    //component: React.lazy(() => import("../views/admin/profile/Profile")),
    component: NewIntegrations,
    exact: true,
    path: "/admin/new-integrations",
    userType: USER_TYPES.admin,
  },
  {
    component: EventNotavail,
    exact: true,
    path: "/admin/event-no-avail",
    userType: USER_TYPES.admin,
  }
];

export default routes;
