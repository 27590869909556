import React from "react";
import { connect } from "react-redux";
import { Alert, FormGroup, Col, Button, Label } from "reactstrap";
import { Formik, Form } from "formik";
import Loader from "../../../components/Loader/Loader";
import * as Yup from "yup";
import { Component } from "react";
import FormField from "../../../components/FormField/FormField";
import DatePickerField from "../../../components/DatePickerField/DatePickerField";
import { addPromo } from "../../../actions/super-admin/promo-actions";
import { getDBFormatedDate, notification } from "../../../utils/utility";

const validation = Yup.object().shape({
  coupon_code: Yup.string().required("Coupon Code is required"),
  coupon_payout_type: Yup.string().required("Coupon type is required"),
  coupon_value: Yup.string().required("Coupon Value is required"),
  coupon_limit: Yup.string().required("Coupon Limit is required"),
  coupon_expiry: Yup.string().required("Coupon Expiry is required"),
});

let initialValues = {
  coupon_code: "",
  coupon_payout_type: "",
  coupon_value: "",
  coupon_limit: "",
  coupon_expiry: "",
};

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errorMessage: "",
      isOpen: false,
    };
  }

  render() {
    const { errorMessage, isLoading } = this.state;

    return (
      <div className="eventdetailsaddbox rd_noshadow">
        <Loader isShowLoader={isLoading} />
        <div className="rd_vacationfilterpart rd_vacationfilterpart3">
          <Formik
            validationSchema={validation}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(data, { resetForm }) => {
              const _this = this;

              const { addPromo } = _this.props;

              let formData = {
                ...data,
                coupon_expiry: getDBFormatedDate(data.coupon_expiry),
              };

              _this.setState({
                isLoading: true,
              });

              addPromo({
                data: {
                  ...formData,
                },
                onSuccess: (response) => {
                  const { data } = response;
                  const { id } = data;
                  if (id) {
                    resetForm();
                    notification.success("Promo added successfully ");
                  }
                  _this.setState({
                    isLoading: false,
                  });
                },
                onError: (error) => {
                  console.log("error", error);
                  _this.setState({
                    isLoading: false,
                  });
                },
              });
            }}
          >
            {(formProps) => {
              const { values, errors, touched, handleChange, setFieldValue } =
                formProps;

              return (
                <Form>
                  <div className="rd_profilerd_erpart">
                    <div className="rd_vacationflex2">
                      <p>Coupon Code *</p>
                      <div className="rd_profilethingco">
                        <FormField
                          type="text"
                          name="coupon_code"
                          placeholder="Coupon Code"
                          value={values.coupon_code}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "coupon_code", value },
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="rd_vacationflex2">
                      <p>Coupon Type *</p>
                      <div className="rd_profilethingco">
                        <FormField
                          placeholder="Coupon Type"
                          showPlaceholder={true}
                          type="select"
                          name="coupon_payout_type"
                          errors={errors}
                          touched={touched}
                          value={values.coupon_payout_type.toString()}
                          options={[
                            { value: "P", label: "Percentage" },
                            { value: "F", label: "Flat" },
                          ]}
                        />
                      </div>
                    </div>

                    <div className="rd_vacationflex2">
                      <p>Coupon Value *</p>
                      <div className="rd_profilethingco">
                        <FormField
                          type="text"
                          name="coupon_value"
                          placeholder="Coupon Value"
                          value={values.coupon_value}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "coupon_value", value },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="rd_profilerd_erpart">
                    <div className="rd_vacationflex2">
                      <p>Coupon Limit *</p>
                      <div className="rd_profilethingco">
                        <FormField
                          type="text"
                          name="coupon_limit"
                          placeholder="Coupon Limit"
                          value={values.coupon_limit}
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "coupon_limit", value },
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="rd_vacationflex2">
                      <FormGroup>
                        <Label for="coupon_expiry">Coupon Expiry *</Label>
                        <DatePickerField
                          errors={errors}
                          touched={touched}
                          name="coupon_expiry"
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <Col lg="12" md="12" sm="12">
                    <Button
                      style={{ marginBottom: "10px" }}
                      type="submit"
                      className="btn btn-app col-md-2 col-lg-2 col-sm-12 float-right"
                    >
                      Save
                    </Button>
                  </Col>

                  <Col lg="12" md="12" sm="12">
                    &nbsp;
                  </Col>

                  <div className="event-card-footer">
                    {errorMessage !== "" && (
                      <Alert color="danger" className="mt-4 text-center p-10">
                        {errorMessage}
                      </Alert>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  addPromo,
})(Create);
