import axios from "../../axios-instance";
import { getAdminBusinessId } from "../../utils/authHelper";
import { fallBackErrorMessage } from "../../constants/event";
import { gotProperty } from "../../utils/utility";

/**
 * pending events
 * @param {*} options
 * @returns
 */
export const pendingEvents = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post(`admin/scheduled-pending-events/${getAdminBusinessId()}`, data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * pending events
 * @param {*} options
 * @returns
 */
export const staffPendingEvents = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post(`staff/scheduled-pending-events`, data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
};

/**
 * request invitee to reschedule booking
 * @param {*} options
 * @returns
 */
export const requestInviteeToRescheduleAction =
  (options) => async (dispatch) => {
    const { data, onSuccess, onError } = options || {};
    const { id } = data || {};

    axios
      .get(`/admin/request-invitee-to-reschedule/${id}`)
      .then((res) => {
        const { data: resData } = res || {};
        const { data } = resData || {};

        if (onSuccess) {
          onSuccess(data);
        }
      })
      .catch((err) => {
        if (onError) {
          onError(err);
        }
      });
  };

/**
 * upcoming events
 * @param {*} options
 * @returns
 */
export const dateRangeEvents = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post(`admin/date-range-events/${getAdminBusinessId()}`, data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * upcoming events
 * @param {*} options
 * @returns
 */
export const upcomingEvents = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post(`admin/scheduled-upcoming-events/${getAdminBusinessId()}`, data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * sete booking status
 * @param {*} options
 * @returns
 */
export const setBookingStatus = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post(`admin/set-booking-status`, data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * upcoming events
 * @param {*} options
 * @returns
 */
export const pastEvents = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post(`admin/scheduled-past-events/${getAdminBusinessId()}`, data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * get booking details
 * @param {*} options
 * @returns
 */
export const getBookingDetails = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { id } = data || {};

  axios
    .get(`/admin/booking-details/${id}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};

      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * cancel events
 * @param {*} options
 * @returns
 */
export const cancelEvent = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};

  // CS - Cancel by Service provider
  data.booking_status = !gotProperty(data, "booking_status")
    ? "CS"
    : data.booking_status;

  axios
    .post("/booking/cancel", data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * cancel events
 * @param {*} options
 * @returns
 */
export const rescheduleEvent = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("/booking/reschedule", data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};
