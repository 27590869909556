import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
} from "reactstrap";
import * as Icon from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../../assets/images/logo-new.svg";
import EmbedCodeModal from "../EmbedCodeModal";
import FeatureRequestModal from "../FeatureRequestModal";
import { connect, useDispatch } from "react-redux";
import {
  IMAGE_BASE_URL,
  notification,
  COMPANY_BASE_URL,
} from "../../../../utils/utility";
import {
  TOGGLE_ADMIN_PROFILE_DROPDOWN,
  TOGGLE_MOB_LEFT_MENU,
} from "../../../../actions/types";
import { getCompanyData } from "../../../../actions/companyActions";
import Loader from "../../../../components/Loader/Loader";
import NewScheduleIcon from "../../../../assets/icons/new_schedule.svg";
import MeetOctoIcon from "../../../../assets/icons/meetocto_icon.svg";
import SettingsMenu from "./settings-menu";
import Notification from "./notification";

const UserDropdown = (props) => {
  const [embedCodeStatus, setEmbedCodeStatus] = useState(false);

  const hideEmbedCodeModal = () => {
    setEmbedCodeStatus(false);
  };

  return (
    <>
      {embedCodeStatus && (
        <EmbedCodeModal hideEmbedCodeModal={hideEmbedCodeModal} />
      )}
      <DropdownMenu>
        <div className="rd_menuthingcont react-tour-step-two">
          <Link to="/admin/profile" className="dropdown-item cus_icons">
            <Icon.User
              size={14}
              className="mr-50"
              color="#0C4767"
              strokeWidth="1.4"
            />
            <span className="align-middle">Profile</span>
          </Link>
          <Link
            to="/admin/manage-subscription"
            className="dropdown-item cus_icons"
          >
            <Icon.Mail
              size={14}
              className="mr-50"
              color="#0C4767"
              strokeWidth="1.4"
            />
            <span className="align-middle">Subscription</span>
          </Link>

          <Link
            to="/admin/settings/company"
            className="dropdown-item cus_icons"
          >
            <Icon.Settings
              size={14}
              className="mr-50"
              color="#0C4767"
              strokeWidth="1.4"
            />
            <span className="align-middle">Setting</span>
          </Link>

          <Link
            to="/admin/calendar-integration"
            className="dropdown-item cus_icons rect line"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 21.8 21.8"
              className="mr-50"
            >
              <g transform="translate(-220.1 -32.1)">
                <g transform="translate(218 31)">
                  <rect
                    width="20"
                    height="18"
                    rx="2"
                    transform="translate(3 4)"
                    fill="none"
                    stroke="#0C4767"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.4"
                  />
                  <line
                    y2="3"
                    transform="translate(18 2)"
                    fill="none"
                    stroke="#0C4767"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.4"
                  />
                  <line
                    y2="3"
                    transform="translate(8 2)"
                    fill="none"
                    stroke="#0C4767"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.4"
                  />
                  <line
                    x2="20"
                    transform="translate(3 9)"
                    fill="none"
                    stroke="#0C4767"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.4"
                  />
                </g>
                <line
                  x2="5"
                  transform="translate(224 43.5)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />
                <line
                  x2="8.5"
                  transform="translate(224 47.5)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />
              </g>
            </svg>
            <span className="align-middle">Calendar Connections</span>
          </Link>

          <span
            className="dropdown-item cus_icons rect"
            onClick={(event) => {
              console.log("working now", event);
              setEmbedCodeStatus(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 24.563 24.563"
              className="mr-50"
            >
              <g
                id="Group_9368"
                data-name="Group 9368"
                transform="translate(-64 -85)"
              >
                <g
                  id="Rectangle_3114"
                  data-name="Rectangle 3114"
                  transform="translate(67.281 88.281)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-width="1.4"
                >
                  <rect width="18" height="18" rx="3" stroke="none" />
                  <rect x="1" y="1" width="16" height="16" rx="2" fill="none" />
                </g>
                <line
                  id="Line_5"
                  data-name="Line 5"
                  y2="2.724"
                  transform="translate(72.333 86)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />
                <line
                  id="Line_13"
                  data-name="Line 13"
                  y2="2.7"
                  transform="translate(65 102.358) rotate(-90)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />
                <line
                  id="Line_16"
                  data-name="Line 16"
                  y2="2.821"
                  transform="translate(84.742 102.358) rotate(-90)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />

                <line
                  id="Line_8"
                  data-name="Line 8"
                  y2="2.478"
                  transform="translate(72.333 106.085)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />
                <line
                  id="Line_6"
                  data-name="Line 6"
                  y2="2.724"
                  transform="translate(76.845 86)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />
                <line
                  id="Line_12"
                  data-name="Line 12"
                  y2="2.7"
                  transform="translate(65 97.845) rotate(-90)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />
                <line
                  id="Line_15"
                  data-name="Line 15"
                  y2="2.821"
                  transform="translate(84.742 97.845) rotate(-90)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />
                <line
                  id="Line_9"
                  data-name="Line 9"
                  y2="2.478"
                  transform="translate(76.845 106.085)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />
                <line
                  id="Line_7"
                  data-name="Line 7"
                  y2="2.724"
                  transform="translate(81.358 86)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />
                <line
                  id="Line_11"
                  data-name="Line 11"
                  y2="2.7"
                  transform="translate(65 93.333) rotate(-90)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />
                <line
                  id="Line_14"
                  data-name="Line 14"
                  y2="2.821"
                  transform="translate(84.742 93.333) rotate(-90)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />
                <line
                  id="Line_10"
                  data-name="Line 10"
                  y2="2.478"
                  transform="translate(81.358 106.085)"
                  fill="none"
                  stroke="#0C4767"
                  stroke-linecap="round"
                  stroke-width="1.4"
                />
                <g
                  id="Group_9367"
                  data-name="Group 9367"
                  transform="translate(70.711 95.025)"
                >
                  <path
                    id="Path_24108"
                    data-name="Path 24108"
                    d="M81.371,57.179l-3.243,1.21,3.243,1.235"
                    transform="translate(-78.128 -56.05)"
                    fill="none"
                    stroke="#0C4767"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.4"
                  />
                  <path
                    id="Path_24110"
                    data-name="Path 24110"
                    d="M78.128,57.179l3.243,1.21-3.243,1.235"
                    transform="translate(-70.231 -56.05)"
                    fill="none"
                    stroke="#0C4767"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.4"
                  />
                  <path
                    id="Path_24109"
                    data-name="Path 24109"
                    d="M84.248,56l-2.265,4.778"
                    transform="translate(-77.471 -56)"
                    fill="none"
                    stroke="#0C4767"
                    stroke-linecap="round"
                    stroke-width="1.4"
                  />
                </g>
              </g>
            </svg>
            <span className="align-middle">Embed Code</span>
          </span>
          <Link
            to="/admin/settings/admin-refferal"
            className="dropdown-item cus_icons"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="18"
              viewBox="0 0 22.694 25.458"
              className="mr-50"
            >
              <g
                id="Group_9380"
                data-name="Group 9380"
                transform="translate(-253.376 -196.913)"
              >
                <path
                  id="Path_24117"
                  data-name="Path 24117"
                  d="M263.054,220.788a3.294,3.294,0,0,1-3.194-3.073h.946a.331.331,0,0,0,.321-.34.359.359,0,0,0-.095-.243l-2.246-2.39a.307.307,0,0,0-.453,0h0l-2.246,2.39a.357.357,0,0,0,0,.483.31.31,0,0,0,.228.1h.976a3.956,3.956,0,0,0,3.836,3.756h1.925a.342.342,0,0,0,0-.683Z"
                  fill="none"
                  stroke="#0C4767"
                  stroke-width="1.4"
                />
                <path
                  id="Path_24118"
                  data-name="Path 24118"
                  d="M254.607,212.355a.332.332,0,0,1-.331-.331V210.7a4.974,4.974,0,0,1,9.947,0v1.327a.333.333,0,0,1-.331.331Z"
                  fill="none"
                  stroke="#0C4767"
                  stroke-miterlimit="10"
                  stroke-width="1.4"
                />
                <path
                  id="Path_24119"
                  data-name="Path 24119"
                  d="M256.265,200.8a2.984,2.984,0,1,1,2.985,2.984A2.984,2.984,0,0,1,256.265,200.8Z"
                  fill="none"
                  stroke="#0C4767"
                  stroke-miterlimit="10"
                  stroke-width="1.4"
                />
                <path
                  id="Path_24120"
                  data-name="Path 24120"
                  d="M265.554,221.471a.331.331,0,0,1-.331-.331v-1.327a4.974,4.974,0,0,1,9.947,0v1.327a.332.332,0,0,1-.331.331Z"
                  fill="none"
                  stroke="#0C4767"
                  stroke-width="1.4"
                />
                <path
                  id="Path_24121"
                  data-name="Path 24121"
                  d="M267.187,209.914a2.984,2.984,0,1,1,2.985,2.984A2.984,2.984,0,0,1,267.187,209.914Z"
                  fill="none"
                  stroke="#0C4767"
                  stroke-width="1.4"
                />
              </g>
            </svg>
            <span className="align-middle">Admin Referral</span>
          </Link>
          <DropdownItem divider />

          <span className="dropdown-item" onClick={props.userLogout}>
            <Icon.Power
              size={14}
              className="mr-50"
              color="#0C4767"
              strokeWidth="1.4"
            />
            <span className="align-middle">Log Out</span>
          </span>
        </div>
      </DropdownMenu>
    </>
  );
};

const AdminTopNavbar = (props) => {
  const navigate = useNavigate();
  const [showUserProfileDropdown, setShowUserProfileDropdown] = useState(false);
  const [featureRequestStatus, setFeatureRequestStatus] = useState(false);
  const [notiDropdownOpen, setNotiDropdownOpen] = useState(false);
  const [notiInnerOpen, setNotiInnerOpen] = useState(false);
  const [onlyUnreadNoti, setOnlyUnreadNoti] = useState(false);
  const [profileDrop, setProfileDrop] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [tipsActive, setTipsActive] = useState(false);
  const [settingsActive, setSettingsActive] = useState(false);
  const notiToggle = () => setNotiDropdownOpen((prevState) => !prevState);
  const notiInnerToggle = () => setNotiInnerOpen((prevState) => !prevState);
  const profileToggle = () => setProfileDrop((prevState) => !prevState);
  const onTipsActive = () => setTipsActive((prevState) => !prevState);
  const onSettingsToggle = () => setSettingsActive((prevState) => !prevState);
  const unreadNotiSelect = () => setOnlyUnreadNoti(true);
  const goToNotiSettings = () => {
    navigate("/admin/notification-settings");
  };

  const dispatch = useDispatch();

  /**
   * hide feature request modal
   */
  const hideFeatureRequestModal = () => {
    setFeatureRequestStatus(false);
  };

  /**
   * toggle user drop down
   */
  const toggleUserDropdown = (event) => {
    const { layout } = props;

    if (!layout.profileDropdownStatus) {
      dispatch({
        type: TOGGLE_ADMIN_PROFILE_DROPDOWN,
        payload: !layout.profileDropdownStatus,
      });
    } else {
      setTimeout(() => {
        dispatch({
          type: TOGGLE_ADMIN_PROFILE_DROPDOWN,
          payload: !layout.profileDropdownStatus,
        });
      }, 500);
    }
  };

  /**
   * on copy company link (meetocto link)
   */
  const onCopyCompanyLink = () => {
    const { getCompanyData } = props;
    setIsLoading(true);
    getCompanyData({
      data: {},
      onSuccess: function (details) {
        setIsLoading(false);
        if (details.url_slug != "") {
          let textArea = document.createElement("textarea");
          textArea.value = COMPANY_BASE_URL + details.url_slug;

          // Avoid scrolling to bottom
          textArea.style.top = "0";
          textArea.style.left = "0";
          textArea.style.position = "fixed";

          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();

          try {
            const status = document.execCommand("copy");

            if (status) {
              notification.success(
                `Company URL Copied ${COMPANY_BASE_URL}${details.url_slug}`
              );
            } else {
              notification.error(
                "Copy command is not supported in your browser."
              );
            }
          } catch (err) {
            notification.error(
              "Copy command is not supported in your browser."
            );
          }
        }
      },
      onError: function (error) {
        setIsLoading(false);
      },
    });
  };
  const { user } = props.auth;
  // console.log(user);
  return (
    <>
      <Modal
        className="quick_tips_popup"
        isOpen={tipsActive}
        toggle={onTipsActive}
        centered={true}
      >
        <ModalBody>
          <div className="d-flex align-items-start">
            <div className="img">
              <img src={MeetOctoIcon} alt="" />
            </div>
            <p>
              This tips and tricks pop up will display here in the center of the
              page wgenver uers login. we may keep it up to 3 months or always.
            </p>
          </div>
          <div className="row justify-content-between mt-4">
            <div className="col-md-6 mb-3 mb-md-0">
              <button className="btn btn-danger" onClick={onTipsActive}>
                Don't show again
              </button>
            </div>
            <div className="col-6 col-md-3">
              <button className="btn rd_cancel">Back</button>
            </div>
            <div className="col-6 col-md-3">
              <button className="btn btn-app">Next</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <header className="rd_headerthing">
        <Loader isShowLoader={isLoading} />
        <div className="topheadertext">
          Your <span>Free</span> trial ends in 10 days
          <Link to="/admin/manage-subscription"> Upgrade now </Link>
        </div>
        <div className="headercont">
          <div className="logocont w-auto d-none d-md-flex align-items-center">
            <a href="#" className="logo">
              <img src={logo} alt="MeetOcto Logo" />
            </a>
          </div>
          <div
            className="mob_menu d-flex align-items-center d-md-none"
            onClick={() => {
              console.log("working now");
              dispatch({
                type: TOGGLE_MOB_LEFT_MENU,
                payload: "active",
              });
            }}
          >
            <Icon.Menu size={20} color="#0C4767" />
          </div>

          {featureRequestStatus && (
            <FeatureRequestModal
              hideFeatureRequestModal={hideFeatureRequestModal}
            />
          )}

          <div className="notificationtabmen">
            <div className="rd_flexrow d-none d-lg-flex">
              <div className="rd_flexrowitem dispnonemobile">
                <div
                  className={`buttonnotgcont react-quicktips ${
                    tipsActive ? "active" : ""
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.489"
                    height="32.903"
                    viewBox="0 0 27.489 32.903"
                  >
                    <path
                      d="M2163.968,748.191a13.741,13.741,0,1,0-8.467,26.145l3.683,5.782a.66.66,0,0,0,.2.2.653.653,0,0,0,.9-.2l3.679-5.782a13.741,13.741,0,0,0,0-26.145Zm-1.911,16.895h-9.939c-.128,0-.234-.223-.234-.5s.105-.5.234-.5h9.939c.128,0,.232.225.232.5S2162.185,765.086,2162.057,765.086Zm5.173-3.958h-14.994c-.194,0-.352-.225-.352-.5s.158-.5.352-.5h14.994c.2,0,.352.224.352.5S2167.426,761.128,2167.23,761.128Zm0-3.959h-14.994c-.194,0-.352-.223-.352-.5s.158-.5.352-.5h14.994c.2,0,.352.223.352.5S2167.426,757.169,2167.23,757.169Z"
                      transform="translate(-2145.989 -747.519)"
                      fill="#8e8e8e"
                    />
                  </svg>
                  <button
                    className="rd_iconthing rd_quicktips"
                    onClick={onTipsActive}
                  >
                    <span>Quick Tips</span>
                  </button>
                </div>
              </div>
              <div className="rd_flexrowitem dispnonemobile">
                <div className="buttonnotgcont react-tour-step-one">
                  <Link
                    to="/admin/quick-setup-step-one"
                    className="buttonnotgcont"
                  >
                    <button className="rd_iconthing rd_quicksetup">
                      <span>Quick setup</span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="rd_flexrowitem dispnonemobile">
                <div className="buttonnotgcont react-tour-step-fourth">
                  <button
                    className="rd_iconthing rd_octolink"
                    onClick={onCopyCompanyLink}
                  >
                    <span>Octo Link</span>
                  </button>
                </div>
              </div>
              <div className="rd_flexrowitem dispnonemobile">
                <div className="buttonnotgcont">
                  <a
                    target="_blank"
                    href="https://meetocto.freshdesk.com/support/home"
                    className="buttonnotgcont"
                  >
                    <button className="rd_iconthing rd_supproticonbtn">
                      <span>Support</span>
                    </button>
                  </a>
                </div>
              </div>
              <div className="rd_flexrowitem dispnonemobile">
                <div className="buttonnotgcont">
                  <button
                    className="rd_iconthing rd_requesticonthtnb"
                    onClick={(event) => {
                      event.preventDefault();
                      setFeatureRequestStatus(true);
                    }}
                  >
                    <span>Ideas</span>
                  </button>
                </div>
              </div>

              <Notification />

              <div className="rd_flexrowitem dispnonemobile">
                <div className="buttonnotgcont react-tour-step-three">
                  <button className="rd_addneewnottopba">
                    <Link to="/admin/events/create/list" className="text-white">
                      <span>Create new</span>
                    </Link>
                  </button>
                </div>
              </div>
              <div className="rd_flexrowitem dispnonemobile">
                <div className="menumobileham">
                  <button className="humbergermenu"></button>
                </div>
                <div className="accountdiccon">
                  <div className="imageaccountnav">
                    {user?.profile_image === "" ? (
                      <>
                        {/* <img src="assets/images/Oval.png" alt="" /> */}
                        <div className="name_initial">
                          {user.first_name.charAt(0) + user.last_name.charAt(0)}
                        </div>
                      </>
                    ) : (
                      <img
                        src={IMAGE_BASE_URL + user?.profile_image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "45%",
                        }}
                      />
                    )}
                  </div>
                  <Dropdown
                    isOpen={props.layout.profileDropdownStatus}
                    toggle={toggleUserDropdown}
                  >
                    <DropdownToggle
                      className="profile_menu_nav p-0"
                      color="none"
                    >
                      <div className="accountnamenavbar">
                        <p>
                          <strong>{`${user?.first_name} ${user?.last_name}`}</strong>
                        </p>
                        <button className="detaiaccountmo">Sign me out</button>
                      </div>
                    </DropdownToggle>
                    <UserDropdown {...props} />
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="rd_flexrow d-lg-none">
              <div className="rd_flexrowitem">
                <div className="buttonnotgcont">
                  {/* <button className="rd_iconthing notbtnnav notifiexist">
                  <span>Notification</span>
                </button> */}
                  <Dropdown
                    isOpen={notiDropdownOpen}
                    toggle={notiToggle}
                    className="profile_dropdown"
                  >
                    <DropdownToggle caret>
                      <div className="noti_num">
                        {props.notification.totalUnread > 9
                          ? "9+"
                          : props.notification.totalUnread}
                      </div>
                      <span className="rd_iconthing notbtnnav notifiexist">
                        {/* Notification */}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* Top Drop Option */}
                      <DropdownItem
                        className="drop_options d-flex align-items-center justify-content-between mb-4"
                        text
                      >
                        <h2>Notificationss</h2>
                        <FormGroup className="ml-auto" check inline>
                          <Input
                            type="checkbox"
                            checked={onlyUnreadNoti}
                            onChange={unreadNotiSelect}
                          />
                          <Label className="mb-1" check>
                            View unread messages
                          </Label>
                        </FormGroup>
                        <Dropdown
                          isOpen={notiInnerOpen}
                          toggle={notiInnerToggle}
                        >
                          <DropdownToggle className="inner_drop_toggle" caret>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#0C4767"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                            </svg>
                          </DropdownToggle>
                          <DropdownMenu className="inner_drop">
                            <DropdownItem>Mark all as read</DropdownItem>
                            <DropdownItem onClick={goToNotiSettings}>
                              Notifications Setting
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </DropdownItem>
                      {/* Top Drop Option End */}

                      {(props.notification.notifications || []).map(
                        (notification, index) => {
                          return (
                            <>
                              <DropdownItem className="notification-item unread">
                                <div className="row no-gutters">
                                  <div className="col-auto noti_img pr-2">
                                    <div className="user_img">
                                      <img
                                        src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg"
                                        alt="User Profile Pic"
                                      />
                                    </div>
                                    <div className="noti_icon">
                                      <img
                                        src={NewScheduleIcon}
                                        alt="Notificion Icon"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-auto noti_txt pl-2">
                                    <h4>{notification.title}</h4>
                                    <p>{notification.description}</p>
                                    <p className="time">An hour ago</p>
                                  </div>
                                </div>
                              </DropdownItem>
                              <DropdownItem divider />
                            </>
                          );
                        }
                      )}

                      <DropdownItem divider />
                      <div className="view_all">
                        <Link to="/admin/notifications">
                          View All Notifications
                        </Link>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div className="rd_flexrowitem">
                <div className="buttonnotgcont text-center mob_sett">
                  <SettingsMenu
                    toggleSettings={onSettingsToggle}
                    settingState={settingsActive}
                  />
                </div>
              </div>
              <div className="rd_flexrowitem">
                <div className="accountdiccon">
                  <div className="imageaccountnav">
                    {user?.profile_image === "" ? (
                      <>
                        {/* <img src="assets/images/Oval.png" alt="" /> */}
                        <div className="name_initial">
                          {user.first_name.charAt(0) + user.last_name.charAt(0)}
                        </div>
                      </>
                    ) : (
                      <img
                        src={IMAGE_BASE_URL + user?.profile_image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "45%",
                        }}
                      />
                    )}
                  </div>
                  <Dropdown
                    isOpen={props.layout.profileDropdownStatus}
                    toggle={toggleUserDropdown}
                  >
                    <DropdownToggle
                      className="profile_menu_nav p-0"
                      color="none"
                    >
                      <div className="accountnamenavbar">
                        <p>
                          <strong>{`${user?.first_name} ${user?.last_name}`}</strong>
                        </p>
                        {/* <button className="detaiaccountmo"></button> */}
                      </div>
                    </DropdownToggle>
                    <UserDropdown {...props} />
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
  notification: state.notification,
});

export default connect(mapStateToProps, { getCompanyData })(AdminTopNavbar);
