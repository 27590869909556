import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as Icon from "react-feather";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const MobBottomBar = () => {
    const location = useLocation();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    return (<>
        <section className="mob_bottom_bar d-lg-none">
            <div className="d-flex justify-content-between">
                <div className="bar-link-home">
                    <Link to={"/admin/dashboard"} className={location.pathname === "/admin/dashboard" ? "active" : ""}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><g transform="translate(-2.009 -2)"><path d="M15.009,22V18.5a1.5,1.5,0,0,0-1.5-1.5h-2a1.5,1.5,0,0,0-1.5,1.5V22Zm-3.5-6h2a2.5,2.5,0,0,1,2.5,2.5v4a.5.5,0,0,1-.5.5h-6a.5.5,0,0,1-.5-.5v-4A2.5,2.5,0,0,1,11.509,16Z" fill="#0c4767" /><path d="M20.045,9.955,12.509,3.173,4.984,9.945a.5.5,0,0,1,.025.156V20.5a1.5,1.5,0,0,0,1.5,1.5h12a1.5,1.5,0,0,0,1.5-1.5V10.1a.314.314,0,0,1,.036-.145Zm.964.867V20.5a2.5,2.5,0,0,1-2.5,2.5h-12a2.5,2.5,0,0,1-2.5-2.5V10.823L2.843,11.872a.5.5,0,1,1-.669-.743l10-9a.5.5,0,0,1,.669,0l10,9a.5.5,0,1,1-.669.743Z" fill="#0c4666" /></g></svg>
                        <p>Dashboard</p>
                    </Link>
                </div>
                <div className="bar-link-octo">
                    <Link to="/#">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.581" height="18.555" viewBox="0 0 19.581 18.555"><g transform="translate(0 -0.048)"><path d="M5.5,11.821V10.344l8.927,4.868-.541,1.182Zm0-3.747L13.887,3.5l.541,1.178L5.5,9.551Z" transform="translate(-0.174 -0.276)" fill="#0c4767" /><path d="M12.887,14.96a3.1,3.1,0,1,1,3.1,3.143A3.1,3.1,0,0,1,12.887,14.96ZM.5,9.3a3.121,3.121,0,1,1,3.1,3.144A3.12,3.12,0,0,1,.5,9.3ZM12.887,3.645a3.1,3.1,0,1,1,3.1,3.1,3.1,3.1,0,0,1-3.1-3.1Z" transform="translate(0 0)" fill="#fff" stroke="#0c4767" stroke-width="1" /></g></svg>
                        <p>Octo Link</p>
                    </Link>
                </div>
                <div className="bar-link-drop">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"up"}>
                        <DropdownToggle>
                            {dropdownOpen ? <Icon.X size={16} color="#fff" /> : <Icon.Plus size={18} color="#fff" />}
                        </DropdownToggle>
                        <DropdownMenu>
                            <div className="first_item">
                                <Link to={"/admin/events/create/ad-hoc"}>
                                    <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="16.129" height="16.953" viewBox="0 0 16.129 16.953"><path id="path" d="M11.6,15.467,14.182,12.9V11.694L11.6,14.257ZM7.3,12.9l2.58,2.566v-1.21L7.3,11.694ZM5.58,11.693V12.9L3,15.467v-1.21Zm8.6-9.4V3.5L11.6,6.065V4.857Zm-6.881,0,2.58,2.564V6.065L7.3,3.5Zm-1.72,0V3.5L3,6.065V4.857Z" transform="translate(-0.527 -0.402)" fill="#0c4767" /><path id="path-2" data-name="path" d="M10.187,13.562a1.7,1.7,0,1,1-1.7,1.7A1.7,1.7,0,0,1,10.187,13.562Zm-8.489,0a1.7,1.7,0,1,1-1.7,1.7,1.7,1.7,0,0,1,1.7-1.7Zm8.489.848a.848.848,0,1,0,.849.848.848.848,0,0,0-.849-.848Zm-8.489,0a.848.848,0,1,0,.849.848A.848.848,0,0,0,1.7,14.41ZM5.942,9.325a1.7,1.7,0,1,1-1.7,1.7,1.7,1.7,0,0,1,1.7-1.7Zm8.489,0a1.7,1.7,0,1,1-1.7,1.7,1.7,1.7,0,0,1,1.7-1.7Zm-8.489.848a.848.848,0,1,0,.849.848.848.848,0,0,0-.849-.848Zm8.489,0a.848.848,0,1,0,.849.848A.848.848,0,0,0,14.431,10.172ZM10.187,4.238a1.7,1.7,0,1,1-1.7,1.7A1.7,1.7,0,0,1,10.187,4.238Zm-8.489,0A1.7,1.7,0,1,1,0,5.934a1.7,1.7,0,0,1,1.7-1.7Zm8.489.848a.848.848,0,1,0,.849.848.848.848,0,0,0-.849-.848Zm-8.489,0a.848.848,0,1,0,.849.848A.848.848,0,0,0,1.7,5.086ZM5.942,0a1.7,1.7,0,1,1-1.7,1.7A1.7,1.7,0,0,1,5.942,0Zm8.489,0a1.7,1.7,0,1,1-1.7,1.7A1.7,1.7,0,0,1,14.431,0ZM5.942.848a.848.848,0,1,0,.849.848A.848.848,0,0,0,5.942.848Zm8.489,0a.848.848,0,1,0,.849.848A.848.848,0,0,0,14.431.848Z" transform="translate(0)" fill="#0c4767" /></svg>
                                    Quick Event
                                </Link>
                            </div>
                            <Link to={"/admin/events/create?type=free-event"}>
                                Free Event
                                <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="17.076" height="14.896" viewBox="0 0 17.076 14.896"><g transform="translate(-1.655 -2.484)"><path d="M18.483,6.483a2.711,2.711,0,0,1,2.9,2.483,2.711,2.711,0,0,1-2.9,2.483h0v-.827a1.9,1.9,0,0,0,2.069-1.655A1.9,1.9,0,0,0,18.483,7.31h0ZM16.414,4a.414.414,0,0,1,.414.414h0v3.31a.414.414,0,1,1-.827,0h0V4.414A.414.414,0,0,1,16.414,4Z" transform="translate(-2.648 -0.862)" fill="#0c4767" /><path d="M6.966,6.069l-2.291.247A2.069,2.069,0,0,0,2.827,8.373v.85A2.069,2.069,0,0,0,4.671,11.28l2.294.255Zm.827-.089v5.647l1.688.187,6.048,1.93a.414.414,0,0,0,.54-.394v-9.1a.414.414,0,0,0-.54-.394L9.482,5.789,9.4,5.806Zm.072,6.482.717,3.979A1.242,1.242,0,0,1,7.36,17.9H6.9a1.242,1.242,0,0,1-1.156-.79L3.688,11.856A2.9,2.9,0,0,1,2,9.223v-.85A2.9,2.9,0,0,1,4.586,5.495l4.683-.507,6.007-1.923A1.242,1.242,0,0,1,16.9,4.246v9.1a1.242,1.242,0,0,1-1.619,1.182L9.309,12.618Zm-3.188-.348,1.835,4.7a.414.414,0,0,0,.385.263H7.36a.414.414,0,0,0,.407-.487l-.76-4.221-2.331-.255Z" transform="translate(-0.345 -0.521)" fill="#0c4767" /></g></svg>
                            </Link>
                            <Link to={"/admin/events/create?type=paid-event"}>
                                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="17.676" height="17.676" viewBox="0 0 17.676 17.676"><path d="M12.724,8.884h1.32a.454.454,0,0,1,.454.455v.344a.454.454,0,0,1-.895.11H11.376a.454.454,0,0,0-.454.454V10.7a.454.454,0,0,0,.454.454h1.8a1.363,1.363,0,0,1,1.363,1.363v.454a1.363,1.363,0,0,1-1.363,1.363h-.454v.446a.454.454,0,0,1-.908,0v-.45H10.453A.454.454,0,0,1,10,13.866l.013-.416a.454.454,0,0,1,.907-.024H13.18a.454.454,0,0,0,.454-.454v-.454a.454.454,0,0,0-.454-.454H11.375A1.363,1.363,0,0,1,10.011,10.7v-.454a1.363,1.363,0,0,1,1.363-1.363h.442V8.43a.454.454,0,1,1,.908,0Z" transform="translate(-3.433 -3.17)" fill="#0c4767" /><path d="M10.838,2A8.838,8.838,0,1,1,2,10.838,8.838,8.838,0,0,1,10.838,2Zm0,.842a8,8,0,1,0,8,8,8,8,0,0,0-8-8Zm0,1.683a6.313,6.313,0,1,1-6.313,6.313A6.313,6.313,0,0,1,10.838,4.525Zm0,.842a5.471,5.471,0,1,0,5.471,5.471,5.471,5.471,0,0,0-5.471-5.471Z" transform="translate(-2 -2)" fill="#0c4767" /></svg>
                                Paid Event
                            </Link>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className="bar-link-calendar">
                    <Link to={"/admin/my-calendar"} className={location.pathname === "/admin/my-calendar" ? "active" : ""}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 21.8 21.8" class="cust_svg rect line"><g transform="translate(-220.1 -32.1)"><g transform="translate(218 31)"><rect width="20" height="18" rx="2" transform="translate(3 4)" fill="none" stroke="#0C4767" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"></rect><line y2="3" transform="translate(18 2)" fill="none" stroke="#0C4767" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"></line><line y2="3" transform="translate(8 2)" fill="none" stroke="#0C4767" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"></line><line x2="20" transform="translate(3 9)" fill="none" stroke="#0C4767" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"></line></g><line x2="5" transform="translate(224 43.5)" fill="none" stroke="#0C4767" stroke-linecap="round" stroke-width="1.4"></line><line x2="8.5" transform="translate(224 47.5)" fill="none" stroke="#0C4767" stroke-linecap="round" stroke-width="1.4"></line></g></svg>
                        <p>My Calendar</p>
                    </Link>
                </div>
                <div className="bar-link-upgrade">
                    <Link to="/admin/manage-subscription" className={location.pathname === "/admin/manage-subscription" ? "active" : ""}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.928" height="20.84" viewBox="0 0 20.928 20.84" className="upgrade"><g transform="translate(-1506.926 -6454.34)"><g transform="translate(1507.629 6463.58)"><path d="M1520.548,6404.942l4.4-5.5,4.2,5.5" transform="translate(-1520.548 -6399.438)" fill="none" stroke="#0c4767" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" /><path d="M1524.529,6399.438v11.1" transform="translate(-1520.159 -6399.438)" fill="none" stroke="#0c4767" stroke-linecap="round" stroke-width="1" /></g><g transform="translate(1518.554 6463.58)"><path d="M1520.548,6404.942l4.4-5.5,4.2,5.5" transform="translate(-1520.548 -6399.438)" fill="none" stroke="#0c4767" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" /><path d="M1524.529,6399.438v11.1" transform="translate(-1520.159 -6399.438)" fill="none" stroke="#0c4767" stroke-linecap="round" stroke-width="1" /></g><g transform="translate(1512.88 6454.84)"><path d="M1520.548,6404.942l4.4-5.5,4.2,5.5" transform="translate(-1520.548 -6399.438)" fill="none" stroke="#0c4767" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" /><path d="M1524.529,6399.438v11.1" transform="translate(-1520.159 -6399.438)" fill="none" stroke="#0c4767" stroke-linecap="round" stroke-width="1" /></g></g></svg>
                        <p>Upgrade</p>
                    </Link>
                </div>
            </div>
        </section>
    </>);
}

export default MobBottomBar;