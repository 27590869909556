import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Loader from "../../../components/Loader/Loader";
import { Button, Col } from "reactstrap";
import { notification } from "../../../utils/utility";
import { connect } from "react-redux";
import { sendGetRequest, sendPostRequest } from "../../../actions/app-actions";
import { getAdminBusinessId } from "../../../utils/authHelper";
import isEmpty from "../../../validation/is-empty";
import{FormGroup, Label, Input} from "reactstrap";

let initialValues = {
  my_event_scheduled_status: "D",
  my_event_rescheduled_status: "D",
  my_event_cancelled_status: "D",
  my_staff_scheduled_status: "D",
  my_staff_rescheduled_status: "D",
  my_staff_cancelled_status: "D",
  my_staff_confirm_status: "D",
  reminder_meeting_status: "D",
  reminder_not_notify_me_status: "D",
  communication_notify_me_status: "D",
  communication_not_notify_me_status: "D",
  system_alerts_status: "D",
};
const Settings = (props) => {
  const [pageLoading, setPageLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    const { sendGetRequest } = props;

    setPageLoading(true);

    sendGetRequest({
      url: `admin/get-notification-settings/${getAdminBusinessId()}`,
      onSuccess: function (details) {
        const { data } = details;
        console.log("data", data);
        if (!isEmpty(data)) {
          setFormValues({
            ...data,
          });
        }
        setPageLoading(false);
      },
      onError: function (error) {
        setPageLoading(false);
      },
    });
  }, []);

  return (
    <>
      <div className="filter_bar d-flex justify-content-between align-items-center ">
        <h3>Notification Settings</h3>
      </div>
      <Loader isShowLoader={pageLoading}></Loader>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        onSubmit={(values) => {
          const { sendPostRequest } = props;
          setPageLoading(true);

          sendPostRequest({
            url: `/admin/save-notification-settings`,
            data: {
              ...values,
              business_id: getAdminBusinessId(),
            },
            onSuccess: (response) => {
              console.log("response", response);
              if (response.success) {
                notification.success(response.message);
                setFormValues({
                  ...response.data.data,
                });
              }
              setPageLoading(false);
              console.log("response", response);
            },
            onError: (error) => {
              notification.error(
                "An error occured during save, Please try later"
              );
              setPageLoading(false);
              console.log("company error ", error);
            },
          });
        }}
      >
        {(formProps) => {
          const { values, errors, touched, handleChange, setFieldValue } =
            formProps;

          return (
            <Form>
              <section className="notif_settings">
                <div className="setting_field">
                  <div className="setting_name">
                    <h4>My Events</h4>
                    <p>
                      Get notified when someone books time with you, reschedules
                      event, or cancels event.
                    </p>
                  </div>
                  <div className="toggle_fields">
                    <div className="toggle_field">
                      <div className="box_content p-0">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={
                              values.my_event_scheduled_status === "E"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "my_event_scheduled_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label cus_label"
                            htmlFor="flexSwitchCheckChecked1"
                          >
                            Scheduled
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="toggle_field">
                      <div className="box_content p-0">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked2"
                            checked={
                              values.my_event_rescheduled_status === "E"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "my_event_rescheduled_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label cus_label"
                            htmlFor="flexSwitchCheckChecked2"
                          >
                            Rescheduled
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="toggle_field">
                      <div className="box_content p-0">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked4"
                            checked={
                              values.my_event_cancelled_status === "E"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "my_event_cancelled_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label cus_label"
                            htmlFor="flexSwitchCheckChecked4"
                          >
                            Cancelled
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Divider */}
                <div className="divider"></div>
                <div className="setting_field">
                  <div className="setting_name">
                    <h4>My Staff's event</h4>
                    <p>
                      As a staff get notified when you're assigned to an event
                      when invitees schedule, reschedule, or cancel an event.
                    </p>
                  </div>
                  <div className="toggle_fields">
                    <div className="toggle_field">
                      <div className="box_content p-0">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked4"
                            checked={
                              values.my_staff_scheduled_status === "E"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "my_staff_scheduled_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label cus_label"
                            htmlFor="flexSwitchCheckChecked1"
                          >
                            Scheduled
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="toggle_field">
                      <div className="box_content p-0">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked5"
                            checked={
                              values.my_staff_rescheduled_status === "E"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "my_staff_rescheduled_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label cus_label"
                            htmlFor="flexSwitchCheckChecked2"
                          >
                            Rescheduled
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="toggle_field">
                      <div className="box_content p-0">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked6"
                            checked={
                              values.my_staff_cancelled_status === "E"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "my_staff_cancelled_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label cus_label"
                            htmlFor="flexSwitchCheckChecked4"
                          >
                            Cancelled
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="toggle_field">
                      <div className="box_content p-0">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked7"
                            checked={
                              values.my_staff_confirm_status === "E"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "my_staff_confirm_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label cus_label"
                            htmlFor="flexSwitchCheckChecked7"
                          >
                            Confirm
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Divider */}
                <div className="divider"></div>
                {/* Setting Field */}
                <div className="setting_field">
                  <div className="setting_name">
                    <h4>Reminders</h4>
                    <p>Receiver reminders when having meetings soon.</p>
                  </div>
                  <div className="toggle_fields">
                    <div className="toggle_field">
                      <div className="box_content p-0">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked8"
                            checked={
                              values.reminder_meeting_status === "E"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "reminder_meeting_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label cus_label"
                            htmlFor="flexSwitchCheckChecked8"
                          >
                            Meeting reminders
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="toggle_field">
                      <div className="box_content p-0">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked9"
                            checked={
                              values.reminder_not_notify_me_status === "E"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "reminder_not_notify_me_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label cus_label"
                            htmlFor="flexSwitchCheckChecked9"
                          >
                            Do not notify me
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Divider */}
                <div className="divider"></div>
                {/* Setting Field */}
                <div className="setting_field">
                  <div className="setting_name">
                    <h4>Communication</h4>
                    <p>
                      Get notify when there are, exclusive offers, rewards, new
                      features, when your feedback is kindly requested and more.
                    </p>
                  </div>
                  <div className="toggle_fields">
                    <div className="toggle_field">
                      <div className="box_content p-0">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked10"
                            checked={
                              values.communication_notify_me_status === "E"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "communication_notify_me_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label cus_label"
                            htmlFor="flexSwitchCheckChecked10"
                          >
                            Notify me
                            <span>(Recommended)</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="toggle_field">
                      <div className="box_content p-0">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked11"
                            checked={
                              values.communication_not_notify_me_status === "E"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "communication_not_notify_me_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label cus_label"
                            htmlFor="flexSwitchCheckChecked11"
                          >
                            Do not notify me
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Divider */}
                <div className="divider"></div>
                {/* Setting Field */}
                <div className="setting_field">
                  <div className="setting_name">
                    <h4>Alerts</h4>
                    <p>
                      Get important alerts when, an update is needed, payment
                      issues, app migration upgrading needed and more.
                    </p>
                  </div>
                  <div className="toggle_fields">
                    <div className="toggle_field">
                      <div className="box_content p-0">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked10"
                            checked={
                              values.system_alerts_status === "E" ? true : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "system_alerts_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label cus_label"
                            htmlFor="flexSwitchCheckChecked10"
                          >
                            System Alerts
                            <span>(Can not be changed)</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="time-status">
                   <div className="tittle">
                     <h2>Timming</h2>
                   </div>
                    <div className="select-timing">
                    <input type="text" className="form-control mb-3" placeholder="5"/>
                    <FormGroup>
                      <Input id="exampleSelect"name="select" type="select">
                        <option>
                          minute(s)
                        </option>
                        <option>
                          20
                        </option>
                        <option>
                          40
                        </option>
                        <option>
                          50
                        </option>
                        <option>
                          60
                        </option>
                      </Input>
                    </FormGroup>
                         <p>before event</p>
                    </div>
                    <div className="add-reminder">
                    <Button className="add-remider-btn">
                      + Add another reminder
                    </Button>
                    </div>
                    <div className="status-toggle">
                      <h2>Status</h2>
                      <div className="toggle_fields">
                    <div className="toggle_field">
                      <div className="box_content p-0">
                      <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked18"
                            checked={
                              values.reminder_time_status === "E"
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "reminder_time_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label cus_label"
                            htmlFor="flexSwitchCheckChecked18"
                          >
                            On
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                    </div>
                </div>

                <div className="sms-box">
                <FormGroup>
                  <Label for="exampleText">
                    Text Messages
                  </Label>
                  <Input
                    id="exampleText"
                    name="text"
                    type="textarea"
                    placeholder="Reminder:Event Name with MyName at Event Time on Event Date"
                  />
                </FormGroup>
                </div>
              </section>
              <Col sm="12" className="submit_btn">
                <Button
                  type="submit"
                  className="btn btn-app mt-4 col-md-2 col-lg-2 col-sm-12 mb-5"
                >
                  Save
                </Button>
              </Col>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default connect(null, {
  sendGetRequest,
  sendPostRequest,
})(Settings);
