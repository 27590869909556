import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toogleMenu } from "../../../../actions/layoutAction";
import { withRouter } from "../../../../utils/utility";
import * as Icon from "react-feather";
class AdminLeftMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowChild: [false, false, false, false],
      extended: true,
    };
  }

  componentDidMount() {
    this.props.toogleMenu(true);
    this.setState({ extended: true });
  }

  openExtended = (e) => {
    this.props.toogleMenu(true);
    this.setState({ extended: true });
  };
  closeExtended = (e) => {
    this.props.toogleMenu(false);
    this.setState({ extended: false });
  };

  toogleMenu = (idNumber) => {
    var oldArray = this.state.isShowChild;

    if (!this.state.isShowChild[idNumber]) oldArray[idNumber] = true;
    else oldArray[idNumber] = false;

    this.setState({ isShowChild: oldArray });
  };

  render() {
    const { router } = this.props;
    const { location } = router;
    const { pathname } = location;

    return (
      <>
        {!this.state.extended ? (
          <PerfectScrollbar>
            <div className="sidemenu rd_sidemenusmall dnmobile">
              <div className="text-center" role="button">
                <Icon.Maximize2 size={20} onClick={() => this.openExtended()} />{" "}
              </div>

              <div className="ul-listitemmenu rd_listitemmenusmall">
                <a
                  className={
                    "itemcomcon staffNav dashboardmenuicon " +
                    (pathname === "/staff/dashboard" ? "active" : "")
                  }
                ></a>
                <a
                  className={
                    "itemcomcon staffNav schcdulcmenuicon " +
                    (pathname === "/staff/schedule" ? "active" : "")
                  }
                ></a>
                <a
                  className={
                    "itemcomcon staffNav eventcmenuicon " +
                    (pathname === "/staff/booking-history" ? "active" : "")
                  }
                ></a>
                <div className="logoutlinkmenu">
                  <a className="itemcomcon staffNav logoutlinkmenuicon"></a>
                </div>
              </div>
            </div>
          </PerfectScrollbar>
        ) : (
          <PerfectScrollbar>
            <div className="sidemenu dnmobile">
              <div className="ul-listitemmenu">
                <div className="text-right mr-2" role="button">
                  <Icon.Minimize2
                    size={20}
                    onClick={() => this.closeExtended()}
                  />
                </div>
                <Link
                  to={"/staff/dashboard"}
                  className={
                    "itemcomcon staffNav dashboardmenuicon " +
                    (pathname === "/staff/dashboard" ? "active" : "")
                  }
                >
                  My Dashboard
                </Link>
                <Link
                  to="/staff/schedule"
                  className={
                    "itemcomcon staffNav schcdulcmenuicon " +
                    (pathname === "/staff/schedule" ? "active" : "")
                  }
                >
                  Schedule
                </Link>

                <Link
                  to="/staff/booking-history"
                  className={
                    "itemcomcon staffNav eventcmenuicon " +
                    (pathname === "/staff/booking-history" ? "active" : "")
                  }
                >
                  My Booking History
                </Link>

                <div className="logoutlinkmenu">
                  <a
                    href="#"
                    onClick={this.props.userLogout}
                    className="itemcomcon staffNav logoutlinkmenuicon"
                  >
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </PerfectScrollbar>
        )}
      </>
    );
  }
}

const actionMethods = {
  toogleMenu: toogleMenu,
};

export default connect(null, actionMethods)(withRouter(AdminLeftMenu));
