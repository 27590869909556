import React, { Fragment } from "react";

import Header from "./Header";
import Footer from "./Footer";
import { connect } from "react-redux";

const Layout = ({ Component, route, props }) => (
  <Fragment>
    <div className="">
      <Header />
      <section
        className={!props.SectionClass ? "contentboxcont" : props.SectionClass}
      >
        <Component route={route} />
      </section>
      <Footer />
    </div>
  </Fragment>
);

const mapStateToProps = (state) => {
  return {
    SectionClass: state.layout.sectionClass,
  };
};

export default connect(mapStateToProps, null)(Layout);
