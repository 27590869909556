import React, { Component } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import {
  getEventStaffList,
  getVenues,
  saveEventAvailibility,
  getEventAvailability,
} from "../../actions/eventActions";

import {
  Row,
  Col,
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import SidebarProgress from "./Components/Sidebar/sidebar-progress";
import classnames from "classnames";
import TextEditor from "./Components/Common/TextEditorField";
import TimeSelectorField from "./Components/Common/TimeSelectorField";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import qs from "query-string";
import Loader from "../../components/Loader/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import EventAvailbilityComponent from "./Components/Common/Event-Availbility-Component";
import moment from "moment";
import { withRouter, notification } from "../../utils/utility";
import * as Yup from "yup";
import { Collapse } from 'antd';
import "antd/dist/antd.css";
import MultiSelect from "../../components/MultiSelect/index";
import isEmpty from "../../validation/is-empty";
import TimeField from "../../components/Time";
import { InputNumber } from "antd";
const { Panel } = Collapse;



const validationSchema = Yup.object().shape({
  timezone_id: Yup.string().required("Timezone is required."),
});

let initialValues = {
  event_duration_in_minutes: 5,
  invitee_set_duration_status: "D",
  max_event_per_week: 0,
  max_event_per_day: 0,
  buffer_before_event_minutes: 0,
  max_scheduling_notice_minutes: 0,
  min_scheduling_notice_minutes: 0,
  buffer_after_event_minutes: 0,
  slot_duration_increment_of:0,
  staff_assignment_type: "",
  staff_choose: "",
  event_validity: "",
  valid_over_period: "",
  staff_assignment_method: "",
  is_time_block: "D",
  staffs: [],
  timezone_id: "",
  event_start_date: "",
  event_end_date: ""
};

class AddNewEventAvailability extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoading: false,
      pageLoading: false,
      activeTab: "1",
      staff: [],
      selectedStaff: [],
      schedules: [],
      selectedStaffId: null,
      staffForEvent: [],
      selectedStaffs: [],
    };
  }

  /**
   * Get event type
   * @returns 
   */
  getType = () => {
    const { router } = this.props;
    const { location } = router || {};
    const { search } = location || {};
    const locationParse = qs.parse(search);
    const { type } = locationParse || {};
    return type;
  }

  /**
   * Get id
   */
  getId = () => {
    const { router } = this.props;
    const { params } = router || {};
    const { id } = params || {};
    return id;
  }

  /**
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps, prevState) {
    const { selectedStaff } = this.state;
    if (prevState.selectedStaff.length !== selectedStaff.length) {
      this.updateStaffForEvent();
    }
  }

  componentDidMount() {
    const _this = this;
    const {
      getEventStaffList: getLocalStaff,
      getEventAvailability,
    } = this.props;
    const id = _this.getId();
    const businessId = localStorage.getItem("businessId");
    if (id) {
      this.setState({
        pageLoading: true,
      });

      getEventAvailability({
        data: {
          id,
        },
        onSuccess: function (response) {
          initialValues = {
            ...initialValues,
            event_duration_in_minutes: response.event_duration_in_minutes,
            invitee_set_duration_status: response.invitee_set_duration_status,
            is_time_block: response.is_time_block,
            event_internal_notes: response.event_internal_notes,
            max_event_per_week: response.max_event_per_week,
            max_event_per_day: response.max_event_per_day,
            buffer_before_event_minutes: response.buffer_before_event_minutes,
            max_scheduling_notice_minutes:
              response.max_scheduling_notice_minutes,
            min_scheduling_notice_minutes:
              response.min_scheduling_notice_minutes,
            buffer_after_event_minutes: response.buffer_after_event_minutes,
            slot_duration_increment_of: response.slot_duration_increment_of,
            staff_assignment_type: response.staff_assignment_type,
            staff_choose: response.allow_attendee_select_staff === 'E' ? 'allow_invitee' : 'random_choose',
            //event_validity: "",
            //event_valid_period: "",
            staff_assignment_method: response.staff_assignment_method,
            event_validity: response.event_validity,
            valid_over_period: response.valid_over_period,
            event_start_date: response.event_start_date,
            event_end_date: response.event_end_date
            //staffs: [],
          };


          let selectedEventStaffs = [];
          if (response.event_staffs.length) {
            response.event_staffs.forEach((eventStaff, index) => {
              const name = eventStaff.first_name + " " + eventStaff.last_name;
              selectedEventStaffs.push({
                label: name,
                value: eventStaff.id,
              });
            });

            //console.log('selected', selectedEventStaffs);
          }


          _this.setState({
            pageLoading: false,
            selectedStaffs: selectedEventStaffs,
            staffForEvent: response.event_staffs,
            schedules: _this.processSchedulesForListView(response.schedules)
          });


        },
        onError: function (error) {
          _this.setState({
            pageLoading: false,
          });
        },
      });

    }


    getLocalStaff({
      data: {
        businessId,
      },
      onSuccess: (data) => {
        this.setState({
          staff: data,
        });
      },
      onError: (error) => {
        console.log({
          error,
        });
      },
    });
  }

  /**
   * @param Array schedules
   * @return Array
   */
  processSchedulesForListView = (schedules) => {
    let processedSchedules = [];

    processedSchedules = (schedules || []).map((schedule, index) => {

      let scheduleDetails = (schedule.schedule_details).map((scheduleDetail) => {
        if (scheduleDetail.type == "weekly" || scheduleDetail.weekday_id !== null) {
          return {
            dayId: parseInt(scheduleDetail.weekday_id),
            dated: null,
            schedules: [...scheduleDetail.schedule_availability],
          };
        } else if (scheduleDetail.type == "custom") {
          return {
            dayId: null,
            dated: moment(scheduleDetail.dated, "yyyy-MM-DD").format("DD-MM-yyyy") ,
            schedules: [...scheduleDetail.schedule_availability],
          }
        }
      });

      return {
        type: schedule.schedule_type,
        staffId: schedule.staff_id,
        schedules: scheduleDetails,
        timezoneId: schedule.timezone_id
      };
    });

    console.log('process schedules', processedSchedules);

    /*
    processedSchedules = (schedules.schedule_details || []).map(
      (scheduleDetail) => {
        if (scheduleDetail.type == "weekly") {
          return {
            dayId: parseInt(scheduleDetail.weekday_id),
            schedules: [...scheduleDetail.schedule_availability],
          };
        }
      }
    );
    */

    return processedSchedules;
  };


  /**
   * set staff schedule
   * @param {*} updatedSchedules 
   * @param {*} staffId 
   * @param int timezoneId
   */
  setStaffSchedule = (updatedSchedules, staffId, timezoneId) => {
    const _this = this;
    //console.log('updated schedules', updatedSchedules);
    console.log('timezone id', timezoneId);
    const schedules = [..._this.state.schedules];

    console.log('schedules', schedules);

    const found = schedules.some(
      (el) => el.staffId === staffId
    );

    if (found) {

      const staffIndex = schedules.findIndex(
        (el) => el.staffId === staffId
      );

      schedules[staffIndex].schedules = updatedSchedules;
      schedules[staffIndex].timezoneId = timezoneId;
      _this.setState({
        schedules: schedules,
      });

    } else {

      schedules.push({
        type: "event_staff",
        staffId: staffId,
        timezoneId: timezoneId,
        schedules: updatedSchedules
      });

      _this.setState({
        schedules: schedules,
      });

    }

  }

  /**
   *
   * @param Array updatedSchedules
   * @param int timezoneId
   */
  setSchedules = (updatedSchedules, timezoneId) => {
    console.log("updated scehdule", updatedSchedules);

    const _this = this;

    const schedules = [..._this.state.schedules];

    const found = schedules.some(
      (el) => el.type === "event"
    );


    console.log('schedules', schedules);

    console.log('timezone id', timezoneId);

    if (found) {

      const eventIndex = schedules.findIndex(
        (el) => el.type === "event"
      );

      schedules[eventIndex].schedules = updatedSchedules;
      schedules[eventIndex].timezoneId = timezoneId;
      _this.setState({
        schedules: schedules,
      });

    } else {

      schedules.push({
        type: "event",
        staffId: 0,
        timezoneId: timezoneId,
        schedules: updatedSchedules
      });

      _this.setState({
        schedules: schedules,
      });

    }

  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  /**
   * update staff for Event array
   */
  updateStaffForEvent = () => {
    const { selectedStaff } = this.state;
    let transformValues = [];
    //console.log("selected staff", selectedStaff);
    transformValues = (selectedStaff || []).map((staff) => {
      //console.log("each staff ", staff);
      let { first_name, id, last_name } = staff || {};
      const name = first_name + " " + last_name;
      return {
        ...staff,
        label: name,
        value: id,
      };
    });

    //console.log("transform values", transformValues);
    this.setState({
      staffForEvent: transformValues,
    });
  };

  transformValue = (options) => {
    let transformValues = [];
    if ((options || []).length > 0) {
      transformValues = (options || []).map((option) => {
        let { first_name, id, last_name } = option || {};

        const name = first_name + " " + last_name;
        return {
          //...option,
          title: name,
          value: id,
        };
      });
    }

    return transformValues;
  };

  /**
   * process schedules to save in database
   * @param int eventId
   * @returns array
   */
  processScheduleForSave = (eventId) => {
    const businessId = localStorage.getItem("businessId");
    const { schedules } = this.state;

    console.log('schedules', schedules);


    // process schedules
    let staffs = [];
    let processedSchedules = [];

    schedules.forEach((availability, index) => {
      let scheduleDetails = [];

      availability.schedules.forEach((scheduleDetail, sdIndex) => {
        let weekDayId = scheduleDetail.hasOwnProperty("dayId")
          ? scheduleDetail.dayId
          : null;
        let dated = scheduleDetail.hasOwnProperty("dated") && scheduleDetail.dated !== null
          ? scheduleDetail.dated
          : null;

        let type = (scheduleDetail.hasOwnProperty("dated") && scheduleDetail.dated !== null) ? "custom" : "weekly";
        scheduleDetails.push({
          weekday_id: weekDayId,
          dated: dated,
          on_status: "E",
          type: type,
          schedule_availibility: scheduleDetail.schedules,
        });
      });

      if (availability.type === "event_staff") {
        staffs.push({
          event_id: eventId,
          provider_id: 1,
          timezone_id: availability.timezoneId,
          schedule_status: "E",
          business_id: businessId,
          staff_id: availability.staffId,
          schedule_details: scheduleDetails,
        });
      } else if (availability.type === "event") {

        processedSchedules.push({
          schedule_type: "event",
          event_id: eventId,
          provider_id: 1,
          timezone_id: availability.timezoneId,
          schedule_status: "E",
          business_id: businessId,
          schedule_details: scheduleDetails,
        });

      }


    });

    if (staffs.length > 0) {
      processedSchedules.push({
        schedule_type: "event_staff",
        staffs: staffs,
      })
    }

    console.log('processed schedules', processedSchedules);

    return processedSchedules;
  };

  /**
   * on cancel button handler
   */
  onCancel = () => {
    const { router } = this.props;
    const { navigate } = router || {};
    navigate("/admin/events/list");
  };

  /**
   * save event as draft
   */
  onGoBack = () => {
    const { router } = this.props;
    const { navigate } = router || {};
    navigate(-1);
  };

  /**
   * save event as draft
   */
  onSaveAsDraft = () => {
    const data = this.formRef.current.values;
    const id = this.getId();


    this.setState({
      isLoading: true,
    });


    const { staffForEvent } = this.state;
    let staffs = [];

    staffForEvent.forEach((staffData, index) => {
      staffs.push(staffData.id);
    });

    const { event_validity } = data || {};

    if (event_validity === "") {
      notification.warning("Event validity field is required.");
      return;
    }

    const { saveEventAvailibility } = this.props;
    const processedSchedules = this.processScheduleForSave(id);
    let newData = {
      ...data,
      schedules: processedSchedules,
      staffs: staffs,
      id: parseInt(id),
    };

    saveEventAvailibility({
      data: {
        ...newData,
      },
      onSuccess: (eventId) => {
        const { router } = this.props;
        const { navigate } = router || {};
        navigate("/admin/events/list");
      },
      onError: (error) => {
        this.setState({
          isLoading: false,
          errorMessage: error,
        });
      },
    });
  };
  
  render() {

    const businessId = localStorage.getItem("businessId");
    const type = this.getType();
    const id = this.getId();
    const {
      isLoading,
      pageLoading,
      activeTab,
      staff,
      staffForEvent,
      selectedStaffs,
    } = this.state;

    return (
      <div className="create-event-wrapper">
        <div className="create-event-container">
          <Row className="flex-column-reverse flex-lg-row">
            <Col lg="9">
              <div className="event-card">
                <div className="event-card-head">
                  <h3 className="event-title">Availability</h3>
                </div>
                {pageLoading ? (
                  <Loader isShowLoader={true} />
                ) : (
                  <Formik
                    innerRef={this.formRef}
                    initialValues={initialValues}
                    //validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={(data) => {
                      const { staffForEvent } = this.state;
                      let staffs = [];

                      
                      this.setState({
                        isLoading: true,
                      });
                      

                      staffForEvent.forEach((staffData, index) => {
                        staffs.push(staffData.id);
                      });

                      const { event_validity } = data || {};

                      if (event_validity === "") {
                        notification.warning("Event validity field is required.");
                        return;
                      }

                      const { saveEventAvailibility } = this.props;
                      const processedSchedules =
                        this.processScheduleForSave(id);

                      let newData = {
                        ...data,
                        schedules: processedSchedules,
                        staffs: staffs,
                        id: parseInt(id),
                      };

                      console.log('new Data', newData);
                      saveEventAvailibility({
                        data: {
                          ...newData,
                        },
                        onSuccess: (eventId) => {
                          const { router } = this.props
                          const { navigate } = router || {};
                          navigate(
                            `/admin/events/create/${eventId}/step-4?type=${type}`
                          );
                        },
                        onError: (error) => {
                          console.log('error', error);
                          for (const key in error) {
                            notification.error(error[key])
                          }

                          this.setState({
                            isLoading: false,
                            errorMessage: error,
                          });
                        },
                      });
                    }}
                  >
                    {(formProps) => {
                      const { values, errors, touched, handleChange } =
                        formProps;

                      return (
                        <Form>
                          <div className="event-card-body">
                            <Row>
                              <Col sm="12" md="12" lg="12">
                                <Nav tabs>
                                  <NavItem>
                                    <NavLink
                                      className={classnames({
                                        active: activeTab === "1",
                                      })}
                                      onClick={() => {
                                        this.toggle("1");
                                      }}
                                    >
                                      Event Duration
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      className={classnames({
                                        active: activeTab === "2",
                                      })}
                                      onClick={() => {
                                        this.toggle("2");
                                      }}
                                    >
                                      Advanced
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                  <TabPane tabId="1">
                                    <Row>
                                      <Col sm="12" md="12" lg="12">
                                        <div className="event-field-group">
                                          <Row>
                                            <Col xl="6">
                                              <div className="form-group event-form-group">
                                                <label>
                                                  **Options are in Minutes
                                                </label>
                                                <TimeSelectorField
                                                  defaultValue={
                                                    values.event_duration_in_minutes
                                                  }
                                                  inviteeSetDurationStatus={values.invitee_set_duration_status}
                                                  handleFormChange={handleChange}
                                                  onChange={(value) => {
                                                    handleChange({
                                                      target: {
                                                        name: "event_duration_in_minutes",
                                                        value,
                                                      },
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                            <Col xl="6" className="mt-3 mt-xl-0">
                                              <FormGroup
                                                tag="fieldset"
                                                className="event-form-group "
                                              >
                                                <label>
                                                  Time block this event
                                                </label>
                                                <Row>
                                                <Col md="12" lg="12" className="d-md-none">
                                                    <div className="event-form-check">
                                                      <input
                                                        type="checkbox"
                                                        id="input-3"
                                                        name="invitee_set_duration_status"
                                                        value={
                                                          values.invitee_set_duration_status
                                                        }
                                                        checked={
                                                          values.invitee_set_duration_status ===
                                                          "E"
                                                        }
                                                        onChange={({
                                                          target,
                                                        }) => {
                                                          const {
                                                            name,
                                                          } = target || {};
                                                          handleChange({
                                                            target: {
                                                              name,
                                                              value: target.checked ? "E" : "D",
                                                            },
                                                          });
                                                        }}
                                                      ></input>
                                                      <label htmlFor="input-3">
                                                        <span></span>
                                                        Let Invitee Set Event
                                                        Duration. (Coming Soon)
                                                      </label>
                                                    </div>
                                                  </Col>
                                                  <Col md="4" lg="4">
                                                    <div className="event-form-check">
                                                      <input
                                                        type="radio"
                                                        id="input-1"
                                                        name="is_time_block"
                                                        checked={
                                                          values.is_time_block ==
                                                          "E"
                                                        }
                                                        value="E"
                                                        onChange={({
                                                          target,
                                                        }) => {
                                                          const {
                                                            name,
                                                            value,
                                                          } = target || {};
                                                          handleChange({
                                                            target: {
                                                              name,
                                                              value,
                                                            },
                                                          });
                                                        }}
                                                      ></input>
                                                      <label htmlFor="input-1">
                                                        <span></span>
                                                        Yes
                                                      </label>
                                                    </div>
                                                  </Col>
                                                  <Col md="4" lg="4">
                                                    <div className="event-form-check">
                                                      <input
                                                        type="radio"
                                                        id="input-2"
                                                        name="is_time_block"
                                                        checked={
                                                          values.is_time_block ==
                                                          "D"
                                                        }
                                                        value="D"
                                                        onChange={({
                                                          target,
                                                        }) => {
                                                          const {
                                                            name,
                                                            value,
                                                          } = target || {};
                                                          handleChange({
                                                            target: {
                                                              name,
                                                              value,
                                                            },
                                                          });
                                                        }}
                                                      ></input>
                                                      <label htmlFor="input-2">
                                                        <span></span>
                                                        No
                                                      </label>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row className="d-none d-md-flex">
                                            <Col xl="6">
                                              <FormGroup
                                                tag="fieldset"
                                                className="event-form-group "
                                              >
                                                <Row>
                                                  <Col md="12" lg="12">
                                                    <div className="event-form-check">
                                                      <input
                                                        type="checkbox"
                                                        id="input-3"
                                                        name="invitee_set_duration_status"
                                                        value={
                                                          values.invitee_set_duration_status
                                                        }
                                                        checked={
                                                          values.invitee_set_duration_status ===
                                                          "E"
                                                        }
                                                        onChange={({
                                                          target,
                                                        }) => {
                                                          const {
                                                            name,
                                                          } = target || {};
                                                          handleChange({
                                                            target: {
                                                              name,
                                                              value: target.checked ? "E" : "D",
                                                            },
                                                          });
                                                        }}
                                                      ></input>
                                                      <label htmlFor="input-3">
                                                        <span></span>
                                                        Let Invitee Set Event
                                                        Duration. (Coming Soon)
                                                      </label>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                    </Row>
                                  </TabPane>
                                  <TabPane tabId="2">
                                    <Row>
                                      <Col sm="12" md="12" lg="12">
                                        <div className="event-field-group">
                                          <Row>
                                            <Col md="6" lg="4">
                                              <div className="form-group">
                                                <label>
                                                  Min Adv. Booking Time
                                                </label>
                                                <TimeField
                                                  defaultValue={parseInt(
                                                    values.min_scheduling_notice_minutes
                                                  ) || 0}
                                                  onChange={(value) => {
                                                    handleChange({
                                                      target: {
                                                        name: "min_scheduling_notice_minutes",
                                                        value,
                                                      },
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                            <Col md="6" lg="4">
                                              <div className="form-group">
                                                <label>
                                                  Buffer Before The Event
                                                </label>
                                                <TimeField 
                                                  defaultValue={parseInt(
                                                    values.buffer_before_event_minutes
                                                  ) || 0}
                                                  onChange={(value) => {
                                                    handleChange({
                                                      target: {
                                                        name: "buffer_before_event_minutes",
                                                        value,
                                                      },
                                                    });
                                                  }}/>
                                              </div>
                                            </Col>
                                            <Col md="6" lg="4">
                                              <div className="form-group">
                                                <label>
                                                  Maximum Event Per Day
                                                </label>
                                                <div className="rd_profilethingco">
                                                <InputNumber
                                                    value={values.max_event_per_day}
                                                    min={1}
                                                    onChange={(value) => {
                                                      handleChange({
                                                        target: {
                                                          name: "max_event_per_day",
                                                          value,
                                                      },
                                                    });
                                                  }}
                                                />
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md="6" lg="4">
                                              <div className="form-group">
                                                <label>
                                                Max Adv. Booking Time
                                                </label>
                                                <TimeField
                                                  defaultValue={parseInt(
                                                    values.max_scheduling_notice_minutes
                                                  ) || 0}
                                                  onChange={(value) => {
                                                    handleChange({
                                                      target: {
                                                        name: "max_scheduling_notice_minutes",
                                                        value,
                                                      },
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                            <Col md="6" lg="4">
                                              <div className="form-group">
                                                <label>
                                                  Buffer After The Event
                                                </label>
                                                <TimeField
                                                  defaultValue={parseInt(
                                                    values.buffer_after_event_minutes
                                                  ) || 0}
                                                  onChange={(value) => {
                                                    handleChange({
                                                      target: {
                                                        name: "buffer_after_event_minutes",
                                                        value,
                                                      },
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                            <Col md="6" lg="4">
                                              <div className="form-group">
                                                <label>
                                                  Maximum Event Per Week
                                                </label>
                                                <div className="rd_profilethingco">
                                                <InputNumber
                                                    value={values.max_event_per_week}
                                                    min={1}
                                                    onChange={(value) => {
                                                      handleChange({
                                                        target: {
                                                          name: "max_event_per_week",
                                                          value,
                                                      },
                                                    });
                                                  }}
                                                />
                                                </div>
                                              </div>
                                            </Col>

                                            <Col md="6" lg="4">
                                              <div className="form-group">
                                                <label>
                                                Start time increments
                                                </label>
                                                <TimeField
                                                  view={["hour", "minutes"]}
                                                  defaultValue={parseInt(
                                                    values.slot_duration_increment_of
                                                  ) || 0}
                                                  onChange={(value) => {
                                                    handleChange({
                                                      target: {
                                                        name: "slot_duration_increment_of",
                                                        value,
                                                      },
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </Col>

                                          </Row>
                                        </div>
                                      </Col>
                                    </Row>
                                  </TabPane>
                                </TabContent>
                                <div className="event-field-group">
                                  <Row>
                                    <Col xl="6">
                                      <FormGroup
                                        tag="fieldset"
                                        className="event-form-group "
                                      >
                                        <label>Staff Assignment</label>
                                        <div className="event-form-check">
                                          <input
                                            type="radio"
                                            id="input-4"
                                            name="staff_assignment_type"
                                            checked={values.staff_assignment_type === "1"}
                                            value="1"
                                            onChange={({ target }) => {
                                              const { name, value } =
                                                target || {};
                                              handleChange({
                                                target: {
                                                  name,
                                                  value,
                                                },
                                              });

                                              handleChange({
                                                target: {
                                                  name: "staff_choose",
                                                  value: "",
                                                },
                                              });

                                              handleChange({
                                                target: {
                                                  name: "staff_assignment_method",
                                                  value: "",
                                                },
                                              });

                                              this.setState({
                                                selectedStaff: [],
                                                selectedStaffs: []
                                              });
                                              
                                            }}
                                          ></input>
                                          <label htmlFor="input-4">
                                            <span></span>
                                            My Event
                                          </label>
                                        </div>
                                        <div className="event-form-check">
                                          <input
                                            type="radio"
                                            id="input-5"
                                            checked={values.staff_assignment_type === "2"}
                                            name="staff_assignment_type"
                                            value="2"
                                            onChange={({ target }) => {
                                              const { name, value } =
                                                target || {};
                                              handleChange({
                                                target: {
                                                  name,
                                                  value,
                                                },
                                              });
                                            }}
                                          ></input>
                                          <label htmlFor="input-5">
                                            <span></span>
                                            Assign it To A Staff
                                          </label>
                                        </div>
                                        <div className="staff_assignment ml-2">
                                          <Row>
                                            <Col md="8" lg="8">
                                              <div className="form-group event-form-group">
                                                <MultiSelect 
                                                  data={this.transformValue(
                                                    staff
                                                  )}
                                                  isDisabled={values.staff_assignment_type === "1"}
                                                  defaultValue={selectedStaffs} 
                                                  onChangeTreeSelect={(values) => {
                                                    let assignedStaffs = [];
                                                    this.transformValue(staff).forEach((adminStaff, index) => {
                                                      if (values.includes(adminStaff.value)) {
                                                        assignedStaffs.push({
                                                          first_name: adminStaff.title,
                                                          last_name: '',
                                                          id: adminStaff.value
                                                        });
                                                      }
                                                    });

                                                    this.setState({
                                                      selectedStaff: [
                                                        ...assignedStaffs,
                                                      ],
                                                    });
                                                  }}  />
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row className="mt-3 mt-xl-1">
                                            <Col md="12" lg="12">
                                              <div className="event-form-check">
                                                <input
                                                  type="checkbox"
                                                  id="input-27"
                                                  name="staff_choose"
                                                  value="allow_invitee"
                                                  disabled={values.staff_assignment_type === "1"}
                                                  checked={
                                                    values.staff_choose ===
                                                    "allow_invitee"
                                                  }
                                                  onChange={({ target }) => {
                                                    const { value, name } =
                                                      target || {};
                                                    handleChange({
                                                      target: {
                                                        name,
                                                        value,
                                                      },
                                                    });
                                                    handleChange({
                                                      target: {
                                                        name: "staff_assignment_method",
                                                        value: "",
                                                      },
                                                    });
                                                  }}
                                                ></input>
                                                <label htmlFor="input-27">
                                                  <span></span>
                                                  Allow Attendee To Select Staff
                                                </label>
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row className="">
                                            <Col md="12" lg="12">
                                              <div className="event-form-check">
                                                <input
                                                  type="checkbox"
                                                  id="input-28"
                                                  name="staff_choose"
                                                  value="random_choose"
                                                  disabled={values.staff_assignment_type === "1"}
                                                  checked={
                                                    values.staff_choose ===
                                                    "random_choose"
                                                  }
                                                  onChange={({ target }) => {
                                                    const { value, name } =
                                                      target || {};
                                                    handleChange({
                                                      target: {
                                                        name,
                                                        value,
                                                      },
                                                    });
                                                  }}
                                                ></input>
                                                <label htmlFor="input-28">
                                                  <span></span>
                                                  Select Staff (Automatic
                                                  Assignment)
                                                </label>
                                              </div>
                                              <Row className="ml-xl-1">
                                                <Col md="6" lg="6">
                                                  <div className="event-form-check">
                                                    <input
                                                      type="checkbox"
                                                      id="input-30"
                                                      name="staff_assignment_method"
                                                      value="round-robin"
                                                      checked={
                                                        values.staff_assignment_method ===
                                                        "round-robin"
                                                      }
                                                      disabled={values.staff_assignment_type === "1" || values.staff_choose === "allow_invitee"}
                                                      onChange={({
                                                        target,
                                                      }) => {
                                                        const { value, name } =
                                                          target || {};
                                                        handleChange({
                                                          target: {
                                                            name,
                                                            value,
                                                          },
                                                        });

                                                        if (isEmpty(values.staff_choose)) {
                                                          handleChange({
                                                            target: {
                                                              name: "staff_choose",
                                                              value: "random_choose",
                                                            },
                                                          });
                                                        }
                                                        
                                                      }}
                                                    ></input>
                                                    <label htmlFor="input-30">
                                                      <span></span>
                                                      Round-Robin
                                                    </label>
                                                  </div>
                                                </Col>
                                                <Col md="6" lg="6">
                                                  <div className="event-form-check">
                                                    <input
                                                      type="checkbox"
                                                      id="input-31"
                                                      name="staff_assignment_method"
                                                      value="event-rotation"
                                                      checked={
                                                        values.staff_assignment_method ===
                                                        "event-rotation"
                                                      }
                                                      disabled={values.staff_assignment_type === "1" || values.staff_choose === "allow_invitee"}
                                                      onChange={({
                                                        target,
                                                      }) => {
                                                        const { value, name } =
                                                          target || {};
                                                        handleChange({
                                                          target: {
                                                            name,
                                                            value,
                                                          },
                                                        });

                                                        if (isEmpty(values.staff_choose)) {
                                                          handleChange({
                                                            target: {
                                                              name: "staff_choose",
                                                              value: "random_choose",
                                                            },
                                                          });
                                                        }
                                                        
                                                      }}
                                                    ></input>
                                                    <label htmlFor="input-31">
                                                      <span></span>
                                                      Even Rotation
                                                    </label>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                    <Col xl="6">
                                      <div className="form-group event-form-group">
                                        <label>Event Internal Notes:</label>
                                        <TextEditor
                                          name="event_internal_notes"
                                          value={values.event_internal_notes}
                                          onChange={(value) => {
                                            handleChange({
                                              target: {
                                                name: "event_internal_notes",
                                                value,
                                              },
                                            });
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="event-field-group">
                                  <Row>
                                    <Col md="12" lg="12">
                                      <FormGroup
                                        tag="fieldset"
                                        className="event-form-group "
                                      >
                                        <label>Event Validity</label>
                                        <Row>
                                          <Col md="6" xl="3">
                                            <div className="event-form-check">
                                              <input
                                                type="radio"
                                                id="input-16"
                                                value="indefinitly"
                                                checked={
                                                  values.event_validity ==
                                                  "indefinitly"
                                                }
                                                name="event_validity"
                                                onChange={({ target }) => {
                                                  const { name, value } =
                                                    target || {};
                                                  handleChange({
                                                    target: { name, value },
                                                  });
                                                }}
                                              ></input>
                                              <label htmlFor="input-16">
                                                <span></span>
                                                Indefinitely
                                              </label>
                                            </div>
                                          </Col>
                                          <Col md="6" xl="4">
                                            <div className="event-field-inline">
                                              <div className="event-form-check ">
                                                <input
                                                  type="radio"
                                                  id="input-17"
                                                  value="valid_over"
                                                  checked={
                                                    values.event_validity ==
                                                    "valid_over"
                                                  }
                                                  name="event_validity"
                                                  onChange={({ target }) => {
                                                    const { name, value } =
                                                      target || {};
                                                    handleChange({
                                                      target: { name, value },
                                                    });
                                                  }}
                                                ></input>
                                                <label htmlFor="input-17">
                                                  <span></span>
                                                  Valid Over
                                                </label>
                                              </div>
                                              <div className="input-field">
                                                <input
                                                  type="number"
                                                  placeholder="15 Days"
                                                  name="valid_over_period"
                                                  value={values.valid_over_period}
                                                  onChange={({ target }) => {
                                                    const { name, value } =
                                                      target || {};
                                                    handleChange({
                                                      target: { name, value },
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>

                                          <Col md="6" xl="5">
                                            <div className="event-field-inline-range">
                                              <div className="event-form-check">
                                                <input
                                                  type="radio"
                                                  id="input-18"
                                                  value="date_range"
                                                  name="event_validity"
                                                  checked={
                                                    values.event_validity ==
                                                    "date_range"
                                                  }
                                                  onChange={({ target }) => {
                                                    const { name, value } =
                                                      target || {};
                                                    handleChange({
                                                      target: { name, value },
                                                    });
                                                  }}
                                                ></input>
                                                <label htmlFor="input-18">
                                                  <span></span>
                                                  Over Date Range
                                                </label>
                                              </div>
                                              {values.event_validity ===
                                                "date_range" && (
                                                  <div className="range-field">
                                                    <DateRangePicker
                                                      initialSettings={{
                                                        startDate: !isEmpty(values.event_start_date) ? values.event_start_date : moment(),
                                                        endDate: !isEmpty(values.event_end_date) ? values.event_end_date : moment().add(
                                                          14,
                                                          "d"
                                                        ),
                                                        locale: {
                                                          "firstDay": 0
                                                        }
                                                      }}
                                                      onCallback={(
                                                        start,
                                                        end,
                                                        label
                                                      ) => {
                                                          handleChange({
                                                            target: {
                                                              name: 'event_start_date',
                                                              value: moment(start).format(
                                                                "DD/MM/yyyy"
                                                              )
                                                            },
                                                          });
                                                          handleChange({
                                                            target: {
                                                              name: 'event_end_date',
                                                              value:moment(end).format(
                                                                "DD/MM/yyyy"
                                                              )
                                                            },
                                                          });

                                                      }}
                                                    >
                                                      <input type="text" />
                                                    </DateRangePicker>
                                                  </div>
                                                )}
                                            </div>
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="event-field-group border-zero">
                                  <div className="availbility-container">
                                    <EventAvailbilityComponent
                                      businessId={businessId}
                                      errors={errors}
                                      touched={touched}
                                      type="event"
                                      staffId=""
                                      schedules={this.state.schedules}
                                      setSchedules={this.setSchedules}
                                    />
                                  </div>
                                </div>
                                {staffForEvent.length > 0 && (
                                  <div className="event-field-group border-zero">
                                    <Collapse defaultActiveKey={['1']}>
                                      {(staffForEvent || []).map((eventStaff, index) => {
                                        return (
                                          <Panel header={`${eventStaff.first_name} ${eventStaff.last_name}`} key={index + 1}>
                                            <EventAvailbilityComponent
                                              businessId={businessId}
                                              errors={errors}
                                              touched={touched}
                                              type="event_staff"
                                              staffId={eventStaff.id}
                                              schedules={this.state.schedules}
                                              setSchedules={(schedules, timezoneId) => {
                                                this.setStaffSchedule(schedules, eventStaff.id, timezoneId)
                                              }}
                                            />
                                          </Panel>
                                        );
                                      })}
                                    </Collapse>
                                  </div>
                                )}

                              </Col>
                            </Row>
                          </div>
                          <div className="event-card-footer pt-0">
                            <Row>
                              <Col md="3" lg="3">
                                <Button
                                  className="btn btn-danger"
                                  type="button"
                                  disabled={isLoading}
                                  onClick={this.onCancel}
                                >
                                  Cancel
                                </Button>
                              </Col>
                              <Col md="3" lg="3" className="mt-3 mt-md-0">
                                <Button
                                  className="btn btn-outline"
                                  type="button"
                                  disabled={isLoading}
                                  onClick={this.onSaveAsDraft}
                                >
                                  Save as Draft
                                </Button>
                              </Col>

                              <Col md="3" lg="3" className="mt-3 mt-md-0">
                                <Button
                                  className="btn btn-success"
                                  type="button"
                                  disabled={isLoading}
                                  onClick={this.onGoBack}
                                >
                                  Back
                                </Button>
                              </Col>

                              <Col md="3" lg="3" className="mt-3 mt-md-0">
                                <Button
                                  type="submit"
                                  className="btn btn-app"
                                  disabled={isLoading}
                                >
                                  Next Step
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </div>
            </Col>
            <Col lg="3">
              <SidebarProgress props={this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  getEventStaffList,
  getVenues,
  saveEventAvailibility,
  getEventAvailability,
})(withRouter(AddNewEventAvailability));
