import React, { useState, useEffect } from "react";
import CancelScheduleIcon from "../../../../assets/icons/cancel_schedule.svg";
import FeatureIcon from "../../../../assets/icons/feature_icon.svg";
import AnnouncemnetIcon from "../../../../assets/icons/announcement_icon.svg";
import StaffAsigned from "../../../../assets/icons/staff_assign.svg";
import PaymentIcon from "../../../../assets/icons/payment_icon.svg";
import NotiBell from "../../../../assets/icons/notification_bell.svg";
import NewScheduleIcon from "../../../../assets/icons/new_schedule.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { getAdminBusinessId, getLoggedInUserId } from "../../../../utils/authHelper";
import { connect, useDispatch } from "react-redux";
import { sendPostRequest } from "../../../../actions/app-actions";
import {
  CHANNELS_LOADED,
  UPDATE_NOTIFICATIONS,
  APPEND_NOTIFICATIONS,
} from "../../../../actions/types";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";

let isAdminChannelsLoaded = false;
let totalUnread = 0;
let notifications = [];

const Notification = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notiDropdownOpen, setNotiDropdownOpen] = useState(false);
  const [onlyUnreadNoti, setOnlyUnreadNoti] = useState(false);
  //const [notiInnerOpen, setNotiInnerOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 7,
  });

  //const notiInnerToggle = () => setNotiInnerOpen((prevState) => !prevState);

  const unreadNotiSelect = () => setOnlyUnreadNoti(true);

  const notiToggle = () => setNotiDropdownOpen((prevState) => !prevState);

  const goToNotiSettings = () => {
    navigate("/admin/notification-settings");
  };

  useEffect(() => {
    fetchNotifications(pagination.pageSize, pagination.current);
  }, []);

  const loadMoreNotifications = () => {
    fetchNotifications(pagination.pageSize, pagination.current + 1);
  };

  /**
   * fetch notifications
   * @param {*} pageSize
   * @param {*} pageNumber
   */
  const fetchNotifications = (pageSize, pageNumber) => {
    const { sendPostRequest } = props;
    setPagination({
      current: pageNumber,
      pageSize,
    });
    sendPostRequest({
      url: `/admin/notifications/${getAdminBusinessId()}/${getLoggedInUserId()}`,
      data: {
        pageSize,
        pageNumber,
      },
      onSuccess: function (response) {
        const { data } = response;
        totalUnread = data.totalUnreadNotifications;

        if (totalCount === 0) {
          setTotalCount(data.totalCount);
        }

        notifications = [...data.data];
        dispatch({
          type: APPEND_NOTIFICATIONS,
          payload: {
            totalUnread,
            notifications,
          },
        });
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  };

  useEffect(() => {
    if (!props.layout.isChannelsLoaded && !isAdminChannelsLoaded) {
      isAdminChannelsLoaded = true;
      dispatch({
        type: CHANNELS_LOADED,
        payload: true,
      });
      const channel = window.Echo.channel(`notifications-staff-${getAdminBusinessId()}-${getLoggedInUserId()}`);
      channel
        .subscribed(() => {
          console.log("subscribed");
        })
        .listen(".event-booked", (data) => {
          console.log("event", data);
          totalUnread++;
          notifications = [data];
          dispatch({
            type: UPDATE_NOTIFICATIONS,
            payload: {
              totalUnread,
              notifications,
            },
          });
        });

      channel.listen(".event-cancelled", (data) => {
        console.log("event", data, "unread", totalUnread);
        totalUnread++;
        notifications = [data];
        dispatch({
          type: UPDATE_NOTIFICATIONS,
          payload: {
            totalUnread,
            notifications,
          },
        });
      });

      channel.listen(".event-rescheduled", (data) => {
        console.log("event", data, "unread", totalUnread);
        totalUnread++;
        notifications = [data];
        dispatch({
          type: UPDATE_NOTIFICATIONS,
          payload: {
            totalUnread,
            notifications,
          },
        });
      });
    }
  }, [props.layout.isChannelsLoaded]);

  return (
    <div className="rd_flexrowitem dispnonemobile">
      <div className="buttonnotgcont">
        {/* <button className="rd_iconthing notbtnnav notifiexist">
                  <span>Notification</span>
                </button> */}

        <Dropdown
          isOpen={notiDropdownOpen}
          toggle={notiToggle}
          className="profile_dropdown"
        >
          <DropdownToggle caret>
            <div className="noti_num">
              {props.notification.totalUnread > 9
                ? "9+"
                : props.notification.totalUnread}
            </div>
            <span className="rd_iconthing notbtnnav notifiexist">
              Notification
            </span>
          </DropdownToggle>

          <DropdownMenu id="scrollableDiv">
            {/* Top Drop Option */}
            <DropdownItem
              className="drop_options d-flex align-items-center justify-content-between mb-4"
              text
            >
              <h2>Notifications</h2>
              {/* <FormGroup className="ml-auto" check inline>
                <Input
                  type="checkbox"
                  checked={onlyUnreadNoti}
                  onChange={unreadNotiSelect}
                />
                <Label className="mb-1" check>
                  View unread messages
                </Label>
              </FormGroup> */}
              {/* <Link
                to={"/admin/notification-settings"}
                className={"itemcomconsub dashboardmenuicon "}
              >
                <Icon.Settings
                  size={16}
                  className="mr-50"
                  color="#FE9920"
                  strokeWidth="1.4"
                />
              </Link> */}

              {/* <Dropdown isOpen={notiInnerOpen} toggle={notiInnerToggle}>
                <DropdownToggle className="inner_drop_toggle" caret>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#0C4767"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                  </svg>
                </DropdownToggle>
                <DropdownMenu className="inner_drop">
                  <DropdownItem>Mark all as read</DropdownItem>
                  <DropdownItem onClick={goToNotiSettings}>
                    Notifications Setting
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown> */}
            </DropdownItem>
            {/* Top Drop Option End */}

            <InfiniteScroll
              dataLength={props.notification.notifications.length}
              next={loadMoreNotifications}
              hasMore={true}
              height={570}
              scrollableTarget="scrollableDiv"
            >
              {(props.notification.notifications || []).map(
                (notification, index) => {
                  return (
                    <>
                      <DropdownItem
                        className="notification-item unread"
                        key={notification.id}
                      >
                        <div className="row no-gutters">
                          <div className="col-auto noti_img pr-2">
                            <div className="user_img">
                              <img
                                src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg"
                                alt="User Profile Pic"
                              />
                            </div>
                            <div className="noti_icon">
                              <img
                                src={NewScheduleIcon}
                                alt="Notificion Icon"
                              />
                            </div>
                          </div>
                          <div className="col-auto noti_txt pl-2">
                            <h4>{notification.title}</h4>
                            <p>{notification.description}</p>
                            <p className="time">
                              {moment.utc(notification.dated).local().fromNow()}
                            </p>
                          </div>
                        </div>
                      </DropdownItem>
                      <DropdownItem divider />
                    </>
                  );
                }
              )}
              <DropdownItem divider />
              <div className="view_all">
                <Link to="/admin/notifications">View All Notifications</Link>
              </div>
            </InfiniteScroll>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
  notification: state.notification,
});

export default connect(mapStateToProps, { sendPostRequest })(Notification);
