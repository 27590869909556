import React, { Fragment } from "react";
import SettingAsideLeft from "../partials/SettingAsideLeft";

const FrontTooltips = () => {
  return (
    <Fragment>
      <SettingAsideLeft />
    </Fragment>
  );
};

export default FrontTooltips;
