import React from "react";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import ProfileInfo from "./user-profile";
import CardInfo from "./card-info";
import ChangePassword from "./change-password";
import { USER_TYPES } from "../../../routes/types/private";

import "../../../assets/scss/pages/users.scss";

class Profile extends React.Component {
  state = {
    activeTab: "1",
  };

  toggle = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    return (
      <div className="eventdetailsaddbox rd_noshadow">
        <div className="boxheader rd_floatingheaderthig">
          <div className="rd_inputselectheader">
            <div className="rd_selectheaderrdt2 rd_selectheaderrdt2profile">
              <button
                className={classnames({
                  active: this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Profile
              </button>
              {localStorage.userType === USER_TYPES.admin && (
                <button
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Payment Info
                </button>
              )}

              <button
                className={classnames({
                  active: this.state.activeTab === "3",
                })}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                Change Credentials
              </button>
            </div>
          </div>
        </div>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <ProfileInfo />
          </TabPane>
          {localStorage.userType === USER_TYPES.admin && (
            <TabPane tabId="2">
              <CardInfo />
            </TabPane>
          )}
          <TabPane tabId="3">
            <ChangePassword />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
export default Profile;
