import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getBookingDetails,
  setBookingStatus,
} from "../../../actions/admin/scheduled-event-actions";
import {
  Modal,
  ModalBody,
  Row,
  Col,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import { FRONTEND_BASE_URL } from "../../../utils/utility";
import {
  notification,
  onModalOpen,
  onModalClose,
} from "../../../utils/utility";
import LinkIcon from "../../../assets/icons/link_icon.svg";

const PastViewDetails = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState(null);
  const [noShowModal, setNoShowModal] = useState(false);
  const toggleNoShowModal = () => {
    if (!noShowModal) {
      onModalOpen();
    } else {
      onModalClose();
    }
    setNoShowModal(!noShowModal);
  };

  useEffect(() => {
    const { getBookingDetails, bookingId } = props;
    setIsLoading(true);
    getBookingDetails({
      data: {
        id: bookingId,
      },
      onSuccess: function (response) {
        setData(response);
        setIsLoading(false);
      },
      onError: function (error) {
        console.error(error);
        setIsLoading(false);
      },
    });
  }, []);

  /**
   * on copy event link
   * @param {*} eventURL
   * @param {*} copyFieldElementId
   */
  const onCopyEventLink = (eventURL) => {
    var tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = FRONTEND_BASE_URL + "booking/" + eventURL;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    notification.success(
      `Event URL Copied ${FRONTEND_BASE_URL}booking/${eventURL}`
    );
    document.body.removeChild(tempInput);
  };

  /**
   * on mark as no show
   */
  const setStatus = (status) => {
    const { id } = data;
    const { setBookingStatus, updatePastEventStatus } = props;
    setIsLoading(true);
    setBookingStatus({
      data: {
        id: id,
        status,
      },
      onSuccess: function (response) {
        if (status === "MN") {
          toggleNoShowModal();
        }
        setData({
          ...data,
          booking_status: status,
        });
        updatePastEventStatus(id, status);
        notification.success("Status Successfully updated");

        setIsLoading(false);
      },
      onError: function (error) {
        console.error(error);
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Loader isShowLoader={isLoading} />
      <Modal
        isOpen={props.showViewDetailModal}
        toggle={props.handleClose}
        className="modal-dialog"
      >
        <ModalBody>
          <div className="">
            <div className="box_content">
              <div className="rd_inputselectheadermodalsetting">
                <button
                  className="rd_colsebtn"
                  onClick={props.handleClose}
                ></button>
              </div>

              <h3 className="rd_modalheadertext rd_modalheadertextnm">
                Booking Details
              </h3>
              {/* {data ? (
                data.booking_status === "MN" ? (
                  <>
                    <button
                      className="btn btn-outline btn_no_show mt-2 mb-3"
                      onClick={() => setStatus("A")}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 561 561"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M280.729 0.333496C206.469 0.333496 135.249 29.8335 82.7424 82.3442C30.2317 134.855 0.731689 206.073 0.731689 280.331C0.731689 354.588 30.2317 425.811 82.7424 478.318C135.253 530.828 206.472 560.328 280.729 560.328C354.986 560.328 426.209 530.828 478.716 478.318C531.226 425.807 560.726 354.588 560.726 280.331C560.726 206.073 531.226 134.851 478.716 82.3442C426.205 29.8335 354.986 0.333496 280.729 0.333496V0.333496ZM47.3957 280.334C47.3436 226.552 65.9837 174.421 100.13 132.867L428.197 460.934C382.369 498.308 324.061 516.887 265.064 512.917C206.064 508.943 150.772 482.724 110.37 439.548C69.9637 396.371 47.4584 339.47 47.401 280.335L47.3957 280.334ZM461.329 427.8L133.262 99.7335C178.09 63.6602 234.664 45.4268 292.116 48.5308C349.569 51.6298 403.85 75.8482 444.529 116.531C485.212 157.213 509.43 211.495 512.529 268.944C515.633 326.398 497.398 382.971 461.326 427.797L461.329 427.8Z"
                          fill=""
                        />
                      </svg>
                      Undo Mark as no-show
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-outline btn_no_show mt-2 mb-3"
                      onClick={toggleNoShowModal}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 561 561"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M280.729 0.333496C206.469 0.333496 135.249 29.8335 82.7424 82.3442C30.2317 134.855 0.731689 206.073 0.731689 280.331C0.731689 354.588 30.2317 425.811 82.7424 478.318C135.253 530.828 206.472 560.328 280.729 560.328C354.986 560.328 426.209 530.828 478.716 478.318C531.226 425.807 560.726 354.588 560.726 280.331C560.726 206.073 531.226 134.851 478.716 82.3442C426.205 29.8335 354.986 0.333496 280.729 0.333496V0.333496ZM47.3957 280.334C47.3436 226.552 65.9837 174.421 100.13 132.867L428.197 460.934C382.369 498.308 324.061 516.887 265.064 512.917C206.064 508.943 150.772 482.724 110.37 439.548C69.9637 396.371 47.4584 339.47 47.401 280.335L47.3957 280.334ZM461.329 427.8L133.262 99.7335C178.09 63.6602 234.664 45.4268 292.116 48.5308C349.569 51.6298 403.85 75.8482 444.529 116.531C485.212 157.213 509.43 211.495 512.529 268.944C515.633 326.398 497.398 382.971 461.326 427.797L461.329 427.8Z"
                          fill=""
                        />
                      </svg>
                      Mark as no-show
                    </button>
                  </>
                )
              ) : null} */}

              {/* <Modal
                className="asno_show_modal"
                isOpen={noShowModal}
                toggle={toggleNoShowModal}
              >
                <ModalBody>
                  <h2>Are you sure you want to mark Event as a no-show?</h2>
                  <p>
                    This will prevent your invitee from receiving any automated
                    follow-up messages after the event.
                  </p>
                  <a href="/#">
                    Learn more about how no-shows work{" "}
                    <img src={LinkIcon} alt=">Link Icon" />
                  </a>
                </ModalBody>
                <ModalFooter>
                  <button
                    className="btn btn-outline"
                    onClick={toggleNoShowModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-app"
                    onClick={() => setStatus("MN")}
                  >
                    Mark as no-show
                  </button>
                </ModalFooter>
              </Modal> */}
              <Row>
                <Col lg="12">
                  <div className="vd-title">Event Title :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.event_name : null}
                  </div>
                </Col>
                <Col lg="12">
                  <div className="vd-title">Host :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.host : null}
                  </div>
                </Col>
                <Col lg="12">
                  <div className="vd-title">Invitee :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.invitee : null}
                  </div>
                </Col>
                <Col lg="12">
                  <div className="vd-title">When :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.when : null}
                  </div>
                </Col>
                <Col lg="12">
                  <div className="vd-title">Event Duration :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.event_duration : null}
                  </div>
                </Col>

                <Col lg="12">
                  <div className="vd-title">Venue :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.venue : null}
                  </div>
                </Col>

                <Col lg="12">
                  <div className="vd-title">Venue Details :</div>
                  <div className="vd-description pt-1 pb-3">
                    {data ? data.venue_details : null}
                  </div>
                </Col>
                {data && data.event_url && (
                  <div class="col-lg-12 mt-4">
                    <div
                      class="copy_link_btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => onCopyEventLink(data.event_url)}
                    >
                      Copy event link
                    </div>
                  </div>
                )}
              </Row>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, {
  getBookingDetails,
  setBookingStatus,
})(PastViewDetails);
