import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import { sendPostRequest } from "../../../../actions/app-actions";
import { getLoggedInUserTimezone, notification } from "../../../../utils/utility";
import moment from "moment";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Input,
  Button,
  ModalHeader,
} from "reactstrap";

const ReassignModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSet, setDataSet] = useState([]);

  const [bookings, setBookings] = useState([]);

  useEffect(() => {

    const {
      data,
      sendPostRequest
    } = props;

    setIsLoading(true);
    sendPostRequest({
      url: `/get-staffs-to-reassign`,
      data: {
        ids: data,
        timezone: getLoggedInUserTimezone(),
      },
      onSuccess: function (response) {
        setIsLoading(false);
        const { data } = response.data;
        setDataSet(data);
        console.log('working now', response);
      },
      onError: function (error) {
        setIsLoading(false);
        console.log("error", error);
      },
    });

  }, []);


  /**
   * on reassign
   */
  const onReassign = () => {

    const {
      sendPostRequest,
      handleClose,
      loadUpcomingEvents
    } = props;

    setIsLoading(true);
    sendPostRequest({
      url: `/booking/bulk-reassign`,
      data: {
        bookings
      },
      onSuccess: function (response) {
        setIsLoading(false);
        handleClose();
        loadUpcomingEvents();
        notification.success("Events successfully reassigned!");
      },
      onError: function (error) {
        setIsLoading(false);
        handleClose();
        console.log("error", error);
      },
    });
    
  }


  /**
   * on staff change handler
   */
  const onStaffChangeHandler = (bookingId, staffId) => {
    console.log('booking id', bookingId, 'staff id', staffId);
    const processedData = [...bookings];
    const foundIndex = bookings.findIndex(booking => booking.id === bookingId);
    if (foundIndex !== -1) {
      processedData[foundIndex].staffId = staffId;
    } else {
      processedData.push({
        id: bookingId,
        staffId
      });
    }
    setBookings(processedData)
  }


  return (
    <>
      <Loader isShowLoader={isLoading} />
      <Modal
        isOpen={props.showReassignModal}
        toggle={props.handleClose}
        centered={true}
        className="event-modal modal-dialog modal-lg reassign-popup"
      >
        <ModalHeader toggle={props.handleClose}>Assign to Other Staff</ModalHeader>
        <ModalBody>
        {(dataSet || [])
            .map((data, index) => {
              return (
                <div className="row justify-content-between align-items-center" key={`booking-${index}`}>
                  <div className="col-md-auto mb-3 mb-md-0">
                    <h3 className="fw-bold text-brandprimary">
                      {moment(data.dated, "YYYY-MM-DD").format("dddd, MMMM, DD YYYY")}
                    </h3>
                  </div>
                  <div className="col-md-auto">
                    <div className="slot">{data.slot}</div>
                  </div>
                  <div className="col-12 bookig_staff_sec mb-4">
                    <div className="staff_slect">
                      <form>
                        {data.staffs.length > 0 ? 
                        (
                          <>
                            <div className="head py-3">
                              <h3>Choose a Staff</h3>
                            </div>
                            <div className="staff_select_main">
                            {(data.staffs || [])
                              .map((staff, innerIndex) => {
                                return (
                                  <div 
                                    className="slide_inner position-relative"
                                    key={`staff-select-${innerIndex}`} >
                                      <input
                                        type="radio"
                                        name={`event-staff-${data.id}`}
                                        value={staff.id}
                                        onChange={(e) => {
                                          onStaffChangeHandler(data.id, e.target.value);
                                        }}
                                      />
                                      <label>
                                        <img
                                          src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg"
                                          alt=""
                                          className="staff_img"
                                        />
                                        <p className="text-brandprimary">{`${staff.first_name} ${staff.last_name}`}</p>
                                      </label>
                                    </div>
                                );
                            })}
                            </div>
                          </>
                        ) : 
                        (
                          <>
                            <div className="head py-3">
                              <h3>No Staff available</h3>
                            </div>
                          </>
                        )}
                      </form>  
                    </div>
                  </div>
                </div>
              );
        })}
          <Row>
            <Col lg={{ size: 5, offset: 1 }} className="mt-4">
              <Button
                className="btn btn-danger"
                type="button"
                onClick={props.handleClose}
              >
                Canel
              </Button>
            </Col>

            <Col lg="5" className="mt-4">
              <Button
                type="submit"
                className="btn btn-app"
                onClick={onReassign}
              >
                Update
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, {
  sendPostRequest,
})(ReassignModal);
