import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { sendPostRequest } from "../../../../../actions/app-actions";
import { getAdminBusinessId } from "../../../../../utils/authHelper";
import { getLoggedInUserTimezone } from "../../../../../utils/utility";
import { connect } from "react-redux";
import { getEvents } from "../../../../../actions/adminDashboardActions";
import { Select } from "antd";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from 'moment';
import Tooltip from "../../../../../components/Tooltip";

Chart.register(...registerables);
const PieSalesChart = (props) => {
  const [chartData, setChartData] = useState(null);
  const [data, setData] = useState([]);
  const [renderChart, setRenderChart] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    /*
    const { getEvents } = props;
    getEvents({
      data: {},
      onSuccess: function (data) {
        setEvents([{ value: "", label: "All Events" }, ...data.events]);
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
    */

    loadChartData();
  }, []);

  /**
   * loads chart data
   * @param {*} data
   */
  const loadChartData = (data = {}) => {
    const { sendPostRequest } = props;
    sendPostRequest({
      url: `/admin/event-availability-ratio-chart/${getAdminBusinessId()}`,
      data: {
        ...data,
        timezone: getLoggedInUserTimezone()
      },
      onSuccess: function (response) {
        const { data } = response;
        setData(data.data);
        setChartData({
          labels: data.data.map((row) => row.label),
          datasets: [
            {
              label: "Total Minutes",
              data: data.data.map((data) => data.total),
              backgroundColor: ["#1789C6", "#FF9500", "#9270D5", "#00C875"],
            },
          ],
        });
        setRenderChart(true);
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  };

  return (
    <>
      <div className="col-lg-4 col-md-6 dashboard_pie_chart mb-lg-4">
        <div className="page_title_dashboard">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 mb-2">
              <h3>Avaiability Ratio  <Tooltip code="P3F4" /></h3>
            </div>
            <div className="col-12 d-flex justify-content-end flex-wrap flex-md-nowrap">
              <DateRangePicker
                initialSettings={{
                  startDate: moment(),
                  endDate: moment().add(
                    14,
                    "d"
                  ),
                  locale: {
                    "firstDay": 0
                  }
                }}
                onCallback={(
                  start,
                  end,
                  label
                ) => {
                  setStartDate(moment(start).format("DD/MM/yyyy"));
                  setEndDate(moment(end).format("DD/MM/yyyy"));
                  loadChartData({
                    event_id: eventId,
                    start_date: moment(start).format("DD/MM/yyyy"),
                    end_date: moment(end).format("DD/MM/yyyy"),
                  });
                }}
              >
                <input type="text" className="me-md-3 mb-2 mb-md-0" />
              </DateRangePicker>
              <div className="slct">
                <Select
                  size={"large"}
                  placeholder="Select Event"
                  allowClear={true}
                  onChange={(value) => {
                    setEventId(value);
                    loadChartData({
                      event_id: value,
                      start_date: startDate,
                      end_date: endDate,
                    });
                  }}
                  style={{
                    width: "100%",
                  }}
                  value={eventId}
                  options={props.events}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="rd_vacationfilterpart mt-2 align-items-center">
          <div className="col-12">
            <div className="chart_container">
              {renderChart ? (
                <Pie
                  data={chartData}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getEvents, sendPostRequest })(PieSalesChart);
