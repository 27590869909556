import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import "antd/dist/antd.css";
import Loader from "../../../components/Loader/Loader";
import Filter from "../../../components/Filter/Filter";
import IntegrationGoupImg from "../../../assets/images/all-integ.png";
import { Link } from "react-router-dom";
import GoogleCalendar from "../../../assets/icons/g-calendar.png";
import MsOffice from "../../../assets/icons/ms-office-360.svg";
import ICloud from "../../../assets/icons/apple-icloud.svg";
import OutlookCalendar from "../../../assets/icons/ms-outlook.svg";
import GoToIcon from "../../../assets/icons/goto_icon.svg";
import ExchangeCalendar from "../../../assets/icons/exchange-calendar.svg";
import ZoomIcon from "../../../assets/icons/zoom.svg";
import TeamsIcon from "../../../assets/icons/Microsoft_Teams.svg";
import GoogleMeet from "../../../assets/icons/google-meet.svg";

const AllIntegrations = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: false,
  });

  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);

  const [rowData, setRowData] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);





  return (
    <>
      <Row className="all-integ">
        <Loader isShowLoader={isPageLoading} />
        <Col sm="12">
          <Filter
            name="Integrations"
            showButtons={false}
          >
          </Filter>
        </Col>
        <Col sm="12">
          <div className="integ-banner">
            <Row className="justify-content-between align-items-center">
              <Col md="8" lg="7">
                <h1>Connect Salesforce to report on how meetings drive your outcomes</h1>
              </Col>
              <Col md="4" lg="5">
                <img src={IntegrationGoupImg} alt="All Integration" />
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm="12">
          <Row>
            <Col md="3" xl="2">
              <div className="int-sidebar">
                <button className="btn active">
                   All Integrations
                </button>
                <button className="btn">
                  Calendars
                </button>
                <button className="btn">
                  Venues
                </button>
                <button className="btn">
                  Zapier
                </button>
              </div>
            </Col>
            <Col md="9" xl="10">
              <div className="int-content">
                <h2>All Integrations</h2>
                <Row>
                  <Col md="6" lg="4">
                    <Link to="all-integrations" className="int-col">
                      <div className="img">
                        <img src={GoogleCalendar} alt="Google Calendar" />
                      </div>
                      <div className="text">
                        <h3>Google Calendar</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing an typesetting industry.</p>
                      </div>
                    </Link>
                  </Col>
                  <Col md="6" lg="4">
                    <Link to="all-integrations" className="int-col">
                      <div className="img">
                        <img src={MsOffice} alt="Google Calendar" />
                      </div>
                      <div className="text">
                        <h3>Office 365 Calendar</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing an typesetting industry.</p>
                      </div>
                    </Link>
                  </Col>
                  <Col md="6" lg="4">
                    <Link to="all-integrations" className="int-col">
                      <div className="img">
                        <img src={ICloud} alt="Google Calendar" />
                      </div>
                      <div className="text">
                        <h3>Icloud Calendar</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing an typesetting industry.</p>
                      </div>
                    </Link>
                  </Col>
                  <Col md="6" lg="4">
                    <Link to="all-integrations" className="int-col">
                      <div className="img">
                        <img src={OutlookCalendar} alt="Google Calendar" />
                      </div>
                      <div className="text">
                        <h3>Outlook Calendar</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing an typesetting industry.</p>
                      </div>
                    </Link>
                  </Col>
                  <Col md="6" lg="4">
                    <Link to="all-integrations" className="int-col">
                      <div className="img">
                        <img src={GoToIcon} alt="Google Calendar" />
                      </div>
                      <div className="text">
                        <h3>Goto Meeting</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing an typesetting industry.</p>
                      </div>
                    </Link>
                  </Col>
                  <Col md="6" lg="4">
                    <Link to="all-integrations" className="int-col">
                      <div className="img">
                        <img src={ExchangeCalendar} alt="Google Calendar" />
                      </div>
                      <div className="text">
                        <h3>Exchange Calendar</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing an typesetting industry.</p>
                      </div>
                    </Link>
                  </Col>
                  <Col md="6" lg="4">
                    <Link to="all-integrations" className="int-col">
                      <div className="img">
                        <img src={ZoomIcon} alt="Google Calendar" />
                      </div>
                      <div className="text">
                        <h3>Zoom</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing an typesetting industry.</p>
                      </div>
                    </Link>
                  </Col>
                  <Col md="6" lg="4">
                    <Link to="all-integrations" className="int-col">
                      <div className="img">
                        <img src={TeamsIcon} alt="Google Calendar" />
                      </div>
                      <div className="text">
                        <h3>Microsoft Teams</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing an typesetting industry.</p>
                      </div>
                    </Link>
                  </Col>
                  <Col md="6" lg="4">
                    <Link to="all-integrations" className="int-col">
                      <div className="img">
                        <img src={GoogleMeet} alt="Google Calendar" />
                      </div>
                      <div className="text">
                        <h3>Google Meet</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing an typesetting industry.</p>
                      </div>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AllIntegrations;
