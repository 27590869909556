import {
  SET_FILTER_VISIBILITY_STATUS,
  ON_UPDATE_FILTER,
  ON_CLEAR_FILTER,
} from "../actions/types";
import isEmpty from "../validation/is-empty";

const initialState = {
  filterShow: false,
  searchFilters: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    // set filter visibility
    case SET_FILTER_VISIBILITY_STATUS:
      return {
        ...state,
        filterShow: action.payload,
      };

    // Case for on updating filters object when value is added or removed
    case ON_UPDATE_FILTER:
      const { name, value } = action.payload;
      const searchFilters = { ...state.searchFilters };
      if (isEmpty(value)) {
        delete searchFilters[name];
      } else {
        searchFilters[name] = value;
      }
      return {
        ...state,
        searchFilters,
      };

    case ON_CLEAR_FILTER:
      return {
        ...state,
        filterShow: false,
        searchFilters: {},
      };

    default:
      return state;
  }
};
