import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Button,
  Input,
  FormFeedback,
} from "reactstrap";
import { Formik, Form } from "formik";

import * as Yup from "yup";
import FormField from "../../../../components/FormField/FormField";
import { notification } from "../../../../utils/utility";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const validation = Yup.object().shape({
  first_name: Yup.string().required("First name is required."),
  last_name: Yup.string().required("Last name is required."),
  phone: Yup.string().required("Phone is required."),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      "Please enter valid email"
    )
    .required("Email is required"),
});

let initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  address_line_1: "",
  country_id: "",
  state: "",
  city: "",
  zip: "",
};

const CreateModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);

  /**
   * on toggle modal
   */
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button
        className="btn btn-outline btn-add mr-4"
        type="button"
        style={{ width: "126px" }}
        onClick={(event) => {
          event.preventDefault();
          toggle();
        }}
      >
        Add New
      </Button>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="event-modal modal-lg modal-dialog"
      >
        <ModalBody>
          <div className="">
            <div className="box_content">
              <div className="rd_inputselectheadermodalsetting">
                <button className="rd_colsebtn" onClick={toggle}></button>
              </div>

              <h5 className="rd_modalheadertext rd_modalheadertextnm">
                Add Customer
              </h5>

              <Formik
                validationSchema={validation}
                initialValues={formValues || initialValues}
                enableReinitialize
                onSubmit={(values) => {
                  const { onAddRecord } = props;
                  let formData = {
                    ...values,
                  };

                  toggle();
                  onAddRecord(formData);
                }}
              >
                {(formProps) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                  } = formProps;
                  return (
                    <Form>
                      <Row>
                        <Col lg="6" md="6">
                          <label className="same-label" htmlFor="coupon_code">
                            First Name *
                          </label>
                          <FormField
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            value={values.first_name}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "first_name", value },
                              });
                            }}
                          />
                        </Col>

                        <Col lg="6" md="6">
                          <label className="same-label" htmlFor="coupon_code">
                            Last Name *
                          </label>
                          <FormField
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            value={values.last_name}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "last_name", value },
                              });
                            }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6" md="6">
                          <label className="same-label" htmlFor="coupon_code">
                            Email *
                          </label>
                          <FormField
                            type="text"
                            name="email"
                            placeholder="Email"
                            value={values.email}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "email", value },
                              });
                            }}
                          />
                        </Col>

                        <Col lg="6" md="6">
                          <label className="same-label" htmlFor="coupon_code">
                            Phone *
                          </label>
                          <PhoneInput
                            containerClass="custom_phn_inp"
                            country={"us"}
                            value={values.phone}
                            onBlur={() => {
                              setFieldTouched(`phone`, true);
                            }}
                            onChange={(value) => {
                              handleChange({
                                target: { name: "phone", value },
                              });
                            }}
                          />
                          {errors[`phone`] && touched[`phone`] && (
                            <FormFeedback className="d-block">
                              {errors[`phone`]}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" lg="12" className="pt-3">
                          <div className="form-group event-form-group">
                            <label>Address Line 1</label>
                            <Input
                              type="textarea"
                              className="form-control"
                              name="address_line_1"
                              placeholder="Address"
                              value={values.address_line_1}
                              onChange={({ target }) => {
                                const { name, value } = target || {};
                                handleChange({
                                  target: { name: "address_line_1", value },
                                });
                              }}
                            />
                            <p className="info_txt">
                              Street address, P.O. box, Company name C/o
                            </p>
                          </div>
                        </Col>
                        <Col md="12" lg="12" className="pt-3">
                          <div className="form-group event-form-group">
                            <label>Address Line 2</label>
                            <Input
                              type="textarea"
                              className="form-control"
                              name="address_line_2"
                              placeholder="Address"
                              value={values.address_line_2}
                              onChange={({ target }) => {
                                const { name, value } = target || {};
                                handleChange({
                                  target: { name: "address_line_2", value },
                                });
                              }}
                            />
                            <p className="info_txt">
                              Apartment, suite, unit, building, floor, etc
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6" md="6">
                          <label className="same-label" htmlFor="coupon_code">
                            City
                          </label>
                          <FormField
                            type="text"
                            name="city"
                            placeholder="City"
                            errors={errors}
                            touched={touched}
                            value={values.city}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "city", value },
                              });
                            }}
                          />
                        </Col>

                        <Col lg="6" md="6">
                          <label className="same-label" htmlFor="coupon_code">
                            State/Province/Region
                          </label>
                          <FormField
                            type="text"
                            name="state"
                            placeholder="State"
                            errors={errors}
                            touched={touched}
                            value={values.state}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "state", value },
                              });
                            }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6" md="6">
                          <label className="same-label" htmlFor="coupon_code">
                            Zip/Postal code
                          </label>
                          <FormField
                            type="text"
                            name="zip"
                            placeholder="Zip"
                            errors={errors}
                            touched={touched}
                            value={values.zip}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "zip", value },
                              });
                            }}
                          />
                        </Col>
                        <Col lg="6" md="6">
                          <label className="same-label" htmlFor="coupon_code">
                            Country
                          </label>
                          <FormField
                            placeholder="Country"
                            showPlaceholder={true}
                            type="select"
                            name="country_id"
                            errors={errors}
                            touched={touched}
                            options={props.countries}
                            value={values.country_id.toString()}
                          />
                        </Col>
                      </Row>
                      {/* Timezone */}
                      <Row className="mb-5">
                        {/* <Col md="12">
                        <label className="same-label" htmlFor="coupon_code">
                            Country
                          </label>
                          <FormField
                              placeholder="Select Timezone"
                              showPlaceholder={true}
                              type="select"
                              name="timezone_id"
                              errors={errors}
                              touched={touched}
                              value={values.timezone_id.toString()}
                              options={timezones}
                            />
                        </Col> */}
                      </Row>
                      <div className="rd_cancelappbtnmodal">
                        <button
                          type="button"
                          className="rd_cancel"
                          onClick={toggle}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-app">
                          Save
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateModal;
