import axios from "../axios-instance";
/*
import {
  SEO_START,
  SEO_ERROR,
  SEO_SUCCESS,
  SEO_FAIL,
  IsDataSubmitedSuccessfully,
} from "../constants/seo";
*/
import { getAdminBusinessId } from "../utils/authHelper";

/**
 * Get admin settings marketing tools
 * @param {*} options
 * @returns
 */
export const getSEO = (options) => async (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get("admin/settings/seo/" + getAdminBusinessId())
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * save company details
 * @param {*} options
 */
export const saveSEO = (options) => {
  const { onSuccess, onError, data } = options || {};
  data.business_id = getAdminBusinessId();
  axios
    .post("admin/settings/seo/save", data)
    .then((res) => {
      const { data: resData } = res || {};
      if (onSuccess) {
        onSuccess(resData);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message = errorData.message || error.message;
      if (onError) {
        onError(message);
      }
    });
};

/*
export const getSeoData = (id) => (dispatch) => {
  dispatch({
    type: SEO_START,
  });
  axios
    .get("admin/settings/seo/" + getAdminBusinessId())
    .then((res) => {
      dispatch({
        type: SEO_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: SEO_FAIL,
        payload: err.response.data.data,
      })
    );
};
*/

/*
export const updateSeoData = (thriveData) => (dispatch) => {
  dispatch({
    type: SEO_START,
  });
  axios
    .post("admin/settings/seo/save", thriveData)
    .then((res) => {
      dispatch({
        type: IsDataSubmitedSuccessfully,
        IsDataSubmitedSuccessfully: true,
      });
      getSeoData(1)(dispatch);
    })
    .catch((err) =>
      dispatch({
        type: SEO_ERROR,
        payload: err.response.data.data,
      })
    );
};
*/
