export const USER_TYPE_PREFIX = "tbook2020-abcxyz-";
export const APP_KEY = "meetocto2022-ssssscy";

/**
 * Websockets settings
 */
export const PUSHER_APP_ID = "1";
export const PUSHER_APP_KEY = "public-key-123";
export const LARAVEL_ECHO_WS_HOST = "ws.thrive-booking.com";
export const PUSHER_APP_CLUSTER = "ap2";
export const LARAVEL_ECHO_WS_PORT = 443;
export const LARAVEL_ECHO_WSS_PORT = 443;
