import axios from "../../axios-instance";
import { getAdminBusinessId } from "../../utils/authHelper";

/**
 * calendar view or list view bookings
 * @param {*} options
 * @returns
 */
export const getCalendarOrListBookings = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post(`admin/calendar-or-list-bookings`, data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};
