import axios from "../axios-instance";
import { fallBackErrorMessage } from "../constants/event";
import { getLoggedInUserId } from "../utils/authHelper";
import { SET_USER_AND_PLAN_DETAILS } from "./types";

/**
 * Get user and plan details
 * @param {*} options
 * @return
 */
export const getUserAndPlanDetails = (options) => (dispatch, getState) => {
  const { subscription } = getState();
  const { onSuccess, onError } = options || {};
  axios
    .get(`user-details/${getLoggedInUserId()}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data: details } = resData || {};

      axios
        .get("/admin/plans")
        .then((response) => {
          let userPlan = null;
          response.data.map((plan) => {
            if (plan.id === subscription.userPlan.plan_id) {
              userPlan = {
                ...subscription.userPlan,
                monthly_price: plan.monthly_price,
              };
            }
          });
          dispatch({
            type: SET_USER_AND_PLAN_DETAILS,
            payload: {
              userPlan: {
                plan_id: details.plan_id,
                plan_period_type: details.plan_period_type,
                plan_type: details.plan_type,
                is_paid_subscription: details.is_paid_subscription,
                monthly_price: 0,
                plan_price: details.plan_price,
              },
              admin: {
                id: details.id,
                first_name: details.first_name,
                last_name: details.last_name,
                username: details.username,
                email: details.email,
                phone: details.phone,
              },
              plans: [...response.data],
            },
          });
        })
        .catch((error) => {
          console.error(error);
        });

      if (onSuccess) {
        onSuccess();
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * get user details
 * @param {*} options
 * @returns
 */
export const getUserDetails = (options) => (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get(`user-details/${getLoggedInUserId()}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        //const { venues } = data || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * on cancel plan
 * @param {*} options
 */
export const onCancelPlan = (options) => (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/cancel-plan", data)
    .then((res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * on direct upgrade plan (user directly click on upgrade plan), moving from
 * one paid plan to another paid
 * @param {*} options
 */
export const upgradePlan = (options) => (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/upgrade-plan", data)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data.message);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};
