import React from "react";

import Integrations from "../../views/integrations/index";
import ZoomIntegration from "../../views/integrations/zoom/index";
import GoogleMeetIntegration from "../../views/integrations/meet/index";

const routes = [
    {
        component: Integrations,
        exact: true,
        path: "/integrations"
    },
    {
        component: ZoomIntegration,
        exact: true,
        path: "/integrations/zoom"
    },
    {
        component: GoogleMeetIntegration,
        exact: true,
        path: "/integrations/google-meet"
    }
];

export default routes;