import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getStaffBookingHistory } from "../../../actions/staffActions";
import { toggleFilter } from "../../../actions/filterActions";
import isEmpty from "../../../validation/is-empty";
import Filter from "../../../components/Filter/Filter";
import PaginatorDropdown from "../../../components/Datatable/PaginationDropdown";
import { Table } from "antd";
import { Col, Row } from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import BookingHistoryFilters from "./BookingHistoryFilters";

const BookingHistory = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  });

  const columns = [
    {
      title: "Event",
      dataIndex: "event_name",
      sorter: true,
    },
    {
      title: "Booking",
      dataIndex: "booking",
      render: (_, data) => {
        return data.booking_date + " " + data.booking_time;
      },
      sorter: false,
    },
    {
      title: "Venue",
      dataIndex: "venue",
      sorter: false,
    },
    {
      title: "Event Type",
      dataIndex: "event_format",
      sorter: false,
    },
    {
      title: "Invitee",
      dataIndex: "booking",
      render: (_, data) => {
        return data.invitee_first_name + " " + data.invitee_last_name;
      },
      sorter: false,
    },
    {
      title: "Invitee Phone",
      dataIndex: "invitee_phone",
      sorter: false,
    },
    {
      title: "Invitee Email",
      dataIndex: "invitee_email",
      sorter: false,
    },
    {
      title: "Amount",
      dataIndex: "booking_total_amount",
      sorter: false,
    },
    {
      title: "Commission",
      dataIndex: "commission",
      sorter: false,
    },
  ];

  const [data, setData] = useState([]);

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  const prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * on filter data table
   * @param {*} name
   * @param {*} value
   */
  const filterRecords = () => {
    const { toggleFilter, filter } = props;
    toggleFilter(false);
    handleTableChange(
      {
        current: 1,
        pageSize: 10,
      },
      {
        ...filter.searchFilters,
      },
      {}
    );
  };

  /**
   * fetch data table data
   * @param {*} params
   */
  const fetchTableData = (params = {}) => {
    const { getStaffBookingHistory } = props;
    setIsDataLoading(true);
    getStaffBookingHistory({
      data: prepareDataParam(params),
      onSuccess: function (data) {
        setData(data.entity);
        setPagination({
          ...params.pagination,
          total: data.totalCount,
        });
        setIsDataLoading(false);
      },
      onError: function (error) {
        setData([]);
        console.log("error", error);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on clear filter handler
   */
  const clearFilterHandler = () => {
    handleTableChange(
      {
        ...pagination,
      },
      {},
      {}
    );
  };

  /**
   * on page size drop down change
   * @param {*} e  event
   */
  const onPageSizeChange = (e) => {
    handleTableChange(
      {
        //current: pagination.current,
        current: 1,
        pageSize: e.target.value,
        total: pagination.total,
      },
      {},
      {}
    );
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  const handleTableChange = (pagination, filters, sorter) => {
    fetchTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  useEffect(() => {
    fetchTableData({ pagination });
  }, []);

  return (
    <>
      <Row className="app-user-list">
        <Loader isShowLoader={isPageLoading} />
        <Col sm="12">
          <Filter
            name="My Booking History"
            onFilterRecords={filterRecords}
            onClearFilterHandler={clearFilterHandler}
          >
            <BookingHistoryFilters />
          </Filter>
        </Col>

        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={pagination}
          loading={isDataLoading}
          onChange={handleTableChange}
        ></Table>

        <Col sm="6" style={{ marginTop: "-58px" }}>
          <PaginatorDropdown
            onPageSizeChange={onPageSizeChange}
            pageSize={pagination.pageSize}
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  getStaffBookingHistory,
  toggleFilter,
})(BookingHistory);
