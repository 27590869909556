


export const AdminDashboard_START = 'AdminDashboard_START';
export const AdminDashboard_END = 'AdminDashboard_END';
export const AdminDashboard_ERROR = 'AdminDashboard_ERROR';
export const AdminDashboardFirst_SUCCESS = 'AdminDashboardFirst_SUCCESS';
export const AdminDashboardSecond_SUCCESS = 'AdminDashboardSecond_SUCCESS';
export const AdminDashboardThird_SUCCESS = 'AdminDashboardThird_SUCCESS';
export const AdminDashboardForthPortion_SUCCESS = 'AdminDashboardForthPortion_SUCCESS';
export const AdminDashboardFifthPortion_SUCCESS = 'AdminDashboardFifthPortion_SUCCESS';

export const AdminDashboard_FAIL = 'AdminDashboard_FAIL';
export const IsDataSubmitedSuccessfullyAdminDashboard ='IsDataSubmitedSuccessfullyAdminDashboard';