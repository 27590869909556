import React from 'react';
import OutlookCalendarIcon from "../../../assets/icons/ms-outlook.svg";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";


const OutlookCalendar = () => {
    return (
        <Col md="6" xxl="3" xl="4">
            <Link to="/admin/calendar-integration" className="int-col">
            <div className="img">
                <img
                src={OutlookCalendarIcon}
                alt="Outlook Calendar"
                />
            </div>
            <div className="text">
                <h3>Outlook Calendar</h3>
                <p>
                Lorem Ipsum is simply dummy text of the
                printing an typesetting industry.
                </p>
            </div>
            </Link>
        </Col>
    );
}

export default OutlookCalendar;