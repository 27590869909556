import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Loader from "../../../components/Loader/Loader";
import { Input, Button, Col, Row } from "reactstrap";
import Tooltip from "../../../components/Tooltip";
import {
  getEventConfigData,
  saveEventConfigData,
} from "../../../actions/eventSettingsActions";
import { connect } from "react-redux";
import { notification } from "../../../utils/utility";
import isEmpty from "../../../validation/is-empty";
import TimeField from "../../../components/Time";
const validation = Yup.object().shape({
  time_format: Yup.string().required("Time format is required."),
});

let initialValues = {
  event_duration: 0,
  cancellation_buffer_time: 0,
  reschedule_buffer_time: 0,
  min_adv_booking_time: 0,
  max_adv_booking_time: 0,
  time_format: "",
  date_picker_format: "",
  calendar_default_view: "",
  calendar_first_day: "",
  staff_can_reschedule_booking: "D",
  staff_can_cancel_booking: "D",
  staff_can_confirm_booking: "D",
};

const EventSettings = (props) => {
  const [pageLoading, setPageLoading] = useState(false);

  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    const { getEventConfigData } = props;

    setPageLoading(true);

    getEventConfigData({
      data: {},
      onSuccess: function (details) {
        const { data } = details;
        if (!isEmpty(data)) {
          setFormValues({
            ...data,
          });
        }
        setPageLoading(false);
      },
      onError: function (error) {
        setPageLoading(false);
      },
    });
  }, []);

  return (
    <div className="eventdetailsaddbox rd_noshadow">
      <Loader isShowLoader={pageLoading}></Loader>
      <Row>
        <Col sm="12">
          <div
            className={`filter_bar d-flex justify-content-between align-items-center `}
          >
            <h3>Event Settings</h3>
          </div>
        </Col>
      </Row>
      <Formik
        validationSchema={validation}
        initialValues={formValues || initialValues}
        enableReinitialize
        onSubmit={(values) => {
          const { saveEventConfigData } = props;
          setPageLoading(true);

          saveEventConfigData({
            data: {
              ...values,
            },
            onSuccess: (response) => {
              if (response.success) {
                notification.success(response.message);
              }
              setPageLoading(false);
              console.log("response", response);
            },
            onError: (error) => {
              notification.error(
                "An error occured during save, Please try later"
              );
              setPageLoading(false);
              console.log("company error ", error);
            },
          });
        }}
      >
        {(formProps) => {
          const { values, errors, touched, handleChange, setFieldValue } =
            formProps;

          return (
            <Form>
              <div className="">
                <div className="row">
                  <br />
                  <div className="col-lg-8">
                    <div className="rd_vacationfilterpart rd_vacationfilterpartema d-block">
                      <div class="rd_speciheh3 rd_vacationflex2">
                        <h3>Appointment Setting</h3>
                      </div>
                      <div className="rd_vacationflex2">
                        <p>
                          Default Event Duration <Tooltip code="P2F1" />
                        </p>
                        <div className="rd_profilethingco">
                          <TimeField
                            defaultValue={parseInt(values.event_duration) || 0}
                            onChange={(value) => {
                              handleChange({
                                target: {
                                  name: "event_duration",
                                  value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="rd_vacationflex2">
                        <p>
                          Cancellation Buffer Time <Tooltip code="P2F2" />
                        </p>
                        <div className="rd_profilethingco">
                          <TimeField
                            defaultValue={
                              parseInt(values.cancellation_buffer_time) || 0
                            }
                            onChange={(value) => {
                              handleChange({
                                target: {
                                  name: "cancellation_buffer_time",
                                  value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="rd_vacationflex2">
                        <p>
                          Reschedule Buffer Time <Tooltip code="P2F3" />
                        </p>
                        <div className="rd_profilethingco">
                          <TimeField
                            defaultValue={
                              parseInt(values.reschedule_buffer_time) || 0
                            }
                            onChange={(value) => {
                              handleChange({
                                target: {
                                  name: "reschedule_buffer_time",
                                  value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="rd_vacationflex2">
                        <p>
                          Min Adv. Booking Time <Tooltip code="P2F4" />
                        </p>
                        <div className="rd_profilethingco">
                          <TimeField
                            defaultValue={
                              parseInt(values.min_adv_booking_time) || 0
                            }
                            onChange={(value) => {
                              handleChange({
                                target: {
                                  name: "min_adv_booking_time",
                                  value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="rd_vacationflex2">
                        <p>
                          Max Adv. Booking Time <Tooltip code="P2F5" />
                        </p>
                        <div className="rd_profilethingco">
                          <TimeField
                            defaultValue={
                              parseInt(values.max_adv_booking_time) || 0
                            }
                            onChange={(value) => {
                              handleChange({
                                target: {
                                  name: "max_adv_booking_time",
                                  value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 mt-3 mt-lg-0">
                    <div className="rd_vacationfilterpart rd_vacationfilterpartema d-block">
                      <div class="">
                        <div className="rd_speciheh3 rd_vacationflex2">
                          <h3 class>Calendar Setting</h3>
                        </div>
                        <div className="rd_vacationflex2 py-3">
                          <p>
                            Time Format * <Tooltip code="P2F6" />
                          </p>
                          <div className="rd_profilethingco">
                            <Input
                              type="select"
                              name="time_format"
                              id="time-format"
                              value={values.time_format}
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: "time_format",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            >
                              <option value="">Select Time Format</option>
                              <option value={24}>24 Hours</option>
                              <option value={12}>12 Hours</option>
                            </Input>
                          </div>
                        </div>

                        <div className="rd_vacationflex2 py-3">
                          <p>
                            Date Picker Format <Tooltip code="P2F7" />
                          </p>
                          <div className="rd_profilethingco">
                            <Input
                              type="select"
                              name="date_picker_format"
                              id="date-picker-format"
                              value={values.date_picker_format}
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: "date_picker_format",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            >
                              <option value="">Select Format</option>
                              <option value="mm-dd-yyyy">mm-dd-yyyy</option>
                              <option value="mm/dd/yyyy">mm/dd/yyyy</option>
                              <option value="yyyy/mm/dd">yyyy/mm/dd</option>
                            </Input>
                          </div>
                        </div>

                        <div className="rd_vacationflex2 py-3">
                          <p>
                            Calendar Default View <Tooltip code="P2F8" />
                          </p>
                          <div className="rd_profilethingco">
                            <Input
                              type="select"
                              name="calendar_default_view"
                              id="calendar-default-view"
                              value={values.calendar_default_view}
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: "calendar_default_view",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            >
                              <option value="">Calendar Default View</option>
                              <option value="monthly">Monthly</option>
                              <option value="weekly">Weekly</option>
                              <option value="daily">Daily</option>
                            </Input>
                          </div>
                        </div>

                        <div className="rd_vacationflex2 py-3">
                          <p>
                            Calendar First Day <Tooltip code="P2F9" />
                          </p>
                          <div className="rd_profilethingco">
                            <Input
                              type="select"
                              name="calendar_first_day"
                              id="calendar-first-day"
                              value={values.calendar_first_day}
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: "calendar_first_day",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            >
                              <option value="">Calendar First Day</option>
                              <option value="1">Monday</option>
                              <option value="2">Tuesday</option>
                              <option value="3">Wenesday</option>
                              <option value="4">Thursday</option>
                              <option value="5">Friday</option>
                              <option value="6">Saturday</option>
                              <option value="7">Sunday</option>
                            </Input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="rd_vacationfilterpart rd_vacationfilterpartema d-block pb-0">
                      <div class="">
                        <div className="rd_speciheh3 rd_vacationflex2">
                          <h3 class>Allow Staff To :</h3>
                        </div>
                        <div
                          class="toggle_field"
                          style={{ padding: "14px 20px 10px" }}
                        >
                          <div class="box_content w- p-0">
                            <div class="form-check form-switch mw-100">
                              <label class="form-check-label" for="">
                                Reschedule <Tooltip code="P2F10" />
                              </label>
                              <input
                                class="form-check-input"
                                style={{ float: "right" }}
                                checked={
                                  values.staff_can_reschedule_booking === "E"
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  handleChange({
                                    target: {
                                      name: "staff_can_reschedule_booking",
                                      value: event.target.checked ? "E" : "D",
                                    },
                                  });
                                }}
                                type="checkbox"
                                id="flexSwitchCheckChecked3"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="toggle_field"
                          style={{ padding: "10px 20px" }}
                        >
                          <div class="box_content w- p-0">
                            <div class="form-check form-switch mw-100">
                              <label class="form-check-label" for="">
                                Cancel <Tooltip code="P2F11" />
                              </label>
                              <input
                                class="form-check-input"
                                style={{ float: "right" }}
                                checked={
                                  values.staff_can_cancel_booking === "E"
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  handleChange({
                                    target: {
                                      name: "staff_can_cancel_booking",
                                      value: event.target.checked ? "E" : "D",
                                    },
                                  });
                                }}
                                type="checkbox"
                                id="flexSwitchCheckChecked3"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="toggle_field"
                          style={{ padding: "10px 20px" }}
                        >
                          <div class="box_content w- p-0">
                            <div class="form-check form-switch mw-100">
                              <label class="form-check-label" for="">
                                Confirm <Tooltip code="P2F12" />
                                <span
                                  style={{
                                    display: "block",
                                    fontSize: 12,
                                    color: "#8F8F8F",
                                  }}
                                >
                                  Recommended 'Disable'
                                </span>
                              </label>
                              <input
                                class="form-check-input"
                                style={{ float: "right" }}
                                checked={
                                  values.staff_can_confirm_booking === "E"
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  handleChange({
                                    target: {
                                      name: "staff_can_confirm_booking",
                                      value: event.target.checked ? "E" : "D",
                                    },
                                  });
                                }}
                                type="checkbox"
                                id="flexSwitchCheckChecked3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Col sm="12" className="submit_btn">
                  <Button
                    type="submit"
                    className="btn btn-app col-md-2 col-lg-2 col-sm-12 mb-5"
                  >
                    Save
                  </Button>
                </Col>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default connect(null, {
  getEventConfigData,
  saveEventConfigData,
})(EventSettings);
