import React, { useState, useEffect } from "react";
import { CardBody, Button, Col } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { notification } from "../../../utils/utility";
import "../../../assets/scss/plugins/extensions/dropzone.scss";
import { connect } from "react-redux";
import { updateMyChangePassword } from "../../../actions/myChangePassword";
import Loader from "../../../components/Loader/Loader";
import FormField from "../../../components/FormField/FormField";

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string().required("Password is required."),
  new_password: Yup.string().required("New Password is required."),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("new_password"), null],
      "Password and Confirm Password must match"
    )
    .required("Confirm Password is required."),
});

const ProfileChangePassword = (props) => {
  const [isShowLoader, setIsShowLoader] = useState(false);
  useEffect(() => {
    if (props.IsDataSubmitedSuccessfullyChangePassword) {
      setIsShowLoader(false);
      notification.success("Password Updated Successfully");
    }

    if (props.IsError) {
      setIsShowLoader(false);
      notification.error("something went wrong");
    }
  }, [props.IsDataSubmitedSuccessfullyChangePassword, props.IsError]);

  return (
    <CardBody className="p-0">
      <Loader isShowLoader={isShowLoader}></Loader>
      <Formik
        initialValues={{
          password: "",
          new_password: "",
          confirm_password: "",
        }}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values) => {
          setIsShowLoader(true);
          props.updateMyChangePassword(values);
        }}
      >
        {(formProps) => {
          const { values, errors, touched, handleChange, setFieldValue } =
            formProps;

          return (
            <Form>
              <div class="rd_vacationfilterpart rd_vacationfilterpart3">
                {/* Change Password */}
                <div class="rd_profilerd_erpart">
                  <div className="col-12" style={{ padding: '15px 20px' }}>
                    <h3>Change Password</h3>
                  </div>
                  <div className="col-md-4 px-0">
                    <div class="rd_vacationflex2">
                      <p>Old Password *</p>
                      <div class="rd_profilethingco">
                        <FormField
                          type="password"
                          name="password"
                          placeholder="Old Password"
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "password", value },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 px-0">
                    <div class="rd_vacationflex2">
                      <p>New Password *</p>
                      <div class="rd_profilethingco">
                        <FormField
                          type="password"
                          name="new_password"
                          placeholder="New Password"
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "new_password", value },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 px-0">
                    <div class="rd_vacationflex2">
                      <p>Confirm Password *</p>
                      <div class="rd_profilethingco">
                        <FormField
                          type="password"
                          name="confirm_password"
                          placeholder="Confirm Password"
                          errors={errors}
                          touched={touched}
                          onChange={({ target }) => {
                            const { value } = target;
                            handleChange({
                              target: { name: "confirm_password", value },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Change Email */}
                <div class="rd_profilerd_erpart">
                  <div className="col-12" style={{ padding: '15px 20px' }}>
                    <h3>Change Email</h3>
                  </div>
                  <div className="col-md-4 px-0">
                    <div class="rd_vacationflex2">
                      <p>Old Email *</p>
                      <div class="rd_profilethingco">
                        <FormField
                          type="email"
                          name="email"
                          placeholder="Old Email"
                          errors={errors}
                          touched={touched}
                          // onChange={({ target }) => {
                          //   const { value } = target;
                          //   handleChange({
                          //     target: { name: "password", value },
                          //   });
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 px-0">
                    <div class="rd_vacationflex2">
                      <p>New Email *</p>
                      <div class="rd_profilethingco">
                        <FormField
                          type="email"
                          name="new_email"
                          placeholder="New Email"
                          errors={errors}
                          touched={touched}
                          // onChange={({ target }) => {
                          //   const { value } = target;
                          //   handleChange({
                          //     target: { name: "new_password", value },
                          //   });
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 px-0">
                    <div class="rd_vacationflex2">
                      <p>Confirm Email *</p>
                      <div class="rd_profilethingco">
                        <FormField
                          type="email"
                          name="confirm_email"
                          placeholder="Confirm Email"
                          errors={errors}
                          touched={touched}
                          // onChange={({ target }) => {
                          //   const { value } = target;
                          //   handleChange({
                          //     target: { name: "confirm_password", value },
                          //   });
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Button  */}
                <Col lg="12" md="12" sm="12">
                  <Button
                    type="submit"
                    className="btn btn-app col-md-2 col-lg-2 col-sm-12 float-right mb-5"
                  >
                    Change Password
                  </Button>
                </Col>
              </div>
            </Form>
          );
        }}
      </Formik>
    </CardBody>
  );
};
//}

const mapStateToProps = (state) => {
  return {
    MyChangePasswordSuccess: state.myChangePassword.MyChangePasswordSuccess,
    IsDataSubmitedSuccessfullyChangePassword:
      state.myChangePassword.IsDataSubmitedSuccessfullyChangePassword,
    IsError: state.myChangePassword.IsError,
  };
};

const actionMethods = {
  updateMyChangePassword: updateMyChangePassword,
};

export default connect(mapStateToProps, actionMethods)(ProfileChangePassword);