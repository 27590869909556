import axios from "../axios-instance";
import { fallBackErrorMessage } from "../constants/event";
import { getAdminBusinessId } from "../utils/authHelper";
import { objectToFormData } from "../utils/utility";

export const getVenues = (options) => (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get("venues")
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        const { venues } = data || {};
        onSuccess(venues);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

export const getEventTypes = (options) => (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get("event-types")
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        const { eventTypes } = data || {};
        onSuccess(eventTypes);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

export const saveEventType = (options) => (dispatch) => {
  const { onSuccess, onError, data, eventId } = options || {};

  let formData = data;

  if (eventId) {
    formData = {
      ...data,
      id: eventId,
    };
  }

  axios
    .post("admin/event/save-event-type-page", formData)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        const { data: event } = data || {};
        const { id } = event || {};
        onSuccess(id);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * save event details
 * @param {*} options
 */
export const saveEventDetails = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  let formData = objectToFormData(data);
  axios
    .post("admin/event/save-event-details-page", formData)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        const { data: event } = data || {};
        const { id } = event || {};
        onSuccess(id);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * save event availability page
 * @param {*} options
 */
export const saveEventAvailibility = (options) => {
  const { onSuccess, onError, data } = options || {};

  axios
    .post("admin/event/save-event-availability-page", data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        const { data: event } = data || {};
        const { id } = event || {};
        onSuccess(id);
      }
    })
    .catch((error) => {
      const { data } = error.response.data;
      if (onError) {
        onError(data);
      }
    });
};

/**
 * get event availability page data
 * @param {*} options
 * @returns
 */
export const getEventAvailability = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { id } = data || {};

  axios
    .get(`/admin/event/event-availability-page/${id}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};

      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * save event advanced page
 * @param {*} options
 */
export const saveEventAdvancedPage = (options) => {
  const { onSuccess, onError, data } = options || {};

  axios
    .post("admin/event/save-event-advanced-page", data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        const { data: event } = data || {};
        const { id } = event || {};
        onSuccess(id);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * get event summary
 * @param {*} options
 * @returns
 */
export const getEventSummary = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { id } = data || {};
  axios
    .get(`/admin/event-summary/${id}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};

      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * get event advanced page data
 * @param {*} options
 * @returns
 */
export const getEventAdvancedPage = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { id } = data || {};

  axios
    .get(`/admin/event/event-advanced-page/${id}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};

      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

export const saveAdHocEvent = (options) => {
  const { onSuccess, onError, data, eventId } = options || {};

  let formData = data;

  let apiUrl = "admin/create-adhoc-event";

  if (eventId) {
    formData = {
      ...data,
      id: eventId,
    };

    apiUrl = `admin/update-adhoc-event/${eventId}`;
  }

  // return false;

  axios
    .post(apiUrl, formData)
    .then((res) => {
      const { data: resData } = res || {};
      const { data, message } = resData || {};

      if (onSuccess) {
        const { data: event } = data || {};
        const { id } = event || {};
        onSuccess(message);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * toggle Event secret status
 * @param {*} options
 */
export const toggleEventSecretStatus = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/event/toggle-event-secret-status", data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        const { data: event } = data || {};
        const { id } = event || {};
        onSuccess(id);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * toggle Event status
 * @param {*} options
 */
export const toggleEventStatus = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/event/toggle-event-status", data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        const { data: event } = data || {};
        const { id } = event || {};
        onSuccess(id);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * delete event
 * @param {*} options
 * @returns
 */
export const deleteEvent = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .delete(`admin/event/delete/${data.id}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

// list events

export const getEvents = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post(`admin/events/${getAdminBusinessId()}`, data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * Get adhoc event listing
 * @param {*} options
 * @returns
 */
export const getAdhocEvents = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .get(`admin/adhoc-events/${getAdminBusinessId()}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

export const getEventStaffList = (options) => (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { businessId } = data || {};
  axios
    .post(`/admin/staffs/${businessId}`, {
      pageSize: 1000,
      page: 1,
      sortField: "id",
      sortOrder: "asc",
    })
    .then((res) => {
      const { data: resData } = res || {};
      const { entity: data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

export const getStaffSchedule = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { id } = data || {};

  axios
    .get(`/admin/schedule/get-staff-schedule/${id}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      const { schedules } = data || {};
      if (onSuccess) {
        onSuccess(schedules);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

// duplicate event
export const duplicateEvent = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { id } = data || {};

  axios
    .get(`/admin/event/duplicate/${id}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};

      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

// get base event details
export const getEventTypeDetails = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { id } = data || {};

  axios
    .get(`/admin/event/event-type-page/${id}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};

      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

// get event details

export const getEventDetails = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { id, type: eventType } = data || {};

  axios
    .get(`/admin/event/${eventType}-details-page/${id}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};

      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

// get adhoc events

export const getAdHocEventDetails = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { id } = data || {};

  axios
    .get(`/admin/adhoc-event/${id}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};

      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

export const saveStaffSchedule = (options) => async (dispatch) => {
  console.log({
    options,
  });

  const { onSuccess, onError, data } = options || {};

  axios
    .post("/admin/schedule/save-staff-schedule", data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data, message } = resData || {};

      if (onSuccess) {
        const { data: event } = data || {};
        const { id } = event || {};
        onSuccess(message);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};
