import React, { useEffect, useState } from 'react';
import CheckMark from "../../../../assets/icons/whiteCheckMark.svg";
import isEmpty from '../../../../validation/is-empty';

const ClrPicker = (props) => {
    const [defaultClr, setDefaultClr] = useState("linear-gradient(to right, #FCF3C4, #F962A0, #AA96F9)");
    const [selectedClr, setSelectedClr] = useState("linear-gradient(to right, #FCF3C4, #F962A0, #AA96F9)");
    const [openSelector, setOpenSelector] = useState(false);
    const [inputClr, setinputClr] = useState("#D7CCC8");

    const colors = ["#fe9920", "#0c4767", "#45c48a", "#1fbae7", "#fece42"];
    
    useEffect(() => {
       if ((props.value === 'default' || isEmpty(props.value)) && selectColor !== defaultClr || colors.includes(props.value)) {
            setSelectedClr(defaultClr); 
       } else if (!colors.includes(props.value)) {
            setSelectedClr(props.value);
       }
       console.log('props value', props.value);
    }, [props.value]);

    /**
     * set color picker color
     * @param {*}
     */
    const selectColor = (color) => {
        //setSelectedClr(e.currentTarget.style.backgroundColor);
        setSelectedClr(color);
        props.onColorPickerChange(color);
        setOpenSelector(!openSelector);
    }

    const toggleClrOpt = () => {
        setOpenSelector(!openSelector)
    }
    const handleChange = (e) => {
        setinputClr(e.target.value);
        setSelectedClr(e.target.value);
    }

    return (<>
        <div className="cus_clrpick">
            <div className={`clr_show ${selectedClr !== defaultClr && "active"}`} style={{ background: selectedClr }} onClick={toggleClrOpt}></div>
            {openSelector ? 
            <div className="clr_dropdown">
                <div className="selected_clr" style={{ background: selectedClr, color: selectedClr === "rgb(255, 255, 255)" ? "#0C4767" : "#fff"  }}>
                    {selectedClr === "linear-gradient(to right, #FCF3C4, #F962A0, #AA96F9)" ? "Select Color" : selectedClr}
                </div>
                <div className="clr_options">
                    <div className="selectable_clr" style={{background: "#607D8B"}} onClick={() => { selectColor("#607D8B") }}>
                        { selectedClr === "#607D8B" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#0C4767"}} onClick={() => { selectColor("#0C4767") }}>
                        { selectedClr === "#0C4767" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#00C875"}} onClick={() => { selectColor("#00C875") }}>
                        { selectedClr === "#00C875" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#FF5722"}} onClick={() => { selectColor("#FF5722") }}>
                        { selectedClr === "#FF5722" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#E91E63"}} onClick={() => { selectColor("#E91E63") }}>
                        { selectedClr === "#E91E63" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#795548"}} onClick={() => { selectColor("#795548") }}>
                        { selectedClr === "#795548" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#9E9E9E"}} onClick={() => { selectColor("#9E9E9E") }}>
                        { selectedClr === "#9E9E9E" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#3F51B5"}} onClick={() => { selectColor("#3F51B5") }}>
                    { selectedClr === "#3F51B5" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#2196F3"}} onClick={() => { selectColor("#2196F3") }}>
                        { selectedClr === "#2196F3" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#03A9F4"}} onClick={() => { selectColor("#03A9F4") }}>
                        { selectedClr === "#03A9F4" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#00BCD4"}} onClick={() => { selectColor("#00BCD4") }}>
                        { selectedClr === "#00BCD4" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#009688"}} onClick={() => { selectColor("#009688") }}>
                        { selectedClr === "#009688" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#76FF03"}} onClick={() => { selectColor("#76FF03") }}>
                    { selectedClr === "#76FF03" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#FFEB3B"}} onClick={() => { selectColor("#FFEB3B") }}>
                    { selectedClr === "#FFEB3B" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#1F323B"}} onClick={() => { selectColor("#1F323B") }}>
                    { selectedClr === "#1F323B" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#000000"}} onClick={() => { selectColor("#000000") }}>
                    { selectedClr === "#000000" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#D7CCC8"}} onClick={() => { selectColor("#D7CCC8") }}>
                    { selectedClr === "#D7CCC8" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                    <div className="selectable_clr" style={{background: "#E0E0E0"}} onClick={() => { selectColor("#E0E0E0") }}>
                    { selectedClr === "#E0E0E0" &&  <img src={CheckMark} alt="Check Mark White" /> }
                    </div>
                </div>
                <div className="other_opt">
                    <input type="text" className='form-control' value={inputClr} onChange={handleChange} />
                    <button className='btn btn-outline' onClick={toggleClrOpt}>Cancel</button>
                    <button className='btn btn-app' onClick={toggleClrOpt}>Apply</button>
                </div>
            </div> : null }
            <svg xmlns="http://www.w3.org/2000/svg" width="7.824" height="4.831" viewBox="0 0 7.824 4.831">
                <path id="Path" d="M.919,0,3.912,2.986,6.9,0l.919.919L3.912,4.831,0,.919Z" fill="#818181" />
            </svg>
        </div>
    </>);
}

export default ClrPicker;