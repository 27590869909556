import React from "react";


const Layout = ({ Component, route, props }) => {

    return (
        <>
            <div className="">
                <section>
                    <Component route={route} />
                </section>
            </div>
        </>
    );

};

export default Layout;