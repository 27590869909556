import React from "react";
import { Row, Col } from "reactstrap";
import BillingInfoModal from "./billing-info-modal";

const BillingPage = (props) => {
  return (
    <>
      <div className="billing_info">
        <Row className="justify-content-between">
          <Col xl="5" className="mb-4 mb-xl-0">
            <h3>Payment Method</h3>
            <Row className="justify-content-between">
              <Col xl="6" md="5">
                <p>Payment Type:</p>
                <p>Card Number:</p>
                <p>Cardholder:</p>
                <p>Exp. Date (MM/YY):</p>
              </Col>
              <Col xl="6" md="7">
                <p>
                  <span>Mastercard</span>
                </p>
                <p>
                  <span>xxxxxxxxxxxx1234</span>
                </p>
                <p>
                  <span>John Giggs</span>
                </p>
                <p>
                  <span>12/23</span>
                </p>
              </Col>
            </Row>
          </Col>
          <Col xl="6" className="mb-4 mb-xl-0">
            <h3>Billing Address</h3>
            <Row className="justify-content-between">
              <Col md="5">
                <p>Address:</p>
                <p>Phone Number:</p>
              </Col>
              <Col md="7">
                <p>
                  <span>123 curious road san mateo, CA, 12345, US</span>
                </p>
                <p>
                  <span>024 5848 5879</span>
                </p>
              </Col>
            </Row>
          </Col>
          <Col md="12" className="d-flex justify-content-end">
            <BillingInfoModal />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BillingPage;
