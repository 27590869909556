import axios from "../axios-instance";
import {
  Payment_START,
  Payment_ERROR,
  Payment_SUCCESS,
  PaymentLocallySuccess,
  PaymentPaypalSuccess,
  PaymentStripeSuccess,
} from "../constants/payment";
import { getAdminBusinessId } from "../utils/authHelper";

/**
 * Get admin payment details
 * @param {*} options
 * @returns
 */
export const getAdminPaymentDetails = (options) => async (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get("admin/settings/payment-details/" + getAdminBusinessId())
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * save admin pay settings
 * @param {*} options
 */
export const saveAdminPaymentSetting = (options) => {
  const { onSuccess, onError, data } = options || {};
  data.business_id = getAdminBusinessId();
  axios
    .post("admin/settings/save-payment-setting", data)
    .then((res) => {
      const { data: resData } = res || {};
      if (onSuccess) {
        onSuccess(resData);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message = errorData.message || error.message;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * save admin pay local
 * @param {*} options
 */
export const saveAdminPayLocal = (options) => {
  const { onSuccess, onError, data } = options || {};
  data.business_id = getAdminBusinessId();
  axios
    .post("admin/settings/pay-locally/save", data)
    .then((res) => {
      const { data: resData } = res || {};
      if (onSuccess) {
        onSuccess(resData);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message = errorData.message || error.message;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * save admin paypal
 * @param {*} options
 */
export const saveAdminPaypal = (options) => {
  const { onSuccess, onError, data } = options || {};
  data.business_id = getAdminBusinessId();
  axios
    .post("admin/settings/paypal/save", data)
    .then((res) => {
      const { data: resData } = res || {};
      if (onSuccess) {
        onSuccess(resData);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message = errorData.message || error.message;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * save admin stripe
 * @param {*} options
 */
export const saveAdminStripe = (options) => {
  const { onSuccess, onError, data } = options || {};
  data.business_id = getAdminBusinessId();
  axios
    .post("admin/settings/stripe/save", data)
    .then((res) => {
      const { data: resData } = res || {};
      if (onSuccess) {
        onSuccess(resData);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message = errorData.message || error.message;
      if (onError) {
        onError(message);
      }
    });
};

/*
export const getMyPayment = () => (dispatch) => {
  dispatch({
    type: Payment_START,
  });
  axios
    .get("admin/settings/payment-details/" + getAdminBusinessId())
    .then((res) => {
      dispatch({
        type: Payment_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: Payment_ERROR,
        payload: null,
      })
    );
};
*/

/*
export const updatePayLocal = (paymentData) => (dispatch) => {
  dispatch({
    type: Payment_START,
  });
  axios
    .post("admin/settings/pay-locally/save", paymentData)
    .then((res) => {
      dispatch({
        type: PaymentLocallySuccess,
        PaymentLocallySuccess: true,
      });
    })
    .catch((err) =>
      dispatch({
        type: Payment_ERROR,
        payload: err.response.data.data,
      })
    );
};
*/

/*
export const updatePayPal = (paymentData) => (dispatch) => {
  dispatch({
    type: Payment_START,
  });
  axios
    .post("admin/settings/paypal/save", paymentData)
    .then((res) => {
      dispatch({
        type: PaymentPaypalSuccess,
        PaymentPaypalSuccess: true,
      });
    })
    .catch((err) =>
      dispatch({
        type: Payment_ERROR,
        payload: err.response.data.data,
      })
    );
};
*/

export const updateStripe = (paymentData) => (dispatch) => {
  dispatch({
    type: Payment_START,
  });
  axios
    .post("admin/settings/stripe/save", paymentData)
    .then((res) => {
      dispatch({
        type: PaymentStripeSuccess,
        PaymentStripeSuccess: true,
      });
    })
    .catch((err) =>
      dispatch({
        type: Payment_ERROR,
        payload: err.response.data.data,
      })
    );
};
