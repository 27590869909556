import { TreeSelect } from "antd";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
const { SHOW_PARENT } = TreeSelect;

/*
const treeData = [
  {
    title: <div style={{display: "flex", alignItems: "center"}}><img src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg" alt="" style={{width: 20, height: 20, borderRadius: "50%", marginRight: 10}}/> Option 1</div>,
    value: "0-0",
    key: "0-0"
  },
  {
    title: <div style={{display: "flex", alignItems: "center"}}><img src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg" alt="" style={{width: 20, height: 20, borderRadius: "50%", marginRight: 10}}/> Option 2</div>,
    value: "0-1",
    key: "0-1"
  },
  {
    title: <div style={{display: "flex", alignItems: "center"}}><img src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg" alt="" style={{width: 20, height: 20, borderRadius: "50%", marginRight: 10}}/> Option 3</div>,
    value: "0-2",
    key: "0-2"
  },
  {
    title: <div style={{display: "flex", alignItems: "center"}}><img src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg" alt="" style={{width: 20, height: 20, borderRadius: "50%", marginRight: 10}}/> Option 4</div>,
    value: "0-3",
    key: "0-3"
  },
  {
    title: <div style={{display: "flex", alignItems: "center"}}><img src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg" alt="" style={{width: 20, height: 20, borderRadius: "50%", marginRight: 10}}/> Option 1</div>,
    value: "0-4",
    key: "0-4"
  }
];
*/

const MultiSelect = (props) => {
  const [value, setValue] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(props.isDisabled);
  }, [props.isDisabled]);

  useEffect(() => {
    let defaultValues = [];
    props.defaultValue.forEach((data, index) => defaultValues.push(data.value));
    setValue(defaultValues);
  }, [props.defaultValue]);

  useEffect(() => {
    let data = [];
    props.data.forEach((tData, index) => {
      data.push({
        title: (
          <div style={{ display: "flex", alignItems: "center" }}>
            {tData.title}
          </div>
        ),
        value: tData.value,
        key: `tdata-${index}`,
      });
    });
    setTreeData(data);
  }, [props.data]);

  const onChange = (newValue) => {
    props.onChangeTreeSelect(newValue);
    setValue(newValue);
  };

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    maxTagCount: 2,
    disabled: isDisabled,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Please select",
    style: {
      width: "100%",
    },
  };

  return <TreeSelect {...tProps} dropdownStyle={{ zIndex: "10000" }} />;
};

export default MultiSelect;
