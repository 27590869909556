import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import TimeChooserField from "../Common/TimeChooserField";
import { connect } from "react-redux";
import "react-day-picker/dist/style.css";
import { X, Plus } from "react-feather";
import { getDayId } from "../../../../utils/utility";
import TrashIcon from "../../../../assets/icons/trash_icon.svg";
import PlusIcon from "../../../../assets/icons/plus_icon.svg";

class WeekdayScheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTime: [
        {
          startTime: "09:00am",
          endTime: "05:00pm",
        },
      ],
    };
  }

  /**
   * component did mount
   */
  componentDidMount() {
    const { weekDayId, eventSchedules } = this.props || {};
    const dayId = getDayId(weekDayId);

    let evntSchedules = [];

    (eventSchedules || []).map((schedule, index) => {
      if (schedule.dayId === dayId) {
        (schedule.schedules || []).map((daySchedule, dayScheduleIndex) => {
          evntSchedules.push({
            startTime: daySchedule.start_time,
            endTime: daySchedule.end_time,
          });
        });
      }
    });

    if (evntSchedules.length > 0) {
      this.setState({
        selectedTime: [...evntSchedules],
      });
    }
  }

  toggle = () => {
    const { onToggle } = this.props || {};
    onToggle();
  };

  /**
   * save schedule only in event schedule state
   */
  saveSchedule = () => {
    const { onToggle, weekDayId, businessId } = this.props || {};
    const { selectedTime } = this.state || [];
    const selectEvents = (selectedTime || []).map((time, index) => {
      const { startTime, endTime } = time || {};
      return {
        start_time: startTime,
        end_time: endTime,
      };
    });

    const dayId = getDayId(weekDayId);
    const schedules = [...this.props.eventSchedules];
    const found = schedules.some((el) => el.dayId === dayId);

    if (found) {
      const dayIndex = schedules.findIndex((el) => el.dayId === dayId);
      schedules[dayIndex].schedules = [...selectEvents];
      this.props.setEventSchedules(schedules, this.props.timezoneId);
    } else {
      schedules.push({
        dayId,
        schedules: [...selectEvents],
      });
      this.props.setEventSchedules(schedules, this.props.timezoneId);
    }
    onToggle();
  };

  addOption = () => {
    const { selectedTime } = this.state || {};
    const defaultOption = {
      startTime: "",
      endTime: "",
    };

    const newOptions = [...selectedTime, defaultOption];
    this.setState({
      selectedTime: newOptions,
    });
  };

  onUpdate = (fieldName, fieldValue, index) => {
    const { selectedTime } = this.state || {};
    const newOptions = (selectedTime || []).map((time, key) => {
      const { value } = fieldValue || {};
      if (index === key) {
        return {
          ...time,
          [fieldName]: value,
        };
      }

      return time;
    });

    this.setState({
      selectedTime: newOptions,
    });
  };

  removeOption = (index) => {
    const { selectedTime } = this.state || {};

    if ((selectedTime || []).length > 1) {
      const newOptions = (selectedTime || []).filter(
        (time, currentItem) => parseInt(index) !== parseInt(currentItem)
      );

      this.setState({
        selectedTime: newOptions,
      });
    }
  };

  render() {
    const { isOpen, day } = this.props || {};
    const { selectedTime } = this.state || {};

    return (
      <div className="day-schedule-wrapper">
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          size="sm"
          className="event-modal modal-lg modal-dialog cal_day_popup cal_popup2"
        >
          <div className="cal_day_popup_wrapper">
            <ModalHeader toggle={this.toggle}>{day} availability</ModalHeader>
            <ModalBody>
              <div className="time-interval-wrapper">
                <Label>What hours are you available?</Label>
                {(selectedTime || []).map((time, index) => {
                  const { startTime, endTime } = time || {};
                  return (
                    <Row key={index}>
                      <Col md="5" lg="5" sm="5">
                        <FormGroup>
                          <TimeChooserField
                            name="startTime"
                            index={index}
                            onUpdate={this.onUpdate}
                            interval={startTime}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="5" lg="5" sm="5">
                        <FormGroup>
                          <TimeChooserField
                            name="endTime"
                            index={index}
                            onUpdate={this.onUpdate}
                            interval={endTime}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2" lg="2" sm="2">
                        <div className="icons">
                          <span onClick={() => this.removeOption(index)}>
                            <img src={TrashIcon} alt="Trash Icon" />
                          </span>
                          {index === 0 && (
                            <span onClick={this.addOption}>
                              <img src={PlusIcon} alt="Plus Icon" />
                            </span>
                          )}
                          
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </ModalBody>

            <ModalFooter>
              <div className="action-items">
                <Button className="btn-outline" onClick={this.toggle}>
                  Cancel
                </Button>
                <Button className="btn btn-primary" onClick={this.saveSchedule}>
                  Save
                </Button>
              </div>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    );
  }
}

export default WeekdayScheduleModal;