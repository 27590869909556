import React, { useState, useEffect } from "react";
import { getAdminBusinessId } from "../../../utils/authHelper";
import { BACKEND_API_BASE_URL } from "../../../utils/utility";
import GoogleCalendarSVG from "../../../assets/icons/google_calendar.svg";
import MicrosoftOfficeLogo from "../../../assets/icons/ms-office-360.svg";
import ICloudIcon from "../../../assets/icons/apple-icloud.svg";
import ExchangeCenterIcon from "../../../assets/icons/exchange-calendar.svg";
import OutlookIcon from "../../../assets/icons/ms-outlook.svg";
import OutlookDull from "../../../assets/icons/ms-outlook-dull.svg";
import OctoIcon from "../../../assets/icons/octo_icon.svg";
import OutlookPluginIcon from "../../../assets/icons/icone-outlook-jaune.png";
import * as Icon from "react-feather";
import { Modal, ModalBody } from 'reactstrap';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { GOOGLE_CALENDAR_ID } from "../../../data/integration-app";

const ConnectToCalendarView = (props) => {
  const [dataSet, setDataSet] = useState([]);
  const [icloudModal, setIcloudModal] = useState(false);
  const [outlookModal, setOutlookModal] = useState(false);
  const [exchangeModal, setExchangeModal] = useState(false);

  const exchangeToggle = () => setExchangeModal(!exchangeModal);
  const icloudToggle = () => setIcloudModal(!icloudModal);
  const outlookToggle = () => setOutlookModal(!outlookModal);
  const isDesktop = useMediaQuery('(min-width: 1200px)');
    const isTablet = useMediaQuery('(min-width: 768px)');
    const isMobile = useMediaQuery('(min-width: 250px)');
  useEffect(() => {
    setDataSet(props.data);
  }, []);

  return (
    <section className="integreted-calenders calend-integ w-100">
      {/* <Row>
        <Col sm="12">
          <div
            className={`filter_bar mb-0`}
          >
            <h3>Calendar Connections</h3>
          </div>
        </Col>
      </Row> */}
      <div className="bg-div">
        <div className="section-title d-flex justify-content-between align-items-center">
            <h2>
              Calendar Connections
              
            </h2>
            {dataSet.length > 0 && (
              <a
                href="#"
                className="bootstrap-default-btn btn-calender-account"
                onClick={() => {
                  props.setCalendarIntegrationView("1");
                }}
              >
              Connected Calendars
              </a>
            )}
        </div>
      </div>

      <div className="calender-clients">
        {/* <form> */}
        <div className="row-1 row">
          {!dataSet.some((data) => data.app_id === GOOGLE_CALENDAR_ID) && (
          <div className="col-xl-4 col-lg-6">
            <h2>Google</h2>
            <a
              href={`${BACKEND_API_BASE_URL}google-calendar-auth/${getAdminBusinessId()}`}
            >
              <div className="calender-client-google">
                <label htmlFor="googleCalender" role="button">
                  <img src={GoogleCalendarSVG} alt="" />
                  <span>Google Calender</span>
                </label>
              </div>
            </a>
          </div>
          )} 
          <div className="col-xl-4 col-lg-6">
            <h2>Apple</h2>
            <button className="btn p-0" onClick={icloudToggle}>
              <div className="calender-client-apple">
                {/* <input
                      type="checkbox"
                      name="appleCalender"
                      id="appleCalender"
                    /> */}

                <label role="button">
                  <img src={ICloudIcon} alt="Icloud Icon" />
                  <span>I cloud Calendar</span>
                </label>
              </div>
            </button>
            <Modal className="cale_con_modal" isOpen={icloudModal} toggle={icloudToggle} centered={true}>
              <ModalBody>
                <div className="head">
                  <div className="img">
                    <img src={OctoIcon} alt="Octo Icon" />
                  </div>
                  <hr className="my-0" />
                  <Icon.Plus size={20} color="#0C4767" strokeWidth={2} />
                  <hr className="my-0" />
                  <div className="img">
                    <img src={ICloudIcon} alt="Octo Icon" />
                  </div>
                </div>
                <h3 className="desc text-center">MeetOcto Needs to authenticate with iCloud to connect to your calendar.</h3>
                <div className="row mt-3">
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label htmlFor="icloudEmail" className="form-label">iCloud Email Address</label>
                      <input type="text" name="icloudEmail" id="" className="form-control" placeholder="johndoe@yahoo.com" />
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label htmlFor="icloudEmail" className="form-label">iCloud Password</label>
                      <input type="password" name="icloudEmail" id="" className="form-control" placeholder="**************" />
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <button className="btn btn-outline" onClick={icloudToggle}>Cancel</button>
                  </div>
                  <div className="col-md-6 mt-4">
                    <button className="btn btn-app">Connect</button>
                  </div>
                  <div className="col-12 mt-3">
                    <p className="msg">Two-factor authentication and an app-specific password are required to connect your account. Visit <a href="/#">dummy.com</a> to learn more</p>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
        <div className="row-2 row">
          <div className="col-12">
            <h2>Microsoft</h2>
          </div>
          {!dataSet.some((data) => data.type === "outlook") && (
            <div className="col-xl-4 col-lg-6">
              <a
                href={`${BACKEND_API_BASE_URL}outlook-calendar-auth/${getAdminBusinessId()}`}
              >
                <div className="calender-client-office">
                  {/* <input
                      type="checkbox"
                      name="officeCalender"
                      id="officeCalender"
                    /> */}
                  <label htmlFor="officeCalender" role="button">
                    <img src={MicrosoftOfficeLogo} alt="" />
                    <span>Office 365 Calender</span>
                  </label>
                </div>
              </a>
            </div>
          )}
          <div className="col-xl-4 col-lg-6">
            <button className="btn p-0" onClick={exchangeToggle}>
              <div className="calender-client-exchange">
                {/* <input
                      type="checkbox"
                      name="exchangeCalender"
                      id="exchangeCalender"
                    /> */}

                <label htmlFor="exchangeCalender" role="button">
                  <img src={ExchangeCenterIcon} alt="Exchange Center Icon" />
                  <span>Exchange Calendar</span>
                </label>
              </div>
            </button>
            <Modal className="cale_con_modal exchange_mod" isOpen={exchangeModal} toggle={exchangeToggle} centered={true}>
              <ModalBody>
                <div className="head">
                  <div className="img">
                    <img src={OctoIcon} alt="Octo Icon" />
                  </div>
                  <hr className="my-0" />
                  <Icon.Plus size={20} color="#0C4767" strokeWidth={2} />
                  <hr className="my-0" />
                  <div className="img ex">
                    <img src={ExchangeCenterIcon} alt="Exchange Icon" />
                  </div>
                </div>
                <h3 className="desc text-center">Enter your Exchange email address and password to connect your Exchange Email Address calendar.</h3>
                <div className="row mt-3">
                  <div className="col-12 mt-4">
                    <div className="form-group">
                      <label htmlFor="exchangeEmail" className="form-label">iCloud Email Address</label>
                      <input type="email" name="exchangeEmail" id="exchangeEmail" className="form-control" placeholder="johndoe@yahoo.com" />
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="form-group">
                      <label htmlFor="exchangePassword" className="form-label">Exchange Password</label>
                      <input type="password" name="exchangePassword" id="exchangePassword" className="form-control" placeholder="**************" />
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="form-group">
                      <label htmlFor="exchangeServer" className="form-label">Exchange Server</label>
                      <input type="text" name="exchangeServer" id="exchangeServer" className="form-control" placeholder="Optional" />
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="form-group">
                      <label htmlFor="exchangeDomain" className="form-label">Domain</label>
                      <input type="text" name="exchangeDomain" id="exchangeDomain" className="form-control" placeholder="Optional" />
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="form-group">
                      <label htmlFor="exchangeName" className="form-label">Username</label>
                      <input type="text" name="exchangeName" id="exchangeName" className="form-control" placeholder="Optional" />
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <button className="btn btn-outline" onClick={exchangeToggle}>Cancel</button>
                  </div>
                  <div className="col-md-6 mt-4">
                    <button className="btn btn-app">Connect</button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
          <div className="col-xl-4 col-lg-6">
            <button className="btn p-0" onClick={outlookToggle} style={{pointerEvents: 'none'}}>
              <div className="calender-client-outlook">
                {/* <input
                      type="checkbox"
                      name="outlookCalender"
                      id="outlookCalender"
                    /> */}

                <label htmlFor="outlookCalender" role="button">
                  <img src={isTablet ? OutlookIcon : OutlookDull} alt="Icloud Icon" />
                  <span>Outlook Plug-in</span>
                  <span className="label">For Windows Users</span>
                </label>
              </div>
            </button>
            <Modal className="cale_con_modal exhange_plugin" isOpen={outlookModal} toggle={outlookToggle} centered={true}>
              <ModalBody>
                <div className="head">
                  <div className="img">
                    <img src={OutlookPluginIcon} alt="Outlook Plugin Icon" />
                  </div>
                  <h3 className="ms-2">Do you use Exchange?</h3>
                </div>
                <p className="descr">If you are using an Exchange Server, Meetocto can connect directly to your calendar, and you don't need to install the Outlook Plug-in.</p>
                <div className="row mt-3">
                  <div className="col-md-6 mt-4">
                    <button className="btn btn-outline" onClick={outlookToggle}>Cancel</button>
                  </div>
                  <div className="col-md-6 mt-4">
                    <button className="btn btn-app">Install plug-in</button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
        {/* </form> */}
      </div>
    </section>
  );
};

export default ConnectToCalendarView;
