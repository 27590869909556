import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { pastEvents } from "../../../../actions/admin/scheduled-event-actions";
import isEmpty from "../../../../validation/is-empty";
import Loader from "../../../../components/Loader/Loader";
import PastViewDetails from "./past-view-details";
import { Pagination } from "antd";
import {
  Row,
  Col
} from "reactstrap";
import {  onModalClose } from "../../../../utils/utility";
import moment from "moment";
import P from "../../../../assets/bookingeventassets/assets/p.svg";
import ZoomIcon from "../../../../assets/icons/zoom.svg";
import MeetIcon from "../../../../assets/icons/google-meet.svg";
import TeamsIcon from "../../../../assets/icons/Microsoft_Teams.svg";
import GoToIcon from "../../../../assets/icons/goto_icon.svg";
import PhoneCall from "../../../../assets/icons/phone_call.svg";
import InPersonMeet from "../../../../assets/icons/in_person_meet.svg";
import OtherMeet from "../../../../assets/icons/other_meet_icon.svg";
import PaginatorDropdown from "../../../../components/Datatable/PaginationDropdown";
import Filter from "../../../../components/Filter/Filter";
import { toggleFilter } from "../../../../actions/filterActions";
import PastFilters from "./past-filters";

class Past extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      totalEvents: 0,
      bookingId: null,
      showViewDetailModal: false,
      isLoading: false,
      pageLoading: false,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    };
  }

  /**
   * on page size drop down change
   * @param {*} e  event
   */
  onPageSizeChange = (e) => {
    const _this = this;
    _this.setState({
      isLoading: true,
    });
    _this.fetchListEvents({
      pagination: {
        current: 1,
        pageSize: e.target.value,
      },
    });
  };

  /**
   * on page change
   * @param {*} page
   * @param {*} pageSize
   */
  onPageChange = (page, pageSize) => {
    const _this = this;
    _this.setState({
      isLoading: true,
    });
    _this.fetchListEvents({
      pagination: {
        current: page,
        pageSize: pageSize,
      },
    });
  };

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * fetch list events
   */
  fetchListEvents = (params = {}) => {
    const _this = this;
    const { pastEvents } = _this.props || {};
    pastEvents({
      data: _this.prepareDataParam(params),
      onSuccess: function (response) {
        const { data, total } = response;
        _this.setState({
          events: data,
          totalEvents: total,
          isLoading: false,
          pageLoading: false,
          pagination: { ...params.pagination },
        });
      },
      onError: function (error) {
        console.error(error);
      },
    });
  };

  componentDidMount() {
    const _this = this;

    const { pagination } = _this.state;

    _this.setState({
      isLoading: true,
    });

    _this.fetchListEvents({ pagination });
  }

  /**
   * on view detail click
   * @param {*} bookingData
   */
  onViewDetail = (bookingData) => {
    this.setState({
      bookingId: bookingData.id,
      showViewDetailModal: true,
    });
  };

  /**
   * hide modal
   */
  hideViewDetailModal = () => {
    this.setState({
      showViewDetailModal: false,
    });
    onModalClose();
  };

  /**
   * collape/expand filter section
   */
  onToggleCollapseFilter = () => {
    const { filterCollapse } = this.state;
    this.setState({
      filterCollapse: !filterCollapse,
    });
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchListEvents({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  /**
   * on clear filter handler
   */
  clearFilterHandler = () => {
    const _this = this;
    _this.handleTableChange(
      {
        ..._this.state.pagination,
      },
      {},
      {}
    );
  };

  /**
   * on filter data table
   * @param {*} name
   * @param {*} value
   */
  filterRecords = () => {
    const _this = this;
    const { toggleFilter, filter } = _this.props;
    toggleFilter(false);
    _this.setState({
      isLoading: true,
    });
    _this.handleTableChange(
      {
        current: 1,
        pageSize: 10,
      },
      {
        ...filter.searchFilters,
      },
      {}
    );
  };

  onFilterDataTable = (name, value) => {
    const searchFilters = { ...this.state.filter };
    if (isEmpty(value)) {
      delete searchFilters[name];
    } else {
      searchFilters[name] = value;
    }
    this.setState({
      filter: searchFilters,
      isLoading: true,
    });

    this.handleTableChange(
      {
        ...this.state.pagination,
      },
      {
        ...searchFilters,
      },
      {}
    );
  };

  /**
   * on Enterieng filter container
   */
  onEntering = () => {
    this.setState({
      filterStatus: "Opening...",
    });
  };

  /**
   * on Entered filter container
   */
  onEntered = () => {
    this.setState({
      filterStatus: "Opened",
    });
  };

  /**
   * on Exiting filter container
   */
  onExiting = () => {
    this.setState({
      filterStatus: "Closing...",
    });
  };

  /**
   * on Exited filter container
   */
  onExited = () => {
    this.setState({
      filterStatus: "Closed",
    });
  };

  /**
   *
   * @returns
   */
  updatePastEventStatus = (id, status) => {
    let processedEvents = { ...this.state.events };
    Object.keys(processedEvents).forEach((key, index) => {
      (processedEvents[`${key}`] || []).forEach((event, innerIndex) => {
        if (event.id === id) {
          event.status = status;
        }
      });
    });
    this.setState({
      events: processedEvents,
    });
    this.hideViewDetailModal();
  };

  render() {
    const {
      events,
      isLoading,
      pageLoading,
      pagination,
      showViewDetailModal,
      bookingId,
      isEventFilterVisible,
      filterStatus,
      filterCollapse,
    } = this.state || {};
    return (
      <div className="rd_pasevencontth">
        <Row>
          <Col sm="12">
            <Filter
              name="Past Events"
              onFilterRecords={this.filterRecords}
              onClearFilterHandler={this.clearFilterHandler}
            >
              <PastFilters />
            </Filter>
          </Col>
        </Row>

        <Loader isShowLoader={isLoading} />
        {showViewDetailModal && (
          <PastViewDetails
            bookingId={bookingId}
            showViewDetailModal={showViewDetailModal}
            handleClose={this.hideViewDetailModal}
            updatePastEventStatus={this.updatePastEventStatus}
          />
        )}
        {Object.keys(events).length > 0 ? (
          <Fragment>
            {Object.keys(events).map((key, index) => {
              return (
                <Fragment key={index}>
                  <h2>
                    <span className="rd_archiveiconth"></span>
                    {moment(key, "DD-MM-YYYY").format("dddd, MMMM, DD YYYY")}
                  </h2>
                  <div className="rd_eventcardscontainer align-items-stretch">
                    {(events[`${key}`] || []).map((event, innerIndex) => {
                      if (event.type === "regular") {
                        return (
                          <div className="rd_carditem" key={innerIndex}>
                            {event.status === "MN" && (
                              <div class="draft_eve no_show">no-show</div>
                            )}
                            <div className="rd_cardheaderevenpasc">
                              <h3>{event.event_name}</h3>
                              {event.event_format_id === "1" ||
                              event.event_format_id === 1 ? (
                                <p>One on One</p>
                              ) : (
                                <p>{`1 to ${event.no_of_attendies} invitees`}</p>
                              )}
                            </div>
                            <div className="rd_cardbdyevenpasc">
                              <p>
                                <span className="rd_timelefticon"></span>
                                {`${event.start_time} - ${event.end_time}`}
                              </p>
                              <p>
                                <span className="rd_timeiconthin"></span>
                                10:00am-10:30am (Pakistan, Maldives Time)
                              </p>
                              <p>
                                {event.venue === "Microsoft Teams" ? (
                                  <img
                                    src={TeamsIcon}
                                    alt="microsoft teams icon"
                                  />
                                ) : event.venue === "Zoom" ? (
                                  <img src={ZoomIcon} alt="Zoom icon" />
                                ) : event.venue === "Google Meet" ? (
                                  <img src={MeetIcon} alt="Google Meet icon" />
                                ) : event.venue === "Phone Call" ? (
                                  <img src={PhoneCall} alt="Phone Call icon" />
                                ) : event.venue === "In-Person Meeting" ? (
                                  <img
                                    src={InPersonMeet}
                                    alt="In Person Meet icon"
                                    style={{ maxWidth: 14 }}
                                  />
                                ) : event.venue === "Others" ? (
                                  <img
                                    src={OtherMeet}
                                    alt="microsoft teams icon"
                                    style={{ maxWidth: 18 }}
                                  />
                                ) : event.venue === "Go to Meeting" ? (
                                  <img src={GoToIcon} alt="Go To icon" />
                                ) : (
                                  <img src={P} alt="event_img" />
                                )}
                                {event.venue}
                              </p>
                            </div>
                            <button
                              className="view-detail-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                //onModalOpen();
                                this.onViewDetail(event);
                              }}
                            >
                              View Detail
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="rd_carditem"
                            key={innerIndex}
                            style={{ border: "1px solid #ccc" }}
                          >
                            <div className="rd_cardheaderevenpasc">
                              <h3
                                className={
                                  event.status === "CC"
                                    ? "text-line-through"
                                    : ""
                                }
                              >
                                {event.event_name}
                              </h3>
                            </div>
                            <div className="rd_cardbdyevenpasc">
                              <p
                                className={
                                  event.status === "CC"
                                    ? "text-line-through"
                                    : ""
                                }
                              >
                                <span className="rd_timeiconthin"></span>
                                {`${event.start_time} - ${event.end_time}`}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        ) : (
          <div className="box_content rd_boxcontentinf"></div>
        )}
        <Row className="pb-4 pr-md-3">
          <Col className="text-center" lg="12" md="12">
            <Pagination
              className="list-view-pagination"
              defaultCurrent={pagination.current}
              current={pagination.current}
              total={this.state.totalEvents}
              pageSize={pagination.pageSize}
              onChange={this.onPageChange}
              showSizeChanger={false}
            />
          </Col>
          <Col sm="6" className="table_paginaton">
            <PaginatorDropdown
              onPageSizeChange={this.onPageSizeChange}
              pageSize={pagination.pageSize}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  pastEvents,
  toggleFilter,
})(Past);
