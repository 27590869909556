import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Loader from "../../../components/Loader/Loader";
import { FormGroup, Input, Label } from "reactstrap";
import { connect } from "react-redux";
import {
  getAdminPaymentDetails,
  saveAdminPaymentSetting,
} from "../../../actions/paymentAction";
import isEmpty from "../../../validation/is-empty";
import { notification } from "../../../utils/utility";
import Tooltip from "../../../components/Tooltip";

const validation = Yup.object().shape({
  paypal_api_username: Yup.string().required("API username is required"),
  paypal_api_password: Yup.string().required("API password is required"),
  stripe_publishable_key: Yup.string().required("Publishable key is required"),
  stripe_secret_key: Yup.string().required("Secret key is required"),
});

let initialValues = {
  cash_status: "D",
  visa_mastercard_status: "D",
  amex_status: "D",
  cheque_status: "D",
  paypal_api_username: "",
  paypal_api_password: "",
  paypal_signature: "",
  paypal_guest_payment_status: "D",
  paypal_test_mode_status: "D",
  stripe_publishable_key: "",
  stripe_secret_key: "",
};

const PaymentSettings = (props) => {
  const [formValues, setFormValues] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    const { getAdminPaymentDetails } = props;
    setPageLoading(true);
    getAdminPaymentDetails({
      data: {},
      onSuccess: function (response) {
        if (!isEmpty(response)) {
          setFormValues({
            ...response,
          });
        }
        setPageLoading(false);
      },
      onError: function (error) {
        setPageLoading(false);
      },
    });
  }, []);

  return (
    <>
      <div className="filter_bar d-flex justify-content-between align-items-center ">
        <h3>Payment Settings <Tooltip code="P20F1" /></h3>
      </div>
      <Loader isShowLoader={pageLoading} />
      <section
        className="rd_vacationfilterpart d-block"
        style={{ padding: "20px 30px" }}
      >
        <Formik
          //validationSchema={validation}
          initialValues={formValues || initialValues}
          enableReinitialize
          onSubmit={(values) => {
            const { saveAdminPaymentSetting } = props;
            let data = {
              ...values,
            };

            setPageLoading(true);
            saveAdminPaymentSetting({
              data,
              onSuccess: (response) => {
                if (response.success) {
                  notification.success(response.message);
                }
                setPageLoading(false);
              },
              onError: (error) => {
                notification.error(error);

                setPageLoading(false);
                console.log("company error ", error);
              },
            });
          }}
        >
          {(formProps) => {
            const { values, errors, touched, handleChange, setFieldValue } =
              formProps;
            return (
              <Form>
                {/* Pay Local */}
                <div className="pay-method-local">
                  <h3 className="custom_title">Pay Local</h3>
                  <div className="row justify-content-between align-items-center">
                    <div className="col-6 col-md-auto">
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          id="cashMethod"
                          checked={values.cash_status === "E"}
                          onChange={(event) => {
                            handleChange({
                              target: {
                                name: "cash_status",
                                value: event.target.checked ? "E" : "D",
                              },
                            });
                          }}
                        />
                        <Label htmlFor="cashMethod" check>
                          Cash <Tooltip code="P20F2" />
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="col-6 col-md-auto">
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          id="visaCardMethod"
                          checked={values.visa_mastercard_status === "E"}
                          onChange={(event) => {
                            handleChange({
                              target: {
                                name: "visa_mastercard_status",
                                value: event.target.checked ? "E" : "D",
                              },
                            });
                          }}
                        />
                        <Label htmlFor="visaCardMethod" check>
                          Visa/Mastercard <Tooltip code="P20F3" />
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="col-6 col-md-auto mt-3 mt-md-0">
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          id="amexMethod"
                          checked={values.amex_status === "E"}
                          onChange={(event) => {
                            handleChange({
                              target: {
                                name: "amex_status",
                                value: event.target.checked ? "E" : "D",
                              },
                            });
                          }}
                        />
                        <Label htmlFor="amexMethod" check>
                          Amex <Tooltip code="P20F4" />
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="col-6 col-md-auto mt-3 mt-md-0">
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          id="chequeMethod"
                          checked={values.cheque_status === "E"}
                          onChange={(event) => {
                            handleChange({
                              target: {
                                name: "cheque_status",
                                value: event.target.checked ? "E" : "D",
                              },
                            });
                          }}
                        />
                        <Label htmlFor="chequeMethod" check>
                          Cheque <Tooltip code="P20F5" />
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                </div>
                {/* Paypal */}
                <div className="pay-method-paypal">
                  <h3 className="custom_title">Paypal</h3>
                  <div className="row align-items-center">
                    <div className="col-lg-4 col-md-6">
                      <FormGroup>
                        <Label htmlFor="paypalApiName">API Username <Tooltip code="P20F6" /> </Label>
                        <Input
                          type="text"
                          id="paypalApiName"
                          placeholder="API Username"
                          value={values.paypal_api_username}
                          onChange={(event) => {
                            handleChange({
                              target: {
                                name: "paypal_api_username",
                                value: event.target.value,
                              },
                            });
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <FormGroup>
                        <Label htmlFor="paypalApiPwd">API Password <Tooltip code="P20F7" /> </Label>
                        <Input
                          type="text"
                          id="paypalApiPwd"
                          placeholder="API Password"
                          value={values.paypal_api_password}
                          onChange={(event) => {
                            handleChange({
                              target: {
                                name: "paypal_api_password",
                                value: event.target.value,
                              },
                            });
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <FormGroup>
                        <Label htmlFor="paypalSignature" className="cus-label">
                          Signature <Tooltip code="P20F8" />
                        </Label>
                        <Input
                          type="text"
                          id="paypalSignature"
                          placeholder="Signature"
                          value={values.paypal_signature}
                          onChange={(event) => {
                            handleChange({
                              target: {
                                name: "paypal_signature",
                                value: event.target.value,
                              },
                            });
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3 mt-lg-0">
                      <div className="toggle_field">
                        <div className="box_content p-0">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="paypalGuestPayment"
                              checked={
                                values.paypal_guest_payment_status === "E"
                              }
                              onChange={(event) => {
                                handleChange({
                                  target: {
                                    name: "paypal_guest_payment_status",
                                    value: event.target.checked ? "E" : "D",
                                  },
                                });
                              }}
                            />
                            <label
                              className="form-check-label cus_label"
                              htmlFor="paypalGuestPayment"
                            >
                              Paypal guest payment <Tooltip code="P20F11" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3 mt-md-0">
                      <div className="toggle_field">
                        <div className="box_content p-0">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="paypalTestMode"
                              checked={values.paypal_test_mode_status === "E"}
                              onChange={(event) => {
                                handleChange({
                                  target: {
                                    name: "paypal_test_mode_status",
                                    value: event.target.checked ? "E" : "D",
                                  },
                                });
                              }}
                            />
                            <label
                              className="form-check-label cus_label"
                              htmlFor="paypalTestMode"
                            >
                              Test Mode <Tooltip code="P20F12" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Stripe */}
                <div className="pay-method-stripe">
                  <h3 className="custom_title">Stripe</h3>
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <FormGroup>
                        <Label htmlFor="stripeSecretKey">
                          Secret Key <Tooltip code="P20F9" />
                        </Label>
                        <Input
                          type="text"
                          id="stripeSecretKey"
                          placeholder="Secret Key"
                          value={values.stripe_secret_key}
                          onChange={(event) => {
                            handleChange({
                              target: {
                                name: "stripe_secret_key",
                                value: event.target.value,
                              },
                            });
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup>
                        <Label htmlFor="stripePublishKey">
                          Publishable Key <Tooltip code="P20F10" />
                        </Label>
                        <Input
                          type="text"
                          id="stripePublishKey"
                          placeholder="Publishable Key"
                          value={values.stripe_publishable_key}
                          onChange={(event) => {
                            handleChange({
                              target: {
                                name: "stripe_publishable_key",
                                value: event.target.value,
                              },
                            });
                          }}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="save_changes d-flex justify-content-end">
                  <div className="col-auto px-0">
                    <button className="btn btn-app" type="submit">
                      Save Changes
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </section>
    </>
  );
};

export default connect(null, {
  getAdminPaymentDetails,
  saveAdminPaymentSetting,
})(PaymentSettings);
