import React, { Component } from "react";
//import ScheduleCopyDropdown from "./schdules/schedule-copy-dropdown";
import ScheduleTimeDropdown from "./schdules/schedule-time-dropdown";
import { notification } from "../../../../utils/utility";
import { convertHoursToMinutes } from "../../../../utils/utility";

import * as Icon from "react-feather";

class ScheduleListView extends Component {
  state = {
    dayIdSelectedForCopy: 0,
    isMondayChecked: false,
    isTuesdayChecked: false,
    isWednesdayChecked: false,
    isThursdayChecked: false,
    isFridayChecked: false,
    isSaturdayChecked: false,
    isSundayChecked: false,
  };

  /**
   * add slot to day schedule
   * @param int index
   */
  addSlotToDaySchedule = (dayId) => {
    const schedules = [...this.props.eventSchedules];
    const found = schedules.some((el) => el.dayId === dayId);
    if (found) {
      const dayIndex = schedules.findIndex((el) => el.dayId === dayId);
      schedules[dayIndex].schedules.push({ start_time: "", end_time: "" });
      this.props.setEventSchedules(schedules, this.props.timezoneId);
    }
  };

  /**
   * remove slot from day schedule
   * @param int dayId
   * @param int dayScheduleIndex
   */
  removeSlotFromDaySchedule = (dayId, dayScheduleIndex) => {
    const schedules = [...this.props.eventSchedules];
    const found = schedules.some((el) => el.dayId === dayId);
    if (found) {
      const dayIndex = schedules.findIndex((el) => el.dayId === dayId);
      delete schedules[dayIndex].schedules[dayScheduleIndex];
      this.props.setEventSchedules(schedules, this.props.timezoneId);
    }
  };

  /**
   * on schedule start time change for day
   * @param string time
   * @param int dayId
   * @param int dayScheduleIndex
   */
  scheduleStartTimeOnChange = (time, dayId, dayScheduleIndex) => {
    const schedules = [...this.props.eventSchedules];
    const found = schedules.some((el) => el.dayId === dayId);
    if (found) {
      const dayIndex = schedules.findIndex((el) => el.dayId === dayId);

      const endTime = convertHoursToMinutes(
        schedules[dayIndex].schedules[dayScheduleIndex].end_time
      );
      const startTime = convertHoursToMinutes(time);

      if (startTime > endTime) {
        return notification.warning("End time cant be earlier than the start time");
      }

      if (startTime == endTime) {
        return notification.warning("Start time and End time cant be same");
      }

      schedules[dayIndex].schedules[dayScheduleIndex].start_time = time;
      console.log("start time", time);
      this.props.setEventSchedules(schedules, this.props.timezoneId);
    }
  };

  /**
   * on schedule end time change for day
   * @param string time selected time
   * @param int dayId
   * @param int dayScheduleIndex
   */
  scheduleEndTimeOnChange = (time, dayId, dayScheduleIndex) => {
    const schedules = [...this.props.eventSchedules];
    const found = schedules.some((el) => el.dayId === dayId);
    if (found) {
      const dayIndex = schedules.findIndex((el) => el.dayId === dayId);

      const startTime = convertHoursToMinutes(
        schedules[dayIndex].schedules[dayScheduleIndex].start_time
      );
      const endTime = convertHoursToMinutes(time);
      if (startTime > endTime) {
        return notification.warning("End time cant be earlier than the start time");
      }

      if (startTime == endTime) {
        return notification.warning("Start time and End time cant be same");
      }

      schedules[dayIndex].schedules[dayScheduleIndex].end_time = time;

      this.props.setEventSchedules(schedules, this.props.timezoneId);
    }
  };

  /**
   * on schedule start time change for day
   * @param int dayId
   * @return boolean
   */
  isDayScheduleExist = (dayId) => {
    const schedules = [...this.props.eventSchedules];
    const found = schedules.some((el) => el.dayId === dayId);
    return found;
  };

  /**
   *
   * @param Event e
   * @param Int dayId
   */
  handleChange = (e, dayId) => {
    const schedules = [...this.props.eventSchedules];
    const found = schedules.some((el) => el.dayId === dayId);
    if (e.target.checked) {
      if (!found) {
        schedules.push({
          dayId,
          schedules: [{ start_time: "", end_time: "" }],
        });
        this.props.setEventSchedules(schedules, this.props.timezoneId);
      }
    } else {
      const daySchedules = schedules.filter(function (obj) {
        return obj.dayId !== dayId;
      });
      this.props.setEventSchedules(daySchedules, this.props.timezoneId);
    }
  };

  /**
   *
   * @param Int dayId
   */
  checkDefaultCopyCheckbox = (dayId) => {
    this.setState({
      isMondayChecked: false,
      isTuesdayChecked: false,
      isWednesdayChecked: false,
      isThursdayChecked: false,
      isFridayChecked: false,
      isSaturdayChecked: false,
      isSundayChecked: false,
    });

    switch (dayId) {
      case 1:
        return this.setState({
          isMondayChecked: true,
        });

      case 2:
        return this.setState({
          isTuesdayChecked: true,
        });

      case 3:
        return this.setState({
          isWednesdayChecked: true,
        });

      case 4:
        return this.setState({
          isThursdayChecked: true,
        });

      case 5:
        return this.setState({
          isFridayChecked: true,
        });

      case 6:
        return this.setState({
          isSaturdayChecked: true,
        });

      case 7:
        return this.setState({
          isSundayChecked: true,
        });
    }
  };

  /**
   * on copy schedule handler
   */
  onCopyScheduleHandler = (dayId) => {
    if (dayId === this.state.dayIdSelectedForCopy) {
      this.setState({
        dayIdSelectedForCopy: 0,
      });
      return;
    } else {
      this.setState({
        dayIdSelectedForCopy: dayId,
      });
    }

    // handling default checked status
    this.checkDefaultCopyCheckbox(dayId);
  };

  /**
   * handle when click on copy checkbox field
   * @param {*} e
   * @param Int dayId
   */
  handleCopyCheckboxChange = (e, dayId) => {
    switch (dayId) {
      case 1:
        return this.setState({
          isMondayChecked: e.target.checked,
        });

      case 2:
        return this.setState({
          isTuesdayChecked: e.target.checked,
        });

      case 3:
        return this.setState({
          isWednesdayChecked: e.target.checked,
        });

      case 4:
        return this.setState({
          isThursdayChecked: e.target.checked,
        });

      case 5:
        return this.setState({
          isFridayChecked: e.target.checked,
        });

      case 6:
        return this.setState({
          isSaturdayChecked: e.target.checked,
        });

      case 7:
        return this.setState({
          isSundayChecked: e.target.checked,
        });
    }
  };

  /**
   * on apply copy schedule
   * @param Int dayId
   */
  onApplyCopySchedule = (dayId) => {
    const schedules = [...this.props.eventSchedules];
    //console.log("schedules", schedules);
    const found = schedules.some((el) => el.dayId === dayId);
    if (found) {
      const dayIndex = schedules.findIndex((el) => el.dayId === dayId);

      const daySchedule = [...schedules[dayIndex].schedules];

      let copySchedules = [
        {
          dayId: dayId,
          schedules: [...daySchedule],
        },
      ];

      // check for monday
      if (dayId !== 1 && this.state.isMondayChecked) {
        copySchedules.push({
          dayId: 1,
          schedules: [...daySchedule],
        });
      }

      // check for tuesday
      if (dayId !== 2 && this.state.isTuesdayChecked) {
        copySchedules.push({
          dayId: 2,
          schedules: [...daySchedule],
        });
      }

      // check for wednesday
      if (dayId !== 3 && this.state.isWednesdayChecked) {
        copySchedules.push({
          dayId: 3,
          schedules: [...daySchedule],
        });
      }

      // check for thursday
      if (dayId !== 4 && this.state.isThursdayChecked) {
        copySchedules.push({
          dayId: 4,
          schedules: [...daySchedule],
        });
      }

      // check for tuesday
      if (dayId !== 5 && this.state.isFridayChecked) {
        copySchedules.push({
          dayId: 5,
          schedules: [...daySchedule],
        });
      }

      // check for saturday
      if (dayId !== 6 && this.state.isSaturdayChecked) {
        copySchedules.push({
          dayId: 6,
          schedules: [...daySchedule],
        });
      }

      // check for sunday
      if (dayId !== 7 && this.state.isSundayChecked) {
        copySchedules.push({
          dayId: 7,
          schedules: [...daySchedule],
        });
      }

      this.props.setEventSchedules(copySchedules, this.props.timezoneId);
    } else {
      this.props.setEventSchedules([], this.props.timezoneId);
    }

    this.setState({
      dayIdSelectedForCopy: 0,
    });
  };

  render() {
    //const { dailySchedules } = this.state || {};
    const { errors, touched, eventSchedules } = this.props || {};
    return (
      <>
        <div className="rd_datasheducont">
          <div className="rd_datacontshthin">
            <div className="rd_realdatathingshed">
              <div className="rd_realdatathingsheditem">
                <div className="rd_hourlyavalmodal">
                  <div className="rd_hourlyavalmodalitem inactive">
                    <div className="rd_hourlyavalmodalitemblock1">
                      <div className="form-check rd_formdatepicdatash">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          checked={this.isDayScheduleExist(1)}
                          onChange={(e) => this.handleChange(e, 1)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          Monday
                        </label>
                      </div>

                      {(eventSchedules || []).map((schedule, index) => {
                        if (schedule.dayId === 1) {
                          return (
                            <div
                              className="rd_shedulneworg"
                              key={"schedule-row-" + index}
                            >
                              {(schedule.schedules || []).map(
                                (daySchedule, dayScheduleIndex) => {
                                  return (
                                    <div
                                      className="rd_timeorderitem"
                                      key={"day-schedule-" + dayScheduleIndex}
                                    >
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.start_time}
                                        name={
                                          "monday-start-" + dayScheduleIndex
                                        }
                                        onChange={(time) => {
                                          //console.log("time received", time);
                                          this.scheduleStartTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <span>-</span>
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.end_time}
                                        name={"monday-end-" + dayScheduleIndex}
                                        onChange={(time) => {
                                          this.scheduleEndTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="rd_hourlyavalmodalitemblock1del"
                                        onClick={() => {
                                          this.removeSlotFromDaySchedule(
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      ></button>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="rd_hourlyavalmodalitemblock2">
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2add"
                        onClick={() => this.addSlotToDaySchedule(1)}
                      ></button>
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2expand"
                        onClick={() => this.onCopyScheduleHandler(1)}
                      ></button>

                      {this.state.dayIdSelectedForCopy === 1 && (
                        <div className="rd_popbox">
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Monday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isMondayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 1)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Tuesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isTuesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 2)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Wednesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isWednesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 3)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Thursday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isThursdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 4)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Friday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isFridayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 5)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Saturday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSaturdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 6)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Sunday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSundayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 7)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-app"
                            onClick={() => this.onApplyCopySchedule(1)}
                          >
                            Apply
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="rd_hourlyavalmodal">
                  <div className="rd_hourlyavalmodalitem inactive">
                    <div className="rd_hourlyavalmodalitemblock1">
                      <div className="form-check rd_formdatepicdatash">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          checked={this.isDayScheduleExist(2)}
                          onChange={(e) => this.handleChange(e, 2)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          Tuesday
                        </label>
                      </div>

                      {(eventSchedules || []).map((schedule, index) => {
                        if (schedule.dayId === 2) {
                          return (
                            <div
                              className="rd_shedulneworg"
                              key={"schedule-row-" + index}
                            >
                              {(schedule.schedules || []).map(
                                (daySchedule, dayScheduleIndex) => {
                                  return (
                                    <div
                                      className="rd_timeorderitem"
                                      key={"day-schedule-" + dayScheduleIndex}
                                    >
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.start_time}
                                        name={
                                          "tuesday-start-" + dayScheduleIndex
                                        }
                                        onChange={(time) => {
                                          this.scheduleStartTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <span>-</span>
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.end_time}
                                        name={"tuesday-end-" + dayScheduleIndex}
                                        onChange={(time) => {
                                          this.scheduleEndTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="rd_hourlyavalmodalitemblock1del"
                                        onClick={() => {
                                          this.removeSlotFromDaySchedule(
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      ></button>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="rd_hourlyavalmodalitemblock2">
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2add"
                        onClick={() => this.addSlotToDaySchedule(2)}
                      ></button>
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2expand"
                        onClick={() => this.onCopyScheduleHandler(2)}
                      ></button>

                      {this.state.dayIdSelectedForCopy === 2 && (
                        <div className="rd_popbox">
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Monday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isMondayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 1)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Tuesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isTuesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 2)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Wednesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isWednesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 3)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Thursday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isThursdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 4)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Friday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isFridayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 5)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Saturday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSaturdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 6)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Sunday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSundayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 7)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-app"
                            onClick={() => this.onApplyCopySchedule(2)}
                          >
                            Apply
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="rd_hourlyavalmodal">
                  <div className="rd_hourlyavalmodalitem inactive">
                    <div className="rd_hourlyavalmodalitemblock1">
                      <div className="form-check rd_formdatepicdatash">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          checked={this.isDayScheduleExist(3)}
                          onChange={(e) => this.handleChange(e, 3)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          Wednesday
                        </label>
                      </div>

                      {(eventSchedules || []).map((schedule, index) => {
                        if (schedule.dayId === 3) {
                          return (
                            <div
                              className="rd_shedulneworg"
                              key={"schedule-row-" + index}
                            >
                              {(schedule.schedules || []).map(
                                (daySchedule, dayScheduleIndex) => {
                                  return (
                                    <div
                                      className="rd_timeorderitem"
                                      key={"day-schedule-" + dayScheduleIndex}
                                    >
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.start_time}
                                        name={"wed-start-" + dayScheduleIndex}
                                        onChange={(time) => {
                                          this.scheduleStartTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <span>-</span>
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.end_time}
                                        name={"wed-end-" + dayScheduleIndex}
                                        onChange={(time) => {
                                          this.scheduleEndTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="rd_hourlyavalmodalitemblock1del"
                                        onClick={() => {
                                          this.removeSlotFromDaySchedule(
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      ></button>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="rd_hourlyavalmodalitemblock2">
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2add"
                        onClick={() => this.addSlotToDaySchedule(3)}
                      ></button>
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2expand"
                        onClick={() => this.onCopyScheduleHandler(3)}
                      ></button>

                      {this.state.dayIdSelectedForCopy === 3 && (
                        <div className="rd_popbox">
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Monday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isMondayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 1)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Tuesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isTuesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 2)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Wednesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isWednesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 3)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Thursday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isThursdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 4)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Friday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isFridayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 5)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Saturday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSaturdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 6)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Sunday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSundayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 7)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-app"
                            onClick={() => this.onApplyCopySchedule(3)}
                          >
                            Apply
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="rd_hourlyavalmodal">
                  <div className="rd_hourlyavalmodalitem inactive">
                    <div className="rd_hourlyavalmodalitemblock1">
                      <div className="form-check rd_formdatepicdatash">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          checked={this.isDayScheduleExist(4)}
                          onChange={(e) => this.handleChange(e, 4)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          Thursday
                        </label>
                      </div>

                      {(eventSchedules || []).map((schedule, index) => {
                        if (schedule.dayId === 4) {
                          return (
                            <div
                              className="rd_shedulneworg"
                              key={"schedule-row-" + index}
                            >
                              {(schedule.schedules || []).map(
                                (daySchedule, dayScheduleIndex) => {
                                  return (
                                    <div
                                      className="rd_timeorderitem"
                                      key={"day-schedule-" + dayScheduleIndex}
                                    >
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.start_time}
                                        name={"thurs-start-" + dayScheduleIndex}
                                        onChange={(time) => {
                                          this.scheduleStartTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <span>-</span>
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.end_time}
                                        name={"thurs-end-" + dayScheduleIndex}
                                        onChange={(time) => {
                                          this.scheduleEndTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="rd_hourlyavalmodalitemblock1del"
                                        onClick={() => {
                                          this.removeSlotFromDaySchedule(
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      ></button>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="rd_hourlyavalmodalitemblock2">
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2add"
                        onClick={() => this.addSlotToDaySchedule(4)}
                      ></button>
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2expand"
                        onClick={() => this.onCopyScheduleHandler(4)}
                      ></button>

                      {this.state.dayIdSelectedForCopy === 4 && (
                        <div className="rd_popbox">
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Monday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isMondayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 1)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Tuesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isTuesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 2)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Wednesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isWednesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 3)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Thursday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isThursdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 4)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Friday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isFridayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 5)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Saturday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSaturdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 6)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Sunday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSundayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 7)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-app"
                            onClick={() => this.onApplyCopySchedule(4)}
                          >
                            Apply
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="rd_hourlyavalmodal">
                  <div className="rd_hourlyavalmodalitem inactive">
                    <div className="rd_hourlyavalmodalitemblock1">
                      <div className="form-check rd_formdatepicdatash">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          checked={this.isDayScheduleExist(5)}
                          onChange={(e) => this.handleChange(e, 5)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          Friday
                        </label>
                      </div>

                      {(eventSchedules || []).map((schedule, index) => {
                        if (schedule.dayId === 5) {
                          return (
                            <div
                              className="rd_shedulneworg"
                              key={"schedule-row-" + index}
                            >
                              {(schedule.schedules || []).map(
                                (daySchedule, dayScheduleIndex) => {
                                  return (
                                    <div
                                      className="rd_timeorderitem"
                                      key={"day-schedule-" + dayScheduleIndex}
                                    >
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.start_time}
                                        name={"fri-start-" + dayScheduleIndex}
                                        onChange={(time) => {
                                          this.scheduleStartTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <span>-</span>
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.end_time}
                                        name={"fri-end-" + dayScheduleIndex}
                                        onChange={(time) => {
                                          this.scheduleEndTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="rd_hourlyavalmodalitemblock1del"
                                        onClick={() => {
                                          this.removeSlotFromDaySchedule(
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      ></button>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="rd_hourlyavalmodalitemblock2">
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2add"
                        onClick={() => this.addSlotToDaySchedule(5)}
                      ></button>
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2expand"
                        onClick={() => this.onCopyScheduleHandler(5)}
                      ></button>

                      {this.state.dayIdSelectedForCopy === 5 && (
                        <div className="rd_popbox">
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Monday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isMondayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 1)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Tuesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isTuesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 2)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Wednesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isWednesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 3)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Thursday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isThursdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 4)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Friday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isFridayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 5)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Saturday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSaturdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 6)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Sunday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSundayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 7)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-app"
                            onClick={() => this.onApplyCopySchedule(5)}
                          >
                            Apply
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="rd_hourlyavalmodal">
                  <div className="rd_hourlyavalmodalitem inactive">
                    <div className="rd_hourlyavalmodalitemblock1">
                      <div className="form-check rd_formdatepicdatash">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          checked={this.isDayScheduleExist(6)}
                          onChange={(e) => this.handleChange(e, 6)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          Saturday
                        </label>
                      </div>

                      {(eventSchedules || []).map((schedule, index) => {
                        if (schedule.dayId === 6) {
                          return (
                            <div
                              className="rd_shedulneworg"
                              key={"schedule-row-" + index}
                            >
                              {(schedule.schedules || []).map(
                                (daySchedule, dayScheduleIndex) => {
                                  return (
                                    <div
                                      className="rd_timeorderitem"
                                      key={"day-schedule-" + dayScheduleIndex}
                                    >
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.start_time}
                                        name={"sat-start-" + dayScheduleIndex}
                                        onChange={(time) => {
                                          this.scheduleStartTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <span>-</span>
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.end_time}
                                        name={"sat-end-" + dayScheduleIndex}
                                        onChange={(time) => {
                                          this.scheduleEndTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="rd_hourlyavalmodalitemblock1del"
                                        onClick={() => {
                                          this.removeSlotFromDaySchedule(
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      ></button>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="rd_hourlyavalmodalitemblock2">
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2add"
                        onClick={() => this.addSlotToDaySchedule(6)}
                      ></button>
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2expand"
                        onClick={() => this.onCopyScheduleHandler(6)}
                      ></button>

                      {this.state.dayIdSelectedForCopy === 6 && (
                        <div className="rd_popbox">
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Monday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isMondayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 1)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Tuesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isTuesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 2)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Wednesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isWednesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 3)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Thursday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isThursdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 4)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Friday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isFridayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 5)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Saturday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSaturdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 6)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Sunday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSundayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 7)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-app"
                            onClick={() => this.onApplyCopySchedule(6)}
                          >
                            Apply
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="rd_hourlyavalmodal">
                  <div className="rd_hourlyavalmodalitem inactive">
                    <div className="rd_hourlyavalmodalitemblock1">
                      <div className="form-check rd_formdatepicdatash">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          checked={this.isDayScheduleExist(7)}
                          onChange={(e) => this.handleChange(e, 7)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          Sunday
                        </label>
                      </div>

                      {(eventSchedules || []).map((schedule, index) => {
                        if (schedule.dayId === 7) {
                          return (
                            <div
                              className="rd_shedulneworg"
                              key={"schedule-row-" + index}
                            >
                              {(schedule.schedules || []).map(
                                (daySchedule, dayScheduleIndex) => {
                                  return (
                                    <div
                                      className="rd_timeorderitem"
                                      key={"day-schedule-" + dayScheduleIndex}
                                    >
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.start_time}
                                        name={"sun-start-" + dayScheduleIndex}
                                        onChange={(time) => {
                                          this.scheduleStartTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <span>-</span>
                                      <ScheduleTimeDropdown
                                        errors={errors}
                                        touched={touched}
                                        value={daySchedule.end_time}
                                        name={"sun-end-" + dayScheduleIndex}
                                        onChange={(time) => {
                                          this.scheduleEndTimeOnChange(
                                            time,
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="rd_hourlyavalmodalitemblock1del"
                                        onClick={() => {
                                          this.removeSlotFromDaySchedule(
                                            schedule.dayId,
                                            dayScheduleIndex
                                          );
                                        }}
                                      ></button>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="rd_hourlyavalmodalitemblock2">
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2add"
                        onClick={() => this.addSlotToDaySchedule(7)}
                      ></button>
                      <button
                        type="button"
                        className="rd_hourlyavalmodalitemblock2expand"
                        onClick={() => this.onCopyScheduleHandler(7)}
                      ></button>

                      {this.state.dayIdSelectedForCopy === 7 && (
                        <div className="rd_popbox">
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Monday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isMondayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 1)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Tuesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isTuesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 2)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Wednesday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isWednesdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 3)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Thursday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isThursdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 4)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Friday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isFridayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 5)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Saturday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSaturdayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 6)
                              }
                              id="flexCheckChecked"
                            />
                          </div>

                          <div className="form-check rd_popboxrightcht">
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Sunday
                            </label>
                            <input
                              className="form-check-input rd_popboxrightchtc"
                              type="checkbox"
                              value=""
                              checked={this.state.isSundayChecked}
                              onChange={(e) =>
                                this.handleCopyCheckboxChange(e, 7)
                              }
                              id="flexCheckChecked"
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-app"
                            onClick={() => this.onApplyCopySchedule(7)}
                          >
                            Apply
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </>
    );
  }
}

export default ScheduleListView;
