import React from 'react';

const PaginatorDropdown = (props) => {

    const { pageSize, onPageSizeChange } = props;

    return (
        <div className="ag-grid-actions d-flex justify-content-center justify-content-md-start flex-wrap mb-3">
            <div className="sort-dropdown">
              <div className="d-inline-block">
                <select className="form-control" onChange={onPageSizeChange}>
                  <option value="5" selected={pageSize === 5}>
                    5
                  </option>
                  <option value="10" selected={pageSize === 10}>
                    10
                  </option>
                  <option value="20" selected={pageSize === 20}>
                    20
                  </option>
                  <option value="50" selected={pageSize === 50}>
                    50
                  </option>
                  <option value="100" selected={pageSize === 100}>
                    100
                  </option>
                  <option value="150" selected={pageSize === 150}>
                    150
                  </option>
                </select>
              </div>

              <span className="ml-2">Items per page</span>
            </div>
          </div>
    )

}

export default PaginatorDropdown;