import React, { Component } from "react";
import One from "../../assets/bookingeventassets/assets/1.svg";
import Path2 from "../../assets/bookingeventassets/assets/path2.svg";
import Sec from "../../assets/bookingeventassets/assets/sec.svg";
import W from "../../assets/bookingeventassets/assets/w.svg";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import P from "../../assets/bookingeventassets/assets/p.svg";
import ZoomIcon from "../../assets/icons/zoom.svg";
import MeetIcon from "../../assets/icons/google-meet.svg";
import TeamsIcon from "../../assets/icons/Microsoft_Teams.svg";
import GoToIcon from "../../assets/icons/goto_icon.svg";
import PhoneCall from "../../assets/icons/phone_call.svg";
import InPersonMeet from "../../assets/icons/in_person_meet.svg";
import OtherMeet from "../../assets/icons/other_meet_icon.svg";
import moment from "moment";
import isEmpty from "../../validation/is-empty";

class BookingStep4 extends Component {
  render() {
    const {
      event,
      selectedSlot,
      endSlotTime,
      bookingDate,
      specialRibbon,
      timezoneValue,
      totalPrice,
      guests,
    } = this.props;

    let bookedOn = moment(bookingDate, "YYYY-MM-DD");

    const bookedDayName = bookedOn.format("dddd");
    const bookedMonthName = bookedOn.format("MMMM");
    const bookedMonthDay = bookedOn.format("DD");
    const bookedYear = bookedOn.format("YYYY");

    const bookingTime = `${selectedSlot} - ${endSlotTime}, ${bookedDayName}, ${bookedMonthName} ${bookedMonthDay}, ${bookedYear}`;
    // console.log(event);
    return (
      <>
        <div className="row">
          {!isEmpty(specialRibbon) && (
            <div className="col-12">
              <nav className="navbar navbar-expand-sm main_noti shadow">
                <ul className="navbar-nav">
                  <li className="nav-item noti_side">
                    <img src={One} />
                  </li>

                  <li className="nav-item left_noti ml-3 ml-md-0">
                    <span>{specialRibbon}</span>
                  </li>
                </ul>
              </nav>
            </div>
          )}
          <div className="col-12">
            <div className="booking_arrow row align-items-center mx-0">
              <div className="col-auto">
                <img src={ArrowLeft} alt="Arrow Left" />
              </div>
              <div className="col-auto ps-0">
                <p>Back</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center sub_div confirm-book">
          <div class="col-12 main_add border-0">
            <div class="col-12 text-center mb-2">
              <img src={Path2} alt="booking icon" className="book_icon" />
            </div>
            <div class="col-12 mb-2">
              <h1 id="sub_head" class="text-center">
                Confirmed
              </h1>
            </div>
            <div class="col-12 mb-2 text-center">
              <p>You Are Scheduled.</p>
            </div>
            <div class="col-12 mb-4">
              <div class="row add_cal justify-content-center align-items-center">
                <div class="sechulde_s">
                  <img src={Sec} />
                </div>
                <div class="sechulde_d pt-1">Add To Your Calendar</div>
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="row justify-content-center">
                <div class="col-12 for-border"></div>
              </div>
            </div>
            {/* Title */}
            <div class="col-12 mb-2">
              <div class="row">
                <div class="col-12">
                  <h1 id="sub_head">{event.event_name}</h1>
                </div>
              </div>
            </div>
            {/* Event Time and venue */}
            <div class="col-12 mt-3 mb-4">
              <div class="row justify-content-between">
                <div class="col-lg-auto mb-3 mb-lg-0">
                  <div class="row flex-nowrap mx-0">
                    <div class="sechulde_s">
                      <img src={Sec} />
                    </div>
                    <div class="sechulde_d">{bookingTime}</div>
                  </div>
                </div>
                <div class="col-lg-auto col-md-6 mb-3 mb-md-0">
                  <div class="row flex-nowrap mx-0">
                    <div class="sechulde_s">
                      <img src={W} />
                    </div>
                    <div class="sechulde_d">{timezoneValue}</div>
                  </div>
                </div>
                <div class="col-lg-auto col-md-6">
                  <div class="row align-items-center flex-nowrap mx-0">
                    <div class="sechulde_s">
                      {event.venue === "Microsoft Teams" ? (
                        <img src={TeamsIcon} alt="microsoft teams icon" />
                      ) : event.venue === "Zoom" ? (
                        <img src={ZoomIcon} alt="Zoom icon" />
                      ) : event.venue === "Google Meet" ? (
                        <img src={MeetIcon} alt="Google Meet icon" />
                      ) : event.venue === "Phone Call" ? (
                        <img src={PhoneCall} alt="Phone Call icon" />
                      ) : event.venue === "In-Person Meeting" ? (
                        <img
                          src={InPersonMeet}
                          alt="In Person Meet icon"
                          style={{ maxWidth: 13 }}
                        />
                      ) : event.venue === "Others" ? (
                        <img src={OtherMeet} alt="Other icon" />
                      ) : event.venue === "Go to Meeting" ? (
                        <img
                          src={GoToIcon}
                          alt="Go To icon"
                          style={{ maxWidth: 30 }}
                        />
                      ) : (
                        <img src={P} alt="event_img" />
                      )}
                    </div>
                    <div class="sechulde_d">{event.venue}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* Event Detail */}
            <div class="col-12 mb-4 eve_detail">
              <div class="row justify-content-between">
                {!isEmpty(event.instruction_to_attendee) && (
                  <div class="col-md-6 mb-4">
                    <>
                      <h4>Instructions to Attendee</h4>
                      <p>{event.instruction_to_attendee}</p>
                    </>
                  </div>
                )}
                {!isEmpty(event.cancellation_policy) && (
                  <div class="col-md-6 mb-4">
                    <>
                      <h4>Cancellation Policy</h4>
                      <p>{event.cancellation_policy}</p>
                    </>
                  </div>
                )}

                {(event.venue_id === 1 || event.venue_id === "1") &&
                  event.venue_location_status ===
                    "display_booking_after_confirmed" && (
                    <div className="inst_main">
                      <h4>Venue Location</h4>
                      <p>{event.venue_location}</p>
                    </div>
                  )}

                {(event.venue_id === 7 || event.venue_id === "7") &&
                  (event.venue_other_display_status === 2 ||
                    event.venue_other_display_status === "2") && (
                    <div class="col-md-6 mb-4">
                      <h4>Note</h4>
                      <p>{event.venue_other_notes}</p>
                    </div>
                  )}

                {event.is_event_paid === "E" && (
                  <div class="col-md-6 mb-4 d-flex align-items-center">
                    <h4>Total Payment Pay</h4>
                    <p className="mt-0 ml-4">
                      <>
                        <span>$ {totalPrice}</span>
                      </>
                    </p>
                  </div>
                )}

                {!isEmpty(guests) && (
                  <div className="col-12 emails">
                    <h4>Guest Email</h4>
                    <div className="d-flex flex-wrap">
                      {guests.map((guest, index) => {
                        return <p key={`guest-${index}`}>{guest}</p>;
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="row">
                <div class="col-12 for-border"></div>
              </div>
            </div>
            <div className="col-12 text-center">
              <h3 className="subtitle">
                A calendar invitation has been sent to your email address.
              </h3>
            </div>
            <div class="col-12 mb-2 d-none">
              <div class="row">
                <div class="col-12 snd_text_head">
                  <h1>Simple. Automated. Scheduling</h1>
                </div>
              </div>
            </div>
            <div class="col-12 mb-2 d-none">
              <div class="snd_input">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="test@gmail.com"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 mb-2 d-none">
              <div class="snd_input">
                <button type="button" class="btn btn-info btn-block">
                  Try It Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BookingStep4;
