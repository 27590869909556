import React, { useState, useEffect } from "react";
import ConnectToCalendarView from "./connect";
import ConnectedCalendars from "./connected";
import "../../../assets/calendar-integration/scss/style.scss";
import { getAdminConnectedCalendars } from "../../../actions/admin/calendar-integration-actions";
import { connect } from "react-redux";
import Loader from "../../../components/Loader/Loader";

const CalendarIntegration = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [view, setView] = useState("1");

  useEffect(() => {
    const { getAdminConnectedCalendars } = props;
    setIsLoading(true);

    getAdminConnectedCalendars({
      onSuccess: function (response) {
        if (response.entity.length) {
          setData(response.entity);
          setView("1");
        } else {
          setView("2");
        }
        setIsLoading(false);
      },
      onError: function (error) {
        console.log("error", error);
        setIsLoading(false);
      },
    });
  }, []);

  return (
    <>
      <Loader isShowLoader={isLoading} />
      {!isLoading && view === "1" && (
        <ConnectedCalendars
          data={data}
          setCalendarIntegrationView={setView}
          setIntegrationDataSet={setData}
        />
      )}
      {!isLoading && view === "2" && <ConnectToCalendarView 
      data={data}
      setCalendarIntegrationView={setView} 
      />}
    </>
  );
};

export default connect(null, {
  getAdminConnectedCalendars,
})(CalendarIntegration);
