import React from "react";
import {
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DefaultIcon from "../../../../../assets/icons/self_star.svg";
import ElipsisVertical from "../../../../../assets/icons/elipsisV.svg";
import ElipsisVDark from "../../../../../assets/icons/elipsisV_dark.svg";
import { Edit, Trash2, Copy, Star } from "react-feather";
import useMediaQuery from "../../../../../hooks/useMediaQuery";

const ListScheduleTemplates = ({
  listSchedules,
  selectedScheduleId,
  settingsDropdownStatus,
  selectedScheduleName,
  selectedScheduleIsDefaultOne,
  scheduleListDropDownStatus,
  onAddScheduleHandle,
  loadSchedule,
  toggleSettingListDropdown,
  showEditScheduleModal,
  onClone,
  showMakeAsDefaultHandle,
  showDeleteScheduleHandle,
  toggleScheduleListDropdown,
  setListSchedules,
}) => {
  const isTablet = useMediaQuery("(min-width: 768px)");

  const templateSize = isTablet ? 4 : 1;

  return (
    <>
      <Col md="12" className="mb-4">
        <div className="d-flex justify-content-between">
          <div className="template_wrapper d-flex">
            {(listSchedules || [])
              .filter((schedule, index) => index < templateSize)
              .map((schedule, innerIndex) => {
                if (schedule.is_default === "E") {
                  return (
                    <button
                      key={innerIndex}
                      className={`btn avail_templates ${
                        schedule.id == selectedScheduleId ? "active" : ""
                      }`}
                      onClick={() => {
                        if (schedule.id !== selectedScheduleId) {
                          loadSchedule(schedule.id);
                        }
                      }}
                    >
                      <img src={DefaultIcon} alt="" />
                      <span>
                        {schedule.schedule_name}
                        {schedule.is_business_hours === "E" && (
                          <p>You can not delete it</p>
                        )}
                      </span>

                      {schedule.id === selectedScheduleId && (
                        <Dropdown
                          isOpen={settingsDropdownStatus}
                          toggle={toggleSettingListDropdown}
                          className="float-right ml-auto"
                          direction="left"
                        >
                          <DropdownToggle caret tag="a">
                            <img
                              src={ElipsisVertical}
                              alt=""
                              className="elipsis"
                            />
                          </DropdownToggle>

                          <DropdownMenu>
                            <DropdownItem
                              onClick={(event) => {
                                showEditScheduleModal(
                                  selectedScheduleId,
                                  selectedScheduleName
                                );
                              }}
                            >
                              <Edit size={18} className="mr-2" />
                              Edit Name
                            </DropdownItem>

                            <DropdownItem onClick={onClone}>
                              <Copy size={18} className="mr-2" />
                              Clone
                            </DropdownItem>

                            {!selectedScheduleIsDefaultOne && (
                              <DropdownItem
                                onClick={() => {
                                  showMakeAsDefaultHandle(selectedScheduleId);
                                }}
                              >
                                <Star size={18} className="mr-2" />
                                Set as Default
                              </DropdownItem>
                            )}

                            <DropdownItem
                              onClick={() => {
                                showDeleteScheduleHandle(selectedScheduleId);
                              }}
                            >
                              <Trash2 size={18} className="mr-2" />
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      )}
                    </button>
                  );
                } else {
                  return (
                    <button
                      key={innerIndex}
                      className={`btn avail_templates ${
                        schedule.id == selectedScheduleId ? "active" : ""
                      }`}
                      onClick={() => {
                        if (schedule.id !== selectedScheduleId) {
                          loadSchedule(schedule.id);
                        }
                      }}
                    >
                      <span>
                        {schedule.schedule_name}
                        {schedule.is_business_hours === "E" && (
                          <p>You can not delete it</p>
                        )}
                      </span>

                      {schedule.id === selectedScheduleId && (
                        <Dropdown
                          isOpen={settingsDropdownStatus}
                          toggle={toggleSettingListDropdown}
                          className="float-right ml-auto"
                          direction="left"
                        >
                          <DropdownToggle caret tag="a">
                            <img
                              src={ElipsisVertical}
                              alt=""
                              className="elipsis"
                            />
                          </DropdownToggle>

                          <DropdownMenu>
                            <DropdownItem
                              onClick={(event) => {
                                showEditScheduleModal(
                                  selectedScheduleId,
                                  selectedScheduleName
                                );
                              }}
                            >
                              <Edit size={18} className="mr-2" />
                              Edit Name
                            </DropdownItem>

                            <DropdownItem onClick={onClone}>
                              <Copy size={18} className="mr-2" />
                              Clone
                            </DropdownItem>

                            {!selectedScheduleIsDefaultOne && (
                              <DropdownItem
                                onClick={() => {
                                  showMakeAsDefaultHandle(selectedScheduleId);
                                }}
                              >
                                <Star size={18} className="mr-2" />
                                Set as Default
                              </DropdownItem>
                            )}

                            <DropdownItem
                              onClick={() => {
                                showDeleteScheduleHandle(selectedScheduleId);
                              }}
                            >
                              <Trash2 size={18} className="mr-2" />
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      )}
                    </button>
                  );
                }
              })}
          </div>
          {listSchedules.length > templateSize && (
            <div>
              <Dropdown
                isOpen={scheduleListDropDownStatus}
                toggle={toggleScheduleListDropdown}
                className="more_drop d-inline-block"
                direction="left"
              >
                <DropdownToggle caret tag="a">
                  <button className="btn avail_templates more_btn">
                    <div className="noti_num">
                      {listSchedules.length >= templateSize + 10
                        ? "9+"
                        : listSchedules.length - templateSize}
                    </div>
                    <img src={ElipsisVDark} alt="" className="elipsis" />
                  </button>
                </DropdownToggle>

                <DropdownMenu>
                  {listSchedules
                    .filter(
                      (schedule, filterIndex) => filterIndex > templateSize - 1
                    )
                    .map((schedule, index) => {
                      return (
                        <DropdownItem
                          key={schedule.id}
                          onClick={() => {
                            const schedules = [...listSchedules];
                            // for mobile show on first and on desktop show on third
                            const replacingIndex = templateSize === 1 ? 0 : 2;
                            const temp = schedules[replacingIndex];
                            schedules[replacingIndex] = {
                              id: schedule.id,
                              schedule_name: schedule.schedule_name,
                            };
                            schedules[index + templateSize] = temp;
                            setListSchedules(schedules);
                            loadSchedule(schedule.id);
                          }}
                        >
                          {schedule.schedule_name}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </div>
      </Col>
    </>
  );
};

export default ListScheduleTemplates;
