import React, { useEffect, useState } from 'react';
import { Bar, Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import LineChartIcon from "../../../../../assets/icons/line_chart_icon.svg";
import BarChartIcon from "../../../../../assets/icons/bar_chart_icon.svg";
import LineChartWhite from "../../../../../assets/icons/line_chart_white.svg";
import BarChartClr from "../../../../../assets/icons/bar_chart_color.svg";
import RefresIcon from "../../../../../assets/icons/refresh_icon.svg";
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import { sendPostRequest } from '../../../../../actions/app-actions';
import { connect } from "react-redux";
import { Select } from "antd";
import { getAdminBusinessId } from '../../../../../utils/authHelper';
import DatePicker from "react-date-picker";
import moment from 'moment';
import Tooltip from "../../../../../components/Tooltip";

Chart.register(...registerables);
const StaffCommisionChart = (props) => {
    const [chartType, setChartType] = useState("line");
    const [chartData, setChartData] = useState(null);
    const [renderChart, setRenderChart] = useState(false);
    const [staffId, setStaffId] = useState("");
    const [filterMonth, setFilterMonth] = useState(new Date());
    const [filterYear, setFilterYear] = useState(new Date());
    const [data, setData] = useState([]);


    const isDesktop = useMediaQuery('(min-width: 1200px)');
    const isTablet = useMediaQuery('(min-width: 768px)');
    const isMobile = useMediaQuery('(min-width: 250px)');

    const options = {
        scales: {
            y:
            {
                min: 0,
                //stepSize: 1,
                //max:15,
                ticks: {
                    //precision: 0,
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return '$ ' + value;
                    }
                }
            }
        },
    };

    useEffect(() => {
        setChartData({
            labels: data.map(row => row.label),
            datasets: [
                {
                    label: "Commission",
                    data: data.map((data) => data.total),
                    backgroundColor: "#1789C6",
                    borderRadius: 10,
                    borderWidth: chartType === "line" ? 2 : 0,
                    borderColor: chartType === "line" ? "#1789C6" : "transparent",
                    tension: chartType === "line" ? 0.5 : 0,
                    pointRadius: chartType === "line" ? isTablet ? 5 : 2 : 0
                }
            ]
        });
        setRenderChart(true);
    }, [chartType]);

    useEffect(() => {
        /*
        const { getStaffs } = props;
        getStaffs({
            data: {
                pageSize: 10000,
                pageNumber: 1,
            },
            onSuccess: function (response) {
                const staffList = response.entity.map((staff, index) => {
                    return {
                        label: staff.first_name + " " + staff.last_name,
                        value: staff.id,
                    };
                });
                setStaffs([{ value: "", label: "All Staffs" }, ...staffList]);
            },
            onError: function (error) {
                console.log("error", error);
            },
        });
        */

        loadChartData();
    }, []);

    /**
     * loads chart data
     * @param {*} data 
     */
    const loadChartData = (data = {}) => {
        const { sendPostRequest } = props;
        sendPostRequest({
            url: `/admin/get-staff-commission-earned/${getAdminBusinessId()}`,
            data,
            onSuccess: function (response) {
                const { data } = response;
                setData(data.data);
                setChartData({
                    labels: data.data.map(row => row.label),
                    datasets: [
                        {
                            label: "Commission",
                            data: data.data.map((data) => data.total),
                            backgroundColor: "#1789C6",
                            borderRadius: 10,
                            borderWidth: chartType === "line" ? 2 : 0,
                            borderColor: chartType === "line" ? "#1789C6" : "transparent",
                            tension: chartType === "line" ? 0.5 : 0,
                            pointRadius: chartType === "line" ? isTablet ? 5 : 2 : 0
                        }
                    ]
                });

            },
            onError: function (error) {
                console.log("error", error);
            },
        });
    }

    return (<>
        <div className="col-lg-8">
            <div className="page_title_dashboard">
                <div className="row justify-content-between align-items-center">
                    <div className="col-auto mb-3 mb-md-0">
                        <h3>Commissions Earned/ staff <Tooltip code="P3F8" /></h3>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        <DatePicker
                            name="start_date"
                            format="MM-yyyy"
                            maxDetail="year"
                            showNavigation={true}
                            value={filterMonth}
                            locale="en-GB"
                            selected={(filterMonth && new Date(filterMonth)) || null}
                            onChange={(val) => {
                                setFilterMonth(val);
                                loadChartData({
                                    event_id: staffId,
                                    month: val ? moment(val).format('MM') : null,
                                    year: val ? moment(val).format('YYYY') : null
                                });
                            }}
                        />
                        <div className="slct">
                            <Select
                                size={"large"}
                                placeholder="Select Staff"
                                allowClear={true}
                                onChange={(value) => {
                                    setStaffId(value);
                                    loadChartData({
                                        staff_id: value,
                                        month: filterMonth ? moment(filterMonth).format('MM') : filterMonth,
                                        year: filterYear ? moment(filterYear).format('YYYY') : filterYear
                                    });
                                }}
                                style={{
                                    width: "100%",
                                }}
                                value={staffId}
                                options={props.staffs}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="rd_vacationfilterpart mt-2">
                <div className="col-12">
                    <div className="chart_container">
                        <div className="head">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-auto page_title_dashboard">
                                    <h2>Stats</h2>
                                </div>
                                <div className="col-auto">
                                    <div className="tools d-block">
                                        {/* Chart Type */}
                                        <div className="chart_type">
                                            <button className={`btn ${chartType === "line" ? "active" : ""}`} onClick={() => setChartType("line")}><img src={chartType === "line" ? LineChartWhite : LineChartIcon} alt="Line Chart Icon" /></button>
                                            <button className={`btn ${chartType === "bar" ? "active" : ""}`} onClick={() => setChartType("bar")}><img src={chartType === "bar" ? BarChartIcon : BarChartClr} alt="Bar Chart Icon" /></button>
                                            <button className='btn refres_btn' onClick={() => {
                                                setStaffId("");
                                                setFilterMonth(null);
                                                setFilterYear(null);
                                                loadChartData({
                                                    staff_id: "",
                                                    month: null,
                                                    year: null
                                                });
                                            }}><img src={RefresIcon} alt="Refresh Icon" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {renderChart && chartType === "bar" ?
                            <Bar
                                data={chartData}
                                options={options}
                            // options={{
                            //     plugins: {
                            //         legend: {
                            //             display: false
                            //         }
                            //     }
                            // }}
                            /> : null}
                        {renderChart && chartType === "line" ?
                            <Line
                                data={chartData}
                                options={options}
                            // options={{
                            //     plugins: {
                            //         legend: {
                            //             display: false
                            //         }
                            //     }
                            // }}
                            /> : null}
                    </div>
                </div>
            </div>
        </div>



    </>);
}

export default connect(null, { sendPostRequest })(StaffCommisionChart);