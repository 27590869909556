import React, { useEffect, useState } from "react";
import logoSm from "../../../../../assets/images/logo-sm.png";
import fillStep from "../../../../../assets/images/fill.png";
import currentStep from "../../../../../assets/images/current-step.png";
import unSelectedRadio from "../../../../../assets/images/unselected-radio.png";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import axios from "../../../../../axios-instance";
import * as Yup from "yup";
import Loader from "../../../../../components/Loader/Loader";
import { FormGroup, Input, Label } from "reactstrap";
import { getUserBusinesses } from "../../../../../utils/authHelper";

const ProfileStepThreeView = (props) => {
  const navigate = useNavigate();

  const [pageLoading, setPageLoading] = useState(false);

  const Schema = Yup.object().shape({
    role: Yup.mixed().required("Role cannot be empty"),
    event_types: Yup.mixed().required("Event Types cannot be empty"),
  });
  const formikProps = {
    initialValues: {
      role: "",
      other_role: "",
      event_types: [],
      other_event_type: "",
    },
    validationSchema: Schema,
    onSubmit: (values, formikHelpers) => {
      // check if others role is checked
      if (values.role === "7") {
        values.role = values.other_role;
      }

      // check if other event type is checked
      if (values.event_types.includes(String(9))) {
        values.event_types.push(values.other_event_type);
      }
      const filteredEventType = values.event_types.filter(function (item) {
        return item !== "9";
      });
      values.event_types = filteredEventType;
      setPageLoading(true);
      if (getUserBusinesses()) {
        const business_id = Object.values(getUserBusinesses()).pop();
        values.business_id = business_id;
      }
      axios
        .post("/admin/quick-setup-step-three", {
          ...values,
          event_types: values.event_types.join(","),
        })
        .then((response) => {
          setPageLoading(false);
          navigate("/signup/profile-step-four");
        })
        .catch((error) => {
          setPageLoading(false);
          console.error(error);
        });
    },
  };

  const [profession, setProfession] = useState([]);

  useEffect(() => {
    setPageLoading(true);
    axios
      .get("/profession-types")
      .then((response) => {
        console.log(response);
        setProfession(response.data.data.profession_types);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [eventTypes, setEventTypes] = useState([]);

  useEffect(() => {
    axios
      .get("/event-types")
      .then((response) => {
        setPageLoading(false);
        setEventTypes(response.data.data.eventTypes);
      })
      .catch((error) => {
        setPageLoading(false);
        console.error(error);
      });
  }, []);
  return (
    <>
      <Loader isShowLoader={pageLoading} />
      <Formik {...formikProps}>
        {(formik) => {
          const { values, handleChange, handleSubmit, handleBlur, errors } =
            formik;

          return (
            <div className="profile-step-three">
              <header>
                <div className="header-container">
                  <div className="logo">
                    <img src={logoSm} alt="" />
                  </div>
                </div>
              </header>

              <div className="profile-step signup_proc">
                <div className="step-1">
                  <h1>Thank you for signing up</h1>
                  <p className="m-0">Just few more steps to get you started</p>
                  <div className="by-step">
                    <div className="step">
                      <img src={fillStep} alt="" />
                      <p className="current-step">Step 1</p>
                    </div>
                    <div className="step">
                      <img src={fillStep} alt="" />
                      <p className="current-step">Step 2</p>
                    </div>
                    <div className="step">
                      <img src={currentStep} alt="" />
                      <p className="current-step">Step 3</p>
                    </div>
                    <div className="step">
                      <img src={unSelectedRadio} alt="" />
                      <p>Step 4</p>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="same-label" htmlFor="role">
                      What’s Your Role In Your Company?
                    </label>
                    <div className="form-checks">
                      {profession.map((item) => (
                        <div className="form-check">
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-check-input"
                            value={item.id}
                            type="radio"
                            name="role"
                            id={item.id}
                          />
                          <label className="form-check-label" htmlFor={item.id}>
                            {item.profession}
                          </label>
                        </div>
                      ))}
                    </div>
                    {values.role == "7" && (
                      <>
                        <input
                          type="text"
                          className="form-control same-input"
                          name="other_role"
                          id="other_role"
                          placeholder="Enter Role"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="other_role"
                          render={(error) => (
                            <span className="error">{error}</span>
                          )}
                        />
                      </>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="same-label">
                      What Type Of Event Will You Be Using Meetocto Mainly?
                    </div>
                    <div className="flex events">
                      {eventTypes.map((item) => (
                        <FormGroup check>
                            <Input type="checkbox"
                              name="event_types"
                              value={item.id}
                              checked={values.event_types.includes(String(item.id))}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          {' '}
                          <Label className="tag-button-label" check>
                            {item.event_type}
                          </Label>
                        </FormGroup>
                      ))}

                      <FormGroup check>
                            <Input type="checkbox"
                              name="event_types"
                              value={9}
                              checked={values.event_types.includes(String(9))}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          {' '}
                          <Label className="tag-button-label" check>
                            Others, Specify
                          </Label>
                        </FormGroup>
                    </div>
                    {values.event_types.includes(String(9)) && (
                      <>
                        <input
                          type="text"
                          className="form-control same-input"
                          name="other_event_type"
                          id="other_event_type"
                          placeholder="Enter Event type"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="event_types"
                          render={(error) => (
                            <span className="error">{error}</span>
                          )}
                        />
                      </>
                    )}
                  </div>
                  <div className="submit-btns">
                    <Link to="/admin/dashboard" className="skip-btn">
                      <button className="btn btn-outline" type="button">
                        Go Back
                      </button>
                    </Link>
                    <button type="submit" className="btn btn-app">
                      Next Step
                    </button>
                  </div>
                </form>
              </div>

              <footer>
                <ul className="languages d-none">
                  <li>English (UK)</li>
                  <li>हिन्दी</li>
                  <li>ਪੰਜਾਬੀ</li>
                  <li>বাংলা</li>
                  <li>Deutsch</li>
                  <li>ગુજરાતી</li>
                  <li>Español</li>
                </ul>
                <p>Copyright © 2021 MeetOcto.</p>
              </footer>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default ProfileStepThreeView;
