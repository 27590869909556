import React, { useState, useEffect, useRef } from "react";
import * as Icon from "react-feather";
import isEmpty from "../../validation/is-empty";
import { IMAGE_BASE_URL } from "../../utils/utility";
import VideoIcon from "../../assets/icons/video_icon.svg";
import ArticleIcon from "../../assets/icons/article_icon.svg";

const Tooltip = ({ code }) => {
  const [isShow, setIsShow] = useState(false);
  const [tooltipText, setTooltipText] = useState(null);
  const tooltipImg = useRef(null);

  useEffect(() => {
    const tooltips = localStorage.getItem("tooltips");
    if (tooltips) {
      const tooltip = JSON.parse(tooltips).find((item) => {
        return item.tooltip_code == code;
      });

      if (!isEmpty(tooltip)) {
        setTooltipText(tooltip.tooltip_text);

        // tooltip img
        if (!isEmpty(tooltip.tooltip_image)) {
          tooltipImg.current = tooltip.tooltip_image;
        }
        setIsShow(true);
      }
    }
  }, []);

  return (
    <>
      {isShow && (
        <span className="tooltip-wrapper">
          <Icon.HelpCircle size="16" />
          <div className={`tooltip-section`}>
            <div className="cust_tooltip">
              {!isEmpty(tooltipImg.current) && (
                <div className="img">
                  <img src={`${IMAGE_BASE_URL}${tooltipImg.current}`} />
                  {/* <img
                  src="https://www.staging.thrive-booking.com/backend/public//uploads/events/event-1676283255.png"
                  alt="Tooltip img"
                /> */}
                </div>
              )}
              <div className="body">
                {/* <h3>Reschedule</h3> */}
                {!isEmpty(tooltipText) && <p>{tooltipText}</p>}
                <a href="/#">
                  <img src={VideoIcon} alt="Video Icon" /> Watch Video
                </a>
                <a href="/#">
                  <img
                    src={ArticleIcon}
                    alt="Article Icon"
                    className="article"
                  />{" "}
                  Read Article
                </a>
              </div>
            </div>
          </div>
        </span>
      )}
    </>
  );
};

export default Tooltip;
