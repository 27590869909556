import {
  TOGGLE_ADMIN_PROFILE_DROPDOWN,
  TOGGLE_MOB_LEFT_MENU,
  SET_IS_LOADING,
  CHANNELS_LOADED,
} from "../actions/types";
const initialState = {
  events: [],
  sidebar: false,
  selectedEvent: null,
  profileDropdownStatus: false,
  leftMenuActiveClass: null,
  isLoading: false,
  isChannelsLoaded: false,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ToogleSideBar":
      return { ...state, sectionClass: action.sectionClass };
    case "HANDLE_SELECTED_EVENT":
      return { ...state, selectedEvent: action.event };
    case TOGGLE_ADMIN_PROFILE_DROPDOWN:
      return {
        ...state,
        profileDropdownStatus: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case TOGGLE_MOB_LEFT_MENU:
      return {
        ...state,
        leftMenuActiveClass: action.payload,
      };
    case CHANNELS_LOADED:
      return {
        ...state,
        isChannelsLoaded: true,
      };
    default:
      return state;
  }
};

export default layoutReducer;
