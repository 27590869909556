


export const SuperAdminDashboard_START = 'SuperAdminDashboard_START';
export const SuperAdminDashboard_END = 'SuperAdminDashboard_END';
export const SuperAdminDashboard_ERROR = 'SuperAdminDashboard_ERROR';
export const SuperAdminDashboard_SUCCESS = 'SuperAdminDashboard_SUCCESS';
export const SuperAdminDashboardFeed_SUCCESS = 'SuperAdminDashboardFeed_SUCCESS';
export const SuperAdminDashboardPie_SUCCESS = 'SuperAdminDashboardPie_SUCCESS';

export const SuperAdminDashboard_FAIL = 'SuperAdminDashboard_FAIL';
export const IsDataSubmitedSuccessfullySuperAdminDashboard ='IsDataSubmitedSuccessfullySuperAdminDashboard';