import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import One from "../../assets/bookingeventassets/assets/1.svg";
import PaypalSVG from "../../assets/bookingeventassets/assets/PayPal.svg";
import CreditCardSVG from "../../assets/bookingeventassets/assets/credit.svg";
import PathSVG from "../../assets/bookingeventassets/assets/path.svg";
import FormField from "../../components/FormField/FormField";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import { FormGroup, Input, Label } from "reactstrap";
import isEmpty from "../../validation/is-empty";
import { notification } from "../../utils/utility";
import { getPromoByCode } from "../../actions/admin/promo-actions";
import { connect } from "react-redux";
import Loader from "../../components/Loader/Loader";

const validation = Yup.object().shape({
  card_number: Yup.string().required("Card number is required."),
  card_holder: Yup.string().required("Card holder is required."),
  card_expiry: Yup.string().required("Card expiry is required."),
  cvc: Yup.string().required("CVC is required."),
});

const noValidation = null;

let initialValues = {
  card_number: "",
  card_holder: "",
  card_expiry: "",
  cvc: "",
};

class BookingStep3 extends Component {
  state = {
    addOns: [],
    pricing: [],
    couponCode: "",
    discount: 0,
    isLoading: false,
  };

  /**
   * Get total pricing
   * @returns int
   */
  getTotalPricing = () => {
    const { pricing } = this.props;
    let totalPricing = 0;
    (pricing || []).forEach((eventPricing, index) => {
      totalPricing +=
        Number(eventPricing.item_price) * Number(eventPricing.item_qty);
    });
    return totalPricing;
  };

  /**
   * Get total addons
   * @returns int
   */
  getTotalAddons = () => {
    const { addOns } = this.props;
    let totalAddOns = 0;
    (addOns || []).forEach((addOn, index) => {
      totalAddOns += Number(addOn.addon_price) * Number(addOn.addon_qty);
    });
    return totalAddOns;
  };

  onApplyCouponCode = () => {
    const _this = this;

    const { getPromoByCode } = _this.props;
    const { couponCode } = this.state;
    if (isEmpty(couponCode))
      return notification.error("Please enter Coupon code");

    _this.setState({ isLoading: true, discount: 0 });

    getPromoByCode({
      data: {
        couponCode,
      },
      onSuccess: function (response) {
        _this.setState({ isLoading: false });
        const { promo } = response;
        if (isEmpty(promo)) {
          return notification.error("Coupon code does not exist.");
        }
        const couponValue = promo.coupon_value;
        const totalAmount = _this.getTotalPricing() + _this.getTotalAddons();
        if (promo.coupon_payout_type === "P") {
          _this.setState({
            discount: Math.floor((totalAmount / 100) * couponValue),
          });
        } else {
          _this.setState({
            discount: couponValue,
          });
        }
      },
      onError: (error) => {
        _this.setState({ isLoading: false });
      },
    });
  };

  render() {
    const _this = this;
    const { event, onScheduleEvent, addOns, pricing, specialRibbon } =
      this.props;

    const { isLoading, discount } = this.state;

    let totalPricing = this.getTotalPricing();
    let totalAddOns = this.getTotalAddons();

    return (
      <>
        <Loader isShowLoader={isLoading} />
        <div className="row">
          {!isEmpty(specialRibbon) && (
            <div className="col-12">
              <nav className="navbar navbar-expand-sm main_noti shadow">
                <ul className="navbar-nav">
                  <li className="nav-item noti_side">
                    <img src={One} />
                  </li>

                  <li className="nav-item left_noti ml-3 ml-md-0">
                    <span>{specialRibbon}</span>
                  </li>
                </ul>
              </nav>
            </div>
          )}
          <div className="col-12">
            <div className="booking_arrow row align-items-center mx-0">
              <div className="col-auto">
                <img src={ArrowLeft} alt="Arrow Left" />
              </div>
              <div className="col-auto ps-0">
                <p>Back</p>
              </div>
              <h1> {event.event_name} </h1>
            </div>
          </div>
          <div className="col-12">
            <div
              className="main_add p-3 border-0 booking_pay_full"
              style={{ borderRadius: 5 }}
            >
              <label className="form-group-label">
                This Event Accepts
              </label>
              <FormGroup check>
                <Input name="radio1" type="radio" checked />
                <Label htmlFor="radio1" check>
                  Full Payment
                </Label>
              </FormGroup>
            </div>
          </div>
        </div>

        <Formik
          validationSchema={
            event.event_prepaid_status === "E" ? validation : noValidation
          }
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(data) => {
            onScheduleEvent({
              cardDetails: data,
              totalPrice: totalPricing + totalAddOns,
              couponCode: _this.state.couponCode,
            });
            console.log("data", data);
          }}
        >
          {(formProps) => {
            const { values, errors, touched, handleChange, setFieldValue } =
              formProps;

            return (
              <Form>
                <div className="row sub_div justify-content-end">
                  <div className="col-lg-6 main_add booking_payment_cards">
                    <div className="head">
                      <h3>Payment Summary</h3>
                    </div>
                    <div className="body">
                      <div className="row justify-content-between">
                        <div className="col-lg-5 col-6">
                          <p>
                            Price Item <span>({pricing.length})</span>
                          </p>
                        </div>
                        <div className="col-auto price">
                          <p>${totalPricing}</p>
                        </div>
                      </div>
                      <div className="row justify-content-between">
                        <div className="col-lg-5 col-6">
                          <p>
                            Add On Item <span>({addOns.length})</span>
                          </p>
                        </div>
                        <div className="col-auto price">
                          <p>${totalAddOns}</p>
                        </div>
                      </div>
                      <div className="row justify-content-between">
                        <div className="col-lg-5 col-6">
                          <p>Full Payment</p>
                        </div>
                        <div className="col-auto price">
                          <p>${totalPricing + totalAddOns}</p>
                        </div>
                      </div>
                      <div className="row justify-content-between">
                        <div className="col-12">
                          <label className="form-group-label">
                            Have A Coupon Code
                          </label>
                          <div class="form-group coupon_code">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Nf162"
                              onChange={(event) => {
                                this.setState({
                                  couponCode: event.target.value,
                                });
                              }}
                            />
                            <button
                              className="btn btn-app"
                              type="button"
                              onClick={this.onApplyCouponCode}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row justify-content-between">
                        <div className="col-lg-5 col-6">
                          <p>Discount</p>
                        </div>
                        <div className="col-auto price">
                          <p>$ {discount}</p>
                        </div>
                      </div>
                      <div className="row justify-content-between total">
                        <div className="col-lg-5 col-6">
                          <p>Total</p>
                        </div>
                        <div className="col-auto price">
                          <p>${totalPricing + totalAddOns - discount}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 main_add booking_payment_cards">
                    <div className="head">
                      <h3>Payment Method</h3>
                    </div>

                    <div className="body">
                      <div className="row">
                        <div className="col-12 d-none">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="payment_option"
                              id="exampleRadios1"
                              value="option1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              <img src={PaypalSVG} />
                            </label>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row">
                            <div className="col-6">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="payment_option"
                                  id="exampleRadios2"
                                  value="option2"
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios2"
                                >
                                  Credit or Debit Card
                                </label>
                              </div>
                            </div>
                            <div className="col-6">
                              <img src={CreditCardSVG} />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="basic-url">Card Number</label>
                                <div className="input-group mb-3">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon1"
                                    >
                                      <img src={PathSVG} />
                                    </span>
                                  </div>

                                  <FormField
                                    type="text"
                                    id="card_number"
                                    name="card_number"
                                    placeholder="**** **** **** ****"
                                    errors={errors}
                                    touched={touched}
                                    value={values.card_number}
                                    disabled={
                                      event.event_prepaid_status === "D"
                                    }
                                    onChange={({ target }) => {
                                      const { value } = target;
                                      handleChange({
                                        target: { name: "card_number", value },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="cardHolder">Card Holder</label>

                                <FormField
                                  type="text"
                                  name="card_holder"
                                  placeholder="Your Name"
                                  errors={errors}
                                  touched={touched}
                                  value={values.card_holder}
                                  disabled={event.event_prepaid_status === "D"}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    handleChange({
                                      target: { name: "card_holder", value },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="card_expiry">Exp. Date</label>

                                <FormField
                                  type="text"
                                  id="card_expiry"
                                  name="card_expiry"
                                  placeholder="Card Expire Date"
                                  errors={errors}
                                  touched={touched}
                                  value={values.card_expiry}
                                  disabled={event.event_prepaid_status === "D"}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    handleChange({
                                      target: { name: "card_expiry", value },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="cvc">Card CVC</label>
                                <FormField
                                  type="text"
                                  name="cvc"
                                  placeholder="CVC"
                                  errors={errors}
                                  touched={touched}
                                  value={values.cvc}
                                  disabled={event.event_prepaid_status === "D"}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    handleChange({
                                      target: { name: "cvc", value },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 text-end mt-4">
                    <button type="submit" className="btn btn-app">
                      Confirm Booking
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default connect(null, {
  getPromoByCode,
})(BookingStep3);
