import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  toogleMenu,
  toggleMobileLeftMenu,
} from "../../../../actions/layoutAction";
import { withRouter } from "../../../../utils/utility";
import * as Icon from "react-feather";
import OctoIcon from "../../../../assets/icons/octo_icon.svg";
import FeatureRequestModal from "../FeatureRequestModal";

class AdminLeftMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowChild: [false, false, false, false],
      extended: true,
      featureRequestStatus: false,
    };
  }

  componentDidMount() {
    this.props.toogleMenu(true);
    this.setState({ extended: true });
  }

  openExtended = (e) => {
    this.props.toogleMenu(true);
    this.setState({ extended: true });
  };
  closeExtended = (e) => {
    this.props.toogleMenu(false);
    this.setState({ extended: false });
  };

  toogleMenu = (idNumber) => {
    var oldArray = this.state.isShowChild;

    if (!this.state.isShowChild[idNumber]) oldArray[idNumber] = true;
    else oldArray[idNumber] = false;

    this.setState({ isShowChild: oldArray });
  };

  /**
   * close mobile left menu
   */
  closeMobileLeftMenu = () => {
    this.props.toggleMobileLeftMenu(null);
  };

  /**
   * hide feature request modal
   */
  hideFeatureRequestModal = () => {
    this.setState({ featureRequestStatus: false });
  };

  render() {
    const { router } = this.props;
    const { location } = router;
    const { pathname } = location;

    const { featureRequestStatus } = this.state;

    return (
      <>
        {featureRequestStatus && (
          <FeatureRequestModal
            hideFeatureRequestModal={this.hideFeatureRequestModal}
          />
        )}
        {!this.state.extended ? (
          <PerfectScrollbar>
            <div className={`sidemenu rd_sidemenusmall dnmobile`}>
              <div className="text-center" role="button">
                <Icon.Maximize2 size={20} onClick={() => this.openExtended()} />{" "}
              </div>

              <div className="ul-listitemmenu rd_listitemmenusmall">
                <a
                  className={
                    "itemcomcon dashboardmenuicon " +
                    (pathname === "/admin/dashboard" ? "active" : "")
                  }
                ></a>
                <a
                  className={
                    "itemcomcon calendarmenuicon " +
                    (pathname === "/admin/my-calendar" ? "active" : "")
                  }
                ></a>
                <a
                  className={
                    "itemcomcon eventcmenuicon schcdulcmenuiconnos " +
                    (pathname === "/admin/events/list" ||
                    pathname === "/admin/events/create/list"
                      ? "active"
                      : "")
                  }
                ></a>
                <a
                  className={
                    "itemcomcon schcdulcmenuicon schcdulcmenuiconnos " +
                    (pathname === "/admin/vacation" ||
                    pathname === "/admin/settings/schedule" ||
                    pathname === "/admin/scheduled-events"
                      ? "active"
                      : "")
                  }
                ></a>
                <a
                  className={
                    "itemcomcon crmmenuicon schcdulcmenuiconnos " +
                    (pathname === "/admin/customer" ||
                    pathname === "/admin/booking-history"
                      ? "active"
                      : "")
                  }
                ></a>
                <div className="logoutlinkmenu">
                  <a className="itemcomcon logoutlinkmenuicon"></a>
                </div>
              </div>
            </div>
          </PerfectScrollbar>
        ) : (
          <PerfectScrollbar>
            <div
              className={`sidemenu ${this.props.layout.leftMenuActiveClass}`}

              // onMouseEnter={() => this.openExtended()}
              // onMouseLeave={() => this.closeExtended()}
            >
              {/* Mobile Menu Options */}
              <div
                className="mobile_left_topbar d-flex d-md-none justify-content-between align-items-center"
                role="button"
              >
                <div className="imgs d-flex align-items-center">
                  <img className="logo_icon" src={OctoIcon} alt="Octo Icon" />
                  <div className="buttonnotgcont react-quicktips">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27.489"
                      height="32.903"
                      viewBox="0 0 27.489 32.903"
                    >
                      <path
                        d="M2163.968,748.191a13.741,13.741,0,1,0-8.467,26.145l3.683,5.782a.66.66,0,0,0,.2.2.653.653,0,0,0,.9-.2l3.679-5.782a13.741,13.741,0,0,0,0-26.145Zm-1.911,16.895h-9.939c-.128,0-.234-.223-.234-.5s.105-.5.234-.5h9.939c.128,0,.232.225.232.5S2162.185,765.086,2162.057,765.086Zm5.173-3.958h-14.994c-.194,0-.352-.225-.352-.5s.158-.5.352-.5h14.994c.2,0,.352.224.352.5S2167.426,761.128,2167.23,761.128Zm0-3.959h-14.994c-.194,0-.352-.223-.352-.5s.158-.5.352-.5h14.994c.2,0,.352.223.352.5S2167.426,757.169,2167.23,757.169Z"
                        transform="translate(-2145.989 -747.519)"
                        fill="#8e8e8e"
                      />
                    </svg>
                    <button
                      className="rd_quicktips"
                      // onClick={onTipsActive}
                    >
                      <span>Quick Tips</span>
                    </button>
                  </div>
                </div>
                <Icon.X
                  size={23}
                  onClick={() => this.props.toggleMobileLeftMenu(null)}
                />
              </div>
              <div className="ul-listitemmenu">
                <div
                  className="text-right mr-2 d-none d-md-block"
                  role="button"
                >
                  <Icon.Minimize2
                    size={20}
                    onClick={() => this.closeExtended()}
                  />
                </div>
                <Link
                  to={"/admin/dashboard"}
                  className={
                    "itemcomconsub dashboardmenuicon " +
                    (pathname === "/admin/dashboard" ? "active" : "")
                  }
                  onClick={this.closeMobileLeftMenu}
                >
                  My Dashboard
                </Link>
                <Link
                  to={"/admin/my-calendar"}
                  className={
                    "itemcomconsub calendarmenuicon " +
                    (pathname === "/admin/my-calendar" ? "active" : "")
                  }
                  onClick={this.closeMobileLeftMenu}
                >
                  My Calendar
                </Link>
                <a
                  className="itemcomcon eventcmenuicon schcdulcmenuiconnos"
                  onClick={() => this.toogleMenu(3)}
                >
                  Event
                </a>
                {this.state.isShowChild[3] ? (
                  <div className={"schcdulcsublinks"}>
                    <Link
                      to={"/admin/events/list"}
                      className={
                        "itemcomconsub " +
                        (pathname === "/admin/events/list" ? "active" : "")
                      }
                      onClick={this.closeMobileLeftMenu}
                    >
                      Event List
                    </Link>
                    <Link
                      to={"/admin/events/create/list"}
                      className={
                        "itemcomconsub " +
                        (pathname === "/admin/events/create/list"
                          ? "active"
                          : "")
                      }
                      onClick={this.closeMobileLeftMenu}
                    >
                      New Event
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
                <a
                  className="itemcomcon schcdulcmenuicon"
                  onClick={() => this.toogleMenu(0)}
                >
                  Schedule
                </a>
                {this.state.isShowChild[0] ? (
                  <div className={"schcdulcsublinks"}>
                    <Link
                      to={"/admin/vacation"}
                      className={
                        "itemcomconsub " +
                        (pathname === "/admin/vacation" ? "active" : "")
                      }
                      onClick={this.closeMobileLeftMenu}
                    >
                      Vacation
                    </Link>
                    <Link
                      to={"/admin/settings/schedule"}
                      className={
                        "itemcomconsub " +
                        (pathname === "/admin/settings/schedule"
                          ? "active"
                          : "")
                      }
                      onClick={this.closeMobileLeftMenu}
                    >
                      Availability
                    </Link>

                    <Link
                      to={"/admin/scheduled-events"}
                      className={
                        "itemcomconsub " +
                        (pathname === "/admin/scheduled-events" ? "active" : "")
                      }
                      onClick={this.closeMobileLeftMenu}
                    >
                      Scheduled Events
                    </Link>
                  </div>
                ) : (
                  <> </>
                )}
                <a
                  className="itemcomcon staffmenuicon"
                  onClick={() => this.toogleMenu(1)}
                >
                  Staff
                </a>
                {this.state.isShowChild[1] ? (
                  <div className={"schcdulcsublinks"}>
                    <Link
                      to={"/admin/staff"}
                      className={
                        "itemcomconsub " +
                        (pathname === "/admin/staff" ? "active" : "")
                      }
                      onClick={this.closeMobileLeftMenu}
                    >
                      Directory Info
                    </Link>

                    {/* <Link
                      to={"/admin/staff/booking-history"}
                      className={
                        "itemcomconsub " +
                        (pathname === "/admin/staff/booking-history"
                          ? "active"
                          : "")
                      }
                    >
                      Booking History
                    </Link> */}
                  </div>
                ) : (
                  <></>
                )}
                <a
                  className="itemcomcon crmmenuicon"
                  onClick={() => this.toogleMenu(2)}
                >
                  CRM
                </a>
                {this.state.isShowChild[2] ? (
                  <div className={"schcdulcsublinks"}>
                    <Link
                      to={"/admin/customer"}
                      className={
                        "itemcomconsub " +
                        (pathname === "/admin/customer" ? "active" : "")
                      }
                      onClick={this.closeMobileLeftMenu}
                    >
                      Customer
                    </Link>
                    <Link
                      to={"/admin/booking-history"}
                      className={
                        "itemcomconsub " +
                        (pathname === "/admin/booking-history" ? "active" : "")
                      }
                      onClick={this.closeMobileLeftMenu}
                    >
                      Booking History
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
                <Link
                  to={"/integrations"}
                  className={
                    "itemcomconsub calendarmenuicon " +
                    (pathname === "/integrations" ? "active" : "")
                  }
                  onClick={this.closeMobileLeftMenu}
                >
                  Apps Integrations
                </Link>

                <div className="mobile_links d-md-none">
                  <Link
                    to={"https://meetocto.freshdesk.com/support/home"}
                    className="itemcomconsub supportmenuicon"
                    target="_blank"
                    onClick={this.closeMobileLeftMenu}
                  >
                    Support
                  </Link>
                  <Link
                    to={"/admin/quick-setup-step-one"}
                    className={
                      "itemcomconsub setupmenuicon " +
                      (pathname === "/admin/quick-setup-step-one"
                        ? "active"
                        : "")
                    }
                    onClick={this.closeMobileLeftMenu}
                  >
                    Quick setup
                  </Link>
                  <a
                    href={"#"}
                    className="itemcomconsub ideasmenuicon"
                    onClick={(event) => {
                      event.preventDefault();
                      this.closeMobileLeftMenu();
                      this.setState({ featureRequestStatus: true });
                    }}
                  >
                    Ideas
                  </a>
                </div>

                <div className="logoutlinkmenu">
                  <a
                    href="#"
                    onClick={this.props.userLogout}
                    className="itemcomcon logoutlinkmenuicon"
                  >
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </PerfectScrollbar>
        )}
      </>
    );
  }
}

const actionMethods = {
  toogleMenu: toogleMenu,
  toggleMobileLeftMenu,
};

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(
  mapStateToProps,
  actionMethods
)(withRouter(AdminLeftMenu));
