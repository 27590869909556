import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import axiosInstance from "../../../axios-instance";
import trueIcon from "../../../assets/images/true-icon.png";
import falseIcon from "../../../assets/images/false-icon.png";
import clear from "../../../assets/images/clear.png";
import ArrowDown from "../../../assets/icons/chevron-double-down.svg";

class PlanDetailModal extends Component {
  state = {
    isOpen: false,
    plans: [],
    planType: "individual",
    planPeriodType: "monthly",
  };

  /**
   * on toggle modal
   */
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  componentDidMount() {
    const _this = this;
    axiosInstance
      .get("/admin/plans")
      .then((response) => {
        _this.setState({
          plans: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * find item in plans
   * @param {*} planType
   * @param {*} planName
   * @param {*} featureName
   * @returns
   */
  findItem = (planType, planName, featureName) => {
    const { plans } = this.state;
    const plan = plans.find((item) => {
      return item.plan_type === planType && item.plan_name === planName;
    });
    if (plan) {
      const plan_detail = plan.plan_details.find((item) => {
        return item.feature_name === featureName;
      });
      if (plan_detail) {
        return plan_detail.status === "E";
      }
    }
    return false;
  };

  /**
   * on buy plan
   */
  onBuyPlan = () => {
    const _this = this;
    const { gotoPaymentPage } = _this.props;
    gotoPaymentPage();
    _this.toggle();
  };

  render() {
    const { isOpen, plans, planType, planPeriodType } = this.state;

    return (
      <>
        <button
          className="btn btn-outline"
          onClick={() => {
            this.toggle();
          }}
        >
          View Details
        </button>

        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className="event-modal modal-xl modal-dialog pricing_table_modal"
        >
          <ModalBody>
            <div className="pricing-modal-container">
              <div className="remove-modal">
                <button type="button" className="btn" onClick={this.toggle}>
                  <img src={clear} alt="" />
                </button>
              </div>
              <div className="pricings-modal">
                <h1 className="heading">Pricing Plans</h1>
                <div className="toggle-section">
                  <p>Individual Plans</p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={planType === "team"}
                      onChange={() => {
                        this.setState({
                          planType:
                            planType === "individual" ? "team" : "individual",
                        });
                      }}
                    />
                    <span className="slider round" />
                  </label>
                  <p>For Teams</p>
                </div>
                {/* Mobile Columns */}
                <div className="pricing-mobile">
                  <div className="btns d-flex">
                    <button
                      className={`btn annully ${planPeriodType === "monthly" ? "btn-app" : "btn-outline"
                        }`}
                      onClick={() => {
                        this.setState({
                          planPeriodType: "monthly",
                        });
                      }}
                    >
                      Monthly
                    </button>
                    <button
                      className={`btn ml-3 annully ${planPeriodType === "annual" ? "btn-app" : "btn-outline"
                        }`}
                      onClick={() => {
                        this.setState({
                          planPeriodType: "annual",
                        });
                      }}
                    >
                      Annually
                    </button>
                  </div>
                  <div className="row g-0">
                    {/* Column 1 */}
                    <div className="col-lg-4 col-md-6">
                      <div className="pricing_col">
                        {/* PRicing Column Head */}
                        <div className="head">
                          <div class="best-plan op-0">Best Value</div>
                          <h3>{planType === "individual" ? <>Free</> : <>Starter</>}</h3>
                          {planType === "individual" ? (
                            <>
                              <h2>$0</h2>
                            </>
                          ) : null}
                          {planType === "team" ? (
                            <>
                              {planPeriodType === "monthly" ? (
                                <h2>$35</h2>
                              ) : (
                                <h2>$30/month</h2>
                              )}
                            </>
                          ) : null}
                          <button className="btn btn-outline" onClick={this.onBuyPlan}>Buy Now</button>
                        </div>
                        {/* PRicing Column Body */}
                        <div className="body">
                          <ul>
                            <li class="feature"><span class="full"><b>Features</b></span></li>
                            {planType === "individual" ? "" : <li class="feature mt-2"><span class="full"><b>2-3 members</b></span></li>}
                            <li><span class="full">Connect 1 calendar/user</span></li>
                            <li><span class="full">2 Events only</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Calendar integrations with Google Calendar, Office 365, Outlook and iCloud</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Personalize MeetOcto link</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Removable MeetOcto Branding</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Pooled availability options for teams <small>(round robin, even rotation, allow attendee to select staff)</small></span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Group Events</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Automated event notifications</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Customize notifications and reminders <small>(SMS and email)</small></span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Redirect to Custom Pages</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Collect Payments <small>(PayPal, Stripe)</small></span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Coupon Codes</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Enable Recurring Booking Discounts</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Upsell/ Cross Sell</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>OctoLink Customization <small>(colors, images upload)</small></span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Metrics and Reporting</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Add OctoLink to your website</span></li>
                            <li><span class="full"><b>Integrations</b></span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Google Meet</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Zoom</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Microsoft Teams</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Go to Webinar</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Zapier</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 text-center pt-4">
                      <span class="full"><img src={ArrowDown} alt="Arrow Down" style={{ width: 50 }} /></span>
                    </div>
                    {/* Column 2 */}
                    <div className="col-lg-4 col-md-6">
                      <div className="pricing_col best">
                        {/* PRicing Column Head */}
                        <div className="head">
                          <div class="best-plan">Best Value</div>
                          <h3>{planType === "individual" ? "Basic" : "Standard"}</h3>
                          <h2>
                            {planType === "individual" ? (
                              <>{planPeriodType === "monthly" ? "$12" : "$120"}</>
                            ) : null}

                            {planType === "team" ? (
                              <>{planPeriodType === "monthly" ? "$55" : "$50/month"}</>
                            ) : null}

                            <span>
                              /{planPeriodType === "monthly" ? "Month" : "Year"}
                            </span>
                          </h2>
                          <button className="btn btn-app" onClick={this.onBuyPlan}>Buy Now</button>
                        </div>
                        {/* PRicing Column Body */}
                        <div className="body">
                          <ul>
                            <li class="feature"><span class="full"><b>Features</b></span></li>
                            {planType === "individual" ? "" : <li class="feature mt-2"><span class="full"><b>4-5 members</b></span></li>}
                            <li><span class="full">Connect 4 calendars/user</span></li>
                            <li><span class="full">Unlimited Events</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Calendar integrations with Google Calendar, Office 365, Outlook and iCloud</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Personalize MeetOcto link</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Removable MeetOcto Branding</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Pooled availability options for teams <small>(round robin, even rotation, allow attendee to select staff)</small></span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Group Events</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Automated event notifications</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Customize notifications and reminders</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Redirect to Custom Pages</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Collect Payments <small>(PayPal, Stripe)</small></span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Coupon Codes</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Enable Recurring Booking Discounts</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Upsell/ Cross Sell</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>OctoLink Customization (colors, images upload)</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Metrics and Reporting</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Add OctoLink to your website</span></li>
                            <li><span class="full"><b>Integrations</b></span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Google Meet</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Zoom</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Microsoft Teams</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Go to Webinar</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Zapier</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 text-center pt-4">
                      <span class="full"><img src={ArrowDown} alt="Arrow Down" style={{ width: 50 }} /></span>
                    </div>
                    {/* Column 3 */}
                    <div className="col-lg-4 col-md-6">
                      <div className="pricing_col">
                        {/* PRicing Column Head */}
                        <div className="head">
                          <div class="best-plan op-0">Best Value</div>
                          <h3>Pro</h3>
                          <h2>
                            {planType === "individual" ? (
                              <>{planPeriodType === "monthly" ? "$15" : "$156"}</>
                            ) : null}

                            {planType === "team" ? (
                              <>{planPeriodType === "monthly" ? "$12/user" : "$10/user"}</>
                            ) : null}

                            <span>
                              /{planPeriodType === "monthly" ? "Month" : "Year"}
                            </span>
                          </h2>
                          <button className="btn btn-outline" onClick={this.onBuyPlan}>Buy Now</button>
                        </div>
                        {/* PRicing Column Body */}
                        <div className="body">
                          <ul>
                            <li class="feature"><span class="full"><b>Features</b></span></li>
                            {planType === "individual" ? "" : <li class="feature mt-2"><span class="full"><b>6+ members</b></span></li>}
                            <li><span class="full">Connect 6 calendars/user</span></li>
                            <li><span class="full">Unlimited Events</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Calendar integrations with Google Calendar, Office 365, Outlook and iCloud</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Personalize MeetOcto link</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Removable MeetOcto Branding</span></li>
                            <li><img src={falseIcon} className="plan-tick-icons" alt="check icon" /> <span>Pooled availability options for teams <small>(round robin, even rotation, allow attendee to select staff)</small></span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Group Events</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Automated event notifications</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Customize notifications and reminders</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Redirect to Custom Pages</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Collect Payments <small>(PayPal, Stripe)</small></span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Coupon Codes</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Enable Recurring Booking Discounts</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Upsell/ Cross Sell</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>OctoLink Customization (colors, images upload)</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Metrics and Reporting</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Add OctoLink to your website</span></li>
                            <li><span class="full"><b>Integrations</b></span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Google Meet</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Zoom</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Microsoft Teams</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Go to Webinar</span></li>
                            <li><img src={trueIcon} className="plan-tick-icons" alt="check icon" /> <span>Zapier</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table_flow_wrapper">
                  <table className="pricing-table">
                    <tr>
                      <th className="names">
                        <div className="chose-plan">
                          <h1>Choose Your Plane</h1>
                          <div className="btns">
                            <button
                              className={`btn p-3 annully ${planPeriodType === "monthly" ? "active" : ""
                                }`}
                              onClick={() => {
                                this.setState({
                                  planPeriodType: "monthly",
                                });
                              }}
                            >
                              Monthly
                            </button>
                            <button
                              className={`btn p-3 ml-3 annully ${planPeriodType === "annual" ? "active" : ""
                                }`}
                              onClick={() => {
                                this.setState({
                                  planPeriodType: "annual",
                                });
                              }}
                            >
                              Annually
                            </button>
                          </div>
                        </div>
                      </th>
                      <th className="border-top">
                        <div className="plan">
                          <h1>
                            {planType === "individual" ? <>Free</> : <>Starter</>}
                          </h1>

                          {planType === "individual" ? (
                            <>
                              <h2>$0</h2>
                            </>
                          ) : null}
                          {planType === "team" ? (
                            <>
                              {planPeriodType === "monthly" ? (
                                <h2>$35</h2>
                              ) : (
                                <h2>$360</h2>
                              )}
                            </>
                          ) : null}

                          <button className="btn" onClick={this.onBuyPlan}>
                            Buy Now
                          </button>
                          <h1 className="fill-h">Features</h1>
                          {planType === "individual" ? "" : <h1 className="fill-h mt-2">2-3 members</h1>}
                        </div>
                      </th>
                      <th className="border-top active-border">
                        <div className="plan active-plan">
                          <div className="active-plan-value">Best Value</div>
                          <h1>
                            {planType === "individual" ? (
                              <>Basic</>
                            ) : (
                              <>Standard</>
                            )}
                          </h1>
                          <h2>
                            {planType === "individual" ? (
                              <>{planPeriodType === "monthly" ? "$12" : "$120"}</>
                            ) : null}

                            {planType === "team" ? (
                              <>{planPeriodType === "monthly" ? "$55" : "$600"}</>
                            ) : null}

                            <span>
                              /{planPeriodType === "monthly" ? "Month" : "Year"}
                            </span>
                          </h2>
                          <button className="btn" onClick={this.onBuyPlan}>
                            Buy Now
                          </button>
                          <h1 className="fill-h">Features</h1>
                          {planType === "individual" ? "" : <h1 className="fill-h mt-2">4-5 members</h1>}
                        </div>
                      </th>
                      <th className="border-top">
                        <div className="plan">
                          <h1>Pro</h1>
                          <h2>
                            {planType === "individual" ? (
                              <>{planPeriodType === "monthly" ? "$15" : "$156"}</>
                            ) : null}

                            {planType === "team" ? (
                              <>{planPeriodType === "monthly" ? "$12" : "$120"}</>
                            ) : null}

                            <span>
                              /{planPeriodType === "monthly" ? "Month" : "Year"}
                            </span>
                          </h2>
                          <button className="btn" onClick={this.onBuyPlan}>
                            Buy Now
                          </button>
                          <h1 className="fill-h">Features</h1>
                          {planType === "individual" ? "" : <h1 className="fill-h mt-2">6+ members</h1>}
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <td className="names">Connected Calendar/s</td>
                      <td className="big-font">1 calendar/user</td>
                      <td className="active-border big-font">4 calendar/user</td>
                      <td className="big-font">6 calendar/user</td>
                    </tr>
                    <tr>
                      <td className="names">Events</td>
                      <td className="big-font">2 only</td>
                      <td className="active-border big-font">Unlimited</td>
                      <td className="big-font">Unlimited</td>
                    </tr>

                    <tr>
                      <td className="names">
                        Calendar integrations with Google Calendar, Office 365,
                        Outlook and iCloud
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Free",
                          "Calendar integrations with Google Calendar, Office 365, Outlook and iCloud"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Calendar integrations with Google Calendar, Office 365, Outlook and iCloud"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td className="active-border">
                        {this.findItem(
                          "individual",
                          "Basic",
                          "Calendar integrations with Google Calendar, Office 365, Outlook and iCloud"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Calendar integrations with Google Calendar, Office 365, Outlook and iCloud"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Pro",
                          "Calendar integrations with Google Calendar, Office 365, Outlook and iCloud"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "Calendar integrations with Google Calendar, Office 365, Outlook and iCloud"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Personalize MeetOcto link</td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Free",
                          "Personalize MeetOcto link"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Personalize MeetOcto link"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td className="active-border">
                        {this.findItem(
                          "individual",
                          "Basic",
                          "Personalize MeetOcto link"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Personalize MeetOcto link"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Pro",
                          "Personalize MeetOcto link"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "Personalize MeetOcto link"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Removable MeetOcto Branding</td>
                      <td>
                        {/* {this.findItem(
                          "individual",
                          "Free",
                          "Removable MeetOcto Branding"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Removable MeetOcto Branding"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td className="active-border">
                        {this.findItem(
                          "individual",
                          "Basic",
                          "Removable MeetOcto Branding"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Removable MeetOcto Branding"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Pro",
                          "Removable MeetOcto Branding"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "Removable MeetOcto Branding"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">
                        Pooled availability options for teams
                        <span>
                          (round robin, even rotation, allow attendee to select
                          staff)
                        </span>
                      </td>
                      <td>
                        {/* {this.findItem(
                          "individual",
                          "Free",
                          "Pooled availability options for teams (round robin, even rotation, allow attendee to select staff)"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Pooled availability options for teams (round robin, even rotation, allow attendee to select staff)"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td className="active-border">
                        {/* {this.findItem(
                          "individual",
                          "Basic",
                          "Pooled availability options for teams (round robin, even rotation, allow attendee to select staff)"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Pooled availability options for teams (round robin, even rotation, allow attendee to select staff)"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td>
                        {/* {this.findItem(
                          "individual",
                          "Pro",
                          "Pooled availability options for teams (round robin, even rotation, allow attendee to select staff)"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "Pooled availability options for teams (round robin, even rotation, allow attendee to select staff)"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Group Events</td>
                      <td>
                        {/* {this.findItem("individual", "Free", "Group Events") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Free", "Group Events") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td className="active-border">
                        {this.findItem("individual", "Basic", "Group Events") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Group Events"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td>
                        {this.findItem("individual", "Pro", "Group Events") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Pro", "Group Events") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Automated event notifications</td>
                      <td>
                        {/* {this.findItem(
                          "individual",
                          "Free",
                          "Automated event notifications"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Automated event notifications"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td className="active-border">
                        {/* {this.findItem(
                          "individual",
                          "Basic",
                          "Automated event notifications"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Automated event notifications"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Pro",
                          "Automated event notifications"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "Automated event notifications"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">
                        Customize notifications and reminders
                        <span>(SMS and email)</span>
                      </td>
                      <td>
                        {/* {this.findItem(
                          "individual",
                          "Free",
                          "Customize notifications and reminders (SMS and email)"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Customize notifications and reminders (SMS and email)"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td className="active-border">
                        {/* {this.findItem(
                          "individual",
                          "Basic",
                          "Customize notifications and reminders (SMS and email)"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Customize notifications and reminders (SMS and email)"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Pro",
                          "Customize notifications and reminders (SMS and email)"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "Customize notifications and reminders (SMS and email)"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Redirect to Custom Pages</td>
                      <td>
                        {/* {this.findItem(
                          "individual",
                          "Free",
                          "Redirect to Custom Pages"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Redirect to Custom Pages"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td className="active-border">
                        {this.findItem(
                          "individual",
                          "Basic",
                          "Redirect to Custom Pages"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Redirect to Custom Pages"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Pro",
                          "Redirect to Custom Pages"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "Redirect to Custom Pages"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">
                        Collect Payments <span>(PayPal, Stripe)</span>
                      </td>
                      <td>
                        {/* {this.findItem(
                          "individual",
                          "Free",
                          "Collect Payments (PayPal, Stripe)"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Collect Payments (PayPal, Stripe)"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td className="active-border">
                        {/* {this.findItem(
                          "individual",
                          "Basic",
                          "Collect Payments (PayPal, Stripe)"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Collect Payments (PayPal, Stripe)"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Pro",
                          "Collect Payments (PayPal, Stripe)"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "Collect Payments (PayPal, Stripe)"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Coupon Codes</td>
                      <td>
                        {/* {this.findItem("individual", "Free", "Coupon Codes") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Free", "Coupon Codes") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td className="active-border">
                        {/* {this.findItem("individual", "Basic", "Coupon Codes") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Coupon Codes"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td>
                        {this.findItem("individual", "Pro", "Coupon Codes") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Pro", "Coupon Codes") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">
                        Enable Recurring Booking Discounts
                      </td>
                      <td>
                        {/* {this.findItem(
                          "individual",
                          "Free",
                          "Enable Recurring Booking Discounts"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Enable Recurring Booking Discounts"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td className="active-border">
                        {/* {this.findItem(
                          "individual",
                          "Basic",
                          "Enable Recurring Booking Discounts"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Enable Recurring Booking Discounts"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Pro",
                          "Enable Recurring Booking Discounts"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "Enable Recurring Booking Discounts"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Upsell/ Cross Sell</td>
                      <td>
                        {/* {this.findItem(
                          "individual",
                          "Free",
                          "Upsell/ Cross Sell"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Upsell/ Cross Sell"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td className="active-border">
                        {/* {this.findItem(
                          "individual",
                          "Basic",
                          "Upsell/ Cross Sell"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Upsell/ Cross Sell"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Pro",
                          "Upsell/ Cross Sell"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "Upsell/ Cross Sell"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">
                        OctoLink Customization (colors, images upload)
                      </td>
                      <td>
                        {/* {this.findItem(
                          "individual",
                          "Free",
                          "OctoLink Customization (colors, images upload)"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "OctoLink Customization (colors, images upload)"
                        ) && <img src={falseIcon} className="plan-tick-icons" />} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td className="active-border">
                        {this.findItem(
                          "individual",
                          "Basic",
                          "OctoLink Customization (colors, images upload)"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "OctoLink Customization (colors, images upload)"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Pro",
                          "OctoLink Customization (colors, images upload)"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "OctoLink Customization (colors, images upload)"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Metrics and Reporting</td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Free",
                          "Metrics and Reporting"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Metrics and Reporting"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td className="active-border">
                        {this.findItem(
                          "individual",
                          "Basic",
                          "Metrics and Reporting"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Metrics and Reporting"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Pro",
                          "Metrics and Reporting"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "Metrics and Reporting"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Add OctoLink to your website</td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Free",
                          "Add OctoLink to your website"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Add OctoLink to your website"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td className="active-border">
                        {this.findItem(
                          "individual",
                          "Basic",
                          "Add OctoLink to your website"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Add OctoLink to your website"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Pro",
                          "Add OctoLink to your website"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "Add OctoLink to your website"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names"></td>
                      <td>
                        <h1>Integrations</h1>
                      </td>
                      <td className="active-border">
                        <h1>Integrations</h1>
                      </td>
                      <td>
                        <h1>Integrations</h1>
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Google Meet</td>
                      <td>
                        {this.findItem("individual", "Free", "Google Meet") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Free", "Google Meet") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )}
                      </td>
                      <td className="active-border">
                        {this.findItem("individual", "Basic", "Google Meet") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Basic", "Google Meet") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )}
                      </td>
                      <td>
                        {this.findItem("individual", "Pro", "Google Meet") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Pro", "Google Meet") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Zoom</td>
                      <td>
                        {this.findItem("individual", "Free", "Zoom") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Free", "Zoom") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )}
                      </td>
                      <td className="active-border">
                        {this.findItem("individual", "Basic", "Zoom") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Basic", "Zoom") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )}
                      </td>
                      <td>
                        {this.findItem("individual", "Pro", "Zoom") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Pro", "Zoom") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Microsoft Teams</td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Free",
                          "Microsoft Teams"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Microsoft Teams"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td className="active-border">
                        {this.findItem(
                          "individual",
                          "Basic",
                          "Microsoft Teams"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Microsoft Teams"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td>
                        {this.findItem(
                          "individual",
                          "Pro",
                          "Microsoft Teams"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Pro",
                          "Microsoft Teams"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Go to Webinar</td>
                      <td>
                        {this.findItem("individual", "Free", "Go to Webinar") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem(
                          "individual",
                          "Free",
                          "Go to Webinar"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td className="active-border">
                        {this.findItem(
                          "individual",
                          "Basic",
                          "Go to Webinar"
                        ) && <img src={trueIcon} className="plan-tick-icons" />}
                        {!this.findItem(
                          "individual",
                          "Basic",
                          "Go to Webinar"
                        ) && <img src={falseIcon} className="plan-tick-icons" />}
                      </td>
                      <td>
                        {this.findItem("individual", "Pro", "Go to Webinar") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Pro", "Go to Webinar") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="names">Zapier</td>
                      <td>
                        {/* {this.findItem("individual", "Free", "Zapier") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Free", "Zapier") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )} */}
                        {planType === "individual" ? <img src={falseIcon} className="plan-tick-icons" alt="Tick Icon" /> : <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />}
                      </td>
                      <td className="active-border">
                        {/* {this.findItem("individual", "Basic", "Zapier") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Basic", "Zapier") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )} */}
                        <img src={trueIcon} className="plan-tick-icons" alt="Cross Icon" />
                      </td>
                      <td>
                        {this.findItem("individual", "Pro", "Zapier") && (
                          <img src={trueIcon} className="plan-tick-icons" />
                        )}
                        {!this.findItem("individual", "Pro", "Zapier") && (
                          <img src={falseIcon} className="plan-tick-icons" />
                        )}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="names"></td>
                      <td className="border-bottom">
                        <button
                          className="btn"
                          type="button"
                          onClick={this.onBuyPlan}
                        >
                          Buy
                        </button>
                      </td>
                      <td className="border-bottom active-border">
                        <button
                          className="btn"
                          type="button"
                          onClick={this.onBuyPlan}
                        >
                          Buy
                        </button>
                      </td>
                      <td className="border-bottom">
                        <button
                          className="btn"
                          type="button"
                          onClick={this.onBuyPlan}
                        >
                          Buy
                        </button>
                      </td>
                    </tr> */}
                  </table>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default PlanDetailModal;