import axios from "../../axios-instance";
import { fallBackErrorMessage } from "../../constants/event";
import { getAdminBusinessId, getLoggedInUserId } from "../../utils/authHelper";

/**
 * get vacations
 * @param {*} options
 * @returns
 */
export const getVacations = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post("/admin/vacations/" + getAdminBusinessId(), data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * delete vacation
 * @param {*} options
 * @returns
 */
export const deleteVacation = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post("admin/vacation/delete", data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * save vacation use for both create/update, if id then it will update record otherwise will create
 * @param {*} options
 */
export const saveVacation = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  data.business_id = getAdminBusinessId();
  data.provider_id = getLoggedInUserId();
  axios
    .post("admin/vacation/save", data)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};
