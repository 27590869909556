import React, { Component, Fragment } from "react";
import {
  Modal,
  ModalBody,
  Row,
  Col,
} from "reactstrap";
import inlineEmbedded from "../../../../assets/images/inline-embeded.png";
import popupText from "../../../../assets/images/popup-text.png";
import popupWidget from "../../../../assets/images/popup-widget.png";
import classnames from "classnames";
import ShareEventTypeDetailModal from "./ShareEventTypeDetailModal";
import AddTimeToEmailDetailModal from "./AddTimeToEmailDetailModal";
import Calendar from "react-calendar";
import { notification, getLoggedInUserTimezone } from "../../../../utils/utility";
import { connect } from "react-redux";
import { getAvailableTimeSlots, getAvailableDates } from "../../../../actions/bookingAction";
import { getAdminBusinessId } from "../../../../utils/authHelper";
import Loader from "../../../../components/Loader/Loader";
import Tooltip from "../../../../components/Tooltip";
import moment from "moment";
import * as Icon from "react-feather";

class ShareEventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      shareEventType: 1,
      pageNumber: 1,
      calendarDate: new Date(),
      slots: [],
      selectedSlots: {},
      selectedDate: null,
      availableDates: [],
      isLoading: false
    };
  }

  /**
   * set page on modal
   * @param {*} pageNumber 
   */
  setPage = (pageNumber) => {
    this.setState({
      pageNumber
    });
  }

  toggle = () => {
    const { isOpen } = this.state;
    if (!isOpen) {
      this.getMonthAvailableDates(moment().month() + 1, moment().year());
    }
    this.setState({
      isOpen: !isOpen,
    });
  };

  /**
   * 
   * @param {*} type 
   */
  onChangeShareEventType = (type) => {
    this.setState({
      shareEventType: type,
    });
  };

  /**
   * on select time slot
   * @param {*} slot
   */
  onSelectTimeSlot = (slot) => {
    const _this = this;
    const { selectedDate } = _this.state;
    const selectedSlots = { ..._this.state.selectedSlots };
    if (selectedSlots[selectedDate] !== undefined) {
      const selectedDateSlots = [...selectedSlots[selectedDate]];
      const found = selectedDateSlots.some((el) => el === slot);
      if (found) {
        const processedSlots = selectedDateSlots.filter((el) => {
          return el !== slot;
        });
        selectedSlots[selectedDate] = processedSlots;
        _this.setState({
          selectedSlots: selectedSlots
        });
      } else {
        selectedDateSlots.push(slot);
        selectedSlots[selectedDate] = selectedDateSlots;
        _this.setState({
          selectedSlots: selectedSlots
        });
      }
    } else {
      selectedSlots[selectedDate] = [slot];
      _this.setState({
        selectedSlots: selectedSlots
      });
    }

    console.log('selectedSlots', selectedSlots);
  }


  /**
   * Get dates of month
   * @param int month
   * @param int year
   * @returns array
   */
  getMonthAvailableDates = (month, year) => {
    const _this = this;
    const { getAvailableDates } = _this.props;

    _this.setState({ isLoading: true });

    console.log("month", month, "year", year);
    let daysInMonth = moment(year + "-" + month, "YYYY-M").daysInMonth();
    console.log("days in month, ", daysInMonth);
    let monthDates = [];
    while (daysInMonth) {
      monthDates.push(
        moment(year + "-" + month + "-" + daysInMonth, "YYYY-M-D").format(
          "YYYY-M-D"
        )
      );
      daysInMonth--;
    }

    getAvailableDates({
      data: {
        dates: monthDates.reverse(),
        event_id: _this.props.selectedEvent.id,
        timezone: getLoggedInUserTimezone(),
        business_id: getAdminBusinessId(),
      },
      onSuccess: function (result) {
        const { data } = result.data;
        _this.setState({
          availableDates: data,
          isLoading: false,
        });
      },
      onError: function (error) {
        _this.setState({
          isLoading: false,
        });
      },
    });
  };

  /**
   * on go to previous month button handler
   * @param {*} event
   */
  onGotoPrevMonth = (event) => {
    const _this = this;
    event.preventDefault();
    const { calendarDate } = this.state;
    const dated = moment(calendarDate).subtract(1, "M");
    //console.log("onGotoPrevMonth dated", dated.toDate());
    _this.setState({
      calendarDate: dated.toDate(),
    });
    this.getMonthAvailableDates(dated.format("M"), dated.format("YYYY"));
  };


  /**
   * on go to next month button handler
   */
  onGotoNextMonth = (event) => {
    event.preventDefault();
    const { calendarDate } = this.state;
    const dated = moment(calendarDate).add(1, "M");
    this.setState({
      calendarDate: dated.toDate(),
    });
    this.getMonthAvailableDates(dated.format("M"), dated.format("YYYY"));
  };


  /**
   * check if slot is select or not
   * @param {*} slot
   * @returns Boolean 
   */
  isSelectedSlot = (slot) => {
    const { selectedDate, selectedSlots } = this.state;
    if (selectedSlots[selectedDate] !== undefined) {
      const selectedDateSlots = selectedSlots[selectedDate];
      return selectedDateSlots.some((el) => el === slot);
    }
    return false;
  }


  /**
   * on calendar date change
   * @param {*} date 
   */
  onCalendarDateChange = (date) => {
    const _this = this;
    const { getAvailableTimeSlots } = this.props;
    this.setState({
      calendarDate: date,
      selectedDate: moment(date).format("yyyy-MM-DD")
    });



    console.log('dated', date);

    console.log('moment dated', moment(date).format("yyyy-MM-DD"));

    getAvailableTimeSlots({
      data: {
        selected_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        staff_id: 1,
        business_id: getAdminBusinessId(),
        event_id: _this.props.selectedEvent.id,
      },
      onSuccess: function (response) {
        console.log("response", response);
        _this.setState({
          slots: response,
        });
      },
      onError: (error) => {
        notification.error(error.response.data.message);
      },
    });
  };

  render() {
    const _this = this;
    const { selectedEvent } = this.props;
    const { venue } = selectedEvent;
    const {
      isOpen,
      shareEventType,
      pageNumber,
      calendarDate,
      slots,
      selectedSlots,
      isLoading,
      availableDates
    } = this.state;
    return (
      <Fragment>
        <Loader isShowLoader={isLoading} />
        <button className="rd_sharebuttontheven" onClick={this.toggle}></button>
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className="event-modal modal-lg modal-dialog"
        >
          {/* <ModalHeader toggle={this.toggle}>Add Question</ModalHeader> */}
          <ModalBody>
            <div className="">
              <div className="eventdetailsaddbox rd_modalvieew rd_modalvieewdashboard">
                <div className="box_content rd_modalboxcontent">
                  <div className="rd_dashboardmodaltopt">
                    <div>
                      <p>{selectedEvent.event_title}</p>
                      <p>
                        <span className="rdmodaldashicontim"></span>
                        {selectedEvent.timeslot !== 'Customized' ? `${selectedEvent.timeslot} mins` : selectedEvent.timeslot}
                      </p>
                    </div>
                    <div className="rd_alignright">
                      <p>
                        <span className="rd_zoomicon"></span>
                        {venue}
                      </p>
                      <p>{selectedEvent.event_format_id === 1 ? 'One to One' : 'Group'}</p>
                    </div>
                  </div>
                  <div className="rd_dashboardmodalmainco">
                    <div className="rd_maxwdashsha">
                      <div className="rd_dashtabstop">
                        <button
                          className={classnames({
                            active: pageNumber === 1
                          })}
                          onClick={() => this.setPage(1)}>
                          Add to website
                        </button>
                        <button
                          className={classnames({
                            active: pageNumber === 2
                          })}
                          onClick={() => this.setPage(2)}>
                          Add times to Email
                        </button>
                      </div>
                    </div>

                    {pageNumber === 2 && (
                      <div className="rd_contenttabsthingmodalcenter">
                        <Row>
                          <Col md="8" lg="8">
                            <div className="cale_container position-relative">
                              {availableDates.length === 0 && (
                                <div className="nodate_avail">
                                  <button
                                    className="btn p-0 arrow prev"
                                    onClick={this.onGotoPrevMonth}
                                  >
                                    <Icon.ChevronLeft size={20} />
                                  </button>
                                  <div className="txt">
                                    <p>No Dates Available</p>
                                    <button
                                      className="btn p-0"
                                      onClick={this.onGotoNextMonth}
                                    >
                                      Go to next Month
                                    </button>
                                  </div>
                                  <button
                                    className="btn p-0 arrow next"
                                    onClick={this.onGotoNextMonth}
                                  >
                                    <Icon.ChevronRight size={20} />
                                  </button>
                                </div>
                              )}
                              <Calendar
                                tileClassName={({ date }) => {
                                  const calendarDate =
                                    date.getFullYear() +
                                    "-" +
                                    (date.getMonth() + 1) +
                                    "-" +
                                    date.getDate();

                                  if (
                                    availableDates.find(
                                      (availableDate) => availableDate === calendarDate
                                    )
                                  ) {
                                    return "highlight";
                                  }
                                }}
                                minDate={new Date()}
                                onChange={this.onCalendarDateChange}
                                activeStartDate={calendarDate}
                                className="cus_style"
                                onClickMonth={(value) => {
                                  const _this = this;
                                  const dated = moment(value);
                                  const month = dated.format("M");
                                  const year = dated.format("YYYY");
                                  _this.setState({
                                    calendarDate: dated.toDate(),
                                  });
                                  _this.getMonthAvailableDates(month, year);
                                }}
                                onActiveStartDateChange={({ action }) => {
                                  const _this = this;
                                  const { calendarDate } = _this.state;
                                  let dated = null;
                                  if (action === "next" || action === "prev") {
                                    // next month or previous month
                                    dated =
                                      action === "next"
                                        ? moment(calendarDate).add(1, "M")
                                        : moment(calendarDate).subtract(1, "M");
                                  } else if (action === "next2" || action === "prev2") {
                                    // next year or previou year
                                    dated =
                                      action === "next"
                                        ? moment(calendarDate).add(1, "y")
                                        : moment(calendarDate).subtract(1, "y");
                                  }
                                  console.log("action", action);
                                  if (["next", "prev", "next2", "prev2"].includes(action)) {
                                    _this.setState({
                                      calendarDate: dated.toDate(),
                                    });
                                    _this.getMonthAvailableDates(
                                      dated.format("M"),
                                      dated.format("YYYY")
                                    );
                                  }
                                }}
                                locale="en-GB"
                              />
                            </div>
                          </Col>
                          <Col md="4" lg="4">

                            {(slots || []).length > 0 ? (
                              <>
                                <div className="col-12 px-0">
                                  <div className="add-time-slot-container addTimes">
                                    {(slots || []).map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className={`slot ${this.isSelectedSlot(item) ? "time_active" : null
                                            }`}
                                          onClick={() => this.onSelectTimeSlot(item)}
                                        >
                                          {item}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>

                              </>
                            ) : (
                              <div className="col-12 px-0">
                                <div className="row">
                                  <p className="text-left">No Slot Available.</p>
                                </div>
                              </div>
                            )}
                          </Col>
                        </Row>

                      </div>
                    )}

                    {pageNumber === 1 && (
                      <div className="rd_contenttabsthingmodalcenter">
                        <p>How do you want to add events to your site?</p>

                        <div className="rd_flexwebsiterdcont">
                          <div
                            className={classnames("rd_flexwebsiterdcontitem", {
                              active: shareEventType === 1,
                            })}
                            onClick={() => this.onChangeShareEventType(1)}
                          >
                            <div>
                              <img src={inlineEmbedded} alt="" />
                              <h5>
                                <strong>Embed inline <Tooltip code="P25F1" /></strong>
                              </h5>
                              <p>Add a scheduling page to your site</p>
                            </div>
                          </div>
                          <div
                            className={classnames("rd_flexwebsiterdcontitem", {
                              active: shareEventType === 2,
                            })}
                            onClick={() => this.onChangeShareEventType(2)}
                          >
                            <div>
                              <img src={popupText} alt="" />
                              <h5>
                                <strong>Text popup <Tooltip code="P25F2" /></strong>
                              </h5>
                              <p>Add a text link that opens a popup</p>
                            </div>
                          </div>
                          <div
                            className={classnames("rd_flexwebsiterdcontitem", {
                              active: shareEventType === 3,
                            })}
                            onClick={() => this.onChangeShareEventType(3)}
                          >
                            <div>
                              <img src={popupWidget} alt="" />
                              <h5>
                                <strong>Widget popup <Tooltip code="P25F3" /> </strong>
                              </h5>
                              <p>Add a floating button that opens a popup</p>
                            </div>
                          </div>
                        </div>

                        <a href="">See how members use events on their sites</a>
                      </div>
                    )}

                  </div>

                  <div className="rd_dashboardmodalfooterpart">
                    <div>
                      <button
                        className="rd_dashbackbutton"
                        onClick={this.toggle}
                      >
                        Back
                      </button>
                    </div>
                    <div className="rd_dashboardmodalfooterpartb">
                      <button onClick={this.toggle}>Cancel</button>
                      {pageNumber === 1 && (
                        <ShareEventTypeDetailModal
                          event={selectedEvent}
                          shareEventType={shareEventType}
                        />
                      )}

                      {pageNumber === 2 && (
                        <AddTimeToEmailDetailModal
                          slots={selectedSlots}
                          event={selectedEvent}
                        />
                      )}


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(null, {
  getAvailableTimeSlots,
  getAvailableDates
})(ShareEventModal);
