import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";

class DeleteEventModal extends Component {
  
  render() {
    const { onDelete, eventId, handleClose, showDeleteEventModal  } = this.props;

    return (
      <>
        <Modal isOpen={showDeleteEventModal} toggle={handleClose}>
          <ModalBody>
            <div className="">
              <div className="rd_modalvieew">
                <div className="box_content rd_modalboxcontent">
                  <div className="rd_inputselectheadermodalsetting">
                    <button
                      className="rd_colsebtn"
                      onClick={handleClose}
                    ></button>
                  </div>

                  <h5 className="rd_modalheadertext rd_modalheadertextnm">
                    Confirm
                  </h5>

                  <p className="rd_modalheadertextparath mt-5 mb-5">
                    Are you sure, you want to delete event ?
                  </p>

                  <div className="rd_cancelappbtnmodal">
                    <button className="rd_cancel" onClick={handleClose}>
                      Cancel
                    </button>
                    <button
                      className="btn btn-app"
                      onClick={() => {
                        onDelete(eventId);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default DeleteEventModal;
