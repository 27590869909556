import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import * as Icon from "react-feather";
import useMediaQuery from "../../../../hooks/useMediaQuery";

const EventAvailabilityTabs = ({ activeTab, toggle }) => {
  const isTablet = useMediaQuery("(min-width: 768px)");
  return (
    <Nav tabs>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === "1" })}
          onClick={() => {
            toggle("1");
          }}
        >
          <span className="icon">
            <Icon.List size="20" />
          </span>
          List View
        </NavLink>
      </NavItem>
      {isTablet && (
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            <span className="icon">
              <Icon.Calendar size="20" />
            </span>
            Calendar View
          </NavLink>
        </NavItem>
      )}
    </Nav>
  );
};

export default EventAvailabilityTabs;
