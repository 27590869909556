import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getPromos,
  updatePromo,
  deletePromo,
} from "../../../actions/super-admin/promo-actions";
import { Table } from "antd";
import {
  CardBody,
  Card,
  Input,
  Button,
  Col,
  CardHeader,
  Row,
  CardTitle,
  FormGroup,
  Label,
  Collapse,
} from "reactstrap";
import Loader from "../../../components/Loader/Loader";
import isEmpty from "../../../validation/is-empty";
import ConfirmDeleteModal from "./Components/ConfirmDeleteModal";
import classnames from "classnames";
import { ChevronDown, X } from "react-feather";
import EditPromoModal from "./edit";
import "antd/dist/antd.css";
import DatePicker from "react-date-picker";
import { notification, getDBFormatedDate } from "../../../utils/utility";

const PromoListing = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [couponExpiry, setCouponExpiry] = useState(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: false,
  });

  const [filter, setFilter] = useState({});

  const [data, setData] = useState([]);
  const [collapse, setCollapse] = useState(false);
  const [status, setStatus] = useState("");
  const [isVisible, setIsVisible] = useState(true);

  const columns = [
    {
      title: "Coupon Code",
      dataIndex: "coupon_code",
      sorter: true,
    },
    {
      title: "Payout Type",
      dataIndex: "coupon_payout_type",
      render: (coupon_payout_type) => {
        return coupon_payout_type === "F" ? "Flat" : "Percent";
      },
      sorter: true,
    },
    {
      title: "Value",
      dataIndex: "coupon_value",
      sorter: true,
    },
    {
      title: "Limit",
      dataIndex: "coupon_limit",
      sorter: true,
    },
    {
      title: "Used",
      dataIndex: "coupon_used",
      render: (coupon_used) => {
        return isEmpty(coupon_used) ? "0" : coupon_used;
      },
      sorter: false,
    },
    {
      title: "Expiry",
      dataIndex: "coupon_expiry",
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, data) => {
        return (
          <div className="actions cursor-pointer">
            <EditPromoModal onUpdateRecord={onUpdateRecord} data={data} />
            <ConfirmDeleteModal onDeleteRecord={onDeleteRecord} data={data} />
          </div>
        );
      },
    },
  ];

  /**
   * collape/expand filter section
   */
  const onToggleCollapseFilter = () => {
    setCollapse(!collapse);
  };

  /**
   * remove filter section
   */
  const onRemoveFilterContainer = () => {
    setIsVisible(false);
  };

  /**
   * on Enterieng filter container
   */
  const onEntering = () => {
    setStatus("Opening...");
  };

  /**
   * on Entered filter container
   */
  const onEntered = () => {
    setStatus("Opened");
  };

  /**
   * on Exiting filter container
   */
  const onExiting = () => {
    setStatus("Closing...");
  };

  /**
   * on Exited filter container
   */
  const onExited = () => {
    setStatus("Closed");
  };

  /**
   * on delete record
   * @param {*} id
   */
  const onDeleteRecord = (id) => {
    const { deletePromo } = props;
    setIsDataLoading(true);
    deletePromo({
      data: {
        promoId: id,
      },
      onSuccess: function (data) {
        notification.success("Record successfully deleted");
        handleTableChange(
          {
            current: 1,
            pageSize: 5,
          },
          {},
          {}
        );
        setIsDataLoading(false);
      },
      onError: function (error) {
        notification.error("An error occured during delete, Please try later");
        console.log("error", error);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on update record
   * @param {*} data
   */
  const onUpdateRecord = (data) => {
    const { updatePromo } = props;
    setIsPageLoading(true);
    setIsDataLoading(true);
    updatePromo({
      data: {
        ...data,
      },
      onSuccess: (response) => {
        const { success } = response;
        if (success) {
          notification.success("Promo successfully updated");
        } else {
          notification.error(
            "An error occured during update, please try later."
          );
        }
        handleTableChange(
          {
            ...pagination,
          },
          {
            ...filter,
          },
          {}
        );
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
      onError: (error) => {
        console.log("error", error);
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on filter data table
   * @param {*} name
   * @param {*} value
   */
  const onFilterDataTable = (name, value) => {
    const searchFilters = { ...filter };
    if (isEmpty(value)) {
      delete searchFilters[name];
    } else {
      searchFilters[name] = value;
    }
    setFilter(searchFilters);

    handleTableChange(
      {
        ...pagination,
      },
      {
        ...searchFilters,
      },
      {}
    );
  };

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  const prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    console.log("params", params.filters);

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * fetch data table data
   * @param {*} params
   */
  const fetchTableData = (params = {}) => {
    const { getPromos } = props;
    setIsDataLoading(true);
    getPromos({
      data: prepareDataParam(params),
      onSuccess: function (data) {
        setData(data.entity);
        setPagination({
          ...params.pagination,
          total: data.totalCount,
        });
        setIsDataLoading(false);
      },
      onError: function (error) {
        setData([]);
        console.log("error", error);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on page size drop down change
   * @param {*} e  event
   */
  const onPageSizeChange = (e) => {
    handleTableChange(
      {
        //current: pagination.current,
        current: 1,
        pageSize: e.target.value,
        total: pagination.total,
      },
      {},
      {}
    );
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  const handleTableChange = (pagination, filters, sorter) => {
    console.log("pagination", pagination);
    console.log("filter", filters);
    console.log("sorter", sorter);

    fetchTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  useEffect(() => {
    fetchTableData({ pagination });
  }, []);

  return (
    <>
      <Row className="app-user-list">
        <Loader isShowLoader={isPageLoading} />
        <Col sm="12">
          <Card
            className={classnames("card-action card-reload", {
              "d-none": isVisible === false,
              "card-collapsed": status === "Closed",
              closing: status === "Closing...",
              opening: status === "Opening...",
              //refreshing: this.state.reload,
            })}
          >
            <CardHeader>
              <CardTitle>Filters</CardTitle>
              <div className="actions">
                <ChevronDown
                  className="collapse-icon mr-50"
                  size={15}
                  onClick={onToggleCollapseFilter}
                />
                <X size={15} onClick={onRemoveFilterContainer} />
              </div>
            </CardHeader>
            <Collapse
              isOpen={collapse}
              onExited={onExited}
              onEntered={onEntered}
              onExiting={onExiting}
              onEntering={onEntering}
            >
              <CardBody>
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <FormGroup className="mb-0">
                      <Label for="coupon-code">Coupon code</Label>
                      <Input
                        id="coupon-code"
                        placeholder="Coupon code #..."
                        onChange={(e) => {
                          onFilterDataTable("coupon_code", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <FormGroup className="mb-0">
                      <Label for="coupon-payout-type">Payout Type</Label>
                      <Input
                        type="select"
                        name="select"
                        id="coupon-payout-type"
                        onChange={(e) => {
                          onFilterDataTable(
                            "coupon_payout_type",
                            e.target.value
                          );
                        }}
                      >
                        <option value="">Select Payout Type</option>
                        <option value="P">Percentage</option>
                        <option value="F">Flat</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <FormGroup className="mb-0">
                      <Label for="coupon-limit">Limit</Label>
                      <Input
                        id="coupon-limit"
                        placeholder="Limit #..."
                        onChange={(e) => {
                          onFilterDataTable("coupon_limit", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <FormGroup className="mb-0">
                      <Label for="department">Expiry</Label>
                      <DatePicker
                        name="coupon_expiry"
                        selected={
                          (couponExpiry && new Date(couponExpiry)) || null
                        }
                        format="dd-MM-yyyy"
                        value={couponExpiry}
                        onChange={(value) => {
                          setCouponExpiry(value);
                          if (value) {
                            const expiry = getDBFormatedDate(value);
                            onFilterDataTable("coupon_expiry", expiry);
                          } else {
                            onFilterDataTable("coupon_expiry", "");
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Collapse>
          </Card>
        </Col>
        <div className="ag-grid-actions d-flex justify-content-between flex-wrap mb-3">
          <div className="sort-dropdown">
            <span className="mr-2">Show</span>
            <div className="d-inline-block">
              <select className="form-control" onChange={onPageSizeChange}>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
              </select>
            </div>

            <span className="ml-2">Entries</span>
          </div>

          <Col md="2" lg="2">
            <div className="filter-actions d-flex">
              <Button
                className="btn btn-app"
                type="button"
                onClick={() => {
                  props.onToggleTab("2");
                }}
              >
                Add New
              </Button>
            </div>
          </Col>
        </div>
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={pagination}
          loading={isDataLoading}
          onChange={handleTableChange}
        ></Table>
      </Row>
    </>
  );
};

export default connect(null, {
  getPromos,
  deletePromo,
  updatePromo,
})(PromoListing);
