import axios from "../axios-instance";
import {
  Booking_START,
  Booking_ERROR,
  IsDataSubmitedSuccessfully,
  BookingCompany_SUCCESS,
  BookingEventList_SUCCESS,
  SingleEvent_SUCCESS,
  AvalaibleSlots_SUCCESS,
} from "../constants/booking";
import { fallBackErrorMessage } from "../constants/event";
import { getUserBrowserTimezone } from "../utils/utility";

/**
 * Get company events
 * @param {*} options
 * @returns
 */
export const getCompanyEvents = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { slug } = data || {};
  axios
    .get(`/company-events/${slug}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * get booking details
 * @param {*} options
 * @returns
 */
export const getBookingByRescheduleToken = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { token } = data || {};
  axios
    .get(`/get-booking-by-reschedule-token/${token}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * get booking details
 * @param {*} options
 * @returns
 */
export const getBookingByCancelToken = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { token } = data || {};
  axios
    .get(`/get-booking-by-cancel-token/${token}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * Get available dates
 * @param {*} options
 * @returns
 */
export const getAvailableDates = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post(`/get-available-dates`, data)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * Get available time slots
 * @param {*} options
 * @returns
 */
export const getAvailableTimeSlots = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  //data.timezone = getUserBrowserTimezone();
  axios
    .post(`/available-time-slots`, data)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * Get event
 * @param {*} options
 * @returns
 */
export const getEvent = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  const { slug } = data || {};
  axios
    .get(`/admin/event/show/${slug}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * save paid event booking
 * @param {*} options
 */
export const savePaidBooking = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("booking/save", data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        //const { data } = data || {};
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * save free event booking
 * @param {*} data
 * @returns
 */
export const saveFreeBooking = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  data.timezone = getUserBrowserTimezone();
  axios
    .post("booking/save-free-booking", data)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * save Paid Event
 * @param {*} data
 * @returns
 */
/*
export const savePaidEvent2 = (options) => async (dispatch) => {
  axios
    .post("booking/save", options)
    .then((res) => {
      dispatch({
        type: PaidEvent_SUCCESS,
        payload: res.data,
      });
      //  getBookingData(1)(dispatch);
    })
    .catch((err) =>
      dispatch({
        type: PaidEvent_FAIL,
        //payload: err.response.data.data,
        PaidEvent_fail: true,
      })
    );
};
*/

/*
export const getCompanyData = (id) => (dispatch) => {
  dispatch({
    type: Booking_START,
  });
  axios
    .get("admin/settings/company/1")
    .then((res) => {
      dispatch({
        type: BookingCompany_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: Booking_ERROR,
        payload: err.response.data.data,
      })
    );
};
*/

export const getEventsList = (id) => (dispatch) => {
  dispatch({
    type: Booking_START,
  });
  axios
    .get("/admin/events/1")
    .then((res) => {
      dispatch({
        type: BookingEventList_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: Booking_ERROR,
        payload: err.response.data.data,
      })
    );
};

export const getEventByID = (id) => (dispatch) => {
  dispatch({
    type: Booking_START,
  });
  axios
    .get("/admin/event/show/" + id)
    .then((res) => {
      dispatch({
        type: SingleEvent_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: Booking_ERROR,
        payload: err.response.data.data,
      })
    );
};

export const getAvalaibleSlots = (data) => (dispatch) => {
  dispatch({
    type: Booking_START,
  });
  axios
    .post("available-time-slots", data)
    .then((res) => {
      dispatch({
        type: AvalaibleSlots_SUCCESS,
        payload: res.data,
      });
      //  getBookingData(1)(dispatch);
    })
    .catch((err) =>
      dispatch({
        type: Booking_ERROR,
        payload: err.response.data.data,
        //  IsDataSubmitedSuccessfully:false
      })
    );
};

/*
export const savePaidEvent = (data) => (dispatch) => {
  dispatch({
    type: Booking_START,
  });
  axios
    .post("booking/save", data)
    .then((res) => {
      dispatch({
        type: PaidEvent_SUCCESS,
        payload: res.data,
      });
      //  getBookingData(1)(dispatch);
    })
    .catch((err) =>
      dispatch({
        type: PaidEvent_FAIL,
        //payload: err.response.data.data,
        PaidEvent_fail: true,
      })
    );
};
*/

/*
export const saveFreeEvent = (data) => (dispatch) => {
  dispatch({
    type: Booking_START,
  });
  axios
    .post("booking/save-free-booking", data)
    .then((res) => {
      dispatch({
        type: FreeEvent_SUCCESS,
        payload: res.data,
      });
      //  getBookingData(1)(dispatch);
    })
    .catch((err) => {
      dispatch({
        type: FreeEvent_FAIL,
        FreeEvent_fail: true,
        // payload: err.response.data.data,
        //  IsDataSubmitedSuccessfully:false
      });
    });
};
*/

export const updateBookingData = (thriveData) => (dispatch) => {
  dispatch({
    type: Booking_START,
  });
  axios
    .post("admin/settings/company/save", thriveData)
    .then((res) => {
      dispatch({
        type: IsDataSubmitedSuccessfully,
        IsDataSubmitedSuccessfully: true,
      });
      //  getBookingData(1)(dispatch);
    })
    .catch((err) =>
      dispatch({
        type: Booking_ERROR,
        payload: err.response.data.data,
        //  IsDataSubmitedSuccessfully:false
      })
    );
};
