import React, { Component } from "react";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "../../../../../components/FormField/FormField";

const validation = Yup.object().shape({
  schedule_name: Yup.string().required("Schedule Name is required."),
});

let initialValues = {
  schedule_name: "",
};

class AddScheduleModal extends Component {
  render() {
    const { showAddScheduleModal, hideAddScheduleModal } = this.props;
    return (
      <>
        <Modal isOpen={showAddScheduleModal} toggle={hideAddScheduleModal}>
          <ModalBody>
            <div className="">
              <div className="rd_modalvieew">
                <div className="box_content rd_modalboxcontent">
                  <div className="rd_inputselectheadermodalsetting">
                    <button
                      className="rd_colsebtn"
                      onClick={hideAddScheduleModal}
                    ></button>
                  </div>

                  <Formik
                    validationSchema={validation}
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={(values) => {
                      this.props.hideAddScheduleModal();
                      this.props.saveNewSchedule(values.schedule_name);
                    }}
                  >
                    {(formProps) => {
                      const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        setFieldValue,
                      } = formProps;
                      return (
                        <Form>
                          <h5 className="rd_modalheadertext rd_modalheadertextnm">
                            New Schedule
                          </h5>

                          <FormGroup className="mb-4">
                            <label className="same-label" htmlFor="coupon_code">
                              Schedule Name *
                            </label>
                            <FormField
                              type="text"
                              name="schedule_name"
                              placeholder="Schedule Name"
                              value={values.schedule_name}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: { name: "schedule_name", value },
                                });
                              }}
                            />
                          </FormGroup>

                          <div className="rd_cancelappbtnmodal">
                            <button
                              className="rd_cancel"
                              onClick={hideAddScheduleModal}
                            >
                              Cancel
                            </button>
                            <button type="submit" className="btn btn-app">
                              Save
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default AddScheduleModal;
