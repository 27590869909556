import React, { Component } from "react";
import { gotProperty, getDBFormatedDate } from "../../../../utils/utility";
import { connect } from "react-redux";
import { onUpdateFilters } from "../../../../actions/filterActions";
import DatePicker from "react-date-picker";

class ReferralListFilters extends Component {
  state = {
    sentOn: null,
  };

  render() {
    const { searchFilters } = this.props.filter;

    return (
      <>
        <div className="form-group">
          <label htmlFor="invitee-name">Invitee</label>
          <input
            type="text"
            id="invitee-name"
            placeholder="Invitee ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "invite_name")
                ? searchFilters["invite_name"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("invite_name", e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="invitee-email">Invitee Email</label>
          <input
            type="text"
            id="invitee-email"
            placeholder="Invitee Email ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "invite_email")
                ? searchFilters["invite_email"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("invite_email", e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <div className="mb-3">
            <label htmlFor="send-on">Sent On</label>
            <DatePicker
              className="my-calendar-view"
              format="dd-MM-yyyy"
              value={
                gotProperty(searchFilters, "sent_on") ? this.state.sentOn : null
              }
              onChange={(val) => {
                this.setState({
                  sentOn: val,
                });
                this.props.onUpdateFilters("sent_on", getDBFormatedDate(val));
              }}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            placeholder="Email ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "email") ? searchFilters["email"] : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("email", e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input
            type="text"
            id="phone"
            placeholder="Phone ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "phone") ? searchFilters["phone"] : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("phone", e.target.value);
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  onUpdateFilters,
})(ReferralListFilters);
