import React from "react";
import { Modal, ModalBody } from "reactstrap";

const ConfirmModal = (props) => {
  const { onConfirm, onToggle, title, message, data } = props;

  return (
    <Modal isOpen={true} toggle={onToggle}>
      <ModalBody>
        <div className="">
          <div className="rd_modalvieew">
            <div className="box_content rd_modalboxcontent">
              <div className="rd_inputselectheadermodalsetting">
                <button className="rd_colsebtn" onClick={onToggle}></button>
              </div>

              <h5 className="rd_modalheadertext rd_modalheadertextnm">
                {title}
              </h5>

              <p className="rd_modalheadertextparath mt-5 mb-5">{message}</p>

              <div className="rd_cancelappbtnmodal">
                <button className="rd_cancel" onClick={onToggle}>
                  Cancel
                </button>
                <button
                  className="btn btn-app"
                  onClick={() => {
                    onToggle();
                    onConfirm(data);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmModal;
