import {
  SET_FILTER_VISIBILITY_STATUS,
  ON_CLEAR_FILTER,
  ON_UPDATE_FILTER,
} from "./types";

/**
 * Toggle filter
 * @param {*} status
 * @returns
 */
export const toggleFilter = (status) => (dispatch) => {
  dispatch({ type: SET_FILTER_VISIBILITY_STATUS, payload: status });
};

/**
 * Toggle filter
 * @param {*} status
 * @returns
 */
export const onClearFilters = () => (dispatch) => {
  dispatch({ type: ON_CLEAR_FILTER });
};

/**
 *
 * @param {*} name
 * @param {*} value
 * @returns
 */
export const onUpdateFilters = (name, value) => (dispatch) => {
  dispatch({ type: ON_UPDATE_FILTER, payload: { name, value } });
};
