import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input, FormFeedback } from "reactstrap";
import FormField from "../../components/FormField/FormField";
import One from "../../assets/bookingeventassets/assets/1.svg";
import Questions from "./Components/Questions";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import P from "../../assets/bookingeventassets/assets/p.svg";
import C from "../../assets/bookingeventassets/assets/c.svg";
import ZoomIcon from "../../assets/icons/zoom.svg";
import MeetIcon from "../../assets/icons/google-meet.svg";
import TeamsIcon from "../../assets/icons/Microsoft_Teams.svg";
import GoToIcon from "../../assets/icons/goto_icon.svg";
import PhoneCall from "../../assets/icons/phone_call.svg";
import InPersonMeet from "../../assets/icons/in_person_meet.svg";
import OtherMeet from "../../assets/icons/other_meet_icon.svg";
import LocationIcon from "../../assets/icons/location_icon.svg";
import NumberField from "../events/Components/Common/NumberField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { getYupSchemaFromMetaData } from "../../validation/booking-question-validator";
import isEmpty from "../../validation/is-empty";
import { notification } from "../../utils/utility";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";

let initialValues = {
  name: "",
  email: "",
  message_from_customer: "",
  reminder_phone_no: "",
  invitee_guests: [],
};

class BookingStep2 extends Component {
  state = {
    addOns: [],
    pricing: [],
    guest_emails: [],
  };

  validation = getYupSchemaFromMetaData(
    this.props.event.questions,
    {
      name: Yup.string().required("Name is required."),
      reminder_phone_no: Yup.string().required("Phone number is required."),
      email: Yup.string()
        .matches(
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          "Please enter valid email"
        )
        .required("Email is required"),
    },
    []
  );

  /**
   * component mounted in dom
   */
  componentDidMount() {
    const { event } = this.props;

    let pricing = [];
    event.pricing.map((item, index) => {
      pricing.push({
        ...item,
        checked: false,
        selectedQty: 0,
      });
    });

    let addOns = [];
    event.add_ons.map((item, index) => {
      addOns.push({
        ...item,
        checked: false,
        selectedQty: 0,
      });
    });

    this.setState({
      addOns,
      pricing,
    });
  }

  /**
   * on change addons checkbox
   * @param Event event
   * @param int index
   * @returns
   */
  onChangeAddons = (event, index) => {
    const addons = [...this.state.addOns];
    if (event.target.checked) {
      addons[index].selectedQty = 1;
      addons[index].checked = true;
    } else {
      addons[index].selectedQty = 0;
      addons[index].checked = false;
    }
    this.setState({
      addOns: addons,
    });
  };

  /**
   * on change pricing quantity
   * @param {*} index
   */
  onChangeAddonQty = (index, qty) => {
    const addOns = [...this.state.addOns];
    addOns[index].selectedQty = qty;
    if (addOns[index].selectedQty <= 0) {
      addOns[index].checked = false;
    }
    if (addOns[index].selectedQty > 0 && !addOns[index].checked) {
      addOns[index].checked = true;
    }
    this.setState({
      addOns,
    });
  };

  /**
   * on change pricing checkbox
   * @param Event event
   * @param int index
   * @returns
   */
  onChangePricing = (event, index) => {
    const pricing = [...this.state.pricing];
    if (event.target.checked) {
      pricing[index].selectedQty = 1;
      pricing[index].checked = true;
    } else {
      pricing[index].selectedQty = 0;
      pricing[index].checked = false;
    }
    this.setState({
      pricing,
    });
  };

  /**
   * on change pricing quantity
   * @param {*} index
   */
  onChangePricingQty = (index, qty) => {
    const pricing = [...this.state.pricing];
    pricing[index].selectedQty = qty;
    if (pricing[index].selectedQty <= 0) {
      pricing[index].checked = false;
    }
    if (pricing[index].selectedQty > 0 && !pricing[index].checked) {
      pricing[index].checked = true;
    }
    this.setState({
      pricing,
    });
  };

  render() {
    const _this = this;
    const {
      event,
      data,
      onSubmitStepTwo,
      onUpdateStep,
      onScheduleEvent,
      timezoneValue,
      specialRibbon,
    } = this.props;

    const { addOns, pricing, guest_emails } = this.state;
    // console.log(event);
    return (
      <>
        <div className="row">
          {!isEmpty(specialRibbon) && (
            <div className="col-12">
              <nav className="navbar navbar-expand-sm main_noti shadow">
                <ul className="navbar-nav">
                  <li className="nav-item noti_side">
                    <img src={One} />
                  </li>

                  <li className="nav-item left_noti ml-3 ml-md-0">
                    <span>{specialRibbon}</span>
                  </li>
                </ul>
              </nav>
            </div>
          )}
          <div className="col-12">
            <div className="booking_arrow row align-items-center mx-0">
              <div className="col-auto" onClick={() => onUpdateStep(1)}>
                <img src={ArrowLeft} alt="Arrow Left" />
              </div>
              <div className="col-auto ps-0" onClick={() => onUpdateStep(1)}>
                <p>Back</p>
              </div>
              <h1> {event.event_name} </h1>
            </div>
          </div>
        </div>
        <div className="row sub_div">
          <div className="col-sm-12 col-md-12 col-lg-12 main_add pb-2 meeting-data">
            {/* <div className="col-12"> */}
            <div className="row justify-content-between">
              <div className="col-auto mb-3 mb-md-0">
                <div className="sechulde_s text-left">
                  <span className="event_label">Venue:</span>
                </div>
                <div className="sechulde_d">
                  {event.venue === "Microsoft Teams" ? (
                    <img
                      src={TeamsIcon}
                      alt="microsoft teams icon"
                      style={{ marginRight: 10 }}
                    />
                  ) : event.venue === "Zoom" ? (
                    <img
                      src={ZoomIcon}
                      alt="Zoom icon"
                      style={{ marginRight: 10 }}
                    />
                  ) : event.venue === "Google Meet" ? (
                    <img
                      src={MeetIcon}
                      alt="Google Meet icon"
                      style={{ marginRight: 10 }}
                    />
                  ) : event.venue === "Phone Call" ? (
                    <img
                      src={PhoneCall}
                      alt="Phone Call icon"
                      style={{ marginRight: 10 }}
                    />
                  ) : event.venue === "In-Person Meeting" ? (
                    <img
                      src={InPersonMeet}
                      alt="In Person Meet icon"
                      style={{ maxWidth: 13, marginRight: 10 }}
                    />
                  ) : event.venue === "Others" ? (
                    <img
                      src={OtherMeet}
                      alt="microsoft teams icon"
                      style={{ marginRight: 10 }}
                    />
                  ) : event.venue === "Go to Meeting" ? (
                    <img
                      src={GoToIcon}
                      alt="Go To icon"
                      style={{ marginRight: 10, maxWidth: 30 }}
                    />
                  ) : (
                    <img src={P} alt="event_img" style={{ marginRight: 10 }} />
                  )}
                  {event.venue}
                </div>
              </div>
              <div className="col-auto mb-3 mb-md-0">
                <div className="sechulde_s text-left">
                  <span className="event_label">Event duration:</span>
                </div>
                <div className="sechulde_d">
                  <img src={C} style={{ marginRight: 10 }} alt="clock icon" />
                  {event.timeslot !== "Customized"
                    ? event.timeslot + " minutes"
                    : event.timeslot}
                </div>
              </div>
              <div className="col-auto mb-3 mb-md-0">
                <div className="sechulde_s text-left">
                  <span className="event_label">Event format:</span>
                </div>
                <div className="sechulde_d">
                  {event.event_format_id === 1 ? "One On One" : "Group"}
                </div>
              </div>
              <div className="col-auto mb-3 mb-md-0">
                <div className="sechulde_s text-left">
                  <span className="event_label">Time Zone:</span>
                </div>
                <div className="sechulde_d">{timezoneValue}</div>
              </div>
              <div className="col-auto">
                <div className="sechulde_s text-left">
                  <span className="event_label">Event Type:</span>
                </div>
                <div className="sechulde_d">
                  {event.is_event_paid === "E" ? (
                    <>
                      Paid <span>$ {event.event_fee}</span>
                    </>
                  ) : (
                    <>Free</>
                  )}
                </div>
              </div>
              {event.venue === "In-Person Meeting" ? (
                <>
                  <div className="col-12 mt-3">
                    <div className="sechulde_s text-left">
                      <span className="event_label">Add Venue Location:</span>
                    </div>
                    <div className="sechulde_d">
                      <img
                        src={LocationIcon}
                        alt="Location Icon"
                        style={{ maxWidth: 15, marginRight: 10 }}
                      />{" "}
                      {event.venue_location}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        {event.is_event_paid === "E" ? (
          <div className="row sub_div">
            <div className="col-12 px-0 pr-md-3">
              <div className="row">
                <div className="col-xl-6 mb-3 mb-xl-0">
                  <div className="booking_addon">
                    <h3>Price</h3>
                    <div className="items_wrapper">
                      {pricing.length > 0
                        ? pricing.map((item, index) => (
                            <>
                              <div className="item_main event-form-group">
                                <div className="form-check-box">
                                  <input
                                    type="checkbox"
                                    name={`pricing-checkbox-${item.id}`}
                                    id={`pricing-checkbox-${item.id}`}
                                    checked={
                                      item.checked ? item.checked : false
                                    }
                                    onChange={(event) => {
                                      this.onChangePricing(event, index);
                                    }}
                                  />
                                  <label
                                    htmlFor={`pricing-checkbox-${item.id}`}
                                  >
                                    <span></span>
                                    {item.item_name}
                                  </label>
                                </div>
                                <div className="right_cont">
                                  <div className="price">${item.price}</div>
                                  <p>Available: {item.qty}</p>
                                  <NumberField
                                    name="item1"
                                    defaultValue={item.selectedQty}
                                    onChange={(value) => {
                                      this.onChangePricingQty(index, value);
                                      console.log("value", value);
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="booking_addon">
                    <h3>Add on</h3>
                    <div className="items_wrapper">
                      {addOns.length > 0
                        ? addOns.map((item, index) => (
                            <>
                              <div className="item_main event-form-group">
                                <div className="form-check-box">
                                  <input
                                    type="checkbox"
                                    name={`addon-checkbox-${item.id}`}
                                    id={`addon-checkbox-${item.id}`}
                                    checked={
                                      item.checked ? item.checked : false
                                    }
                                    onChange={(event) => {
                                      this.onChangeAddons(event, index);
                                    }}
                                  />
                                  <label htmlFor={`addon-checkbox-${item.id}`}>
                                    <span></span>
                                    {item.item_name}
                                  </label>
                                </div>
                                <div className="right_cont">
                                  <div className="price">${item.price}</div>
                                  <p>Available: {item.qty}</p>
                                  <NumberField
                                    name="item1"
                                    defaultValue={item.selectedQty}
                                    onChange={(value) => {
                                      this.onChangeAddonQty(index, value);
                                      console.log("value", value);
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="row sub_div">
          <div
            className="col-12 main_add schedule-event-form"
            style={{ padding: 30 }}
          >
            <Formik
              validationSchema={_this.validation}
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(data) => {
                const _this = this;
                console.log("data", data);

                let questionAnswers = [];
                for (let key in data) {
                  if (key.startsWith("question-")) {
                    let [, type, questionId] = key.split("-");

                    if (type === "checkbox") {
                      questionAnswers.push({
                        question_id: questionId,
                        answer: data[key].join(),
                      });
                    } else {
                      questionAnswers.push({
                        question_id: questionId,
                        answer: data[key],
                      });
                    }
                  }
                }

                let selectedAddOns = [];
                let selectedPricing = [];
                if (event.is_event_paid === "E") {
                  addOns.forEach((addOn, index) => {
                    if (addOn.checked && addOn.selectedQty > 0) {
                      selectedAddOns.push({
                        event_addon_id: addOn.id,
                        addon_qty: addOn.selectedQty,
                        addon_price: addOn.price,
                      });
                    }
                  });

                  pricing.forEach((item, index) => {
                    if (item.checked && item.selectedQty > 0) {
                      selectedPricing.push({
                        event_pricing_id: item.id,
                        item_qty: item.selectedQty,
                        item_price: item.price,
                      });
                    }
                  });

                  if (selectedPricing.length === 0) {
                    return notification.warning(
                      "please select at least 1 item from pricing"
                    );
                  }
                }

                console.log("question ans", questionAnswers);
                onSubmitStepTwo(
                  data.name,
                  data.email,
                  data.message_from_customer,
                  data.reminder_phone_no,
                  _this.state.guest_emails,
                  questionAnswers,
                  selectedAddOns,
                  selectedPricing,
                  event.is_event_paid
                );

                if (event.is_event_paid === "E") {
                  onUpdateStep(3);
                } else {
                  // this code move to on submit step two funtion
                  //onScheduleEvent({});
                }
              }}
            >
              {(formProps) => {
                const {
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                } = formProps;

                return (
                  <Form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label
                            className="form-group-label"
                            htmlFor="exampleInputEmail1"
                          >
                            Name *
                          </label>

                          <FormField
                            type="text"
                            name="name"
                            placeholder="Name"
                            errors={errors}
                            touched={touched}
                            value={values.name}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "name", value },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label
                            className="form-group-label"
                            htmlFor="exampleInputEmail1"
                          >
                            Email *
                          </label>
                          <FormField
                            type="text"
                            name="email"
                            placeholder="Email"
                            autoComplete="new-off"
                            errors={errors}
                            touched={touched}
                            value={values.email}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "email", value },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <label class="form-group-label">Guest Email(s)</label>
                        <ReactMultiEmail
                          placeholder="Please enter guests Email Address"
                          emails={guest_emails}
                          onChange={(_emails) => {
                            this.setState({ guest_emails: _emails });
                          }}
                          getLabel={(email, index, removeEmail) => {
                            return (
                              <div data-tag key={index}>
                                {email}
                                <span
                                  data-tag-handle
                                  onClick={() => removeEmail(index)}
                                >
                                  ×
                                </span>
                              </div>
                            );
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label
                            className="form-group-label"
                            htmlFor="exampleFormControlTextarea1"
                          >
                            Please share anything that will help prepare for our
                            Meeting.
                          </label>
                          <Input
                            type="textarea"
                            className="form-control"
                            name="message_from_customer"
                            value={values.message_from_customer}
                            onChange={({ target }) => {
                              const { name, value } = target || {};
                              handleChange({
                                target: {
                                  name: "message_from_customer",
                                  value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className={
                          (event.venue_id === 1 || event.venue_id === "1") &&
                          event.venue_location_status === "invitee_add_location"
                            ? "col-md-6"
                            : "col-md-12"
                        }
                      >
                        <div className="form-group">
                          <label className="form-group-label" htmlFor="">
                            Send text reminder to *
                          </label>
                          <PhoneInput
                            containerClass="custom_phn_inp"
                            country={"us"}
                            value={values.reminder_phone_no}
                            onBlur={() => {
                              setFieldTouched(`reminder_phone_no`, true);
                            }}
                            onChange={(value) => {
                              handleChange({
                                target: { name: "reminder_phone_no", value },
                              });
                            }}
                          />
                          {errors[`reminder_phone_no`] &&
                            touched[`reminder_phone_no`] && (
                              <FormFeedback className="d-block">
                                {errors[`reminder_phone_no`]}
                              </FormFeedback>
                            )}
                        </div>
                      </div>
                      {(event.venue_id === 1 || event.venue_id === "1") &&
                        event.venue_location_status ===
                          "invitee_add_location" && (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-group-label" htmlFor="">
                                Add Venue Location
                              </label>
                              <FormField
                                type="text"
                                name="add_venue_location"
                                placeholder="2972 Westheimer Rd. Santa Ana, Illinois 85486"
                                errors={errors}
                                touched={touched}
                                // value={values.reminder_phone_no}
                                // onChange={({ target }) => {
                                //   const { value } = target;
                                //   handleChange({
                                //     target: { name: "reminder_phone_no", value },
                                //   });
                                // }}
                              />
                            </div>
                          </div>
                        )}
                      <Questions
                        errors={errors}
                        touched={touched}
                        setFieldTouched={setFieldTouched}
                        questions={event.questions}
                        initialValues={initialValues}
                        onFieldChange={handleChange}
                      />
                      <div className="col-12">
                        <hr
                          style={{
                            width: "100%",
                            margin: "30px 0",
                            borderColor: "#E0E0E0",
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <div className="instructions">
                          {(event.venue_id === 1 || event.venue_id === "1") &&
                            event.venue_location_status ===
                              "display_location_booking" && (
                              <div className="inst_main">
                                <h4>Venue Location</h4>
                                <p>{event.venue_location}</p>
                              </div>
                            )}
                          <div className="inst_main">
                            {!isEmpty(event.instruction_to_attendee) && (
                              <>
                                <h4>Instructions to Attendee</h4>
                                <p>{event.instruction_to_attendee}</p>
                              </>
                            )}
                          </div>
                          <div className="inst_main">
                            {!isEmpty(event.cancellation_policy) && (
                              <>
                                <h4>Cancellation Policy</h4>
                                <p>{event.cancellation_policy}</p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-between mt-4">
                      <div className="col-md-6 copy_link" id="copy_link">
                        <label
                          className="form-group-label"
                          htmlFor="exampleInputEmail1"
                        >
                          Invite
                        </label>
                        <div className="input-group mb-3 form-group">
                          <input
                            type="text"
                            className="form-control p-3"
                            value="https://meetocto.com/invitation/attende"
                            disabled
                            style={{ width: "calc(100% - 100px)" }}
                          />
                          <div
                            className="input-group-append"
                            style={{ width: 100 }}
                          >
                            <button
                              className="btn btn-outline-secondary mw-100"
                              type="button"
                            >
                              Copy Link
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6" id="s_e_btn">
                        <button type="submit" className="btn btn-app">
                          {event.is_event_paid === "E"
                            ? "Next"
                            : "Schedule Event"}
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </>
    );
  }
}

export default BookingStep2;
