import React from "react";
import errorImg from "../../assets/icons/img_404.svg";

class Error404 extends React.Component {
  render() {
    return (<>
      <section className="error_page">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-auto error_column">
              <img src={errorImg} alt='error img' className="img_404" />
              <h1>OPPS! PAGE NOT FOUND</h1>
              <p>Sorry, the page you're looking for doesn't exist.</p>
            </div>
          </div>
        </div>
      </section>
    </>);
  }
}
export default Error404;
