import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import { sendGetRequest } from '../../../actions/app-actions';
import { getAdminBusinessId } from '../../../utils/authHelper';
import { GOOGLE_CALENDAR_ID, OUTLOOK_CALENDAR_ID } from '../../../data/integration-app';
import { connect } from "react-redux";

const AddToCalendarModal = (props) => {

    const { 
        data,
        showAddToCalendarModal, 
        hideAddToCalendarModal, 
        sendGetRequest, 
        setIsLoading, 
        onUpdateAddToCalendar, 
        dataSet 
    } = props;
    const [calendars, setCalendars] = useState([]);
    const [addNewEventStatus, setAddNewEventStatus] = useState(data?.config?.add_new_event_status === 'E' ? true : false);
    const [selectedCalendar, setSelectedCalendar] = useState(data?.config?.selected_calendar_id);
    const [deleteEventStatus, setDeleteEventStatus] = useState(data?.config?.delete_event_status === 'E' ? true : false);
    const [appIntegrationId, setAppIntegrationId] = useState(data?.id);
    const [selectedConn, setSelectedConn] = useState(data?.app_id);

    useEffect(() => {
        const requestUrl = selectedConn === GOOGLE_CALENDAR_ID ? 'google-list-calendars' : 'outlook-list-calendars';
        setIsLoading(true);
        sendGetRequest({
            url: `/${requestUrl}/${data?.id}/${getAdminBusinessId()}`,
            onSuccess: function (response) {
              setCalendars(response.calendars);
              setIsLoading(false);
            },
            onError: function (error) {
                setIsLoading(false);
            },
        });    
    }, [selectedConn]);


    





    /**
     * on update add to calendar handler
     */
    const onUpdate = () => {
        const addToCalendarData = {
            id : data?.config?.id,
            add_new_event_status : addNewEventStatus ? 'E' : 'D',
            selected_calendar_id : selectedCalendar,
            delete_event_status : deleteEventStatus ? 'E' : 'D',
            app_integration_id : appIntegrationId
        }
        onUpdateAddToCalendar(addToCalendarData);
    }

    return (
        <Modal className="cal_int_popup" isOpen={showAddToCalendarModal} toggle={hideAddToCalendarModal}>
            <ModalBody>
                <h2 className="title">Which calendar should we add new events to?</h2>
                {dataSet.map((connection, index) => {
                    return (
                        <FormGroup tag="fieldset" className="event-form-group">
                            <div className="event-form-check">
                                <input 
                                    type="radio" 
                                    id={`calendar-${index}`} 
                                    name="add_new_event_status" 
                                    checked={addNewEventStatus && connection.app_id === selectedConn} 
                                    onChange={(browserEvent) => { 
                                        if(browserEvent.target.checked) { 
                                            setSelectedConn(connection.app_id);
                                            setAppIntegrationId(connection.id);
                                            setAddNewEventStatus(true); 
                                        }
                                    }}>
                                </input>
                                <label htmlFor={`calendar-${index}`}>
                                    <span></span>
                                    <img src={props.getConnectionIcon(connection?.app_id)} alt="Google Calendar Icon" />
                                    {JSON.parse(connection?.meta_data)?.email}
                                </label>
                            </div>
                        </FormGroup>
                    );
                })}
                <FormGroup tag="fieldset" className="event-form-group">
                    <div className="event-form-check">
                    <input type="radio" id="notAdd" name="add_new_event_status" checked={!addNewEventStatus} onChange={(browserEvent) => { if(browserEvent.target.checked) { setAddNewEventStatus(false) }}}></input>
                    <label htmlFor="notAdd">
                        <span></span>
                        Do not add new events to a calendar
                    </label>
                    </div>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
            {addNewEventStatus && (
                <div className="col-12 px-0">
                    <h3>Add to calendar</h3>
                    <FormGroup>
                    <Input id="exampleSelect" name="select" type="select" onChange={(event) => setSelectedCalendar(event.target.value)}>
                        {calendars.map((calendar) => {
                            return (<option selected={data?.config?.selected_calendar_id == calendar?.id} value={calendar?.id}>{calendar?.summary}</option>)
                        })}
                    </Input>
                    </FormGroup>
                </div>
            )}
            
            {addNewEventStatus && (
                <div className="col-12 px-0">
                    <h3>Sync cancellations</h3>
                    <div className="form-group event-form-group">
                        <div className="form-check-box">
                            <input id="reminder_email" type="checkbox" checked={deleteEventStatus} onChange={(browserEvent) => setDeleteEventStatus(browserEvent.target.checked)}></input>
                            <label htmlFor="reminder_email">
                            <span></span>
                            Deleting or declining an event in your calendar will also cancel it in Meetocto.
                            </label>
                        </div>
                    </div>
                </div>
            )}
            
            <div className="col-md-6 ps-md-0">
                <button className="btn btn-outline" onClick={hideAddToCalendarModal}>Cancel</button>
            </div>
            <div className="col-md-6 pe-md-0 mt-4 mt-md-0">
                <button className="btn btn-app" onClick={onUpdate}>Update</button>
            </div>
            </ModalFooter>
      </Modal>
    );
}


export default connect(null, {
    sendGetRequest,
  })(AddToCalendarModal);