import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import { withRouter } from "../../../utils/utility";
import { USER_TYPES } from "../../../routes/types/private";
import AdminNavbar from "./admin/navbar";
import SuperAdminNavbar from "./super-admin/navbar";
import StaffNavbar from "./staff/navbar";
//import CustomerNav from "./CustomerNav";
const Navbar = (props) => {
  /**
   * logout current logged in user
   * @returns void
   */
  const onLogout = () => {
    props.logoutUser();
  };

  if (localStorage.userType) {
    switch (localStorage.userType) {
      case USER_TYPES.admin:
        return <AdminNavbar userLogout={onLogout} />;
      case USER_TYPES.superAdmin:
        return <SuperAdminNavbar userLogout={onLogout} />;
      case USER_TYPES.staff:
        return <StaffNavbar userLogout={onLogout} />;
      //case USER_TYPES.customer:
      //  return <CustomerNav userLogout={onLogout} />;
      default:
        return null;
    }
  }
};

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Navbar));
