import * as yup from "yup";

/**
 * Create yup schema
 * @param {*} schema
 * @param {*} config
 * @returns
 */
export function createYupSchema(schema, question) {
  const { id, question_type, mandatory_status, show_status } = question;

  if (mandatory_status === "E" && show_status === "E") {
    if (question_type === "checkbox") {
      schema[`question-${question_type}-${id}`] = yup
        .array()
        .min(1, "This field is required");
    } else {
      /*
      let validator = yup["string"]();
      validator = validator["required"]("This field is required");
      schema[`question-${question_type}-${id}`] = validator;
      */
      schema[`question-${question_type}-${id}`] = yup
        .string()
        .required("This field is required");
    }
  } else {
    return schema;
  }
  return schema;
}

/**
 * get yup schema from meda data
 * @param {*} metadata
 * @param {*} additionalValidations
 * @param {*} forceRemove
 * @returns
 */
export const getYupSchemaFromMetaData = (
  metadata,
  additionalValidations,
  forceRemove
) => {
  //console.log("questions", metadata);
  const yepSchema = metadata.reduce(createYupSchema, {});
  console.log("yep schema", yepSchema);
  const mergedSchema = {
    ...yepSchema,
    ...additionalValidations,
  };

  forceRemove.forEach((field) => {
    delete mergedSchema[field];
  });

  //console.log("merged schema", mergedSchema);

  const validateSchema = yup.object().shape(mergedSchema);

  return validateSchema;
};
