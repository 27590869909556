import axios from "../../axios-instance";
import { fallBackErrorMessage } from "../../constants/event";
import { getAdminBusinessId } from "../../utils/authHelper";

/**
 * Get company setting page services
 * @param {*} options
 * @returns
 */
export const getAdminConnectedCalendars = (options) => async (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get("admin/get-connected-calendars/" + getAdminBusinessId())
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * disconnect
 * @param {*} options
 */
export const disconnect = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/calendar-disconnect", data)
    .then((res) => {
      console.log("data", res);
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};
