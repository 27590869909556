import React, { useState } from "react";
import { notification, getDBFormatedDate } from "../../../utils/utility";
import Loader from "../../../components/Loader/Loader";
import { Modal, ModalBody } from "reactstrap";
import Calendar from "react-calendar";
import { getAdminBusinessId } from "../../../utils/authHelper";
import { getAvailableTimeSlots } from "../../../actions/bookingAction";
import { rescheduleEvent } from "../../../actions/admin/scheduled-event-actions";
import { connect } from "react-redux";
import * as Icon from "react-feather";

const RescheduleModal = (props) => {
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slots, setSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [bookingDate, setBookingDate] = useState(null);

  /**
   * on calendar date change handler
   * @param {*} date
   */
  const onCalendarDateChange = (date) => {
    const { getAvailableTimeSlots } = props;
    setSlots([]);
    setCalendarDate(date);
    setBookingDate(getDBFormatedDate(new Date(date)));
    setIsLoading(true);
    getAvailableTimeSlots({
      data: {
        selected_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        staff_id: 1,
        business_id: getAdminBusinessId(),
        event_id: props.data.event_id,
      },
      onSuccess: function (response) {
        console.log("response", response);
        setIsLoading(false);
        setSlots(response);
      },
      onError: (error) => {
        setIsLoading(false);
        notification.error(error.response.data.message);
      },
    });
  };

  /**
   * on select time slot
   * @param {*} timeslot
   */
  const onSelectTimeSlot = (timeslot) => {
    setSelectedSlot(timeslot);
  };

  /**
   * on confirm reschedule
   */
  const onConfirm = () => {
    const {
      data,
      rescheduleEvent,
      handleClose,
      handleViewDetailClose,
      loadBookings,
    } = props;
    const bookingDateTime = bookingDate + " " + selectedSlot;
    let endDate = new Date(bookingDateTime);
    if (!isNaN(data.timeslot)) {
      endDate.setTime(endDate.getTime() + data.timeslot * 60 * 1000);
    }

    const endSlot =
      ("0" + endDate.getHours()).slice(-2) +
      ":" +
      ("0" + endDate.getMinutes()).slice(-2);

    setIsLoading(true);
    rescheduleEvent({
      data: {
        booking_id: data.id,
        booking_date_time: bookingDateTime,
        start_time: selectedSlot + ":00",
        end_time: endSlot + ":00",
        reschedule_reason: "",
      },
      onSuccess: function (response) {
        console.log(response);
        setIsLoading(false);
        notification.success("Reschedule Successfully..");
        handleClose();
        handleViewDetailClose();
        loadBookings();
      },
      onError: function (error) {
        console.error(error);
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Loader isShowLoader={isLoading} />
      <Modal
        returnFocusAfterClose={true}
        isOpen={props.showRescheduleModal}
        toggle={props.handleClose}
        className="event-modal modal-lg modal-dialog"
      >
        <ModalBody>
          <div className="">
            <div className="box_content">
              <div className="rd_inputselectheadermodalsetting">
                <button
                  className="rd_colsebtn"
                  onClick={props.handleClose}
                ></button>
              </div>

              <h5 className="rd_modalheadertext rd_modalheadertextnm mb-4">
                Reschedule
              </h5>

              <div className="row sub_div">
                <div className="col-sm-6 col-lg-6 col-md-6 mb-5 mb-md-0">
                  <input
                    type="text"
                    className="form-control rs-select-date-input"
                    id="result"
                    placeholder="Select date"
                    disabled="disabled"
                  />
                  <form className="row">
                    <div className="col-md-12">
                    <div className="cale_container position-relative">
                    <div className="nodate_avail">
                      <div className="txt d-flex">
                    <button className="btn p-0 arrow prev">
                      <Icon.ChevronLeft size={20} />
                    </button>
                      <div className="mid">
                        <p>No Dates Available</p>
                        <button
                          className="btn p-0"
                          // onClick={this.onGotoNextMonth}
                        >
                          Go to next Month
                        </button>
                      </div>
                      <button className="btn p-0 arrow next">
                      <Icon.ChevronRight size={20} />
                    </button>
                      </div>
                    </div>
                      <Calendar
                        minDate={new Date()}
                        onChange={onCalendarDateChange}
                        value={calendarDate}
                        locale="en-GB"
                      />
                      </div>
                    </div>
                  </form>
                </div>
                {(slots || []).length > 0 ? (
                  <>
                    <div className="col-sm-6 col-lg-6 col-md-6">
                      <input
                        type="text"
                        className="form-control rs-select-date-input"
                        placeholder="Select Time"
                        disabled="disabled"
                      />
                      <div className="col-12 px-0">
                        <div className="row main_time_div rs-timeslot-container">
                          {(slots || []).map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={`col-5 main_time rs-timeslot ${
                                  selectedSlot == item ? "time_active" : ""
                                }`}
                                onClick={() => onSelectTimeSlot(item)}
                              >
                                {item}
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="col-12">
                        {selectedSlot ? (
                          <button
                            type="button"
                            className="btn btn-app"
                            id="cnfrm_btn"
                            onClick={onConfirm}
                          >
                            {`Confirm ${selectedSlot}`}
                          </button>
                        ) : (
                          <> </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-sm-6 col-lg-6 col-md-6">
                    <input
                      type="text"
                      className="form-control rs-select-date-input"
                      placeholder="Select Time"
                      disabled="disabled"
                    />
                    <div className="col-12 px-0">
                      <div className="row main_time_div">
                        <p className="text-left">No Slot Available.</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, {
  getAvailableTimeSlots,
  rescheduleEvent,
})(RescheduleModal);
