import React, { Component, Fragment } from "react";
import {
  Modal,
  ModalBody,
} from "reactstrap";
import { notification, IMAGE_BASE_URL } from "../../../../utils/utility";
import { getLoggedInUserId } from "../../../../utils/authHelper";
import ClrPicker from "../Fields/ClrPicker";
import axiosInstance from "../../../../axios-instance";

class ShareEventTypeDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      backgroundColor: "default",
      textColor: "default",
      buttonColor: "default",
    };
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
      backgroundColor: "default",
      textColor: "default",
      buttonColor: "default",
    });
  };

  onCopyCode = () => {
    var copyText = document.getElementById("share-event-element");
    copyText.select();
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    notification.success(`Code copied successfully`);
  };

  /**
   *
   */
  onPreview = () => {
    const embedCodeElement = document.getElementById("share-event-element");
    axiosInstance
      .post(`admin/generate-code-preview`, {
        data: embedCodeElement.value,
        type: 'event',
        userId: getLoggedInUserId(),
      })
      .then((res) => {
        window.open(
          `${IMAGE_BASE_URL}uploads/preview/event-code-preview-${getLoggedInUserId()}.html`,
          "_blank"
        );
      })
      .catch((err) => {});
  };

  render() {
    const _this = this;
    const { isOpen, backgroundColor, textColor, buttonColor } = this.state;
    const { shareEventType, event } = this.props;
    const { event_title, event_url } = event;

    let content = "";
    let shareTypeTitle = "";

    if (shareEventType === 1) {
      shareTypeTitle = "Inline Embed";
      const dataConfig = { 
        "event-url": event_url, 
        "event-name": event_title, 
        'type': 'inline',
        "background-color": backgroundColor,
        "text-color": textColor,
        "button-color": buttonColor, 
      };
      content =
        "<div id='meetocto-booking-widget' data-config='" +
        JSON.stringify(dataConfig) +
        "'></div>";
      content +=
        '<link rel="stylesheet" href="https://www.staging.thrive-booking.com/embeddable-widget/widget.css" />';
      content +=
        '<script type="text/javascript" src="https://www.staging.thrive-booking.com/embeddable-widget/widget.js"></script>';
    } else if (shareEventType === 2) {
      const dataConfig = { 
        "event-url": event_url, 
        "event-name": event_title, 
        'type': 'text-popup',
        "background-color": backgroundColor,
        "text-color": textColor,
        "button-color": buttonColor, 
      };
      shareTypeTitle = "Popup Embed";
      content =
        "<div id='meetocto-booking-widget' data-config='" +
        JSON.stringify(dataConfig) +
        "'></div>";
      content +=
        '<link rel="stylesheet" href="https://www.staging.thrive-booking.com/embeddable-widget/widget.css" />';
      content +=
        '<script type="text/javascript" src="https://www.staging.thrive-booking.com/embeddable-widget/widget.js"></script>';
    } else {
      const dataConfig = { 
        "event-url": event_url, 
        "event-name": event_title, 
        'type': 'widget-popup',
        "background-color": backgroundColor,
        "text-color": textColor,
        "button-color": buttonColor, 
      };
      shareTypeTitle = "Widget Embed";
      content =
        "<div id='meetocto-booking-widget' data-config='" +
        JSON.stringify(dataConfig) +
        "'></div>";
      content +=
        '<link rel="stylesheet" href="https://www.staging.thrive-booking.com/embeddable-widget/widget.css" />';
      content +=
        '<script type="text/javascript" src="https://www.staging.thrive-booking.com/embeddable-widget/widget.js"></script>';
    }

    return (
      <Fragment>
        <button onClick={this.toggle}>Continue</button>
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className="event-modal embed_other_modal modal-lg modal-dialog"
        >
          <ModalBody>
            <div className="">
              <div className="eventdetailsaddbox rd_modalvieew rd_modalvieewdashboard">
                <div className="box_content rd_modalboxcontent">
                  <div className="rd_dashboardmodaltopt">
                    <div>
                      <p>Group Conference</p>
                      <p>
                        <span className="rdmodaldashicontim"></span>
                        30 mins
                      </p>
                    </div>
                    <div className="rd_alignright">
                      <p>
                        <span className="rd_zoomicon"></span>
                        Zoom Meeting
                      </p>
                      <p>Group</p>
                    </div>
                  </div>
                  <div className="rd_dashboardmodalmainco">
                    <div className="rd_dashboardmodalmaincotoppart">
                      <div>
                        <h4>
                          <strong>{shareTypeTitle}</strong>
                        </h4>
                        <p>{event_title}</p>
                      </div>
                      <div>
                        <button onClick={this.toggle}>Change</button>
                      </div>
                    </div>
                    <div className="row flex-column-reverse flex-md-row">
                      <div className="col-md-5 clr_settings">
                        <div className="inner">
                          <h4>Page Settings</h4>
                          <p>Customize the look of your booking page to fit seamlessly into your website.</p>
                          <div className="clr_field_width d-flex align-items-center justify-content-between">
                            <label className="form-label">Background color</label>
                            <ClrPicker 
                              onColorPickerChange={(color) => {
                              _this.setState({ backgroundColor: color });
                              }}
                              value={_this.state.backgroundColor}
                          />
                          </div>
                          <div className="clr_field_width d-flex align-items-center justify-content-between">
                            <label className="form-label">Text color</label>
                            <ClrPicker
                              onColorPickerChange={(color) => {
                                _this.setState({ textColor: color });
                              }} 
                              value={_this.state.textColor}
                            />
                          </div>
                          <div className="clr_field_width d-flex align-items-center justify-content-between">
                            <label className="form-label">Button and link color</label>
                            <ClrPicker
                              onColorPickerChange={(color) => {
                                _this.setState({ buttonColor: color });
                              }} 
                              value={_this.state.buttonColor}
                             />
                          </div>
                          <div className="clr_picker d-flex align-items-center mt-5">
                            <button className="btn btn-grey mr-2" onClick={_this.onPreview}>Preview</button>
                            <button className="btn btn-grey ml-2" 
                              onClick={(event) => {
                                event.preventDefault();
                                _this.setState({
                                  backgroundColor: "default",
                                  textColor: "default",
                                  buttonColor: "default",
                                });
                              }}>
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div
                          className="rd_modaldashtabtop opened"
                          style={{ display: "none" }}
                        >
                          <button>Link Text Settings</button>
                        </div>
                        <div
                          className="rd_modaldashtabtcont opened"
                          style={{ display: "none" }}
                        >
                          <p>
                            Customize the link visitors will click to launch your
                            Calendly page.
                          </p>
                          <div className="rd_modaldashtabtcontspeci">
                            <label htmlFor="">Link Text</label>
                            <input
                              type="text"
                              name=""
                              id=""
                              placeholder="Schedule time with me"
                            />
                          </div>
                        </div>
                        <div
                          className="rd_modaldashtabtop opened"
                          style={{ display: "none" }}
                        >
                          <button>Page Settings</button>
                        </div>
                        <div
                          className="rd_modaldashtabtcont opened"
                          style={{ display: "none" }}
                        >
                          <div className="rd_flexthing rd_flexthingspethingmo">
                            <div className="rd_flexthing33">
                              <h6>
                                <strong>Page Settings</strong>
                              </h6>
                              <p>
                                Customize the look of your booking page to fit
                                seamlessly into your website
                              </p>
                            </div>
                            <div className="rd_flexthing2">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckChecked3"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckChecked3"
                                >
                                  Hide Event Type Details
                                </label>
                              </div>
                            </div>
                            <div className="rd_flexthing2">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckChecked3"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckChecked3"
                                >
                                  Hide GDPR Banner
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="rd_flexthing rd_flexthingspethingmo">
                            <div className="rd_flexthing2">
                              <label htmlFor="">Background color</label>
                              <button className="rd_colorbtn"></button>
                            </div>
                            <div className="rd_flexthing2">
                              <label htmlFor="">Text color</label>
                              <button className="rd_colorbtn"></button>
                            </div>
                            <div className="rd_flexthing2">
                              <label htmlFor="">Button and link color</label>
                              <button className="rd_colorbtn"></button>
                            </div>
                          </div>
                        </div>

                        <div
                          className="rd_modaldashtabtop opened"
                          style={{ display: "none" }}
                        >
                          <button>Popup Widget Settings</button>
                        </div>
                        <div
                          className="rd_modaldashtabtcont opened"
                          style={{ display: "none" }}
                        >
                          <p>
                            Customize the display of the floating button that will
                            launch your Calendly page.
                          </p>
                          <div className="rd_flexthing rd_flexthingspethingmo">
                            <div className="rd_flexthing33">
                              <label>Button Text</label>
                              <input
                                type="text"
                                name=""
                                id=""
                                placeholder="Schedule time with me"
                              />
                            </div>
                            <div className="rd_flexthing2">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckChecked3"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckChecked3"
                                >
                                  Button Background color
                                </label>
                              </div>
                            </div>
                            <div className="rd_flexthing2">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckChecked3"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckChecked3"
                                >
                                  Button Text color
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rd_emebedparinmodl">
                          <h5>
                            <strong>Embed Code</strong>
                          </h5>
                          <p>
                            Place this code in your page's HTML where you
                            <br />
                            want your event page to appear.
                          </p>

                          <textarea
                            name=""
                            cols="30"
                            rows="10"
                            id="share-event-element"
                            readOnly={true}
                            value={content}
                          ></textarea>

                          <p style={{ display: "none" }}>
                            See how to embed your Calendly link across the web,
                            including sites like
                            <a href="">Wix</a>
                            <a href=""> Squarespace</a>
                            and <a href="">WordPress</a>. Or explore
                            <a href="">advanced embed features .</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rd_dashboardmodalfooterpart">
                    <div>
                      <button
                        className="rd_dashbackbutton"
                        onClick={this.toggle}
                      >
                        Back
                      </button>
                    </div>
                    <div className="rd_dashboardmodalfooterpartb">
                      <button onClick={this.toggle}>Cancel</button>
                      <button onClick={this.onCopyCode}>Copy Code</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default ShareEventTypeDetailModal;