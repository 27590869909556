import React, { useState, useEffect } from "react";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "../../../../../components/FormField/FormField";

const validation = Yup.object().shape({
  schedule_name: Yup.string().required("Schedule Name is required."),
});

let initialValues = {
  schedule_name: "",
  schedule_id: "",
};

const EditScheduleModal = (props) => {
  const { showEditScheduleModal, hideEditScheduleModal } = props;

  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    setFormValues({
      schedule_id: props.scheduleId,
      schedule_name: props.scheduleName,
    });
  }, []);

  return (
    <>
      <Modal isOpen={showEditScheduleModal} toggle={hideEditScheduleModal}>
        <ModalBody>
          <div className="">
            <div className="rd_modalvieew">
              <div className="box_content rd_modalboxcontent">
                <div className="rd_inputselectheadermodalsetting">
                  <button
                    className="rd_colsebtn"
                    onClick={hideEditScheduleModal}
                  ></button>
                </div>

                <Formik
                  validationSchema={validation}
                  initialValues={formValues || initialValues}
                  enableReinitialize
                  onSubmit={(values) => {
                    props.hideEditScheduleModal();
                    props.updateScheduleTemplateName(
                      props.scheduleId,
                      values.schedule_name
                    );
                  }}
                >
                  {(formProps) => {
                    const {
                      values,
                      errors,
                      touched,
                      handleChange,
                      setFieldValue,
                    } = formProps;
                    return (
                      <Form>
                        <h5 className="rd_modalheadertext rd_modalheadertextnm">
                          Update Schedule Template Name
                        </h5>

                        <FormGroup className="mb-4">
                          <label className="same-label" htmlFor="coupon_code">
                            Schedule Name *
                          </label>
                          <FormField
                            type="text"
                            name="schedule_name"
                            placeholder="Schedule Name"
                            value={values.schedule_name}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "schedule_name", value },
                              });
                            }}
                          />
                        </FormGroup>

                        <div className="rd_cancelappbtnmodal">
                          <button
                            className="rd_cancel"
                            onClick={hideEditScheduleModal}
                          >
                            Cancel
                          </button>
                          <button type="submit" className="btn btn-app">
                            Save
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditScheduleModal;
