import React, { Component, Fragment } from 'react';
import * as Icon from "react-feather";
import { Row, Col, Button } from 'reactstrap';
import TrashIcon from "../../../../assets/icons/trash_icon.svg"

class OptionsField extends Component {
    render() {
        const { options, onChange, onAddOption, onRemoveOption } = this.props || {};
        return (
            <div className="answer_options">
                {
                    (options || {}).map((option, index) => {
                        const { field_label: fieldLabel } = option || {};
                        return (
                            <Row key = { index } className="align-items-center">
                                <Col xs="10" className="pe-0">
                                    <div className="form-group event-form-group">
                                        <label>Option</label>
                                        <input type="text" className="form-control" value = { fieldLabel } placeholder="Answer" onChange = { ({ target }) => {
                                            const { value } = target || {};
                                            onChange(index, value);
                                        }}/>
                                    </div>
                                </Col>
                                <Col xs="2" className='mt-2'>
                                    <span className="removeOption" onClick = { () => {
                                        onRemoveOption(index);
                                    }}><img src={TrashIcon} alt="Trash Icon" style={{maxWidth: 20}} /></span>
                                </Col>
                            </Row>
                        )
                    })
                }
                <Row>
                    <Col md="5" lg="3" className="mt-4">
                        <Button className="btn btn-primary btn-outline btn-add-question" type="button" onClick = { () => {
                            onAddOption()
                        }}>
                            <span> <Icon.Plus size="16" /></span>
                            Add Option
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }
}


export default OptionsField;