import React, { Component, Fragment } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button, Label } from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { getTimezones } from "../../../../actions/companyActions";
import { getScheduleTemplates, getSchedule, saveAsNewScheduleTemplate } from "../../../../actions/scheduleActions";
import ScheduleListView from "./ScheduleListView";
import ScheduleCalendarView from "./ScheduleCalendarView";
import FormField from "../../../../components/FormField/FormField";
import AddScheduleModal from "../Modals/AddScheduleModal";
import { getAdminBusinessId } from "../../../../utils/authHelper";
import { notification } from "../../../../utils/utility";
import isEmpty from "../../../../validation/is-empty";
import moment from "moment";
import EventAvailabilityTabs from "./event-availability-tabs";

class EventAvailbilityComponent extends Component {
  
  timezoneValue = "";

  state = {
    events: [],
    activeTab: "1",
    showModal: false,
    timezones: [],
    activeAvailabilityBtn : "1",
    templates: [],
    timezoneId: "",
    showAddScheduleModal: false,
    scheduleTemplateId: "",
  };

  /**
   * Execute when component inserted in dom
   */
  componentDidMount() {
    const _this = this;
    const { getTimezones } = _this.props;
    getTimezones({
      data: {},
      onSuccess: function (data) {
        _this.setState({
          timezones: data.timezones,
        });
      },
      onError: function (error) {
        console.log("error", error);
      },
    });

    _this.loadScheduleTemplates();
  }

   
  /**
   * Load schedule templates
   */
  loadScheduleTemplates = () => {
    const _this = this;
    const { getScheduleTemplates, type, staffId, schedules } = _this.props;
    getScheduleTemplates({
      data: {},
      onSuccess: function (data) {
        let templates = [];
        if (type === 'event') {
          templates  = (data.templates || [])
          .filter((template, index) => template.schedule_type === 'company');
        } else {
          templates = (data.templates || [])
          .filter((template, index) => template.staff_id === parseInt(staffId) || (template.schedule_type === 'company' && template.is_default === 'E') || (template.schedule_type === 'company' && template.is_business_hours === 'E'));
        }
        _this.setState({
          templates
        });

        // loading by default schedule template
        if (type === "event_staff") {
          const staffSchedules = (schedules || []).filter((schedule, index) => {
            return schedule.staffId == staffId;
          });
          if (staffSchedules.length === 0) {
            templates.forEach((template, index) => {
              if (template.is_default === 'E' && template.staff_id === parseInt(staffId)) {
                _this.setState({
                  scheduleTemplateId: template.value
                });
                _this.getTemplateSchedule(template.value);
              }  
            });
          }
          
        } else if (type === "event") {
          const eventSchedules = (schedules || []).filter((schedule, index) => {
            return schedule.type == "event";
          });
          if (eventSchedules.length === 0) {
            //console.log("length checking");
            templates.forEach((template, index) => {
              if (template.is_default === 'E') {
                _this.setState({
                  scheduleTemplateId: template.value
                });
                //_this.getTemplateSchedule(template.value);
              }  
            });
          }
        }

      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  }

  /**
   * 
   * @param {*} template 
   */
  getTemplateSchedule = (value) => {
    const _this = this;
    const { 
      getSchedule, 
      setSchedules, 
    } = _this.props;

    getSchedule({
      data: {
        scheduleId: value
      },
      onSuccess: function (response) {
        if (response.data) {
          _this.setState({
            timezoneId: response.data.timezone_id
          });
        }
        
        let scheduleDetails = (response.data.schedule_details).map((scheduleDetail) => {
          if (scheduleDetail.type == "weekly") {
            return {
              dayId: parseInt(scheduleDetail.weekday_id),
              schedules: [...scheduleDetail.schedule_availability],
            };
          } else if (scheduleDetail.type == "custom") {
            return {
              dayId: null,
              dated: moment(scheduleDetail.dated, "yyyy-MM-DD").format("DD-MM-yyyy"),
              schedules: [...scheduleDetail.schedule_availability],
            }
          }
        });

        //console.log("i am template here now abc", scheduleDetails);

        setSchedules(scheduleDetails, response.data.timezone_id);

      },
      onError: function (error) {
        console.log("error", error);
      },
    });

  }

  /**
   * Toggle nav tab
   * @param {*} tab 
   */
  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  /**
   * hide add schedule modal
   */
  hideAddScheduleModal = () => {
    this.setState({
      showAddScheduleModal: false
    });
  }


  /**
   * process schedules to save in database
   * @param int eventId
   * @returns array
   */
   processScheduleForSave = (schedules) => {
    let processedSchedules = [];
    schedules.forEach((schedule, index) => {
      const weekDayId = schedule.hasOwnProperty("dayId")
        ? schedule.dayId
        : null;
      const dated = schedule.hasOwnProperty("dated") ? schedule.dated : null;

      const type = schedule.hasOwnProperty("dated") ? "custom" : "weekly";

      processedSchedules.push({
        weekday_id: weekDayId,
        dated: dated,
        on_status: "E",
        type: type,
        schedule_availability: schedule.schedules,
      });
    });

    return processedSchedules;
  };


  /**
   * save new schedule
   */
  saveNewSchedule = (scheduleName) => {
    const _this = this;
    const {
      type, 
      staffId,
      saveAsNewScheduleTemplate
    } = _this.props;


    if (isEmpty(_this.state.timezoneId)) {
      return notification.warning("Please select timezone first");
    }

    const data = {
      'schedule_name': scheduleName,
      'business_id' : getAdminBusinessId(),
      'type': type,
      'staff_id': staffId,
      'timezone_id': _this.state.timezoneId,
      'schedule_details': _this.processScheduleForSave(_this.getFilteredSchedules()),
    }

    saveAsNewScheduleTemplate({
      data,
      onSuccess: function (response) {
        _this.loadScheduleTemplates();
        notification.success("Schedule added successfully");
      },
      onError: function (error) {
        _this.setState({
          pageLoading: false,
        });
        const { data } = error?.response;
        notification.error("An error during save, please try later");
      },
    });
  }


  /**
   * 
   * @returns 
   */
  getFilteredSchedules = () => {
    const _this = this;
    const {
      schedules,
      type, 
      staffId
    } = _this.props;

    if (type === "event_staff") {
      const staffSchedules = (schedules || []).filter((schedule, index) => {
        return schedule.staffId == staffId;
      });
      //console.log('staff schedule', staffSchedules);
      if (staffSchedules.length > 0) {
        this.timezoneValue = staffSchedules[0].timezoneId;
        return staffSchedules[0].schedules;
      } else {
        return []; 
      }
      
    } else if (type === "event") {
      const eventSchedules = (schedules || []).filter((schedule, index) => {
        return schedule.type == "event";
      });
      if (eventSchedules.length > 0) {
        this.timezoneValue = eventSchedules[0].timezoneId;
        return eventSchedules[0].schedules;
      } else {
        return [];
      }
    } else {
      return [];
    }

  }

  render() {
    const {
      errors,
      touched,
      businessId,
      setSchedules,
    } = this.props || {};
    const { 
      activeTab, 
      startDate, 
      endDate, 
      events: stateEvents, 
      timezones, 
      activeAvailabilityBtn, 
      templates, 
      timezoneId,
      showAddScheduleModal,
      scheduleTemplateId
    } = this.state;

    const filteredSchedules = this.getFilteredSchedules();

    return (
      <Fragment>

        {showAddScheduleModal && (
          <AddScheduleModal
            showAddScheduleModal={showAddScheduleModal}
            hideAddScheduleModal={this.hideAddScheduleModal}
            saveNewSchedule={this.saveNewSchedule}
          />
        )}

        <div className="tabs-wrapper event-availbility">
          <div className="tabs-container">
            <Row className="mb-2">
              <Col>
                <Label className="event-form-label">
                How do you want to offer your availability for this event ?
                </Label>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col md="6" xl="4">
                <Button
                  className={classnames("btn btn-outline", {
                    "availability-btn-active": activeAvailabilityBtn === "1"
                  })}
                  type="button"
                  onClick= { (event) => {
                    this.setState({
                      activeAvailabilityBtn:"1"
                    });
                  }}
                >
                  Us an existing Schedule
                </Button>
              </Col>
              <Col md="6" xl="4" className="mt-3 mt-md-0">
                <Button
                  className={classnames("btn btn-outline", {
                    "availability-btn-active": activeAvailabilityBtn === "2"
                  })}
                  type="button"
                  onClick= { (event) => {
                    this.setState({
                      activeAvailabilityBtn: "2",
                      timezoneId: ""
                    });
                    setSchedules([], "");
                  }}
                >
                  Set Custom hours
                </Button>
              </Col>

              { activeAvailabilityBtn === "2" && (<Col md="6" lg="4" className="mt-3">
                <Button
                  className="btn btn-outline"
                  type="button"
                  onClick= { (event) => {
                    this.setState({
                      showAddScheduleModal: true
                    });
                  }}
                >
                  Save as new schedule
                </Button>
              </Col>) }
              

            </Row>

            { activeAvailabilityBtn === "1" && (
              <Row className="mb-4">
                <Col md="6" lg="6">
                  <Label className="event-form-label"> 
                    Which schedule do you want to use for this event type?
                  </Label>
                  <FormField
                    placeholder="Select Schedule Template"
                    showPlaceholder={true}
                    type="select"
                    name="schedule_template_id"
                    label="Select Schedule Template"
                    errors={[]}
                    touched={[]}
                    value={scheduleTemplateId}
                    options={templates}
                    onChange={({ target }) => {
                      const { value } = target;
                      this.setState({
                        scheduleTemplateId: value
                      });
                      this.getTemplateSchedule(value);
                    }}
                  />
                </Col>
              </Row>
            )}
            

            <Row className="pt-4">
              <Col md="4" lg="4">
              <div className="form-group event-form-group">
                  <FormField
                    showLabel
                    placeholder="Select Timezone"
                    showPlaceholder={true}
                    type="select"
                    name="timezone"
                    label="Select Timezone"
                    errors={[]}
                    touched={[]}
                    value={timezoneId || this.timezoneValue}
                    options={timezones}
                    onChange={({ target }) => {
                      const { value } = target;
                      //console.log('timezone value', value);
                      setSchedules(this.getFilteredSchedules(), value);
                      this.setState({
                        timezoneId: value
                      });

                    }}
                  />
                </div>
              </Col>
              <Col md="8" lg="8">
                <EventAvailabilityTabs 
                  activeTab={activeTab} 
                  toggle={this.toggle}
                />
              </Col>
            </Row>

            
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <ScheduleListView
                  businessId={businessId}
                  timezoneId={this.state.timezoneId}
                  errors={errors}
                  touched={touched}
                  eventSchedules={filteredSchedules}
                  setEventSchedules={setSchedules}
                />
              </TabPane>
              <TabPane tabId="2">
                <ScheduleCalendarView
                  businessId={businessId}
                  timezoneId={this.state.timezoneId}
                  events={stateEvents}
                  errors={errors}
                  touched={touched}
                  eventSchedules={filteredSchedules}
                  setEventSchedules={setSchedules}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  getTimezones,
  getScheduleTemplates,
  getSchedule,
  saveAsNewScheduleTemplate
})(EventAvailbilityComponent);
