import React from 'react';

const SidebarProgressPoint = ({ point, index, route }) => {
    const { stepTitle } = point || {};
    return (
        <li className={"progress-point " +  (point.routePath.indexOf(route) > -1  ? 'active' : "") }>
            <span className="point-decimal">
                { index }
            </span>
            { stepTitle }
        </li>

    )
}

export default SidebarProgressPoint;