import axios from "../axios-instance";
import { fallBackErrorMessage } from "../constants/event";
import { getAdminBusinessId } from "../utils/authHelper";

/**
 * get staff schedule list
 * @param {*} options
 * @returns
 */
export const getStaffScheduleList = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .get(`/admin/schedule/get-staff-schedule-list/${data.staffId}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * get staff schedule list
 * @param {*} options
 * @returns
 */
export const getSchedule = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .get(
      `/admin/schedule/get-schedule/${getAdminBusinessId()}/${data.scheduleId}`
    )
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * get schedule templates for a company
 * @param {*} options
 * @returns
 */
export const getScheduleTemplates = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .get(`/admin/schedule/get-schedule-templates/${getAdminBusinessId()}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * save as new schedule template
 * @param {*} options
 */
export const saveAsNewScheduleTemplate = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/schedule/save-as-new-schedule-template", data)
    .then((res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
};

/**
 * save company schedule data
 * @param {*} options
 */
export const saveStaffScheduleName = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/schedule/save-staff-schedule-name", data)
    .then((res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
};

/**
 * update schedule template name
 * @param {*} options
 */
export const updateScheduleName = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/schedule/update-schedule-name", data)
    .then((res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * save company schedule data
 * @param {*} options
 */
export const deleteSchedule = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/schedule/delete", data)
    .then((res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * set as default
 * @param {*} options
 */
export const makeAsDefault = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/schedule/set-as-default", data)
    .then((res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * duplicate
 * @param {*} options
 */
export const duplicate = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/schedule/duplicate", data)
    .then((res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * get company schedule data
 * @param {*} options
 * @returns
 */
export const getStaffScheduleTemplate = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .get(
      `/admin/schedule/get-staff-schedule/${data.staffId}/${data.scheduleId}`
    )
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * save staff schedule data
 * @param {*} options
 */
export const saveStaffSchedule = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};

  axios
    .post("admin/schedule/save-staff-schedule", data)
    .then((res) => {
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * get company schedule data
 * @param {*} options
 * @returns
 */
export const getCompanySchedule = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};

  let getCompanyScheduleUrl = `/admin/schedule/get-company-schedule/${getAdminBusinessId()}`;
  if (data.scheduleId) {
    getCompanyScheduleUrl = getCompanyScheduleUrl + `/${data.scheduleId}`;
  }

  axios
    .get(getCompanyScheduleUrl)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * save company schedule data
 * @param {*} options
 */
export const saveCompanyScheduleName = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};

  axios
    .post("admin/schedule/save-company-schedule-name", data)
    .then((res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
};

/**
 * save company schedule data
 * @param {*} options
 */
export const saveCompanySchedule = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};

  axios
    .post("admin/schedule/save-company-schedule", data)
    .then((res) => {
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};
