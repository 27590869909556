import axios from "../axios-instance";

/**
 * Send Post Request
 * @param {*} options
 * @returns
 */
export const sendPostRequest = (options) => async (dispatch) => {
  const { url, data, onSuccess, onError } = options || {};
  axios
    .post(url, data)
    .then((res) => {
      const { data: resData } = res || {};
      //const { data } = resData || {};
      if (onSuccess) {
        onSuccess(resData);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * Send Get request
 * @param {*} options
 * @returns
 */
export const sendGetRequest = (options) => async (dispatch) => {
  const { url, onSuccess, onError } = options || {};
  axios
    .get(url)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};
