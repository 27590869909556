import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import EditModal from "./edit";
import DeleteModal from "./delete";
import CreateModal from "./create";
import { Col, Row } from "reactstrap";
import { Edit } from "react-feather";
import { Table, Dropdown, Menu } from "antd";
import "antd/dist/antd.css";
import Loader from "../../../../components/Loader/Loader";
import { getCompanySettingServices } from "../../../../actions/companyActions";
import {
  getCustomers,
  createCustomer,
  deleteCustomer,
  updateCustomer,
} from "../../../../actions/admin/crm-actions";
import isEmpty from "../../../../validation/is-empty";
import { notification } from "../../../../utils/utility";
import PaginatorDropdown from "../../../../components/Datatable/PaginationDropdown";
import Filter from "../../../../components/Filter/Filter";
import CustomerFilters from "./customer-filters";
import { toggleFilter } from "../../../../actions/filterActions";
import ActionDots from "../../../../components/Datatable/ActionDots";

const CustomerListing = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: false,
  });

  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);

  const [rowData, setRowData] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, data) => {
        return data.first_name + " " + data.last_name;
      },
      sorter: false,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      render: (_, data) => {
        return data.country ? data.country.label : "";
      },
      sorter: false,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: true,
    },
    {
      title: "Zip Code",
      dataIndex: "zip",
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, data) => {
        const menu = (
          <Menu
            items={[
              {
                label: (
                  <span
                    onClick={() => {
                      onEditClick(data);
                    }}
                  >
                    <Edit className="mr-50" size={15} /> Edit
                  </span>
                ),
                key: "0",
              },
              {
                type: "divider",
              },
              {
                label: (
                  <>
                    <DeleteModal onDeleteRecord={onDeleteRecord} data={data} />
                  </>
                ),
                key: "1",
              },
            ]}
          />
        );
        return (
          <div className="actions cursor-pointer">
            <Dropdown overlay={menu} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <ActionDots />
              </a>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  /**
   * hide edit modal
   */
  const hideEditModal = () => {
    setShowEditModal(false);
  };

  /**
   * on Edit icon click
   * @param {*} data
   */
  const onEditClick = (data) => {
    setRowData(data);
    setShowEditModal(true);
  };

  /**
   * add record
   * @param {*} data
   */
  const onAddRecord = (data) => {
    const { createCustomer, filter } = props;
    setIsPageLoading(true);
    setIsDataLoading(true);
    createCustomer({
      data: {
        ...data,
      },
      onSuccess: (response) => {
        const { success } = response;
        if (success) {
          notification.success("Customer successfully added");
        } else {
          notification.error(
            "An error occured during update, please try later."
          );
        }
        handleTableChange(
          {
            ...pagination,
          },
          {
            ...filter.searchFilters,
          },
          {}
        );
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
      onError: (error) => {
        console.log("error", error);
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on update record
   * @param {*} data
   */
  const onUpdateRecord = (data) => {
    const { updateCustomer, filter } = props;
    setIsPageLoading(true);
    setIsDataLoading(true);
    updateCustomer({
      data: {
        ...data,
      },
      onSuccess: (response) => {
        const { success } = response;
        if (success) {
          notification.success("Customer successfully updated");
        } else {
          notification.error(
            "An error occured during update, please try later."
          );
        }
        handleTableChange(
          {
            ...pagination,
          },
          {
            ...filter.searchFilters,
          },
          {}
        );
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
      onError: (error) => {
        console.log("error", error);
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on delete record
   * @param {*} id
   */
  const onDeleteRecord = (id) => {
    const { deleteCustomer } = props;
    setIsDataLoading(true);
    deleteCustomer({
      data: {
        id: id,
      },
      onSuccess: function (data) {
        notification.success("Record successfully deleted");
        handleTableChange(
          {
            current: 1,
            pageSize: 5,
          },
          {},
          {}
        );
        setIsDataLoading(false);
      },
      onError: function (error) {
        notification.error("An error occured during delete, Please try later");
        console.log("error", error);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on filter data table
   * @param {*} name
   * @param {*} value
   */
  const filterRecords = () => {
    const { toggleFilter, filter } = props;
    toggleFilter(false);
    handleTableChange(
      {
        ...pagination,
      },
      {
        ...filter.searchFilters,
      },
      {}
    );
  };

  /**
   * on clear filter handler
   */
  const clearFilterHandler = () => {
    handleTableChange(
      {
        ...pagination,
      },
      {},
      {}
    );
  };

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  const prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * fetch data table data
   * @param {*} params
   */
  const fetchTableData = (params = {}) => {
    const { getCustomers } = props;
    setIsDataLoading(true);
    getCustomers({
      data: prepareDataParam(params),
      onSuccess: function (data) {
        setData(data.entity);
        setPagination({
          ...params.pagination,
          total: data.totalCount,
        });
        setIsDataLoading(false);
      },
      onError: function (error) {
        setData([]);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on page size drop down change
   * @param {*} e  event
   */
  const onPageSizeChange = (e) => {
    handleTableChange(
      {
        //current: pagination.current,
        current: 1,
        pageSize: e.target.value,
        total: pagination.total,
      },
      {},
      {}
    );
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  const handleTableChange = (pagination, filters, sorter) => {
    fetchTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  useEffect(() => {
    fetchTableData({ pagination });
    const { getCompanySettingServices } = props;
    getCompanySettingServices({
      data: {},
      onSuccess: function (data) {
        setCountries(data.countries);
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  }, []);

  return (
    <>
      <Row className="app-user-list">
        <Loader isShowLoader={isPageLoading} />
        {showEditModal && (
          <EditModal
            onUpdateRecord={onUpdateRecord}
            data={rowData}
            countries={countries}
            showEditModal={showEditModal}
            handleClose={hideEditModal}
          />
        )}
        <Col sm="12">
          <Filter
            name="Customer"
            className="with_btn"
            onFilterRecords={filterRecords}
            onClearFilterHandler={clearFilterHandler}
            showButtons={true}
            buttons={
              <CreateModal onAddRecord={onAddRecord} countries={countries} />
            }
          >
            <CustomerFilters />
          </Filter>
        </Col>

        <Col sm="12">
          <Table
            columns={columns}
            className="crm_customer_table"
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={pagination}
            loading={isDataLoading}
            onChange={handleTableChange}
          ></Table>
        </Col>
        <Col
          sm="6"
          className={data.length !== 0 ? "table_paginaton" : "table_no_pagi"}
        >
          <PaginatorDropdown
            onPageSizeChange={onPageSizeChange}
            pageSize={pagination.pageSize}
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  getCompanySettingServices,
  getCustomers,
  createCustomer,
  deleteCustomer,
  updateCustomer,
  toggleFilter,
})(CustomerListing);
