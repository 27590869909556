import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notification as antdNotification } from "antd";
import isEmpty from "../validation/is-empty";
import moment from "moment-timezone";

import P from "../assets/bookingeventassets/assets/p.svg";
import ZoomIcon from "../assets/icons/zoom.svg";
import MeetIcon from "../assets/icons/google-meet.svg";
import TeamsIcon from "../assets/icons/Microsoft_Teams.svg";
import GoToIcon from "../assets/icons/goto_icon.svg";
import PhoneCall from "../assets/icons/phone_call.svg";
import InPersonMeet from "../assets/icons/in_person_meet.svg";
import OtherMeet from "../assets/icons/other_meet_icon.svg";

/**
 * update filter field in component state
 * @param {*} name
 * @param {*} value
 * @param {*} searchFilters
 */
export const onUpdateFilters = (name, value, searchFilters) => {
  if (isEmpty(value)) {
    delete searchFilters[name];
  } else {
    searchFilters[name] = value;
  }
  return searchFilters;
};

/**
 * used for get query params
 * @returns
 */
export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

/**
 * with router high order component
 * @param {*} Component
 * @returns
 */
export const withRouter = (Component) => {
  const ComponentWithRouterProp = (props) => {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  };
  return ComponentWithRouterProp;
};

/**
 * convert object to form data
 * @param {*} obj
 * @param {*} form
 * @param {*} namespace
 * @returns
 */
export const objectToFormData = (obj, form, namespace) => {
  var fd = form || new FormData();
  var formKey;
  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }
      // if the property is an object, but not a File,
      // use recursivity.
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};

/**
 * Convert hour
 * @param string hours
 * @returns
 */
export const convertHoursToMinutes = (hours) => {
  const timeParts = hours.split(":");
  const timeInMinutes = timeParts[0] * 60 + timeParts[1];
  return parseInt(timeInMinutes);
};

/**
 * Get day id
 * @param mixed weekDayId
 * @return int
 */
export const getDayId = (weekDayId) => {
  return weekDayId == "0" ? 7 : parseInt(weekDayId);
};

/**
 *
 * @param Date dated
 * @returns
 */
export const getFormatedDate = (dated) => {
  return (
    ("0" + dated.getDate()).slice(-2) +
    "-" +
    ("0" + (dated.getMonth() + 1)).slice(-2) +
    "-" +
    dated.getFullYear()
  );
};

/**
 *
 * @param Date dated
 * @returns
 */
export const getDBFormatedDate = (dated) => {
  return (
    dated.getFullYear() +
    "-" +
    ("0" + (dated.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + dated.getDate()).slice(-2)
  );
};

/**
 * @param string htmlString
 * @returns string
 */
export const stripHTMLTags = (htmlString) => {
  return !isEmpty(htmlString) ? htmlString.replace(/<[^>]+>/g, "") : htmlString;
};

/**
 * frontend base url
 */
export const FRONTEND_BASE_URL = "http://www.meetocto.com/";

/**
 * Backend api base url
 */
export const BACKEND_API_BASE_URL =
  "https://www.staging.thrive-booking.com/backend/public/api/";
/*
export const BACKEND_API_BASE_URL =
"http://127.0.0.1:8000/api/";
*/

/**
 * Company base url
 */
export const COMPANY_BASE_URL = "http://www.meetocto.com/company/";

/**
 * images base url
 */
export const IMAGE_BASE_URL =
  "https://www.staging.thrive-booking.com/backend/public/";
//"http://127.0.0.1:8000";

/**
 * show alert notification
 */
export const notification = {
  success: (description, message = "Success", placement = "topRight") => {
    antdNotification["success"]({
      message,
      description,
      placement,
    });
  },
  warning: (description, message = "Warning", placement = "topRight") => {
    antdNotification["warning"]({
      message,
      description,
      placement,
    });
  },
  error: (description, message = "Error", placement = "topRight") => {
    antdNotification["error"]({
      message,
      description,
      placement,
    });
  },
  info: (description, message = "Info", placement = "topRight") => {
    antdNotification["info"]({
      message,
      description,
      placement,
    });
  },
};

/**
 * Get Venue icon
 * @param {*} venue
 * @returns
 */
export const getVenueIcon = (venue) => {
  switch (venue) {
    case venue === "Microsoft Teams":
      return <img src={TeamsIcon} alt="microsoft teams icon" />;
    case venue === "Zoom":
      return <img src={ZoomIcon} alt="Zoom icon" />;
    case venue === "Google Meet":
      return <img src={MeetIcon} alt="Google Meet icon" />;
    case venue === "Phone Call":
      return <img src={PhoneCall} alt="Phone Call icon" />;
    case venue === "In-Person Meeting":
      return (
        <img
          src={InPersonMeet}
          alt="In Person Meet icon"
          style={{ maxWidth: 14 }}
        />
      );
    case venue === "Others":
      return <img src={OtherMeet} alt="microsoft teams icon" />;
    case venue === "Go to Meeting":
      return <img src={GoToIcon} alt="Go To icon" style={{ maxWidth: 18 }} />;
    default:
      return <img src={P} alt="event_img" />;
  }
};

/**
 * Capitalize first letter of word/string
 * @param {*} string
 * @returns
 */
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * on modal open
 *
 */
export const onModalOpen = () => {
  document.getElementsByTagName("body")[0].classList.add("modal-open");
};

/**
 * on modal close
 */
export const onModalClose = () => {
  document.getElementsByTagName("body")[0].classList.remove("modal-open");
  setTimeout(() => {
    document.getElementsByTagName("body")[0].style.removeProperty("overflow");
  }, 500);
};

/**
 * Get user timezone
 */
export const getUserTimezoneId = () => {
  if (localStorage.userTimezoneId) {
    return localStorage.userTimezoneId;
  }
  return 60; // America/New_York
};

/**
 * Get Logged In user time zone
 * @returns string
 */
export const getLoggedInUserTimezone = () =>
  localStorage.userTimezone ? localStorage.userTimezone : "America/New_York";

export const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const urlSlugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

/**
 * check if property exist or not
 * @param {*} obj
 * @param {*} prop
 * @return boolean
 */
export const gotProperty = (obj, prop) => {
  return !isEmpty(obj) ? prop in obj : false;
};

/**
 * get user browser time zone
 * @returns string
 */
export const getUserBrowserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * Convert from UtC time to user time
 * @param {*} utcTime
 * @param {*} timezone
 * @param {*} fromFormat
 * @param {*} toFormat
 */
export const toUserTime = (
  utcTime,
  timezone,
  fromFormat = "HH:mm:ss",
  toFormat = "HH:mm"
) => {
  return moment.tz(utcTime, fromFormat, "UTC").tz(timezone).format(toFormat);
};
