import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getPromos,
  updatePromo,
  deletePromo,
  addPromo,
} from "../../../../actions/admin/promo-actions";
import { Table } from "antd";
import { Col, Row } from "reactstrap";
import Loader from "../../../../components/Loader/Loader";
import isEmpty from "../../../../validation/is-empty";
import ConfirmDeleteModal from "./Components/ConfirmDeleteModal";
import EditPromoModal from "./edit";
import "antd/dist/antd.css";
import { notification } from "../../../../utils/utility";
import Filter from "../../../../components/Filter/Filter";
import CreateModal from "./create";

const PromoListing = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: false,
  });

  const [filter, setFilter] = useState({});

  const [data, setData] = useState([]);

  const columns = [
    {
      title: "Coupon Code",
      dataIndex: "coupon_code",
      sorter: true,
    },
    {
      title: "Payout Type",
      dataIndex: "coupon_payout_type",
      render: (coupon_payout_type) => {
        return coupon_payout_type === "F" ? "Flat" : "Percent";
      },
      sorter: true,
    },
    {
      title: "Value",
      dataIndex: "coupon_value",
      sorter: true,
    },
    {
      title: "Limit",
      dataIndex: "coupon_limit",
      sorter: true,
    },
    {
      title: "Used",
      dataIndex: "coupon_used",
      render: (coupon_used) => {
        return isEmpty(coupon_used) ? "0" : coupon_used;
      },
      sorter: false,
    },
    {
      title: "Expiry",
      dataIndex: "coupon_expiry",
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, data) => {
        return (
          <div className="actions cursor-pointer">
            <EditPromoModal onUpdateRecord={onUpdateRecord} data={data} />
            <ConfirmDeleteModal onDeleteRecord={onDeleteRecord} data={data} />
          </div>
        );
      },
    },
  ];

  /**
   * add record
   * @param {*} data
   */
  const onAddRecord = (data) => {
    const { addPromo, filter } = props;
    setIsPageLoading(true);
    setIsDataLoading(true);
    addPromo({
      data: {
        ...data,
      },
      onSuccess: (response) => {
        console.log("resonse", response);
        if (response) {
          notification.success("Promo successfully added");
        } else {
          notification.error("An error occured during save, please try later.");
        }
        handleTableChange(
          {
            ...pagination,
          },
          {
            ...filter.searchFilters,
          },
          {}
        );
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
      onError: (error) => {
        console.log("error", error);
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on delete record
   * @param {*} id
   */
  const onDeleteRecord = (id) => {
    const { deletePromo } = props;
    setIsDataLoading(true);
    deletePromo({
      data: {
        promoId: id,
      },
      onSuccess: function (data) {
        notification.success("Record successfully deleted");
        handleTableChange(
          {
            current: 1,
            pageSize: 5,
          },
          {},
          {}
        );
        setIsDataLoading(false);
      },
      onError: function (error) {
        notification.error("An error occured during delete, Please try later");
        console.log("error", error);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on update record
   * @param {*} data
   */
  const onUpdateRecord = (data) => {
    const { updatePromo } = props;
    setIsPageLoading(true);
    setIsDataLoading(true);
    updatePromo({
      data: {
        ...data,
      },
      onSuccess: (response) => {
        const { success } = response;
        if (success) {
          notification.success("Promo successfully updated");
        } else {
          notification.error(
            "An error occured during update, please try later."
          );
        }
        handleTableChange(
          {
            ...pagination,
          },
          {
            ...filter,
          },
          {}
        );
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
      onError: (error) => {
        console.log("error", error);
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on filter data table
   * @param {*} name
   * @param {*} value
   */
  const onFilterDataTable = (name, value) => {
    const searchFilters = { ...filter };
    if (isEmpty(value)) {
      delete searchFilters[name];
    } else {
      searchFilters[name] = value;
    }
    setFilter(searchFilters);

    handleTableChange(
      {
        ...pagination,
      },
      {
        ...searchFilters,
      },
      {}
    );
  };

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  const prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    console.log("params", params.filters);

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * fetch data table data
   * @param {*} params
   */
  const fetchTableData = (params = {}) => {
    const { getPromos } = props;
    setIsDataLoading(true);
    getPromos({
      data: prepareDataParam(params),
      onSuccess: function (data) {
        setData(data.entity);
        setPagination({
          ...params.pagination,
          total: data.totalCount,
        });
        setIsDataLoading(false);
      },
      onError: function (error) {
        setData([]);
        console.log("error", error);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on page size drop down change
   * @param {*} e  event
   */
  const onPageSizeChange = (e) => {
    handleTableChange(
      {
        //current: pagination.current,
        current: 1,
        pageSize: e.target.value,
        total: pagination.total,
      },
      {},
      {}
    );
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  const handleTableChange = (pagination, filters, sorter) => {
    fetchTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };
  useEffect(() => {
    fetchTableData({ pagination });
  }, []);

  return (
    <>
      <Row className="app-user-list">
        <Loader isShowLoader={isPageLoading} />
        <Col sm="12">
          <Filter
            name="Promocodes"
            className="with_btn"
            showButtons={true}
            buttons={<CreateModal onAddRecord={onAddRecord} />}
          />
        </Col>
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={pagination}
          loading={isDataLoading}
          onChange={handleTableChange}
        ></Table>
        <Col sm="6" className="table_paginaton">
          <div className="ag-grid-actions">
            <div className="sort-dropdown">
              <span className="mr-2">Show</span>
              <div className="d-inline-block">
                <select className="form-control" onChange={onPageSizeChange}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </div>

              <span className="ml-2">Entries</span>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  getPromos,
  deletePromo,
  updatePromo,
  addPromo,
})(PromoListing);
