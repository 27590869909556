import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import {
  getRecurrentDiscounts,
  saveRecurrentDiscount,
} from "../../../../actions/admin/promo-actions";
import { notification } from "../../../../utils/utility";

let initialValues = {
  once_id: "",
  once_rates: "",
  once_discount_type: "P",
  once_status: "D",
  once_label: "",
  bi_weekly_id: "",
  bi_weekly_rates: "",
  bi_weekly_discount_type: "P",
  bi_weekly_status: "D",
  bi_weekly_label: "",
  weekly_id: "",
  weekly_rates: "",
  weekly_discount_type: "P",
  weekly_status: "D",
  weekly_label: "",
  monthly_id: "",
  monthly_rates: "",
  monthly_discount_type: "P",
  monthly_status: "D",
  monthly_label: "",
};

const RecurrentDiscount = (props) => {
  const [formValues, setFormValues] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    const { getRecurrentDiscounts } = props;

    setPageLoading(true);

    getRecurrentDiscounts({
      data: {},
      onSuccess: function (result) {
        const { data } = result;
        console.log("details", data);

        if (data.length) {
          setFormValues({
            once_id: data.Once.id,
            once_rates: data.Once.rates,
            once_discount_type: data.Once.discount_type,
            once_status: data.Once.status,
            once_label: data.Once.label,
            bi_weekly_id: data["Bi-Weekly"].id,
            bi_weekly_rates: data["Bi-Weekly"].rates,
            bi_weekly_discount_type: data["Bi-Weekly"].discount_type,
            bi_weekly_status: data["Bi-Weekly"].status,
            bi_weekly_label: data["Bi-Weekly"].label,
            weekly_id: data.Weekly.id,
            weekly_rates: data.Weekly.rates,
            weekly_discount_type: data.Weekly.discount_type,
            weekly_status: data.Weekly.status,
            weekly_label: data.Weekly.label,
            monthly_id: data.Monthly.id,
            monthly_rates: data.Monthly.rates,
            monthly_discount_type: data.Monthly.discount_type,
            monthly_status: data.Monthly.status,
            monthly_label: data.Monthly.label,
          });
        }

        setPageLoading(false);
      },
      onError: function (error) {
        setPageLoading(false);
      },
    });
  }, []);

  return (
    <div className="rd_vacationfilterpart rd_vacationfilterpart3">
      <Loader isShowLoader={pageLoading} />
      <Formik
        //validationSchema={validation}
        initialValues={formValues || initialValues}
        enableReinitialize
        onSubmit={(data) => {
          const { saveRecurrentDiscount } = props;

          const formData = {
            once: {
              id: data.once_id,
              discount_type_name: "Once",
              rates: data.once_rates,
              discount_type: data.once_discount_type,
              status: data.once_status,
              label: data.once_label,
            },
            bi_weekly: {
              id: data.bi_weekly_id,
              discount_type_name: "Bi-Weekly",
              rates: data.bi_weekly_rates,
              discount_type: data.bi_weekly_discount_type,
              status: data.bi_weekly_status,
              label: data.bi_weekly_label,
            },
            weekly: {
              id: data.weekly_id,
              discount_type_name: "Weekly",
              rates: data.weekly_rates,
              discount_type: data.weekly_discount_type,
              status: data.weekly_status,
              label: data.weekly_label,
            },
            monthly: {
              id: data.monthly_id,
              discount_type_name: "Monthly",
              rates: data.monthly_rates,
              discount_type: data.monthly_discount_type,
              status: data.monthly_status,
              label: data.monthly_label,
            },
          };

          setPageLoading(true);

          saveRecurrentDiscount({
            data: {
              ...formData,
            },
            onSuccess: (response) => {
              if (response.success) {
                notification.success(response.message);
              }
              setPageLoading(false);
              console.log("response", response);
            },
            onError: (error) => {
              notification.error(
                "An error occured during save, Please try later"
              );
              setPageLoading(false);
              console.log("company error ", error);
            },
          });
        }}
      >
        {(formProps) => {
          const { values, errors, touched, handleChange, setFieldValue } =
            formProps;

          return (
            <>
              <Form style={{ width: "100%" }}>
                <div className="rd_profilerd_erpart rd_profilerd_erpartpromo">
                  <div className="rd_coupenthingitewm">
                    <div className="rd_activeprdesa">
                      <div className="box_content">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked4"
                            checked={values.once_status === "E" ? true : false}
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "once_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            for="flexSwitchCheckChecked4"
                          >
                            Once
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="rd_conpbtpartth">
                      <div className="rd_conpbtpartthitem">
                        <label className="form-label">Discount Label</label>
                        <div className="rd_profilethingco">
                          <input
                            type="text"
                            name=""
                            id=""
                            className="rd_adddayofinput"
                            placeholder="Discount Label"
                            value={values.once_label}
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "once_label",
                                  value: event.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="rd_conpbtpartthitem">
                      <label className="form-label">Discount Value</label>
                        <div className="rd_profilethingco rd_cutomstyleinputthin">
                          <div className="input-group">
                            <div className="input-group-prepend rd_dropdownbtn">
                              <select
                                className="form-select"
                                name=""
                                id=""
                                onChange={(event) => {
                                  handleChange({
                                    target: {
                                      name: "once_discount_type",
                                      value: event.target.value,
                                    },
                                  });
                                }}
                              >
                                <option
                                  value="P"
                                  selected={values.once_discount_type === "P"}
                                >
                                  %
                                </option>
                                <option
                                  value="F"
                                  selected={values.once_discount_type === "F"}
                                >
                                  $
                                </option>
                              </select>
                            </div>
                            <input
                              type="text"
                              className="form-control noshadfoc pl-3"
                              id="inlineFormInputGroupUsername"
                              placeholder="05620554"
                              value={values.once_rates}
                              onChange={(event) => {
                                handleChange({
                                  target: {
                                    name: "once_rates",
                                    value: event.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="rd_coupenthingitewm">
                    <div className="rd_activeprdesa">
                      <div className="box_content">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked4"
                            checked={
                              values.bi_weekly_status === "E" ? true : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "bi_weekly_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            for="flexSwitchCheckChecked4"
                          >
                            Bi-Weekly
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="rd_conpbtpartth">
                      <div className="rd_conpbtpartthitem">
                      <label className="form-label">Discount Label</label>
                        <div className="rd_profilethingco">
                          <input
                            type="text"
                            name=""
                            id=""
                            className="rd_adddayofinput"
                            placeholder="Discount Label"
                            value={values.bi_weekly_label}
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "bi_weekly_label",
                                  value: event.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="rd_conpbtpartthitem">
                      <label className="form-label">Discount Value</label>
                        <div className="rd_profilethingco rd_cutomstyleinputthin">
                          <div className="input-group">
                            <div className="input-group-prepend rd_dropdownbtn">
                              <select
                                className="form-select"
                                name=""
                                id=""
                                onChange={(event) => {
                                  handleChange({
                                    target: {
                                      name: "bi_weekly_discount_type",
                                      value: event.target.value,
                                    },
                                  });
                                }}
                              >
                                <option
                                  value="P"
                                  selected={
                                    values.bi_weekly_discount_type === "P"
                                  }
                                >
                                  %
                                </option>
                                <option
                                  value="F"
                                  selected={
                                    values.bi_weekly_discount_type === "F"
                                  }
                                >
                                  $
                                </option>
                              </select>
                            </div>
                            <input
                              type="text"
                              className="form-control noshadfoc pl-3"
                              id="inlineFormInputGroupUsername"
                              placeholder="05620554"
                              value={values.bi_weekly_rates}
                              onChange={(event) => {
                                handleChange({
                                  target: {
                                    name: "bi_weekly_rates",
                                    value: event.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="rd_coupenthingitewm">
                    <div className="rd_activeprdesa">
                      <div className="box_content">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked4"
                            checked={
                              values.weekly_status === "E" ? true : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "weekly_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            for="flexSwitchCheckChecked4"
                          >
                            Weekly
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="rd_conpbtpartth">
                      <div className="rd_conpbtpartthitem">
                      <label className="form-label">Discount Label</label>
                        <div className="rd_profilethingco">
                          <input
                            type="text"
                            name=""
                            id=""
                            className="rd_adddayofinput"
                            placeholder="Discount Label"
                            value={values.weekly_label}
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "weekly_label",
                                  value: event.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="rd_conpbtpartthitem">
                      <label className="form-label">Discount Value</label>
                        <div className="rd_profilethingco rd_cutomstyleinputthin">
                          <div className="input-group">
                            <div className="input-group-prepend rd_dropdownbtn">
                              <select
                                className="form-select"
                                name=""
                                id=""
                                onChange={(event) => {
                                  handleChange({
                                    target: {
                                      name: "weekly_discount_type",
                                      value: event.target.value,
                                    },
                                  });
                                }}
                              >
                                <option
                                  value="P"
                                  selected={values.weekly_discount_type === "P"}
                                >
                                  %
                                </option>
                                <option
                                  value="F"
                                  selected={values.weekly_discount_type === "F"}
                                >
                                  $
                                </option>
                              </select>
                            </div>
                            <input
                              type="text"
                              className="form-control noshadfoc pl-3"
                              id="inlineFormInputGroupUsername"
                              placeholder="05620554"
                              value={values.weekly_rates}
                              onChange={(event) => {
                                handleChange({
                                  target: {
                                    name: "weekly_rates",
                                    value: event.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="rd_coupenthingitewm">
                    <div className="rd_activeprdesa">
                      <div className="box_content">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked4"
                            checked={
                              values.monthly_status === "E" ? true : false
                            }
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "monthly_status",
                                  value: event.target.checked ? "E" : "D",
                                },
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            for="flexSwitchCheckChecked4"
                          >
                            Monthly
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="rd_conpbtpartth">
                      <div className="rd_conpbtpartthitem">
                      <label className="form-label">Discount Label</label>
                        <div className="rd_profilethingco">
                          <input
                            type="text"
                            name=""
                            id=""
                            className="rd_adddayofinput"
                            placeholder="Discount Label"
                            value={values.monthly_label}
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: "monthly_label",
                                  value: event.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="rd_conpbtpartthitem">
                      <label className="form-label">Discount Value</label>
                        <div className="rd_profilethingco rd_cutomstyleinputthin">
                          <div className="input-group">
                            <div className="input-group-prepend rd_dropdownbtn">
                              <select
                                className="form-select"
                                name=""
                                id=""
                                onChange={(event) => {
                                  handleChange({
                                    target: {
                                      name: "monthly_discount_type",
                                      value: event.target.value,
                                    },
                                  });
                                }}
                              >
                                <option
                                  value="P"
                                  selected={
                                    values.monthly_discount_type === "P"
                                  }
                                >
                                  %
                                </option>
                                <option
                                  value="F"
                                  selected={
                                    values.monthly_discount_type === "F"
                                  }
                                >
                                  $
                                </option>
                              </select>
                            </div>
                            <input
                              type="text"
                              className="form-control noshadfoc pl-3"
                              id="inlineFormInputGroupUsername"
                              placeholder="05620554"
                              value={values.monthly_rates}
                              onChange={(event) => {
                                handleChange({
                                  target: {
                                    name: "monthly_rates",
                                    value: event.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rd_svaebtn">
                  <button type="submit"> Save Changes</button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default connect(null, {
  getRecurrentDiscounts,
  saveRecurrentDiscount,
})(RecurrentDiscount);
