import axios from "axios";
import { INIT_CALENDAR, SET_CALENDAR_VIEW, SET_CALENDAR_EVENTS } from "./types";

// ########################## Calendar Plugin ########################## //
export const initCalendar = (props) => {
  return (dispatch) => dispatch({ type: INIT_CALENDAR, payload: props });
};

/**
 * Set calendar events
 * @param {*} events
 * @returns
 */
export const setCalendarEvents = (events) => (dispatch) => {
  dispatch({ type: SET_CALENDAR_EVENTS, payload: events });
};

/**
 * set calendar view i-e month, week, day
 * @param {*} selectedView
 * @returns
 */
export const setCalendarView = (selectedView) => (dispatch) => {
  dispatch({ type: SET_CALENDAR_VIEW, payload: selectedView });
};

// ########################## Calendar Plugin ########################## //

export const fetchEvents = () => {
  return async (dispatch) => {
    await axios
      .get("/api/apps/calendar/events")
      .then((response) => {
        dispatch({ type: "FETCH_EVENTS", events: response.data });
      })
      .catch((err) => console.log(err));
  };
};

export const handleSidebar = (bool) => {
  return (dispatch) => dispatch({ type: "HANDLE_SIDEBAR", status: bool });
};

export const addEvent = (event) => {
  return (dispatch) => {
    dispatch({ type: "ADD_EVENT", event });
  };
};
export const updateEvent = (event) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_EVENT", event });
  };
};

export const updateDrag = (event) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_DRAG", event });
  };
};

export const updateResize = (event) => {
  return (dispatch) => {
    dispatch({ type: "EVENT_RESIZE", event });
  };
};

export const handleSelectedEvent = (event) => {
  return (dispatch) => dispatch({ type: "HANDLE_SELECTED_EVENT", event });
};
