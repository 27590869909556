import React, { useState, useEffect } from "react";
import { getAdminReferrals } from "../../../../actions/adminRefferalActions";
import { connect } from "react-redux";
import { Table } from "antd";

import "antd/dist/antd.css";
import Filter from "../../../../components/Filter/Filter";
import { Col, Row } from "reactstrap";
import isEmpty from "../../../../validation/is-empty";
import Loader from "../../../../components/Loader/Loader";
import { toggleFilter } from "../../../../actions/filterActions";
import PaginatorDropdown from "../../../../components/Datatable/PaginationDropdown";
import InviteUserModal from "./invite-user-modal";
import { inviteUser } from "../../../../actions/admin/referral-actions";
import { notification } from "../../../../utils/utility";
import ReferralListFilters from "./referral-list-filters";

const AdminRefferalList = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  });

  const [data, setData] = useState([]);

  const columns = [
    {
      title: "Referral type",
      dataIndex: "referral_type",
      sorter: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "Invitee",
      dataIndex: "invite_name",
      sorter: true,
    },
    {
      title: "Invitee Email",
      dataIndex: "invite_email",
      sorter: true,
    },
    {
      title: "Sent On",
      dataIndex: "sent_on",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: false,
    },
    {
      title: "Earn",
      dataIndex: "earn",
      sorter: false,
    },
  ];

  /**
   * on filter data table
   * @param {*} name
   * @param {*} value
   */
  const filterRecords = () => {
    const { toggleFilter, filter } = props;
    toggleFilter(false);
    handleTableChange(
      {
        ...pagination,
      },
      {
        ...filter.searchFilters,
      },
      {}
    );
  };

  /**
   * on clear filter handler
   */
  const clearFilterHandler = () => {
    handleTableChange(
      {
        ...pagination,
      },
      {},
      {}
    );
  };

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  const prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * fetch data table data
   * @param {*} params
   */
  const fetchTableData = (params = {}) => {
    const { getAdminReferrals } = props;
    setIsDataLoading(true);
    getAdminReferrals({
      data: prepareDataParam(params),
      onSuccess: function (data) {
        setData(data.entity);
        setPagination({
          ...params.pagination,
          total: data.totalCount,
        });
        setIsDataLoading(false);
      },
      onError: function (error) {
        setData([]);

        setIsDataLoading(false);
      },
    });
  };

  /**
   * on page size drop down change
   * @param {*} e  event
   */
  const onPageSizeChange = (e) => {
    handleTableChange(
      {
        current: 1,
        pageSize: e.target.value,
        total: pagination.total,
      },
      {},
      {}
    );
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  const handleTableChange = (pagination, filters, sorter) => {
    fetchTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  useEffect(() => {
    fetchTableData({ pagination });
  }, []);

  /**
   * on invitee staff
   * @param {*} data
   */
  const onInviteUserHandler = (data) => {
    const { inviteUser } = props;

    console.log("i am called");
    setIsPageLoading(true);

    inviteUser({
      data: {
        ...data,
      },
      onSuccess: function (details) {
        setIsPageLoading(false);
        notification.success("User Invited successfully");
        fetchTableData({ pagination });
      },
      onError: function (error) {
        notification.error(
          error ? error : "An error occured during invitation, please try later"
        );
        setIsPageLoading(false);
      },
    });
  };

  return (
    <>
      <Row className="app-user-list">
        <Loader isShowLoader={isPageLoading} />
        <Col sm="12">
          <Filter
            tooltipCode="P18F1"
            name="Admin Refferals"
            className="with_btn"
            onFilterRecords={filterRecords}
            onClearFilterHandler={clearFilterHandler}
            showButtons={true}
            buttons={<InviteUserModal onInviteUser={onInviteUserHandler} />}
          >
            <ReferralListFilters />
          </Filter>
        </Col>
        <Col sm="12">
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={pagination}
            loading={isDataLoading}
            onChange={handleTableChange}
          ></Table>
        </Col>
        <Col sm="6" className="table_paginaton">
          <PaginatorDropdown
            onPageSizeChange={onPageSizeChange}
            pageSize={pagination.pageSize}
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  getAdminReferrals,
  toggleFilter,
  inviteUser,
})(AdminRefferalList);
