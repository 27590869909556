import React from "react";
import { Modal, ModalBody } from "reactstrap";

const SetAsDefaultModal = (props) => {
  const { showSetAsDefaultModal, hideSetAsDefaultModal } = props;

  return (
    <>
      <Modal isOpen={showSetAsDefaultModal} toggle={hideSetAsDefaultModal}>
        <ModalBody>
          <div className="">
            <div className="rd_modalvieew">
              <div className="box_content rd_modalboxcontent">
                <div className="rd_inputselectheadermodalsetting">
                  <button
                    className="rd_colsebtn"
                    onClick={hideSetAsDefaultModal}
                  ></button>
                </div>

                <h5 className="rd_modalheadertext rd_modalheadertextnm">
                  Confirm
                </h5>

                <p className="rd_modalheadertextparath mt-5 mb-5">
                  Are you sure, you want to make selected schedule as default ?
                </p>

                <div className="rd_cancelappbtnmodal">
                  <button className="rd_cancel" onClick={hideSetAsDefaultModal}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-app"
                    type="submit"
                    onClick={() => {
                      hideSetAsDefaultModal();
                      props.setAsDefaultHandle(props.scheduleId);
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SetAsDefaultModal;
