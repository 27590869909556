import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "../../../components/Loader/Loader";
import currentStep from "../../../assets/images/current-step.png";
import unSelectedRadio from "../../../assets/images/unselected-radio.png";
import fillStep from "../../../assets/images/fill.png";
import { Row, Col, Button } from "reactstrap";
import axiosInstance from "../../../axios-instance";
import checkbox from "../../../assets/icons/checkboxblack.svg";
import checkboxOutline from "../../../assets/icons/checkbox-outline.svg";
import {
  getStepThreeData,
  saveStepThreeData,
} from "../../../actions/admin/quick-setup-actions";
import { connect } from "react-redux";
import isEmpty from "../../../validation/is-empty";
import { getAdminBusinessId } from "../../../utils/authHelper";
import { notification } from "../../../utils/utility";
import { useNavigate } from "react-router-dom";
import { FormGroup, Input, Label } from "reactstrap";
import { getUserLandingPage } from "../../../utils/authHelper";

const validation = Yup.object().shape({
  role: Yup.mixed().required("Role is required"),
  event_types: Yup.mixed().required("Event Types is required"),
});

let initialValues = {
  role: "",
  other_role: "",
  event_types: [],
  other_event_type: "",
};

const QuickSetupStepThree = (props) => {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [profession, setProfession] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);

  useEffect(() => {
    setPageLoading(true);
    axiosInstance
      .get("/profession-types")
      .then((response) => {
        setProfession(response.data.data.profession_types);
        getEventTypes();
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getEventTypes = () => {
    axiosInstance
      .get("/event-types")
      .then((response) => {
        setEventTypes(response.data.data.eventTypes);
        getData();
      })
      .catch((error) => {
        setPageLoading(false);
        console.error(error);
      });
  };

  /**
   * On Go back event
   */
  const onGoBack = () => {
    navigate(-1);
  };

  const getData = () => {
    const { getStepThreeData } = props;

    getStepThreeData({
      onSuccess: function (response) {
        const { data } = response;

        if (!isEmpty(data)) {
          let adminEventTypes = [];
          let otherEventType = "";
          if (!isEmpty(data.event_types)) {
            const dbEventTypes = data.event_types.split(",");
            dbEventTypes.forEach((eventType) => {
              if (!isNaN(eventType)) {
                adminEventTypes.push(eventType);
              } else {
                adminEventTypes.push("9"); // Other event type
                otherEventType = eventType;
              }
            });
          }

          setFormValues({
            ...initialValues,
            role: isNaN(data.role) ? "7" : data.role,
            event_types:
              adminEventTypes.length > 0
                ? adminEventTypes
                : initialValues.event_types,
            other_event_type:
              otherEventType !== ""
                ? otherEventType
                : initialValues.other_event_type,
            other_role: isNaN(data.role) ? data.role : initialValues.other_role,
          });
        }

        setPageLoading(false);
      },
      onError: function (error) {
        setPageLoading(false);
      },
    });
  };

  return (
    <section className="profile-step-three">
      <Loader isShowLoader={pageLoading} />

      <div className="profile-step w-100 mt-0 mb-0 pt-3 profile-step-3">
        <div className="step-1 w-100">
          <div
            className="by-step pt-1"
            style={{ backgroundPositionY: "16px", backgroundSize: "95%" }}
          >
            <div className="step">
              <img src={fillStep} alt="" />
              <p>Step 1</p>
            </div>
            <div className="step">
              <img src={fillStep} alt="" />
              <p>Step 2</p>
            </div>
            <div className="step">
              <img src={currentStep} alt="" />
              <p className="current-step">Step 3</p>
            </div>
          </div>
        </div>
        {/* </div>
      <div className="rd_vacationfilterpart rd_vacationfilterpart3"> */}
        <Formik
          validationSchema={validation}
          initialValues={formValues || initialValues}
          enableReinitialize
          onSubmit={(values) => {
            // check if others role is checked
            if (values.role === "7") {
              values.role = values.other_role;
            }

            // check if other event type is checked
            if (values.event_types.includes(String(9))) {
              values.event_types.push(values.other_event_type);
            }
            const filteredEventType = values.event_types.filter(function (
              item
            ) {
              return item !== "9";
            });
            values.event_types = filteredEventType;

            values.business_id = getAdminBusinessId();
            setPageLoading(true);

            const { saveStepThreeData } = props;

            saveStepThreeData({
              data: {
                ...values,
                event_types: values.event_types.join(","),
              },
              onSuccess: (response) => {
                if (response.success) {
                  notification.success("Record saved successfully");
                  navigate(getUserLandingPage());
                }
                setPageLoading(false);
              },
              onError: (error) => {
                notification.error(
                  "An error occured during save, Please try later"
                );
                setPageLoading(false);
                console.log("company error ", error);
              },
            });
          }}
        >
          {(formProps) => {
            const {
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
            } = formProps;

            return (
              <>
                <Form>
                  <Row className="p-4">
                    <div className="form-group">
                      <label className="same-label" htmlFor="role">
                        What’s Your Role In Your Company?
                      </label>
                      <div className="form-checks">
                        {profession.map((item) => (
                          <div className="form-check">
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-check-input"
                              checked={item.id === parseInt(values.role)}
                              value={item.id}
                              type="radio"
                              name="role"
                              id={item.id}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={item.id}
                            >
                              {item.profession}
                            </label>
                          </div>
                        ))}
                      </div>
                      {values.role == "7" && (
                        <>
                          <input
                            type="text"
                            className="form-control"
                            name="other_role"
                            id="other_role"
                            value={values.other_role}
                            placeholder="Enter Role"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name="other_role"
                            render={(error) => (
                              <span className="error">{error}</span>
                            )}
                          />
                        </>
                      )}
                    </div>
                    <div className="form-group">
                      <div className="same-label">
                        What Type Of Event Will You Be Using Meetocto Mainly?
                      </div>
                      <div className="flex events">
                        {eventTypes.map((item) => (
                          <FormGroup check>
                            <Input
                              type="checkbox"
                              name="event_types"
                              value={item.id}
                              checked={values.event_types.includes(
                                String(item.id)
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />{" "}
                            <Label className="tag-button-label" check>
                              {item.event_type}
                            </Label>
                          </FormGroup>
                        ))}
                        {/* <label className="tag-button-label">
                            <input
                              name="event_types"
                              value={item.id}
                              checked={values.event_types.includes(
                                String(item.id)
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="checkbox"
                            />
                            <div className="tag-button">
                              <div className="tag-icon checked">
                                <img src={checkbox} />
                              </div>
                              <div className="tag-icon unchecked">
                                <img src={checkboxOutline} />
                              </div>
                              <div className="tag-text flex">
                                {item.event_type}
                              </div>
                            </div>
                          </label> */}
                        {/* <label className="tag-button-label">
                          <input
                            name="event_types"
                            value={9}
                            checked={values.event_types.includes(String(9))}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="checkbox"
                          />
                          <div className="tag-button">
                            <div className="tag-icon checked">
                              <img src={checkbox} />
                            </div>
                            <div className="tag-icon unchecked">
                              <img src={checkboxOutline} />
                            </div>
                            <div className="tag-text flex">Others, Specify</div>
                          </div>
                        </label> */}
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            name="event_types"
                            value={9}
                            checked={values.event_types.includes(String(9))}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />{" "}
                          <Label className="tag-button-label" check>
                            Others, Specify
                          </Label>
                        </FormGroup>
                      </div>
                      {values.event_types.includes(String(9)) && (
                        <>
                          <input
                            type="text"
                            className="form-control"
                            name="other_event_type"
                            value={values.other_event_type}
                            id="other_event_type"
                            placeholder="Enter Event type"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name="event_types"
                            render={(error) => (
                              <span className="error">{error}</span>
                            )}
                          />
                        </>
                      )}
                    </div>
                  </Row>

                  <Row className="mb-4 p-4">
                    <Col md={6} lg={6}>
                      &nbsp;
                    </Col>

                    <Col md={3} lg={3}>
                      <Button
                        className="btn btn-outline"
                        type="button"
                        disabled={pageLoading}
                        onClick={onGoBack}
                      >
                        Back
                      </Button>
                    </Col>

                    <Col md="3" lg="3" className="mt-4 mt-md-0">
                      <Button
                        type="submit"
                        className="btn btn-app"
                        disabled={pageLoading}
                      >
                        Finish
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

export default connect(null, {
  getStepThreeData,
  saveStepThreeData,
})(QuickSetupStepThree);
