import React, { useState, useEffect, Fragment, useRef } from "react";
import Calendar from "react-calendar";
import { connect } from "react-redux";
import { 
    Row, 
    Col, 
    Label, 
    FormGroup, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
} from "reactstrap";
import moment from "moment";
import * as Icon from "react-feather";
import { getAdminBusinessId } from "../../utils/authHelper";
import { getLoggedInUserTimezone, FRONTEND_BASE_URL } from "../../utils/utility";
import { getAvailableTimeSlots } from "../../actions/bookingAction";
import { sendPostRequest } from "../../actions/app-actions";
import isEmpty from "../../validation/is-empty";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "../../components/FormField/FormField";
import ToolTip from "./Components/Common/ToolTip";
import ClockIcon from "../../assets/icons/Clock.svg";
import PlannerIcon from "../../assets/icons/Planner.svg";
import IntervalField from "./Components/Common/IntervalField";
import TimeSelectorField from "./Components/Common/TimeSelectorField";
import { notification } from "../../utils/utility";
import { getVenues } from "../../actions/eventActions";
import Loader from "../../components/Loader/Loader";
import mail from "../../assets/icons/email.jpeg";
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from "react-router-dom";

const validation = Yup.object().shape({
  event_title: Yup.string().required("Event name is required"),
  venue_id: Yup.string().required("Venue is required."),
});

let initialValues = {
  event_title: "",
  venue_id: "",
  link_expiration_period: 0,
  min_schedule_notice_in_minutes: 0,
};

const AddAdhoc = (props) => {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [slots, setSlots] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [step, setStep] = useState("1");
  const [duration, setDuration] = useState(30);
  const [viewDetail, setViewDetail] = useState(false);
  const [venues, setVenues] = useState([]);
  const [showAdhocSavedModal, setShowAdhocSavedModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);


  const eventUrl = useRef(null);
  const eventTitle = useRef(null);


  const nextStep = () => {
    if (isEmpty(selectedSlots)) {
      return notification.error("Please Select atleast one Slot");
    }
    setStep("2");
  };

  const prevStep = () => {
    setStep("1");
  };

  useEffect(() => {
    getMonthAvailableDates(moment().month() + 1, moment().year());

    const { getVenues } = props;
    getVenues({
      onSuccess: function (result) {
        setVenues(result);
      },
      onError: function (error) {
        console.error(error);
      },
    });
  }, []);

  /**
   * on go to previous month button handler
   * @param {*} event
   */
  const onGotoPrevMonth = (event) => {
    event.preventDefault();
    const dated = moment(calendarDate).subtract(1, "M");
    setCalendarDate(dated.toDate());
    getMonthAvailableDates(dated.format("M"), dated.format("YYYY"));
  };

  /**
   * on go to next month button handler
   */
  const onGotoNextMonth = (event) => {
    event.preventDefault();
    const dated = moment(calendarDate).add(1, "M");
    setCalendarDate(dated.toDate());
    getMonthAvailableDates(dated.format("M"), dated.format("YYYY"));
  };

  /**
   * Get dates of month
   * @param int month
   * @param int year
   * @returns array
   */
  const getMonthAvailableDates = (month, year) => {
    const { sendPostRequest } = props;
    setIsLoading(true);
    let daysInMonth = moment(year + "-" + month, "YYYY-M").daysInMonth();
    let monthDates = [];
    while (daysInMonth) {
      monthDates.push(
        moment(year + "-" + month + "-" + daysInMonth, "YYYY-M-D").format(
          "YYYY-M-D"
        )
      );
      daysInMonth--;
    }
    sendPostRequest({
      url: `/admin/get-adhoc-available-dates`,
      data: {
        dates: monthDates.reverse(),
        duration: duration,
        timezone: getLoggedInUserTimezone(),
        business_id: getAdminBusinessId(),
      },
      onSuccess: function (result) {
        const { data } = result.data;
        setAvailableDates(data);
        setIsLoading(false);
      },
      onError: function (error) {
        setIsLoading(false);
      },
    });
  };

  const transformValue = (options, key) => {
    let values = [];
    if ((options || []).length > 0) {
      values = (options || []).map((option) => {
        let { name, id } = option || {};
        if (key === "venues") {
          const { venue } = option || {};
          name = venue;
        }

        return {
          ...option,
          label: name,
          value: id,
        };
      });
    }

    return values;
  };

  /**
   * check if slot is select or not
   * @param {*} slot
   * @returns Boolean
   */
  const isSelectedSlot = (slot) => {
    if (selectedSlots[selectedDate] !== undefined) {
      const selectedDateSlots = selectedSlots[selectedDate];
      return selectedDateSlots.some((el) => el === slot);
    }
    return false;
  };

  /**
   * on select time slot
   * @param {*} slot
   */
  const onSelectTimeSlot = (slot) => {
    const processSelectedSlots = { ...selectedSlots };
    if (processSelectedSlots[selectedDate] !== undefined) {
      const selectedDateSlots = [...processSelectedSlots[selectedDate]];
      const found = selectedDateSlots.some((el) => el === slot);
      if (found) {
        const processedSlots = selectedDateSlots.filter((el) => {
          return el !== slot;
        });
        processSelectedSlots[selectedDate] = processedSlots;
        setSelectedSlots(processSelectedSlots);
      } else {
        selectedDateSlots.push(slot);
        processSelectedSlots[selectedDate] = selectedDateSlots;
        setSelectedSlots(processSelectedSlots);
      }
    } else {
      processSelectedSlots[selectedDate] = [slot];
      setSelectedSlots(processSelectedSlots);
    }
    console.log("selectedSlots", processSelectedSlots);
  };

  /**
   * on calendar date change
   * @param {*} date
   */
  const onCalendarDateChange = (date) => {
    const { getAvailableTimeSlots } = props;
    setCalendarDate(date);
    setSelectedDate(moment(date).format("yyyy-MM-DD"));
    getAvailableTimeSlots({
      data: {
        selected_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        staff_id: "",
        business_id: getAdminBusinessId(),
        event_id: "",
        duration: duration,
        timezone: getLoggedInUserTimezone(),
      },
      onSuccess: function (response) {
        console.log("response", response);
        setSlots(response);
      },
      onError: (error) => {
        //notification.error(error.response.data.message);
      },
    });
  };


  /**
   * on copy event link
   * @param string event url
   * @param string copy field element id
   */
  const onCopyEventLink = (adhocEventURL, copyFieldElementId) => {
    if (!isCopied) {
        var copyText = document.getElementById(copyFieldElementId);
        copyText.disabled = false;
        copyText.select();
        document.execCommand("copy");
        copyText.disabled = true;
        window.getSelection().removeAllRanges();
        notification.success(`Event URL Copied ${FRONTEND_BASE_URL}adhoc-booking/${adhocEventURL}`);
        setIsCopied(true);
    }
  }

  /**
   * 
   */
  const toggleModal = () => {
    setShowAdhocSavedModal(false);
    navigate('/admin/scheduled-events?page=pending');
  }

  const onCopyAddTimesToEmail = () => {
    var tempInput = document.createElement("textarea");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    let content = "";
    content = (
        <table widht="344" style={{width: '344px', maxWidth: '344px', fontFamily: "Sofia Pro, sans-serif", fontStyle: 'normal', fontWeight: 'normal', 'fontSize': '16px', backgroundColor: 'white'}}>
          <tbody>
            <tr>
              <td>
                <div style={{paddingBottom: '3px', fontFamily: "Sofia Pro, sans-serif", fontSize: '13px', color: '#0C4767', backgroundColor: 'white', lineHeight: 1.4, width: '344px'}}>
                  <div style={{
                    fontFamily: "Sofia Pro, sans-serif", 
                    fontStyle: 'normal', 
                    fontWeight: 'bold', 
                    fontSize: '16px', 
                    lineHeight: '19px', 
                    color: '#0C4767', 
                    marginTop: '15px'}}
                  >
                    {eventTitle.current}
                  </div>
  
                  <div style={{
                    fontFamily: "Sofia Pro, sans-serif", 
                    fontStyle: 'normal', 
                    fontWeight: 'normal', 
                    fontSize: '14px', 
                    lineHeight: '17px', 
                    color: '#fc9834'
                    }}>
                      {duration} mins
                  </div>
  
                  {/* <div>
                    <b style={{color: '#0C4767'}}></b>
                    <span style={{
                      fontFamily: "Sofia Pro, sans-serif", 
                      fontStyle: 'normal', 
                      fontWeight: 'normal', 
                      fontSize: '14px', 
                      lineHeight: '17px', 
                      color: '#fc9834'
                    }}>
                      Time zone: Pakistan, Maldives Time{" "} 
                    </span>
                    <a href={`${FRONTEND_BASE_URL}booking/${event_url}`} style={{marginLeft: 3, color: '#0C4767', }}> Change </a>
                  </div> */}
                  {console.log('selected slots', selectedSlots)}
  
                  {
                    Object.keys(selectedSlots).map((key, index) => {
                      return (
                        <table style={{
                          marginTop: '16px', 
                          textAlign: 'left', 
                          borderCollapse: 'collapse', 
                          borderSpacing: 0, 
                          width: '362px'}}
                          key={`dated-${index}`}
                        >
                          <tbody>
                            <tr>
                              <td style={{textAlign: 'left', marginTop: '16px'}}>
                                <span style={{
                                  fontFamily: "Sofia Pro, sans-serif", 
                                  fontStyle: 'normal', 
                                  fontWeight: 'bold', 
                                  fontSize: '14px', 
                                  lineHeight: '16px', 
                                  color: '#0C4767', 
                                  paddingBottom: 8, 
                                }}>
                                  {moment(key, "YYYY-MM-DD").format("dddd, MMMM, DD YYYY")}
                                </span>
                              </td>
                            </tr>
                            {console.log(key, selectedSlots[`${key}`])}    
                            <tr>
                              <td>
                                <table style={{borderCollapse: 'separate', borderSpacing: '0px 4px'}}>
                                  <tbody>
                                    <tr style={{height: '25px'}}>
                                      {
                                        (selectedSlots[`${key}`] || []).map((slot, innerIndex) => {
                                          return (
                                            <td style={{padding: 0, width: 64, display: 'inline-block', marginRight: 4, height: 22, float: 'left', border: '1px solid #fc9834', borderRadius: 3}}>
                                              <table style={{height: 21}}>
                                                <tbody>
                                                  <tr style={{height: 21 }}>
                                                    <td style={{width: 7}}></td>
                                                    <td style={{width: 50, textAlign: 'center'}}>
                                                      <a href={`${FRONTEND_BASE_URL}adhoc-booking/${eventUrl.current}/${key}/${slot}`} className="spot" style={{fontFamily: "Sofia Pro, sans-serif", textDecoration: 'none', textAlign: 'center', color: '#fc9834', fontSize: 12, lineHeight: 1.6}}>
                                                          <b style={{fontWeight: 'normal', textDecoration: 'none'}}>{slot}</b>
                                                      </a>
                                                    </td>
                                                    <td style={{height: 7}}></td>
                                                  </tr>
                                                </tbody>
                                            </table>
                                            </td>
  
                                          );
                                        })
                                      }
  
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>  
                                                      
  
                          </tbody>
  
                        </table>
                        
                      );
                    })
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      );
      document.getElementById("copy-element").innerHTML = renderToStaticMarkup(content);
        const str = document.getElementById("copy-element").innerHTML;

        document.addEventListener("copy", (e) => {
            e.clipboardData.setData("text/html", str);
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        });
        document.execCommand("copy");
        document.removeEventListener("copy", (e) => {
            e.clipboardData.setData("text/html", str);
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        });

        notification.success(
            "Slots copied successfully"
        );
  }

  return (
    <>
      <Loader isShowLoader={isLoading} />
      <Formik
        validationSchema={validation}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => {
          const { sendPostRequest } = props;
          setIsLoading(true);
          sendPostRequest({
            url: `/admin/create-adhoc-event`,
            data: {
              ...values,
              selected_slots: selectedSlots,
              duration: duration,
              business_id: getAdminBusinessId(),
              timezone: getLoggedInUserTimezone(),
            },
            onSuccess: function (result) {
              const { data } = result.data;
              eventUrl.current = data.event_url;
              eventTitle.current = data.title; 
              setShowAdhocSavedModal(true);
              setIsLoading(false);
              notification.success("Event saved successfully!");
            },
            onError: function (error) {
              setIsLoading(false);
            },
          });
        }}
      >
        {(formProps) => {
          const { values, errors, touched, handleChange, setFieldValue } =
            formProps;
          return (
            <Form>
              <div className="create-event-wrapper">
                <div className="create-event-container">
                  <div className="">
                    <div className="event-card-body">
                      <div className="form-group event-form-group">
                        <div className="rd_contenttabsthingmodalcenter">
                          <Row className="justify-content-center">
                            <Col md="12">
                              <div className="filter_bar row justify-content-between align-items-center mx-0 position-relative">
                                <div className="col-md-auto">
                                  <h3>Ad Hoc Meetings</h3>
                                </div>
                                <div className="col-md-auto d-flex mt-4 mt-md-0 adhoc_btns">
                                  {step === "1" ? (
                                    <button
                                      type="button"
                                      className="btn btn-app"
                                      onClick={nextStep}
                                    >
                                      Next
                                    </button>
                                  ) : (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-outline"
                                        onClick={prevStep}
                                      >
                                        Back
                                      </button>
                                      <button
                                        type="submit"
                                        className="btn btn-app"
                                        onClick={() => {}}
                                      >
                                        Publish
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {step === "1" ? (
                            <>
                              <Row className="mb-3">
                                <Col md="12">
                                  <div className="form-group event-form-group">
                                    <label>
                                      Duration (**Options are in Minutes)
                                    </label>
                                    <TimeSelectorField
                                      defaultValue={duration}
                                      inviteeSetDurationStatus="D"
                                      handleFormChange={(value) => {}}
                                      onChange={(value) => {
                                        setDuration(value);
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="8" lg="8">
                                  <div className="cale_container position-relative">
                                    {availableDates.length === 0 && (
                                      <div className="nodate_avail">
                                        <button
                                          className="btn p-0 arrow prev"
                                          onClick={onGotoPrevMonth}
                                        >
                                          <Icon.ChevronLeft size={20} />
                                        </button>
                                        <div className="txt">
                                          <p>No Dates Available</p>
                                          <button
                                            className="btn p-0"
                                            onClick={onGotoNextMonth}
                                          >
                                            Go to next Month
                                          </button>
                                        </div>
                                        <button
                                          className="btn p-0 arrow next"
                                          onClick={onGotoNextMonth}
                                        >
                                          <Icon.ChevronRight size={20} />
                                        </button>
                                      </div>
                                    )}
                                    <Calendar
                                      tileClassName={({ date }) => {
                                        const calendarDate =
                                          date.getFullYear() +
                                          "-" +
                                          (date.getMonth() + 1) +
                                          "-" +
                                          date.getDate();

                                        if (
                                          availableDates.find(
                                            (availableDate) =>
                                              availableDate === calendarDate
                                          )
                                        ) {
                                          return "highlight";
                                        }
                                      }}
                                      minDate={new Date()}
                                      onChange={onCalendarDateChange}
                                      activeStartDate={calendarDate}
                                      className="cus_style"
                                      onClickMonth={(value) => {
                                        const dated = moment(value);
                                        const month = dated.format("M");
                                        const year = dated.format("YYYY");
                                        setCalendarDate(dated.toDate());
                                        getMonthAvailableDates(month, year);
                                      }}
                                      onActiveStartDateChange={({ action }) => {
                                        let dated = null;
                                        if (
                                          action === "next" ||
                                          action === "prev"
                                        ) {
                                          // next month or previous month
                                          dated =
                                            action === "next"
                                              ? moment(calendarDate).add(1, "M")
                                              : moment(calendarDate).subtract(
                                                  1,
                                                  "M"
                                                );
                                        } else if (
                                          action === "next2" ||
                                          action === "prev2"
                                        ) {
                                          // next year or previou year
                                          dated =
                                            action === "next"
                                              ? moment(calendarDate).add(1, "y")
                                              : moment(calendarDate).subtract(
                                                  1,
                                                  "y"
                                                );
                                        }
                                        console.log("action", action);
                                        if (
                                          [
                                            "next",
                                            "prev",
                                            "next2",
                                            "prev2",
                                          ].includes(action)
                                        ) {
                                          setCalendarDate(dated.toDate());
                                          // _this.setState({
                                          //     calendarDate: dated.toDate(),
                                          // });
                                          getMonthAvailableDates(
                                            dated.format("M"),
                                            dated.format("YYYY")
                                          );
                                        }
                                      }}
                                      locale="en-GB"
                                    />
                                  </div>
                                </Col>
                                <Col md="4" lg="4">
                                  {(slots || []).length > 0 ? (
                                    <>
                                      <div className="col-12 px-0">
                                        <div className="add-time-slot-container addTimes">
                                          {(slots || []).map((item, index) => {
                                            return (
                                              <div
                                                key={index}
                                                className={`slot ${
                                                  isSelectedSlot(item)
                                                    ? "time_active"
                                                    : null
                                                }`}
                                                onClick={() =>
                                                  onSelectTimeSlot(item)
                                                }
                                              >
                                                {item}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="col-12 px-0">
                                      <div className="row">
                                        <p className="text-left">
                                          No Slot Available.
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </Col>
                              </Row>

                              {!isEmpty(selectedSlots) && (
                                <div className="rd_emebedparinmodl">
                                  <h5>
                                    <strong>Selected Slots</strong>
                                  </h5>
                                  {Object.keys(selectedSlots).map(
                                    (key, index) => {
                                      if (selectedSlots[`${key}`].length > 0) {
                                        return (
                                          <Fragment key={index}>
                                            <h4 className="mt-3 mb-2">
                                              <span className="rd_archiveiconth"></span>
                                              {moment(key, "YYYY-MM-DD").format(
                                                "dddd, MMMM, DD YYYY"
                                              )}
                                            </h4>
                                            <div className="row add-time-slot-container custom_slot">
                                              <Row>
                                                {(
                                                  selectedSlots[`${key}`] || []
                                                ).map(
                                                  (
                                                    selectedSlot,
                                                    innerIndex
                                                  ) => {
                                                    return (
                                                      <Col md="2" lg="2">
                                                        <div
                                                          key={innerIndex}
                                                          className="slot time_active"
                                                        >
                                                          {selectedSlot}
                                                        </div>
                                                      </Col>
                                                    );
                                                  }
                                                )}
                                              </Row>
                                            </div>
                                          </Fragment>
                                        );
                                      } else {
                                        return null;
                                      }
                                    }
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <Row className="justify-content-center flex-column-reverse flex-lg-row">
                                <Col xxl="6" xl="7" className="mt-5">
                                  <div
                                    className="event-card adhoc_second"
                                    style={{ padding: "25px 35px" }}
                                  >
                                    <Row>
                                      {/* heading */}
                                      <Col md="12">
                                        <h2>Add Event details</h2>
                                      </Col>
                                      {/* Event Name */}
                                      <Col md="12" lg="12">
                                        <FormGroup>
                                          <Label for="venue">
                                            Event Title *
                                          </Label>
                                          <FormField
                                            type="text"
                                            name="event_title"
                                            label="Event name *"
                                            placeholder="An ad hoc event"
                                            showLabel={true}
                                            value={values.event_title}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </FormGroup>
                                      </Col>
                                      {/* Venue */}
                                      <Col md="12">
                                        <FormGroup>
                                          <Label for="venue">Venue *</Label>
                                          <FormField
                                            showLabel
                                            showPlaceholder={true}
                                            placeholder="Select Venue"
                                            type="select"
                                            name="venue_id"
                                            label="Select Venue *"
                                            errors={errors}
                                            touched={touched}
                                            value={values.venue_id}
                                            options={transformValue(
                                              venues,
                                              "venues"
                                            )}
                                            onChange={({ target }) => {
                                              const { name, value } =
                                                target || {};
                                              handleChange({
                                                target: { name, value },
                                              });
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                      {/* Minmum Notice */}
                                      <Col md="12">
                                        <div className="form-group event-group adhoc-form-group">
                                          <label>
                                            Minimum Scheduling Notice *
                                            <ToolTip />
                                          </label>
                                          <IntervalField
                                            onChange={(value) => {
                                              handleChange({
                                                target: {
                                                  name: "min_schedule_notice_in_minutes",
                                                  value,
                                                },
                                              });
                                            }}
                                            label="min"
                                            interval={10}
                                            defaultValue={parseInt(
                                              values.link_expiration_period
                                            )}
                                          />
                                        </div>
                                      </Col>
                                      {/* Link Validity */}
                                      <Col md="12">
                                        <div className="form-group event-group adhoc-form-group">
                                          <label>
                                            Link Validity Period *
                                            <ToolTip position="left" />
                                          </label>
                                          <IntervalField
                                            onChange={(value) => {
                                              handleChange({
                                                target: {
                                                  name: "link_expiration_period",
                                                  value,
                                                },
                                              });
                                            }}
                                            label="days"
                                            interval={1}
                                            defaultValue={parseInt(
                                              values.link_expiration_period
                                            )}
                                          />
                                        </div>
                                      </Col>
                                      {/* Event Summary */}
                                      <Col md="12">
                                        <div className="event_summary">
                                          <p>Event summay</p>
                                          <div className="inner">
                                            <div className="view_detail d-flex justify-content-between">
                                              <p>Host</p>
                                              <p
                                                onClick={() =>
                                                  setViewDetail(!viewDetail)
                                                }
                                                style={{ cursor: "pointer" }}
                                              >
                                                {viewDetail
                                                  ? "Hide Details"
                                                  : "View Details"}
                                              </p>
                                            </div>
                                            <div className="name host">
                                              <img
                                                className="prof_img"
                                                src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg"
                                                alt="Profile Img"
                                              />
                                              Host Name
                                            </div>
                                            <div className="name icon">
                                              <img
                                                src={ClockIcon}
                                                alt="Profile Img"
                                              />
                                              <span>15 minutes</span>
                                            </div>
                                            <div className="name icon">
                                              <img
                                                src={PlannerIcon}
                                                alt="Profile Img"
                                              />
                                              <span>4 slots on 2 days</span>
                                            </div>

                                            {viewDetail ? (
                                              <>
                                                <div className="name icon">
                                                  <span className="small_head">
                                                    Selected Times
                                                  </span>
                                                </div>
                                                {Object.keys(selectedSlots).map(
                                                  (key, index) => {
                                                    if (
                                                      selectedSlots[`${key}`]
                                                        .length > 0
                                                    ) {
                                                      return (
                                                        <div
                                                          className="name icon d-block"
                                                          key={index}
                                                        >
                                                          <span>
                                                            {moment(
                                                              key,
                                                              "YYYY-MM-DD"
                                                            ).format(
                                                              "dddd, MMMM, DD YYYY"
                                                            )}
                                                          </span>
                                                          <p>
                                                            {(
                                                              selectedSlots[
                                                                `${key}`
                                                              ] || []
                                                            ).map(
                                                              (
                                                                selectedSlot,
                                                                innerIndex
                                                              ) => {
                                                                return (
                                                                  <span
                                                                    key={
                                                                      innerIndex
                                                                    }
                                                                  >
                                                                    {
                                                                      selectedSlot
                                                                    }
                                                                  </span>
                                                                );
                                                              }
                                                            )}
                                                          </p>
                                                        </div>
                                                      );
                                                    } else {
                                                      return null;
                                                    }
                                                  }
                                                )}
                                              </>
                                            ) : null}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div id="copy-element" className="d-none"></div>  
      {showAdhocSavedModal && (
        <Modal className="new-share-modal" isOpen={showAdhocSavedModal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}></ModalHeader>
            <ModalBody>
                <h2 className='fw-bold'>Meeting is ready to share!</h2>
                <p className='my-4'>Paste this link in a message to invite them to pick time</p>
                <div className="col-md-12">
                    <div class="input-group">
                        <input 
                            type="text"
                            id="copy-field-element" 
                            class="form-control col-md-8" 
                            placeholder="https//meetocto.com/group-conference" 
                            value={ FRONTEND_BASE_URL +
                                    "adhoc-booking/" +
                                    eventUrl.current} 
                            aria-label="Username" 
                            aria-describedby="quickSignup" 
                            disabled
                        />
                        <button 
                            class="btn rd_cancel col-md-4" 
                            type="button" 
                            id="quickSignup"
                            onClick={() =>
                                onCopyEventLink(
                                  eventUrl.current,
                                  "copy-field-element"
                                )
                            }
                        >{ isCopied ? "Copied" : "Copy link" }</button>
                    </div>
                </div>
                <div class="divider">
                <div class="divider-text">
                        <p>or</p>
                </div>
                </div>

                <div className="txt text-center">
                <img src={mail} class='mb-2' alt="Brand Logo" />
                    <h3 class='my-2'><strong>Add times to email</strong></h3>
                    <p>Paste times directly into an email to share</p>     
                </div>
                
                
            </ModalBody>
            <ModalFooter className="justify-content-center mb-4 border-top-0 pt-4">
                <div className="col-6">
                  <button type="button" className="btn btn-outline brand" onClick={onCopyAddTimesToEmail}>Copy Times</button>
                </div>
            </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  getAvailableTimeSlots,
  getVenues,
  sendPostRequest,
})(AddAdhoc);
