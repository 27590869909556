import React from 'react';

const ActionDots = (props) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="5"
            viewBox="0 0 21 5"
        >
            <g
            id="Group_8555"
            data-name="Group 8555"
            transform="translate(-302 1345) rotate(-90)"
            >
                <circle
                    id="Ellipse_571"
                    data-name="Ellipse 571"
                    cx="2.5"
                    cy="2.5"
                    r="2.5"
                    transform="translate(1340 302)"
                    fill="#0c4767"
                    opacity="0.67"
                />
                <circle
                    id="Ellipse_572"
                    data-name="Ellipse 572"
                    cx="2.5"
                    cy="2.5"
                    r="2.5"
                    transform="translate(1340 310)"
                    fill="#0c4767"
                    opacity="0.67"
                />
                <circle
                    id="Ellipse_573"
                    data-name="Ellipse 573"
                    cx="2.5"
                    cy="2.5"
                    r="2.5"
                    transform="translate(1340 318)"
                    fill="#0c4767"
                    opacity="0.67"
                />
            </g>
        </svg>
    );

}

export default ActionDots;