import React from "react";
import EventIcon from "../../assets/icons/event_not_icon.svg";

const ErrorPage = (props) => {
  return (
    <>
      <section className="event_not_avail">
        <img src={EventIcon} alt="Icon" />
        <h2>{props.message}</h2>
      </section>
    </>
  );
};

export default ErrorPage;
