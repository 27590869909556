import React from 'react';
import EventIcon from "../../assets/icons/event_not_icon.svg";

const EventNotavail = () => {
    return (<>
        <section className="event_not_avail">
            <img src={EventIcon} alt="Icon" />
            <h2>This event is invalid or no longer available</h2>
        </section>
    </>);
}

export default EventNotavail;