import React from "react";
import { Col, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import AddPromo from "./create";
import PromoListing from "./list";

class Promo extends React.Component {
  constructor(props) {
    super(props);
    this.setState({ promoCode: {} });

    this.editClickPromoCode = this.editClickPromoCode.bind(this);
  }
  state = {
    activeTab: "1",
  };

  editClick = (value) => {
    console.log(value);
  };

  ChangeTab = () => {
    this.setState({
      activeTab: "2", // open add new form when click on edit
    });
  };

  editClickPromoCode(param) {
    this.setState({
      activeTab: "3", // open add new form when click on edit
    });
    this.setState({ promoCode: param });
    console.log("do something: ", param);
  }

  toggle = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };
  render() {
    return (
      <div className="eventdetailsaddbox rd_noshadow">
        <div className="boxheader rd_floatingheaderthig">
          <div className="rd_inputselectheader">
            <div className="rd_selectheaderrdt2 rd_selectheaderrdt2profile">
              <button
                className={classnames({
                  active: this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Promocodes
              </button>
              <button
                className={classnames({
                  active: this.state.activeTab === "2",
                })}
                onClick={() => {
                  this.toggle("2");
                  this.setState({ promoCode: {} });
                }}
              >
                Add New
              </button>
            </div>
          </div>
        </div>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <PromoListing onToggleTab={this.toggle}></PromoListing>
          </TabPane>
          <TabPane tabId="2">
            <Col md="12">
              <AddPromo></AddPromo>
            </Col>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
export default Promo;
