import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Button } from "reactstrap";
import { Formik, Form } from "formik";
import Loader from "../../../components/Loader/Loader";
import * as Yup from "yup";
import FormField from "../../../components/FormField/FormField";
import { getSEO, saveSEO } from "../../../actions/seoAction";
import isEmpty from "../../../validation/is-empty";
import { notification } from "../../../utils/utility";
import Filter from "../../../components/Filter/Filter";
import Tooltip from "../../../components/Tooltip";

const validation = Yup.object().shape({
  title: Yup.string().required("Title is required"),
});

let initialValues = {
  title: "",
  description: "",
  keywords: "",
  facebook_pixel_id: "",
  twitter_pixel_id: "",
  linkedin_pixel_id: "",
  pinterest_pixel_id: "",
  quora_pixel_id: "",
  bing_pixel_id: "",
  google_analytics_id: "",
  google_adwords_pixel_id: "",
  google_tm_pixel_id: "",
  google_analytics_id: "",
};

const SEO = (props) => {
  const [formValues, setFormValues] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    const { getSEO } = props;
    setPageLoading(true);
    getSEO({
      data: {},
      onSuccess: function (details) {
        console.log(details);
        if (!isEmpty(details)) {
          setFormValues({
            id: details.id,
            title: details.title,
            description: details.description,
            keywords: details.keywords,
            facebook_pixel_id: details.facebook_pixel_id,
            twitter_pixel_id: details.twitter_pixel_id,
            linkedin_pixel_id: details.linkedin_pixel_id,
            pinterest_pixel_id: details.pinterest_pixel_id,
            quora_pixel_id: details.quora_pixel_id,
            bing_pixel_id: details.bing_pixel_id,
            google_analytics_id: details.google_adwords_pixel_id,
            google_adwords_pixel_id: details.google_adwords_pixel_id,
            google_tm_pixel_id: details.google_tm_pixel_id,
            google_analytics_id: details.google_analytics_id,
          });
        }
        setPageLoading(false);
      },
      onError: function (error) {
        setPageLoading(false);
      },
    });
  }, []);

  return (
    <section>
      <Loader isShowLoader={pageLoading} />
      <div>
        <div className="eventdetailsaddbox rd_noshadow">
          {/* <div className="boxheader rd_floatingheaderthig">
            <div className="rd_inputselectheader">
              <div className="rd_selectheaderrdt2">
                <h3>
                  <strong>Marketing Tools</strong>
                </h3>
              </div>
            </div>
          </div> */}
          <Filter name="Marketing Tools" />

          <div className="rd_vacationfilterpart rd_specialcenterthingim rd_specialcenterthingimte market_tools">
            <Formik
              validationSchema={validation}
              initialValues={formValues || initialValues}
              enableReinitialize
              onSubmit={(data) => {
                const { saveSEO } = props;
                setPageLoading(true);

                saveSEO({
                  data: {
                    ...data,
                  },
                  onSuccess: (response) => {
                    console.log("res", response);
                    if (response.success) {
                      if (response.data) {
                        const { data } = response.data;
                        setFormValues({
                          ...formValues,
                          id: data.id,
                        });
                      }
                      notification.success(response.message);
                    }
                    setPageLoading(false);
                    console.log("response", response);
                  },
                  onError: (error) => {
                    notification.error(
                      "An error occured during save, Please try later"
                    );
                    setPageLoading(false);
                    console.log("company error ", error);
                  },
                });
              }}
            >
              {(formProps) => {
                const { values, errors, touched, handleChange, setFieldValue } =
                  formProps;

                return (
                  <>
                    <Form style={{ width: "100%" }}>
                      <div className="rd_vacationfilterpart rd_specialcenterthingim">
                        <div className="rd_seotoppart1contflitem1">
                          <h3 className="page_title">Search Engines Optimization (SEO):</h3>
                          <div className="rd_seoinfopa">
                            <label className="form-label">Title * <Tooltip code="P22F1" /> </label>
                            <FormField
                              type="text"
                              name="title"
                              placeholder="Title"
                              fieldClasses="rd_adddayofinput"
                              value={values.title}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: { name: "title", value },
                                });
                              }}
                            />

                            <label className="form-label">Description <Tooltip code="P22F2" /></label>
                            <FormField
                              component="textarea"
                              name="description"
                              placeholder="Description"
                              fieldClasses="rd_adddayofinput mb-3"
                              value={values.description}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: { name: "description", value },
                                });
                              }}
                            />
                            <label className="form-label">Keywords <Tooltip code="P22F3" /></label>

                            <FormField
                              type="text"
                              name="keywords"
                              placeholder="Keywords, Keyword 1, Keyword 2"
                              fieldClasses="rd_adddayofinput"
                              value={values.keywords}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: { name: "keywords", value },
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="rd_seotoppart1contflitem2">
                          {values.title != "" ? (
                            <div>
                              <div id="searchresultsarea">
                                <div className="searchresult">
                                  <h2>{values.title}</h2>
                                  <a className="searchresulta">
                                    https://en.wikipedia.org/wiki/Lock_(computer_science)
                                  </a>
                                  <button>▼</button>
                                  <p>{values.description}</p>
                                  <p>{values.keywords}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="rd_vacationfilterpart rd_noflex">
                        <div className="rd_pixelretargetingthning">
                        <h3 className="page_title">Remarketing Pixels</h3>

                          <div className="rd_pixelinpucointret">
                            <div className="rd_flexpoarttpixelreta">
                              <div className="rd_flexpoarttpixelretaitem">
                                <p>
                                  <span className="rd_retagpixico rd_retagpixico-facebook"></span>
                                  Facebook Pixel <Tooltip code="P22F5" />
                                </p>
                              </div>
                              <div className="rd_flexpoarttpixelretaitem">
                                <FormField
                                  type="text"
                                  name="facebook_pixel_id"
                                  placeholder="Pixel Id"
                                  fieldClasses="rd_adddayofinput"
                                  value={values.facebook_pixel_id}
                                  errors={errors}
                                  touched={touched}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    handleChange({
                                      target: {
                                        name: "facebook_pixel_id",
                                        value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="rd_flexpoarttpixelreta">
                              <div className="rd_flexpoarttpixelretaitem">
                                <p>
                                  <span className="rd_retagpixico rd_retagpixico-twitter"></span>
                                  Twitter <Tooltip code="P22F6" />
                                </p>
                              </div>
                              <div className="rd_flexpoarttpixelretaitem">
                                <FormField
                                  type="text"
                                  name="twitter_pixel_id"
                                  placeholder="Pixel Id"
                                  fieldClasses="rd_adddayofinput"
                                  value={values.twitter_pixel_id}
                                  errors={errors}
                                  touched={touched}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    handleChange({
                                      target: {
                                        name: "twitter_pixel_id",
                                        value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="rd_flexpoarttpixelreta">
                              <div className="rd_flexpoarttpixelretaitem">
                                <p>
                                  <span className="rd_retagpixico rd_retagpixico-linkedin"></span>
                                  LinkedIn <Tooltip code="P22F7" />
                                </p>
                              </div>
                              <div className="rd_flexpoarttpixelretaitem">
                                <FormField
                                  type="text"
                                  name="linkedin_pixel_id"
                                  placeholder="Pixel Id"
                                  fieldClasses="rd_adddayofinput"
                                  value={values.linkedin_pixel_id}
                                  errors={errors}
                                  touched={touched}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    handleChange({
                                      target: {
                                        name: "linkedin_pixel_id",
                                        value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="rd_flexpoarttpixelreta">
                              <div className="rd_flexpoarttpixelretaitem">
                                <p>
                                  <span className="rd_retagpixico rd_retagpixico-pinterest"></span>
                                  Pinterest <Tooltip code="P22F8" />
                                </p>
                              </div>
                              <div className="rd_flexpoarttpixelretaitem">
                                <FormField
                                  type="text"
                                  name="pinterest_pixel_id"
                                  placeholder="Pixel Id"
                                  fieldClasses="rd_adddayofinput"
                                  value={values.pinterest_pixel_id}
                                  errors={errors}
                                  touched={touched}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    handleChange({
                                      target: {
                                        name: "pinterest_pixel_id",
                                        value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="rd_flexpoarttpixelreta">
                              <div className="rd_flexpoarttpixelretaitem">
                                <p>
                                  <span className="rd_retagpixico rd_retagpixico-quora"></span>
                                  Quora <Tooltip code="P22F9" />
                                </p>
                              </div>
                              <div className="rd_flexpoarttpixelretaitem">
                                <FormField
                                  type="text"
                                  name="quora_pixel_id"
                                  placeholder="Pixel Id"
                                  fieldClasses="rd_adddayofinput"
                                  value={values.quora_pixel_id}
                                  errors={errors}
                                  touched={touched}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    handleChange({
                                      target: {
                                        name: "quora_pixel_id",
                                        value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="rd_flexpoarttpixelreta">
                              <div className="rd_flexpoarttpixelretaitem">
                                <p>
                                  <span className="rd_retagpixico rd_retagpixico-Bing"></span>
                                  Bing <Tooltip code="P22F10" />
                                </p>
                              </div>
                              <div className="rd_flexpoarttpixelretaitem">
                                <FormField
                                  type="text"
                                  name="bing_pixel_id"
                                  placeholder="Pixel Id"
                                  fieldClasses="rd_adddayofinput"
                                  value={values.bing_pixel_id}
                                  errors={errors}
                                  touched={touched}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    handleChange({
                                      target: {
                                        name: "bing_pixel_id",
                                        value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="rd_flexpoarttpixelreta">
                              <div className="rd_flexpoarttpixelretaitem">
                                <p>
                                  <span className="rd_retagpixico rd_retagpixico-googleadwords"></span>
                                  Google Adwords <Tooltip code="P22F11" />
                                </p>
                              </div>
                              <div className="rd_flexpoarttpixelretaitem">
                                <FormField
                                  type="text"
                                  name="google_adwords_pixel_id"
                                  placeholder="Pixel Id"
                                  fieldClasses="rd_adddayofinput"
                                  value={values.google_adwords_pixel_id}
                                  errors={errors}
                                  touched={touched}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    handleChange({
                                      target: {
                                        name: "google_adwords_pixel_id",
                                        value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="rd_flexpoarttpixelreta">
                              <div className="rd_flexpoarttpixelretaitem">
                                <p>
                                  <span className="rd_retagpixico rd_retagpixico-googletagmanager"></span>
                                  Google Tag Manager <Tooltip code="P22F12" />
                                </p>
                              </div>
                              <div className="rd_flexpoarttpixelretaitem">
                                <FormField
                                  type="text"
                                  name="google_tm_pixel_id"
                                  placeholder="Pixel Id"
                                  fieldClasses="rd_adddayofinput"
                                  value={values.google_tm_pixel_id}
                                  errors={errors}
                                  touched={touched}
                                  onChange={({ target }) => {
                                    const { value } = target;
                                    handleChange({
                                      target: {
                                        name: "google_tm_pixel_id",
                                        value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="rd_vacationfilterpart">
                        <div className="rd_flexpoarttpixelreta rd_flexpoarttpixelretafull">
                          <div className="rd_flexpoarttpixelretaitem">
                          <label className="form-label">Google Analytics <Tooltip code="P22F13" /></label>
                            <FormField
                              type="text"
                              name="google_analytics_id"
                              placeholder="Google analytics ID"
                              fieldClasses="rd_adddayofinput"
                              value={values.google_analytics_id}
                              errors={errors}
                              touched={touched}
                              onChange={({ target }) => {
                                const { value } = target;
                                handleChange({
                                  target: {
                                    name: "google_analytics_id",
                                    value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>

                        <Col lg="12" md="12" sm="12">
                          <Button
                            style={{ marginBottom: "10px" }}
                            type="submit"
                            className="btn btn-app col-md-2 col-lg-2 col-sm-12 float-right"
                          >
                            Save
                          </Button>
                        </Col>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default connect(null, {
  getSEO,
  saveSEO,
})(SEO);
