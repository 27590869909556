import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col
} from "reactstrap";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import moment from "moment";
import Loader from "../../components/Loader/Loader";
import { FRONTEND_BASE_URL } from "../../utils/utility";
import {
  getEvents,
  getAdhocEvents,
  toggleEventStatus,
  toggleEventSecretStatus,
  deleteEvent,
  duplicateEvent,
  getVenues,
} from "../../actions/eventActions";
import noImage from "../../assets/images/no-event-image.jpg";
import classnames from "classnames";
import { Fragment } from "react";
import { notification, withRouter } from "../../utils/utility";
import ShareEventModal from "./Components/Modals/ShareEventModal";
import Filter from "../../components/Filter/Filter";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import isEmpty from "../../validation/is-empty";
import DeleteEventModal from "./Components/Modals/DeleteEventModal";
import EventFilters from "./Components/Fields/EventFilters";
import { toggleFilter } from "../../actions/filterActions";
import CopyIcon from "../../assets/icons/copy_icon.svg";


class ListEventsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      totalEvents: 0,
      adHocEvents: [],
      isLoading: false,
      isEventListView: true,
      showDeleteEventModal: false,
      showAdhocDeleteEventModal: false,
      eventId: null,
      pageLoading: false,
      pagination: {
        current: 1,
        pageSize: 12
      },
      filter: {},
      venues: [],
    };

  }




  /**
   * delete event
   * @param {*} eventId
   * @param {*} index
   */
  deleteAdhoc = (eventId) => {
    const _this = this;
    const { deleteEvent } = this.props || {};
    this.setState({
      pageLoading: true,
    });

    deleteEvent({
      data: {
        id: eventId,
      },
      onSuccess: function () {
        _this.setState({
          showAdhocDeleteEventModal: false,
          pageLoading: false,
        });
        _this.fetchAdhocEvents();
        notification.success(`Event successfully deleted`);

      },
      onError: function (error) {
        _this.setState({
          pageLoading: false,
        });
        notification.error("An Error occurred during delete, please try later");
      },
    });
  };



  /**
   * delete event
   * @param {*} eventId
   * @param {*} index
   */
  delete = (eventId) => {
    const _this = this;
    const { deleteEvent } = this.props || {};
    this.setState({
      pageLoading: true,
    });

    deleteEvent({
      data: {
        id: eventId,
      },
      onSuccess: function () {
        _this.setState({
          showDeleteEventModal: false,
          pageLoading: false,
        });

        const { pagination } = _this.state;
        _this.fetchListEvents({ pagination });
        notification.success(`Event successfully deleted`);

      },
      onError: function (error) {
        _this.setState({
          pageLoading: false,
        });
        notification.error("An Error occurred during delete, please try later");
      },
    });
  };

  /**
   *
   * @param {*} id
   * @param {*} eventType
   */
  onEdit = (id, eventType) => {
    this.setState({
      pageLoading: true,
    });
    window.open(`/admin/events/${id}/create?type=${eventType}`, "_self");
  };

  /**
   * Toggle event secret status
   * @param {*} eventId
   * @param {*} index
   * @param {*} status
   */
  onToggleEventSecretStatus = (eventId, index, status) => {
    const _this = this;
    const { toggleEventSecretStatus } = this.props || {};
    const events = [...this.state.events];
    const isActive = status === 'D' ? "E" : "D";

    this.setState({
      pageLoading: true,
    });

    toggleEventSecretStatus({
      data: {
        id: eventId,
        status: isActive,
      },
      onSuccess: function () {
        events[index].event_public_visiblity_status = isActive;
        _this.setState({
          events: events,
          pageLoading: false,
        });
        notification.success(`Event visibility status is updated`);
      },
      onError: function (error) {
        _this.setState({
          pageLoading: false,
        });
        notification.error("An Error occurred duing saving, please try later");
      },
    });
  };


  /**
   * Toggle adhoc event status
   * @param {*} eventId
   * @param {*} index
   * @param {*} status
   */
  toggleAdhocStatus = (eventId, index, status) => {
    const _this = this;
    const { toggleEventStatus } = this.props || {};
    const adHocEvents = [...this.state.adHocEvents];
    const isActive = status ? "E" : "D";

    this.setState({
      pageLoading: true,
    });

    toggleEventStatus({
      data: {
        id: eventId,
        status: isActive,
      },
      onSuccess: function () {
        adHocEvents[index].is_active = isActive;
        _this.setState({
          adHocEvents: adHocEvents,
          pageLoading: false,
        });
        notification.success(
          `Event successfully ${isActive == "E" ? "Activated" : "Deactivated"}`
        );
      },
      onError: function (error) {
        _this.setState({
          pageLoading: false,
        });
        notification.error("An Error occurred duing saving, please try later");
      },
    });
  };

  /**
   * Toggle event status
   * @param {*} eventId
   * @param {*} index
   * @param {*} status
   */
  toggleStatus = (eventId, index, status) => {
    const _this = this;
    const { toggleEventStatus } = this.props || {};
    const events = [...this.state.events];
    const isActive = status ? "E" : "D";

    this.setState({
      pageLoading: true,
    });

    toggleEventStatus({
      data: {
        id: eventId,
        status: isActive,
      },
      onSuccess: function () {
        events[index].is_active = isActive;
        _this.setState({
          events: events,
          pageLoading: false,
        });
        notification.success(
          `Event successfully ${isActive == "E" ? "Activated" : "Deactivated"}`
        );
      },
      onError: function (error) {
        _this.setState({
          pageLoading: false,
        });
        notification.error("An Error occurred duing saving, please try later");
      },
    });
  };

  /**
   * toggle view
   * @param boolean status
   */
  toggleView = (status) => {
    const { isEventListView } = this.state;
    if (isEventListView !== status) {
      this.setState({
        isEventListView: status,
      });
    }
  };

  /**
   * Copy event url
   * @param string event_url
   */
  onCopyEventLink = (eventURL, copyFieldElementId) => {
    var copyText = document.getElementById(copyFieldElementId);
    copyText.disabled = false;
    copyText.select();
    document.execCommand("copy");
    copyText.disabled = true;
    window.getSelection().removeAllRanges();
    notification.success(`Event URL Copied ${FRONTEND_BASE_URL}booking/${eventURL}`);
  };

  /**
   * Open event in new tab
   * @param {*} eventURL
   */
  onOpenEvent = (eventURL) => {
    window.open(FRONTEND_BASE_URL + "booking/" + eventURL, "_blank").focus();
  };


  /**
   * on event summary
   * @param {*} id 
   */
  onEventSummary = (id) => {
    const { router } = this.props;
    const { navigate } = router || {};
    navigate(`/admin/event-summary/${id}`);
  }

  /**
   * Duplicate event
   */
  onDuplicate = (id) => {
    const _this = this;
    const { duplicateEvent } = this.props || {};

    _this.setState({
      pageLoading: true,
    });

    duplicateEvent({
      data: {
        id,
      },
      onSuccess: function (response) {
        const { id, event_type } = response.data;
        window.open(`/admin/events/${id}/create?type=${event_type}`, "_self");
      },
      onError: function (error) {
        console.error(error);
        _this.setState({
          pageLoading: false,
        });
      },
    });
  };


  /**
   * on page change
   * @param {*} page 
   * @param {*} pageSize 
   */
  onPageChange = (page, pageSize) => {
    const _this = this;
    _this.setState({
      pageLoading: true
    });
    _this.fetchListEvents({
      pagination: {
        current: page,
        pageSize: pageSize
      }
    });
  }


  /**
   * filter records
   */
  filterRecords = () => {
    const _this = this;
    const { toggleFilter, filter } = _this.props;
    toggleFilter(false);
    _this.setState({
      pageLoading: true
    });
    _this.handleTableChange(
      {
        ..._this.state.pagination,
      },
      {
        ...filter.searchFilters,
      },
      {}
    );
  };


  /**
   * on clear filter handler
   */
  clearFilterHandler = () => {
    const _this = this;
    _this.setState({
      pageLoading: true
    });
    _this.handleTableChange(
      {
        ..._this.state.pagination,
      },
      {},
      {}
    );
  }


  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchListEvents({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };


  /**
  * prepare data parameters to be send to server
  * @param {*} params
  */
  prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };


  /**
   * fetch list events
   */
  fetchListEvents = (params = {}) => {
    const _this = this;
    const { getEvents } = _this.props || {};
    getEvents({
      data: _this.prepareDataParam(params),
      onSuccess: function (eventList) {
        const { events: basicEvents, total } = eventList || {};
        _this.setState({
          events: basicEvents,
          totalEvents: total,
          isLoading: false,
          pageLoading: false,
          pagination: { ...params.pagination }
        });
      },
      onError: function (error) {
        console.error(error);
      },
    });
  }


  /**
   * fetch adhoc events
   */
  fetchAdhocEvents = () => {
    const _this = this;
    const { getAdhocEvents } = this.props || {};
    getAdhocEvents({
      data: {},
      onSuccess: function (eventList) {
        const { adhocEvents: adhoc } = eventList || {};
        _this.setState({
          adHocEvents: adhoc,
        });
      },
      onError: function (error) {
        console.error(error);
      },
    });
  }

  /**
   * hide delete event modal
   */
  hideDeleteEventModal = () => {
    this.setState({
      showDeleteEventModal: false
    });
  }

  /**
   * hide adhoc event delete modal
   */
  hideAdhocDeleteEventModal = () => {
    this.setState({
      showAdhocDeleteEventModal: false
    });
  }



  componentDidMount() {

    const { getVenues } = this.props || {};
    //const bussinessId = localStorage.getItem("businessId");
    const _this = this;

    const { pagination } = _this.state;

    this.setState({
      isLoading: true,
    });

    _this.fetchListEvents({ pagination });
    _this.fetchAdhocEvents();

    getAdhocEvents({
      data: {},
      onSuccess: function (eventList) {
        const { adhocEvents: adhoc } = eventList || {};
        _this.setState({
          adHocEvents: adhoc,
        });
      },
      onError: function (error) {
        console.error(error);
      },
    });

    getVenues({
      onSuccess: function (venues) {
        _this.setState({
          venues
        })
      },
      onError: function (error) {
        console.error(error);
      },
    });


  }
  render() {
    const {
      events,
      adHocEvents,
      isLoading,
      isEventListView,
      pageLoading,
      pagination,
      showDeleteEventModal,
      showAdhocDeleteEventModal,
      eventId,
    } = this.state || {};
    return (
      <section>
        <Loader isShowLoader={pageLoading} />
        <div className="">
          <div className="eventdetailsaddbox rd_noshadow">
            <div className="boxheader rd_floatingheaderthig d-none">
              <div className="rd_inputselectheader">
                <div className="rd_selectheaderrdt2 rd_selectheaderrdt2profile">
                  <button
                    className={classnames({
                      active: isEventListView,
                    })}
                    onClick={() => {
                      this.toggleView(true);
                    }}
                  >
                    Event List
                  </button>
                  <button
                    className={classnames({
                      active: !isEventListView,
                    })}
                    onClick={() => {
                      this.toggleView(false);
                    }}
                  >
                    AdHoc Events.
                  </button>
                </div>
              </div>
            </div>

            <div className="rd_pasevencontth pr-md-0">
              {
                isEventListView ? (
                  <Filter
                    name="Events"
                    onFilterRecords={this.filterRecords}
                    onClearFilterHandler={this.clearFilterHandler}
                  >
                    <EventFilters
                    />
                  </Filter>
                ) : null
              }
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <div className="rd_eventcardscontainer">
                  {isEventListView ? (
                    <Fragment>
                      {(events || []).length > 0 ? (
                        <Fragment>
                          {(events || []).map((event, index) => {
                            const {
                              is_event_paid,
                              venue,
                              event_image,
                              event_color,
                              event_title,
                              event_format_id,
                              end_date,
                              timeslot,
                              event_url,
                              is_draft,
                              event_fee,
                              is_active,
                              event_public_visiblity_status,
                              id,
                            } = event || {};
                            const event_type =
                              is_event_paid === "E"
                                ? "paid-event"
                                : "free-event";

                            let image = noImage;

                            if (event_image) {
                              image = `https://www.staging.thrive-booking.com/backend/public/${event_image}`;
                            }
                            return (
                              <div
                                className="rd_carditem rd_carditemnoback"
                                style={{
                                  borderColor: event_color || "#b8c2cc",
                                  borderStyle: "solid",
                                }}
                                key={index}
                              >
                                {is_draft === 'E' && (<div className="draft_eve">Draft</div>)}
                                <div className="dashboarditemthing dashboarditemthing2">
                                  <div className="td_dashboardtopcardpartth">
                                    <h5>
                                      <Link
                                        to={`/admin/events/${id}/create?type=${event_type}`}
                                      >
                                        {event_title}
                                      </Link>
                                      {event_format_id === "2" ||
                                        event_format_id === 2 ? (
                                        <button
                                          className="rd_groupebuttontheven"
                                          style={{
                                            width: "23px",
                                            height: "16px",
                                            border: "none",
                                            cursor: "auto",
                                            marginLeft: "5px",
                                          }}
                                        ></button>
                                      ) : (<Icon.User size={17} />)}

                                    </h5>
                                    <div className="box_content rd_p0special">
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="flexSwitchCheckChecked4"
                                          checked={is_active === "E"}
                                          onChange={(event) => {
                                            this.toggleStatus(
                                              id,
                                              index,
                                              event.target.checked
                                            );
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexSwitchCheckChecked4"
                                        >
                                          {is_active === "E"
                                            ? "Deactivate"
                                            : "Activate"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <p>
                                    <span>Ends: </span>
                                    {end_date}
                                  </p>
                                  <p>
                                    <span>Duration: </span>
                                    {!timeslot ? "Not Selected" : timeslot}
                                  </p>
                                  <p>
                                    <span>Event type: </span>
                                    {is_event_paid === "E"
                                      ? "Paid-" + event_fee
                                      : "Free-0"}
                                  </p>
                                  <p>
                                    <span>Event Venue: </span>
                                    {venue}
                                  </p>

                                  <div className="rd_eventlibuttanlikco">
                                    <div className="rd_eventlibuttanlikcolinkco">
                                      <div>
                                        <button
                                          className="rd_settingbuttontheven"
                                          onClick={() => this.onDuplicate(id)}
                                        ></button>
                                        <button
                                          className="rd_eventSummary" onClick={() => this.onEventSummary(id)}></button>
                                      </div>
                                      <div>
                                        <ShareEventModal
                                          selectedEvent={event}
                                        />
                                        <button
                                          className="rd_clobuttontheven"
                                          onClick={() =>
                                            this.onOpenEvent(event_url)
                                          }
                                        ></button>
                                        <button
                                          className="rd_parameterbuttontheven"
                                          onClick={() =>
                                            this.onEdit(id, event_type)
                                          }
                                        ></button>
                                        {event_public_visiblity_status === 'E' ? (
                                          <button
                                            className="rd_eyebuttontheven"
                                            onClick={() => {
                                              this.onToggleEventSecretStatus(
                                                id,
                                                index,
                                                event_public_visiblity_status
                                              );
                                            }}
                                          ></button>
                                        ) : (<Icon.EyeOff size={18} className="eye-off-icon" onClick={() => {
                                          this.onToggleEventSecretStatus(
                                            id,
                                            index,
                                            event_public_visiblity_status
                                          );
                                        }} />)}


                                        <button
                                          className="rd_deletebuttontheven"
                                          onClick={() => {
                                            this.setState({
                                              eventId: id,
                                              showDeleteEventModal: true,
                                            })
                                          }}
                                        ></button>
                                      </div>
                                    </div>

                                    <div>
                                      <div className="rd_profilethingco">
                                        <div className="input-group">
                                          <input
                                            type="text"
                                            className="form-control noshadfoc pl-2"
                                            id={"copy-field-element-" + index}
                                            placeholder="https://referral_link.com/yourlink"
                                            value={
                                              FRONTEND_BASE_URL +
                                              "booking/" +
                                              event_url
                                            }
                                            disabled
                                          />
                                          <div className="input-group-prepend rd_sahredownbtn">
                                            <button
                                              className="input-group-text"
                                              onClick={() =>
                                                this.onCopyEventLink(
                                                  event_url,
                                                  "copy-field-element-" + index
                                                )
                                              }
                                            >
                                              <span className="d-none d-lg-block">Copy link</span>
                                              <img src={CopyIcon} alt="copy icon" className="d-lg-none" />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        </Fragment>
                      ) : (
                        <p className="text-left">No Events Found.</p>
                      )}
                    </Fragment>
                  ) : null}

                  {!isEventListView ? (
                    <Fragment>
                      {(adHocEvents || []).length > 0 ? (
                        <Fragment>
                          {(adHocEvents || []).map((event, index) => {
                            const {
                              is_event_paid,
                              is_active,
                              venue,
                              event_color,
                              event_name,
                              event_format_id,
                              event_date: end_date,
                              end_time: timeslot,
                              is_draft,
                              id,
                            } = event || {};
                            const event_type =
                              is_event_paid === "E"
                                ? "paid-event"
                                : "free-event";

                            return (
                              <div
                                className="rd_carditem rd_carditemnoback"
                                key={index}
                              >
                                <div className="dashboarditemthing dashboarditemthing2">
                                  <div className="td_dashboardtopcardpartth">
                                    <h5>
                                      <Link
                                        to={`/admin/events/create/${id}/ad-hoc`}
                                      >
                                        {event_name}
                                      </Link>
                                    </h5>
                                    <div className="box_content rd_p0special">
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="flexSwitchCheckChecked4"
                                          checked={is_active === "E"}
                                          onChange={(event) => {
                                            this.toggleAdhocStatus(
                                              id,
                                              index,
                                              event.target.checked
                                            );
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexSwitchCheckChecked4"
                                        >
                                          {is_active === "E"
                                            ? "Deactivate"
                                            : "Activate"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <p>
                                    <span>Ends: </span>
                                    {!end_date
                                      ? "Not Selected"
                                      : moment(end_date).format("MMM Do YYYY")}
                                  </p>
                                  <p>
                                    <span>Time: </span>
                                    {!timeslot ? "Not Selected" : timeslot}
                                  </p>
                                  <p>
                                    <span>Status: </span>
                                    {is_active === "E" ? "Active" : "Deactive"}
                                  </p>
                                  <div className="rd_eventlibuttanlikco">
                                    <div className="rd_eventlibuttanlikcolinkco">
                                      <div>
                                      </div>
                                      <div>
                                        <button
                                          className="rd_deletebuttontheven"
                                          onClick={() => {
                                            this.setState({
                                              eventId: id,
                                              showAdhocDeleteEventModal: true,
                                            })
                                          }}
                                        ></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <p className="text-left">No Adhoc Events Found.</p>
                      )}
                    </Fragment>
                  ) : null}
                </div>
              )}
            </div>
          </div>

          {showDeleteEventModal && (
            <DeleteEventModal
              eventId={eventId}
              showDeleteEventModal={showDeleteEventModal}
              handleClose={this.hideDeleteEventModal}
              onDelete={this.delete}
            />
          )}

          {showAdhocDeleteEventModal && (
            <DeleteEventModal
              eventId={eventId}
              showDeleteEventModal={showAdhocDeleteEventModal}
              handleClose={this.hideAdhocDeleteEventModal}
              onDelete={this.deleteAdhoc}
            />
          )}

          {(isEventListView && !isLoading) ? (
            <Row className="pb-4 pr-3">
              <Col className="text-center" lg="12" md="12">
                <Pagination
                  className="list-view-pagination"
                  defaultCurrent={pagination.current}
                  current={pagination.current}
                  total={this.state.totalEvents}
                  pageSize={pagination.pageSize}
                  onChange={this.onPageChange}
                  showSizeChanger={false}
                />
              </Col>
            </Row>
          ) : null}

        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.filter
});

export default connect(mapStateToProps, {
  getEvents,
  getAdhocEvents,
  toggleEventStatus,
  toggleEventSecretStatus,
  deleteEvent,
  duplicateEvent,
  getVenues,
  toggleFilter,
})(withRouter(ListEventsView));
