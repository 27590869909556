import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import BookingDetailPdf from "./booking-detail-pdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PrintIcon from "../../../assets/icons/print_icon.svg";
import CloneIcon from "../../../assets/icons/clone_icon.svg";
import ViewIcon from "../../../assets/icons/view-event.svg";
import EventAgain from "../../../assets/icons/event_again.svg";
import CancelEvent from "../../../assets/icons/cancel_event.svg";
import { getBookingDetails } from "../../../actions/admin/scheduled-event-actions";
import { connect } from "react-redux";
import { notification, onModalOpen } from "../../../utils/utility";
import Loader from "../../../components/Loader/Loader";
import CancelModal from "./cancel-modal";
import RescheduleModal from "./reschedule-modal";
import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from "react-dom/server";

const ViewDetailsModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const navigate = useNavigate();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);

  useEffect(() => {
    const { getBookingDetails, bookingId } = props;
    setIsLoading(true);
    getBookingDetails({
      data: {
        id: bookingId,
      },
      onSuccess: function (response) {
        console.log(response);
        setData(response);
        setIsLoading(false);
      },
      onError: function (error) {
        console.error(error);
        setIsLoading(false);
      },
    });
  }, []);

  /**
   * hide modal
   */
  const hideCancelModal = () => {
    setShowCancelModal(false);
  };

  /**
   * hide reschedule modal
   */
  const hideRescheduleModal = () => {
    setShowRescheduleModal(false);
  };

  /**
   * on copy event summary
   */
  const onCopyEventSummary = () => {
    console.log("data", data);
    let tempInput = document.createElement("textarea");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    let content = "";

    content = (
      <table>
        <tbody>
          <tr>
            <td style={{ height: 10 }}></td>
          </tr>
          <tr>
            <td
              style={{
                fontFamily: "Sofia Pro",
                fontWeight: 700,
                color: "#0C4767",
                fontSize: 16,
              }}
            >
              Event Name:
            </td>
            <td
              style={{
                fontFamily: "Sofia Pro",
                color: "#626262",
                fontSize: 16,
                paddingLeft: 10,
              }}
            >
              {data.event_name}
            </td>
          </tr>
          <tr>
            <td style={{ height: 10 }}></td>
          </tr>
          <tr>
            <td
              style={{
                fontFamily: "Sofia Pro",
                fontWeight: 700,
                color: "#0C4767",
                fontSize: 16,
              }}
            >
              Event end date:
            </td>
            <td
              style={{
                fontFamily: "Sofia Pro",
                color: "#626262",
                fontSize: 16,
                paddingLeft: 10,
              }}
            >
              {data.end_date}
            </td>
          </tr>
          <tr>
            <td style={{ height: 10 }}></td>
          </tr>
          <tr>
            <td
              style={{
                fontFamily: "Sofia Pro",
                fontWeight: 700,
                color: "#0C4767",
                fontSize: 16,
              }}
            >
              Event type:
            </td>
            <td
              style={{
                fontFamily: "Sofia Pro",
                color: "#626262",
                fontSize: 16,
                paddingLeft: 10,
              }}
            >
              {data.is_event_paid === "E" ? "Paid" : "Free"}
            </td>
          </tr>
          <tr>
            <td style={{ height: 10 }}></td>
          </tr>
          <tr>
            <td
              style={{
                fontFamily: "Sofia Pro",
                fontWeight: 700,
                color: "#0C4767",
                fontSize: 16,
              }}
            >
              Event venue:
            </td>
            <td
              style={{
                fontFamily: "Sofia Pro",
                color: "#626262",
                fontSize: 16,
                paddingLeft: 10,
              }}
            >
              {data.venue}
            </td>
          </tr>
          <tr>
            <td style={{ height: 10 }}></td>
          </tr>
          <tr>
            <td
              style={{
                fontFamily: "Sofia Pro",
                fontWeight: 700,
                color: "#0C4767",
                fontSize: 16,
              }}
            >
              Event duration:
            </td>
            <td
              style={{
                fontFamily: "Sofia Pro",
                color: "#626262",
                fontSize: 16,
                paddingLeft: 10,
              }}
            >
              {data.event_duration}
            </td>
          </tr>
          <tr>
            <td style={{ height: 10 }}></td>
          </tr>
          <tr>
            <td
              style={{
                fontFamily: "Sofia Pro",
                fontWeight: 700,
                color: "#0C4767",
                fontSize: 16,
              }}
            >
              Event date:
            </td>
            <td
              style={{
                fontFamily: "Sofia Pro",
                color: "#626262",
                fontSize: 16,
                paddingLeft: 10,
              }}
            >
              {data.when}
            </td>
          </tr>
          <tr>
            <td style={{ height: 10 }}></td>
          </tr>
          <tr>
            <td
              style={{
                fontFamily: "Sofia Pro",
                fontWeight: 700,
                color: "#0C4767",
                fontSize: 16,
              }}
            >
              Invitee :
            </td>
            <td
              style={{
                fontFamily: "Sofia Pro",
                color: "#626262",
                fontSize: 16,
                paddingLeft: 10,
              }}
            >
              {data.invitee_name}
            </td>
          </tr>
          <tr>
            <td style={{ height: 10 }}></td>
          </tr>
          <tr>
            <td
              style={{
                fontFamily: "Sofia Pro",
                fontWeight: 700,
                color: "#0C4767",
                fontSize: 16,
              }}
            >
              Timezone:
            </td>
            <td
              style={{
                fontFamily: "Sofia Pro",
                color: "#626262",
                fontSize: 16,
                paddingLeft: 10,
              }}
            >
              {data.invitee_timezone}
            </td>
          </tr>
          <tr>
            <td style={{ height: 10 }}></td>
          </tr>
          <tr>
            <td
              style={{
                fontFamily: "Sofia Pro",
                fontWeight: 700,
                color: "#0C4767",
                fontSize: 16,
              }}
            >
              Host:
            </td>
            <td
              style={{
                fontFamily: "Sofia Pro",
                color: "#626262",
                fontSize: 16,
                paddingLeft: 10,
              }}
            >
              {data.host}
            </td>
          </tr>
        </tbody>
      </table>
    );

    document.getElementById("copy-element").innerHTML =
      renderToStaticMarkup(content);
    const str = document.getElementById("copy-element").innerHTML;

    document.addEventListener("copy", (e) => {
      e.clipboardData.setData("text/html", str);
      e.clipboardData.setData("text/plain", str);
      e.preventDefault();
    });
    document.execCommand("copy");
    document.removeEventListener("copy", (e) => {
      e.clipboardData.setData("text/html", str);
      e.clipboardData.setData("text/plain", str);
      e.preventDefault();
    });

    notification.success("Event summary copied successfully");
  };

  return (
    <>
      <Loader isShowLoader={isLoading} />
      <Modal
        modalClassName="event_modal"
        isOpen={props.showViewDetailModal && !isLoading}
        toggle={props.handleClose}
        centered
      >
        <ModalHeader toggle={props.handleClose}>
          {data && (
            <>
              <PDFDownloadLink document={<BookingDetailPdf data={data} />}>
                {({ loading }) =>
                  loading ? (
                    <></>
                  ) : (
                    <button className="btn p-0">
                      <img src={PrintIcon} alt="print icon" />
                    </button>
                  )
                }
              </PDFDownloadLink>
            </>
          )}

          <button className="btn p-0" onClick={onCopyEventSummary}>
            <img src={CloneIcon} alt="print icon" />
          </button>
          <button
            className="btn p-0"
            onClick={(event) => {
              event.preventDefault();
              navigate(`/admin/event-summary/${data.event_id}?view-only=true`);
            }}
          >
            <img src={ViewIcon} alt="print icon" />
          </button>
          <button className="btn p-0">
            <img
              src={EventAgain}
              alt="Reschedule"
              onClick={() => {
                setShowRescheduleModal(true);
                onModalOpen();
              }}
            />
          </button>
          <button className="btn p-0">
            <img
              src={CancelEvent}
              onClick={() => {
                setShowCancelModal(true);
              }}
              alt="print icon"
            />
          </button>
        </ModalHeader>
        <ModalBody>
          <div id="copy-element" className="d-none"></div>
          <h4>{data ? data.event_name : null}</h4>
          <div className="row justify-content-between">
            <div className="col-md-6">
              <p>
                Ends: <span>{data ? data.end_date : null}</span>
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Event Venue: <span>{data ? data.venue : null}</span>
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Event type:
                <span>
                  {data
                    ? data.is_event_paid === "E"
                      ? `Paid-$${data.fee}`
                      : "Free"
                    : null}
                </span>
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Event Duration: <span>{data ? data.event_duration : null}</span>
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Timezone: <span>{data ? data.invitee_timezone : null}</span>
              </p>
            </div>
            <div className="col-md-6">
              <p>
                When:{" "}
                <span>
                  {data
                    ? data.when + " " + data.start_time + " to " + data.end_time
                    : null}
                </span>
              </p>
            </div>
            <div className="col-12">
              <p>
                Invitee: <span>{data ? data.invitee : null}</span>
              </p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-start">
          <div className="row justify-content-between">
            <div className="col-12 author">
              <div className="img">
                <img
                  src="https://www.staging.thrive-booking.com/backend/public/uploads/profile-images/profile-1-1655986264.jpg"
                  alt="Author Profile Img"
                />
              </div>
              <p>{data ? data.host : null}</p>
            </div>
            <div className="col-md-6">
              <p className="slot">
                {data ? data.start_time : null} to {data ? data.end_time : null}
              </p>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      {showCancelModal && (
        <CancelModal
          showCancelModal={showCancelModal}
          handleViewDetailClose={props.handleClose}
          handleClose={hideCancelModal}
          data={data}
          loadBookings={props.loadBookings}
        />
      )}
      {showRescheduleModal && (
        <RescheduleModal
          showRescheduleModal={showRescheduleModal}
          handleViewDetailClose={props.handleClose}
          handleClose={hideRescheduleModal}
          data={data}
          loadBookings={props.loadBookings}
        />
      )}
    </>
  );
};

export default connect(null, {
  getBookingDetails,
})(ViewDetailsModal);
