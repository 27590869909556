import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody
} from "reactstrap";
import * as Icon from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../../assets/images/logo-new.svg";
import FeatureRequestModal from "../FeatureRequestModal";
import { connect, useDispatch } from "react-redux";
import {
  IMAGE_BASE_URL
} from "../../../../utils/utility";
import {
  TOGGLE_ADMIN_PROFILE_DROPDOWN,
  TOGGLE_MOB_LEFT_MENU,
  SET_USER_CONFIG,
} from "../../../../actions/types";
import { getCompanyData } from "../../../../actions/companyActions";
import Loader from "../../../../components/Loader/Loader";
import NewScheduleIcon from "../../../../assets/icons/new_schedule.svg";
import MeetOctoIcon from "../../../../assets/icons/meetocto_icon.svg";
// import SettingsMenu from "./settings-menu";
import Notification from "./notification";
import { sendGetRequest } from "../../../../actions/app-actions";
import { getAdminBusinessId } from "../../../../utils/authHelper";

const UserDropdown = (props) => {
  return (
    <DropdownMenu>
      <div className="rd_menuthingcont">
        <Link to="/staff/profile" className="dropdown-item cus_icons">
          <Icon.User
            size={14}
            className="mr-50"
            color="#0C4767"
            strokeWidth="1.4"
          />
          <span className="align-middle">Profile</span>
        </Link>
        <Link
          to="/staff/calendar"
          className="dropdown-item cus_icons rect line"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 21.8 21.8"
            className="mr-50"
          >
            <g transform="translate(-220.1 -32.1)">
              <g transform="translate(218 31)">
                <rect
                  width="20"
                  height="18"
                  rx="2"
                  transform="translate(3 4)"
                  fill="none"
                  stroke="#626262"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.4"
                />
                <line
                  y2="3"
                  transform="translate(18 2)"
                  fill="none"
                  stroke="#626262"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.4"
                />
                <line
                  y2="3"
                  transform="translate(8 2)"
                  fill="none"
                  stroke="#626262"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.4"
                />
                <line
                  x2="20"
                  transform="translate(3 9)"
                  fill="none"
                  stroke="#626262"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.4"
                />
              </g>
              <line
                x2="5"
                transform="translate(224 43.5)"
                fill="none"
                stroke="#707070"
                stroke-linecap="round"
                stroke-width="1.4"
              />
              <line
                x2="8.5"
                transform="translate(224 47.5)"
                fill="none"
                stroke="#707070"
                stroke-linecap="round"
                stroke-width="1.4"
              />
            </g>
          </svg>
          <span className="align-middle">Calendar </span>
        </Link>
        <DropdownItem divider />
        <Link
          to="#"
          className="dropdown-item cus_icons"
          onClick={props.userLogout}
        >
          <Icon.Power
            size={14}
            className="mr-50"
            color="#0C4767"
            strokeWidth="1.4"
          />
          <span className="align-middle">Log Out</span>
        </Link>
      </div>
    </DropdownMenu>
  );
};

const StaffTopNavbar = (props) => {
  const navigate = useNavigate();
  const [showUserProfileDropdown, setShowUserProfileDropdown] = useState(false);
  const [featureRequestStatus, setFeatureRequestStatus] = useState(false);
  const [notiDropdownOpen, setNotiDropdownOpen] = useState(false);
  const [notiInnerOpen, setNotiInnerOpen] = useState(false);
  const [onlyUnreadNoti, setOnlyUnreadNoti] = useState(false);
  const [profileDrop, setProfileDrop] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [tipsActive, setTipsActive] = useState(false);
  const [settingsActive, setSettingsActive] = useState(false);
  const notiToggle = () => setNotiDropdownOpen((prevState) => !prevState);
  const notiInnerToggle = () => setNotiInnerOpen((prevState) => !prevState);
  const profileToggle = () => setProfileDrop((prevState) => !prevState);
  const onTipsActive = () => setTipsActive((prevState) => !prevState);
  const onSettingsToggle = () => setSettingsActive((prevState) => !prevState);
  const unreadNotiSelect = () => setOnlyUnreadNoti(true);
  const goToNotiSettings = () => {
    navigate("/admin/notification-settings");
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const { sendGetRequest } = props;
    sendGetRequest({
      url: `configuration/${getAdminBusinessId()}`,
      onSuccess: function (response) {
        dispatch({
          type: SET_USER_CONFIG,
          payload: response.data,
        });
      },
      onError: function (error) {},
    });
  }, []);

  /**
   * hide feature request modal
   */
  const hideFeatureRequestModal = () => {
    setFeatureRequestStatus(false);
  };

  /**
   * toggle user drop down
   */
  const toggleUserDropdown = (event) => {
    const { layout } = props;

    if (!layout.profileDropdownStatus) {
      dispatch({
        type: TOGGLE_ADMIN_PROFILE_DROPDOWN,
        payload: !layout.profileDropdownStatus,
      });
    } else {
      setTimeout(() => {
        dispatch({
          type: TOGGLE_ADMIN_PROFILE_DROPDOWN,
          payload: !layout.profileDropdownStatus,
        });
      }, 500);
    }
  };

  const { user } = props.auth;
  // console.log(user);
  return (
    <>
      <Modal
        className="quick_tips_popup"
        isOpen={tipsActive}
        toggle={onTipsActive}
        centered={true}
      >
        <ModalBody>
          <div className="d-flex align-items-start">
            <div className="img">
              <img src={MeetOctoIcon} alt="" />
            </div>
            <p>
              This tips and tricks pop up will display here in the center of the
              page wgenver uers login. we may keep it up to 3 months or always.
            </p>
          </div>
          <div className="row justify-content-between mt-4">
            <div className="col-md-6 mb-3 mb-md-0">
              <button className="btn btn-danger" onClick={onTipsActive}>
                Don't show again
              </button>
            </div>
            <div className="col-6 col-md-3">
              <button className="btn rd_cancel">Back</button>
            </div>
            <div className="col-6 col-md-3">
              <button className="btn btn-app">Next</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <header className="rd_headerthing">
        <Loader isShowLoader={isLoading} />
        <div className="topheadertext">
          Your <span>Free</span> trial ends in 10 days
          <Link to="/admin/manage-subscription"> Upgrade now </Link>
        </div>
        <div className="headercont">
          <div className="logocont w-auto d-none d-md-flex align-items-center">
            <a href="#" className="logo">
              <img src={logo} alt="MeetOcto Logo" />
            </a>
          </div>
          <div
            className="mob_menu d-flex align-items-center d-md-none"
            onClick={() => {
              console.log("working now");
              dispatch({
                type: TOGGLE_MOB_LEFT_MENU,
                payload: "active",
              });
            }}
          >
            <Icon.Menu size={20} color="#0C4767" />
          </div>

          {featureRequestStatus && (
            <FeatureRequestModal
              hideFeatureRequestModal={hideFeatureRequestModal}
            />
          )}

          <div className="notificationtabmen">
            <div className="rd_flexrow d-none d-lg-flex">
              <div className="rd_flexrowitem dispnonemobile">
                <div
                  className={`buttonnotgcont react-quicktips ${
                    tipsActive ? "active" : ""
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.489"
                    height="32.903"
                    viewBox="0 0 27.489 32.903"
                  >
                    <path
                      d="M2163.968,748.191a13.741,13.741,0,1,0-8.467,26.145l3.683,5.782a.66.66,0,0,0,.2.2.653.653,0,0,0,.9-.2l3.679-5.782a13.741,13.741,0,0,0,0-26.145Zm-1.911,16.895h-9.939c-.128,0-.234-.223-.234-.5s.105-.5.234-.5h9.939c.128,0,.232.225.232.5S2162.185,765.086,2162.057,765.086Zm5.173-3.958h-14.994c-.194,0-.352-.225-.352-.5s.158-.5.352-.5h14.994c.2,0,.352.224.352.5S2167.426,761.128,2167.23,761.128Zm0-3.959h-14.994c-.194,0-.352-.223-.352-.5s.158-.5.352-.5h14.994c.2,0,.352.223.352.5S2167.426,757.169,2167.23,757.169Z"
                      transform="translate(-2145.989 -747.519)"
                      fill="#8e8e8e"
                    />
                  </svg>
                  <button
                    className="rd_iconthing rd_quicktips"
                    onClick={onTipsActive}
                  >
                    <span>Quick Tips</span>
                  </button>
                </div>
              </div>
              <div className="rd_flexrowitem dispnonemobile">
                <div className="buttonnotgcont">
                  <a
                    target="_blank"
                    href="https://meetocto.freshdesk.com/support/home"
                    className="buttonnotgcont"
                  >
                    <button className="rd_iconthing rd_supproticonbtn">
                      <span>Support</span>
                    </button>
                  </a>
                </div>
              </div>
              <div className="rd_flexrowitem dispnonemobile">
                <div className="buttonnotgcont">
                  <button
                    className="rd_iconthing rd_requesticonthtnb"
                    onClick={(event) => {
                      event.preventDefault();
                      setFeatureRequestStatus(true);
                    }}
                  >
                    <span>Ideas</span>
                  </button>
                </div>
              </div>

              <Notification />
              <div className="rd_flexrowitem dispnonemobile">
                <div className="menumobileham">
                  <button className="humbergermenu"></button>
                </div>
                <div className="accountdiccon">
                  <div className="imageaccountnav">
                    {user?.profile_image === "" ? (
                      <>
                        <div className="name_initial">
                          {user.first_name.charAt(0) + user.last_name.charAt(0)}
                        </div>
                      </>
                    ) : (
                      <img
                        src={IMAGE_BASE_URL + user?.profile_image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "45%",
                        }}
                      />
                    )}
                  </div>
                  <Dropdown
                    isOpen={props.layout.profileDropdownStatus}
                    toggle={toggleUserDropdown}
                  >
                    <DropdownToggle
                      className="profile_menu_nav p-0"
                      color="none"
                    >
                      <div className="accountnamenavbar">
                        <p>
                          <strong>{`${user?.first_name} ${user?.last_name}`}</strong>
                        </p>
                        <button className="detaiaccountmo">Sign me out</button>
                      </div>
                    </DropdownToggle>
                    <UserDropdown {...props} />
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="rd_flexrow d-lg-none">
              <div className="rd_flexrowitem">
                <div className="buttonnotgcont">
                  {/* <button className="rd_iconthing notbtnnav notifiexist">
                  <span>Notification</span>
                </button> */}
                  <Dropdown
                    isOpen={notiDropdownOpen}
                    toggle={notiToggle}
                    className="profile_dropdown"
                  >
                    <DropdownToggle caret>
                      <div className="noti_num">
                        {props.notification.totalUnread > 9
                          ? "9+"
                          : props.notification.totalUnread}
                      </div>
                      <span className="rd_iconthing notbtnnav notifiexist">
                        {/* Notification */}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* Top Drop Option */}
                      <DropdownItem
                        className="drop_options d-flex align-items-center justify-content-between mb-4"
                        text
                      >
                        <h2>Notificationss</h2>
                        <FormGroup className="ml-auto" check inline>
                          <Input
                            type="checkbox"
                            checked={onlyUnreadNoti}
                            onChange={unreadNotiSelect}
                          />
                          <Label className="mb-1" check>
                            View unread messages
                          </Label>
                        </FormGroup>
                        <Dropdown
                          isOpen={notiInnerOpen}
                          toggle={notiInnerToggle}
                        >
                          <DropdownToggle className="inner_drop_toggle" caret>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#0C4767"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                            </svg>
                          </DropdownToggle>
                          <DropdownMenu className="inner_drop">
                            <DropdownItem>Mark all as read</DropdownItem>
                            <DropdownItem onClick={goToNotiSettings}>
                              Notifications Setting
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </DropdownItem>
                      {/* Top Drop Option End */}

                      {(props.notification.notifications || []).map(
                        (notification, index) => {
                          return (
                            <>
                              <DropdownItem className="notification-item unread">
                                <div className="row no-gutters">
                                  <div className="col-auto noti_img pr-2">
                                    <div className="user_img">
                                      <img
                                        src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg"
                                        alt="User Profile Pic"
                                      />
                                    </div>
                                    <div className="noti_icon">
                                      <img
                                        src={NewScheduleIcon}
                                        alt="Notificion Icon"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-auto noti_txt pl-2">
                                    <h4>{notification.title}</h4>
                                    <p>{notification.description}</p>
                                    <p className="time">An hour ago</p>
                                  </div>
                                </div>
                              </DropdownItem>
                              <DropdownItem divider />
                            </>
                          );
                        }
                      )}

                      <DropdownItem divider />
                      <div className="view_all">
                        <Link to="/admin/notifications">
                          View All Notifications
                        </Link>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              {/* <div className="rd_flexrowitem">
                <div className="buttonnotgcont text-center mob_sett">
                  <SettingsMenu
                    toggleSettings={onSettingsToggle}
                    settingState={settingsActive}
                  />
                </div>
              </div> */}
              <div className="rd_flexrowitem">
                <div className="accountdiccon">
                  <div className="imageaccountnav">
                    {user?.profile_image === "" ? (
                      <>
                        {/* <img src="assets/images/Oval.png" alt="" /> */}
                        <div className="name_initial">
                          {user.first_name.charAt(0) + user.last_name.charAt(0)}
                        </div>
                      </>
                    ) : (
                      <img
                        src={IMAGE_BASE_URL + user?.profile_image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "45%",
                        }}
                      />
                    )}
                  </div>
                  <Dropdown
                    isOpen={props.layout.profileDropdownStatus}
                    toggle={toggleUserDropdown}
                  >
                    <DropdownToggle
                      className="profile_menu_nav p-0"
                      color="none"
                    >
                      <div className="accountnamenavbar">
                        <p>
                          <strong>{`${user?.first_name} ${user?.last_name}`}</strong>
                        </p>
                        {/* <button className="detaiaccountmo"></button> */}
                      </div>
                    </DropdownToggle>
                    <UserDropdown {...props} />
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
  notification: state.notification,
});

export default connect(mapStateToProps, { getCompanyData, sendGetRequest })(
  StaffTopNavbar
);
