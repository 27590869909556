import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getVacations,
  deleteVacation,
  saveVacation,
} from "../../../../actions/admin/vacation-actions";
import { toggleFilter } from "../../../../actions/filterActions";
import { Table, Menu, Dropdown } from "antd";
import { Col, Row } from "reactstrap";
import Loader from "../../../../components/Loader/Loader";
import isEmpty from "../../../../validation/is-empty";
import DeleteVacationModal from "./delete";
import { Edit } from "react-feather";
import EditModal from "./edit";
import CreateModal from "./create";
import "antd/dist/antd.css";
import { notification } from "../../../../utils/utility";
import Filter from "../../../../components/Filter/Filter";
import VacationFilters from "./vacation-filters";
import PaginatorDropdown from "../../../../components/Datatable/PaginationDropdown";
import ActionDots from "../../../../components/Datatable/ActionDots";
import moment from "moment";

const VacationListing = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  });

  const [data, setData] = useState([]);

  const [rowData, setRowData] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const columns = [
    {
      title: "Vacation Name",
      dataIndex: "vacation_name",
      sorter: true,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      sorter: true,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      sorter: true,
    },
    {
      title: "No of Days",
      dataIndex: "no_of_days",
      render: (_, data) => {
        if (data.start_date && data.end_date) {
          const startDate = moment(data.start_date, "DD-MM-YYYY");
          const endDate = moment(data.end_date, "DD-MM-YYYY");
          const noOfDays = endDate.diff(startDate, "days");
          return noOfDays > 0 ? noOfDays : 1;
        } else {
          return 0;
        }
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, data) => {
        if (data.end_date) {
          let endDate = data.end_date.split("-");
          let vacationEndDate = "";
          if (endDate.length === 3) {
            vacationEndDate = new moment(
              endDate[2] + "-" + endDate[1] + "-" + endDate[0]
            );
            const todayDate = moment();
            let menu;
            if (todayDate > vacationEndDate) {
              // vacation date is past
              menu = (
                <Menu
                  items={[
                    {
                      label: (
                        <>
                          <DeleteVacationModal
                            onDeleteRecord={onDeleteRecord}
                            data={data}
                          />
                        </>
                      ),
                      key: "1",
                    },
                  ]}
                />
              );
            } else {
              // vacation date is future
              menu = (
                <Menu
                  items={[
                    {
                      label: (
                        <span
                          onClick={() => {
                            onEditClick(data);
                          }}
                        >
                          <Edit className="mr-2" size={15} /> Edit
                        </span>
                      ),
                      key: "0",
                    },
                    {
                      type: "divider",
                    },
                    {
                      label: (
                        <>
                          <DeleteVacationModal
                            onDeleteRecord={onDeleteRecord}
                            data={data}
                          />
                        </>
                      ),
                      key: "1",
                    },
                  ]}
                />
              );
            }

            return (
              <div className="actions cursor-pointer">
                <Dropdown overlay={menu} trigger={["click"]}>
                  <a onClick={(e) => e.preventDefault()}>
                    <ActionDots />
                  </a>
                </Dropdown>
              </div>
            );
          }
        }
      },
    },
  ];

  /**
   * hide edit modal
   */
  const hideEditModal = () => {
    setShowEditModal(false);
  };

  /**
   * on Edit icon click
   * @param {*} data
   */
  const onEditClick = (data) => {
    setRowData(data);
    setShowEditModal(true);
  };

  /**
   * on delete record
   * @param {*} id
   */
  const onDeleteRecord = (id) => {
    const { deleteVacation } = props;
    setIsDataLoading(true);
    deleteVacation({
      data: {
        vacationId: id,
      },
      onSuccess: function (data) {
        notification.success("Record successfully deleted");
        handleTableChange(
          {
            current: 1,
            pageSize: 10,
          },
          {},
          {}
        );
        setIsDataLoading(false);
      },
      onError: function (error) {
        notification.error("An error occured during delete, Please try later");
        console.log("error", error);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * add record
   * @param {*} data
   */
  const onAddRecord = (data) => {
    const { saveVacation, filter } = props;
    setIsPageLoading(true);
    setIsDataLoading(true);
    saveVacation({
      data: {
        ...data,
      },
      onSuccess: (response) => {
        const { success } = response;
        if (success) {
          notification.success("Vacation successfully added");
        } else {
          notification.error(
            "An error occured during update, please try later."
          );
        }
        handleTableChange(
          {
            ...pagination,
          },
          {
            ...filter.searchFilters,
          },
          {}
        );
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
      onError: (error) => {
        console.log("error", error);
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on update record
   * @param {*} data
   */
  const onUpdateRecord = (data) => {
    const { saveVacation, filter } = props;
    setIsPageLoading(true);
    setIsDataLoading(true);
    saveVacation({
      data: {
        ...data,
      },
      onSuccess: (response) => {
        const { success } = response;
        if (success) {
          notification.success("Vacation successfully updated");
        } else {
          notification.error(
            "An error occured during update, please try later."
          );
        }
        handleTableChange(
          {
            ...pagination,
          },
          {
            ...filter.searchFilters,
          },
          {}
        );
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
      onError: (error) => {
        console.log("error", error);
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on filter data table
   * @param {*} name
   * @param {*} value
   */
  const filterRecords = () => {
    const { toggleFilter, filter } = props;
    toggleFilter(false);
    handleTableChange(
      {
        current: 1,
        pageSize: 10,
      },
      {
        ...filter.searchFilters,
      },
      {}
    );
  };

  /**
   * on clear filter handler
   */
  const clearFilterHandler = () => {
    handleTableChange(
      {
        ...pagination,
      },
      {},
      {}
    );
  };

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  const prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * fetch data table data
   * @param {*} params
   */
  const fetchTableData = (params = {}) => {
    const { getVacations } = props;
    setIsDataLoading(true);
    getVacations({
      data: prepareDataParam(params),
      onSuccess: function (data) {
        setData(data.entity);
        setPagination({
          ...params.pagination,
          total: data.totalCount,
        });
        setIsDataLoading(false);
      },
      onError: function (error) {
        setData([]);
        console.log("error", error);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on page size drop down change
   * @param {*} e  event
   */
  const onPageSizeChange = (e) => {
    handleTableChange(
      {
        //current: pagination.current,
        current: 1,
        pageSize: e.target.value,
        total: pagination.total,
      },
      {},
      {}
    );
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  const handleTableChange = (pagination, filters, sorter) => {
    fetchTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  useEffect(() => {
    fetchTableData({ pagination });
  }, []);

  return (
    <>
      <Row className="app-user-list">
        <Loader isShowLoader={isPageLoading} />
        {showEditModal && (
          <EditModal
            onUpdateRecord={onUpdateRecord}
            data={rowData}
            showEditModal={showEditModal}
            handleClose={hideEditModal}
          />
        )}
        <Col sm="12">
          <Filter
            name="Vacation"
            className="with_btn"
            tootipCode="P7F1"
            onFilterRecords={filterRecords}
            onClearFilterHandler={clearFilterHandler}
            showButtons={true}
            buttons={<CreateModal onAddRecord={onAddRecord} />}
          >
            <VacationFilters />
          </Filter>
        </Col>
        <Col sm="12">
          <Table
            className="vacation_table"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={pagination}
            loading={isDataLoading}
            onChange={handleTableChange}
          ></Table>
        </Col>

        <Col sm="6" className="table_paginaton">
          <PaginatorDropdown
            onPageSizeChange={onPageSizeChange}
            pageSize={pagination.pageSize}
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  getVacations,
  deleteVacation,
  saveVacation,
  toggleFilter,
})(VacationListing);
