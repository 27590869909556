import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "../../../components/FormField/FormField";

const validation = Yup.object().shape({
  first_name: Yup.string().required("First Name is required."),
  last_name: Yup.string().required("Last Name is required."),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      "Please enter valid email"
    )
    .required("Email is required"),
});

let initialValues = {
  first_name: "",
  last_name: "",
  email: "",
};

class InviteUserModal extends Component {
  state = {
    isOpen: false,
  };

  /**
   * on toggle modal
   */
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  render() {
    const { isOpen } = this.state;
    const { onInviteStaff } = this.props;

    return (
      <>
        <button className="btn btn-app w-auto" onClick={this.toggle}>
          <span className="mr-2">+</span> Invite User
        </button>
        <Modal isOpen={isOpen} toggle={this.toggle}>
          <ModalBody>
            <div className="">
              <div className="eventdetailsaddbox rd_modalvieew">
                <div className="box_content rd_modalboxcontent">
                  <div className="rd_inputselectheadermodalsetting">
                    <button
                      className="rd_colsebtn"
                      onClick={this.toggle}
                    ></button>
                  </div>
                  <h3 className="rd_modalheadertext rd_modalheadertextleft">
                    Invite new user
                  </h3>

                  <Formik
                    validationSchema={validation}
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={(data) => {
                      console.log(data);
                      this.toggle();
                      onInviteStaff(data);
                    }}
                  >
                    {(formProps) => {
                      const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        setFieldValue,
                      } = formProps;

                      return (
                        <Form>
                          <div className="rd_addnewuserfieth">
                            <label htmlFor="">First Name *</label>
                            <div className="input-group">
                              <FormField
                                type="text"
                                name="first_name"
                                elementclasses="p-2"
                                placeholder="Enter First Name"
                                value={values.first_name}
                                errors={errors}
                                touched={touched}
                                onChange={({ target }) => {
                                  const { value } = target;
                                  handleChange({
                                    target: { name: "first_name", value },
                                  });
                                }}
                              />
                            </div>

                            <label htmlFor="">Last Name *</label>
                            <div className="input-group">
                              <FormField
                                type="text"
                                name="last_name"
                                elementclasses="p-2"
                                placeholder="Enter Last Name"
                                value={values.last_name}
                                errors={errors}
                                touched={touched}
                                onChange={({ target }) => {
                                  const { value } = target;
                                  handleChange({
                                    target: { name: "last_name", value },
                                  });
                                }}
                              />
                            </div>

                            <label htmlFor="">Email *</label>
                            <div className="input-group">
                              <FormField
                                type="text"
                                name="email"
                                elementclasses="p-2"
                                placeholder="Enter Email"
                                value={values.email}
                                errors={errors}
                                touched={touched}
                                onChange={({ target }) => {
                                  const { value } = target;
                                  handleChange({
                                    target: { name: "email", value },
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="rd_cancelappbtnmodal">
                            <button
                              className="btn btn-danger"
                              onClick={this.toggle}
                            >
                              Back
                            </button>
                            <button type="submit" className="btn btn-app">
                              Send
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default InviteUserModal;
