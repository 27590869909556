import axios from "../../axios-instance";
import { fallBackErrorMessage } from "../../constants/event";
/**
 * create staff
 * @param {*} options
 * @returns
 */
export const inviteUser = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("/admin/invite-user", data)
    .then((res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};
