import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
// import { Data } from "./DataSalesChart";
import LineChartIcon from "../../../../../assets/icons/line_chart_icon.svg";
import BarChartIcon from "../../../../../assets/icons/bar_chart_icon.svg";
import LineChartWhite from "../../../../../assets/icons/line_chart_white.svg";
import BarChartClr from "../../../../../assets/icons/bar_chart_color.svg";
import RefresIcon from "../../../../../assets/icons/refresh_icon.svg";
import useMediaQuery from "../../../../../hooks/useMediaQuery";
import DatePicker from "react-date-picker";
import { sendPostRequest } from "../../../../../actions/app-actions";
import { getAdminBusinessId } from "../../../../../utils/authHelper";
import { connect } from "react-redux";
import moment from "moment";
import Tooltip from "../../../../../components/Tooltip";

Chart.register(...registerables);
const SalesChart = (props) => {
  const [chartType, setChartType] = useState("bar");
  const [chartData, setChartData] = useState(null);
  const [renderChart, setRenderChart] = useState(false);
  const [isMonthlyView, setIsMonthlyView] = useState(true);
  const [filterYear, setFilterYear] = useState(new Date());
  const [data, setData] = useState([]);

  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const isTablet = useMediaQuery("(min-width: 768px)");
  const isMobile = useMediaQuery("(min-width: 250px)");
  useEffect(() => {
    setChartData({
      labels: data.map((row) => row.index),
      datasets: [
        {
          label: "Sale",
          data: data.map((data) => data.total),
          backgroundColor: "#FD9926",
          borderRadius: 10,
          borderWidth: chartType === "line" ? 2 : 0,
          borderColor: chartType === "line" ? "#FD9926" : "transparent",
          tension: chartType === "line" ? 0.5 : 0,
          pointRadius: chartType === "line" ? (isTablet ? 5 : 2) : 0,
        },
      ],
    });
    setRenderChart(true);
  }, [chartType]);

  useEffect(() => {
    loadChartData({
      is_month_view: "E",
    });
  }, []);

  /**
   * loads chart data
   * @param {*} data
   */
  const loadChartData = (data = {}) => {
    const { sendPostRequest } = props;

    sendPostRequest({
      url: `/admin/event-sales-chart/${getAdminBusinessId()}`,
      data,
      onSuccess: function (response) {
        const { data } = response;
        setData(data.data);
        setChartData({
          labels: data.data.map((row) => row.index),
          datasets: [
            {
              label: "Sale",
              data: data.data.map((data) => data.total),
              backgroundColor: "#FD9926",
              borderRadius: 10,
              borderWidth: chartType === "line" ? 2 : 0,
              borderColor: chartType === "line" ? "#FD9926" : "transparent",
              tension: chartType === "line" ? 0.5 : 0,
              pointRadius: chartType === "line" ? (isTablet ? 5 : 2) : 0,
            },
          ],
        });
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
  };

  return (
    <>
      <div className="page_title_dashboard">
        <div className="row justify-content-between">
          <div className="col-auto mb-3 mb-md-0">
            <h2>Sales Chart <Tooltip code="P3F5" /> </h2>
          </div>
          <div className="col-auto d-flex justify-content-end">
            {isMonthlyView && (
              <DatePicker
                name="start_date"
                className="cus_datepicker me-3"
                format="yyyy"
                maxDetail="decade"
                showNavigation={true}
                value={filterYear}
                locale="en-GB"
                selected={(filterYear && new Date(filterYear)) || null}
                onChange={(val) => {
                  setFilterYear(val);
                  loadChartData({
                    is_month_view: isMonthlyView ? "E" : "D",
                    year: val ? moment(val).format("YYYY") : null,
                  });
                }}
              />
            )}
            <div className="rd_vacationflex2 dash_filter_bar mr-0">
              <label className="pr-2 mb-0">Monthly</label>
              <div className="toggle_field">
                <div className="box_content bg-transparent p-0">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="paypalGuestPayment"
                      onChange={(e) => {
                        setIsMonthlyView(!e.target.checked);
                        loadChartData({
                          is_month_view: !e.target.checked ? "E" : "D",
                          year: filterYear
                            ? moment(filterYear).format("YYYY")
                            : filterYear,
                        });
                      }}
                      checked={!isMonthlyView}
                    />
                  </div>
                </div>
              </div>
              <label className="pl-2 mb-0">Annualy</label>
            </div>
          </div>
        </div>
      </div>

      <div className="rd_vacationfilterpart mt-2">
        <div className="col-12">
          <div className="chart_container">
            <div className="head">
              <div className="row justify-content-between align-items-center">
                <div className="col-auto page_title_dashboard">
                  <h2>Stats</h2>
                </div>
                <div className="col-auto">
                  <div className="tools d-block">
                    {/* Chart Type */}
                    <div className="chart_type">
                      <button
                        className={`btn ${chartType === "line" ? "active" : ""
                          }`}
                        onClick={() => setChartType("line")}
                      >
                        <img
                          src={
                            chartType === "line"
                              ? LineChartWhite
                              : LineChartIcon
                          }
                          alt="Line Chart Icon"
                        />
                      </button>
                      <button
                        className={`btn ${chartType === "bar" ? "active" : ""}`}
                        onClick={() => setChartType("bar")}
                      >
                        <img
                          src={chartType === "bar" ? BarChartIcon : BarChartClr}
                          alt="Bar Chart Icon"
                        />
                      </button>
                      <button
                        className="btn refres_btn"
                        onClick={() => {
                          setIsMonthlyView(true);
                          setFilterYear(null)
                          loadChartData({
                            is_month_view: true,
                            year: null,
                          });

                        }}
                      >
                        <img src={RefresIcon} alt="Refresh Icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {renderChart && chartType === "bar" ? (
              <Bar
                data={chartData}
                options={{
                  scales: {
                    y:
                    {
                      min: 0,
                      //stepSize: 1,
                      //max:15,
                      ticks: {
                        //precision: 0,
                        // Include a dollar sign in the ticks
                        callback: function (value, index, ticks) {
                          return '$ ' + value;
                        }
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            ) : null}
            {renderChart && chartType === "line" ? (
              <Line
                data={chartData}
                options={{
                  scales: {
                    y:
                    {
                      min: 0,
                      //stepSize: 1,
                      //max:15,
                      ticks: {
                        //precision: 0,
                        // Include a dollar sign in the ticks
                        callback: function (value, index, ticks) {
                          return '$ ' + value;
                        }
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { sendPostRequest })(SalesChart);
