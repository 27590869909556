import React from "react";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import { TODAY_CALENDAR_MONTH } from "../../actions/types";
import { setCalendarView } from "../../actions/calendarActions";

const WeekView = (props) => {
  const dispatch = useDispatch();

  /**
   * render day namess
   */
  const renderDayNames = () => {
    const { selectedDate, currentDate } = props;
    const dated = moment(selectedDate);
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          className={`calendar_head ${
            moment(currentDate).isSame(
              dated.startOf("week").add(i, "days"),
              "day"
            )
              ? "active"
              : ""
          }`}
          key={i}
        >
          <span className="date">
            {dated.startOf("week").add(i, "days").format("DD")}
          </span>
          <span>{dated.startOf("week").add(i, "days").format("ddd")}</span>
        </div>
      );
    }

    return <div className="calendar_head_main">{days}</div>;
  };

  /**
   * render vertical times col
   */
  const renderTimeRows = () => {
    const timeSlotRows = [];
    // add 12:00 am to 1:00 AM slot
    timeSlotRows.push(
      <div className="time_slot">
        <p>
          <span>&nbsp;</span>
        </p>
      </div>
    );
    for (let i = 1; i < 24; i++) {
      timeSlotRows.push(
        <div className="time_slot" key={i}>
          <p>
            {i <= 12 ? i : i - 12} <span>{i < 12 ? "am" : "pm"}</span>
          </p>
        </div>
      );
    }
    return <div className="cal_time_slots">{timeSlotRows}</div>;
  };

  /**
   * on view more button c
   * @param {*} calendarDate
   */
  const onViewMoreHandler = (calendarDate) => {
    dispatch({
      type: TODAY_CALENDAR_MONTH,
      payload: new Date(calendarDate),
    });
    props.setCalendarView("day");
  };

  /**
   *
   * @param {*} hour
   */
  const getStartTimeEndTime = (hour) => {
    hour = hour - 1;
    if (hour === 0) {
      return { startTime: "12:00am", endTime: "1:00am" };
    } else {
      /*
      let leftPadding = "";
      if (hour < 10) {
        leftPadding = "0";
      }
      */
      return {
        startTime:
          (hour > 12 ? hour - 12 : hour) + ":00" + (hour >= 12 ? "pm" : "am"),
        endTime:
          (hour >= 12 ? hour - 12 : hour) +
          1 +
          ":00" +
          (hour + 1 >= 12 && hour + 1 !== 24 ? "pm" : "am"),
      };
    }
  };

  /**
   *
   * @param {*} hour
   */
  const getCalendarHourEvents = (hour, calendarDate) => {
    //const { events } = props.calendar;
    const { onSelectEvent } = props.calendar;
    const { events } = props;
    let eventSlots = [];

    let { startTime, endTime } = getStartTimeEndTime(hour);
    //console.log("start time", startTime, "end time", endTime);
    startTime = moment(startTime, "hh:mma").format("hh:mma");
    endTime = moment(endTime, "hh:mma").format("hh:mma");

    calendarDate = moment(calendarDate).format("yyyy-MM-DD");

    console.log("calendar date", calendarDate);

    const dated = moment(calendarDate).format("yyyy-MM-DD");

    var eventCount = 0;
    //console.log("eventts", events);
    events.forEach((event, index) => {
      const { id, start, end, title } = event;
      let eventStartTime = moment(start, "hh:mma").format("hh:mma");
      let eventEndTime = moment(end, "hh:mma").format("hh:mma");
      const eventStartedDate = moment(start).format("yyyy-MM-DD");

      /*
      console.log(
        "calendar date",
        calendarDate,
        moment(calendarDate).isSame(eventStartedDate)
      );
      */
      if (moment(calendarDate).isSame(eventStartedDate)) {
        if (
          (moment(eventStartTime, "hh:mma").isAfter(
            moment(startTime, "hh:mma")
          ) ||
            moment(eventStartTime, "hh:mma").isSame(
              moment(startTime, "hh:mma"),
              "hh"
            )) &&
          moment(eventStartTime, "hh:mma").isBefore(moment(endTime, "hh:mma"))
          //||
          // moment(eventStartTime, "hh:mma").isSame(
          //   moment(endTime, "hh:mma"),
          //   "hh"
          // ))
        ) {
          eventCount++;
          if (eventSlots.length < 1) {
            let duration = moment.duration(moment(end).diff(moment(start)));
            let durationInMinutes = parseInt(duration.asMinutes());

            let slotHeight = null;
            if (durationInMinutes > 60) {
              slotHeight = {
                height: `${parseInt(durationInMinutes * 2.26)}px`,
              };
            }

            eventSlots.push(
              <div
                key={index}
                className="cal_event_detail"
                style={{
                  backgroundColor: "rgba(31, 186, 231, 0.08)",
                  borderLeft: "3px solid rgb(31, 186, 231)",
                  ...slotHeight,
                }}
                onClick={() => onSelectEvent(event)}
              >
                <div className="event_det">
                  <h3 style={{ color: "rgb(31, 186, 231)" }}>{title}</h3>
                  <p style={{ color: "rgb(31, 186, 231)" }}>{`${moment(
                    start
                  ).format("hh:mm a")} to ${moment(end).format("hh:mm a")}`}</p>
                </div>
                <div className="author_img">
                  <img
                    src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg"
                    alt="Author Profile Img"
                    className="eve_img"
                  />
                </div>
              </div>
            );
          }
        }
      }
    });

    if (eventCount > 1) {
      eventSlots.push(
        <button
          className="btn week_more p-0"
          onClick={() => onViewMoreHandler(dated)}
          style={{ marginTop: "-5px" }}
        >{`+${eventCount - 1} more`}</button>
      );
    }

    return eventSlots;
  };

  /**
   * render cells
   */
  const renderCells = () => {
    const { selectedDate } = props;
    const dated = moment(selectedDate);

    const allCells = [];
    for (let i = 0; i < 7; i++) {
      const day = dated.startOf("week").add(i, "days");
      const dayCells = [];
      //console.log("day", moment(day), moment(day).format("ddd"));
      for (let j = 1; j <= 24; j++) {
        const calendarHourEvents = getCalendarHourEvents(j, day);
        dayCells.push(
          <div className="day_slot" key={i + j}>
            {calendarHourEvents.length > 0 && calendarHourEvents}
          </div>
        );
      }
      allCells.push(
        <div className="cal_day_slots" key={i}>
          {dayCells}
        </div>
      );
    }

    return <div className="day_slots_main">{allCells}</div>;
  };

  return (
    <div className="calendar_wrapper week_wrapper">
      <div className="calendar_header">
        <div className="d-flex align-items-center">
          <div className="head_empty"></div>
          {renderDayNames()}
        </div>
      </div>
      <div className="calendar_body">
        {renderTimeRows()}
        {renderCells()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  calendar: state.calendar,
});

export default connect(mapStateToProps, {
  setCalendarView,
})(WeekView);
