import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";

const DayView = (props) => {
  const [hourRowWidth, setHourRowWidth] = useState(100);

  /**
   * render vertical times col
   */
  const renderTimeRows = () => {
    const timeSlotRows = [];

    // add 12:00 am to 1:00 AM slot
    timeSlotRows.push(
      <div className="time_slot">
        <p>
          <span>&nbsp;</span>
        </p>
      </div>
    );
    for (let i = 1; i < 24; i++) {
      timeSlotRows.push(
        <div className="time_slot" key={i}>
          <p>
            {i <= 12 ? i : i - 12} <span>{i < 12 ? "am" : "pm"}</span>
          </p>
        </div>
      );
    }
    return <div className="cal_time_slots">{timeSlotRows}</div>;
  };

  /**
   *
   * @param {*} hour
   */
  const getStartTimeEndTime = (hour) => {
    hour = hour - 1;
    if (hour === 0) {
      return { startTime: "12:00am", endTime: "1:00am" };
    } else {
      /*
      let leftPadding = "";
      if (hour < 10) {
        leftPadding = "0";
      }
      */
      return {
        startTime:
          (hour > 12 ? hour - 12 : hour) + ":00" + (hour >= 12 ? "pm" : "am"),
        endTime:
          (hour >= 12 ? hour - 12 : hour) +
          1 +
          ":00" +
          (hour + 1 >= 12 && hour + 1 !== 24 ? "pm" : "am"),
      };
    }
  };

  /**
   *
   * @param {*} hour
   */
  const getCalendarHourEvents = (hour) => {
    const { shortEventSlot, onSelectEvent } = props.calendar;
    const { selectedDate } = props;
    const { events } = props;
    let eventSlots = [];

    let { startTime, endTime } = getStartTimeEndTime(hour);
    startTime = moment(startTime, "hh:mma").format("hh:mma");
    endTime = moment(endTime, "hh:mma").format("hh:mma");

    const calendarDate = moment(selectedDate).format("yyyy-MM-DD");

    events.forEach((event, index) => {
      const { id, start, end, title } = event;
      let eventStartTime = moment(start, "hh:mma").format("hh:mma");
      let eventEndTime = moment(end, "hh:mma").format("hh:mma");
      const eventStartedDate = moment(start).format("yyyy-MM-DD");
      if (moment(calendarDate).isSame(eventStartedDate)) {
        // now we need to check if event start is same time of that hour
        //eventStartTime.isAftar(startTime) or eventStartTime.isSame() && eventStartTime.isBefore(endTime) or issame to endtime
        /*
        Slot 7:15 : 7:30
        start = 7:15

        startTime = 7:00
        endTime = 8:00

        (7:15 is after 7:00 or 7:15 is same as 7:00)  && (7:15 is before 8:00 or 7:15 is same as 8:00)
        */

        if (
          (moment(eventStartTime, "hh:mma").isAfter(
            moment(startTime, "hh:mma")
          ) ||
            moment(eventStartTime, "hh:mma").isSame(
              moment(startTime, "hh:mma"),
              "hh"
            )) &&
          moment(eventStartTime, "hh:mma").isBefore(moment(endTime, "hh:mma"))
          //||
          // moment(eventStartTime, "hh:mma").isSame(
          //   moment(endTime, "hh:mma"),
          //   "hh"
          // ))
        ) {
          if (!shortEventSlot) {
            let duration = moment.duration(moment(end).diff(moment(start)));
            let durationInMinutes = parseInt(duration.asMinutes());

            let slotHeight = null;
            if (durationInMinutes > 60) {
              slotHeight = { height: `${parseInt(durationInMinutes * 1.5)}px` };
            }

            eventSlots.push(
              <div
                key={index}
                className="cal_event_detail"
                style={{
                  backgroundColor: "rgba(31, 186, 231, 0.08)",
                  borderLeft: "3px solid rgb(31, 186, 231)",
                  ...slotHeight,
                }}
                onClick={() => onSelectEvent(event)}
              >
                <div className="event_det">
                  <h3 style={{ color: "rgb(31, 186, 231)" }}>{title}</h3>
                  <p style={{ color: "rgb(31, 186, 231)" }}>{`${moment(
                    start
                  ).format("hh:mm a")} to ${moment(end).format("hh:mm a")}`}</p>
                </div>
                <div className="author_img">
                  <img
                    src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg"
                    alt="Author Profile Img"
                    className="eve_img"
                  />
                </div>
              </div>
            );
          } else {
            eventSlots.push(
              <div
                key={index}
                className="cal_event_detail"
                style={{
                  backgroundColor: "rgba(31, 186, 231, 0.08)",
                  borderLeft: "3px solid rgb(31, 186, 231)",
                }}
              >
                <div className="event_det w-100">
                  <p
                    style={{ color: "rgb(31, 186, 231)" }}
                    className="text-center"
                  >{`${moment(start).format("hh:mm a")} - ${moment(end).format(
                    "hh:mm a"
                  )}`}</p>
                </div>
              </div>
            );
          }
        }
      }
    });

    // set custom row width
    if (eventSlots.length > 5) {
      if (hourRowWidth !== 100) {
        const rowWidth = eventSlots.length * 220;
        if (rowWidth > hourRowWidth) {
          setHourRowWidth(rowWidth);
        }
      } else {
        setHourRowWidth(eventSlots.length * 220);
      }
    }

    return eventSlots;
  };

  /**
   * render cells
   */
  const renderCells = () => {
    const dayCells = [];
    for (let i = 1; i <= 24; i++) {
      const calendarHourEvents = getCalendarHourEvents(i);
      dayCells.push(
        <div
          className="cal_day_slots"
          key={i}
          style={{
            width:
              hourRowWidth === 100 ? hourRowWidth + "%" : hourRowWidth + "px",
          }}
        >
          {calendarHourEvents.length > 0 && calendarHourEvents}
        </div>
      );
    }

    return <div className="day_slots_main">{dayCells}</div>;
  };

  return (
    <div className="calendar_wrapper day_wrapper">
      <div className="calendar_body">
        {renderTimeRows()}
        {renderCells()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  calendar: state.calendar,
});

export default connect(mapStateToProps, null)(DayView);
