import React, { useState, useEffect } from "react";
import { getEventSummary } from "../../actions/eventActions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { Collapse } from 'antd';
import { Link } from 'react-router-dom';
import editIcon from "../../assets/icons/edit_icon.svg";
import personsIcon from "../../assets/icons/persons_group.svg";

import ZoomIcon from "../../assets/icons/zoom.svg";
import MeetIcon from "../../assets/icons/google-meet.svg";
import TeamsIcon from "../../assets/icons/Microsoft_Teams.svg";
import GoToIcon from "../../assets/icons/goto_icon.svg";
import PhoneCall from "../../assets/icons/phone_call.svg";
import InPersonMeet from "../../assets/icons/in_person_meet.svg";
import OtherMeet from "../../assets/icons/other_meet_icon.svg";
import LocationIcon from "../../assets/icons/location_icon.svg";
import P from "../../assets/bookingeventassets/assets/p.svg";

import linkIcon from "../../assets/icons/link_icon.svg";
import eventImg from "../../assets/fe-images/event_img.svg";
import checkMark from "../../assets/fe-images/checkMark.svg";
import clockIcon from "../../assets/icons/Clock.svg";
import calenderIcon from "../../assets/icons/Planner.svg";
import TickIcon from "../../assets/icons/check_mark_green.svg";
import { FRONTEND_BASE_URL, IMAGE_BASE_URL, stripHTMLTags, useQuery } from "../../utils/utility";
import EventSummaryAvailabilityComponent from "./Components/Common/event-summary-availability";
import { getAdminBusinessId } from "../../utils/authHelper";
import isEmpty from "../../validation/is-empty";
import moment from "moment";
import { Formik, Form } from "formik";
const { Panel } = Collapse;

const initialValues = {
    availability: ""
};

/**
 * Event type Header
 * @returns 
 */
const EventTypeHeader = ({id, isPaidEvent}) => {
    const query = useQuery();
    const eventType = isPaidEvent === "E" ? "paid-event" : "free-event";
    return (
        <h2>
            Event Type
            {isEmpty(query.get('view-only')) && <><Link to={`/admin/events/${id}/create?type=${eventType}`}><img src={editIcon} alt="Edit Icon" /></Link></>} 
            
        </h2>
    );
}

/**
 * Event detail Header
 * @returns 
 */
 const EventDetailHeader = ({id, isPaidEvent}) => {
    const query = useQuery();
    const eventType = isPaidEvent === "E" ? "paid-event" : "free-event";
    return (
        <h2>
            Event Details 
            {isEmpty(query.get('view-only')) && <Link to={`/admin/events/create/${id}/step-2?type=${eventType}`}><img src={editIcon} alt="Edit Icon" /></Link>}
            
        </h2>
    );
}

/**
 * Event availability Header
 * @returns 
 */
const EventAvailabilityHeader = ({id, isPaidEvent}) => {
    const query = useQuery();
    const eventType = isPaidEvent === "E" ? "paid-event" : "free-event";
    return (
        <h2>
            Availability 
            {isEmpty(query.get('view-only')) && <Link to={`/admin/events/create/${id}/step-3?type=${eventType}`}><img src={editIcon} alt="Edit Icon" /></Link>}
            
        </h2>
    );
}


/**
 * Event advanced Header
 * @returns 
 */
const EventAdvancedHeader = ({id, isPaidEvent}) => {
    const query = useQuery();
    const eventType = isPaidEvent === "E" ? "paid-event" : "free-event";
    return (
        <h2>
            Advanced
            {isEmpty(query.get('view-only')) && <Link to={`/admin/events/create/${id}/step-4?type=${eventType}`}><img src={editIcon} alt="Edit Icon" /></Link>} 
            
        </h2>
    );
}
    

const EventSummary = (props) => {

    const { id } = useParams();
    const [pageLoading, setPageLoading] = useState(false);
    const [data, setData] = useState(null);

    const [deposit, setDeposit] = useState(null);
    const [payment, setPayment] = useState(null);
    const [addOns, setAddOns] = useState([]);
    const [pricing, setPricing] = useState([]);
    const [advanceTab, setAdvanceTab] = useState(false);
    const [schedules, setSchedules] = useState([]);

    const businessId = getAdminBusinessId();

    const [showMorePricing, setShowMorePricing] = useState(false);
    const [showMoreAddons, setShowMoreAddons] = useState(false);

    /**
   * @param Array schedules
   * @return Array
   */
  const processSchedulesForListView = (schedules, type) => {
    let processedSchedules = [];

    processedSchedules = (schedules || []).map((schedule, index) => {

      let scheduleDetails = (schedule.schedule_details).map((scheduleDetail) => {
        if (scheduleDetail.type == "weekly" || scheduleDetail.weekday_id !== null) {
          return {
            dayId: parseInt(scheduleDetail.weekday_id),
            dated: null,
            schedules: [...scheduleDetail.schedule_availability],
          };
        } else if (scheduleDetail.type == "custom") {
          return {
            dayId: null,
            dated: moment(scheduleDetail.dated, "yyyy-MM-DD").format("DD-MM-yyyy") ,
            schedules: [...scheduleDetail.schedule_availability],
          }
        }
      });

      return {
        type: schedule.schedule_type,
        staffId: schedule.staff_id,
        schedules: scheduleDetails,
        timezoneId: schedule.timezone_id,
        staff_first_name: schedule.staff_first_name,
        staff_last_name: schedule.staff_last_name,
      };
    });

    return processedSchedules;
  };
    
    useEffect(() => {
        const { getEventSummary } = props;
        setPageLoading(true);
        getEventSummary({
            data: {
              id
            },
            onSuccess: function (response) {
                setPageLoading(false);
                setData(response);

                if (response.add_ons.length > 0) {
                    setAddOns([...response.add_ons])
                }

                if (response.pricing.length > 0) {
                    setPricing([...response.pricing]);
                }

                // process prepayments
                if (response.hasOwnProperty("payments")) {
                    const payments = [...response.payments];
                    payments.map((payment, index) => {
                        if (payment.prepayment_type == "deposit") {
                            setDeposit({
                                deposit_type: payment.prepayment_type,
                                deposit_price: payment.prepayment_type_price,
                                deposit_flat_per_type: payment.prepayment_type_flat_percent,
                                is_prepayment_checked: true,
                            });
                        } else {
                            setPayment({
                                payments_type: payment.prepayment_type,
                                payments_price: payment.prepayment_type_price,
                                payments_flat_type: payment.prepayment_type_flat_percent,
                                is_prepayment_checked: true,
                            });
                        }
                    });
                }
                setSchedules(processSchedulesForListView(response.schedules));
            },
            onError: function (error) {
                setPageLoading(false);
            },
        });
    }, []);

    

    return (
        <div className="event_summarry">
            <Loader isShowLoader={pageLoading} />
            { data && (
                <>
                    <h2 className='mb-3'> {data.event_name } 
                        { data.is_event_paid === 'D' ? <span className='eve_status free'>Free</span> : <span className='eve_status paid'>Paid</span>}
                    </h2>
                    <Collapse accordion defaultActiveKey={['1']}>
                        {/* Event Type */}
                        <Panel header={<EventTypeHeader id={data.id} isPaidEvent={data.is_event_paid} />} key="1">
                            <div className="row justify-content-between">
                                <div className="col-auto mb-3 mb-md-0">
                                    <h3>Event Name:</h3>
                                    <p><span className='custom_circle'></span> { data.event_name }</p>
                                </div>
                                <div className="col-auto mb-3 mb-md-0">
                                    <h3>Event Format:</h3>
                                    <p>
                                        <span>
                                            <img src={personsIcon} alt="Group Humans" />
                                        </span> 
                                        { data.event_format_id === 1 ? <>One to One</> : <>Group</> } 
                                        <span>{data.event_format_id === 2 && (<>[Attendees: {data.no_of_attendies}]</>)}</span>
                                    </p>
                                </div>
                                <div className="col-auto eve_url">
                                    <h3>Event URL:</h3>
                                    <p><a  target="_blank" href={`${FRONTEND_BASE_URL}booking/${data.event_url}`}><span><img src={linkIcon} alt="" /></span> {`${FRONTEND_BASE_URL}booking/${data.event_url}`} </a></p>
                                </div>
                                <div className="col-auto">
                                    <h3>Venue:</h3>
                                    <p><span>{data.venue === "Microsoft Teams" ? (
                                        <img
                                        src={TeamsIcon}
                                        alt="microsoft teams icon"
                                        />
                                    ) : data.venue === "Zoom" ? (
                                        <img
                                        src={ZoomIcon}
                                        alt="Zoom icon"
                                        />
                                    ) : data.venue === "Google Meet" ? (
                                        <img
                                        src={MeetIcon}
                                        alt="Google Meet icon"
                                        />
                                    ) : data.venue === "Phone Call" ? (
                                        <img
                                        src={PhoneCall}
                                        alt="Phone Call icon"
                                        />
                                    ) : data.venue === "In-Person Meeting" ? (
                                        <img
                                        src={InPersonMeet}
                                        alt="In Person Meet icon"
                                        style={{ maxWidth: 13}}
                                        />
                                    ) : data.venue === "Others" ? (
                                        <img
                                        src={OtherMeet}
                                        alt="microsoft teams icon"
                                        />
                                    ) : data.venue === "Go to Meeting" ? (
                                        <img
                                        src={GoToIcon}
                                        alt="Go To icon"
                                        style={{ maxWidth: 30 }}
                                        />
                                    ) : (
                                        <img src={P} alt="event_img"  />
                                    )}</span> {data.venue} </p>
                                </div>
                            </div>

                            {data.venue_id === 1 && (
                                <div className="row justify-content-between" style={{ borderTop: "1px solid rgba(112,112,112,0.1)", paddingTop: 20, marginTop: 20 }}>
                                    <div className="col-auto">
                                        <h3>location:</h3>
                                        <p><span><img src={LocationIcon} alt="Location Icon" /></span> 
                                            {data.venue_location}
                                        </p>
                                    </div>
                                    <div className="col-auto">
                                        <h3 className="op-0">Hidden</h3>
                                        { data.venue_location_status === 'display_location_booking' && (
                                            <p>
                                                <span><img src={TickIcon} alt="Check mark" /></span> 
                                                Display Location while booking.
                                            </p>
                                        )}

                                        { data.venue_location_status === 'display_booking_after_confirmed' && (
                                            <p>
                                                <span><img src={TickIcon} alt="Check mark" /></span> 
                                                Display Location After Booking is Confirmed.
                                            </p>
                                        )}

                                        { data.venue_location_status === 'invitee_add_location' && (
                                            <p>
                                                <span><img src={TickIcon} alt="Check mark" /></span> 
                                                Let invitee add location for meeting.
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-auto">
                                        <h3>Venue note:</h3>
                                        <p>{data.venue_location_notes}</p>
                                    </div>
                                </div>
                            )}


                            {data.venue === "Others" && (
                                <div className="row justify-content-between" style={{ borderTop: "1px solid rgba(112,112,112,0.1)", paddingTop: 20, marginTop: 20 }}>
                                    <div className="col-lg-auto col-md-6 mb-3 mb-md-0">
                                        <h3>Venue other Notes:</h3>
                                        <p><span><img src={LocationIcon} alt="Location Icon" /></span> 
                                            {data.venue_other_notes}
                                        </p>
                                    </div>
                                    <div className="col-lg-auto col-md-6 ">
                                        { (data.venue_other_display_status === '1' || data.venue_other_display_status === 1) && (
                                            <p>
                                                <span><img src={TickIcon} alt="Check mark" /></span> 
                                                Display booking notes
                                            </p>
                                        )}

                                        { (data.venue_other_display_status === '2' || data.venue_other_display_status === 2) && (
                                            <p>
                                                <span><img src={TickIcon} alt="Check mark" /></span> 
                                                Display Notes After Booking is Confirmed
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                            
                        </Panel>

                        {/* Event Details */}
                        <Panel header={<EventDetailHeader id={data.id} isPaidEvent={data.is_event_paid}  />} key="2">
                            <div className="event_detail_tab">
                                {/* first row */}
                                <div className="main_row row justify-content-between">
                                    <div className="col-lg-2 col-6 event_img">
                                        <h3>Image:</h3>
                                        <img src={`${IMAGE_BASE_URL}${data.event_image}`} alt="Event Img" />
                                    </div>
                                    <div className="col-lg-2 col-6 event_clr">
                                        <h3>Event Color:</h3>
                                        <span className='d-block' style={{ background: data.event_color }}></span>
                                    </div>
                                    <div className="col-md-8 mt-4 mt-lg-0">
                                        <h3>Description:</h3>
                                        <p className='event_des'>{stripHTMLTags(data.event_description)}</p>
                                    </div>
                                </div>

                                <div className="main_row row justify-content-between">
                                    <div className="col-12">
                                        {/* <h3>Allow</h3> */}
                                        <div className="allow_row row">
                                            {data.max_no_booking_status === 'E' ? <div className="col-auto d-flex">
                                                <h3>Max Number of Bookings:</h3>
                                                <p className="ml-2">{data.max_no_of_booking}</p>
                                            </div> : null}

                                            {data.recurring_booking_status === 'E' && (
                                                <div className="col-auto">
                                                    <p>Allow Recurring Booking? <span><img src={checkMark} alt="Check Mark" /></span></p>
                                                </div>
                                            )}

                                            {data.allow_rescheduling_status === 'E' && (
                                                <div className="col-auto">
                                                    <p>Allow Rescheduling? <span><img src={checkMark} alt="Check Mark" /></span></p>
                                                </div>
                                            )}

                                            
                                            {data.attendee_cancellation_status === 'E' && (
                                                <div className="col-auto">
                                                    <p>Allow Attendee Cancellation? <span><img src={checkMark} alt="Check Mark" /></span></p>
                                                </div>
                                            )}
                                        </div>
                                        {data.is_event_paid === 'D' ? null : <>
                                            <div className="row mt-4">
                                                <div className="col-auto d-flex flex-wrap">
                                                    <h3 className="payment_req">Do You Require A Prepayment/Deposit:</h3>
                                                    <p className="mx-md-2 mr-2">{data.event_prepaid_status === 'E' ? 'Yes' : 'No, I would get paid at event'}</p>

                                                    {payment && payment.payments_type === "full_payment" && (
                                                        <h3>
                                                            Full Payment
                                                            {deposit && deposit.deposit_type === "deposit" && (
                                                                <>
                                                                    [Deposit {deposit.deposit_flat_per_type === "percent" && '%'}{deposit.deposit_price}]
                                                                </>
                                                            )} 
                                                        </h3>
                                                    )}

                                                    {payment && payment.payments_type === "partial_payment" && (
                                                        <h3>
                                                            Partial Payment[{payment.payments_flat_type === "percent" && '%'}{payment.payments_price}]
                                                            {deposit && deposit.deposit_type === "deposit" && (
                                                                <>
                                                                    [Deposit {deposit.deposit_flat_per_type === "percent" && '%'}{deposit.deposit_price}]
                                                                </>
                                                            )} 
                                                        </h3>
                                                    )}

                                                </div>
                                            </div>
                                            <div className="allow_row row">
                                                {data.coupon_codes_status === 'E' && (
                                                    <div className="col-auto">
                                                        <p>Coupon Codes status: <span><img src={checkMark} alt="Check Mark" /></span></p>
                                                    </div>
                                                )}

                                                {data.recurrent_booking_discounts_status === 'E' && (
                                                    <div className="col-auto">
                                                        <p>Enable Recurrent Booking Discounts: <span><img src={checkMark} alt="Check Mark" /></span></p>
                                                    </div>
                                                )}
                                                {data.staff_commission_status === 'E' && (
                                                    <div className="col-auto">
                                                        <p>Staff Commission Status: <span><img src={checkMark} alt="Check Mark" /></span> [{data.staff_commission_rate}] </p>
                                                    </div>
                                                )}
                                            </div>
                                        </>}
                                    </div>
                                </div>

                                {(data.is_event_paid === 'D') ? null : <>
                                    <div className="main_row price_row row justify-content-between">
                                        <div className="col-lg-6">
                                            <h3>Pricing</h3>
                                            {(pricing).filter((p, pIndex) => pIndex < 3).map((eventPricing, index) => {
                                                const { item_name, price, qty } = eventPricing || {};
                                                return (<div className="row justify-content-between" key={index} style={{paddingBottom: 5, marginBottom: 7, borderBottom: "1px solid rgba(224,224,224,0.6)"}}>
                                                    <div className="col-md-4 mb-3 mb-md-0">
                                                        <p>Item name: {item_name}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Item price: ${price}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Quantity: {qty}</p>
                                                    </div>
                                                </div>)    
                                            })}

                                            {showMorePricing && (
                                                <>
                                                    {(pricing).filter((p, pIndex) => pIndex > 2).map((eventPricing, index) => {
                                                        const { item_name, price, qty } = eventPricing || {};
                                                        return (<div className="row justify-content-between" key={index} style={{paddingBottom: 5, marginBottom: 7, borderBottom: "1px solid rgba(224,224,224,0.6)"}}>
                                                            <div className="col-md-4 mb-3 mb-md-0">
                                                                <p>Item name: {item_name}</p>
                                                            </div>
                                                            <div className="col-6 col-md-4">
                                                                <p>Item price: ${price}</p>
                                                            </div>
                                                            <div className="col-6 col-md-4">
                                                                <p>Quantity: {qty}</p>
                                                            </div>
                                                        </div>)    
                                                    })}
                                                </>
                                            )}

                                            {pricing.length > 3 && !showMorePricing && (
                                                <>
                                                    <div className="text-center"><button className="btn more_btn" onClick={() => setShowMorePricing(true)}>+{pricing.length - 3} more</button></div>
                                                </>
                                            )} 
                                            
                                        </div>
                                        <div className="col-lg-6 mt-3 mt-lg-0">
                                            <h3>Add on</h3>

                                            {(addOns).filter((a, aIndex) => aIndex < 3).map((addon, index) => {
                                                const { item_name, price, qty } = addon || {};
                                                return (<div className="row justify-content-between" key={index} style={{paddingBottom: 5, marginBottom: 7, borderBottom: "1px solid rgba(224,224,224,0.6)"}}>
                                                    <div className="col-md-4 mb-3 mb-md-0">
                                                        <p>Item name: {item_name}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Item price: ${price}</p>
                                                    </div>
                                                    <div className="col-6 col-md-4">
                                                        <p>Quantity: {qty}</p>
                                                    </div>
                                                </div>)    
                                            })}

                                            {showMoreAddons && (
                                                <>
                                                    {(addOns).filter((a, aIndex) => aIndex > 2).map((addon, index) => {
                                                        const { item_name, price, qty } = addon || {};
                                                        return (<div className="row justify-content-between" key={index} style={{paddingBottom: 5, marginBottom: 7, borderBottom: "1px solid rgba(224,224,224,0.6)"}}>
                                                            <div className="col-md-4 mb-3 mb-md-0">
                                                                <p>Item name: {item_name}</p>
                                                            </div>
                                                            <div className="col-6 col-md-4">
                                                                <p>Item price: ${price}</p>
                                                            </div>
                                                            <div className="col-6 col-md-4">
                                                                <p>Quantity: {qty}</p>
                                                            </div>
                                                        </div>)    
                                                    })}
                                                </>
                                            )}
                                            {addOns.length > 3 && !showMoreAddons && (
                                                <>
                                                    <div className="text-center d-none"><button className="btn more_btn" onClick={() => setShowMoreAddons(true)}>+{addOns.length - 3} more</button></div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>}
                                
                                <div className="main_row row justify-content-between">
                                    <div className="col-12">
                                        <h3>Cancellation Policy:</h3>
                                        <p className='event_des'>{data.cancellation_policy}</p>
                                        <div className="allow_row row">
                                            {data.ita_booking_page_status === 'E' && (
                                                <div className="col-md-auto mb-3 mb-md-0">
                                                    <p>Booking Page <span><img src={checkMark} alt="Check Mark" /></span></p>
                                                </div>
                                            )}
                                            {data.ita_confirmation_page_status === 'E' && (
                                                <div className="col-md-auto mb-3 mb-md-0">
                                                    <p>Confirmation Page <span><img src={checkMark} alt="Check Mark" /></span></p>
                                                </div>
                                            )}

                                            {data.ita_reminder_email_status === 'E' && (
                                                <div className="col-md-auto mb-3 mb-md-0">
                                                    <p>Reminder Email <span><img src={checkMark} alt="Check Mark" /></span></p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="main_row row justify-content-between">
                                    <div className="col-12">
                                        <h3>Instructions to Attendee:</h3>
                                        <p className='event_des'>{data.instruction_to_attendee}</p>
                                        <div className="allow_row row">
                                            {data.ita_booking_page_status === 'E' && (
                                                <div className="col-md-auto mb-3 mb-md-0">
                                                    <p>Booking Page <span><img src={checkMark} alt="Check Mark" /></span></p>
                                                </div>
                                            )}
                                            {data.ita_confirmation_page_status === 'E' && (
                                                <div className="col-md-auto mb-3 mb-md-0">
                                                    <p>Confirmation Page <span><img src={checkMark} alt="Check Mark" /></span></p>
                                                </div>
                                            )}

                                            {data.ita_reminder_email_status === 'E' && (
                                                <div className="col-md-auto mb-3 mb-md-0">
                                                    <p>Reminder Email <span><img src={checkMark} alt="Check Mark" /></span></p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>

                        {/* Availability */}
                        <Panel header={<EventAvailabilityHeader id={data.id} isPaidEvent={data.is_event_paid}  />} key="3">
                            <div className="availability_tab">
                                <nav className='custom_tabs d-flex'>
                                    <button className={`btn ${advanceTab ? null : 'active'}`} onClick={() => { setAdvanceTab(false) }}>Event Duration</button>
                                    <button className={`btn ${advanceTab ? 'active' : null}`} onClick={() => { setAdvanceTab(true) }}>Advanced</button>
                                </nav>
                                {advanceTab ?
                                <div className="advance_tab main_row row">
                                    <div className="col-lg-3 col-md-4">
                                        <p>
                                            Minimum Scheduling Notice : 
                                            <span>{data.min_scheduling_notice_minutes}</span>
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <p>
                                            Maximum Scheduling Notice : 
                                            <span>{data.max_scheduling_notice_minutes}</span>
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <p>
                                            Maximum Event Per Day : 
                                            <span>{data.max_event_per_day}</span>
                                        </p>
                                    </div>
                                    
                                    <div className="col-lg-3 col-md-4">
                                        <p>
                                            Maximum Event Per Week : 
                                            <span>{data.max_event_per_week}</span>
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <p>
                                            Buffer Before The Event : 
                                            <span>{data.buffer_before_event_minutes}</span>
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <p>
                                            Buffer After The Event : 
                                            <span>{data.buffer_after_event_minutes}</span>
                                        </p>
                                    </div>
                                </div> : null}  

                                <div className="main_row row justify-content-between">
                                    <div className="col-lg-auto col-md-6 d-flex">
                                        <h3>Event Duration:</h3>
                                        <p className="ml-2"><span><img src={clockIcon} alt="Clock Icon" /></span> {data.event_duration_in_minutes} minutes</p>
                                    </div>
                                    <div className="col-lg-auto col-md-6 d-flex mt-3 mt-md-0">
                                        <h3>Event Validity:</h3>
                                        <p className="ml-2"><span><img src={calenderIcon} alt="Clock Icon" /></span> {data.event_validity}</p>
                                    </div>
                                    <div className="col-lg-7 d-flex flex-column flex-md-row my-3 my-lg-0">
                                        <h3>Event Internal Notes:</h3>
                                        <p className="ml-2">{stripHTMLTags(data.event_internal_notes)}</p>
                                    </div>
                                </div>

                                <Formik initialValues={initialValues}>
                                    {(formProps) => {
                                        const { values, errors, touched, handleChange } = formProps;
                                        return (
                                            <Form>
                                                <div className="main_row row justify-content-between">
                                                    <div className="col-12 mb-3">
                                                        <h3>Event availability:</h3>
                                                        {schedules.length && (
                                                            <EventSummaryAvailabilityComponent
                                                                businessId={businessId}
                                                                errors={errors}
                                                                touched={touched}
                                                                type="event"
                                                                staffId=""
                                                                schedules={schedules}
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                                <Collapse defaultActiveKey={['1']}>
                                                    {(schedules || []).filter((sch, index) => sch.type === 'event_staff').map((schedule, index) => {
                                                        return (
                                                            <Panel header={`${schedule.staff_first_name} ${schedule.staff_last_name}`} key={index + 1}>
                                                                <EventSummaryAvailabilityComponent
                                                                    businessId={businessId}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    type="event_staff"
                                                                    staffId={schedule.staffId}
                                                                    schedules={schedules}
                                                                />
                                                            </Panel>
                                                            );
                                                    })}
                                                </Collapse>

                                            </Form>
                                        );
                                    }}
                                </Formik>


                                

                                

                            </div>                            
                        </Panel>

                        {/* Advanced */}
                        <Panel header={<EventAdvancedHeader id={data.id} isPaidEvent={data.is_event_paid}  />} key="4">
                            <div className="advanced_tab">
                                <div className="main_row row justify-content-lg-between">
                                    <div className="col-12">
                                        <h3>Ask Attendee Questions:</h3>
                                    </div>
                                
                                    {(data.questions).map((question, index) => {
                                        return (
                                            <div className="col-md-auto mt-3 mt-lg-0" key={index}>
                                                <p>{question.question} {question.mandatory_status === 'E' ? '*' : null}</p>
                                            </div>        
                                        )
                                    })}
                                </div>

                                <div className="main_row row justify-content-between">
                                    <div className="d-flex d-md-block col-lg-auto col-md-4">
                                        <h3>Make Event Public:</h3>
                                        <p>{data.event_public_visiblity_status === 'E' ? 'Yes' : 'No'}</p>
                                    </div>
                                    <div className="d-flex d-md-block col-lg-auto col-md-4 mt-3 mt-md-0">
                                        <h3>Collect Customer Feedback:</h3>
                                        <p>{data.customer_feedback_status ==='E' ? 'Yes' : 'No' }</p>
                                    </div>
                                    <div className="col-lg-auto col-md-4 mt-3 mt-md-0">
                                        <h3>Reminders:</h3>
                                        {data.email_reminder_status === 'E' && (
                                            <p>Email Reminders <span className="ml-2 mr-0"><img src={checkMark} alt="Check Mark" /></span></p>
                                        )}
                                        {data.sms_reminder_status === 'E' && (
                                            <p className='mt-2'>SMS Reminders <span className="ml-2 mr-0"><img src={checkMark} alt="Check Mark" /></span></p>
                                        )}
                                        {data.email_followup_status === 'E' && (
                                            <p className='mt-2'>Email Follow-up <span className="ml-2 mr-0"><img src={checkMark} alt="Check Mark" /></span></p>        
                                        )}
                                    </div>
                                    <div className="col-lg-auto col-md-4 mt-3 mt-md-0">
                                        <h3>Confirmation Page:</h3>
                                        <p>{data.confirmation_page === 'default' ? 'Default' : 'External'} </p>
                                    </div>
                                </div>

                            </div>
                        </Panel>



                    </Collapse>
                </>
            )}

            
        </div>
    );
}


export default connect(null, {
    getEventSummary
})(EventSummary);
