import React, { Component } from "react";
import { gotProperty } from "../../../utils/utility";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { getVenues } from "../../../actions/eventActions";
import { onUpdateFilters } from "../../../actions/filterActions";
import MultiSelect from "../../../components/MultiSelect";
import { getEventStaffList } from "../../../actions/eventActions";
import { getAdminBusinessId } from "../../../utils/authHelper";

class MyCalendarFilters extends Component {
  state = {
    venues: [],
    staff: [],
  };

  componentDidMount() {
    const _this = this;
    const { getVenues, getEventStaffList } = this.props;
    getVenues({
      onSuccess: function (venues) {
        _this.setState({
          venues,
        });
      },
      onError: function (error) {
        console.error(error);
      },
    });

    getEventStaffList({
      data: {
        businessId: getAdminBusinessId(),
      },
      onSuccess: (data) => {
        _this.setState({
          staff: data,
        });
      },
      onError: (error) => {
        console.log({
          error,
        });
      },
    });
  }

  /**
   * Transform values
   * @param {*} options
   * @returns
   */
  transformValue = (options) => {
    let transformValues = [];
    if ((options || []).length > 0) {
      transformValues = (options || []).map((option) => {
        let { first_name, id, last_name } = option || {};
        const name = first_name + " " + last_name;
        return {
          title: name,
          value: id,
        };
      });
    }
    return transformValues;
  };

  render() {
    const { searchFilters } = this.props.filter;
    const { venues, staff } = this.state;

    return (
      <>
        <div className="form-group event-form-group">
          <label>Staff</label>
          <MultiSelect
            data={this.transformValue(staff)}
            defaultValue={
              gotProperty(searchFilters, "staff") ? searchFilters["staff"] : []
            }
            onChangeTreeSelect={(values) => {
              let assignedStaffs = [];
              this.transformValue(staff).forEach((adminStaff, index) => {
                if (values.includes(adminStaff.value)) {
                  assignedStaffs.push({
                    title: adminStaff.title,
                    value: adminStaff.value,
                  });
                }
              });
              this.props.onUpdateFilters("staff", [...assignedStaffs]);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="event-venue">Venue</label>
          <Input
            type="select"
            name="select"
            id="event-venue"
            value={
              gotProperty(searchFilters, "venue_id")
                ? searchFilters["venue_id"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("venue_id", e.target.value);
            }}
          >
            <option value="">Select Venue</option>
            {(venues || []).map((venue, index) => {
              return (
                <option key={index} value={venue.id}>
                  {venue.venue}
                </option>
              );
            })}
          </Input>
        </div>

        <div className="form-group">
          <label htmlFor="event-type">Type</label>
          <Input
            type="select"
            name="select"
            id="event-type"
            value={
              gotProperty(searchFilters, "is_event_paid")
                ? searchFilters["is_event_paid"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("is_event_paid", e.target.value);
            }}
          >
            <option value="">Select Type</option>
            <option value="E">Paid</option>
            <option value="D">Free</option>
          </Input>
        </div>

        <div className="form-group">
          <label htmlFor="filter-by">Filter By</label>
          <Input
            type="select"
            name="select"
            id="filter-by"
            value={
              gotProperty(searchFilters, "filter_by")
                ? searchFilters["filter_by"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("filter_by", e.target.value);
            }}
          >
            <option value="">Show All</option>
            <option value="1">Staff Events</option>
            <option value="2">My Events</option>
          </Input>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  getVenues,
  onUpdateFilters,
  getEventStaffList,
})(MyCalendarFilters);
