import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import useMediaQuery from "../../../../../hooks/useMediaQuery";
import LineChartIcon from "../../../../../assets/icons/line_chart_icon.svg";
import BarChartIcon from "../../../../../assets/icons/bar_chart_icon.svg";
import LineChartWhite from "../../../../../assets/icons/line_chart_white.svg";
import BarChartClr from "../../../../../assets/icons/bar_chart_color.svg";
import RefresIcon from "../../../../../assets/icons/refresh_icon.svg";
import { Select } from "antd";
import DatePicker from "react-date-picker";
import { getEvents } from "../../../../../actions/adminDashboardActions";
import { sendPostRequest } from "../../../../../actions/app-actions";
import { connect } from "react-redux";
import moment from "moment";
import { getAdminBusinessId } from "../../../../../utils/authHelper";
import Loader from "../../../../../components/Loader/Loader";
import Tooltip from "../../../../../components/Tooltip";


Chart.register(...registerables);
const GlobalChart = (props) => {
  const [data, setData] = useState([]);
  const [chartType, setChartType] = useState("bar");
  const [chartData, setChartData] = useState([]);
  const [renderChart, setRenderChart] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const isTablet = useMediaQuery("(min-width: 768px)");
  const isMobile = useMediaQuery("(min-width: 250px)");

  const [filterMonth, setFilterMonth] = useState(new Date());
  const [filterYear, setFilterYear] = useState(new Date());
  const [eventId, setEventId] = useState("");
  const [isDailyView, setIsDailyView] = useState(true);
  // const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);




  useEffect(() => {
    /*
    const { getEvents } = props;
    getEvents({
      data: {},
      onSuccess: function (data) {
        setEvents([{ value: "", label: "All Events" }, ...data.events]);
      },
      onError: function (error) {
        console.log("error", error);
      },
    });
    */

    loadChartData({
      is_month_view: "D",
    });
  }, []);

  /**
   * loads chart data
   * @param {*} data
   */
  const loadChartData = (data = {}) => {
    const { sendPostRequest } = props;
    setIsLoading(true);
    sendPostRequest({
      url: `/admin/event-stats-chart/${getAdminBusinessId()}`,
      data,
      onSuccess: function (response) {
        const { data } = response;
        setData(data.data);
        setChartData({
          labels: data.data.map((row) => row.index),
          datasets: [
            {
              label: "Scheduled",
              data: data.data.map((data) => data.scheduled),
              backgroundColor: "#FD9926",
              borderRadius: 10,
              borderWidth: chartType === "line" ? 2 : 0,
              borderColor: chartType === "line" ? "#FD9926" : "transparent",
              tension: chartType === "line" ? 0.5 : 0,
              pointRadius: chartType === "line" ? (isTablet ? 5 : 2) : 0,
            },
            {
              label: "Completed",
              data: data.data.map((data) => data.completed),
              backgroundColor: "#00C875",
              borderRadius: 10,
              borderWidth: chartType === "line" ? 2 : 0,
              borderColor: chartType === "line" ? "#00C875" : "transparent",
              tension: chartType === "line" ? 0.5 : 0,
              pointRadius: chartType === "line" ? (isTablet ? 5 : 2) : 0,
            },
            {
              label: "Cancelled",
              data: data.data.map((data) => data.cancelled),
              backgroundColor: "#E8515A",
              borderRadius: 10,
              borderWidth: chartType === "line" ? 2 : 0,
              borderColor: chartType === "line" ? "#E8515A" : "transparent",
              tension: chartType === "line" ? 0.5 : 0,
              pointRadius: chartType === "line" ? (isTablet ? 5 : 2) : 0,
            },
            {
              label: "No show",
              data: data.data.map((data) => data.noshow),
              backgroundColor: "#9270D5",
              borderRadius: 10,
              borderWidth: chartType === "line" ? 2 : 0,
              borderColor: chartType === "line" ? "#9270D5" : "transparent",
              tension: chartType === "line" ? 0.5 : 0,
              pointRadius: chartType === "line" ? (isTablet ? 5 : 2) : 0,
            },
          ],
        });
        setIsLoading(false);
      },
      onError: function (error) {
        setIsLoading(false);
        console.log("error", error);
      },
    });
  };

  useEffect(() => {
    setChartData({
      labels: data.map((row) => row.index),
      datasets: [
        {
          label: "Scheduled",
          data: data.map((data) => data.scheduled),
          backgroundColor: "#FD9926",
          borderRadius: 10,
          borderWidth: chartType === "line" ? 2 : 0,
          borderColor: chartType === "line" ? "#FD9926" : "transparent",
          tension: chartType === "line" ? 0.5 : 0,
          pointRadius: chartType === "line" ? (isTablet ? 5 : 2) : 0,
        },
        {
          label: "Completed",
          data: data.map((data) => data.completed),
          backgroundColor: "#00C875",
          borderRadius: 10,
          borderWidth: chartType === "line" ? 2 : 0,
          borderColor: chartType === "line" ? "#00C875" : "transparent",
          tension: chartType === "line" ? 0.5 : 0,
          pointRadius: chartType === "line" ? (isTablet ? 5 : 2) : 0,
        },
        {
          label: "Cancelled",
          data: data.map((data) => data.cancelled),
          backgroundColor: "#E8515A",
          borderRadius: 10,
          borderWidth: chartType === "line" ? 2 : 0,
          borderColor: chartType === "line" ? "#E8515A" : "transparent",
          tension: chartType === "line" ? 0.5 : 0,
          pointRadius: chartType === "line" ? (isTablet ? 5 : 2) : 0,
        },
        {
          label: "No show",
          data: data.map((data) => data.noshow),
          backgroundColor: "#9270D5",
          borderRadius: 10,
          borderWidth: chartType === "line" ? 2 : 0,
          borderColor: chartType === "line" ? "#9270D5" : "transparent",
          tension: chartType === "line" ? 0.5 : 0,
          pointRadius: chartType === "line" ? (isTablet ? 5 : 2) : 0,
        },
      ],
    });
    setRenderChart(true);
  }, [chartType]);

  return (
    <>
      <Loader isShowLoader={isLoading} />
      <div className="page_title_dashboard mb-3">
        <div className="row justify-content-between align-items-center">
          <div className="col-auto mb-3 mb-md-0">
            <h2>Global stats <Tooltip code="P3F1" /> </h2>
          </div>
          <div className="col-md-6 d-flex justify-content-center justify-content-md-end align-items-center flex-wrap flex-md-nowrap">
            {isDailyView && (
              <DatePicker
                name="start_date"
                className="cus_datepicker me-3"
                format="MM-yyyy"
                maxDetail="year"
                showNavigation={true}
                value={filterMonth}
                locale="en-GB"
                selected={(filterMonth && new Date(filterMonth)) || null}
                onChange={(val) => {
                  setFilterMonth(val);
                  loadChartData({
                    event_id: eventId,
                    is_month_view: isDailyView ? "D" : "E",
                    month: val ? moment(val).format("MM") : null,
                    year: val ? moment(val).format("YYYY") : null,
                  });
                }}
              />
            )}

            {!isDailyView && (
              <DatePicker
                name="start_date"
                className="cus_datepicker me-3"
                format="yyyy"
                maxDetail="decade"
                showNavigation={true}
                value={filterYear}
                locale="en-GB"
                selected={(filterYear && new Date(filterYear)) || null}
                onChange={(val) => {
                  setFilterYear(val);

                  loadChartData({
                    event_id: eventId,
                    is_month_view: isDailyView ? "D" : "E",
                    month: filterMonth
                      ? moment(filterMonth).format("MM")
                      : filterMonth,
                    year: val ? moment(val).format("YYYY") : null,
                  });
                }}
              />
            )}

            <div className="rd_vacationflex2 dash_filter_bar me-md-4">
              <label className="pr-2 mb-0">Daily</label>
              <div className="toggle_field">
                <div className="box_content bg-transparent p-0">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="paypalGuestPayment"
                      onChange={(e) => {
                        setIsDailyView(!e.target.checked);
                        loadChartData({
                          event_id: eventId,
                          is_month_view: e.target.checked ? "E" : "D",
                          month: filterMonth
                            ? moment(filterMonth).format("MM")
                            : filterMonth,
                          year: filterYear
                            ? moment(filterYear).format("YYYY")
                            : filterYear,
                        });
                      }}
                      checked={!isDailyView}
                    />
                  </div>
                </div>
              </div>
              <label className="pl-2 mb-0">Monthly</label>
            </div>
            <div className="slct mt-3 mt-md-0">
              <Select
                size={"large"}
                placeholder="Select Event"
                allowClear={true}
                onChange={(value) => {
                  setEventId(value);
                  loadChartData({
                    event_id: value,
                    is_month_view: isDailyView ? "D" : "E",
                    month: filterMonth
                      ? moment(filterMonth).format("MM")
                      : filterMonth,
                    year: filterYear
                      ? moment(filterYear).format("YYYY")
                      : filterYear,
                  });
                }}
                value={eventId}
                style={{
                  width: "100%",
                }}
                options={props.events}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="rd_vacationfilterpart global_chart">
        <div className="col-12">
          <div className="chart_container">
            <div className="head">
              <div className="row justify-content-between align-items-center">
                <div className="col-auto page_title_dashboard">
                  <h2>Stats</h2>
                </div>
                <div className="col-auto">
                  <div className="tools">
                    {/* labels */}
                    <div className="labels row">
                      <div className="col-auto sch">
                        <p>
                          <span></span> Scheduled
                        </p>
                      </div>
                      <div className="col-auto com">
                        <p>
                          <span></span> Completed
                        </p>
                      </div>
                      <div className="col-auto can">
                        <p>
                          <span></span> Cancelled
                        </p>
                      </div>
                      <div className="col-auto nos">
                        <p>
                          <span></span> No Show
                        </p>
                      </div>
                    </div>
                    {/* Chart Type */}
                    <div className="chart_type">
                      <button
                        className={`btn ${chartType === "line" ? "active" : null
                          }`}
                        onClick={() => setChartType("line")}
                      >
                        <img
                          src={
                            chartType === "line"
                              ? LineChartWhite
                              : LineChartIcon
                          }
                          alt="Line Chart Icon"
                        />
                      </button>
                      <button
                        className={`btn ${chartType === "bar" ? "active" : null
                          }`}
                        onClick={() => setChartType("bar")}
                      >
                        <img
                          src={chartType === "bar" ? BarChartIcon : BarChartClr}
                          alt="Bar Chart Icon"
                        />
                      </button>
                      <button
                        className="btn refres_btn"
                        onClick={() => {
                          setEventId("");
                          setIsDailyView(true);
                          setFilterMonth(null);
                          setFilterYear(null);
                          loadChartData({
                            event_id: "",
                            is_month_view: "D",
                            month: null,
                            year: null,
                          });
                        }}
                      >
                        <img src={RefresIcon} alt="Refresh Icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {renderChart === true && chartType === "bar" ? (
              <Bar
                data={chartData}
                options={{
                  scales: {
                    y:
                    {
                      min: 0,
                      //stepSize: 1,
                      //max:15,
                      ticks: {
                        precision: 0,
                        // Include a dollar sign in the ticks
                        callback: function (value, index, ticks) {
                          return value;
                        }
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            ) : null}
            {renderChart === true && chartType === "line" ? (
              <Line
                data={chartData}
                options={{
                  scales: {
                    y:
                    {
                      min: 0,
                      //stepSize: 1,
                      //max:15,
                      ticks: {
                        precision: 0,
                        // Include a dollar sign in the ticks
                        callback: function (value, index, ticks) {
                          return value;
                        }
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getEvents, sendPostRequest })(GlobalChart);
