import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

const ConfirmDeleteUserModal = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal isOpen={props.showStaffHasBookings} toggle={props.toggle}>
        <ModalBody>
          <div className="">
            <div className="eventdetailsaddbox rd_modalvieew">
              <div className="box_content rd_modalboxcontent">
                <div className="rd_inputselectheadermodalsetting">
                  <button
                    className="rd_colsebtn"
                    onClick={props.toggle}
                  ></button>
                </div>

                <h5 className="rd_modalheadertext rd_modalheadertextnm">
                  Attention
                </h5>

                <p className="rd_modalheadertextparath mt-5 mb-5">
                  This staff member currently has bookings scheduled. Before
                  deleting or deactivating this staff member's account, please
                  reassign or cancel their booked slots.
                </p>

                <div className="rd_cancelappbtnmodal">
                  <button className="rd_cancel" onClick={props.toggle}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-app"
                    onClick={() => {
                      navigate("/admin/scheduled-events");
                    }}
                  >
                    Navigate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ConfirmDeleteUserModal;
