import PublicLayout from "../templates/layouts/public/Layout";
import PrivateLayout from "../templates/layouts/private/Layout";
import IntegrationLayout from "../templates/layouts/integration-apps/Layout";
import privateRoutes from "./types/private";
import publicRoutes from "./types/public";
import sessionRoutes from "./types/session";
import integrationRoutes from "./types/integrations";
import { ROUTE_TYPES } from "./routeTypes";

const routesTemplate = [
  {
    routes: publicRoutes,
    template: PublicLayout,
    type: ROUTE_TYPES.public,
  },
  {
    routes: privateRoutes,
    template: PrivateLayout,
    type: ROUTE_TYPES.private,
  },
  {
    routes: sessionRoutes,
    template: PublicLayout,
    type: ROUTE_TYPES.session,
  },
  {
    routes: integrationRoutes,
    template: IntegrationLayout,
    type: ROUTE_TYPES.integrationApps
  }
];

export default routesTemplate;
