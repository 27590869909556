import axios from "../../axios-instance";
import { fallBackErrorMessage } from "../../constants/event";
import { getAdminBusinessId } from "../../utils/authHelper";
import { objectToFormData } from "../../utils/utility";

/**
 * Get step one data
 * @param {*} options
 * @returns
 */
export const getStepOneData = (options) => async (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get(`admin/quick-setup-step-one/${getAdminBusinessId()}`)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * save step one data
 * @param {*} options
 */
export const saveStepOneData = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  const formData = objectToFormData(data);
  axios
    .post("admin/quick-setup-step-one", formData)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * Get step two data
 * @param {*} options
 * @returns
 */
export const getStepTwoData = (options) => async (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get(`admin/quick-setup-step-two/${getAdminBusinessId()}`)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * save step two data
 * @param {*} options
 */
export const saveStepTwoData = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/quick-setup-step-two", data)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * Get step three data
 * @param {*} options
 * @returns
 */
export const getStepThreeData = (options) => async (dispatch) => {
  const { onSuccess, onError } = options || {};
  axios
    .get(`admin/quick-setup-step-three/${getAdminBusinessId()}`)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * save step three data
 * @param {*} options
 */
export const saveStepThreeData = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("admin/quick-setup-step-three", data)
    .then((res) => {
      const { data } = res || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};
