import React, { useEffect, useState, useRef } from "react";
import ReactCrop, { centerCrop, makeAspectCrop, Crop } from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import { canvasPreview } from "./canvas-preview";

const useDebounceEffect = (fn, waitTime, deps) => {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
};

const ImageCropper = ({ imgSrc, data, onChange }) => {
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(16 / 9);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);

  useEffect(() => {
    console.log("data", data);
    setCrop(data);
  }, [data]);

  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      //setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <>
      {!!imgSrc && (
        <ReactCrop
          // minWidth={100}
          // minHeight={100}
          style={{ width: "100%", height: "100%" }}
          crop={crop}
          onChange={(c) => {
            //console.log("change", c);
            setCrop(c);
            setCompletedCrop(c);
          }}
          onComplete={(c) => {
            setTimeout(() => {
              if (previewCanvasRef.current) {
                console.log("data url", previewCanvasRef.current.toDataURL());
                onChange(c, previewCanvasRef.current.toDataURL());
              }
            }, 1000);
          }}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            // style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />

          {!!completedCrop && (
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          )}
        </ReactCrop>
      )}
    </>
  );
};

export default ImageCropper;
