import React, { Component } from "react";
import { gotProperty } from "../../../../utils/utility";
import { connect } from "react-redux";
import { onUpdateFilters } from "../../../../actions/filterActions";

class VacationFilters extends Component {
  render() {
    const { searchFilters } = this.props.filter;
    return (
      <>
        <div className="form-group">
          <label htmlFor="event-name">Vacation Name</label>
          <input
            type="text"
            id="event-name"
            placeholder="Vacation Name ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "vacation_name")
                ? searchFilters["vacation_name"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("vacation_name", e.target.value);
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  onUpdateFilters,
})(VacationFilters);
