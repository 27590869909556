import React from 'react';
import GoogleMeetIcon from "../../../assets/icons/google-meet.svg";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";


const GoogleMeet = () => {
    
    return (
        <Col md="6" xxl="3" xl="4">
            <Link
            to="/integrations/google-meet"
            className="int-col"
            >
            <div className="img">
                <img src={GoogleMeetIcon} alt="Google Calendar" />
            </div>
            <div className="text">
                <h3>Google Meet</h3>
                <p>
                Lorem Ipsum is simply dummy text of the
                printing an typesetting industry.
                </p>
            </div>
            </Link>
        </Col>
    );
}

export default GoogleMeet;