import {
  UPDATE_NOTIFICATION_UNREAD_COUNT,
  UPDATE_NOTIFICATIONS,
  APPEND_NOTIFICATIONS,
} from "../actions/types";
const initialState = {
  notifications: [],
  totalUnread: 0,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPEND_NOTIFICATIONS:
      const procNotifications = [...state.notifications];
      action.payload.notifications.forEach((notification, index) => {
        if (
          procNotifications.filter((noti) => noti.id === notification.id)
            .length === 0
        ) {
          procNotifications.push(notification);
        }
      });

      console.log("proce a", procNotifications);

      return {
        ...state,
        totalUnread: action.payload.totalUnread,
        notifications: [...procNotifications],
      };
    case UPDATE_NOTIFICATIONS:
      const processedNotifications = [...state.notifications];
      action.payload.notifications.forEach((notification, index) => {
        console.log("notifications", notification);
        if (
          processedNotifications.filter((noti) => noti.id === notification.id)
            .length === 0
        ) {
          console.log("abc here");
          processedNotifications.unshift(notification);
        }
      });

      console.log("proce b", processedNotifications);

      return {
        ...state,
        totalUnread: action.payload.totalUnread,
        notifications: [...processedNotifications],
      };
    case UPDATE_NOTIFICATION_UNREAD_COUNT:
      return {
        ...state,
        totalUnread: action.payload,
      };
    default:
      return state;
  }
};

export default notificationReducer;
