import React, { useState, useEffect } from "react";
import Loader from "../../../components/Loader/Loader";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDefaultLandingPage } from "../../../actions/adminDashboardActions";
import GlobalChart from "./Components/GlobalChart/GlobalChart";
import SalesChart from "./Components/SalesChart/SalesChart";
import SalesStatesChart from "./Components/SalesStatesChart/SalesStatesChart";
import PieSalesChart from "./Components/SalesStatesChart/PieSalesChart";
import StaffCommisionChart from "./Components/StaffCommisionChart/StaffCommisionChart";
import StaffPieChart from "./Components/StaffCommisionChart/StaffPieChart";
import { getEvents } from "../../../actions/adminDashboardActions";
import { getStaffs } from "../../../actions/staffActions";

const Dashboard = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [statsPageStatus, setStatsPageStatus] = useState("E");

  const [events, setEvents] = useState([]);
  const [staffs, setStaffs] = useState([]);


  useEffect(() => {
    const { getEvents, getStaffs } = props;
    getEvents({
      data: {},
      onSuccess: function (data) {
        setEvents([{ value: "", label: "All Events" }, ...data.events]);
      },
      onError: function (error) {
        console.log("error", error);
      },
    });

    getStaffs({
      data: {
          pageSize: 10000,
          pageNumber: 1,
      },
      onSuccess: function (response) {
          const staffList = response.entity.map((staff, index) => {
              return {
                  label: staff.first_name + " " + staff.last_name,
                  value: staff.id,
              };
          });
          setStaffs([{ value: "", label: "All Staffs" }, ...staffList]);
      },
      onError: function (error) {
          console.log("error", error);
      },
  });
  }, []);

  /**
   * set default landing page
   * @param {*} value
   */
  const setLandingPage = (value) => {
    const { setDefaultLandingPage } = props;

    setStatsPageStatus(value);

    let page = "stats";

    if (value === "E") {
      page = "scheduled-events";
    }

    if (page === "scheduled-events") {
      navigate("/admin/scheduled-events");
    }

    setIsLoading(true);

    setDefaultLandingPage({
      data: {
        page,
      },
      onSuccess: (response) => {
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Loader isShowLoader={isLoading} />
      <div className="eventdetailsaddbox rd_noshadow">
        <div className="rd_vacationfilterpart mt-3">
          <div className="w-100">
            <div className="rd_vacationflex2 dash_filter_bar main_dash">
              <label className="pr-2 mb-0">Set Stats Page as homepage</label>
              <div className="toggle_field">
                <div className="box_content p-0">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="paypalGuestPayment"
                      value={statsPageStatus}
                      checked={statsPageStatus === "E" ? true : false}
                      onChange={(event) => {
                        setLandingPage(event.target.checked ? "E" : "D");
                      }}
                    />
                  </div>
                </div>
              </div>
              <label className="pl-2 mb-0">
                Set scheduled events as homepage
              </label>
            </div>
          </div>
        </div>
        <GlobalChart events={events} />
        <SalesChart />
        <div className="page_title_dashboard">
          <div className="row justify-content-between">
            <div className="col-auto">
              <h2>Sales States</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-between mt-3 mt-md-4">
          <SalesStatesChart events={events} />
          <PieSalesChart events={events} />
          <StaffPieChart staffs={staffs} />
          <StaffCommisionChart staffs={staffs} />
        </div>
      </div>
    </>
  );
};

export default connect(null, { getEvents, getStaffs, setDefaultLandingPage })(Dashboard);
