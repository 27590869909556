import React, { useState, useEffect } from "react";
import Loader from "../../../../components/Loader/Loader";
import { Table } from "antd";
import { Col, Row } from "reactstrap";
import isEmpty from "../../../../validation/is-empty";
import { capitalizeFirstLetter } from "../../../../utils/utility";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { getCustomerPayments } from "../../../../actions/admin/crm-actions";
import Filter from "../../../../components/Filter/Filter";
import PaginatorDropdown from "../../../../components/Datatable/PaginationDropdown";
import ClientPaymentFilter from "./ClientPaymentFilter";
import { toggleFilter } from "../../../../actions/filterActions";

const ClientPayments = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    showSizeChanger: false,
  });
  const [filter, setFilter] = useState({});
  const [data, setData] = useState([]);

  const columns = [
    {
      title: "Booking Date",
      dataIndex: "booking_date",
      sorter: true,
    },
    {
      title: "Time",
      dataIndex: "booking_time",
      sorter: false,
    },
    {
      title: "Event",
      dataIndex: "event_name",
      sorter: true,
    },
    {
      title: "Event Format",
      dataIndex: "event_format",
      sorter: true,
    },
    {
      title: "Event Type",
      dataIndex: "is_event_paid",
      render: (_, data) => {
        return data.is_event_paid === "E" ? "Paid" : "Free";
      },
      sorter: false,
    },
    {
      title: "Venue",
      dataIndex: "venue",
      sorter: true,
    },
    {
      title: "Invitee",
      dataIndex: "invitee",
      render: (_, data) => {
        return (
          capitalizeFirstLetter(data.invitee_first_name) +
          " " +
          capitalizeFirstLetter(data.invitee_last_name)
        );
      },
      sorter: false,
    },
    {
      title: "Invitee Email",
      dataIndex: "invitee_email",
      sorter: false,
    },
    {
      title: "Invitee Phone",
      dataIndex: "invitee_phone",
      sorter: false,
    },
    {
      title: "Staff",
      dataIndex: "staff",
      render: (_, data) => {
        return (
          capitalizeFirstLetter(data.staff_first_name) +
          " " +
          capitalizeFirstLetter(data.staff_last_name)
        );
      },
      sorter: false,
    },
    {
      title: "Status",
      dataIndex: "booking_status",
      sorter: true,
    },
    {
      title: "Amount Charged",
      dataIndex: "booking_total_amount",
      sorter: false,
    },
    {
      title: "Commission",
      dataIndex: "commission",
      sorter: false,
    },
  ];

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  const prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * on filter data table
   * @param {*} name
   * @param {*} value
   */
  const filterRecords = () => {
    const { toggleFilter, filter } = props;
    toggleFilter(false);
    handleTableChange(
      {
        ...pagination,
      },
      {
        ...filter.searchFilters,
      },
      {}
    );
  };

  /**
   * on clear filter handler
   */
  const clearFilterHandler = () => {
    handleTableChange(
      {
        ...pagination,
      },
      {},
      {}
    );
  };

  /**
   * fetch data table data
   * @param {*} params
   */
  const fetchTableData = (params = {}) => {
    const { getCustomerPayments } = props;
    setIsDataLoading(true);
    getCustomerPayments({
      data: prepareDataParam(params),
      onSuccess: function (data) {
        setData(data.entity);
        setPagination({
          ...params.pagination,
          total: data.totalCount,
        });
        setIsDataLoading(false);
      },
      onError: function (error) {
        setData([]);

        setIsDataLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchTableData({ pagination });
  }, []);

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  const handleTableChange = (pagination, filters, sorter) => {
    fetchTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  /**
   * on page size drop down change
   * @param {*} e  event
   */
  const onPageSizeChange = (e) => {
    handleTableChange(
      {
        current: 1,
        pageSize: e.target.value,
        total: pagination.total,
      },
      {},
      {}
    );
  };
  return (
    <>
      <Row className="app-user-list">
        <Loader isShowLoader={isPageLoading} />
        <Col sm="12">
          <Filter
            name="Booking History"
            onClearFilterHandler={clearFilterHandler}
            onFilterRecords={filterRecords}
          >
            <ClientPaymentFilter />
          </Filter>
        </Col>
        <Col sm="12">
          <Table
            className="big_table"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={pagination}
            loading={isDataLoading}
            onChange={handleTableChange}
          ></Table>
        </Col>
        <Col sm="6" className="table_paginaton">
          <PaginatorDropdown
            onPageSizeChange={onPageSizeChange}
            pageSize={pagination.pageSize}
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  getCustomerPayments,
  toggleFilter,
})(ClientPayments);
