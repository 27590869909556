import React, { Component, Fragment } from "react";
import {
  Modal,
  ModalBody,
  Row,
  Col
} from "reactstrap";
import { notification } from "../../../../utils/utility";
import { FRONTEND_BASE_URL } from "../../../../utils/utility";
import moment from "moment";

import { renderToStaticMarkup } from 'react-dom/server';

//import CryptoAES from 'crypto-js/aes';
//import { APP_KEY } from "../../../../utils/prefixes";

class AddTimeToEmailDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };


  
  /**
   * on copy code
   */
  onCopyCode = () => {

    const _this = this;
    const { slots, event } = this.props;

    const { event_url } = event;

    var tempInput = document.createElement("textarea");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    let content = "";
    /*
    Object.keys(slots).map((key, index) => {
        content += "<h4>" + moment(key, "YYYY-MM-DD").format("dddd, MMMM, DD YYYY") + "</h4>";
        content += "<div class='row'>";
        let slotTemplate = "";
        //const encryptedDate = CryptoAES.encrypt(key, APP_KEY);
        (slots[`${key}`] || []).map((slot, innerIndex) => {

            //const encryptedSlot = CryptoAES.encrypt(slot, APP_KEY);

            slotTemplate += "<div class='col-md-3 col-lg-3'>";
            slotTemplate += "   <div class='add-time-slot-container'>";
            slotTemplate += "       <div>";
            slotTemplate += "           <a href='"+FRONTEND_BASE_URL+"booking/"+event_url+"/"+key+"/"+slot+"'>";
            slotTemplate += slot;
            slotTemplate += "           </a>";
            slotTemplate += "       </div>";
            slotTemplate += "   </div>";
            slotTemplate += "</div>";
        });
        content += slotTemplate;
        content += "</div>";
    });
    */

    console.log('event', event);

    content = (
      <table widht="344" style={{width: '344px', maxWidth: '344px', fontFamily: "Sofia Pro, sans-serif", fontStyle: 'normal', fontWeight: 'normal', 'fontSize': '16px', backgroundColor: 'white'}}>
        <tbody>
          <tr>
            <td>
              <div style={{paddingBottom: '3px', fontFamily: "Sofia Pro, sans-serif", fontSize: '13px', color: '#0C4767', backgroundColor: 'white', lineHeight: 1.4, width: '344px'}}>
                <div style={{
                  fontFamily: "Sofia Pro, sans-serif", 
                  fontStyle: 'normal', 
                  fontWeight: 'bold', 
                  fontSize: '16px', 
                  lineHeight: '19px', 
                  color: '#0C4767', 
                  marginTop: '15px'}}
                >
                  {event.event_title}
                </div>

                <div style={{
                  fontFamily: "Sofia Pro, sans-serif", 
                  fontStyle: 'normal', 
                  fontWeight: 'normal', 
                  fontSize: '14px', 
                  lineHeight: '17px', 
                  color: '#fc9834'
                  }}>
                    {event.timeslot} mins
                </div>

                <div>
                  <b style={{color: '#0C4767'}}></b>
                  <span style={{
                    fontFamily: "Sofia Pro, sans-serif", 
                    fontStyle: 'normal', 
                    fontWeight: 'normal', 
                    fontSize: '14px', 
                    lineHeight: '17px', 
                    color: '#fc9834'
                  }}>
                    Time zone: Pakistan, Maldives Time{" "} 
                  </span>
                  <a href={`${FRONTEND_BASE_URL}booking/${event_url}`} style={{marginLeft: 3, color: '#0C4767', }}> Change </a>
                </div>

                {
                  Object.keys(slots).map((key, index) => {
                    return (
                      <table style={{
                        marginTop: '16px', 
                        textAlign: 'left', 
                        borderCollapse: 'collapse', 
                        borderSpacing: 0, 
                        width: '362px'}}
                        key={`dated-${index}`}
                      >
                        <tbody>
                          <tr>
                            <td style={{textAlign: 'left', marginTop: '16px'}}>
                              <span style={{
                                fontFamily: "Sofia Pro, sans-serif", 
                                fontStyle: 'normal', 
                                fontWeight: 'bold', 
                                fontSize: '14px', 
                                lineHeight: '16px', 
                                color: '#0C4767', 
                                paddingBottom: 8, 
                              }}>
                                {moment(key, "YYYY-MM-DD").format("dddd, MMMM, DD YYYY")}
                              </span>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <table style={{borderCollapse: 'separate', borderSpacing: '0px 4px'}}>
                                <tbody>
                                  <tr style={{height: '25px'}}>
                                    {
                                      (slots[`${key}`] || []).map((slot, innerIndex) => {
                                        return (
                                          <td style={{padding: 0, width: 64, display: 'inline-block', marginRight: 4, height: 22, float: 'left', border: '1px solid #fc9834', borderRadius: 3}}>
                                            <table style={{height: 21}}>
                                              <tbody>
                                                <tr style={{height: 21 }}>
                                                  <td style={{width: 7}}></td>
                                                  <td style={{width: 50, textAlign: 'center'}}>
                                                    <a href={`${FRONTEND_BASE_URL}booking/${event_url}/${key}/${slot}`} className="spot" style={{fontFamily: "Sofia Pro, sans-serif", textDecoration: 'none', textAlign: 'center', color: '#fc9834', fontSize: 12, lineHeight: 1.6}}>
                                                        <b style={{fontWeight: 'normal', textDecoration: 'none'}}>{slot}</b>
                                                    </a>
                                                  </td>
                                                  <td style={{height: 7}}></td>
                                                </tr>
                                              </tbody>
                                          </table>
                                          </td>

                                        );
                                      })
                                    }

                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>  
                                                    

                        </tbody>

                      </table>
                      
                    );
                  })
                }
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    )

    document.getElementById("copy-element").innerHTML = renderToStaticMarkup(content);
    const str = document.getElementById("copy-element").innerHTML;

    document.addEventListener("copy", (e) => {
        e.clipboardData.setData("text/html", str);
        e.clipboardData.setData("text/plain", str);
        e.preventDefault();
    });
    document.execCommand("copy");
    document.removeEventListener("copy", (e) => {
        e.clipboardData.setData("text/html", str);
        e.clipboardData.setData("text/plain", str);
        e.preventDefault();
    });

    notification.success(
        "Slots copied successfully"
    );
  };

  render() {
    const { isOpen } = this.state;
    const { event, slots } = this.props;
    const { event_title } = event;

    return (
      <Fragment>
        <button onClick={this.toggle}>Continue</button>
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className="event-modal modal-lg modal-dialog embed_other_modal"
        >
          <ModalBody>
            <div id="copy-element" className="d-none"></div>  
            <div className="">
              <div className="eventdetailsaddbox rd_modalvieew rd_modalvieewdashboard">
                <div className="box_content rd_modalboxcontent">
                  <div className="rd_dashboardmodaltopt">
                    <div>
                      <p>{event.event_title}</p>
                      <p>
                        <span className="rdmodaldashicontim"></span>
                        {event.timeslot !== 'Customized' ? `${event.timeslot} mins` :  event.timeslot} 
                      </p>
                    </div>
                    <div className="rd_alignright">
                      <p>
                        <span className="rd_zoomicon"></span>
                        {event.venue}
                      </p>
                      <p>{event.event_format_id === 1 ? 'One to One': 'Group'}</p>
                    </div>
                  </div>
                  <div className="rd_dashboardmodalmainco">
                    <div className="rd_dashboardmodalmaincotoppart">
                      <div>
                        <h4>
                          <strong>Event Name</strong>
                        </h4>
                        <p>{event_title}</p>
                      </div>
                      <div>
                        <button onClick={this.toggle}>Change</button>
                      </div>
                    </div>

                    <div
                      className="rd_modaldashtabtop opened"
                      style={{ display: "none" }}
                    >
                      <button>Link Text Settings</button>
                    </div>
                    <div
                      className="rd_modaldashtabtcont opened"
                      style={{ display: "none" }}
                    >
                      <p>
                        Customize the link visitors will click to launch your
                        Calendly page.
                      </p>
                      <div className="rd_modaldashtabtcontspeci">
                        <label htmlFor="">Link Text</label>
                        <input
                          type="text"
                          name=""
                          id=""
                          placeholder="Schedule time with me"
                        />
                      </div>
                    </div>
                    <div
                      className="rd_modaldashtabtop opened"
                      style={{ display: "none" }}
                    >
                      <button>Page Settings</button>
                    </div>
                    <div
                      className="rd_modaldashtabtcont opened"
                      style={{ display: "none" }}
                    >
                      <div className="rd_flexthing rd_flexthingspethingmo">
                        <div className="rd_flexthing33">
                          <h6>
                            <strong>Page Settings</strong>
                          </h6>
                          <p>
                            Customize the look of your booking page to fit
                            seamlessly into your website
                          </p>
                        </div>
                        <div className="rd_flexthing2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked3"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked3"
                            >
                              Hide Event Type Details
                            </label>
                          </div>
                        </div>
                        <div className="rd_flexthing2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked3"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked3"
                            >
                              Hide GDPR Banner
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="rd_flexthing rd_flexthingspethingmo">
                        <div className="rd_flexthing2">
                          <label htmlFor="">Background color</label>
                          <button className="rd_colorbtn"></button>
                        </div>
                        <div className="rd_flexthing2">
                          <label htmlFor="">Text color</label>
                          <button className="rd_colorbtn"></button>
                        </div>
                        <div className="rd_flexthing2">
                          <label htmlFor="">Button and link color</label>
                          <button className="rd_colorbtn"></button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="rd_modaldashtabtop opened"
                      style={{ display: "none" }}
                    >
                      <button>Popup Widget Settings</button>
                    </div>
                    <div
                      className="rd_modaldashtabtcont opened"
                      style={{ display: "none" }}
                    >
                      <p>
                        Customize the display of the floating button that will
                        launch your Calendly page.
                      </p>
                      <div className="rd_flexthing rd_flexthingspethingmo">
                        <div className="rd_flexthing33">
                          <label>Button Text</label>
                          <input
                            type="text"
                            name=""
                            id=""
                            placeholder="Schedule time with me"
                          />
                        </div>
                        <div className="rd_flexthing2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked3"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked3"
                            >
                              Button Background color
                            </label>
                          </div>
                        </div>
                        <div className="rd_flexthing2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked3"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked3"
                            >
                              Button Text color
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rd_emebedparinmodl">
                    <h5>
                      <strong>Paste your choosen times into Email</strong>
                    </h5>
                    {Object.keys(slots).map((key, index) => {
                        return (
                            <Fragment key={index}>
                                <h4 className="mt-3 mb-3">
                                    <span className="rd_archiveiconth"></span>
                                    {moment(key, "YYYY-MM-DD").format("dddd, MMMM, DD YYYY")}
                                </h4>
                                <div className="row add-time-slot-container custom_slot">
                                    <Row>
                                        {(slots[`${key}`] || []).map((slot, innerIndex) => {
                                            return (
                                                <Col md="3" lg="3">
                                                    <div
                                                    key={innerIndex}
                                                    className="slot time_active"
                                                    >
                                                    {slot}
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </div>
                                    
                            </Fragment>
                        );
                    })}


                  </div>

                  <div className="rd_dashboardmodalfooterpart">
                    <div>
                      <button
                        className="rd_dashbackbutton"
                        onClick={this.toggle}
                      >
                        Back
                      </button>
                    </div>
                    <div className="rd_dashboardmodalfooterpartb">
                      <button onClick={this.toggle}>Cancel</button>
                      <button onClick={this.onCopyCode}>Copy Code</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default AddTimeToEmailDetailModal;
