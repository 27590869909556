import React, { useState } from 'react';
import { Select } from 'antd';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Collapse,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import DatePicker from 'react-date-picker';
import filterIcon from "../../assets/icons/filter_icon.svg";
import { Link } from 'react-router-dom';

const { Option } = Select;
const AfterHeaderBar = (props) => {
    const [filterShow, setFilterShow] = useState(false);
    return (<>
        <div className={`filter_bar d-flex justify-content-between align-items-center ${props.showBtn ? 'with_btn' : ''}`}>
              <h3>{props.title}</h3>
              <div className='d-flex align-items-center w-auto'>
              {props.showBtn ? 
              <button className="btn btn-outline btn-add mr-4" type="button" style={{width: '126px'}}>
                <Link to={"/admin/staff/create"} style={{color : 'inherit'}}>Add New</Link>
              </button> : null }
              <div className="filter_sec">
                <button className="btn p-0" onClick={() => setFilterShow(true)} style={{ width: '30px' }}><img src={filterIcon} alt="" width={27} height={27} /></button>
                {/* {this.state.filterShow ? <> */}
                  <div className={`filter_backdrop ${filterShow ? 'active' : '' }`}></div>
                  <div className={`filter_content ${filterShow ? 'active' : '' }`}>
                    <div className="position-relative">
                      <div className="top_bar">
                        <h3>Filters</h3>
                        <button className="btn p-0">Clear Filters</button>
                      </div>
                      <div className="filter_items_container">
                        <div class="form-group">
                          <label>Email address</label>
                          <input type="text" placeholder="Event Name#..." class="form-control" />
                        </div>
                        <FormGroup>
                          <Label for="exampleSelect">
                            Venue
                          </Label>
                          <Input
                            id="exampleSelect"
                            name="select"
                            type="select"
                          >
                            <option>
                              Select Venue
                            </option>
                            <option>
                              2
                            </option>
                            <option>
                              3
                            </option>
                            <option>
                              4
                            </option>
                            <option>
                              5
                            </option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleSelect1">
                            Type
                          </Label>
                          <Input
                            id="exampleSelect1"
                            name="select"
                            type="select"
                          >
                            <option>
                              Select Type
                            </option>
                            <option>
                              2
                            </option>
                            <option>
                              3
                            </option>
                            <option>
                              4
                            </option>
                            <option>
                              5
                            </option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleSelect3">
                            Status
                          </Label>
                          <Input
                            id="exampleSelect3"
                            name="select"
                            type="select"
                          >
                            <option>
                              Select Status
                            </option>
                            <option>
                              2
                            </option>
                            <option>
                              3
                            </option>
                            <option>
                              4
                            </option>
                            <option>
                              5
                            </option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleSelect4">
                            Event Format
                          </Label>
                          <Input
                            id="exampleSelect4"
                            name="select"
                            type="select"
                          >
                            <option>
                              Select Event Format
                            </option>
                            <option>
                              2
                            </option>
                            <option>
                              3
                            </option>
                            <option>
                              4
                            </option>
                            <option>
                              5
                            </option>
                          </Input>
                        </FormGroup>

                        <FormGroup check>
                          <Input
                            name="radio1"
                            type="radio"
                          />
                          {' '}
                          <Label check>
                            Radio 1
                          </Label>
                        </FormGroup>

                        <FormGroup check>
                          <Input type="checkbox" />
                          {' '}
                          <Label check>
                            Checkbox 1
                          </Label>
                        </FormGroup>

                        <FormGroup>
                          <Label for="exampleText">
                            Text Area
                          </Label>
                          <Input
                            id="exampleText"
                            name="text"
                            type="textarea"
                          />
                        </FormGroup>

                        <div className="mb-3">
                        <label>Multi Select</label>
                          <Select
                            className="w-100"
                            mode="multiple"
                            placeholder="Select Staff"
                            onChange={(value) => {
                              this.setState({ ...this.state, multiSelect: value })
                            }}
                          >
                            <Option>opt1</Option>
                            <Option>opt2</Option>
                            <Option>opt3</Option>
                            <Option>opt4</Option>
                          </Select>
                        </div>

                        <div className="mb-3">
                          <DatePicker
                            className="my-calendar-view"
                            format="MM"
                            // selected={(this.state.month && new Date(this.state.month).getMonth()) || null}
                            // value={this.state.month}
                            // onChange={(val) => {
                            //   // setMonth(val);
                            //   this.setState({ ...this.state, month: val })
                            // }}
                            maxDetail="year"
                            />
                        </div>
                        
                      </div>
                      <div className="bottom_bar">
                        <button className="btn btn-outline" onClick={() => setFilterShow(false)}>Cancel</button>
                        <button className="btn btn-app">Apply</button>
                      </div>
                    </div>
                  </div> 
                  {/* </> : null} */}
              </div>
              </div>
            </div>
    </>);
}

export default AfterHeaderBar;
