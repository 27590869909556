import React, { useState, useEffect } from "react";
import Upcoming from "./upcoming";
import Pending from "./pending";
import Past from "./past";
import DateRange from "./date-range";
import WarningIcon from "../../../../assets/icons/warning_icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import isEmpty from "../../../../validation/is-empty";
import Tooltip from "../../../../components/Tooltip";

const ScheduledEvents = () => {
  const [tab, setTab] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const { search } = location || {};
  const locationParse = qs.parse(search);

  const { page } = locationParse || {};

  useEffect(() => {
    if (!isEmpty(page)) {
      switch (page) {
        case "upcoming":
          setTab(1);
          break;
        case "pending":
          setTab(2);
          break;
        case "past":
          setTab(3);
          break;
        case "date-range":
          setTab(4);
          break;
      }
    }
  }, []);

  /**
   * Toggle tab
   * @param int tab
   */
  const toggleTab = (tab) => {
    setTab(tab);
  };

  return (
    <section>
      <div className="cmplt_prof_msg">
        <img src={WarningIcon} alt="Alert Icon" />{" "}
        <p>
          Warning: You need to complete your profile in order to start sharing
          your event!
        </p>
      </div>
      <div>
        <div className="eventdetailsaddbox rd_noshadow">
          <div className="boxheader rd_floatingheaderthig">
            <div className="rd_inputselectheader">
              <div className="rd_selectheaderrdt2">
                <button
                  onClick={() => toggleTab(1)}
                  className={`${tab === 1 ? "active" : ""}`}
                >
                  Upcoming <Tooltip code="P4F1" />
                </button>
                <button
                  onClick={() => toggleTab(2)}
                  className={`${tab === 2 ? "active" : ""}`}
                >
                  Pending <Tooltip code="P4F2" />
                </button>
                <button
                  onClick={() => toggleTab(3)}
                  className={`${tab === 3 ? "active" : ""}`}
                >
                  Past <Tooltip code="P4F3" />
                </button>
                <button
                  onClick={() => toggleTab(4)}
                  className={`${tab === 4 ? "active" : ""}`}
                >
                  Date Range <Tooltip code="P4F4" />
                </button>
              </div>

              <div className="rd_linkfilexp">
                <button className="rd_filterboxth d-none">Filter</button>
                <button className="rd_exporboxth d-none">Export</button>
              </div>
            </div>
          </div>

          {tab === 1 ? <Upcoming /> : null}

          {tab === 2 ? <Pending /> : null}

          {tab === 3 ? <Past /> : null}

          {tab === 4 && <DateRange />}
        </div>
      </div>
    </section>
  );
};

export default ScheduledEvents;
