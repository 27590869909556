import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import "antd/dist/antd.css";
import Loader from "../../../components/Loader/Loader";
import Filter from "../../../components/Filter/Filter";
import IntegrationGoupImg from "../../../assets/images/all-integ.png";
import { Link } from "react-router-dom";
import GoogleCalendar from "../../../assets/icons/g-calendar.png";
import MsOffice from "../../../assets/icons/ms-office-360.svg";
import ICloud from "../../../assets/icons/apple-icloud.svg";
import OutlookCalendar from "../../../assets/icons/ms-outlook.svg";
import GoToIcon from "../../../assets/icons/goto_icon.svg";
import ExchangeCalendar from "../../../assets/icons/exchange-calendar.svg";
import ZoomIcon from "../../../assets/icons/zoom.svg";
import TeamsIcon from "../../../assets/icons/Microsoft_Teams.svg";
import GoogleMeet from "../../../assets/icons/google-meet.svg";

const NewIntegrations = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: false,
  });

  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);

  const [rowData, setRowData] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);





  return (
    <>
      <Row className="all-integ">
        <Loader isShowLoader={isPageLoading} />
        <Col sm="12">
          <Filter
            name="Integrations"
            showButtons={false}
          >
          </Filter>
        </Col>
        <Col sm="12">
       <section class="integreted-calenders calend-integ w-100">
         <div className="row">
            <Col lg="6">
                <div class="calender-party-col integration-set flex-column flex-md-row">
                  <div class="calender-party-details">
                    <img src={GoToIcon} alt="GoTo" />
                      <div class="calender-name">
                        <p>Connected by</p>
                        <p class="status">
                          <span class="ml-0">dummymail&mail.com</span>
                        </p>
                      </div>
                  </div>
                      <button class="btn btn-outline brand w-auto">Disconnect</button>
                </div>
                <div class="box_content goto">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked4" checked=""></input>
                      <label for="event_name" class="form-label">Include Dial-in Conference Line
                        <span class="tooltip-wrapper">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                            <line x1="12" y1="17" x2="12.01" y2="17"></line>
                            </svg>
                            <div class="tooltip-section undefined">
                              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</p>
                            </div>
                        </span>
                      </label>
                    </div>
                </div>
                <div class="goto-det">
                  <p>*Requiers paid GoTo Meeting account</p>
                  <p>To learn more about the GoTo Meeting Integration, see the <a href="#">Help Center.</a></p>
                </div>
            </Col> 
            <Col lg="6">
                <div class="calender-party-col integration-set flex-column flex-md-row">
                  <div class="calender-party-details">
                    <img src={MsOffice} alt="MsOffice" />
                      <div class="calender-name">
                        <p>Connected by</p>
                        <p class="status">
                          <span class="ml-0">dummymail&mail.com</span>
                        </p>
                      </div>
                  </div>
                      <button class="btn btn-outline brand w-auto">Disconnect</button>
                </div>
            </Col> 
         </div>
       </section>
       </Col>

       
        
      </Row>
    </>
  );
};

export default NewIntegrations;
