import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Row, Col, Input } from "reactstrap";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import FormField from "../../../components/FormField/FormField";
import TextEditor from "../../events/Components/Common/TextEditorField";
import UploaderIcon from "../../../assets/icons/uploader_icon.svg";
import isEmpty from "../../../validation/is-empty";
import {
  getPages,
  getPageFields,
} from "../../../actions/super-admin/tooltip-actions";

const validation = Yup.object().shape({
  page_id: Yup.string().required("Page is required."),
  page_field_id: Yup.string().required("Page field is required."),
  tooltip_text: Yup.string().required("Tooltip text is required."),
});

let initialValues = {
  page_id: "",
  page_field_id: "",
  tooltip_text: "",
  article_link: "",
  tooltip_image: "",
};

const EditModal = (props) => {
  const [formValues, setFormValues] = useState(null);
  const [tooltipImage, setTooltipImage] = useState(undefined);
  const [pages, setPages] = useState([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const { getPages } = props;
    getPages({
      data: {},
      onSuccess: function (data) {
        setPages(data.pages);
      },
      onError: function (error) {},
    });

    loadPageFields(props.data.page_id);
    setFormValues({
      ...props.data,
    });
  }, []);

  /**
   * on change tooltip image
   */
  const onChangeTooltipImage = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setTooltipImage(undefined);
      return;
    }
    setTooltipImage(event.target.files[0]);
  };

  /**
   * load page fields
   * @param in pageId
   */
  const loadPageFields = (pageId) => {
    const { getPageFields } = props;
    getPageFields({
      data: { pageId },
      onSuccess: function (data) {
        setFields(data.fields);
      },
      onError: function (error) {},
    });
  };

  return (
    <>
      <Modal
        isOpen={props.showEditModal}
        toggle={props.handleClose}
        className="tooltip_modal"
      >
        <ModalBody>
          <div className="">
            <div className="box_content">
              <div className="rd_inputselectheadermodalsetting">
                <button
                  className="rd_colsebtn"
                  onClick={props.handleClose}
                ></button>
              </div>

              <h5 className="rd_modalheadertext rd_modalheadertextnm">
                Edit Tooltip
              </h5>

              <Formik
                validationSchema={validation}
                initialValues={formValues || initialValues}
                enableReinitialize
                onSubmit={(values) => {
                  const { onUpdateRecord } = props;
                  let formData = {
                    ...values,
                  };
                  if (tooltipImage !== undefined) {
                    formData.tooltip_image = tooltipImage;
                  }
                  props.handleClose();
                  onUpdateRecord(formData);
                }}
              >
                {(formProps) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                  } = formProps;
                  return (
                    <Form>
                      <Row>
                        <Col lg="12" md="12">
                          <label className="same-label" htmlFor="coupon_code">
                            Page *
                          </label>
                          <FormField
                            placeholder="Select Page"
                            showPlaceholder={true}
                            type="select"
                            name="page_id"
                            errors={errors}
                            touched={touched}
                            options={pages}
                            value={values.page_id.toString()}
                            onChange={(event) => {
                              const value = event.target.value;
                              handleChange({
                                target: {
                                  name: "page_id",
                                  value,
                                },
                              });
                              console.log("value", value);
                              if (!isEmpty(value)) {
                                loadPageFields(value);
                              } else {
                                setFieldValue("page_field_id", "");
                                setFields([]);
                              }
                            }}
                          />
                        </Col>

                        <Col lg="12" md="12">
                          <label className="same-label" htmlFor="coupon_code">
                            Page Field *
                          </label>
                          <FormField
                            placeholder="Select Page Field"
                            showPlaceholder={true}
                            type="select"
                            name="page_field_id"
                            errors={errors}
                            touched={touched}
                            options={fields}
                            value={values.page_field_id.toString()}
                          />
                        </Col>
                        <Col lg="12" md="12">
                          <label className="same-label" htmlFor="coupon_code">
                            Tooltip Text *
                          </label>
                          <FormField
                            type="text"
                            name="tooltip_text"
                            placeholder="Tooltip Text"
                            value={values.tooltip_text}
                            errors={errors}
                            touched={touched}
                            onChange={({ target }) => {
                              const { value } = target;
                              handleChange({
                                target: { name: "tooltip_text", value },
                              });
                            }}
                          />
                        </Col>

                        <Col lg="12" md="12">
                          <label className="same-label" htmlFor="coupon_code">
                            Article Link
                          </label>
                          <TextEditor
                            name="article_link"
                            value={values.article_link}
                            onChange={(value) => {
                              handleChange({
                                target: {
                                  name: "article_link",
                                  value,
                                },
                              });
                            }}
                          />
                        </Col>

                        <Col lg="12" md="12">
                          <label className="same-label" htmlFor="coupon_code">
                            Video Link
                          </label>
                          <TextEditor
                            name="video_link"
                            value={values.video_link}
                            onChange={(value) => {
                              handleChange({
                                target: {
                                  name: "video_link",
                                  value,
                                },
                              });
                            }}
                          />
                        </Col>

                        <Col lg="12" md="12">
                          <div className="img_upload mt-4 mb-3">
                            <label htmlFor="tooltip_image">
                              <input
                                type="file"
                                name="tooltip_image"
                                id="tooltip_image"
                                onChange={onChangeTooltipImage}
                              />
                              <img src={UploaderIcon} alt="Upload icon" />
                              <p>
                                Drop your image here, or <span>browse</span>{" "}
                                here
                              </p>
                            </label>
                          </div>
                        </Col>
                      </Row>

                      <div className="rd_cancelappbtnmodal">
                        <button
                          type="button"
                          className="rd_cancel"
                          onClick={props.handleClose}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-app">
                          Save
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, {
  getPages,
  getPageFields,
})(EditModal);
