import React, { Component } from "react";
import { gotProperty, getDBFormatedDate } from "../../../utils/utility";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { getVenues } from "../../../actions/eventActions";
import { onUpdateFilters } from "../../../actions/filterActions";
import DatePicker from "react-date-picker";

class BookingHistoryFilters extends Component {
  state = {
    venues: [],
    bookingDate: null,
  };

  componentDidMount() {
    const _this = this;
    const { getVenues } = this.props;
    getVenues({
      onSuccess: function (venues) {
        _this.setState({
          venues,
        });
      },
      onError: function (error) {
        console.error(error);
      },
    });
  }

  render() {
    const { searchFilters } = this.props.filter;
    const { venues } = this.state;

    return (
      <>
        <div class="form-group">
          <label htmlFor="booking-status">Show Booking</label>
          <Input
            type="select"
            name="show_bookings"
            id="booking-status"
            value={
              gotProperty(searchFilters, "show_bookings")
                ? searchFilters["show_bookings"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("show_bookings", e.target.value);
            }}
          >
            <option value="A">Show All</option>
            <option value="F">Future</option>
            <option value="P">Past</option>
          </Input>
        </div>

        <div className="form-group">
          <label htmlFor="event-name">Event Name</label>
          <input
            type="text"
            id="event-name"
            placeholder="Event Name ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "event_name")
                ? searchFilters["event_name"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("event_name", e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <div className="mb-3">
            <label htmlFor="event-format">Event Date</label>
            <DatePicker
              className="my-calendar-view"
              format="dd-MM-yyyy"
              value={
                gotProperty(searchFilters, "booking_date")
                  ? this.state.bookingDate
                  : null
              }
              onChange={(val) => {
                this.setState({
                  bookingDate: val,
                });
                this.props.onUpdateFilters(
                  "booking_date",
                  getDBFormatedDate(val)
                );
              }}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="event-format">Event Format</label>
          <Input
            type="select"
            name="event_format"
            id="event-format"
            value={
              gotProperty(searchFilters, "event_format_id")
                ? searchFilters["event_format_id"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("event_format_id", e.target.value);
            }}
          >
            <option value="">Select Event Format</option>
            <option value="1">One to One</option>
            <option value="2">Group</option>
          </Input>
        </div>

        <div className="form-group">
          <label htmlFor="event-venue">Venue</label>
          <Input
            type="select"
            name="select"
            id="event-venue"
            value={
              gotProperty(searchFilters, "venue_id")
                ? searchFilters["venue_id"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("venue_id", e.target.value);
            }}
          >
            <option value="">Select Venue</option>
            {(venues || []).map((venue, index) => {
              return (
                <option key={index} value={venue.id}>
                  {venue.venue}
                </option>
              );
            })}
          </Input>
        </div>

        <div className="form-group">
          <label htmlFor="customer">Invitee</label>
          <input
            type="text"
            id="customer"
            placeholder="Invitee ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "customer")
                ? searchFilters["customer"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("customer", e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="customer-email">Invitee Email</label>
          <input
            type="text"
            id="customer-email"
            placeholder="Invitee Email ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "customer_email")
                ? searchFilters["customer_email"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("customer_email", e.target.value);
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  getVenues,
  onUpdateFilters,
})(BookingHistoryFilters);
