import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getTooltips,
  createTooltip,
  deleteTooltip,
  updateTooltip,
} from "../../../actions/super-admin/tooltip-actions";
import { toggleFilter } from "../../../actions/filterActions";
import { Table, Menu, Dropdown } from "antd";
import { Col, Row } from "reactstrap";
import { Edit } from "react-feather";
import ActionDots from "../../../components/Datatable/ActionDots";
import isEmpty from "../../../validation/is-empty";
import Loader from "../../../components/Loader/Loader";
import Filter from "../../../components/Filter/Filter";
import TooltipFilters from "./tooltip-filters";
import CreateModal from "./create";
import PaginatorDropdown from "../../../components/Datatable/PaginationDropdown";
import { notification, IMAGE_BASE_URL } from "../../../utils/utility";
import DeleteTooltipModal from "./delete";
import EditModal from "./edit";
import { stripHTMLTags } from "../../../utils/utility";

const Tooltip = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  });

  const [data, setData] = useState([]);

  const [rowData, setRowData] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const columns = [
    {
      title: "ID",
      dataIndex: "tooltip_code",
      sorter: false,
    },
    {
      title: "Location",
      dataIndex: "location",
      render: (_, data) => {
        if (data.page_name && data.page_field_name) {
          return `${data.page_name} => ${data.page_field_name}`;
        } else {
          return null;
        }
      },
    },
    {
      title: "Text",
      dataIndex: "tooltip_text",
      sorter: true,
    },
    {
      title: "Image",
      dataIndex: "tooltip_image",
      render: (_, data) => {
        if (data.tooltip_image) {
          return (
            <img
              src={`${IMAGE_BASE_URL}${data.tooltip_image}`}
              alt="Tooltip Img"
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      title: "Article",
      dataIndex: "article_link",
      sorter: false,
      render: (_, data) => stripHTMLTags(data.article_link),
    },
    {
      title: "Video",
      dataIndex: "video_link",
      sorter: false,
      render: (_, data) => stripHTMLTags(data.video_link),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, data) => {
        const menu = (
          <Menu
            items={[
              {
                label: (
                  <span
                    onClick={() => {
                      onEditClick(data);
                    }}
                  >
                    <Edit className="mr-2" size={15} /> Edit
                  </span>
                ),
                key: "0",
              },
              {
                type: "divider",
              },
              {
                label: (
                  <>
                    <DeleteTooltipModal
                      onDeleteRecord={onDeleteRecord}
                      data={data}
                    />
                  </>
                ),
                key: "1",
              },
            ]}
          />
        );

        return (
          <div className="actions cursor-pointer">
            <Dropdown overlay={menu} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <ActionDots />
              </a>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  /**
   * hide edit modal
   */
  const hideEditModal = () => {
    setShowEditModal(false);
  };

  /**
   * on Edit icon click
   * @param {*} data
   */
  const onEditClick = (data) => {
    setRowData(data);
    setShowEditModal(true);
  };

  /**
   * on update record
   * @param {*} data
   */
  const onUpdateRecord = (data) => {
    const { updateTooltip, filter } = props;
    setIsPageLoading(true);
    setIsDataLoading(true);
    updateTooltip({
      data: {
        ...data,
      },
      onSuccess: (response) => {
        const { success } = response;
        if (success) {
          notification.success("Tooltip successfully updated");
        } else {
          notification.error(
            "An error occured during update, please try later."
          );
        }
        handleTableChange(
          {
            ...pagination,
          },
          {
            ...filter.searchFilters,
          },
          {}
        );
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
      onError: (error) => {
        console.log("error", error);
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on delete record
   * @param {*} id
   */
  const onDeleteRecord = (id) => {
    const { deleteTooltip } = props;
    setIsDataLoading(true);
    deleteTooltip({
      data: {
        id,
      },
      onSuccess: function (data) {
        notification.success("Record successfully deleted");
        handleTableChange(
          {
            current: 1,
            pageSize: 10,
          },
          {},
          {}
        );
        setIsDataLoading(false);
      },
      onError: function (error) {
        notification.error("An error occured during delete, Please try later");
        console.log("error", error);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * add record
   * @param {*} data
   */
  const onAddRecord = (data) => {
    const { createTooltip, filter } = props;
    setIsPageLoading(true);
    setIsDataLoading(true);
    createTooltip({
      data: {
        ...data,
      },
      onSuccess: (response) => {
        const { success } = response;
        if (success) {
          notification.success("Tooltip successfully added");
        } else {
          notification.error(
            "An error occured during update, please try later."
          );
        }
        handleTableChange(
          {
            ...pagination,
          },
          {
            ...filter.searchFilters,
          },
          {}
        );
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
      onError: (error) => {
        console.log("error", error);
        setIsPageLoading(false);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on page size drop down change
   * @param {*} e  event
   */
  const onPageSizeChange = (e) => {
    handleTableChange(
      {
        //current: pagination.current,
        current: 1,
        pageSize: e.target.value,
        total: pagination.total,
      },
      {},
      {}
    );
  };

  /**
   * on filter data table
   * @param {*} name
   * @param {*} value
   */
  const filterRecords = () => {
    const { toggleFilter, filter } = props;
    toggleFilter(false);
    handleTableChange(
      {
        current: 1,
        pageSize: 10,
      },
      {
        ...filter.searchFilters,
      },
      {}
    );
  };

  /**
   * on clear filter handler
   */
  const clearFilterHandler = () => {
    handleTableChange(
      {
        ...pagination,
      },
      {},
      {}
    );
  };

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  const prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * fetch data table data
   * @param {*} params
   */
  const fetchTableData = (params = {}) => {
    const { getTooltips } = props;
    setIsDataLoading(true);
    getTooltips({
      data: prepareDataParam(params),
      onSuccess: function (data) {
        setData(data.entity);
        setPagination({
          ...params.pagination,
          total: data.totalCount,
        });
        setIsDataLoading(false);
      },
      onError: function (error) {
        setData([]);
        console.log("error", error);
        setIsDataLoading(false);
      },
    });
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  const handleTableChange = (pagination, filters, sorter) => {
    fetchTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  useEffect(() => {
    fetchTableData({ pagination });
  }, []);

  return (
    <>
      <Row className="app-user-list">
        <Loader isShowLoader={isPageLoading} />
        {showEditModal && (
          <EditModal
            onUpdateRecord={onUpdateRecord}
            data={rowData}
            showEditModal={showEditModal}
            handleClose={hideEditModal}
          />
        )}
        <Col sm="12">
          <Filter
            name="Tooltips"
            className="with_btn"
            onFilterRecords={filterRecords}
            onClearFilterHandler={clearFilterHandler}
            showButtons={true}
            buttons={<CreateModal onAddRecord={onAddRecord} />}
          >
            <TooltipFilters />
          </Filter>
        </Col>
        <Col sm="12">
          <Table
            className="tooltip_table"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={pagination}
            loading={isDataLoading}
            onChange={handleTableChange}
          ></Table>
        </Col>

        <Col sm="6" className="table_paginaton">
          <PaginatorDropdown
            onPageSizeChange={onPageSizeChange}
            pageSize={pagination.pageSize}
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  getTooltips,
  createTooltip,
  toggleFilter,
  deleteTooltip,
  updateTooltip,
})(Tooltip);
