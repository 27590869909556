import React from "react";

const SidebarProgressPoint = ({ point, index, step }) => {
  const { stepTitle } = point || {};
  return (
    <li className={"progress-point " + (index === step ? "active" : "")}>
      <span className={`point-decimal`}>{index}</span>
      {stepTitle}
    </li>
  );
};

export default SidebarProgressPoint;
