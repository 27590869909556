import axios from "../axios-instance";
import { getAdminBusinessId, getLoggedInUserId } from "../utils/authHelper";

import { fallBackErrorMessage } from "../constants/event";
import { objectToFormData } from "../utils/utility";

/**
 * create staff
 * @param {*} options
 * @returns
 */
export const inviteStaff = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("/admin/invite-staff", data)
    .then((res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * active/deactivate staff
 * @param {*} options
 * @returns
 */
export const toggleStaffStatus = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .post("/admin/toggle-staff-status", data)
    .then((res) => {
      const { data } = res;
      const { message, success } = data;
      if (onSuccess) {
        onSuccess(message, success);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(message);
      }
    });
};

/**
 * get event advanced page data
 * @param {*} options
 * @returns
 */
export const getStaffs = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post(`/admin/staffs/${getAdminBusinessId()}`, data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * soft delete staff
 * @param {*} options
 * @returns
 */
export const softDeleteStaff = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  axios
    .delete(`admin/staff/delete/${data.id}`)
    .then((res) => {
      const { data: resData } = res || {};
      const { data } = resData || {};
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;
      if (onError) {
        onError(error);
      }
    });
};

/**
 * create staff
 * @param {*} options
 * @returns
 */
export const saveStaff = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  const formData = objectToFormData(data);
  axios
    .post("/admin/staff/create", formData)
    .then((res) => {
      const { data: resData } = res || {};
      const { data, message } = resData || {};

      if (onSuccess) {
        const { data: event } = data || {};
        const { id } = event || {};
        onSuccess(message);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * get single staff
 * @param {*} options
 * @returns
 */
export const getSingleStaff = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .get(`/user-details/${data.id}`)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * check is staff username exist
 * @param {*} options
 * @returns
 */
export const isUsernameExist = (options) => async (dispatch) => {
  const { data } = options || {};
  return axios.get(
    `/admin/staff/is-username-exist/${data.username}/${
      data.id ? data.id : null
    }`
  );
};

/**
 * check is staff email exist
 * @param {*} options
 * @returns
 */
export const isEmailExist = (options) => async (dispatch) => {
  const { data } = options || {};
  return axios.get(
    `/admin/staff/is-email-exist/${data.email}/${data.id ? data.id : null}`
  );
};

/**
 * create staff
 * @param {*} options
 * @returns
 */
export const updateStaff = (options) => async (dispatch) => {
  const { onSuccess, onError, data } = options || {};
  const formData = objectToFormData(data);
  axios
    .post("/admin/staff/update", formData)
    .then((res) => {
      const { data: resData } = res || {};
      const { data, message } = resData || {};

      if (onSuccess) {
        const { data: event } = data || {};
        const { id } = event || {};
        onSuccess(message);
      }
    })
    .catch((error) => {
      const { data: errorData } = error.response;
      const message =
        errorData.message || error.message || fallBackErrorMessage;

      if (onError) {
        onError(message);
      }
    });
};

/**
 * get staff booking history
 * @param {*} options
 * @returns
 */
export const getStaffBookingHistory = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post(`/staff/booking-history`, data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * get booking history
 * @param {*} options
 * @returns
 */
export const getBookingHistory = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post(`/admin/booking-history/${getAdminBusinessId()}`, data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * get staff payments
 * @param {*} options
 * @returns
 */
export const getStaffPayment = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post("/staff/payments/" + getLoggedInUserId(), data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * get bookings
 * @param {*} options
 * @returns
 */
export const getStaffBookings = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post("/staff/bookings/" + getLoggedInUserId(), data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/*
export const getDirectoryInformation = (filter) => (dispatch) => {
  dispatch({
    type: DirectoryInformation_START,
  });
  axios
    .post("admin/staffs/" + getAdminBusinessId(), filter)
    .then((res) => {
      axios
        .get("company-settings-services")
        .then((countryRes) => {
          dispatch({
            type: DirectoryInformation_SUCCESS,
            payload: res,
            IsDataSubmitedSuccessfullyDirectoryInformation: false,
            countryDD: countryRes.data.data.countries,
            languageDD: countryRes.data.data.languages,
          });
        })
        .catch((err) =>
          dispatch({
            type: DirectoryInformation_ERROR,
            payload: null,
          })
        );
    })
    .catch((err) =>
      dispatch({
        type: DirectoryInformation_ERROR,
        payload: null,
      })
    );
};
*/

/*
export const updateDirectoryInformation =
  (DirectoryInformationData) => (dispatch) => {
    dispatch({
      type: DirectoryInformation_START,
    });
    axios
      .post("admin/staff/update", DirectoryInformationData)
      .then((res) => {
        dispatch({
          type: IsDataSubmitedSuccessfullyDirectoryInformation,
          IsDataSubmitedSuccessfullyDirectoryInformation: true,
        });
      })
      .catch((err) =>
        dispatch({
          type: DirectoryInformation_ERROR,
          payload: err.response.data.data,
        })
      );
  };

*/

/*
export const saveDirectoryInformation =
  (DirectoryInformationData) => (dispatch) => {
    dispatch({
      type: DirectoryInformation_START,
    });
    axios
      .post("admin/staff/create", DirectoryInformationData)
      .then((res) => {
        dispatch({
          type: IsDataSubmitedSuccessfullyDirectoryInformation,
          IsDataSubmitedSuccessfullyDirectoryInformation: true,
        });
      })
      .catch((err) =>
        dispatch({
          type: DirectoryInformation_ERROR,
          payload: err.response.data.data,
        })
      );
  };
*/

/*
export const deleteDirectoryInformation = (id) => (dispatch) => {
  dispatch({
    type: DirectoryInformation_START,
  });
  axios
    .post("admin/staff/delete", { id: id })
    .then((res) => {
      dispatch({
        type: IsDataSubmitedSuccessfullyDirectoryInformation,
        IsDataSubmitedSuccessfullyDirectoryInformation: true,
      });
    })
    .catch((err) =>
      dispatch({
        type: DirectoryInformation_ERROR,
        payload: err.response.data.data,
      })
    );
};
*/

/*
export const getSingleUserByIDDirectoryInformation = (id) => (dispatch) => {
  dispatch({
    type: DirectoryInformation_START,
  });
  axios
    .get("user-details/" + id)
    .then((res) => {
      axios
        .get("company-settings-services")
        .then((countryRes) => {
          dispatch({
            type: DirectoryInformation_SUCCESS,
            payload: res,
            IsDataSubmitedSuccessfullyDirectoryInformation: false,
            countryDD: countryRes.data.data.countries,
            languageDD: countryRes.data.data.languages,
          });
        })
        .catch((err) =>
          dispatch({
            type: DirectoryInformation_ERROR,
            payload: null,
          })
        );
    })
    .catch((err) =>
      dispatch({
        type: DirectoryInformation_ERROR,
        payload: err.response.data.data,
      })
    );
};
*/
