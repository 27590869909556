
const Linked = () => {
    return (
        <div className="link">
            <span>
                <svg
                    width="17"
                    height="17"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                >
                    <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.707 7.2929C4.09752 7.68343 4.09752 8.31659 3.707 8.70712L3.207 9.20712C2.21681 10.1973 2.21681 11.8027 3.207 12.7929C4.19718 13.7831 5.8026 13.7831 6.79278 12.7929L7.29278 12.2929C7.68331 11.9024 8.31647 11.9024 8.707 12.2929C9.09752 12.6834 9.09752 13.3166 8.707 13.7071L8.207 14.2071C6.43576 15.9784 3.56402 15.9784 1.79278 14.2071C0.0215446 12.4359 0.021548 9.56414 1.79278 7.7929L2.29278 7.2929C2.68331 6.90238 3.31647 6.90238 3.707 7.2929Z"
                    fill="#fe9920"
                    ></path>
                    <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.2928 8.70712C11.9023 8.31659 11.9023 7.68343 12.2928 7.2929L12.7928 6.79291C13.783 5.80272 13.783 4.19731 12.7928 3.20712C11.8026 2.21693 10.1972 2.21693 9.20699 3.20712L8.707 3.70712C8.31647 4.09764 7.68331 4.09764 7.29278 3.70712C6.90226 3.31659 6.90226 2.68343 7.29278 2.2929L7.79278 1.79291C9.56402 0.0216699 12.4358 0.021667 14.207 1.7929C15.9782 3.56414 15.9782 6.43588 14.207 8.20712L13.707 8.70712C13.3165 9.09764 12.6833 9.09764 12.2928 8.70712Z"
                    fill="#fe9920"
                    ></path>
                    <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.707 5.2929C11.0975 5.68343 11.0975 6.31659 10.707 6.70712L6.707 10.7071C6.31647 11.0976 5.68331 11.0976 5.29278 10.7071C4.90226 10.3166 4.90226 9.68343 5.29278 9.2929L9.29278 5.2929C9.68331 4.90238 10.3165 4.90238 10.707 5.2929Z"
                    fill="#fe9920"
                    ></path>
                </svg>
            </span>
        </div>
    );
}

export default Linked;