import React from 'react';
import StripeIcon from "../../../assets/icons/stripe.svg";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";

const Stripe = () => {
    return (
        <Col md="6" xxl="3" xl="4">
            <Link
            to="/integrations/google-meet"
            className="int-col"
            >
            <div className="img">
                <img src={StripeIcon} alt="Stripe Payment " />
            </div>
            <div className="text">
                <h3>Stripe</h3>
                <p>
                Lorem Ipsum is simply dummy text of the
                printing an typesetting industry.
                </p>
            </div>
            </Link>
        </Col>
    );
}

export default Stripe;