import moment from "moment";

/**
 * validate credit card number
 * @param string value card number
 * @returns boolean
 */
export const validateCardNumber = (value) => {
  if (!value) return false; // Make the field required
  let cardNumber = value.replace(/ /g, ""); // Remove spaces
  if (!/^[0-9]{13,16}$/.test(cardNumber)) return false; // Check length and format
  let sum = 0;
  for (let i = 0; i < cardNumber.length; i++) {
    let digit = parseInt(cardNumber[i]);
    if ((cardNumber.length - i) % 2 === 0) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }
    sum += digit;
  }
  return sum % 10 === 0; // Check Luhn algorithm
};

/**
 * validate credit card expiry
 * @param {*} value
 * @returns boolean
 */
export const validateCardExpiry = (value) => {
  if (!value) return false; // Make the field required
  let expiryDate = value.split("/"); // Split into month and year
  if (expiryDate.length !== 2) return false; // Check format
  let [month, year] = expiryDate;
  // To explain, .slice(-2) gives us the last two characters of the string
  month = ("0" + month).slice(-2);
  year = parseInt(year);
  if (!/^[0-9]{2}$/.test(month.toString())) return false; // Check month format
  if (!/^[0-9]{2}$/.test(year.toString())) return false; // Check year format
  month = parseInt(month);
  year = moment(year, "YY").format("YYYY");
  let today = new Date();
  let expiry = new Date(year, month - 1, 1); // Create a Date object for the expiry date
  expiry.setMonth(expiry.getMonth() + 1); // Add one month to get the last day of the expiry month
  expiry.setDate(expiry.getDate() - 1); // Subtract one day to get the last day of the expiry month
  return expiry >= today; // Check that the expiry date is in the future
};
