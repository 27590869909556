import React, { useState } from 'react';
import * as Icon from "react-feather";
import TimeLeftIcon from "../../assets/icons/time_left.svg";
import ClockIcon from "../../assets/icons/Clock.svg";
import CalendarIcon from '../../assets/icons/calendar.svg';
import GlobeIocn from "../../assets/icons/Globe.svg";
import VideoIocn from "../../assets/icons/video_icon.svg";
import visible from "../../assets/icons/visibility.svg";
import zapier from "../../assets/icons/Zapier logo.png";
import setlocation from "../../assets/images/setlocation.png";
import team from "../../assets/images/team.png";
import calender from "../../assets/images/calender.png"
import ArticleIocn from "../../assets/icons/article_icon.svg";
import Logo from "../../assets/images/logo-new.svg";
import GoToIcon from "../../assets/icons/goto_icon.svg";
import zoomIcon from "../../assets/icons/zoom.svg";
import outlookIcon from "../../assets/icons/outlook-icon.png";
import OctoIcon from "../../assets/icons/octo_icon.svg";
import teammeet from "../../assets/icons/team-meet.png";
import GoogleCalendarSVG from "../../assets/icons/google_calendar.svg";
import exchangeEmailIcon from "../../assets/icons/exchange-calendar.svg";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import mail from "../../assets/icons/email.jpeg";
import intIllustartion from "../../assets/images/int-illustration.jpg";

const CancelEvent = () => {
  const [modal, setModal] = useState(false);
  const [gotoModal, setGotoModal] = useState(false);
  const [GOTOModal, setGOTOModal] = useState(false);
  const [zoomModal, setzoomModal] = useState(false);
  const [zapierModal, setzapierModal] = useState(false);
  const [setlocationModal, setsetlocationModal] = useState(false);
  const [calenderModal, setcalenderModal] = useState(false);
  const [teamModal, setteamModal] = useState(false);
  const [zapierkeyModal, setzapierkeyModal] = useState(false);
  const [outlookModal, setoutlookModal] = useState(false);
  const [exchangeEmailModal, setexchangeEmailModal] = useState(false);
  const [upgradeProfessionalModal, setupgradeProfessionalModal] = useState(false);
  const gotoToggle = () => setGotoModal(!gotoModal);
  const GOTOToggle = () => setGOTOModal(!GOTOModal);
  const zoomToggle = () => setzoomModal(!zoomModal);
  const zapierToggle = () => setzapierModal(!zapierModal);
  const setlocationToggle = () => setsetlocationModal(!setlocationModal);
  const calenderToggle = () => setcalenderModal(!calenderModal);
  const teamToggle = () => setteamModal(!teamModal);
  const zapierkeyToggle = () => setzapierkeyModal(!zapierkeyModal);
  const toggle = () => setModal(!modal);
  const outlookToggle = () => setoutlookModal(!outlookModal);
  const exchangeEmailToggle = () => setexchangeEmailModal(!exchangeEmailModal);
  const upgradeProfessionalToggle = () => setupgradeProfessionalModal(!upgradeProfessionalModal);
  return (<>
    {/* Tooltips */}
    <div className="cust_tooltip">
      <div className="img">
        <img src="https://www.staging.thrive-booking.com/backend/public//uploads/events/event-1676283255.png" alt="Tooltip img" />
      </div>
      <div className="body">
        <h3>Reschedule</h3>
        <p>Lorem ipsum is simply dummy text of the printing.</p>
        <a href="/#"><img src={VideoIocn} alt="Video Icon" /> Watch Video</a>
        <a href="/#"><img src={ArticleIocn} alt="Article Icon" className='article' /> Read Article</a>
      </div>
    </div>
    <hr />
    <div className="cust_tooltip">
      <div className="body">
        <h3>Reschedule</h3>
        <p>Lorem ipsum is simply dummy text of the printing.</p>
      </div>
    </div>
    <hr />
    <div className="cust_tooltip title_only">
      <div className="body">
        <h3>Reschedule</h3>
      </div>
    </div>
    <hr />
    {/* Copy Code from here */}
    <div className="rd_vacationfilterpart justify-content-center cancel_eve">
      {/* <div className="col-lg-4 col-md-5"> */}
      <div className="confrim_wrapper">
        <div className="txt text-center">
          <img src={Logo} className='logo' alt="Brand Logo" />
          <h4>Mateen</h4>
          <h2>this is the link we're creating to test</h2>
          <div className="info">
            <p className="time_left"><img src={TimeLeftIcon} alt="Time Icon" /> <span>30 min</span></p>
            <p><img src={CalendarIcon} alt="Time Icon" /> <span>11:00am - 11:30am, Thursday, February 9, 2023</span></p>
            <p><img src={ClockIcon} alt="Time Icon" /> <span>Pakistan, Maldives Time</span></p>
          </div>
        </div>
        {/* </div>
            <div className="col-lg-8 col-md-7"> */}
        <div className="field">
          <h3>Cancel Event?</h3>
          <div className="form-group">
            <label htmlFor="cancelingReason">Reason for canceling</label>
            <textarea name="cancelingReason" id="" className="form-control"></textarea>
            <div className="text-center">
              <button className="btn rd_cancel mt-4">Cancel Event</button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
    {/* Copy Code from here */}
    <div className="rd_vacationfilterpart cancel_eve">
      <div className="col-lg-4 col-md-5">
        <div className="txt">
          <h4>Mateen</h4>
          <h2>this is the link we're creating to test</h2>
          <div className="info">
            <p className="time_left"><img src={TimeLeftIcon} alt="Time Icon" /> <span>30 min</span></p>
            <p><img src={CalendarIcon} alt="Time Icon" /> <span>11:00am - 11:30am, Thursday, February 9, 2023</span></p>
            <p><img src={ClockIcon} alt="Time Icon" /> <span>Pakistan, Maldives Time</span></p>
          </div>
        </div>
      </div>
      <div className="col-lg-8 col-md-7">
        <div className="txt">
          <h4 className="fw-normal">Reschedule Event</h4>
          <h2 className="mt-3 mb-4">Select a Date & Time</h2>
          {/* Calendar goes here */}
          <div className="calendar_area my-4">
            <h2>Calendar Component goes Here</h2>
          </div>
          <div className="time_zone">
            <h4>Time Zone</h4>
            <div className="cus_timezone my-3">
              <img src={GlobeIocn} alt="Globe Icon" />
              <h4>Timezone Component goes Here</h4>
              {/* <select
                            value={timezoneId}
                            onChange={(event) => {
                                _this.setState({
                                timezoneId: event.target.value,
                                });
                                onSelectTimzone(
                                event.target.value,
                                event.target.selectedOptions[0].text
                                );
                            }}
                            >
                            <option value="">Select Timezone</option>
                            {(timezones || []).map((timezone, index) => {
                                return (
                                <option key={index} value={timezone.value}>
                                    {timezone.timezone_value}
                                </option>
                                );
                            })}
                            </select> */}
            </div>
            <a href="#" className="switcher">Switch to your invitee's time zone</a>
          </div>
          <button className="btn btn-outline troubleshoot"><Icon.Tool size={20} /> Troubleshoot</button>
        </div>
      </div>
    </div>
    {/* Copy Code from here */}
    <div className="rd_vacationfilterpart justify-content-center cancel_eve">
      <div className="confrim_wrapper text-center">
        <div className="txt p-0">
          <h4>Mateen</h4>
          <h2 className="mb-2 pb-1">Cancellation Confirmed</h2>
          <p>Your meeting with Reda has been cancelled</p>
        </div>
        <div className="info">
          <h2 className="eve_name mt-0"><span></span> this is the link we're creating to test</h2>
          <p className="text-decoration-line-through"><img src={CalendarIcon} alt="Time Icon" /> <span>11:00am - 11:30am, Thursday, February 9, 2023</span></p>
          <p className="text-decoration-line-through"><img src={ClockIcon} alt="Time Icon" /> <span>Pakistan, Maldives Time</span></p>
        </div>
        <div className="foot">
          <h2 className="mb-4">Simple. Automated. Scheduling.</h2>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Username" value="mateen@gmail.com" aria-label="Username" aria-describedby="quickSignup" />
            <button class="btn rd_cancel" type="button" id="quickSignup">Sign up free</button>
          </div>
          <p className='text-left mt-2'>No credit card required.</p>
        </div>
      </div>
    </div>
    {/* Copy Code from here */}
    <div className="rd_vacationfilterpart justify-content-center cancel_eve pt-3 pb-5">
      <div className="noavail_wrapper">
        <div className="txt text-center">
          <img src={Logo} className='logo' alt="Brand Logo" />
          <h4>Mateen</h4>
          <h2>This invitation is longer valid</h2>
          <div className="mt-5">
            <p className="mb-4 text-dark">You are logged in as mateenribbashdev@gmail.com This message is only visible to you</p>
            <p className="text-dark"><strong>Someone has already scheduled a time with you via this invitation. </strong></p>
          </div>
        </div>
      </div>
    </div>
    {/* Copy Code from here */}
    <button className="btn btn-outline brand" onClick={toggle}>Event Share Modal</button>
    <Modal className="new-share-modal" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <h2 className='fw-bold'>Meeting is ready to share!</h2>
        <p className='my-4'>Paste this link in a message to invite them to pick time</p>
        <div className="col-md-12">
          <div class="input-group">
            <input type="text" class="form-control col-md-8" placeholder="https//meetocto.com/group-conference" value="" aria-label="Username" aria-describedby="quickSignup" />
            <button class="btn rd_cancel col-md-4" type="button" id="quickSignup">Copy link</button>
          </div>
        </div>
        <div class="divider">
          <div class="divider-text">
            <p>or</p>
          </div>
        </div>

        <div className="txt text-center">
          <img src={mail} class='mb-2' alt="Brand Logo" />
          <h3 class='my-2'><strong>Add times to email</strong></h3>
          <p>Paste times directly into an email to share</p>
        </div>


      </ModalBody>
      <ModalFooter className="justify-content-center mb-4 border-top-0 pt-4">
        <div className="col-6">
          <button className="btn btn-outline brand" onClick={toggle}>Copy Times</button>
        </div>
      </ModalFooter>
    </Modal>
    {/* Copy Code from here */}
    <div className="row my-4">
      <div className="col-auto">
        <button className="btn btn-primary w-auto" onClick={gotoToggle}>
          Goto Modal
        </button>
        <Modal className="cale_con_modal width_mod" isOpen={gotoModal} toggle={gotoToggle} centered={true}>
          <ModalBody>
            <div className="head">
              <div className="img">
                <img src={OctoIcon} alt="Octo Icon" />
              </div>
              <hr className="my-0" />
              <Icon.Plus size={20} color="#0C4767" strokeWidth={2} />
              <hr className="my-0" />
              <div className="img ex">
                <img src={GoToIcon} alt="GoToIcon Icon" />
              </div>
            </div>
            <div className="desc featuergoto">
              <h3 className="my-3">Features that save you time:</h3>
              <ul>
                <li>Automatically create Zoom Meeting meeting at the time an event is scheduled.</li>
                <li>Instantly share unique conferencing details upon confirmation</li>
              </ul>
              <h3 className="my-3">Supports any GoTo Meeting account</h3>
              <ul>
                <li>Web conferencing url on all GoTo Meeting accounts.</li>
                <li>Call-in instructions on Paid GoTo Meeting accounts.</li>
              </ul>
              <button className="btn btn-app mt-3" onClick={gotoToggle}>Connect GoTo Meeting</button>
            </div>

          </ModalBody>
        </Modal>
      </div>

      <div className="col-auto">
        <button className="btn btn-primary w-auto" onClick={zoomToggle}>
          Zoom Modal
        </button>
        <Modal className="cale_con_modal width_mod" isOpen={zoomModal} toggle={zoomToggle} centered={true}>
          <ModalBody>
            <div className="head">
              <div className="img">
                <img src={OctoIcon} alt="Octo Icon" />
              </div>
              <hr className="my-0" />
              <Icon.Plus size={20} color="#0C4767" strokeWidth={2} />
              <hr className="my-0" />
              <div className="img ex">
                <img src={zoomIcon} alt="ZoomIcon Icon" />
              </div>
            </div>
            <div className="desc featuergoto">
              <h3 className="my-3">Features that save you time:</h3>
              <ul>
                <li>Automatically create Zoom Meeting meeting at the time an event is scheduled.</li>
                <li>Instantly share unique conferencing details upon confirmation</li>
              </ul>
              <h3 className="my-3">Supports any GoTo Meeting account</h3>
              <ul>
                <li>Web conferencing url on all GoTo Meeting accounts.</li>
                <li>Call-in instructions on Paid GoTo Meeting accounts.</li>
              </ul>
              <button className="btn btn-app mt-3" onClick={zoomToggle}>Connect GoTo Meeting</button>
            </div>

          </ModalBody>
        </Modal>
      </div>

      <div className="col-auto">
        <button className="btn btn-primary w-auto" onClick={zapierToggle}>
          Zapier Modal
        </button>
        <Modal className="cale_con_modal width_mod" isOpen={zapierModal} toggle={zapierToggle} centered={true}>
          <ModalBody>
            <div className="head">
              <div className="img">
                <img src={OctoIcon} alt="Octo Icon" />
              </div>
              <hr className="my-0" />
              <Icon.Plus size={20} color="#0C4767" strokeWidth={2} />
              <hr className="my-0" />
              <div className="img ex">
                <img src={zapier} alt="zapier Icon" />
              </div>
            </div>
            <div className="desc featuergoto">
              <p>
                Zapier allow you to connect web app without any development work through automation called zaps.
              </p>
              <p className="my-3">Lorem Ipsum is simpaly dummy text of the printing and typesetting industry standard dummy text ever since the 1500s,</p>
              <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. it has survived noy only five
                centueries, but also leap into electronic typesetting, remaining essentially unchange.
              </p>
              <button className="btn btn-app mt-4" onClick={zapierToggle}> Go to Setup</button>
            </div>

          </ModalBody>
        </Modal>
      </div>

      <div className="col-auto">
        <button className="btn btn-primary w-auto" onClick={GOTOToggle}>
          GOTO
        </button>
        <Modal className="cale_con_modal GOTO_mod width_mod" isOpen={GOTOModal} toggle={GOTOToggle} centered={true}>
          <ModalBody>
            <div className="head">
              <div className="img">
                <img src={GoToIcon} alt="GoToIcon Icon" />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 mt-4">
                <div className="form-group">
                  <label htmlFor="exchangeEmail" className="form-label">Enter an email address</label>
                  <input type="email" name="exchangeEmail" id="exchangeEmail" className="form-control" placeholder="johndoe@yahoo.com" />
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <button className="btn btn-outline" onClick={gotoToggle}>Cancel</button>
              </div>
              <div className="col-md-12 mt-4">
                <button className="btn btn-app">Next</button>
              </div>
              <div className="row justify-content-between ms-2">
                <div className="col-auto mt-4">
                  <div className="form-group">
                    <input type="checkbox" value="" className="form-check-input" />
                    <label class="form-check-label" for="">
                      Keep logged in
                    </label>
                  </div>
                </div>
                <div className="col-auto mt-4">
                  <div className="text">
                    <p>Account Problems</p>
                  </div>
                </div>
              </div>

            </div>


          </ModalBody>
        </Modal>
      </div>


      <div className="col-auto">
        <button className="btn btn-primary w-auto" onClick={setlocationToggle}>
          set up meeting location
        </button>
        <Modal className="cale_con_modal location_mod" isOpen={setlocationModal} toggle={setlocationToggle} centered={true}>
          <ModalBody>
            <h3 className="text-center mb-4">Go to Meeting Setup as the location</h3>
            <div className="set-location">
              <div className="row">
                <div className="col-md-6">
                  <ul>
                    <li>Lorem Ipsum is simpaly dummy text of the printing and typesetting industry standard dummy text ever since the 1500s,</li>
                    <li>Lorem Ipsum is simpaly dummy text of the printing and typesetting industry standard dummy text ever since the 1500s,</li>
                    <li>Lorem Ipsum is simpaly dummy text of the printing and typesetting industry standard dummy text ever since the 1500s,</li>
                  </ul>
                </div>
                <div className="col-md-6 mt-md-0 mt-3">
                  <img src={setlocation} alt="" />
                </div>
                <div className="col-md-6 mt-4">
                  <button className="btn btn-outline" onClick={setlocationToggle}>Manage Integrations</button>
                </div>
                <div className="col-md-6 mt-4">
                  <button className="btn btn-app">Manage Integrations</button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>

      <div className="col-auto">
        <button className="btn btn-primary w-auto" onClick={calenderToggle}>
          Calender
        </button>
        <Modal className="cale_con_modal calender_mod" isOpen={calenderModal} toggle={calenderToggle} centered={true}>
          <ModalBody>
            <div className="head">
              <div className="img">
                <img src={OctoIcon} alt="Octo Icon" />
              </div>
              <hr className="my-0" />
              <Icon.Plus size={20} color="#0C4767" strokeWidth={2} />
              <hr className="my-0" />
              <div className="img ex">
                <img src={GoogleCalendarSVG} alt="GoogleCalendar Icon" />
              </div>
            </div>
            <div className="calender">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <p>Lorem Ipsum is simpaly dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a type specimen book.
                  </p>
                </div>
                <div className="col-md-8 mt-3">
                  <p>it has survived five centueries and the leap into electronic type setting, remaining essentially unchanged. it was popularised
                    in the 1960's with the release of Letraset sheets containing Lorem Ipsum passegesand more recentely with desktop publising  software like
                    Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>
                <div className="col-md-4 mt-3">
                  <img src={calender} alt="" />
                </div>
                <div className="col-md-12 mt-3">
                  <p>Lorem Ipsum is simpaly dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a type specimen book.it has survived five centueries, but also the leap
                    into electronics typesetting, remaining essentially unchange. </p>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>

      <div className="col-auto">
        <button className="btn btn-primary w-auto" onClick={teamToggle}>
          Team
        </button>
        <Modal className="cale_con_modal team_mod" isOpen={teamModal} toggle={teamToggle} centered={true}>
          <ModalBody>
            <div className="head">
              <div className="img">
                <img src={OctoIcon} alt="Octo Icon" />
              </div>
              <hr className="my-0" />
              <Icon.Plus size={20} color="#0C4767" strokeWidth={2} />
              <hr className="my-0" />
              <div className="img ex">
                <img src={teammeet} alt="MicrosoftOffice Icon" />
              </div>
            </div>
            <div className="team">
              <div className="row">
                <div className="col-md-7 mt-3">
                  <p>Lorem Ipsum is simpaly dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a type specimen book.
                  </p>
                  <p>it has survived five centueries and the leap into electronic type setting, remaining essentially unchanged. it was popularised
                    in the 1960's with the release of Letraset sheets containing Lorem Ipsum passegesand more recentely with desktop publising  software like
                    Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>
                <div className="col-md-5 mt-3">
                  <img src={team} alt="" />
                </div>
                <div className="col-md-12 mt-3">
                  <p>Lorem Ipsum is simpaly dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a type specimen book.it has survived five centueries, but also the leap
                    into electronics typesetting, remaining essentially unchange. </p>
                </div>
              </div>
            </div>
            <div className="tittle">
              <h3 className='desc'>Your current subscription is Essentials</h3>
            </div>
            <div className="subscrip">
              <div className="row justify-content-between align-items-center">
                <div className="col-md-8 mt-3 mt-md-0">
                  <p className="desc mt-0 mb-2">For This integration, you'll also need:</p>
                  <p class="fw-normal">Lorem Ipsum is simpaly dummy text of the printing and typesetting industry.</p>
                </div>
                <div className="col-md-4 mt-3 mt-md-0">
                  <button className="btn btn-outline brand w-100">
                    Integrate Now
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>

      <div className="col-auto">
        <button className="btn btn-primary w-auto" onClick={zapierkeyToggle}>
          zapierkey
        </button>
        <Modal className="cale_con_modal width_mod" isOpen={zapierkeyModal} toggle={zapierkeyToggle} centered={true}>
          <ModalBody>
            <div className="head">
              <div className="row justify-content-center">
                <div className="col-md-4 col-4">
                  <div className="Zapir-img">
                    <img src={zapier} alt="zapier Icon" />
                  </div>
                </div>
                <div className="col-md-8 mt-md-0 mt-4">
                  <div className="zapier-head">
                    <p>Setting up your zapir integration</p>
                    <label htmlFor="exchangeEmail" className="form-label">Enter an email address</label>
                    <div class="password-group">
                      <input type="password" name="exchangeEmail" id="exchangeEmail" className="form-control" placeholder="****************" />
                      <img src={visible} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="desc featuergoto">
              <button className="btn btn-app my-3" onClick={zapierkeyToggle}> Copy to Clipboard</button>
              <ul>
                <li>Lorem Ipsum is simpaly dummy text of the printing and typesetting industry.</li>
                <li>Lorem Ipsum is simpaly dummy text of the printing and typesetting industry.</li>
                <li>Lorem Ipsum is simpaly dummy text of the printing and typesetting industry.</li>
                <li>Lorem Ipsum is simpaly dummy text of the printing and typesetting industry.</li>
              </ul>
            </div>

          </ModalBody>
        </Modal>
      </div>


      <div className="col-auto">
        <button className="btn btn-primary w-auto" onClick={outlookToggle}>
          outlook
        </button>
        <Modal className="cale_con_modal width_mod" isOpen={outlookModal} toggle={outlookToggle} centered={true}>
          <ModalBody>
            <div className="head">
              <div className="img">
                <img src={OctoIcon} alt="Octo Icon" />
              </div>
              <hr className="my-0" />
              <Icon.Plus size={20} color="#0C4767" strokeWidth={2} />
              <hr className="my-0" />
              <div className="img  exchange-icon">
                <img src={outlookIcon} alt="outlook Icon" />
              </div>
            </div>
            <div className="desc featuergoto">
              <h3 className='text-center'>Download the Outlook Plug-in</h3>
              <p className="my-3">The Calendly Outlook plug-in runs in the background to connect to your Outlook calendar and keep
                track of your scheduled events</p>
              <button className="btn btn-app my-2">Download</button>
              <p>I have already got the plug-in, <span className='desc'>What is next?</span></p>
            </div>

          </ModalBody>
        </Modal>
      </div>


      <div className="col-auto">
        <button className="btn btn-primary w-auto" onClick={exchangeEmailToggle}>
          Exchange Email
        </button>
        <Modal className="cale_con_modal width_mod" isOpen={exchangeEmailModal} toggle={exchangeEmailToggle} centered={true}>
          <ModalBody>
            <div className="head">
              <div className="img">
                <img src={OctoIcon} alt="Octo Icon" />
              </div>
              <hr className="my-0" />
              <Icon.Plus size={20} color="#0C4767" strokeWidth={2} />
              <hr className="my-0" />
              <div className="img ex">
                <img src={exchangeEmailIcon} alt="exchangeEmail Icon" />
              </div>
            </div>
            <div className="desc featuergoto exchangemail">
              <h3 className='text-center my-3'>Enter your Exchange email addressand password to connect your calender. </h3>
              <div className="row">
                <div className="col-12 mt-3">
                  <label htmlFor="" className="form-label">Exchange Email Address</label>
                  <input type="text" className="form-control" placeholder='johndoe@yourcompany.com' />
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="" className="form-label">Exchange password</label>
                  <input type="password" className="form-control" placeholder='**************' disabled />
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="" className="form-label">Exchange server</label>
                  <input type="text" className="form-control" placeholder='optional' />
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="" className="form-label">Domain</label>
                  <input type="text" className="form-control" placeholder='optional' />
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="" className="form-label">Username</label>
                  <input type="text" className="form-control" placeholder='optional' />
                </div>
              </div>
              <button className="btn btn-app mt-3">Connect</button>

            </div>

          </ModalBody>
        </Modal>
      </div>


      <div className="col-auto">
        <button className="btn btn-primary w-auto" onClick={upgradeProfessionalToggle}>
          Upgrade Professional
        </button>
        <Modal className="cale_con_modal team_mod upgrade-prof" isOpen={upgradeProfessionalModal} toggle={upgradeProfessionalToggle} centered={true}>
            <img src={intIllustartion} alt="" />
          <ModalBody>
            <div className="upgrade-professional">
              <h3 className='mb-3'>Upgrade to Professional to connect to Zapier to  trigger actions and more!</h3>
              <ul>
                <li>Co-host meetings with multiple team members using collective events</li>
                <li>Collect payments from invitees using Stripe and Paypal</li>
                <li>Automate text and email confirmations using workflows</li>
              </ul>
              <div className="row">
                <div className="col-md-6 mt-4">
                  <button className="btn btn-outline" onClick={setlocationToggle}>Compare All Plans</button>
                </div>
                <div className="col-md-6 mt-4">
                  <button className="btn btn-app">Upgrade to Professional</button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>

    </div>

    {/* Copy Code from here */}
    <div className="zoom-feature-int">
      <div className='row'>
        <div className="col-md-5">
          <div className="txt">
            <h3>Featuer that save your time:</h3>
            <div className="zoom-info my-3">
              <ul>
                <li>Automatically create Zoom Meeting meeting at the time an event is scheduled.</li>
                <li>Instantly share unique conferencing details upon confirmation</li>
              </ul>
            </div>
            <h3>Requierments</h3>
            <div className="zoom-info my-3">
              <ul>
                <li>A Zoom account</li>
                <li>Your Zoom account administrator must <span>pre-approve calendly in the Zoom Marketplace</span> </li>
              </ul>
            </div>
            <div className="button">
              <button className="btn btn-app w-auto">
                connect zoom
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-7 mt-md-0 mt-4">
          <div className="meet-info">
            <div className="img border-end pe-3">
              <img src={OctoIcon} alt="Octo Icon" />
            </div>
            <div className="img zoom-icon ms-4">
              <img src={zoomIcon} alt="zoom Icon" />
            </div>
          </div>
        </div>
      </div>
    </div>



    {/* Copy Code from here */}
    <div className="zoom-feature-int my-5">
      <div className='row'>
        <div className="col-md-5">
          <div className="txt">
            <h3>Featuer that save your time:</h3>
            <div className="zoom-info my-3">
              <ul>
                <li>Automatically create Zoom Meeting meeting at the time an event is scheduled.</li>
                <li>Instantly share unique conferencing details upon confirmation</li>
              </ul>
            </div>
            <h3>Supports any GoTo Meeting Account</h3>
            <div className="zoom-info my-3">
              <ul>
                <li>Web conferencing url on all GoTo Meeting accounts</li>
                <li>Call-in instructions on paid  GoTo Meeting accounts</li>
              </ul>
            </div>
            <div className="button">
              <button className="btn btn-app w-auto">
                connect GoTo Meeting
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-7 mt-md-0 mt-4">
          <div className="meet-info goto-meet">
            <div className="img me-4">
              <img src={OctoIcon} alt="Octo Icon" />
            </div>
            <Icon.Plus size={20} color="#0C4767" strokeWidth={2} />
            <div className="img ms-4">
              <img src={GoToIcon} alt="GOTO Icon" />
            </div>
          </div>
        </div>
      </div>
    </div>


     {/* Copy Code from here */}
     <div className=" section.integreted-calenders ">
      <div className='row'>
        <div className="col-md-6">
            <div className="row">
              <div className="col-md-5 d-flex justify-content-space-between align-items-center">
                  <div className="img zoom-icon me-4">
                      <img src={GoToIcon} alt="GoTo" />
                  </div>
                  <div class="calender-name">
                        <p>Connected by</p>
                        <p class="status">
                          <span class="ml-0">dummymail&mail.com</span>
                        </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <button class="btn btn-outline brand ">Disconnect</button>
                </div>
            </div>     
        </div>
        <div className="col-md-6">
        <div className="row">
            <div className="col-md-5 d-flex justify-content-space-between align-items-center">
                  <div className="img zoom-icon me-4">
                      <img src={GoToIcon} alt="GoTo" />
                  </div>
                  <div class="calender-name">
                        <p>Connected by</p>
                        <p class="status">
                          <span class="ml-0">dummymail&mail.com</span>
                        </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <button class="btn btn-outline brand ">Disconnect</button>
                </div>
            </div>
        </div>
      </div>
    </div>
  </>);
}

export default CancelEvent;