import React, { useState, useEffect } from "react";

import GoogleMeetIcon from "../../../assets/icons/google-meet.svg";
import OctoIcon from "../../../assets/icons/octo_icon.svg";
import { useNavigate } from "react-router-dom";
import {  getAdminBusinessId } from "../../../utils/authHelper";
import { BACKEND_API_BASE_URL } from "../../../utils/utility";
import Loader from "../../../components/Loader/Loader";
import ConfirmModal from "../../../components/Modals/Confirm";
import { sendGetRequest, sendPostRequest } from "../../../actions/app-actions";
import { GOOGLE_MEET_APP_ID } from "../../../data/integration-app";
import { connect } from "react-redux";

const GoogleMeetIntegration = (props) => {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    const { sendGetRequest } = props;
    setIsLoading(true);
    sendGetRequest({
      url: `admin/company-venue-integrations/${getAdminBusinessId()}`,
      onSuccess: function (response) {
        const found = response.integrations.some((integration) => integration.app_d === GOOGLE_MEET_APP_ID);
        setIsConnected(found);
        setIsLoading(false);
      },
      onError: function (error) {
        setIsLoading(false);
      },
    });
  }, []);

  /**
   * 
   */
  const disconnectMeet = (data) => {
    const { sendPostRequest } = props;
    setIsLoading(true);
    sendPostRequest({
      url: `disconnect-google-meet/${getAdminBusinessId()}`,
      data:{
        app_id: GOOGLE_MEET_APP_ID
      },
      onSuccess: function (response) {
        console.log('response', response.integrations);
        setIsConnected(false);
        setIsLoading(false);
      },
      onError: function (error) {
        setIsLoading(false);
      },
    });
  }

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          data={{}}
          title="Confirm"
          message="Are you sure, you want to Disconnect from Google Meet ?"
          onConfirm={disconnectMeet}
          onToggle={() => setShowConfirmModal(false)}
        />
      )}
      {isLoading ? (
        <Loader isShowLoader={isLoading} />
      ) : (
        <>
          {
            !isConnected ? 
            (
              <div className="zoom-feature-int">
                <div className="row">
                  <div className="col-md-5">
                    <div className="txt">
                      <h3>Features that save your time:</h3>
                      <div className="zoom-info my-3">
                        <ul>
                          <li>
                            Automatically create Google Meet at the time an event
                            is scheduled.
                          </li>
                          <li>
                            Instantly share unique conferencing details upon confirmation
                          </li>
                        </ul>
                      </div>
                      <h3>Requierments</h3>
                      <div className="zoom-info my-3">
                        <ul>
                          <li>A Google account</li>
                          <li>
                            Your Zoom account administrator must
                            <span> pre-approve calendly in the Zoom Marketplace</span>
                          </li>
                        </ul>
                      </div>
                      <div className="button">
                        <a
                          className="btn btn-app w-auto"
                          href={`${BACKEND_API_BASE_URL}google-meet-auth/${getAdminBusinessId()}`}
                        >
                          Connect Google Meet
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 mt-md-0 mt-4">
                    <div className="meet-info">
                      <div className="img border-end pe-3">
                        <img src={OctoIcon} alt="Meetocto" />
                      </div>
                      <div className="img zoom-icon ms-4">
                        <img src={GoogleMeetIcon} alt="Google Meet" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className=" section.integreted-calenders ">
                <div className='row'>
                  <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-5 d-flex justify-content-space-between align-items-center">
                            <div className="img zoom-icon me-4">
                                <img src={GoogleMeetIcon} alt="Google Meet" />
                            </div>
                            <div class="calender-name">
                                  <p>Connected by</p>
                                  <p class="status">
                                    <span class="ml-0">dummymail&mail.com</span>
                                  </p>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <button class="btn btn-outline brand" onClick={() => { setShowConfirmModal(true);}}>Disconnect</button>
                          </div>
                      </div>     
                  </div>
                </div>
              </div>
            )
          }
        </>
      )}
    </>
    
  );
};

export default connect(null, { sendGetRequest, sendPostRequest })(
  GoogleMeetIntegration
);