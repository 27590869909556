import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import Calendar from "react-calendar";
import {
  getAvailableTimeSlots,
  getAvailableDates,
} from "../../../../actions/bookingAction";
import { connect } from "react-redux";
import { getAdminBusinessId, getLoggedInUserId } from "../../../../utils/authHelper";
import {
  notification,
  getDBFormatedDate,
  getLoggedInUserTimezone,
} from "../../../../utils/utility";
import Loader from "../../../../components/Loader/Loader";
import { rescheduleEvent } from "../../../../actions/admin/scheduled-event-actions";
import moment from "moment";

const UpcomingRescheduleModal = (props) => {
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slots, setSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);

  const [bookingDate, setBookingDate] = useState(null);

  useEffect(() => {
    getMonthAvailableDates(moment().month() + 1, moment().year());
  }, []);

  const /**
     * Get dates of month
     * @param int month
     * @param int year
     * @returns array
     */
    getMonthAvailableDates = (month, year) => {
      const _this = this;
      const { getAvailableDates } = props;

      setIsLoading(true);
      console.log("month", month, "year", year);
      let daysInMonth = moment(year + "-" + month, "YYYY-M").daysInMonth();
      console.log("days in month, ", daysInMonth);
      let monthDates = [];
      while (daysInMonth) {
        monthDates.push(
          moment(year + "-" + month + "-" + daysInMonth, "YYYY-M-D").format(
            "YYYY-M-D"
          )
        );
        daysInMonth--;
      }

      const monthStartDate = moment(year + "-" + month + "-" + 1, "YYYY-M-D").format(
        "YYYY-M-D"
      ); 
      const monthEndDate = moment(year + "-" + month + "-" + daysInMonth, "YYYY-M-D").format(
        "YYYY-M-D"
      );

      getAvailableDates({
        data: {
          dates: monthDates.reverse(),
          event_id: props.data.event_id,
          timezone: getLoggedInUserTimezone(),
          business_id: getAdminBusinessId(),
          month_start_date: monthStartDate, 
          month_end_date: monthEndDate,
          staff_id : getLoggedInUserId(),
        },
        onSuccess: function (result) {
          const { data } = result.data;
          setAvailableDates(data);
          setIsLoading(false);
        },
        onError: function (error) {
          setIsLoading(false);
        },
      });
    };

  /**
   * on calendar date change handler
   * @param {*} date
   */
  const onCalendarDateChange = (date) => {
    const { getAvailableTimeSlots } = props;
    setCalendarDate(date);
    setBookingDate(getDBFormatedDate(new Date(date)));
    setIsLoading(true);
    getAvailableTimeSlots({
      data: {
        selected_date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
        staff_id: 1,
        business_id: getAdminBusinessId(),
        event_id: props.data.event_id,
        timezone: getLoggedInUserTimezone()
      },
      onSuccess: function (response) {
        console.log("response", response);
        setIsLoading(false);
        setSlots(response);
      },
      onError: (error) => {
        setIsLoading(false);
        notification.error(error.response.data.message);
      },
    });
  };

  /**
   * on select time slot
   * @param {*} timeslot
   */
  const onSelectTimeSlot = (timeslot) => {
    setSelectedSlot(timeslot);
  };

  /**
   * on confirm reschedule
   */
  const onConfirm = () => {
    const {
      data,
      rescheduleEvent,
      handleClose,
      handleViewDetailClose,
      loadUpcomingEvents,
    } = props;
    const bookingDateTime = bookingDate + " " + selectedSlot;
    let endDate = new Date(bookingDateTime);
    if (!isNaN(data.timeslot)) {
      endDate.setTime(endDate.getTime() + data.timeslot * 60 * 1000);
    }

    const endSlot =
      ("0" + endDate.getHours()).slice(-2) +
      ":" +
      ("0" + endDate.getMinutes()).slice(-2);

    setIsLoading(true);
    rescheduleEvent({
      data: {
        booking_id: data.id,
        booking_date_time: bookingDateTime,
        start_time: selectedSlot + ":00",
        end_time: endSlot + ":00",
        reschedule_reason: "",
        timezone: getLoggedInUserTimezone(),
      },
      onSuccess: function (response) {
        console.log(response);
        setIsLoading(false);
        notification.success("Reschedule Successfully..");
        handleClose();
        handleViewDetailClose();
        loadUpcomingEvents();
      },
      onError: function (error) {
        console.error(error);
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Modal
        returnFocusAfterClose={true}
        isOpen={props.showRescheduleModal}
        toggle={props.handleClose}
        className="event-modal modal-lg modal-dialog reschedule_popup"
      >
        <ModalBody>
          <div className="">
            <div className="box_content">
              <div className="rd_inputselectheadermodalsetting">
                <button
                  className="rd_colsebtn"
                  onClick={props.handleClose}
                ></button>
              </div>

              <h5 className="rd_modalheadertext rd_modalheadertextnm mb-4">
                Reschedule
              </h5>

              <div className="row sub_div">
                <div className="col-sm-6 col-lg-6 col-md-6 mb-5 mb-md-0">
                  <input
                    type="text"
                    className="form-control rs-select-date-input"
                    id="result"
                    placeholder="Select date"
                    disabled="disabled"
                  />
                  <form className="row">
                    <div className="col-md-12">
                      <Calendar
                        tileClassName={({ date }) => {
                          const calendarDate =
                            date.getFullYear() +
                            "-" +
                            (date.getMonth() + 1) +
                            "-" +
                            date.getDate();

                          if (
                            availableDates.find(
                              (availableDate) => availableDate === calendarDate
                            )
                          ) {
                            return "highlight";
                          }
                        }}
                        onActiveStartDateChange={({ activeStartDate }) => {
                          const dated = moment(activeStartDate);
                          const month = dated.format("M");
                          const year = dated.format("YYYY");
                          console.log("month", month);
                          getMonthAvailableDates(month, year);
                        }}
                        minDate={new Date()}
                        onChange={onCalendarDateChange}
                        value={calendarDate}
                        locale="en-GB"
                      />
                    </div>
                  </form>
                </div>
                {(slots || []).length > 0 ? (
                  <>
                    <div className="col-sm-6 col-lg-6 col-md-6">
                      <input
                        type="text"
                        className="form-control rs-select-date-input"
                        placeholder="Select Time"
                        disabled="disabled"
                      />
                      <div className="col-12 px-0">
                        <div className="row main_time_div rs-timeslot-container">
                          {(slots || []).map((item, index) => {
                            if (item.status === 'NA') {
                              return null;
                            } else {
                              return (
                                <div
                                  key={index}
                                  className={`col-5 main_time rs-timeslot ${
                                    selectedSlot == item.slot ? "time_active" : ""
                                  }`}
                                  onClick={() => onSelectTimeSlot(item.slot)}
                                >
                                  {item.slot}
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>

                      <div className="col-12">
                        {selectedSlot ? (
                          <button
                            type="button"
                            className="btn btn-app"
                            id="cnfrm_btn"
                            onClick={onConfirm}
                          >
                            {`Confirm ${selectedSlot}`}
                          </button>
                        ) : (
                          <> </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-sm-6 col-lg-6 col-md-6">
                    <Loader isShowLoader={isLoading} />
                    <input
                      type="text"
                      className="form-control rs-select-date-input"
                      placeholder="Select Time"
                      disabled="disabled"
                    />
                    <div className="col-12 px-0">
                      <div className="row main_time_div">
                        <p className="text-left">No Slot Available.</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, {
  getAvailableTimeSlots,
  rescheduleEvent,
  getAvailableDates,
})(UpcomingRescheduleModal);
