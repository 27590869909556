import React, { useState, useEffect } from "react";
import { Table } from "antd";
import {
  CardBody,
  Card,
  Input,
  Col,
  CardHeader,
  Row,
  CardTitle,
  FormGroup,
  Label,
  Collapse,
} from "reactstrap";
import Loader from "../../../../components/Loader/Loader";
import isEmpty from "../../../../validation/is-empty";
import { ChevronDown, X } from "react-feather";
import classnames from "classnames";
import "antd/dist/antd.css";
import { getBookingHistory } from "../../../../actions/staffActions";
import { connect } from "react-redux";
import {
  capitalizeFirstLetter,
  getDBFormatedDate,
} from "../../../../utils/utility";
import DatePicker from "react-date-picker";

const BookingHistory = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    showSizeChanger: false,
  });

  const [filter, setFilter] = useState({});

  const [data, setData] = useState([]);
  const [collapse, setCollapse] = useState(false);
  const [status, setStatus] = useState("");
  const [isVisible, setIsVisible] = useState(true);

  const [bookingDate, setBookingDate] = useState(null);

  const columns = [
    {
      title: "Booking Date",
      dataIndex: "booking_date",
      sorter: true,
    },
    {
      title: "Time",
      dataIndex: "booking_time",
      sorter: true,
    },
    {
      title: "Event Type",
      dataIndex: "event_format",
      sorter: true,
    },
    {
      title: "Venue",
      dataIndex: "venue",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "booking_status",
      sorter: true,
    },

    {
      title: "Invitee",
      dataIndex: "invitee",
      render: (_, data) => {
        return (
          capitalizeFirstLetter(data.invitee_first_name) +
          " " +
          capitalizeFirstLetter(data.invitee_last_name)
        );
      },
      sorter: false,
    },

    {
      title: "Staff",
      dataIndex: "staff",
      render: (_, data) => {
        return (
          capitalizeFirstLetter(data.staff_first_name) +
          " " +
          capitalizeFirstLetter(data.staff_last_name)
        );
      },
      sorter: false,
    },
  ];

  /**
   * collape/expand filter section
   */
  const onToggleCollapseFilter = () => {
    setCollapse(!collapse);
  };

  /**
   * remove filter section
   */
  const onRemoveFilterContainer = () => {
    setIsVisible(false);
  };

  /**
   * on Enterieng filter container
   */
  const onEntering = () => {
    setStatus("Opening...");
  };

  /**
   * on Entered filter container
   */
  const onEntered = () => {
    setStatus("Opened");
  };

  /**
   * on Exiting filter container
   */
  const onExiting = () => {
    setStatus("Closing...");
  };

  /**
   * on Exited filter container
   */
  const onExited = () => {
    setStatus("Closed");
  };

  /**
   * on filter data table
   * @param {*} name
   * @param {*} value
   */
  const onFilterDataTable = (name, value) => {
    const searchFilters = { ...filter };
    if (isEmpty(value)) {
      delete searchFilters[name];
    } else {
      searchFilters[name] = value;
    }
    setFilter(searchFilters);

    handleTableChange(
      {
        ...pagination,
      },
      {
        ...searchFilters,
      },
      {}
    );
  };

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  const prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * fetch data table data
   * @param {*} params
   */
  const fetchTableData = (params = {}) => {
    const { getBookingHistory } = props;
    setIsDataLoading(true);
    getBookingHistory({
      data: prepareDataParam(params),
      onSuccess: function (data) {
        setData(data.entity);
        setPagination({
          ...params.pagination,
          total: data.totalCount,
        });
        setIsDataLoading(false);
      },
      onError: function (error) {
        setData([]);

        setIsDataLoading(false);
      },
    });
  };

  /**
   * on page size drop down change
   * @param {*} e  event
   */
  const onPageSizeChange = (e) => {
    handleTableChange(
      {
        //current: pagination.current,
        current: 1,
        pageSize: e.target.value,
        total: pagination.total,
      },
      {},
      {}
    );
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  const handleTableChange = (pagination, filters, sorter) => {
    fetchTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  useEffect(() => {
    fetchTableData({ pagination });
  }, []);

  return (
    <>
      <Row className="app-user-list">
        <Loader isShowLoader={isPageLoading} />
        <Col sm="12">
          <Card
            className={classnames("card-action card-reload", {
              "d-none": isVisible === false,
              "card-collapsed": status === "Closed",
              closing: status === "Closing...",
              opening: status === "Opening...",
              //refreshing: this.state.reload,
            })}
          >
            <CardHeader>
              <CardTitle>Filters</CardTitle>
              <div className="actions">
                <ChevronDown
                  className="collapse-icon mr-50"
                  size={15}
                  onClick={onToggleCollapseFilter}
                />
                <X size={15} onClick={onRemoveFilterContainer} />
              </div>
            </CardHeader>
            <Collapse
              isOpen={collapse}
              onExited={onExited}
              onEntered={onEntered}
              onExiting={onExiting}
              onEntering={onEntering}
            >
              <CardBody>
                <Row>
                  <Col lg="3" md="6" sm="12">
                    <FormGroup className="mb-0">
                      <Label for="status">Status</Label>
                      <Input
                        type="select"
                        name="select"
                        id="status"
                        onChange={(e) => {
                          onFilterDataTable("status", e.target.value);
                        }}
                      >
                        <option value="">Select Status</option>
                        <option value="A">Active</option>
                        <option value="C">Confirm</option>
                        <option value="R">Reject</option>
                        <option value="CC">Cancel by Client</option>
                        <option value="CS">Cancel by service provider</option>
                        <option value="CO">Completed</option>
                        <option value="MN">MARK AS NOSHOW</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="3" md="6" sm="12">
                    <FormGroup className="mb-0">
                      <Label for="staff">Staff</Label>
                      <Input
                        id="staff"
                        placeholder="Staff #..."
                        onChange={(e) => {
                          onFilterDataTable("staff", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <FormGroup className="mb-0">
                      <Label for="customer">Customer</Label>
                      <Input
                        id="customer"
                        placeholder="Customer #..."
                        onChange={(e) => {
                          onFilterDataTable("customer", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="3" md="6" sm="12">
                    <FormGroup className="mb-0">
                      <Label for="booking-date">Booking Date</Label>
                      <DatePicker
                        className="meetocto-data-picker"
                        name="booking-date"
                        selected={
                          (bookingDate && new Date(bookingDate)) || null
                        }
                        format="dd-MM-yyyy"
                        value={bookingDate}
                        onChange={(value) => {
                          setBookingDate(value);
                          if (value) {
                            onFilterDataTable(
                              "booking_date",
                              getDBFormatedDate(value)
                            );
                          } else {
                            onFilterDataTable("booking_date", "");
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Collapse>
          </Card>
        </Col>
        <div className="ag-grid-actions d-flex justify-content-between flex-wrap mb-3">
          <div className="sort-dropdown">
            <span className="mr-2">Show</span>
            <div className="d-inline-block">
              <select className="form-control" onChange={onPageSizeChange}>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
              </select>
            </div>

            <span className="ml-2">Entries</span>
          </div>
        </div>
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={pagination}
          loading={isDataLoading}
          onChange={handleTableChange}
        ></Table>
      </Row>
    </>
  );
};

export default connect(null, {
  getBookingHistory,
})(BookingHistory);
