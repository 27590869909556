import React from "react";
import { Row, Col } from "reactstrap";
import { Pagination } from "antd";
import { connect, useDispatch } from "react-redux";
import ConfirmDeleteUserModal from "./confirm-delete-user-modal";
import InviteUserModal from "./invite-user-modal";
import { toggleStaffStatus } from "../../../actions/staffActions";
import { SET_SUBSCRIPTION_STAFF, SET_IS_LOADING } from "../../../actions/types";
import { notification } from "../../../utils/utility";

const ManageInviteUser = (props) => {
  const { staffs, admin, pagination, totalStaffs } = props.subscription;

  const dispatch = useDispatch();

  /**
   * change staff status
   * @param {*} e
   * @param {*} staffId
   */
  const onChangeStaffStatus = (e, staffId) => {
    const { toggleStaffStatus } = props;

    dispatch({
      type: SET_IS_LOADING,
      payload: true,
    });

    const staffs = [...props.subscription.staffs];
    const found = staffs.some((el) => el.id === staffId);
    if (found) {
      const staffIndex = staffs.findIndex((el) => el.id === staffId);
      if (e.target.checked) {
        staffs[staffIndex].status = "E";
      } else {
        staffs[staffIndex].status = "D";
      }
    }
    dispatch({
      type: SET_SUBSCRIPTION_STAFF,
      payload: {
        staffs,
      },
    });

    toggleStaffStatus({
      data: {
        id: staffId,
        status: e.target.checked ? "E" : "D",
      },
      onSuccess: function (message, success) {
        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });
        if (success) {
          notification.success("status updated successfully");
        }
      },
      onError: function (error) {
        dispatch({
          type: SET_IS_LOADING,
          payload: false,
        });
      },
    });
  };

  return (
    <>
      <div className="subscriptionpageaddtablesection">
        <div className="subscriptionpageaddtabcontent">
          <div
            className="subscriptionpageaddtabtoppart"
            style={{
              paddingBottom: 15,
              borderBottom: "1px solid rgba(112,112,112,0.1)",
            }}
          >
            <div>
              <h6>
                <strong>Active Users : </strong>
                {staffs &&
                  staffs.filter((cStaff) => {
                    return (
                      cStaff.user_account_status === "active" ||
                      cStaff.user_account_status === null
                    );
                  }).length}
              </h6>
            </div>
            <div>
              <div className="rd_imagethincontgbtnt2pixel">
                <InviteUserModal onInviteStaff={props.onInviteStaff} />
              </div>
            </div>
          </div>
          <div className="table_flow_wrapper">
            <table className="rd_subscribertable rd_tdpadd">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Invitation Status </th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{`${admin.first_name} ${admin.last_name}`}</td>
                  <td>{admin.email}</td>
                  <td>{admin.phone}</td>
                  <td>
                    <span className="status active">Active</span>
                  </td>
                  <td>
                    <div className="rd_orangetagthing">Admin</div>
                  </td>
                </tr>

                {staffs &&
                  staffs
                    .filter((cStaff) => {
                      return cStaff.id !== admin.id;
                    })
                    .map((staff, index) => {
                      return (
                        <tr key={index}>
                          <td>{`${staff.first_name} ${staff.last_name}`}</td>
                          <td>{staff.email}</td>
                          <td>{staff.phone}</td>
                          <td>
                            {staff.user_account_status === "pending" ? (
                              <span className="status pending">Pending</span>
                            ) : (
                              <span className="status active">Active</span>
                            )}
                          </td>
                          <td>
                            <div className="rd_inlinething">
                              <div className="box_content">
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked3"
                                    onChange={(e) =>
                                      onChangeStaffStatus(e, staff.id)
                                    }
                                    checked={staff.status === "E"}
                                  />
                                </div>
                              </div>
                            </div>
                            <ConfirmDeleteUserModal
                              staffId={staff.id}
                              onDeleteStaff={props.onDeleteStaff}
                            />
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>

          <Row className="pb-4 pr-3">
            <Col className="text-center" lg="12" md="12">
              <Pagination
                className="list-view-pagination"
                defaultCurrent={pagination.pageNumber}
                current={pagination.pageNumber}
                total={totalStaffs}
                pageSize={pagination.pageSize}
                onChange={props.onPageChange}
                showSizeChanger={false}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  subscription: state.subscription,
});

export default connect(mapStateToProps, {
  toggleStaffStatus,
})(ManageInviteUser);
