export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USER_CONFIG = "SET_USER_CONFIG";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const SET_FILTER_VISIBILITY_STATUS = "SET_FILTER_VISIBILITY_STATUS";
export const ON_UPDATE_FILTER = "ON_UPDATE_FILTER";
export const ON_CLEAR_FILTER = "ON_CLEAR_FILTER";

/**
 * Layout
 */
export const SET_IS_LOADING = "TOGGLE_IS_LOADING";
export const CHANNELS_LOADED = "CHANNELS_LOADED";

/**
 * Notification
 */
export const UPDATE_NOTIFICATION_UNREAD_COUNT =
  "UPDATE_NOTIFICATION_UNREAD_COUNT";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const APPEND_NOTIFICATIONS = "APPEND_NOTIFICATIONS";

/**
 * Calendar
 */
export const INIT_CALENDAR = "INIT_CALENDAR";
export const SET_CALENDAR_VIEW = "SET_CALENDAR_VIEW";
export const NEXT_CALENDAR_MONTH = "ADD_CALENDAR_MONTH";
export const PREV_CALENDAR_MONTH = "PREV_CALENDAR_MONTH";
export const TODAY_CALENDAR_MONTH = "TODAY_CALENDAR_MONTH";
export const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS";
export const TOGGLE_ADMIN_PROFILE_DROPDOWN = "TOGGLE_ADMIN_PROFILE_DROPDOWN";
export const TOGGLE_MOB_LEFT_MENU = "TOGGLE_MOB_LEFT_MENU";

/**
 * Subscription
 */
export const SET_USER_AND_PLAN_DETAILS = "SET_USER_AND_PLAN_DETAILS";
export const SET_SUBSCRIPTION_STAFF = "SET_SUBSCRIPTION_STAFF";
export const SET_PLAN_PERIOD_TYPE = "SET_PLAN_PERIOD_TYPE";
export const SET_SUBSCRIPTION_PLANS = "SET_SUBSCRIPTION_PLANS";
export const SET_SUBSCRIPTION_USER_PAGINATION =
  "SET_SUBSCRIPTION_USER_PAGINATION";
