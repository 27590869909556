import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Formik, Form } from "formik";
import { getEventStaffList } from "../../../../actions/eventActions";
import { Edit, Trash2, Copy, Star } from "react-feather";
import {
  getStaffScheduleList,
  saveStaffScheduleName,
  getStaffScheduleTemplate,
  saveStaffSchedule,
  updateScheduleName,
  makeAsDefault,
  deleteSchedule,
  duplicate,
} from "../../../../actions/scheduleActions";
import { connect } from "react-redux";
import {
  getAdminBusinessId,
  getLoggedInUserId,
} from "../../../../utils/authHelper";
import Loader from "../../../../components/Loader/Loader";
import AddScheduleModal from "./Components/AddScheduleModal";
import DeleteScheduleModal from "./Components/DeleteScheduleModal";
import EditScheduleModal from "./Components/EditScheduleModal";
import SetAsDefaultModal from "./Components/SetAsDefaultModal";
import Availability from "./Components/Availability";
import Select from "react-select";
import { notification } from "../../../../utils/utility";
import DefaultIcon from "../../../../assets/icons/self_star.svg";
import ElipsisVertical from "../../../../assets/icons/elipsisV.svg";
import ElipsisVDark from "../../../../assets/icons/elipsisV_dark.svg";
import ListScheduleTemplates from "./Components/ListScheduleTemplates";

class Staff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      pageLoading: false,
      schedules: [],
      availabilities: [],
      activeTab: "1",
      listSchedules: [],
      showAddScheduleModal: false,
      selectedScheduleId: null,
      selectedScheduleName: null,
      scheduleListDropDownStatus: false,
      staffs: [],
      timezoneId: null,
      selectedStaffId: null,
      selectedStaff: [],
      settingsDropdownStatus: false,
      selectedScheduleIsDefaultOne: false,
      showEditScheduleModal: false,
      scheduleName: "",
      scheduleId: "",
      showDeleteScheduleModal: false,
      showSetAsDefaultModal: false,
    };
  }

  componentDidMount() {
    const _this = this;
    const { getEventStaffList: getStaffs } = _this.props;

    _this.setState({
      pageLoading: true,
    });

    getStaffs({
      data: {
        businessId: getAdminBusinessId(),
      },
      onSuccess: (response) => {
        console.log("data", response);
        const staffList = response.map((staff, index) => {
          if (index === 0) {
            _this.loadDefaultStaffSchedulePage(staff.id);
            _this.setState({
              selectedStaff: [
                {
                  label: staff.first_name + " " + staff.last_name,
                  value: staff.id,
                },
              ],
            });
          }
          return {
            label: staff.first_name + " " + staff.last_name,
            value: staff.id,
          };
        });

        if (staffList.length <= 0) {
          _this.setState({
            pageLoading: false,
          });
        }

        _this.setState({
          staffs: staffList,
        });
      },
      onError: (error) => {
        console.log({
          error,
        });
      },
    });
  }

  /**
   * loo
   * @param {*} staffId
   */
  loadDefaultStaffSchedulePage = (staffId) => {
    const _this = this;
    const { getStaffScheduleList } = _this.props;

    _this.setState({
      pageLoading: true,
      selectedStaffId: staffId,
    });

    getStaffScheduleList({
      data: {
        staffId,
      },
      onSuccess: function (response) {
        _this.setState({
          pageLoading: false,
          listSchedules: response.listSchedules,
        });

        if (response.listSchedules.length > 0) {
          const scheduleId = response.listSchedules[0].id;
          _this.loadSchedule(scheduleId);
        }
      },
      onError: function (error) {
        _this.setState({
          pageLoading: false,
        });
      },
    });
  };

  /**
   * load schedule
   * @param {*} scheduleId
   */
  loadSchedule = (scheduleId) => {
    const _this = this;

    const { getStaffScheduleTemplate } = _this.props;

    _this.setState({
      pageLoading: true,
    });

    getStaffScheduleTemplate({
      data: {
        scheduleId,
        staffId: _this.state.selectedStaffId,
      },
      onSuccess: function (response) {
        if (response.schedules) {
          _this.setState({
            pageLoading: false,
            selectedScheduleId: response.schedules.id,
            selectedScheduleName: response.schedules.schedule_name,
            selectedScheduleIsDefaultOne:
              response.schedules.is_default === "E" ? true : false,
          });
          console.log("my response", response);
          _this.setSchedules(
            _this.processSchedulesForListView(response.schedules),
            response.schedules ? response.schedules.timezone_id : ""
          );
        }
      },
      onError: function (error) {
        _this.setState({
          pageLoading: false,
        });
      },
    });
  };

  /**
   * @param Array schedules
   * @return Array
   */
  processSchedulesForListView = (schedules) => {
    let processedSchedules = [];
    (schedules.schedule_details || []).forEach((scheduleDetail) => {
      if (scheduleDetail.type == "weekly") {
        processedSchedules.push({
          dayId: parseInt(scheduleDetail.weekday_id),
          schedules: [...scheduleDetail.schedule_availability],
        });
      }
    });

    return processedSchedules;
  };

  /**
   * process schedules to save in database
   * @param int eventId
   * @returns array
   */
  processScheduleForSave = () => {
    const { schedules } = this.state;

    let processedSchedules = [];
    schedules.forEach((schedule, index) => {
      const weekDayId = schedule.hasOwnProperty("dayId")
        ? schedule.dayId
        : null;
      const dated = schedule.hasOwnProperty("dated") ? schedule.dated : null;

      const type = schedule.hasOwnProperty("dated") ? "custom" : "weekly";

      processedSchedules.push({
        weekday_id: weekDayId,
        dated: dated,
        on_status: "E",
        type: type,
        schedule_availability: schedule.schedules,
      });
    });

    return processedSchedules;
  };

  /**
   *
   * @param Array updatedSchedules
   */
  setSchedules = (updatedSchedules, timezoneId) => {
    this.setState({
      schedules: updatedSchedules,
      timezoneId: timezoneId,
    });
  };

  /**
   * toggle schedule list drop down
   */
  toggleScheduleListDropdown = () => {
    this.setState((prevState) => ({
      scheduleListDropDownStatus: !prevState.scheduleListDropDownStatus,
    }));
  };

  /**
   * show modal for add schedule
   */
  onAddScheduleHandle = () => {
    this.setState({
      showAddScheduleModal: true,
    });
  };

  /**
   * on toggle active tab
   * @param {*} tab
   */
  onToggleActiveTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  /**
   * save new schedule
   */
  saveNewSchedule = (scheduleName) => {
    const _this = this;
    const { saveStaffScheduleName } = _this.props;

    if (!_this.state.selectedStaffId) {
      return notification.error("Please, you need to select staff first");
    }

    _this.setState({
      pageLoading: true,
    });

    const data = {
      business_id: getAdminBusinessId(),
      schedule_name: scheduleName,
      staff_id: _this.state.selectedStaffId,
    };

    saveStaffScheduleName({
      data,
      onSuccess: function (response) {
        console.log("response", response);

        notification.success("Schedule added successfully");

        const { data } = response.data;

        const listSchedules = [..._this.state.listSchedules];

        listSchedules.push({
          id: data.id,
          schedule_name: data.schedule_name,
          is_default: data.is_default,
        });

        _this.setState({
          listSchedules: listSchedules,
          selectedScheduleId: data.id,
        });
        _this.loadSchedule(data.id);
      },
      onError: function (error) {
        _this.setState({
          pageLoading: false,
        });
        notification.error("An error during save, please try later");
      },
    });
  };

  /**
   * on save
   */
  onSave = () => {
    const _this = this;

    const { saveStaffSchedule } = _this.props;
    const processedSchedules = _this.processScheduleForSave();

    _this.setState({
      pageLoading: true,
    });

    saveStaffSchedule({
      data: {
        provider_id: getLoggedInUserId(),
        schedule_id: _this.state.selectedScheduleId,
        staff_id: _this.state.selectedStaffId,
        business_id: getAdminBusinessId(),
        schedule_details: processedSchedules,
      },
      onSuccess: () => {
        _this.setState({
          pageLoading: false,
        });
        notification.success("Staff schedule saved successfully");
      },
      onError: (error) => {
        notification.error("An error occurred during save, please try later");
        _this.setState({
          pageLoading: false,
        });
      },
    });
  };

  /**
   * Update schedule
   * @param {*} scheduleId
   * @param {*} schduleName
   */
  updateScheduleTemplateName = (scheduleId, schduleName) => {
    const _this = this;

    const { updateScheduleName } = _this.props;
    const listSchedulesNames = [..._this.state.listSchedules];

    _this.setState({
      pageLoading: true,
    });

    updateScheduleName({
      data: {
        id: scheduleId,
        schedule_name: schduleName,
      },
      onSuccess: function (response) {
        const updatedScheduleIndex = listSchedulesNames.findIndex(
          (el) => el.id === scheduleId
        );
        listSchedulesNames[updatedScheduleIndex].schedule_name = schduleName;
        _this.setState({
          pageLoading: false,
          listSchedules: listSchedulesNames,
        });
        notification.success("Schedule Name successfully Updated");
      },
      onError: function (error) {
        _this.setState({
          pageLoading: false,
        });
        notification.error("An error occured during update, please try later");
      },
    });
  };

  /**
   * set schedule as default
   * @param {*} scheduleId
   */
  setAsDefaultHandle = (scheduleId) => {
    const _this = this;

    const { makeAsDefault } = _this.props;

    const listSchedules = [..._this.state.listSchedules];

    _this.setState({
      pageLoading: true,
    });

    makeAsDefault({
      data: {
        id: scheduleId,
        business_id: getAdminBusinessId(),
        staff_id: _this.state.selectedStaffId,
      },
      onSuccess: function (response) {
        notification.success("Record saved successfully");

        const disableDefaultScheduleIndex = listSchedules.findIndex(
          (schedule) => schedule.is_default == "E"
        );

        if (disableDefaultScheduleIndex !== -1) {
          listSchedules[disableDefaultScheduleIndex].is_default = "D";
        }

        const scheduleIndex = listSchedules.findIndex(
          (schedule) => schedule.id == scheduleId
        );

        if (scheduleIndex !== -1) {
          listSchedules[scheduleIndex].is_default = "E";
          listSchedules.push(...listSchedules.splice(0, scheduleIndex));
        }

        _this.setState({
          pageLoading: false,
          selectedScheduleIsDefaultOne: true,
          listSchedules,
        });
      },
      onError: function (error) {
        notification.error("An error occured during save, please try later");
        _this.setState({
          pageLoading: false,
        });
      },
    });
  };

  /**
   * Update schedule
   * @param {*} scheduleId
   */
  deleteScheduleTemplate = (scheduleId) => {
    const _this = this;

    const { deleteSchedule } = _this.props;

    const listSchedulesNames = [..._this.state.listSchedules];

    _this.setState({
      pageLoading: true,
    });

    deleteSchedule({
      data: {
        id: scheduleId,
      },
      onSuccess: function (response) {
        const processedScheduleNames = listSchedulesNames.filter(
          (el) => el.id !== scheduleId
        );

        notification.success("Schedule successfully Deleted");
        _this.setState({
          pageLoading: false,
          listSchedules: processedScheduleNames,
        });
      },
      onError: function (error) {
        notification.error("An error occured during delete, please try later");
        _this.setState({
          pageLoading: false,
        });
      },
    });
  };

  /**
   * on clone schedule
   */
  onClone = () => {
    const _this = this;

    const { selectedScheduleId } = _this.state;
    const { duplicate, getStaffScheduleTemplate } = _this.props;

    _this.setState({
      pageLoading: true,
    });

    duplicate({
      data: {
        id: selectedScheduleId,
      },
      onSuccess: function (response) {
        notification.success("Record cloned successfully");

        console.log("response clone", response);

        const { data } = response.data;

        const listSchedules = [..._this.state.listSchedules];

        if (listSchedules.length > 3) {
          const temp = listSchedules[2];
          listSchedules[2] = {
            id: data.id,
            schedule_name: data.schedule_name,
          };
          listSchedules.push(temp);
        } else {
          listSchedules.push({
            id: data.id,
            schedule_name: data.schedule_name,
          });
        }

        _this.setState({
          listSchedules,
        });

        getStaffScheduleTemplate({
          data: {
            scheduleId: data.id,
            staffId: _this.state.selectedStaffId,
          },
          onSuccess: function (response) {
            _this.setState({
              pageLoading: false,
              selectedScheduleId: response.schedules.id,
              selectedScheduleName: response.schedules.schedule_name,
              selectedScheduleIsDefaultOne:
                response.schedules.is_default === "E" ? true : false,
            });
            console.log("my response", response);
            _this.setSchedules(
              _this.processSchedulesForListView(response.schedules),
              response.schedules ? response.schedules.timezone_id : ""
            );
          },
          onError: function (error) {
            _this.setState({
              pageLoading: false,
            });
          },
        });
      },
      onError: function (error) {
        notification.error("An error occured during clone, please try later");
        _this.setState({
          pageLoading: false,
        });
      },
    });
  };

  /**
   * hide add schedule modal
   */
  hideAddScheduleModal = () => {
    this.setState({
      showAddScheduleModal: false,
    });
  };

  /**
   * hide edit schedule modal
   */
  hideEditScheduleModal = () => {
    this.setState({
      showEditScheduleModal: false,
    });
  };

  /**
   * hide add schedule modal
   */
  hideDeleteScheduleModal = () => {
    this.setState({
      showDeleteScheduleModal: false,
    });
  };

  /**
   * hide set as default modal
   */
  hideSetAsDefaultModal = () => {
    this.setState({
      showSetAsDefaultModal: false,
    });
  };

  /**
   * show modal for add schedule
   */
  showDeleteScheduleHandle = (scheduleId) => {
    const _this = this;
    const listSchedulesNames = [..._this.state.listSchedules];
    const scheduleToDelete = listSchedulesNames.filter(
      (el) => el.id === scheduleId
    );
    if (scheduleToDelete.length && scheduleToDelete[0].is_default === "E") {
      notification.warning("Cant delete default template");
      return;
    }
    this.setState({
      showDeleteScheduleModal: true,
      scheduleId,
    });
  };

  /**
   * show modal to confirm set as default
   * @param {*} scheduleId
   */
  showMakeAsDefaultHandle = (scheduleId) => {
    this.setState({
      showSetAsDefaultModal: true,
      scheduleId,
    });
  };

  /**
   * show Edit schedule modal
   * @param {*} scheduleId
   * @param {*} scheduleName
   */
  showEditScheduleModal = (scheduleId, scheduleName) => {
    this.setState({
      scheduleId,
      scheduleName,
      showEditScheduleModal: true,
    });
  };

  /**
   * Get staff schedule
   * @param {*} staffId
   */
  getStaffSchedules = (staffId) => {
    const _this = this;
    const { getStaffScheduleList } = _this.props;

    _this.setState({
      pageLoading: true,
      selectedStaffId: staffId,
    });

    getStaffScheduleList({
      data: {
        staffId,
      },
      onSuccess: function (response) {
        _this.setState({
          pageLoading: false,
          listSchedules: response.listSchedules,
        });
      },
      onError: function (error) {
        _this.setState({
          pageLoading: false,
        });
      },
    });
  };

  /**
   * toggle setting drop down
   */
  toggleSettingListDropdown = () => {
    this.setState((prevState) => ({
      settingsDropdownStatus: !prevState.settingsDropdownStatus,
    }));
  };

  /**
   *
   * @param {*} schedules
   */
  setListSchedules = (listSchedules) => {
    this.setState({
      listSchedules,
    });
  };

  render() {
    const _this = this;
    const {
      pageLoading,
      isLoading,
      activeTab,
      listSchedules,
      showAddScheduleModal,
      selectedScheduleId,
      scheduleListDropDownStatus,
      staffs,
      selectedStaffId,
      selectedStaff,
      settingsDropdownStatus,
      selectedScheduleName,
      selectedScheduleIsDefaultOne,
      showEditScheduleModal,
      scheduleName,
      scheduleId,
      showDeleteScheduleModal,
      showSetAsDefaultModal,
    } = this.state;

    return (
      <>
        <Loader isShowLoader={pageLoading} />

        {showAddScheduleModal && (
          <AddScheduleModal
            showAddScheduleModal={showAddScheduleModal}
            hideAddScheduleModal={this.hideAddScheduleModal}
            saveNewSchedule={this.saveNewSchedule}
          />
        )}

        {showEditScheduleModal && (
          <EditScheduleModal
            showEditScheduleModal={showEditScheduleModal}
            hideEditScheduleModal={this.hideEditScheduleModal}
            scheduleName={scheduleName}
            scheduleId={scheduleId}
            updateScheduleTemplateName={this.updateScheduleTemplateName}
          />
        )}

        {showDeleteScheduleModal && (
          <DeleteScheduleModal
            showDeleteScheduleModal={showDeleteScheduleModal}
            hideDeleteScheduleModal={this.hideDeleteScheduleModal}
            scheduleId={scheduleId}
            deleteScheduleTemplate={this.deleteScheduleTemplate}
          />
        )}

        {showSetAsDefaultModal && (
          <SetAsDefaultModal
            showSetAsDefaultModal={showSetAsDefaultModal}
            hideSetAsDefaultModal={this.hideSetAsDefaultModal}
            scheduleId={scheduleId}
            setAsDefaultHandle={this.setAsDefaultHandle}
          />
        )}

        <Col md="12" className="mb-4">
          <div className="staff_avail row justify-content-between align-items-center mx-0">
            <div className="col-lg-3 col-md-6 ps-0">
              <Select
                value={selectedStaff}
                className="select_dropdown"
                options={staffs}
                onChange={(staff) => {
                  const { label, value } = staff || {};

                  this.setState({
                    selectedStaff: [
                      {
                        label,
                        value,
                      },
                    ],
                  });

                  if (value) {
                    this.getStaffSchedules(value);
                  }
                }}
              ></Select>
            </div>
            <div className="col-lg-auto col-md-5">
              <Button
                className="btn btn-app btn btn-secondary"
                type="button"
                onClick={this.onAddScheduleHandle}
              >
                + New Schedule
              </Button>
            </div>
          </div>
        </Col>

        <ListScheduleTemplates
          listSchedules={listSchedules}
          loadSchedule={this.loadSchedule}
          selectedScheduleId={selectedScheduleId}
          settingsDropdownStatus={settingsDropdownStatus}
          selectedScheduleName={selectedScheduleName}
          selectedScheduleIsDefaultOne={selectedScheduleIsDefaultOne}
          scheduleListDropDownStatus={scheduleListDropDownStatus}
          toggleSettingListDropdown={this.toggleSettingListDropdown}
          showEditScheduleModal={this.showEditScheduleModal}
          onClone={this.onClone}
          showMakeAsDefaultHandle={this.showMakeAsDefaultHandle}
          showDeleteScheduleHandle={this.showDeleteScheduleHandle}
          toggleScheduleListDropdown={this.toggleScheduleListDropdown}
          setListSchedules={this.setListSchedules}
        />

        {/* <Col md="12" className="mb-4">
          <div className="d-flex justify-content-between">
            <div className="template_wrapper d-flex">
              {(listSchedules || [])
                .filter((schedule, index) => index < 4)
                .map((schedule, innerIndex) => {
                  if (schedule.is_default === "E") {
                    return (
                      <button
                        key={innerIndex}
                        className={`btn avail_templates ${
                          schedule.id == selectedScheduleId ? "active" : ""
                        }`}
                        onClick={() => {
                          if (schedule.id !== selectedScheduleId) {
                            this.loadSchedule(schedule.id);
                          }
                        }}
                      >
                        <img src={DefaultIcon} alt="" />
                        <span>
                          {schedule.schedule_name}
                          <p>You can not delete it</p>
                        </span>

                        {schedule.id === selectedScheduleId && (
                          <Dropdown
                            isOpen={settingsDropdownStatus}
                            toggle={this.toggleSettingListDropdown}
                            className="float-right ml-auto"
                            direction="left"
                          >
                            <DropdownToggle caret tag="a">
                              <img
                                src={ElipsisVertical}
                                alt=""
                                className="elipsis"
                              />
                            </DropdownToggle>

                            <DropdownMenu>
                              <DropdownItem
                                onClick={(event) => {
                                  this.showEditScheduleModal(
                                    selectedScheduleId,
                                    selectedScheduleName
                                  );
                                }}
                              >
                                <Edit size={18} className="mr-2" />
                                Edit Name
                              </DropdownItem>

                              <DropdownItem onClick={this.onClone}>
                                <Copy size={18} className="mr-2" />
                                Clone
                              </DropdownItem>

                              {!selectedScheduleIsDefaultOne && (
                                <DropdownItem
                                  onClick={() => {
                                    this.showMakeAsDefaultHandle(
                                      selectedScheduleId
                                    );
                                  }}
                                >
                                  <Star size={18} className="mr-2" />
                                  Set as Default
                                </DropdownItem>
                              )}

                              <DropdownItem
                                onClick={() => {
                                  this.showDeleteScheduleHandle(
                                    selectedScheduleId
                                  );
                                }}
                              >
                                <Trash2 size={18} className="mr-2" />
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        )}
                      </button>
                    );
                  } else {
                    return (
                      <button
                        key={innerIndex}
                        className={`btn avail_templates ${
                          schedule.id == selectedScheduleId ? "active" : ""
                        }`}
                        onClick={() => {
                          if (schedule.id !== selectedScheduleId) {
                            this.loadSchedule(schedule.id);
                          }
                        }}
                      >
                        <span>{schedule.schedule_name}</span>
                        {schedule.id === selectedScheduleId && (
                          <Dropdown
                            isOpen={settingsDropdownStatus}
                            toggle={this.toggleSettingListDropdown}
                            className="float-right ml-auto"
                            direction="left"
                          >
                            <DropdownToggle caret tag="a">
                              <img
                                src={ElipsisVertical}
                                alt=""
                                className="elipsis"
                              />
                            </DropdownToggle>

                            <DropdownMenu>
                              <DropdownItem
                                onClick={(event) => {
                                  this.showEditScheduleModal(
                                    selectedScheduleId,
                                    selectedScheduleName
                                  );
                                }}
                              >
                                <Edit size={18} className="mr-2" />
                                Edit Name
                              </DropdownItem>

                              <DropdownItem onClick={this.onClone}>
                                <Copy size={18} className="mr-2" />
                                Clone
                              </DropdownItem>

                              {!selectedScheduleIsDefaultOne && (
                                <DropdownItem
                                  onClick={() => {
                                    this.showMakeAsDefaultHandle(
                                      selectedScheduleId
                                    );
                                  }}
                                >
                                  <Star size={18} className="mr-2" />
                                  Set as Default
                                </DropdownItem>
                              )}

                              <DropdownItem
                                onClick={() => {
                                  this.showDeleteScheduleHandle(
                                    selectedScheduleId
                                  );
                                }}
                              >
                                <Trash2 size={18} className="mr-2" />
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        )}
                      </button>
                    );
                  }
                })}
            </div>
            {listSchedules.length > 4 && (
              <div>
                <button className="btn avail_templates more_btn">
                  <div className="noti_num">
                    {listSchedules.length >= 14
                      ? "9+"
                      : listSchedules.length - 4}
                  </div>
                  <img src={ElipsisVDark} alt="" className="elipsis" />
                </button>
              </div>
            )}
          </div>
        </Col> */}
        {selectedStaffId && (
          <Col md="12" lg="12">
            <div className="event-card">
              <div className="event-card-head">
                <Row className="w-100">
                  <Col md="12" lg="12">
                    <h3 className="event-title">Schedule</h3>
                  </Col>
                </Row>
              </div>
              <div className="event-card-body pt-0">
                <div className="event-field-group border-zero">
                  <div className="availbility-container">
                    <Formik onSubmit={(data) => {}}>
                      {(formProps) => {
                        const { values, errors, touched, handleChange } =
                          formProps;
                        return (
                          <Form>
                            <Availability
                              onToggleActiveTab={this.onToggleActiveTab}
                              errors={errors}
                              touched={touched}
                              schedules={this.state.schedules}
                              timezoneId={this.state.timezoneId}
                              setSchedules={this.setSchedules}
                            />
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                  {activeTab == "1" ? (
                    <Col md="3" lg="3" className="float-right">
                      <Button
                        disabled={isLoading}
                        type="button"
                        className="btn btn-app"
                        onClick={this.onSave}
                      >
                        {isLoading ? "Saving..." : "Save"}
                      </Button>
                    </Col>
                  ) : null}
                </div>
              </div>
            </div>
          </Col>
        )}
      </>
    );
  }
}

export default connect(null, {
  getEventStaffList,
  getStaffScheduleList,
  saveStaffScheduleName,
  getStaffScheduleTemplate,
  saveStaffSchedule,
  updateScheduleName,
  makeAsDefault,
  deleteSchedule,
  duplicate,
})(Staff);
