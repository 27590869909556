import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Company from "./Company";
import Staff from "./Staff";

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "company",
    };
  }
  render() {
    const { page } = this.state;

    return (
      <div className="create-event-wrapper">
        <div className="create-event-container">
          <Row>
            <Col md="12">
              <h3 className="section_title">Availability Template</h3>
            </Col>
            <Col md="12">
              <div className="filter_bar dark" style={{ marginTop: 30 }}>
                <ul className="cus_tab">
                  <li>
                    <button
                      className={`btn ${page === "company" ? "active" : ""}`}
                      onClick={() => {
                        if (page !== "company") {
                          this.setState({ page: "company" });
                        }
                      }}
                    >
                      Business Hours
                    </button>
                  </li>
                  <li>
                    <button
                      className={`btn ${page === "staff" ? "active" : ""}`}
                      onClick={() => {
                        if (page !== "staff") {
                          this.setState({ page: "staff" });
                        }
                      }}
                    >
                      Staff Availability
                    </button>
                  </li>
                </ul>
              </div>
            </Col>
            {page === "company" ? <Company /> : <Staff />}
          </Row>
        </div>
      </div>
    );
  }
}

export default Schedule;
