import {
  INIT_CALENDAR,
  SET_CALENDAR_VIEW,
  NEXT_CALENDAR_MONTH,
  PREV_CALENDAR_MONTH,
  TODAY_CALENDAR_MONTH,
} from "../actions/types";

const initialState = {
  views: ["month", "week", "day"],
  currentDate: new Date(),
  selectedDate: new Date(),
  selectedView: "month",
  onSelectEvent: (event) => {},
  onNavigate: (date, action) => {},
  shortEventSlot: false,
  onSelectSlot: (date, browserEvent) => {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_CALENDAR:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CALENDAR_VIEW:
      return {
        ...state,
        selectedView: action.payload,
      };
    case TODAY_CALENDAR_MONTH:
    case NEXT_CALENDAR_MONTH:
    case PREV_CALENDAR_MONTH:
      return {
        ...state,
        selectedDate: action.payload,
      };
    default:
      return state;
  }
};
