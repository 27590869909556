import React, { Component } from "react";
import { gotProperty, getDBFormatedDate } from "../../../../utils/utility";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { onUpdateFilters } from "../../../../actions/filterActions";
import DatePicker from "react-date-picker";
import { getVenues } from "../../../../actions/eventActions";

class ClientPaymentFilter extends Component {
  state = {
    bookingDate: null,
    venues: [],
  };

  componentDidMount() {
    const _this = this;
    const { getVenues } = this.props;
    getVenues({
      onSuccess: function (venues) {
        _this.setState({
          venues,
        });
      },
      onError: function (error) {
        console.error(error);
      },
    });
  }

  render() {
    const { searchFilters } = this.props.filter;
    const { venues } = this.state;

    return (
      <>
        <div className="form-group">
          <label htmlFor="status">Status</label>
          <Input
            type="select"
            name="select"
            id="status"
            value={
              gotProperty(searchFilters, "status")
                ? searchFilters["status"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("status", e.target.value);
            }}
          >
            <option value="">Select Status</option>
            <option value="A">Active</option>
            <option value="C">Confirm</option>
            <option value="R">Reject</option>
            <option value="CC">Cancel by Client</option>
            <option value="CS">Cancel by service provider</option>
            <option value="CO">Completed</option>
            <option value="MN">MARK AS NOSHOW</option>
          </Input>
        </div>

        <div className="form-group">
          <label htmlFor="event-name">Event Name</label>
          <input
            type="text"
            id="event-name"
            placeholder="Event Name ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "event") ? searchFilters["event"] : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("event", e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="event-venue">Venue</label>
          <Input
            type="select"
            name="select"
            id="event-venue"
            value={
              gotProperty(searchFilters, "venue_id")
                ? searchFilters["venue_id"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("venue_id", e.target.value);
            }}
          >
            <option value="">Select Venue</option>
            {(venues || []).map((venue, index) => {
              return (
                <option key={index} value={venue.id}>
                  {venue.venue}
                </option>
              );
            })}
          </Input>
        </div>

        <div className="form-group">
          <label htmlFor="staff">Staff</label>
          <input
            type="text"
            id="staff"
            placeholder="Staff ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "staff") ? searchFilters["staff"] : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("staff", e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="invitee">Invitee</label>
          <input
            type="text"
            id="invitee"
            placeholder="Invitee ..."
            className="form-control"
            value={
              gotProperty(searchFilters, "invitee")
                ? searchFilters["invitee"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("invitee", e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <div className="mb-3">
            <label htmlFor="event-format">Booking Date</label>
            <DatePicker
              className="my-calendar-view"
              format="dd-MM-yyyy"
              value={
                gotProperty(searchFilters, "booking_date")
                  ? this.state.bookingDate
                  : null
              }
              onChange={(val) => {
                this.setState({
                  bookingDate: val,
                });
                this.props.onUpdateFilters(
                  "booking_date",
                  getDBFormatedDate(val)
                );
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  getVenues,
  onUpdateFilters,
})(ClientPaymentFilter);
