import React, { Component, Fragment } from "react";
import { Row, Col } from "reactstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Loader from "../../../../components/Loader/Loader";
import { onModalOpen, onModalClose } from "../../../../utils/utility";
import isEmpty from "../../../../validation/is-empty";
import { dateRangeEvents } from "../../../../actions/admin/scheduled-event-actions";
import { Pagination } from "antd";
import { connect } from "react-redux";
import UpcomingViewDetailsModal from "./upcoming-view-details-modal";
import PastViewDetails from "./past-view-details";
import P from "../../../../assets/bookingeventassets/assets/p.svg";
import ZoomIcon from "../../../../assets/icons/zoom.svg";
import MeetIcon from "../../../../assets/icons/google-meet.svg";
import TeamsIcon from "../../../../assets/icons/Microsoft_Teams.svg";
import GoToIcon from "../../../../assets/icons/goto_icon.svg";
import PhoneCall from "../../../../assets/icons/phone_call.svg";
import InPersonMeet from "../../../../assets/icons/in_person_meet.svg";
import OtherMeet from "../../../../assets/icons/other_meet_icon.svg";
class DateRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: false,
      events: [],
      totalEvents: 0,
      bookingId: null,
      pagination: {
        current: 1,
        pageSize: 5,
      },
      filter: {},
      startDate: "",
      endDate: "",
      showUpcomingEventDetail: false,
      showPastEventDetail: false,
    };
  }

  /**
   * hide view detail modal
   */
  hideViewDetailModal = () => {
    this.setState({
      showUpcomingEventDetail: false,
      showPastEventDetail: false,
    });
  };

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * on page change
   * @param {*} page
   * @param {*} pageSize
   */
  onPageChange = (page, pageSize) => {
    const _this = this;
    _this.setState({
      pageLoading: true,
    });
    _this.fetchListEvents({
      pagination: {
        current: page,
        pageSize: pageSize,
      },
    });
  };

  /**
   * fetch list events
   */
  fetchListEvents = (params = {}) => {
    const _this = this;
    const { dateRangeEvents } = _this.props || {};
    dateRangeEvents({
      data: _this.prepareDataParam(params),
      onSuccess: function (response) {
        const { data, total } = response;
        _this.setState({
          events: data,
          totalEvents: total,
          pageLoading: false,
          pagination: { ...params.pagination },
        });
      },
      onError: function (error) {
        console.error(error);
      },
    });
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchListEvents({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  /**
   * on filter records
   * @param {*} start date
   * @param {*} end date
   */
  onFilterDataTable = (startDate, endDate) => {
    this.handleTableChange(
      {
        ...this.state.pagination,
      },
      {
        start_date: startDate,
        end_date: endDate,
      },
      {}
    );
  };

  /**
   * on upcoming booking detail click
   * @param {*} bookingData
   */
  onUpcomingEventDetail = (bookingData) => {
    this.setState({
      bookingId: bookingData.id,
      showUpcomingEventDetail: true,
    });
  };

  /**
   * on past booking detail click
   * @param {*} bookingData
   */
  onPastEventDetail = (bookingData) => {
    this.setState({
      bookingId: bookingData.id,
      showPastEventDetail: true,
    });
  };

  render() {
    const {
      pageLoading,
      events,
      pagination,
      startDate,
      endDate,
      bookingId,
      showUpcomingEventDetail,
      showPastEventDetail,
    } = this.state || {};

    return (
      <>
        {showUpcomingEventDetail && (
          <UpcomingViewDetailsModal
            bookingId={bookingId}
            showViewDetailModal={showUpcomingEventDetail}
            handleClose={this.hideViewDetailModal}
            loadUpcomingEvents={() =>
              this.onFilterDataTable(startDate, endDate)
            }
          />
        )}

        {showPastEventDetail && (
          <PastViewDetails
            bookingId={bookingId}
            showViewDetailModal={showPastEventDetail}
            handleClose={this.hideViewDetailModal}
          />
        )}

        <Loader isShowLoader={pageLoading} />
        <div className="rd_pasevencontth">
          <DateRangePicker
            initialSettings={{
              startDate: moment(),
              endDate: moment().add(14, "d"),
            }}
            onCallback={(start, end, label) => {
              const startDate = moment(start).format("yyyy-MM-DD");
              const endDate = moment(end).format("yyyy-MM-DD");

              this.setState({
                startDate,
                endDate,
              });

              this.onFilterDataTable(startDate, endDate);
            }}
          >
            <input type="text" className="mb-4" />
          </DateRangePicker>

          {Object.keys(events).length > 0 ? (
            <Fragment>
              {Object.keys(events).map((key, index) => {
                return (
                  <Fragment key={index}>
                    <h2>
                      <span className="rd_archiveiconth"></span>
                      {moment(key, "DD-MM-YYYY").format("dddd, MMMM, DD YYYY")}
                    </h2>
                    <div className="rd_eventcardscontainer align-items-stretch">
                      {(events[`${key}`] || []).map((event, innerIndex) => {
                        if (event.type === "regular") {
                          return (
                            <div className="rd_carditem" key={innerIndex}>
                              <div className="rd_cardheaderevenpasc">
                                <h3>{event.event_name}</h3>
                                {event.event_format_id === "1" ||
                                event.event_format_id === 1 ? (
                                  <p>One on One</p>
                                ) : (
                                  <p>{`1 to ${event.no_of_attendies} invitees`}</p>
                                )}
                              </div>
                              <div className="rd_cardbdyevenpasc">
                                <p>
                                  <span className="rd_timelefticon"></span>
                                  {`${event.start_time} - ${event.end_time}`}
                                </p>
                                <p>
                                  <span className="rd_timeiconthin"></span>
                                  10:00am-10:30am (Pakistan, Maldives Time)
                                </p>
                                <p>
                                {event.venue === "Microsoft Teams" ? (
                                  <img
                                    src={TeamsIcon}
                                    alt="microsoft teams icon"
                                  />
                                ) : event.venue === "Zoom" ? (
                                  <img src={ZoomIcon} alt="Zoom icon" />
                                ) : event.venue === "Google Meet" ? (
                                  <img src={MeetIcon} alt="Google Meet icon" />
                                ) : event.venue === "Phone Call" ? (
                                  <img src={PhoneCall} alt="Phone Call icon" />
                                ) : event.venue === "In-Person Meeting" ? (
                                  <img
                                    src={InPersonMeet}
                                    alt="In Person Meet icon"
                                    style={{ maxWidth: 14 }}
                                  />
                                ) : event.venue === "Others" ? (
                                  <img
                                    src={OtherMeet}
                                    alt="microsoft teams icon"
                                    style={{maxWidth: 18}}
                                  />
                                ) : event.venue === "Go to Meeting" ? (
                                  <img src={GoToIcon} alt="Go To icon" />
                                ) : (
                                  <img src={P} alt="event_img" />
                                )}
                                  {event.venue}
                                </p>
                              </div>
                              {event.is_past_event === "E" ? (
                                <button
                                  className="view-detail-btn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onModalOpen();
                                    this.onPastEventDetail(event);
                                  }}
                                >
                                  View Detail
                                </button>
                              ) : (
                                <button
                                  className="view-detail-btn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onModalOpen();
                                    this.onUpcomingEventDetail(event);
                                  }}
                                >
                                  View Detail
                                </button>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="rd_carditem"
                              key={innerIndex}
                              style={{ border: "1px solid #ccc" }}
                            >
                              <div className="rd_cardheaderevenpasc">
                                <h3
                                  className={
                                    event.status === "CC"
                                      ? "text-line-through"
                                      : ""
                                  }
                                >
                                  {event.event_name}
                                </h3>
                              </div>
                              <div className="rd_cardbdyevenpasc">
                                <p
                                  className={
                                    event.status === "CC"
                                      ? "text-line-through"
                                      : ""
                                  }
                                >
                                  <span className="rd_timeiconthin"></span>
                                  {`${event.start_time} - ${event.end_time}`}
                                </p>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </Fragment>
                );
              })}
            </Fragment>
          ) : null}

          {Object.keys(events).length > 0 && (
            <Row className="pb-4 pr-md-3">
              <Col className="text-center" lg="12" md="12">
                <Pagination
                  className="list-view-pagination"
                  defaultCurrent={pagination.current}
                  current={pagination.current}
                  total={this.state.totalEvents}
                  pageSize={pagination.pageSize}
                  onChange={this.onPageChange}
                />
              </Col>
            </Row>
          )}
        </div>
      </>
    );
  }
}

export default connect(null, {
  dateRangeEvents,
})(DateRange);
