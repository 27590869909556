import React, { Component } from "react";
import { gotProperty } from "../../../utils/utility";
import { connect } from "react-redux";
import { onUpdateFilters } from "../../../actions/filterActions";
import {
  getPages,
  getPageFields,
} from "../../../actions/super-admin/tooltip-actions";
import { Input } from "reactstrap";
import isEmpty from "../../../validation/is-empty";

class TooltipFilters extends Component {
  state = {
    pages: [],
    fields: [],
  };

  componentDidMount() {
    const _this = this;
    const { getPages } = this.props;
    getPages({
      onSuccess: function (data) {
        _this.setState({
          pages: data.pages,
        });
      },
      onError: function (error) {
        console.error(error);
      },
    });
  }

  /**
   * load page fields
   * @param in pageId
   */
  loadPageFields = (pageId) => {
    const _this = this;
    const { getPageFields } = _this.props;
    getPageFields({
      data: { pageId },
      onSuccess: function (data) {
        _this.setState({ fields: data.fields });
      },
      onError: function (error) {},
    });
  };

  render() {
    const { searchFilters } = this.props.filter;
    const { pages, fields } = this.state;
    return (
      <>
        <div className="form-group">
          <label htmlFor="page-id">Page</label>
          <Input
            type="select"
            name="select"
            id="page-id"
            value={
              gotProperty(searchFilters, "page_id")
                ? searchFilters["page_id"]
                : ""
            }
            onChange={(e) => {
              const pageId = e.target.value;
              this.props.onUpdateFilters("page_id", pageId);
              if (isEmpty(pageId)) {
                this.setState({ fields: [] });
                this.props.onUpdateFilters("page_field_id", "");
              } else {
                this.loadPageFields(pageId);
              }
            }}
          >
            <option value="">Select Page</option>
            {(pages || []).map((page, index) => {
              return (
                <option key={index} value={page.value}>
                  {page.label}
                </option>
              );
            })}
          </Input>
        </div>

        <div className="form-group">
          <label htmlFor="page-field-id">Page Field</label>
          <Input
            type="select"
            name="select"
            id="page-field-id"
            value={
              gotProperty(searchFilters, "page_field_id")
                ? searchFilters["page_field_id"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("page_field_id", e.target.value);
            }}
          >
            <option value="">Select Page Field</option>
            {(fields || []).map((field, index) => {
              return (
                <option key={index} value={field.value}>
                  {field.label}
                </option>
              );
            })}
          </Input>
        </div>

        <div className="form-group">
          <label htmlFor="status">Status</label>
          <Input
            type="select"
            name="status"
            id="status"
            value={
              gotProperty(searchFilters, "status")
                ? searchFilters["status"]
                : ""
            }
            onChange={(e) => {
              this.props.onUpdateFilters("status", e.target.value);
            }}
          >
            <option value="">Select Status</option>
            <option value="E">Enabled</option>
            <option value="D">Disabled</option>
          </Input>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  onUpdateFilters,
  getPages,
  getPageFields,
})(TooltipFilters);
