import React, { Component } from "react";
import {
  getEvent,
  savePaidBooking,
  saveFreeBooking,
} from "../../actions/bookingAction";
import {
  notification,
  getDBFormatedDate,
  withRouter,
} from "../../utils/utility";
import { connect } from "react-redux";
import BookingStep1 from "./booking-step-1";
import BookingStep2 from "./booking-step-2";
import BookingStep3 from "./booking-step-3";
import BookingStep4 from "./booking-step-4";

import "../../assets/bookingeventassets/booking.scss";

import "../../assets/bookingeventassets/calender/fonts/icomoon/style.css";
import "../../assets/bookingeventassets/calender/css/rome.css";
import "../../assets/bookingeventassets/calender/css/style.css";

import logo from "../../assets/bookingeventassets/assets/logo.svg";
import SideLogo from "../../assets/bookingeventassets/assets/sidelogo.svg";
import SideBarFootLogo from "../../assets/bookingeventassets/assets/side_bar_foot_logo.svg";
import Loader from "../../components/Loader/Loader";

class TimeToEmailBooking extends Component {
  state = {
    step: 1,
    event: {},
    company: {},
    seletedStaffId: null,
    selectedSlot: null,
    endSlotTime: null,
    bookingDate: null,
    name: null,
    email: null,
    messageFromCustomer: null,
    questionAnswers: [],
    isLoading: false,
  };

  /**
   * on step two submit handler
   * @param {*} name
   * @param {*} email
   * @param {*} messageFromCustomer
   */
  onSubmitStepTwo = (
    name,
    email,
    messageFromCustomer,
    questionAnswers,
    addOns = [],
    pricing = [],
    isEventPaid = "E"
  ) => {
    const _this = this;
    this.setState(
      {
        name,
        email,
        messageFromCustomer,
        questionAnswers,
      },
      () => {
        if (isEventPaid === "D") {
          _this.onScheduleEvent({});
        }
      }
    );
  };

  /**
   * on update step
   * @param {*} step
   */
  onUpdateStep = (step) => {
    this.setState({
      step,
    });
  };

  /**
   * on schedule event
   * @param {*} data
   */
  onScheduleEvent = (data) => {
    const _this = this;
    const {
      name,
      email,
      messageFromCustomer,
      questionAnswers,
      seletedStaffId,
      selectedSlot,
      event,
      bookingDate,
      company,
    } = this.state;

    /*
    console.log("addons", data.addOns);
    console.log("pricing", data.pricing);
    console.log("card details", data.cardDetails);
    console.log("name", name);
    console.log("email", email);
    console.log("messageFromCustomer", messageFromCustomer);
    console.log("questionAnswers", questionAnswers);
    console.log("selectedStaffId", seletedStaffId);
    console.log("selectedSlot", selectedSlot);
    console.log("booking date", bookingDate);
    */

    // calculate booking date and time
    const bookingDateTime = bookingDate + " " + selectedSlot;
    //console.log("booking date and time", bookingDateTime);

    // calculate slot end time
    let endDate = new Date(bookingDateTime);
    if (!isNaN(event.timeslot)) {
      endDate.setTime(endDate.getTime() + event.timeslot * 60 * 1000);
    }

    const endSlot =
      ("0" + endDate.getHours()).slice(-2) +
      ":" +
      ("0" + endDate.getMinutes()).slice(-2);

    _this.setState({
      endSlotTime: endSlot,
    });

    //console.log("end Slot", endSlot);

    console.log("event", event);

    console.log(event.is_event_paid, event.is_event_paid === "E");

    if (event.is_event_paid === "E") {
      // save paid event

      const { savePaidBooking } = this.props;

      const [expMonth, expYear] = data.cardDetails.card_expiry.split("/");

      const bookingDetails = {
        card_number: data.cardDetails.card_number,
        exp_month: expMonth,
        exp_year: expYear,
        cvc: data.cardDetails.cvc,
        event_id: event.id,
        booking_date_time: bookingDateTime,
        start_time: selectedSlot + ":00",
        end_time: endSlot + ":00",
        message_from_customer: messageFromCustomer,
        reminder_phone_no: "32424",
        business_id: company.id,
        staff_id: 1, //seletedStaffId,
        email: email,
        first_name: name,
        last_name: name,
        booking_addons: [...data.addOns],
        booking_pricing: [...data.pricing],
        questions: [...questionAnswers],
      };

      console.log("data", bookingDetails);

      savePaidBooking({
        data: {
          ...bookingDetails,
        },
        onSuccess: (data) => {
          _this.onUpdateStep(4);
          console.log("data", data);
        },
        onError: (error) => {
          /*
          this.setState({
            isLoading: false,
            errorMessage: error,
          });
          */
        },
      });
    } else {
      // save free event
      const { saveFreeBooking } = this.props;
      const bookingDetails = {
        event_id: event.id,
        booking_date_time: bookingDateTime,
        start_time: selectedSlot + ":00",
        end_time: endSlot + ":00",
        message_from_customer: messageFromCustomer,
        reminder_phone_no: "32424",
        business_id: company.id,
        staff_id: 1, //seletedStaffId,
        email: email,
        first_name: name,
        last_name: name,
        questions: [...questionAnswers],
      };

      saveFreeBooking({
        data: {
          ...bookingDetails,
        },
        onSuccess: (data) => {
          _this.onUpdateStep(4);
          console.log("data", data);
        },
        onError: (error) => {
          /*
          this.setState({
            isLoading: false,
            errorMessage: error,
          });
          */
        },
      });
    }
  };

  /**
   * execute when component inserted in dom
   */
  componentDidMount() {
    const _this = this;
    const { router, getEvent } = this.props || {};
    const { params } = router || {};
    const { slug, dated, slot } = params || {};

    _this.setState({
      isLoading: true,
    });

    getEvent({
      data: {
        slug,
      },
      onSuccess: function (response) {
        _this.setState({
          event: response.event,
          company: response.company,
          selectedSlot: slot,
          bookingDate: dated,
          step: 2,
          isLoading: false,
        });
      },
      onError: (error) => {
        notification.error(error.response.data.message);
      },
    });
  }

  render() {
    const {
      step,
      event,
      company,
      selectedSlot,
      endSlotTime,
      bookingDate,
      isLoading,
    } = this.state;
    const bookingData = {
      selectedSlot,
    };

    return (
      <>
        <Loader isShowLoader={isLoading} />
        <div className="container-fluid">
          <nav className="navbar navbar-expand-sm justify-content-center top_nav shadow">
            <a className="navbar-brand" href="#">
              <img src={logo} alt="Logo" style={{ width: "100%" }} />
            </a>
          </nav>
        </div>
        <div className="main_div">
          <div className="left_div">
            <nav className="navbar side_navbar">
              <ul className="navbar-nav side_bar_ul">
                <li className="nav-item">
                  <img src={SideLogo} />
                </li>
                <li className="nav-item">
                  <h2>{company.company_name}</h2>
                  <p>{company.company_description}</p>
                </li>
                <li className="nav-item">
                  <h2>Contact Details</h2>
                  <p className="sub_head">
                    <span>Email:</span> <br />
                    {company.company_email}
                  </p>
                  <p className="sub_head">
                    <span>Phone No:</span> <br />
                    {company.company_phone_no}
                  </p>
                  <p className="sub_head">
                    <span>Address</span> <br />
                    {company.company_address}
                  </p>

                  <div className="row side_bar_foot_img_div">
                    <div className="col-12 side_bar_foot_logo_img">
                      <img src={SideBarFootLogo} />
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div className="right_div">
            {/* {step === 1 && (
              <BookingStep1
                event={event}
                onSeletStaff={this.onSeletStaff}
                onSelectSlot={this.onSelectSlot}
                onSelectDate={this.onSelectDate}
                onUpdateStep={this.onUpdateStep}
              />
            )} */}
            {step === 2 && (
              <BookingStep2
                onSubmitStepTwo={this.onSubmitStepTwo}
                onScheduleEvent={this.onScheduleEvent}
                onUpdateStep={this.onUpdateStep}
                event={event}
                data={bookingData}
              />
            )}
            {step === 3 && (
              <BookingStep3
                onScheduleEvent={this.onScheduleEvent}
                event={event}
              />
            )}
            {step === 4 && (
              <BookingStep4
                event={event}
                selectedSlot={selectedSlot}
                endSlotTime={endSlotTime}
                bookingDate={bookingDate}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, {
  getEvent,
  savePaidBooking,
  saveFreeBooking,
})(withRouter(TimeToEmailBooking));
