import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Trash2 } from "react-feather";

class ConfirmDeleteModal extends Component {
  state = {
    isOpen: false,
  };

  /**
   * on toggle modal
   */
  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  render() {
    const { onDeleteRecord, data } = this.props;

    const { isOpen } = this.state;
    return (
      <>
        <Trash2
          size={15}
          onClick={(event) => {
            event.preventDefault();
            this.toggle();
          }}
        />
        <Modal isOpen={isOpen} toggle={this.toggle}>
          <ModalBody>
            <div className="">
              <div className="rd_modalvieew">
                <div className="box_content rd_modalboxcontent">
                  <div className="rd_inputselectheadermodalsetting">
                    <button
                      className="rd_colsebtn"
                      onClick={this.toggle}
                    ></button>
                  </div>

                  <h5 className="rd_modalheadertext rd_modalheadertextnm">
                    Confirm
                  </h5>

                  <p className="rd_modalheadertextparath mt-5 mb-5">
                    Are you sure, you want to delete coupon ?
                  </p>

                  <div className="rd_cancelappbtnmodal">
                    <button className="rd_cancel" onClick={this.toggle}>
                      Cancel
                    </button>
                    <button
                      className="btn btn-app"
                      onClick={() => {
                        this.toggle();
                        onDeleteRecord(data.id);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default ConfirmDeleteModal;
