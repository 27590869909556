import axios from "../../axios-instance";
import { getAdminBusinessId } from "../../utils/authHelper";

/**
 * get staff payments
 * @param {*} options
 * @returns
 */
export const getStaffPayments = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post(`admin/staff-payments/${getAdminBusinessId()}`, data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * get customer payments
 * @param {*} options
 * @returns
 */
export const getCustomerPayments = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post(`admin/customer-payments/${getAdminBusinessId()}`, data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * get event advanced page data
 * @param {*} options
 * @returns
 */
export const getCustomers = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post(`/admin/customers/${getAdminBusinessId()}`, data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * create customer
 * @param {*} options
 * @returns
 */
export const createCustomer = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  data.business_id = getAdminBusinessId();
  axios
    .post(`/admin/customer/create`, data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * delete customer
 * @param {*} options
 * @returns
 */
export const deleteCustomer = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post("/admin/customer/delete", data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};

/**
 * update customer
 * @param {*} options
 * @returns
 */
export const updateCustomer = (options) => async (dispatch) => {
  const { data, onSuccess, onError } = options || {};
  axios
    .post("/admin/customer/update", data)
    .then((res) => {
      if (onSuccess) {
        const { data } = res || {};
        onSuccess(data);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      }
    });
};
