import React, { Component } from 'react';
import { gotProperty } from '../../../../utils/utility';
import { connect } from "react-redux";
import { Input } from 'reactstrap';
import { getVenues } from "../../../../actions/eventActions";
import { onUpdateFilters } from "../../../../actions/filterActions";


class EventFilters extends Component {

    state = {
        venues: [],
    }

    componentDidMount() {
        const _this = this;
        const { getVenues } = this.props;
        getVenues({
            onSuccess: function (venues) {
              _this.setState({
                venues
              })
            },
            onError: function (error) {
              console.error(error);
            },
        });

    }

    render () {

        const { searchFilters } = this.props.filter;
        const { venues } = this.state;

        return (
            <>
                <div className="form-group">
                    <label htmlFor="event-name">Event Name</label>
                    <input
                    type="text"
                    id="event-name"
                    placeholder="Event Name ..."
                    className="form-control"
                    value={
                        gotProperty(searchFilters, "event_name")
                        ? searchFilters["event_name"]
                        : ""
                    }
                    onChange={(e) => {
                        this.props.onUpdateFilters("event_name", e.target.value);
                    }}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="event-venue">Venue</label>
                    <Input
                        type="select"
                        name="select"
                        id="event-venue"
                        value={
                            gotProperty(searchFilters, "venue_id")
                            ? searchFilters["venue_id"]
                            : ""
                        }
                        onChange={(e) => {
                            this.props.onUpdateFilters("venue_id", e.target.value);
                        }}
                    >
                        <option value="">Select Venue</option>
                        {(venues || []).map((venue, index) => {
                        return (
                            <option key={index} value={venue.id}>{venue.venue}</option>
                        )
                        })}
                    </Input>
                </div>

                <div className="form-group">
                    <label htmlFor="event-type">Type</label>
                    <Input
                        type="select"
                        name="select"
                        id="event-type"
                        value={
                            gotProperty(searchFilters, "is_event_paid")
                            ? searchFilters["is_event_paid"]
                            : ""
                        }
                        onChange={(e) => {
                            this.props.onUpdateFilters("is_event_paid", e.target.value);
                        }}
                    >
                        <option value="">Select Type</option>
                        <option value="E">Paid</option>
                        <option value="D">Free</option>
                    </Input>   
                </div> 

                <div className="form-group">
                    <label htmlFor="event-format">Event Format</label>
                    <Input
                        type="select"
                        name="event_format"
                        id="event-format"
                        value={
                            gotProperty(searchFilters, "event_format_id")
                            ? searchFilters["event_format_id"]
                            : ""
                        }
                        onChange={(e) => {
                            this.props.onUpdateFilters("event_format_id", e.target.value);
                        }}
                    >
                        <option value="">Select Event Format</option>
                        <option value="1">One to One</option>
                        <option value="2">Group</option>
                    </Input>    
                </div> 
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    filter: state.filter
  });
  
export default connect(mapStateToProps, {
    getVenues,
    onUpdateFilters
})(EventFilters);